// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './constants/theme';
import GlobalStyle from './GlobalStyles';
import AppRoutes from './Routers';
import ProcessLoading from './components/layouts/ProcessLoading';
import ModalContainer from './components/Modal';

import 'src/assets/css/ant-design-custom.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'src/assets/css/react-slick-custom.css';

export class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ProcessLoading />
        <AppRoutes />
        <ModalContainer />
      </ThemeProvider>
    );
  }
}

export default App;
