// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

const initialState = {
  tipList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  tipDetail: {
    data: [],
    meta: {},
    errors: [],
  },
};

export type TipReducer = Readonly<typeof initialState>;

export default (state: TipReducer = initialState, action: any): TipReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.TIP_ACTION.GET_LIST): {
    return {
      ...state,
      tipList: {
        ...state.tipList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.TIP_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      tipList: {
        data: data.tips,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TIP_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      tipList: {
        ...state.tipList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TIP_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      tipDetail: {
        data: data.tip,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TIP_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      tipDetail: {
        ...state.tipDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TIP_ACTION.UPDATE_TIP): {
    const { data, meta } = action.payload;
    return {
      ...state,
      tipDetail: {
        data: data.tip,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TIP_ACTION.UPDATE_TIP): {
    const { errors } = action;
    return {
      ...state,
      tipDetail: {
        ...state.tipDetail,
        errors,
      },
    };
  }

  default:
    return state;
  }
};
