// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { merge } from 'lodash/object';
import {
  ADMIN_COMPANY,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  courseDetail: {
    data: {
      plan: {},
      company: {},
      teachers: [],
      tags: [],
      category: {},
      surveys: [] as {
        id: number;
        item: object;
        unit: object;
        version: string;
      }[],
      tests: [] as {
        id: number;
        item: object;
        unit: object;
        version: string;
      }[],
      groups: [] as {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          id: number;
          name: string;
          totalUnits: number;
          totalVideosTime: number;
          unitVersions: {
            id: string;
            item: object;
            unit: object;
          }[];
        }[];
      }[],
      contentOverview: {},
      lastUpdater: {},
      updatedAt: '',
      emailWeeklySchedule: [],
      emailHoursSchedule: undefined,
      emailMinutesSchedule: undefined,
      excludeFinishedUserSchedule: false,
    },
    meta: {},
    load: false,
    errors: [] as object[],
  },
  attendanceStatus: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  attendanceChart: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  courseStudents: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  courses: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  testScoreOverview: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
  testScoreChart: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  testScoreStudents: {
    data: {
      coursesUnitVersions: [],
      usersScore: [],
    },
    meta: {},
    load: false,
    errors: [],
  },
  recommendDates : {
    data: {
      groupRecommendedDate: []
    },
  },
  userProgress:{
    data: [],
    meta: {},
    load: false,
    errors: [],
  }
};

export type CompanyCourseReducer = Readonly<typeof initialState>;

export default (state: CompanyCourseReducer = initialState, action: any): CompanyCourseReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL): {
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseDetail: {
        data: data.course,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_GROUP): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const groupSelectedData = state.courseDetail.data.groups[meta.groupIndex];
    merge(groupSelectedData, { chapters: data.group.chapters });
    newGroups.splice(meta.groupIndex, 1, groupSelectedData);
    return {
      ...state,
      courseDetail: {
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_GROUP): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_STATUS): {
    return {
      ...state,
      attendanceStatus: {
        ...state.attendanceStatus,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_STATUS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      attendanceStatus: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_STATUS): {
    const { errors } = action;
    return {
      ...state,
      attendanceStatus: {
        ...state.attendanceStatus,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_CHART): {
    return {
      ...state,
      attendanceChart: {
        ...state.attendanceChart,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_CHART): {
    const { data, meta } = action.payload;
    return {
      ...state,
      attendanceChart: {
        data: data.joiningStatuses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_CHART): {
    const { errors } = action;
    return {
      ...state,
      attendanceChart: {
        ...state.attendanceChart,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_PROGRESS_RATE_CHART): {
    return {
      ...state,
      attendanceChart: {
        ...state.attendanceChart,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_PROGRESS_RATE_CHART): {
    const { data, meta } = action.payload;
    return {
      ...state,
      attendanceChart: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_PROGRESS_RATE_CHART): {
    const { errors } = action;
    return {
      ...state,
      attendanceChart: {
        ...state.attendanceChart,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_STUDENT_LIST): {
    return {
      ...state,
      courseStudents: {
        ...state.courseStudents,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_STUDENT_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseStudents: {
        data: data.users,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_STUDENT_LIST): {
    const { errors } = action;
    return {
      ...state,
      courseStudents: {
        ...state.courseStudents,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES): {
    return {
      ...state,
      courses: {
        ...state.courses,
        load: true,
        errors: [],
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courses: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES): {
    const { errors } = action;
    return {
      ...state,
      courses: {
        ...state.courses,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW): {
    return {
      ...state,
      testScoreOverview: {
        ...state.testScoreOverview,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testScoreOverview: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW): {
    const { errors } = action;
    return {
      ...state,
      testScoreOverview: {
        ...state.testScoreOverview,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_CHART): {
    return {
      ...state,
      testScoreChart: {
        ...state.testScoreChart,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_CHART): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testScoreChart: {
        data: data.chartData,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_CHART): {
    const { errors } = action;
    return {
      ...state,
      testScoreChart: {
        ...state.testScoreChart,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_STUDENTS): {
    return {
      ...state,
      testScoreStudents: {
        ...state.testScoreStudents,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_STUDENTS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testScoreStudents: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_STUDENTS): {
    const { errors } = action;
    return {
      ...state,
      testScoreStudents: {
        ...state.testScoreStudents,
        load: false,
        errors,
      },
    };
  }
  
  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_RECOMMEND_DATE): {
    return {...state}
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_RECOMMEND_DATE): {
    const { data } = action.payload;
    return {
      ...state,
      recommendDates: {
        ...state.recommendDates,
        data: data
      }
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.SET_PROGRESS_NOTIFICATION): {
    return {
      ...state
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.SET_PROGRESS_NOTIFICATION): {
    const { emailWeeklySchedule, emailHoursSchedule, emailMinutesSchedule, excludeFinishedUserSchedule }
      = action.payload.requestData

    return {
      ...state,
      courseDetail:{
        ...state.courseDetail,
        data:{
          ...state.courseDetail.data,
          emailWeeklySchedule,
          emailHoursSchedule,
          emailMinutesSchedule,
          excludeFinishedUserSchedule,
        }
      }
    };
  } 

  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.SET_PROGRESS_NOTIFICATION): {
    const { errors } = action;
    return {
      ...state,
      courseDetail:{
        ...state.courseDetail,
        errors
      }
    };
  }

  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_USER_PROGRESS_COURSE): {
    return {
      ...state,
      userProgress: {
        ...state.userProgress,
        load: true,
        errors: [],
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_USER_PROGRESS_COURSE): {
    const { data, meta } = action.payload;
    return {
      ...state,
      userProgress: {
        data: data.users,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_USER_PROGRESS_COURSE): {
    const { errors } = action;
    return {
      ...state,
      userProgress: {
        ...state.userProgress,
        load: false,
        errors,
      },
    };
  }
      
  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.CLEAR_LEARNING_TAB): {
    return {
      ...state,
      userProgress: initialState.userProgress,
      attendanceChart: initialState.attendanceChart
    };
  }

  default:
    return state;
  }
};
