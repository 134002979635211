// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { findIndex } from 'lodash/array';
import {
  NOTIFICATION_ACTION,
  ADMIN_SERVICE,
  STUDENT,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../constant';

const initialState = {
  notificationList: {
    data: [] as {
      sender: {
        id: number;
        jituName: string;
        kanaName: string;
        avatarUrl: string;
      },
      item: {
        id: number;
        type: string;
      },
      unit: {
        id: number;
        name: string;
      },
      sentAt: '',
      isRead: boolean,
      course: {
        id: number;
        name: string;
        courseCategory: {
          name: string;
        };
      };
      action: string;
      company:{
        id: number,
        contractName: string,
        name: string
      }
      optionalInformation: {
        numberEmailInvited: number,
        numberEmailSuccess: number
      }
    }[],
    meta: {},
    load: false,
    errors: [] as object[],
  },
  dropdownNotifications: {
    data: [] as {
      isRead: boolean,
    }[],
    meta: {},
    load: false,
    errors: [] as object[],
  },
  notificationUnreadCount: 0,
};

export type NotificationReducer = Readonly<typeof initialState>;

export default (state: NotificationReducer = initialState, action: any): NotificationReducer => {
  switch (action.type) {
  case SUCCESS(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD): {
    const { data } = action.payload;
    return {
      ...state,
      notificationUnreadCount: data.unreadCount,
    };
  }

  case REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_LIST): {
    return {
      ...state,
      notificationList: {
        ...state.notificationList,
        load: true,
      },
    };
  }
  case SUCCESS(NOTIFICATION_ACTION.GET_NOTIFICATION_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      notificationList: {
        data: data.notifications,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(NOTIFICATION_ACTION.GET_NOTIFICATION_LIST): {
    const { errors } = action;
    return {
      ...state,
      notificationList: {
        ...state.notificationList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(NOTIFICATION_ACTION.GET_DROPDOWN_NOTIFICATIONS): {
    return {
      ...state,
      dropdownNotifications: {
        ...state.dropdownNotifications,
        load: true,
      },
    };
  }
  case SUCCESS(NOTIFICATION_ACTION.GET_DROPDOWN_NOTIFICATIONS): {
    const { data, meta, more } = action.payload;
    if (more) {
      return {
        ...state,
        dropdownNotifications: {
          data: [
            ...state.dropdownNotifications.data,
            ...data.notifications,
          ],
          meta,
          load: false,
          errors: [],
        },
      };
    }
    return {
      ...state,
      dropdownNotifications: {
        data: data.notifications,
        meta,
        errors: [],
        load: false,
      },
    };
  }
  case FAILURE(NOTIFICATION_ACTION.GET_DROPDOWN_NOTIFICATIONS): {
    const { errors } = action;
    return {
      ...state,
      dropdownNotifications: {
        ...state.dropdownNotifications,
        errors,
        load: false,
      },
    };
  }

  case SUCCESS(NOTIFICATION_ACTION.MARK_NOTIFICATIONS_AS_READ): {
    const newNotificationList = state.notificationList.data;
    const newDropdownNotifications = state.dropdownNotifications.data;
    newNotificationList.map((notification) => notification.isRead = true);
    newDropdownNotifications.map((notification) => notification.isRead = true);
    return {
      ...state,
      notificationList: {
        ...state.notificationList,
        data: newNotificationList,
      },
      dropdownNotifications: {
        ...state.dropdownNotifications,
        data: newDropdownNotifications,
      },
    };
  }
  case FAILURE(NOTIFICATION_ACTION.MARK_NOTIFICATIONS_AS_READ): {
    const { errors } = action;
    return {
      ...state,
      notificationList: {
        ...state.notificationList,
        errors,
      },
      dropdownNotifications: {
        ...state.dropdownNotifications,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_DETAIL):
  case SUCCESS(STUDENT.TEST_ACTION.GET_TEST_RESULTS): {
    const { notificationId } = action.payload;

    const newNotificationList = state.notificationList.data;
    const notificationIndex = findIndex(state.notificationList.data, { id: notificationId });
    if (notificationIndex !== -1) {
      newNotificationList[notificationIndex].isRead = true;
    }

    const newDropdownNotifications = state.dropdownNotifications.data;
    const dropdownNotificationIndex = findIndex(state.dropdownNotifications.data, { id: notificationId });
    if (dropdownNotificationIndex !== -1) {
      newDropdownNotifications[dropdownNotificationIndex].isRead = true;
    }

    return {
      ...state,
      notificationList: {
        ...state.notificationList,
        data: newNotificationList,
      },
      dropdownNotifications: {
        ...state.dropdownNotifications,
        data: newDropdownNotifications,
      },
    };
  }

  case REQUEST(NOTIFICATION_ACTION.CHECK_NOTIFICATION_AS_READ): {
    const { notificationId } = action.payload;

    const newNotificationList = state.notificationList.data;
    const notificationIndex = findIndex(state.notificationList.data, { id: notificationId });
    if (notificationIndex !== -1) {
      newNotificationList[notificationIndex].isRead = true;
    }

    const newDropdownNotifications = state.dropdownNotifications.data;
    const dropdownNotificationIndex = findIndex(state.dropdownNotifications.data, { id: notificationId });
    if (dropdownNotificationIndex !== -1) {
      newDropdownNotifications[dropdownNotificationIndex].isRead = true;
    }

    return {
      ...state,
      notificationList: {
        ...state.notificationList,
        data: newNotificationList,
      },
      dropdownNotifications: {
        ...state.dropdownNotifications,
        data: newDropdownNotifications,
      },
    };
  }

  default:
    return state;
  }
};
