// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import {
  Button,
  Collapse,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import { Text } from 'src/components/styles/Texts';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import NoData from 'src/components/layouts/NoData';
import ModifyUnitModal from '../ModifyUnitModal';

import { SURVEY_TYPE, TEST_TYPE } from 'src/constants/common';

import * as Style from './styles';

interface ISurveyAndTestProps {
  showModal(params): void;
  deleteCourseUnit(params: any): void;
  courseId: string;
  courseDetail: {
    data: {
      surveys: {
        id: number;
        isLearned: boolean;
        item: {
          surveyType: string;
        };
        unit: {
          id: number;
          name: string;
          category: {
            id: number;
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        isLearned: boolean;
        item: {
          testType: string;
          questionsCount: number;
        };
        unit: {
          id: number;
          name: string;
          category: {
            id: number;
            name: string;
          };
        };
      }[];
    };
  };
}

const SurveyAndTest: React.FC<ISurveyAndTestProps> = ({
  showModal,
  deleteCourseUnit,
  courseId,
  courseDetail,
}) => {
  const [surveyActiveKey, setSurveyActiveKey] = useState<string | string[]>([]);
  const [testActiveKey, setTestActiveKey] = useState<string | string[]>([]);

  useEffect(() => {
    if (isEmpty(courseDetail.data.surveys)) {
      setSurveyActiveKey(['noData']);
    }
  }, [courseDetail.data.surveys]);

  useEffect(() => {
    if (isEmpty(courseDetail.data.tests)) {
      setTestActiveKey(['noData']);
    }
  }, [courseDetail.data.tests]);

  function handleModifyUnit(actionType, unitType, unitData, unitIndex) {
    const unitProps = unitData
      ? { ...unitData, index: unitIndex }
      : { item: { type: unitType } };
    const modalProps = {
      modalData: {
        title: actionType === 'add' ? 'レッスン新規追加' : 'レッスン編集',
        type: 'modifyCourseUnit',
        course: {
          id: courseId,
        },
        unitVersion: unitProps,
        actionType,
      },
      modalAction: {
        setActiveKey: unitType === 'survey' ? setSurveyActiveKey : setTestActiveKey,
      },
    };
    return showModal(<ModifyUnitModal {...modalProps} />);
  }

  function handleDeleteUnit(unitType, unitId, unitIndex) {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: unitType === 'test'
          ? 'このテストを削除してもよろしいですか？'
          : 'このアンケートを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deleteCourseUnit({
          requestData: {
            courseId,
            unitId,
          },
          unitIndex,
          unitType,
        }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function renderSurveyItems() {
    if (isEmpty(courseDetail.data.surveys)) return null;
    return courseDetail.data.surveys.map((survey, unitIndex) => (
      <Style.CollapseItemContainer key={`survey-${survey.id}`}>
        <Style.CollapseItemContent width="20%">
          <Text lg w6>
            {SURVEY_TYPE[survey.item.surveyType]}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="70%">
          <Text lg w6>
            {survey.unit.name}
          </Text>
        </Style.CollapseItemContent>
        {/* <Style.CollapseItemContent width="35%">5問</Style.CollapseItemContent> */}
        <Style.CollapseItemContent justify="flex-end" width="10%">
          <Button
            size="small"
            danger
            ghost
            onClick={() => handleDeleteUnit('survey', survey.id, unitIndex)}
            style={{ marginRight: 16 }}
          >
            &nbsp;削除&nbsp;
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => handleModifyUnit('edit', 'survey', survey, unitIndex)}
            disabled={survey.isLearned}
          >
            &nbsp;編集&nbsp;
          </Button>
        </Style.CollapseItemContent>
      </Style.CollapseItemContainer>
    ));
  }

  function renderTestItems() {
    if (isEmpty(courseDetail.data.tests)) return null;
    return courseDetail.data.tests.map((test, unitIndex) => (
      <Style.CollapseItemContainer key={`test-${test.id}`}>
        <Style.CollapseItemContent width="20%">
          <Text lg w6>
            {TEST_TYPE[test.item.testType]}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="55%">
          <Text lg w6 style={{ width: 'calc(100% - 24px)' }}>
            {test.unit.name}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="15%">
          <Text lg>
            {`${test.item.questionsCount}問`}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent justify="flex-end" width="10%">
          <Button
            size="small"
            danger
            ghost
            onClick={() => handleDeleteUnit('test', test.id, unitIndex)}
            style={{ marginRight: 16 }}
          >
            &nbsp;削除&nbsp;
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => handleModifyUnit('edit', 'test', test, unitIndex)}
            disabled={test.isLearned}
          >
            &nbsp;編集&nbsp;
          </Button>
        </Style.CollapseItemContent>
      </Style.CollapseItemContainer>
    ));
  }

  return (
    <>
      <Style.CourseItemsContainer>
        <Style.CourseItemsHeader>
          <Text xxl style={{ marginBottom: 16 }}>アンケート</Text>
          <Button type="primary" onClick={() => handleModifyUnit( 'add', 'survey', null, null)}>新規追加</Button>
        </Style.CourseItemsHeader>
        <Collapse
          bordered={false}
          activeKey={surveyActiveKey}
          expandIconPosition="right"
          expandIcon={
            ({ isActive }) => isActive ? <MinusOutlined style={{ color: 'white' }} /> : <PlusOutlined style={{ color: 'white' }} />
          }
          onChange={(values) => setSurveyActiveKey(values)}
          style={{ backgroundColor: '#0D122D', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <Collapse.Panel
            key={!isEmpty(courseDetail.data.surveys) ? 'survey' : 'noData'}
            header={
              <Style.CollapseHeader>
                <div style={{ width: '20%' }}>
                  <Text white w6>アンケート種別</Text>
                </div>
                <div style={{ width: '35%' }}>
                  <Text white w6>アンケート名</Text>
                </div>
              </Style.CollapseHeader>
            }
          >
            {!isEmpty(courseDetail.data.surveys)
              ? renderSurveyItems()
              : <NoData message="データが存在しません。" />
            }
          </Collapse.Panel>
        </Collapse>
      </Style.CourseItemsContainer>
      <Style.CourseItemsContainer>
        <Style.CourseItemsHeader>
          <Text margin="0 0 14px 0" xxl>テスト</Text>
          <Button type="primary" onClick={() => handleModifyUnit('add', 'test', null, null)}>新規追加</Button>
        </Style.CourseItemsHeader>
        <Collapse
          bordered={false}
          activeKey={testActiveKey}
          expandIconPosition="right"
          expandIcon={
            ({ isActive }) => isActive ? <MinusOutlined style={{ color: 'white' }} /> : <PlusOutlined style={{ color: 'white' }} />
          }
          onChange={(values) => setTestActiveKey(values)}
          style={{ backgroundColor: '#0D122D', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
        >
          <Collapse.Panel
            key={!isEmpty(courseDetail.data.tests) ? 'test' : 'noData'}
            header={
              <Style.CollapseHeader>
                <div style={{ width: '20%' }}>
                  <Text white w6>テスト種別</Text>
                </div>
                <div style={{ width: '55%' }}>
                  <Text white w6>レッスン名</Text>
                </div>
                <div style={{ width: '15%' }}>
                  <Text white w6>問題数</Text>
                </div>
              </Style.CollapseHeader>
            }
          >
            {!isEmpty(courseDetail.data.tests)
              ? renderTestItems()
              : <NoData message="データが存在しません。" />
            }
          </Collapse.Panel>
        </Collapse>
      </Style.CourseItemsContainer>
    </>
  );
};

export default SurveyAndTest;
