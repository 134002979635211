// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Collapse,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import { getSearchParams } from 'src/utils/common';
import { PlayerStorage } from 'src/utils/localStorge';

import { handleNavigateUnit } from '../../util';

import NoData from 'src/components/layouts/NoData';
import { Text, SkeletonLoading } from 'src/components/styles';

import {
  getOverviewGroupDetail as getOverviewGroupDetailAction,
  setCollapseData as setCollapseDataAction,
} from 'src/redux/actions';

import history from 'src/utils/history';

import prevVideoIcon from 'src/assets/images/videoPlayer/prev-video-icon.svg';
import nextVideoIcon from 'src/assets/images/videoPlayer/next-video-icon.svg';
import prevVideoDisabledIcon from 'src/assets/images/videoPlayer/prev-video-disabled-icon.svg';
import nextVideoDisabledIcon from 'src/assets/images/videoPlayer/next-video-disabled-icon.svg';

import * as Style from './styles';
import { isNumber } from 'util';

declare global {
  interface Window { LMSVideoPlayer: any; }
}
interface IVideoPlayerProps extends StateProps, DispatchProps {
  getOverviewGroupDetail(params): void;
  createStudentQuestion(params): void;
  setCollapseData(params): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  overviewCourseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
      groups: {
        id: number;
        name: string;
        chapters: {
          faqs: {
            id: number;
            name: string;
            faqContents: {
              question: string;
              answer: string;
            }[];
          }[];
        }[];
      }[];
    };
    load: boolean;
  };
  overviewUnitDetail: {
    data: {
      id: number;
      unit: {
        name: string;
        description: string;
        teachers: {
          id: number;
          name: string;
        }[];
      };
      item: {
        type: string;
        duration: number;
        teacherName: string;
        vmsObfuscatedMid: string;
        vmsMid: number;
      };
    };
    meta: {
      back: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
      next: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
    };
    load: boolean;
  };
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  getOverviewGroupDetail,
  setCollapseData,
  collapseData,
  overviewCourseDetail,
  overviewUnitDetail,
}) => {

  const [countdownTime, setCountdownTime] = useState<any>(Date.now());

  const videoContentRef = useRef<any>(null);
  const faqContentRef = useRef<any>(null);
  const refCountDown = useRef<any>();

  const groupId = getSearchParams('group');
  const chapterId = getSearchParams('chapter');
  const videoSetting = PlayerStorage.getData() || {};
  const pathnameSplit = history.location.pathname.split('/');
  const unitId = pathnameSplit[5];

  const teacherList = !isEmpty(overviewUnitDetail.data.unit.teachers)
    ? overviewUnitDetail.data.unit.teachers.map((teacher) => teacher.name)
    : [];

  useEffect(() => {
    if (overviewUnitDetail.data.item.vmsMid && overviewUnitDetail.data.item.duration > 0) {
      setCountdownTime(Date.now() + (overviewUnitDetail.data.item.duration * 1000) + 5000); // 5s next video
    }
  }, [overviewUnitDetail.data.item.vmsMid]);
  
  useEffect(() => {
    if (Number(unitId) === overviewUnitDetail.data?.id && overviewUnitDetail.data?.item?.vmsObfuscatedMid) {
      const videoFunction = `(function initPlayer() {
        var player = jstream_t3.PlayerFactoryIF.create({
          b: "${process.env.REACT_APP_BASE_PLAYER_CONFIG}",
          c: "${process.env.REACT_APP_CONTRACT_ID}",
          m: "${overviewUnitDetail.data.item.vmsObfuscatedMid}",
          t: "0.001",
          s: {
            wp: "${window.innerWidth ? window.innerWidth : 1920}",
            hp: "${window.innerWidth ? window.innerWidth * 9 / 16 : 1080}",
            il: "off",
            pr: "0.5,0.7,1.2,1.5,2.0",
            sn: "",
            tg: "off",
            ip: "on",
            rp: "on",
            rs: "on",
            skb: "10",
            dq: "3",
            mdq: "3",
            ti: "off",
            wm: "off",
          },
        }, "videoPlayer");
        window.LMSVideoPlayer = player;
      })();`;

      const videoScript = document.createElement('script');
      videoScript.innerHTML = videoFunction;
      videoScript.type = 'application/javascript';

      document.body.appendChild(videoScript);

      return function cleanVideoScript() {
        if (overviewUnitDetail.data.item.type === 'video') {
          document.body.removeChild(videoScript);
          PlayerStorage.setData({
            quality: window.LMSVideoPlayer?.accessor?.getQuality(),
            playRate: window.LMSVideoPlayer?.accessor?.getPlaybackRate(),
          });
        }
      };
    }
  }, [overviewUnitDetail.data?.id, overviewUnitDetail.data?.item?.vmsObfuscatedMid]);

  useEffect(() => {
    if (window.LMSVideoPlayer?.accessor?.isLanding) {
      window.LMSVideoPlayer?.accessor?.setPlaybackRate(videoSetting?.playRate || 1);
      window.LMSVideoPlayer?.accessor?.changeQuality(videoSetting?.quality || 3);
    }
  }, [window.LMSVideoPlayer?.accessor?.isLanding]);

  let mousePosition;
  const minSize = 300;

  document.addEventListener('mouseup', () => {
    document.removeEventListener('mousemove', handleResize, false);
  }, false);

  function handleMouseDown(e) {
    mousePosition = e.x;
    document.addEventListener('mousemove', handleResize, false);
  }

  function handleResize(e) {
    const dx = mousePosition - e.x;
    mousePosition = e.x;

    const videoContentElement = videoContentRef.current;
    const faqContentElement = faqContentRef.current;

    const videoWidth = videoContentRef.current.offsetWidth;
    const faqWidth = faqContentRef.current.offsetWidth;

    if (videoWidth >= minSize && faqWidth >= minSize) {
      videoContentElement.style.width = `calc(100% - ${faqWidth + dx + 16}px)`;
      // 16 is margin-right of videoPlayer component
      faqContentElement.style.width = faqWidth + dx + 'px';
    }
    if (videoWidth < minSize) videoContentElement.style.width = minSize - dx + 'px';
    if (faqWidth < minSize) faqContentElement.style.width = minSize + dx + 'px';
  }

  function renderFAQContents(faqContents) {
    return faqContents.map((faqContent, faqContentIndex) => (
      <Style.AnswerContainer key={`faq-content-${faqContentIndex}`}>
        <Text multiLine>{faqContent.question}</Text>
        <Style.AnswerContent>
          <Text multiLine w6>{faqContent.answer}</Text>
        </Style.AnswerContent>
      </Style.AnswerContainer>
    ));
  }

  function renderFAQCollapse() {
    const groupActiveIndex = findIndex(overviewCourseDetail.data.groups, { id: parseFloat(groupId as string) });
    const chapterActiveIndex = groupActiveIndex !== -1
      ? findIndex(overviewCourseDetail.data.groups[groupActiveIndex].chapters, { id: parseFloat(chapterId as string) })
      : -1;
    const faqList = chapterActiveIndex !== -1
      ? overviewCourseDetail.data.groups[groupActiveIndex].chapters[chapterActiveIndex].faqs
      : [];
    if (isEmpty(faqList)) return (
      <NoData
        message="ー"
        style={{
          justifyContent: 'flex-start',
          paddingLeft: 20,
        }}
      />
    );
    return faqList.map((faqCollapse, faqCollapseIndex) => (
      <Collapse.Panel
        key={`faq-${faqCollapseIndex}`}
        header={
          <Text white w6>{faqCollapse.name}</Text>
        }
        className="custom-question-collapse"
      >
        {renderFAQContents(faqCollapse.faqContents)}
      </Collapse.Panel>
    ));
  }

  const onTickCountDown = () => {
    const currentTime = Math.floor(window.LMSVideoPlayer?.accessor?.getCurrentTime());
    setCountdownTime(Date.now() + (overviewUnitDetail.data.item.duration - (currentTime || 0)) * 1000);
  };

  return (
    <>
      {overviewUnitDetail.data.item.vmsMid && (
        <div style={{ display: 'none' }}>
          <Countdown
            ref={refCountDown}
            key={overviewUnitDetail.data.item.vmsMid}
            date={countdownTime}
            onTick={onTickCountDown}
            onComplete={() => {
              const currentTime = Math.floor(window.LMSVideoPlayer?.accessor?.getCurrentTime()) || 0;
              if (isNumber(currentTime) && overviewUnitDetail.data.item.duration - currentTime >= 1) {
                setCountdownTime(Date.now() + (overviewUnitDetail.data.item.duration - currentTime) * 1000);
                refCountDown.current.start();
              } else {
                handleNavigateUnit({
                  action: 'next',
                  getStudentGroupDetail: getOverviewGroupDetail,
                  setCollapseData,
                  courseDetail: overviewCourseDetail,
                  unitDetail: overviewUnitDetail,
                  collapseData,
                });
              }
            }}
          />
        </div>
      )}
      {overviewCourseDetail.load
        ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
        : <Text white w6 xxl>{overviewCourseDetail.data.courseCategory?.name}</Text>
      }
      <Style.VideoDetailContainer>
        <Style.VideoDetailContent ref={videoContentRef}>
          <Style.VideoDetailTitle>
            {overviewCourseDetail.load || overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text white lg w6>{overviewUnitDetail.data.unit.name}</Text>
            }
            <Button
              type="link"
              icon={<Style.MoveLeftIcon />}
              onMouseDown={(e) => handleMouseDown(e)}
              style={{ cursor: 'w-resize', width: '30px' }}
            />
          </Style.VideoDetailTitle>
          <Style.VideoPlayerContainer>
            <Style.VideoPlayerContent load={overviewUnitDetail.load}>
              <div key={overviewUnitDetail.data.item.vmsMid} id="videoPlayer" />
            </Style.VideoPlayerContent>
          </Style.VideoPlayerContainer>
          <Style.VideoDescription>
            {overviewCourseDetail.load || overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 2 }} title={false} />
              : <Text sm>{overviewUnitDetail.data.unit.description}</Text>
            }
            <Style.VideoTeacher>
              <Text xs style={{ marginRight: 8 }}>講師</Text>
              {overviewCourseDetail.load || overviewUnitDetail.load
                ? (
                  <div>
                    <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                  </div>
                )
                : <Text w6>{teacherList.length > 0 ? teacherList.toString() : 'ー'}</Text>
              }
            </Style.VideoTeacher>
            <Style.VideoActionContainer>
              <Button
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(overviewUnitDetail.meta.back)}
                onClick={() => handleNavigateUnit({
                  action: 'back',
                  getStudentGroupDetail: getOverviewGroupDetail,
                  setCollapseData,
                  courseDetail: overviewCourseDetail,
                  unitDetail: overviewUnitDetail,
                  collapseData,
                })}
              >
                <img
                  src={overviewUnitDetail.meta.back ? prevVideoIcon : prevVideoDisabledIcon}
                  style={{ marginRight: 4 }}
                  alt=""
                />
                戻る
              </Button>
              <Button
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(overviewUnitDetail.meta.next)}
                onClick={() => handleNavigateUnit({
                  action: 'next',
                  getStudentGroupDetail: getOverviewGroupDetail,
                  setCollapseData,
                  courseDetail: overviewCourseDetail,
                  unitDetail: overviewUnitDetail,
                  collapseData,
                })}
              >
                次へ
                <img
                  src={overviewUnitDetail.meta.next ? nextVideoIcon : nextVideoDisabledIcon}
                  style={{ marginLeft: 4 }}
                  alt=""
                />
              </Button>
            </Style.VideoActionContainer>
          </Style.VideoDescription>
        </Style.VideoDetailContent>
        <Style.FAQContent ref={faqContentRef}>
          <Style.FAQTitle>
            <Button
              type="link"
              icon={<Style.MoveRightIcon />}
              onMouseDown={(e) => handleMouseDown(e)}
              style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'w-resize', width: '30px' }}
            />
            <Text white lg w6 style={{ marginLeft: 20 }}>FAQ</Text>
          </Style.FAQTitle>
          <Style.CollapseContainer>
            <Collapse
              key={`FAQs-${chapterId}`}
              className="faq-collapse"
              bordered={false}
              defaultActiveKey={[]}
              expandIcon={
                ({ isActive }) => isActive
                  ? <MinusOutlined style={{ color: 'white' }} />
                  : <PlusOutlined style={{ color: 'white' }} />
              }
              expandIconPosition="right"
            >
              {renderFAQCollapse()}
            </Collapse>
          </Style.CollapseContainer>
        </Style.FAQContent>
      </Style.VideoDetailContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { collapseData } = state.commonReducer;
  const { overviewCourseDetail } = state.courseReducer;
  const { overviewUnitDetail } = state.unitReducer;
  return {
    collapseData,
    overviewCourseDetail,
    overviewUnitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOverviewGroupDetail: (params) => dispatch(getOverviewGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setCollapseDataAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
