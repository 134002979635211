// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

const initialState = {
  uploadAnswerList: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
  uploadAnswerDetail: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
};

export type UploadAnswerReducer = Readonly<typeof initialState>;

export default (state: UploadAnswerReducer = initialState, action: any): UploadAnswerReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_LIST): {
    return {
      ...state,
      uploadAnswerList: {
        ...state.uploadAnswerList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      uploadAnswerList: {
        data: data.usersAnswers,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      uploadAnswerList: {
        ...state.uploadAnswerList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      uploadAnswerDetail: {
        data: data.usersAnswer,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      uploadAnswerDetail: {
        ...state.uploadAnswerDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.SCORE_UPLOAD_ANSWER): {
    const { data } = action.payload;
    const newUploadAnswerList = state.uploadAnswerList.data;
    const uploadAnswerIndex = state.uploadAnswerList.data.findIndex((item) => item.id === data.usersAnswer.id);
    if (uploadAnswerIndex >= 0) {
      newUploadAnswerList.splice(uploadAnswerIndex, 1, {
        ...state.uploadAnswerList.data[uploadAnswerIndex],
        ...data.usersAnswer,
        status: 'scored',
      });
    }
    return {
      ...state,
      uploadAnswerList: {
        ...state.uploadAnswerList,
        data: newUploadAnswerList,
      },
    };
  }

  default:
    return state;
  }
};
