//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import {
  Button,
  Table,
  Space,
  Progress,
} from 'antd';
import { isEmpty, isNumber } from 'lodash';

import history from 'src/utils/history';
import { convertSecondTime } from 'src/utils/dateTime';
import { formatDateTime } from 'src/utils/dateTime';

import { PaginationLayout } from 'src/components/layouts/Pagination';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { CompanyPath } from 'src/constants/routerConstants';
import { DATE_FORMAT } from 'src/constants/common';

import * as Style from './styles';

interface ICompanyCoursesProps {
  getCompanyContractCourses(params): void;
  getCompanyCourseDetail(params): void;
  activeTabKey: string;
  companyDetail: {
    data: {
      id: number;
    };
  };
  companyCourses: {
    data: {
      id: number;
      plan: {
        id: number;
        name: string;
      };
      name: string;
      courseCategory: {
        name: string;
      }
      totalLearningTime: number;
      usedLicensesPass: number;
      companyProgressPercentage: number;
      licensesCount: number;
      remainingLicensesCount: number;
      startDate: string;
      endDate: string;
      remainingDays: number;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const CompanyCourses: React.FC<ICompanyCoursesProps> = ({
  getCompanyContractCourses,
  getCompanyCourseDetail,
  companyDetail,
  companyCourses,
  activeTabKey,
}) => {
  const { data, meta } = companyCourses;

  useEffect(() => {
    if (companyDetail.data.id && activeTabKey === '2') {
      getCompanyContractCourses({ page: 1 });
    }
  }, [companyDetail.data.id, activeTabKey]);

  const tableData = data.map((row: any) => ({
    key: row.id,
    id: row.id,
    name: row.courseCategory?.name,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'HH:mm:ss'),
    usedLicensesPass: row.usedLicensesPass,
    companyProgressPercentage: row.companyProgressPercentage,
    licensesCount: `${row.licensesCount - row.remainingLicensesCount}(残り${row.remainingLicensesCount})`,
    startDate: row.startDate ? formatDateTime(row.startDate, DATE_FORMAT) : 'ー',
    endDate: row.endDate
      ? `${formatDateTime(row.endDate, DATE_FORMAT)}${row.remainingDays > 0 ? `[残り${row.remainingDays}日]` : ''}`
      : 'ー',
  }));

  const tableColumns = [
    {
      title: 'コース名',
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 400,
    },
    {
      title: '学習時間',
      dataIndex: 'totalLearningTime',
      width: 120,
    },
    {
      title: '合格者数',
      dataIndex: 'usedLicensesPass',
      render: (_, record) => (
        <Text>{isNumber(record.usedLicensesPass) ? record.usedLicensesPass : 'ー'}</Text>
      ),
      width: 100,
    },
    {
      title: '進歩率',
      dataIndex: 'companyProgressPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.companyProgressPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.companyProgressPercentage} />
        </Space>
      ),
      width: 180,
    },
    {
      title: '受講者数',
      dataIndex: 'licensesCount',
      width: 160,
    },
    {
      title: '受講開始日',
      dataIndex: 'startDate',
      width: 120,
    },
    {
      title: '受講終了日',
      dataIndex: 'endDate',
      width: 200,
    },
  ];

  return (
    <>
      <Style.CompanyCoursesTitle>
        <Text xxxl>契約コース一覧</Text>
        <Button type="primary" onClick={() => history.push(CompanyPath.LicenseGrant)}>
          ライセンス付与
        </Button>
      </Style.CompanyCoursesTitle>
      <Table
        className="table-cursor-pointer"
        pagination={false}
        scroll={{ x: 1280, y: 506 }}
        columns={tableColumns}
        dataSource={tableData}
        onRow={(record) => ({
          onClick: () => {getCompanyCourseDetail({
            id: record.id,
            redirect: `/admin/course/${record.id}`,
          })} 
        })}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={companyCourses.load}
      />
      {!isEmpty(companyCourses.data) && (
        <PaginationLayout
          pageInfo={meta.pageInfo}
          onChangePage={getCompanyContractCourses}
        />
      )}
    </>
  );
};

export default CompanyCourses;
