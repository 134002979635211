// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { ReactNode } from 'react';

import * as Style from './styles';

interface IMainContentProps {
  children: ReactNode;
  fullView?: boolean;
  formView?: boolean;
  sidebarView?: boolean;
  previewQuestion?: boolean;
  isResponsive?: boolean;
  isCourseView?: boolean;
}

const MainContent: React.FC<IMainContentProps> = ({
  children,
  fullView,
  formView,
  previewQuestion,
  sidebarView,
  isResponsive,
  isCourseView
}) => (
  <Style.MainContent
    fullView={fullView}
    formView={formView}
    sidebarView={sidebarView}
    previewQuestion={previewQuestion}
    isResponsive={isResponsive}
    isCourseView={isCourseView}
  >
    {children}
  </Style.MainContent>
);

export default MainContent;
