// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import { MASTER_DATA_ACTION, SUCCESS, FAILURE, REQUEST, NOTIFICATION_ACTION } from '../constant';

import { masterDataServices } from 'src/services';

function* getCareerListSaga() {
  try {
    const result = yield masterDataServices.getCareerList();
    yield put({
      type: SUCCESS(MASTER_DATA_ACTION.GET_CAREER_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(MASTER_DATA_ACTION.GET_CAREER_LIST), errors: e.errors });
  }
}

function* getProvinceListSaga() {
  try {
    const result = yield masterDataServices.getProvinceList();
    yield put({
      type: SUCCESS(MASTER_DATA_ACTION.GET_PROVINCE_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(MASTER_DATA_ACTION.GET_PROVINCE_LIST), errors: e.errors });
  }
}

function* getCurrentTimeSaga(action) {
  try {
    const result = yield masterDataServices.getCurrentTime(action.payload);
    yield put({
      type: SUCCESS(MASTER_DATA_ACTION.GET_CURRENT_TIME),
      payload: result,
    });
    if(action.payload.requestData){
      yield put({ type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD) });
      yield put({
        type: REQUEST(NOTIFICATION_ACTION.CHECK_NOTIFICATION_AS_READ),
        payload: {
          notificationId:
            action?.payload?.requestData?.markNotificationAsReadId,
        },
      });
    }
  } catch (e) {
    yield put({ type: FAILURE(MASTER_DATA_ACTION.GET_CURRENT_TIME), errors: e.errors });
  }
}

function* getCheckVideoSaga(action) {
  try {
    const result = yield masterDataServices.getCheckVideo(action.payload);
    yield put({
      type: SUCCESS(MASTER_DATA_ACTION.GET_CHECK_VIDEO),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(MASTER_DATA_ACTION.GET_CHECK_VIDEO), errors: e.errors });
  }
}

function* getCurrentUserInfo() {
  try {
    const result = yield masterDataServices.getCurrentUserInfo();
    yield put({
      type: SUCCESS(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO), errors: e.errors });
  }
}

export default function* masterDataSaga() {
  yield all([
    takeLatest(REQUEST(MASTER_DATA_ACTION.GET_CAREER_LIST), getCareerListSaga),
    takeLatest(REQUEST(MASTER_DATA_ACTION.GET_PROVINCE_LIST), getProvinceListSaga),
    takeLatest(REQUEST(MASTER_DATA_ACTION.GET_CURRENT_TIME), getCurrentTimeSaga),
    takeLatest(REQUEST(MASTER_DATA_ACTION.GET_CHECK_VIDEO), getCheckVideoSaga),
    takeLatest(REQUEST(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO), getCurrentUserInfo),
  ]);
}
