// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const UploadScoringContainer = styled.div`
  padding: 24px 32px 0;
`;

export const UploadScoringContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0 0 80px;
`;

export const UploadScoringInfo = styled.div`
  display: flex;

  & > p:first-child {
    min-width: 80px;
  }
`;

export const DownloadFileButton = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 42px;
  height: 42px;
  cursor: pointer;
`;
