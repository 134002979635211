// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from '../../constant';
import moment from 'moment';

export function getTipList(params) {
  const newParams = {
    updatedAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    updatedAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    categoryIdEq: params?.category,
    nameCont: (params.name || '').trim(),
    tagIdEq: params?.tag,
    page: params?.page,
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.TIP_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getTipDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TIP_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function createTip(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TIP_ACTION.CREATE_TIP),
    payload: params,
  };
}

export function updateTip(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TIP_ACTION.UPDATE_TIP),
    payload: params,
  };
}
