// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_COMPANY,
  REQUEST,
  SUCCESS,
  FAILURE,
  MODAL_ACTION,
} from 'src/redux/constant';
import { STATUS_USER } from './../../../constants/common';

const initialState = {
  profileDetail: {
    data: {
      avatarUrl: '',
      status: ""
    },
    meta: {},
    load: false,
    errors: [],
  },
  changeRole: {
    errors: [],
  },
  joinedCourses: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  listTestsStudent: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  isResetSucess: false
};

export type CompanyProfileReducer = Readonly<typeof initialState>;

export default (state: CompanyProfileReducer = initialState, action: any): CompanyProfileReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL): {
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      profileDetail: {
        data: data.user,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE): {
    const { data } = action.payload;
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        data: {
          ...state.profileDetail.data,
          ...data,
        },
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE): {
    const { errors } = action;
    return {
      ...state,
      changeRole: {
        ...state.changeRole,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.CLEAR_UPDATE_ROLE_ERRORS): {
    return {
      ...state,
      changeRole: {
        ...state.changeRole,
        errors: [],
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_JOINED_COURSES): {
    return {
      ...state,
      joinedCourses: {
        ...state.joinedCourses,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.GET_JOINED_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      joinedCourses: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.PROFILE_ACTION.GET_JOINED_COURSES): {
    const { errors } = action;
    return {
      ...state,
      joinedCourses: {
        ...state.joinedCourses,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT): {
    return {
      ...state,
      listTestsStudent: {
        ...state.listTestsStudent,
        load: true
      }
    }
  }
  case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT): {
    const { data, meta } = action.payload;
    return {
      ...state,
      listTestsStudent: {
        ...state.listTestsStudent,
        load: false,
        data: data.testsUsers,
        meta
      }
    }
  }
  case FAILURE(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT): {
    const { errors } = action;
    return {
      ...state,
      listTestsStudent: {
        ...state.listTestsStudent,
        errors,
        load: false
      }
    }
  }

  case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY): {
    return {
      ...state,
      isResetSucess: true,
    }
  }
  case REQUEST(MODAL_ACTION.CLOSE): {
    return {
      ...state,
      isResetSucess: false,
    }
  }

  case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.UNLOCK_USER): {
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        data: {
          ...state.profileDetail.data,
          status: STATUS_USER.ACTIVE
        },
      },
    }
  }

  default:
    return state;
}
};
