// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class StudentVideoServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  saveWatchedTime(params) {
    const { courseId, requestData } = params;
    return this.restClient.post(`/student/courses/${courseId}/videos_users`, requestData);
  }
}
