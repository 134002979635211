// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const ConfirmModalContainer = styled.div`
  padding: 48px 160px;
`;

export const ConfirmResetFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 24px;
`;
