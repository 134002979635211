// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Route } from 'react-router-dom';

import FooterLayout from '../Footer';

import brandLogo from 'src/assets/images/common/brand-logo.svg';
import brandName from 'src/assets/images/common/brand-name.svg';

import * as Style from './styles';

const CheckVideoLayout = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <Style.CheckVideoLayoutContainer>
        <Style.HeaderContainer>
          <Style.BrandContent>
            <div>
              <img src={brandLogo} alt="" height="31px" width="auto" />
              <img src={brandName} style={{ marginLeft: 8 }} alt="" />
            </div>
          </Style.BrandContent>
        </Style.HeaderContainer>
        <Style.MainLayout>
          <Component {...matchProps} />
        </Style.MainLayout>
        <FooterLayout isRegister role="student" />
      </Style.CheckVideoLayoutContainer>
    )}
  />
);

export default CheckVideoLayout;
