// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';

export const TestItemsContainer = styled.div`
  margin-bottom: 40px;

  & .ant-collapse-header {
    padding: 9px 16px !important;
    min-height: 50px !important;
    height: unset !important;
  }
`;

export const CollapseHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CollapseItemContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: white;
  padding-right: 20px;

  @media (max-width: 428px) {
    padding-right: 0px;
  }

  ${(props) => props.isChild
    && css`
      background-color: #fafafa;
      padding: 12px 16px;
      padding-right: 36px;
      border-top: 1px solid ${props.theme.colors.secondaryBorder};
    `}
`;

export const CollapseItemContent = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: ${(props) => props.align || 'center'};
  width: ${(props) => props.width || 'auto'};

  & > button {
    min-width: 76px;
  }

  ${(props) => props.isActionButton
    && css`
      display: flex;
      justify-content: flex-start;
    `}

  @media (max-width: 428px) {
    flex-direction: column;
    justify-content: center;

    .show-result {
      font-size: 14px;
      font-weight: 300;
      color: #0DA2FE !important;
    }

    .join-test {
      font-size: 14px;
      font-weight: 700;
    }
  }
`;

export const NoHistoryData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: #FAFAFA;
`;

export const GroupButton = styled.div`
  width: 256px;
  display: flex;
  justify-content: ${(props) => props.isFlexEnd ? 'flex-end' : 'flex-start'};;
`;

export const TableDesktop = styled.div`
  display: block;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const TableMobile = styled.div`
  display: none;

  @media (max-width: 428px) {
    display: block;
  }
`;

export const TextHeader = styled(Text)`
  @media (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const TextHeaderTable = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`;
