// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const PROFILE_ACTION = {
  GET_PROFILE_DETAIL: 'ADMIN_SERVICE/PROFILE_ACTION/GET_PROFILE_DETAIL',
  UPDATE_USER_ROLE: 'ADMIN_SERVICE/PROFILE_ACTION/UPDATE_USER_ROLE',
  CLEAR_UPDATE_ROLE_ERRORS: 'ADMIN_SERVICE/PROFILE_ACTION/CLEAR_UPDATE_ROLE_ERRORS',
  UPDATE_USER_EMAIL: 'ADMIN_SERVICE/PROFILE_ACTION/UPDATE_USER_EMAIL',
  CLEAR_UPDATE_EMAIL_USER_ERRORS: 'ADMIN_SERVICE/PROFILE_ACTION/CLEAR_UPDATE_EMAIL_USER_ERRORS',
  UPDATE_USER_INFO: 'ADMIN_SERVICE/PROFILE_ACTION/UPDATE_USER_INFO',
  GET_JOINED_COURSES: 'ADMIN_SERVICE/PROFILE_ACTION/GET_JOINED_COURSES',
  UPDATE_COURSES_DURATION: 'ADMIN_SERVICE/PROFILE_ACTION/UPDATE_COURSES_DURATION',

  GET_TEST_STUDENT: 'ADMIN_SERVICE/PROFILE_ACTION/GET_TEST_STUDENT',
  RESET_TEST:'ADMIN_SERVICE/PROFILE_ACTION/RESET_TEST',
  GET_COURSE_COMPANY: 'ADMIN_SERVICE/PROFILE_ACTION/GET_COURSE_COMPANY',
  UPDATE_COURSE_MANAGER: 'ADMIN_SERVICE/PROFILE_ACTION/UPDATE_COURSE_MANAGER',
  UNLOCK_USER: 'ADMIN_SERVICE/PROFILE_ACTION/UNLOCK_USER'
};
