// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import { STUDENT, SUCCESS, FAILURE, REQUEST } from '../../constant';

import { studentStatisticService } from 'src/services';

function* getStatisticCoursesSaga() {
  try {
    const result = yield studentStatisticService.getStatisticCourses();
    yield put({
      type: SUCCESS(STUDENT.STATISTIC_ACTION.GET_STATISTIC_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.STATISTIC_ACTION.GET_STATISTIC_COURSES), errors: e.errors });
  }
}

function* getCurrentWeekSaga() {
  try {
    const result = yield studentStatisticService.getCurrentWeek();
    yield put({
      type: SUCCESS(STUDENT.STATISTIC_ACTION.GET_CURRENT_WEEK),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.STATISTIC_ACTION.GET_CURRENT_WEEK), errors: e.errors });
  }
}

function* getLearningHistoriesSaga(action) {
  try {
    const result = yield studentStatisticService.getLearningHistories(action.payload);
    yield put({
      type: SUCCESS(STUDENT.STATISTIC_ACTION.GET_LEARNING_HISTORIES),
      payload: {
        data: result.data,
        meta: result.meta,
        more: !!action.payload.more,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.STATISTIC_ACTION.GET_LEARNING_HISTORIES), errors: e.errors });
  }
}

function* getLearningTimesSaga(action) {
  try {
    const result = yield studentStatisticService.getLearningTimes(action.payload);
    yield put({
      type: SUCCESS(STUDENT.STATISTIC_ACTION.GET_LEARNING_TIMES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.STATISTIC_ACTION.GET_LEARNING_TIMES), errors: e.errors });
  }
}

export default function* studentStatisticSaga() {
  yield all([
    takeLatest(REQUEST(STUDENT.STATISTIC_ACTION.GET_STATISTIC_COURSES), getStatisticCoursesSaga),
    takeLatest(REQUEST(STUDENT.STATISTIC_ACTION.GET_CURRENT_WEEK), getCurrentWeekSaga),
    takeLatest(REQUEST(STUDENT.STATISTIC_ACTION.GET_LEARNING_HISTORIES), getLearningHistoriesSaga),
    takeLatest(REQUEST(STUDENT.STATISTIC_ACTION.GET_LEARNING_TIMES), getLearningTimesSaga),
  ]);
}
