// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class StudentSurveyServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  finishSurvey(params) {
    const { courseId, unitVersionId, groupId, chapterId } = params;
    const requestParams = {
      groupId,
      chapterId,
    };

    return this.restClient.put(`/student/courses/${courseId}/unit_versions/${unitVersionId}?`, requestParams);
  }
}
