// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import Text from 'antd/lib/typography/Text';

export const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleModal = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`;
