// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';

export function getTeacherList() {
  return {
    type: REQUEST(ADMIN_SERVICE.TEACHER_ACTION.GET_LIST),
  };
}

export function createTeacher(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEACHER_ACTION.CREATE_TEACHER),
    payload,
  };
}

export function updateTeacher(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEACHER_ACTION.UPDATE_TEACHER),
    payload: params,
  };
}

export function deleteTeacher(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEACHER_ACTION.DELETE_TEACHER),
    payload: params,
  };
}
