// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';

import { MainContainer, SidebarContent, MainContent } from 'src/components/layouts/ContentLayout';
import { CourseSidebar } from 'src/components/layouts/Sidebar';

import VideoPlayer from './components/VideoPlayer';
import TestExplain from './components/TestExplain';
import TestingContent from './components/TestingContent';
import SurveyOverview from './components/SurveyOverview';
import TipOverview from './components/TipOverview';

import {
  getStudentCourseDetail as getStudentCourseDetailAction,
  getStudentUnitDetail as getStudentUnitDetailAction,
  getStudentGroupDetail as getStudentGroupDetailAction,
  setCollapseData as setStudentCollapseDataAction,
  selectQuestion as selectQuestionAction,
  clearSidebarCourse as clearSidebarCourseAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import { StudentPath } from 'src/constants/routerConstants';
import { PAGE_TITLE, StudentTitle } from 'src/constants/pageTitle';
import * as Style from './styles'
import { Text } from 'src/components/styles';
import NoData from 'src/components/layouts/NoData';
import chevronTopIcon from 'src/assets/images/videoPlayer/chevron-top-icon.svg';

interface ICourseViewPageProps extends StateProps, DispatchProps {
  getStudentUnitDetail(params): void;
  getStudentGroupDetail(params): void;
  setCollapseData(params): void;
  selectQuestion(params): void;
  clearSidebarCourse(): void;
  showModal(params): void;
  closeModal(): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  questionIdSelected: number | string;
  courseDetail: {
    data: {
      id: number,
      name: string,
      studentProgressPercentage: number,
      groups: {
        id: number;
        name: string;
        chapters: {
          id: string;
          faqs: {
            id: number;
            name: string;
            faqContents: {
              question: string;
              answer: string;
            }[];
          }[];
        }[];
        totalUnits: string;
        totalVideosTime: string;
      }[];
    },
  };
  testQuestions: {
    data: {
      unitVersionId: number;
      questions: {
        id: number;
        name: string;
        content: string;
        questionType: string;
        questionOptions: {
          id: number;
          content: string;
        }[];
      }[];
    };
  };
  courseTopDetail: {
    data: {
      groups: {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          totalUnits: number;
          totalVideosTime: number;
        }[];
      }[];
    };
    load: boolean;
  };
}

const CourseViewPage: React.FC<ICourseViewPageProps> = ({
  getStudentUnitDetail,
  getStudentGroupDetail,
  getStudentCourseDetail,
  setCollapseData,
  selectQuestion,
  clearSidebarCourse,
  showModal,
  closeModal,
  collapseData,
  questionIdSelected,
  courseDetail,
  testQuestions,
  courseTopDetail
}) => {
  const [isShowSidebar, setIsShowSidebar] = useState<boolean>(true);
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);

  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[2];
  const unitVersionId = pathnameSplit[4];
  const groupId = getSearchParams('group');
  const chapterId = getSearchParams('chapter');

  useEffect(() => {
    setIsMobileDevice(window.innerWidth < 428)
    window.addEventListener('resize', () => {
      setIsMobileDevice(window.innerWidth < 428)
    });
    return () => window.removeEventListener('resize', () => {});
  }, [])

  useEffect(() => {
    document.title = PAGE_TITLE(StudentTitle.CourseView);

    getStudentGroupDetail({
      requestData: {
        courseId,
        groupId,
      },
    });
    getStudentCourseDetail({ id: courseId });
    return () => {
      clearSidebarCourse();
      closeModal();
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(courseDetail.data.groups)) {
      getStudentUnitDetail({
        courseId,
        unitVersionId,
        groupId,
        chapterId,
      });
    }
  }, [courseId, unitVersionId, groupId, chapterId, courseDetail.data.groups]);

  function renderFAQCollapse() {
    const group = courseDetail?.data?.groups?.filter(item => item.id.toString() === groupId)
    const chapter = group[0]?.chapters?.filter(item => item.id.toString() === chapterId)
    const faqList = !isEmpty(chapter) ? chapter[0].faqs : []
    if (isEmpty(faqList)) return (
      <NoData
        message="ー"
        style={{
          justifyContent: 'flex-start',
          paddingLeft: 20,
        }}
      />
    );
    return faqList.map((faqCollapse, faqCollapseIndex) => (
      <Collapse.Panel
        key={`faq-${faqCollapseIndex}`}
        header={
          <Text white w6>{faqCollapse?.name}</Text>
        }
        className="custom-question-collapse"
      >
        {renderFAQContents(faqCollapse?.faqContents)}
      </Collapse.Panel>
    ));
  }

  function renderFAQContents(faqContents) {
    return faqContents.map((faqContent, faqContentIndex) => (
      <Style.AnswerContainer key={`faq-content-${faqContentIndex}`}>
        <Text multiLine>{faqContent.question}</Text>
        <Style.AnswerContent>
          <Text multiLine w6>{faqContent.answer}</Text>
        </Style.AnswerContent>
      </Style.AnswerContainer>
    ));
  }

  const executeScrollToFAQTitle = () => {
    const element = document.getElementsByClassName('faq-collapse-header-mobile');
    if (element[0]) {
      element[0].scrollIntoView()
    }
  }

  return (
    <MainContainer isStudent isFlexColumn={isMobileDevice}>
      <SidebarContent isCollapse={!isShowSidebar} isCourseView>
        <CourseSidebar
          isShowSidebar={isShowSidebar}
          setIsShowSidebar={setIsShowSidebar}
          getGroupDetail={getStudentGroupDetail}
          setCollapseData={setCollapseData}
          selectQuestion={selectQuestion}
          showModal={showModal}
          closeModal={closeModal}
          collapseData={collapseData}
          questionIdSelected={questionIdSelected}
          courseDetail={courseDetail}
          testQuestions={testQuestions}
          currentUnitId={unitVersionId}
          isMobileDevice={isMobileDevice}
          courseTopDetail={courseTopDetail}
        />
      </SidebarContent>
      <MainContent sidebarView={!isShowSidebar} isCourseView>
        <Switch>
          <Route exact path={StudentPath.VideoPlayer} component={VideoPlayer} />
          <Route exact path={StudentPath.TestExplain} component={TestExplain} />
          <Route
            exact
            path={StudentPath.TestingContent}
            render={(props) => <TestingContent {...props} isMobileDevice={isMobileDevice} />}
          />
          <Route exact path={StudentPath.SurveyOverview} component={SurveyOverview} />
          <Route exact path={StudentPath.TipOverview} component={TipOverview} />
        </Switch>
      </MainContent>
        {pathnameSplit[3] === "video" && (
          <Style.FAQContent>
            <Style.FAQTitle>
              <Text white lg w6> FAQ </Text>
              <img src={chevronTopIcon} onClick={executeScrollToFAQTitle}/>
            </Style.FAQTitle>
            <Style.CollapseContainer>
              <Collapse
                key={`FAQs-${chapterId}`}
                className="faq-collapse-mobile"
                bordered={false}
                defaultActiveKey={[]}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <MinusOutlined style={{ color: "white" }} />
                  ) : (
                    <PlusOutlined style={{ color: "white" }} />
                  )
                }
                expandIconPosition="right"
              >
                {renderFAQCollapse()}
              </Collapse>
            </Style.CollapseContainer>
          </Style.FAQContent>
        )}
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { collapseData, questionIdSelected } = state.commonReducer;
  const { courseDetail, courseTopDetail } = state.studentCourseReducer;
  const { testQuestions } = state.studentTestReducer;
  return {
    collapseData,
    questionIdSelected,
    courseDetail,
    testQuestions,
    courseTopDetail
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentCourseDetail: (params) => dispatch(getStudentCourseDetailAction(params)),
  getStudentUnitDetail: (params) => dispatch(getStudentUnitDetailAction(params)),
  getStudentGroupDetail: (params) => dispatch(getStudentGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setStudentCollapseDataAction(params)),
  selectQuestion: (params) => dispatch(selectQuestionAction(params)),
  clearSidebarCourse: () => dispatch(clearSidebarCourseAction()),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseViewPage);
