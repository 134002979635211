// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const COURSE_ACTION = {
  GET_GROUP: 'STUDENT/COURSE_ACTION/GET_GROUP',
  GET_DETAIL: 'STUDENT/COURSE_ACTION/GET_DETAIL',
  GET_TOP_GROUP_DETAIL: 'STUDENT/COURSE_ACTION/GET_TOP_GROUP_DETAIL',
  CREATE_QUESTION: 'STUDENT/CREATE_QUESTION',
  GET_DETAIL_STATUS: 'STUDENT/COURSE_ACTION/GET_DETAIL_STATUS',
  CLEAR_SIDEBAR_COURSE: 'STUDENT/COURSE_ACTION/CLEAR_SIDEBAR_COURSE',
  UPDATE_COURSE_PROGRESS: 'STUDENT/COURSE_ACTION/UPDATE_COURSE_PROGRESS',
  GET_RECOMMEND_DATE: 'STUDENT/COURSE_ACTION/GET_RECOMMEND_DATE',
};
