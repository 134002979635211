// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

export const initialState = {
  unitList: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
  unitDetail: {
    data: {
      currentVersion: '',
      teachers: [],
      tags: [],
      item: {},
      lastUpdater: {},
    },
    meta: {},
    load: false,
    errors: [],
  },
  unitVersions: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  unitItems: {
    data: [] as object[],
    meta: {},
    type: '',
    load: false,
    errors: [],
  },
  courseContentUnits: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  overviewUnitDetail: {
    data: {
      isDone: false,
      unit: {},
      item: {
        usedAttemptsCount: 0,
      },
    },
    meta: {},
    load: false,
    errors: [],
  },
};

export type UnitReducer = Readonly<typeof initialState>;

export default (state: UnitReducer = initialState, action: any): UnitReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_LIST): {
    return {
      ...state,
      unitList: {
        ...state.unitList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      unitList: {
        data: data.units,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      unitList: {
        ...state.unitList,
        errors,
      },
    };
  }
  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.CLEAR_LIST): {
    return {
      ...state,
      unitList: {
        data: [],
        meta: {},
        load: false,
        errors: [],
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      unitDetail: {
        data: data.unit,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.CLEAR_UNIT_DETAIL): {
    return {
      ...state,
      unitDetail: {
        data: {
          currentVersion: '',
          teachers: [],
          tags: [],
          item: {},
          lastUpdater: {},
        },
        meta: {},
        load: false,
        errors: [],
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.UPDATE): {
    const { data, meta } = action.payload;
    return {
      ...state,
      unitDetail: {
        data: data.unit,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.UPDATE): {
    const { errors } = action;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.CREATE): {
    return {
      ...state,
      unitDetail: {
        data: {
          currentVersion: '',
          teachers: [],
          tags: [],
          item: {},
          lastUpdater: {},
        },
        meta: [],
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.CREATE): {
    const { errors } = action;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_ITEMS): {
    return {
      ...state,
      unitItems: {
        ...state.unitItems,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_ITEMS): {
    const { data, meta, more } = action.payload;
    if (more) {
      return {
        ...state,
        unitItems: {
          data: [
            ...state.unitItems.data,
            ...data[`${data.type}s`],
          ],
          meta,
          type: data.type,
          load: false,
          errors: [],
        },
      };
    }
    return {
      ...state,
      unitItems: {
        data: data[`${data.type}s`],
        meta,
        type: data.type,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_ITEMS): {
    const { errors } = action;
    return {
      ...state,
      unitItems: {
        ...state.unitItems,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.SELECT_VERSION): {
    const { currentVersion, item } = action.payload;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        data: {
          ...state.unitDetail.data,
          currentVersion,
          item,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.SELECT_VERSION): {
    const { errors } = action;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.SELECT_ITEM): {
    const { item } = action.payload;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        data: {
          ...state.unitDetail.data,
          item,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.SELECT_ITEM): {
    const { errors } = action;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION): {
    const { data } = action.payload;
    return {
      ...state,
      unitVersions: {
        ...state.unitVersions,
        errors: [],
      },
      unitDetail: {
        ...state.unitDetail,
        data: {
          ...state.unitDetail.data,
          currentVersion: data.unitVersion.version,
          item: data.unitVersion.item,
        },
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION): {
    const { errors } = action;
    return {
      ...state,
      unitVersions: {
        ...state.unitVersions,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_VERSIONS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      unitVersions: {
        data: data.unitVersions,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_VERSIONS): {
    const { errors } = action.payload;
    return {
      ...state,
      unitVersions: {
        ...state.unitVersions,
        errors,
      },
    };
  }
  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.CLEAR_VERSIONS): {
    return {
      ...state,
      unitVersions: {
        data: [],
        meta: {},
        load: false,
        errors: [],
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_COURSE_CONTENT_UNITS): {
    const { data, meta, more } = action.payload;
    if (more) {
      return {
        ...state,
        courseContentUnits: {
          data: [
            ...state.courseContentUnits.data,
            ...data.units,
          ],
          meta,
          load: false,
          errors: [],
        },
      };
    }
    return {
      ...state,
      courseContentUnits: {
        data: data.units,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_COURSE_CONTENT_UNITS): {
    const { errors } = action;
    return {
      ...state,
      courseContentUnits: {
        ...state.courseContentUnits,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL): {
    return {
      ...state,
      overviewUnitDetail: {
        ...state.overviewUnitDetail,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      overviewUnitDetail: {
        data: data.unitVersion,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      overviewUnitDetail: {
        ...state.overviewUnitDetail,
        load: false,
        errors,
      },
    };
  }

  default:
    return state;
  }
};
