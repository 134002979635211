//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Space } from 'antd';
import { isNumber } from 'lodash/lang';

import { CardWrapper } from 'src/components/layouts/ContentLayout';

import { convertSecondTime } from 'src/utils/dateTime';

import { Text, SkeletonLoading } from 'src/components/styles';

import * as Style from './styles';

interface IAttendanceStatusProps {
  attendanceStatus: {
    data: {
      usersBehindSchedule: {
        usersBehindSchedulePercentage: number;
        total: number;
      };
      usersOnSchedule: {
        usersOnSchedulePercentage: number;
        total: number;
      };
      coursesConsumptionPercentage: number;
      avgLearningTime: number;
      joinTestsPercentage: number;
      passedFinishTest: {
        passedFinishTestsPercentage: number;
        total: number;
      };
    };
    load: boolean;
  };
}

const AttendanceStatus: React.FC<IAttendanceStatusProps> = ({
  attendanceStatus,
}) => {
  const { data } = attendanceStatus

  return (
    <>
      <Text headerText xxl w7 style={{ margin: "24px 0 16px" }} >
        受講状況
      </Text>
      <Style.OverviewContainer>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCard>
                <Text headerText md w7>
                  遅延ユーザー
                </Text>
                {attendanceStatus.load ? (
                  <div>
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      height={34}
                    />
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      style={{ marginTop: 8 }}
                    />
                  </div>
                ) : (
                  <>
                    <Space>
                      <Style.TextTitle headerText w6 xxl>
                        {isNumber(
                          data.usersBehindSchedule
                            ?.usersBehindSchedulePercentage
                        )
                          ? `${data.usersBehindSchedule.usersBehindSchedulePercentage}%`
                          : "ー"}
                      </Style.TextTitle>
                    </Space>
                    <Text headerText md w5>
                    （{isNumber(
                        data.usersBehindSchedule?.total
                      )
                        ? `${data.usersBehindSchedule.total}人`
                        : "ー"}）
                    </Text>
                  </>
                )}
              </Style.OverviewCard>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCard>
                <Text headerText md w7>
                  完了ユーザー
                </Text>
                {attendanceStatus.load ? (
                  <div>
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      height={34}
                    />
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      style={{ marginTop: 8 }}
                    />
                  </div>
                ) : (
                  <>
                    <Space>
                      <Style.TextTitle headerText w6 xxl>
                        {isNumber(
                          data.usersOnSchedule
                            ?.usersOnSchedulePercentage
                        )
                          ? `${data.usersOnSchedule.usersOnSchedulePercentage}%`
                          : "ー"}
                      </Style.TextTitle>
                    </Space>
                    <Text headerText md w5>
                    （{isNumber(data.usersOnSchedule?.total)
                        ? `${data.usersOnSchedule.total}人`
                        : "ー"}）
                    </Text>
                  </>
                )}
              </Style.OverviewCard>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCard>
                <Text headerText md w7>
                  コース消化率
                </Text>
                {attendanceStatus.load ? (
                  <div>
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      height={34}
                    />
                  </div>
                ) : (
                  <>
                    <Style.TextTitle headerText w6 xxl>
                      {isNumber(
                        data?.coursesConsumptionPercentage
                      )
                        ? `${data.coursesConsumptionPercentage}%`
                        : "ー"}
                    </Style.TextTitle>
                  </>
                )}
              </Style.OverviewCard>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCard>
                <Text headerText md w7>
                  平均学習時間
                </Text>
                {attendanceStatus.load ? (
                  <div>
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      height={34}
                    />
                  </div>
                ) : (
                  <>
                    <Style.TextTitle headerText w6 xxl>
                      {isNumber(data?.avgLearningTime)
                        ? convertSecondTime(
                            data?.avgLearningTime,
                            "H時間m分"
                          )
                        : "ー"}
                    </Style.TextTitle>
                  </>
                )}
              </Style.OverviewCard>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCard>
                <Text headerText md w7>
                  テスト受講率
                </Text>
                {attendanceStatus.load ? (
                  <div>
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      height={34}
                    />
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      style={{ marginTop: 8 }}
                    />
                  </div>
                ) : (
                  <Space>
                    <Style.TextTitle headerText w6 xxl>
                      {isNumber(
                        data.joinTestsPercentage
                      )
                        ? `${data.joinTestsPercentage}%`
                        : "ー"}
                    </Style.TextTitle>
                  </Space>
                )}
              </Style.OverviewCard>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCard>
                <Text headerText md w7>
                  修了テスト合格率
                </Text>
                {attendanceStatus.load ? (
                  <div>
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      height={34}
                    />
                    <SkeletonLoading
                      active
                      paragraph={false}
                      title={{ width: 120 }}
                      style={{ marginTop: 8 }}
                    />
                  </div>
                ) : (
                  <>
                    <Space>
                      <Style.TextTitle headerText w6 xxl>
                        {isNumber(
                          data.passedFinishTest
                            ?.passedFinishTestsPercentage
                        )
                          ? `${data.passedFinishTest.passedFinishTestsPercentage}%`
                          : "ー"}
                      </Style.TextTitle>
                    </Space>
                    <Text headerText md w5>
                      {isNumber(data.passedFinishTest?.total)
                        && `（${data.passedFinishTest.total}人）`}
                    </Text>
                  </>
                )}
              </Style.OverviewCard>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
      </Style.OverviewContainer>
      <Text md headerText>
        ※ 遅延ユーザーとは、進捗ギャップがマイナスのユーザーを指します。
      </Text>
    </>
  );
};

export default AttendanceStatus;
