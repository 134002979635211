// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const QuestionModifyAction = styled.div`
  display: flex;
  justify-content: ${(props) => props.hasEdit ? 'space-between' : 'flex-end'};
  align-items: center;
  margin-top: 16px;

  & button {
    width: 100px;
  }
`;

export const QuestionDetailContainer = styled.div`
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
`;
