// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import loginBackground from 'src/assets/images/login/login-background.png';
import { Text } from 'src/components/styles';
import { Button, Input } from 'antd';

export const LoginContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: inherit;
  background-image: url(${loginBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 428px) {
    flex-direction: column;
    background-image: none;
    background-color: #DCA40B;
  }
`;

export const LoginBackground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: inherit;

  ${(props) => props.isAdminService
    && css`
      background-color: ${props.theme.colors.adminBackground};
    `}

  .brand {
    margin-top: 26px;
  }

  @media (max-width: 428px) {
    position: static ;
    width: 100%;
    height: 30vh;
    padding-top: 60px;

    .logo {
      width: 80px;
    }

    .brand {
      width: 110px;
      margin-top: 16px;
    }
  }

  @media (max-height: 780px) and (max-width: 428px) {
    height: 225px;
  }
`;

export const LoginContent = styled.div`
  width: 50%;
  height: inherit;

  @media (max-width: 428px) {
    width: 100%;
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 42px);

  @media (max-width: 428px) {
    height: 40vh;
    padding: 0 22px;
  }

  @media (max-height: 780px) and (max-width: 428px) {
    height: 380px;
  }
`;

export const LoginField = styled.div`
  width: 460px;
  transform: translate(50px, 0);

  & .ant-form-item-control-input {
    min-height: 22px;
  }

  @media (max-width: 428px) {
    width: 100%;
    padding: 26px;
    background-color: #fff;
    transform: none;
  }
`;

export const CopyrightContent = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .copyright {
    display: flex;

    & > span {
      margin-right: 5px;
      font-size: 21px;
    }
  }

  @media (max-width: 428px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 30vh;
    margin-bottom:0;

    .copyright {
      margin-bottom: 26px;
      color: #fff;
    }
  }

  @media (max-height: 780px) and (max-width: 428px) {
    height: 205px;
  }
`;

export const ForgetPassword = styled.div`
  margin-bottom: 22px;
  width: 360px;
  text-align: right;
`;

export const RoleLoginLink = styled.div`
  margin-top: 20px;
  width: 360px;
  text-align: right;
`;

export const Link = styled.a`
  max-height: 16px;
  font-size: 15px;
  color: #0DA2FE;

  &:hover {
    color: #0DA2FE;
    opacity: 0.75;
  }

  ${(props) => props.sm
    && css`
      font-size: 12px;
    `}

  @media only screen and (max-width: 428px) {
    font-size: 14px;
  }
`;

export const GroupCheckbox = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterLinks = styled.div`
  position: absolute;
  width: fit-content;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);

  @media (max-width: 428px) {
    display:none
  }
`;

export const FooterLinksMobile = styled.div`
  display: none;
  margin-bottom: 6px;

  @media (max-width: 428px) {
    display: block;
  }
`;

export const CompanyLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const PolicyLink = styled.div`
  display: flex;
  justify-content: center;
`;

export const FooterItem = styled.div`
  cursor: pointer;

  &:hover {
    opacity: 0.75
  }

  &:first-child {
    border-left: none;
  }
`;

export const TitleLogin = styled(Text)`

  @media (max-width: 428px) {
    display: none;
  }
`;

export const ButtonLogin = styled(Button)`
  width: 360px;
  margin-bottom: 24px;

  @media (max-width: 428px) {
    width: 100%;
  }
`;

export const InputEmail = styled(Input)`
  width: 360px;

  @media (max-width: 428px) {
    width: 100%;
  }
`;

export const InputPassWord = styled(Input.Password)`
  width: 360px;

  @media (max-width: 428px) {
    width: 100%;
  }
`;

export const ItemLink = styled(Text)`
  font-weight: 600;

  @media (max-width: 428px) {
    font-weight: 400;
  }
`;
