// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Space,
  Button,
} from 'antd';
import { snakeCase } from 'lodash/string';
import { isNumber, isEmpty } from 'lodash/lang';
import { omit } from 'lodash/object';

import history from 'src/utils/history';

import QuestionList from './components/QuestionList';
import ModifyAnswer from './components/ModifyAnswer';
import ModifyQuestion from './components/ModifyQuestion';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import { QuestionStorage } from 'src/utils/localStorge';

import {
  getQuestionDetail as getQuestionDetailAction,
  createQuestion as createQuestionAction,
  updateQuestion as updateQuestionAction,
  deleteQuestion as deleteQuestionAction,
  moveQuestion as moveQuestionAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';
import { QUESTION_SCORE } from 'src/constants/common';
import { ServicePath } from 'src/constants/routerConstants';

import * as Style from './styles';

interface ITestContentProps extends StateProps, DispatchProps {
  getQuestionDetail(params): void;
  createQuestion(params): void;
  updateQuestion(params): void;
  deleteQuestion(params): void;
  moveQuestion(params): void;
  closeModal(): void;
  showModal(params): void;
  testId: string;
  hasBeenUsed: boolean;
  questionList: {
    data: {
      id: number;
      name: string;
      questionType: string;
      score: number;
    }[];
  };
  questionDetail: any;
  isDisableRequest: boolean
}

const TestContent: React.FC<ITestContentProps> = ({
  getQuestionDetail,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  moveQuestion,
  showModal,
  testId,
  hasBeenUsed,
  questionDetail,
  questionList,
  closeModal,
  isDisableRequest
}) => {
  const [questionIdSelected, setQuestionIdSelected] = useState<number | string>('create');
  const [questionSelectedIndex, setQuestionSelectedIndex] = useState<number>(questionList.data.length + 1);
  const [fileImage, setFileImage] = useState<any>();
  const [answerType, setAnswerType] = useState<string>('option_type');
  const [imageError, setImageError] = useState<string>('');
  const [imageURL, setImageURL] = useState<any>(questionDetail.data.image?.url);
  const [previewImageUrl, setPreviewImageUrl] = useState<string>('');
  const [deletedAnswers, setDeletedAnswers] = useState<object[]>([]);
  const [answerCount, setAnswerCount] = useState<number>(2);
  const [isScored, setIsScored] = useState<string>('');
  const [editorErrors, setEditorErrors] = useState<string>("");

  const [questionForm] = Form.useForm();
  const questionFormData = new FormData();

  let correctAnswer = '';
  if (questionDetail?.data.questionType === 'option_type') {
    questionDetail?.data.questionOptions?.forEach((questionOption, questionOptionIndex) => {
      if (questionOption.isRight) correctAnswer = `answer${questionOptionIndex + 1}`;
    });
  }

  const questionInitialFormValues = {
    name: questionDetail?.data.name,
    questionType: questionDetail?.data.questionType,
    content: questionDetail?.data.content,
    image: questionDetail?.data.image?.url,
    ...questionDetail?.data.questionType === 'option_type' && { optionScore: questionDetail?.data.score },
    ...questionDetail?.data.questionType === 'input_type' && { inputScore: questionDetail?.data.score },
    ...questionDetail?.data.questionType === 'upload_file_type' && { uploadScore: questionDetail?.data.score },
    rightInput : questionDetail?.data.rightInput,
    isScored: questionDetail?.data.isScored?.toString() || 'true',
    ...questionDetail?.data.questionType === 'option_type' && { questionOptions: questionDetail?.data.questionOptions },
    correctAnswer,
  };

  useEffect(() => {
    closeModal();
  })

  useEffect(() => {
    setQuestionSelectedIndex(questionList.data.length + 1)
  },[questionList])

  useEffect(() => {
    if (isNumber(questionIdSelected)) {
      getQuestionDetail({ testId, questionId: questionIdSelected });
    } else {
      setAnswerCount(2);
      setIsScored('true');
    }
    removeImage();
    setDeletedAnswers([]);
  }, [questionIdSelected]);

  useEffect(() => {
    if (questionIdSelected) {
      questionForm.resetFields();
    }
  }, [questionInitialFormValues.name, questionIdSelected]);

  useEffect(() => {
    setAnswerType(questionDetail?.data.questionType || 'option_type');
    setImageURL(questionDetail.data.image?.url || '');
    if (questionDetail.data.questionType === 'option_type') {
      setAnswerCount(questionDetail.data.questionOptions.length);
    } else {
      setAnswerCount(2);
    }
    if (questionDetail.data.questionType === 'upload_file_type') {
      setIsScored(questionDetail.data.isScored?.toString());
    } else {
      setIsScored('true');
    }
    if (questionDetail.data.length === 0) {
      setQuestionIdSelected('create');
    }
  }, [questionDetail.data]);

  function removeImage() {
    setFileImage(null);
    questionForm.setFieldsValue({ image: '' });
    setImageError('');
    setImageURL('');
    setPreviewImageUrl('');
    (document.getElementById('uploadFileImageField') as HTMLInputElement).value = '';
  }

  function resetAnswerField(type) {
    if (type === 'input_type') {
      questionForm.setFieldsValue({
        questionOptions: [
          { content: '' },
          { content: '' },
        ],
        correctAnswer: '',
      });
    } else {
      questionForm.setFieldsValue({ rightInput: '' });
    }
  }

  function handleConfirmDeleteQuestion() {
    const modalProps = {
      isConfirm: true,
      modalData: {
        message: 'この質問を削除してもよろしいですか？',
        title: '削除確認',
      },
      modalAction: {
        confirm: () => deleteQuestion({ testId, questionId: questionIdSelected }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function createFormData(values, answerKeys) {
    Object.keys(values).forEach((key) => {
      if (answerKeys.includes(key) && values[key]) {
        questionFormData.append('question[question_options][][content]', (values[key].content || '').trim());
        questionFormData.append('question[question_options][][is_right]', `${values.correctAnswer === key}`);
        if (values[key].id) {
          questionFormData.append('question[question_options][][id]', values[key].id);
        }
        if (values[key].isDeleted) {
          questionFormData.append('question[question_options][][is_deleted]', values[key].isDeleted);
        }
      } else if (key !== 'image' && !answerKeys.includes(key) && key !== 'correctAnswer') {
        if (['name', 'content', 'rightInput'].includes(key)) {
          questionFormData.append(`question[${snakeCase(key)}]`, (values[key] || '').trim());
        } else if (['optionScore', 'inputScore', 'uploadScore'].includes(key)) {
          questionFormData.append('question[score]', (values[QUESTION_SCORE[answerType]] || ''));
        } else {
          questionFormData.append(`question[${snakeCase(key)}]`, values[key] || '');
        }
      }
    });
    if (fileImage && !imageError) questionFormData.append('question[image]', fileImage, fileImage.name);
  }

  function handleSubmitQuestionForm(values) {
    resetAnswerField(values.questionType);
    if (!isEmpty(imageError)) {
      questionForm.setFieldsValue({ image: imageError });
    }
    questionForm.validateFields();
    let requestValues = values;
    const newValues = values.questionOptions ? [...values.questionOptions, ...deletedAnswers] : [];
    const answerKeys = Array.from({ length: newValues.length }, (_, idx) => `answer${++idx}`);

    if (values.questionOptions) {
      newValues.forEach((questionOption, questionOptionIndex) => {
        requestValues  = {
          ...requestValues,
          [`answer${questionOptionIndex + 1}`]: {
            ...questionOption.id && { id: questionOption.id },
            content: questionOption.content,
            ...questionOption.isDeleted && { isDeleted: questionOption.isDeleted },
          },
        };
      });
      requestValues = { ...omit(requestValues, ['questionOptions']) };
    }

    createFormData(requestValues, answerKeys);
    if (!imageError) {
      if (isNumber(questionIdSelected)) {
        updateQuestion ({
          testId,
          questionId: questionIdSelected,
          questionData: questionFormData,
          requestActions: {
            setDeletedAnswers,
          },
        });
      } else {
        createQuestion({ testId, questionData: questionFormData, questionForm });
        removeImage();
        setIsScored('true');
      }
    }
  }

  async function handleShowPreviewQuestion() {
    await QuestionStorage.setData({
      ...questionForm.getFieldsValue(),
      image: previewImageUrl || imageURL,
      questionSelectedIndex,
    });
    window.open('/admin-service/preview-question', '_blank');
  }

  function handleDeleteTest() {}

  return (
    <Form
      form={questionForm}
      name="modifyQuestionForm"
      {...questionIdSelected !== 'create'
        ? { initialValues: questionInitialFormValues }
        : { initialValues: {
          isScored: 'true',
          questionType: answerType,
          questionOptions: [
            { content: '' },
            { content: '' },
          ],
        }}
      }
      onFinish={(values) => {
        if(!editorErrors){
          handleSubmitQuestionForm(values);
          setQuestionSelectedIndex(questionSelectedIndex + 1);
        }
        
      }}
      onFinishFailed={() => {
        if (!isEmpty(imageError)) questionForm.setFieldsValue({ image: imageError });
        questionForm.validateFields();
      }}
      style={{ marginTop: 8 }}
      scrollToFirstError={true}
    >
      <Row gutter={24}>
        <Col span={9}>
          <QuestionList
            testId={testId}
            questionList={questionList}
            questionForm={questionForm}
            hasBeenUsed={hasBeenUsed}
            moveQuestion={moveQuestion}
            setAnswerType={setAnswerType}
            setQuestionIdSelected={setQuestionIdSelected}
            questionIdSelected={questionIdSelected}
            setQuestionSelectedIndex={setQuestionSelectedIndex}
          />
        </Col>
        <Col span={15}>
          <Style.QuestionDetailContainer>
            <ModifyQuestion
              fileImage={fileImage}
              questionForm={questionForm}
              setFileImage={setFileImage}
              setImageError={setImageError}
              hasBeenUsed={hasBeenUsed}
              removeImage={removeImage}
              questionList={questionList}
              questionIdSelected={questionIdSelected}
              questionDetail={questionDetail}
              setImageURL={setImageURL}
              imageURL={imageURL}
              questionCount={questionList.data.length}
              setPreviewImageUrl={setPreviewImageUrl}
              editorErrors={editorErrors}
              setEditorErrors={setEditorErrors}
            />
            <ModifyAnswer
              setAnswerType={setAnswerType}
              answerType={answerType}
              setAnswerCount={setAnswerCount}
              answerCount={answerCount}
              setDeletedAnswers={setDeletedAnswers}
              deletedAnswers={deletedAnswers}
              setIsScored={setIsScored}
              isScored={isScored}
              questionForm={questionForm}
              hasBeenUsed={hasBeenUsed}
              questionIdSelected={questionIdSelected}
            />
          </Style.QuestionDetailContainer>
          <Style.QuestionModifyAction hasEdit={isNumber(questionIdSelected)}>
            {isNumber(questionIdSelected) && (
              <Button
                danger
                htmlType="button"
                onClick={() => handleConfirmDeleteQuestion()}
                disabled={hasBeenUsed}
              >
                削除する
              </Button>
            )}
            <Space align="center" size="middle">
              <Button
                type="primary"
                htmlType="button"
                ghost
                disabled={hasBeenUsed && questionIdSelected === 'create'}
                onClick={() => handleShowPreviewQuestion()}
              >
                プレビュー
              </Button>
              <Button
                type="primary"
                htmlType="button"
                className="btn-secondary"
                onClick={() => history.push(ServicePath.TestList)}
              >
                キャンセル
              </Button>
              <Button
                htmlType="submit"
                type="primary"
                disabled={hasBeenUsed && questionIdSelected === 'create' || isDisableRequest}
              >
                保存する
              </Button>
            </Space>
          </Style.QuestionModifyAction>
        </Col>
      </Row>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { questionList, questionDetail } = state.testReducer;
  const { isDisableRequest } = state.disableRequestReducer;

  return {
    questionList,
    questionDetail,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getQuestionDetail: (params) => dispatch(getQuestionDetailAction(params)),
  createQuestion: (params) => dispatch(createQuestionAction(params)),
  updateQuestion: (params) => dispatch(updateQuestionAction(params)),
  deleteQuestion: (params) => dispatch(deleteQuestionAction(params)),
  moveQuestion: (params) => dispatch(moveQuestionAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestContent);
