// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const CourseItemsContainer = styled.div`
  margin-bottom: 40px;
`;

export const CollapseHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const CollapseItemContainer = styled.div`
  display: flex;
  background-color: white;
  height: 50px;
  padding: 0 40px 0 16px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const CollapseItemContent = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: center;
  width: ${(props) => props.width || 'auto'};

  & > button {
    min-width: 76px;
  }
`;

export const UnitAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > button {
    min-width: 76px;
  }
`;
