// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

const initialState = {
  questionList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  questionDetail: {
    data: {},
    meta: {},
    errors: [],
  },
};

export type QuestionReducer = Readonly<typeof initialState>;

export default (state: QuestionReducer = initialState, action: any): QuestionReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.QUESTION_ACTION.GET_LIST): {
    return {
      ...state,
      questionList: {
        ...state.questionList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.QUESTION_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      questionList: {
        data: data.courseQuestions,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.QUESTION_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      questionList: {
        ...state.questionList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.QUESTION_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      questionDetail: {
        data: data.courseQuestion,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.QUESTION_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      questionDetail: {
        ...state.questionDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.QUESTION_ACTION.SELECT_QUESTION_STATUS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      questionDetail: {
        data: data.courseQuestion,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.QUESTION_ACTION.SELECT_QUESTION_STATUS): {
    const { errors } = action;
    return {
      ...state,
      questionDetail: {
        ...state.questionDetail,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
