// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const COURSE_ACTION = {
  GET_LIST: 'ADMIN_SERVICE/COURSE_ACTION/GET_LIST',
  GET_DETAIL: 'ADMIN_SERVICE/COURSE_ACTION/GET_DETAIL',
  GET_GROUP_RECOMMENDED_DATE: 'ADMIN_SERVICE/COURSE_ACTION/GET_GROUP_RECOMMENDED_DATE',
  GET_COURSE_COMPANY_INFO: 'ADMIN_SERVICE/COURSE_ACTION/GET_COURSE_COMPANY_INFO',
  GET_GROUP: 'ADMIN_SERVICE/COURSE_ACTION/GET_GROUP',
  GET_COURSE_VERSIONS: 'ADMIN_SERVICE/COURSE_ACTION/GET_COURSE_VERSIONS',
  CLEAR_COURSE_DETAIL: 'ADMIN_SERVICE/COURSE_ACTION/CLEAR_COURSE_DETAIL',
  CLEAR_COURSE_OVERVIEW: 'ADMIN_SERVICE/COURSE_ACTION/CLEAR_COURSE_OVERVIEW',

  GET_LEARNING_STATUS: 'ADMIN_SERVICE/COURSE_ACTION/GET_LEARNING_STATUS',
  GET_LEARNING_CHART: 'ADMIN_SERVICE/COURSE_ACTION/GET_LEARNING_CHART',
  GET_LEARNING_USER_PROGRESS: 'ADMIN_SERVICE/COURSE_ACTION/GET_LEARNING_USER_PROGRESS',
  CLEAR_LEARNING_TAB: 'ADMIN_SERVICE/COURSE_ACTION/CLEAR_LEARNING_TAB',

  GET_ATTENDANCE_STATUS: 'ADMIN_SERVICE/COURSE_ACTION/GET_ATTENDANCE_STATUS',
  GET_ATTENDANCE_CHART: 'ADMIN_SERVICE/COURSE_ACTION/GET_ATTENDANCE_CHART',
  GET_TEST_SCORE_CHART: 'ADMIN_SERVICE/COURSE_ACTION/GET_TEST_SCORE_CHART',
  GET_STUDENT_LIST: 'ADMIN_SERVICE/COURSE_ACTION/GET_STUDENT_LIST',
  UPDATE_STUDENT_COURSES_DURATION: 'COURSE_ACTION/UPDATE_STUDENT_COURSES_DURATION',

  GET_TEST_SCORE_OVERVIEW: 'ADMIN_SERVICE/COURSE_ACTION/GET_TEST_SCORE_OVERVIEW',
  GET_TEST_SCORE_STUDENTS: 'ADMIN_SERVICE/COURSE_ACTION/GET_TEST_SCORE_STUDENTS',

  CREATE_COURSE: 'ADMIN_SERVICE/COURSE_ACTION/CREATE_COURSE',
  UPDATE_COURSE: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_COURSE',
  DELETE: 'ADMIN_SERVICE/COURSE_ACTION/DELETE',
  COPY_COURSE: 'ADMIN_SERVICE/COURSE_ACTION/COPY_COURSE',

  CREATE_COURSE_UNIT: 'ADMIN_SERVICE/COURSE_ACTION/CREATE_COURSE_UNIT',
  UPDATE_COURSE_UNIT: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_COURSE_UNIT',
  DELETE_COURSE_UNIT: 'ADMIN_SERVICE/COURSE_ACTION/DELETE_COURSE_UNIT',

  CREATE_COURSE_GROUP: 'ADMIN_SERVICE/COURSE_ACTION/CREATE_COURSE_GROUP',
  UPDATE_COURSE_GROUP: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_COURSE_GROUP',
  DELETE_COURSE_GROUP: 'ADMIN_SERVICE/COURSE_ACTION/DELETE_COURSE_GROUP',

  CREATE_GROUP_CHAPTER: 'ADMIN_SERVICE/COURSE_ACTION/CREATE_GROUP_CHAPTER',
  UPDATE_GROUP_CHAPTER: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_GROUP_CHAPTER',
  DELETE_GROUP_CHAPTER: 'ADMIN_SERVICE/COURSE_ACTION/DELETE_GROUP_CHAPTER',

  GET_CHAPTER_FAQS: 'ADMIN_SERVICE/COURSE_ACTION/GET_CHAPTER_FAQS',
  CREATE_CHAPTER_FAQ: 'ADMIN_SERVICE/COURSE_ACTION/CREATE_CHAPTER_FAQ',
  DELETE_CHAPTER_FAQ: 'ADMIN_SERVICE/COURSE_ACTION/DELETE_CHAPTER_FAQ',

  GET_COURSE_FAQS: 'ADMIN_SERVICE/COURSE_ACTION/GET_COURSE_FAQS',

  CREATE_CHAPTER_UNIT: 'ADMIN_SERVICE/COURSE_ACTION/CREATE_CHAPTER_UNIT',
  UPDATE_CHAPTER_UNIT: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_CHAPTER_UNIT',
  DELETE_CHAPTER_UNIT: 'ADMIN_SERVICE/COURSE_ACTION/DELETE_CHAPTER_UNIT',
  MOVE_CHAPTER_FAQ: 'ADMIN_SERVICE/COURSE_ACTION/MOVE_CHAPTER_FAQ',

  GET_COURSES_BY_COMPANY: 'ADMIN_SERVICE/COURSE_ACTION/GET_COURSES_BY_COMPANY',

  UPDATE_OVERVIEW: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_OVERVIEW',

  CLEAR_COURSE_DETAIL_ERRORS: 'ADMIN_SERVICE/COURSE_ACTION/CLEAR_COURSE_DETAIL_ERRORS',

  GET_OVERVIEW_GROUP_DETAIL: 'ADMIN_SERVICE/COURSE_ACTION/GET_OVERVIEW_GROUP_DETAIL',
  CLEAR_OVERVIEW_SIDEBAR: 'ADMIN_SERVICE/COURSE_ACTION/CLEAR_OVERVIEW_SIDEBAR',

  GET_MASTER_DATA_COURSES: 'ADMIN_SERVICE/COURSE_ACTION/GET_MASTER_DATA_COURSES',
  GET_MASTER_DATA_COURSE_LIST: 'ADMIN_SERVICE/COURSE_ACTION/GET_MASTER_DATA_COURSE_LIST',

  GET_OVERVIEW_COURSE_CONTENT: 'ADMIN_SERVICE/COURSE_ACTION/GET_OVERVIEW_COURSE_CONTENT',

  CHANGE_GROUP_POSITION: 'ADMIN_SERVICE/COURSE_ACTION/CHANGE_GROUP_POSITION',
  CHANGE_CHAPTER_POSITION: 'ADMIN_SERVICE/COURSE_ACTION/CHANGE_CHAPTER_POSITION',
  CHANGE_UNIT_POSITION: 'ADMIN_SERVICE/COURSE_ACTION/CHANGE_UNIT_POSITION',

  GET_VERSIONS_OF_COURSE: 'ADMIN_SERVICE/COURSE_ACTION/GET_VERSIONS_OF_COURSE',
  CREATE_COURSE_VERSION: 'ADMIN_SERVICE/COURSE_ACTION/CREATE_COURSE_VERSION',
  UPDATE_COURSE_NAME: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_COURSE_NAME',

  DOWNLOAD_CSV_STUDY_STUDENT: 'ADMIN_SERVICE/COURSE_ACTION/DOWNLOAD_CSV_STUDY_STUDENT',
  UPDATE_PROGRESS_NOTIFICATION_SETTING: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_PROGRESS_NOTIFICATION_SETTING',
  DOWNLOAD_CSV_TESTS: "ADMIN_SERVICE/COURSE_ACTION/DOWNLOAD_CSV_TESTS",

  UPDATE_POSITION_COURSE: 'ADMIN_SERVICE/COURSE_ACTION/UPDATE_POSITION_COURSE',
};