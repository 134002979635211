// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class QuestionServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getQuestionList(params) {
    return this.restClient.get('/admin_manage/course_questions', params);
  }

  getCourseQuestionDetail(params) {
    return this.restClient.get(`/admin_manage/course_questions/${params.id}`);
  }

  selectQuestionStatus(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/course_questions/${id}`, requestData);
  }
}
