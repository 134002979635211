// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  LOADING_ACTION,
  MY_PROFILE_ACTION,
  ADMIN_SERVICE,
  ADMIN_COMPANY,
  STUDENT,
  REQUEST,
  NOTIFICATION_ACTION,
} from '../constant';

const initialState = {
  isProcessLoading: undefined as boolean | undefined,
};

export type LoadingReducer = Readonly<typeof initialState>;

export default (state: LoadingReducer = initialState, action: any): LoadingReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL):
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST):
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.UPDATE_TEST):
  case REQUEST(ADMIN_SERVICE.LICENSE_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_SERVICE.LICENSE_ACTION.UPDATE_LICENSE):
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION):
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.UPDATE_QUESTION):
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.JOIN_OVERVIEW_TEST):
  case REQUEST(ADMIN_SERVICE.SURVEY_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_SERVICE.TIP_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_SERVICE.FAQ_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL):
  case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL):
  case REQUEST(STUDENT.COURSE_ACTION.GET_DETAIL):
  case REQUEST(STUDENT.UNIT_ACTION.GET_DETAIL):
  case REQUEST(STUDENT.TEST_ACTION.JOIN_TEST):
  case REQUEST(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS):
  case REQUEST(MY_PROFILE_ACTION.GET_PROFILE_INFO):
  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS):
  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS):
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST):
  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST):
  case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY):
  case REQUEST(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY):
  case REQUEST(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER):
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER): 
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UNLOCK_USER):
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL):
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO):
  case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.UNLOCK_USER): {
    return {
      ...state,
      isProcessLoading: true,
    };
  }

  case REQUEST(LOADING_ACTION.FINISHED_LOAD): {
    return {
      ...state,
      isProcessLoading: false,
    };
  }

  default:
    return state;
  }
};
