// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import { Button, Table, Tag } from 'antd';
import { isArray, isNumber } from 'lodash/lang';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';

import {
  closeModal as closeModalAction,
  getFileDownloadURL as getFileDownloadURLAction,
  getStudentFileDownloadURL as getStudentFileDownloadURLAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface ITestResultModalProps extends StateProps, DispatchProps {
  closeModal(): void;
  getFileDownloadURL(params): void;
  getStudentFileDownloadURL(params): void;
  modalData: {
    title: string;
    testType?: string;
    isReview: boolean;
    testExplanationFile: {
      id: number;
      explanationFile: {
        id: number;
        url: string;
      };
    };
    testResults: {
      id: number;
      answerInput: number | null;
      answerOptionIndex: number | null;
      score: number;
      answerFile: {
        url: string;
        filename: string;
      };
      isRight: boolean;
      question: {
        name: string;
        rightInput: number | null;
        rightOptionIndex: number | null;
        score: number;
        questionType: string;
        isScored: boolean;
      };
    }[];
    displayResults: string;
    testScore: {
      score: number;
      testScorePercentage: number;
      isPass: boolean;
      completionStatus: string;
    };
  };
  unitDetail: {
    data: {
      item: {
        testType: string;
      };
    };
  };
}

const TestResultModal: React.FC<ITestResultModalProps> = ({
  closeModal,
  getFileDownloadURL,
  getStudentFileDownloadURL,
  modalData,
  unitDetail,
}) => {
  let testNotScoredYet = false;
  if (isArray(modalData.testResults)) {
    modalData.testResults.forEach((item) => {
      if (
        ((item.question.questionType === 'upload_file_type' && (item.answerInput || item.answerFile?.url))
        && item.question.isScored
        && !isNumber(item.score))
      ) {
        testNotScoredYet = true;
      }
    });
  }

  function renderTagResult(record, isColor) {
    if (record.questionType === 'upload_file_type') {
      if (!record.isScored || record.answerFile) return isColor ? 'orange' : '？';
      if (record.score === 0 || !record.answerFile) return isColor ? 'red' : '✖';
      return isColor ? 'green' : '〇';
    } else if (record.isRight) {
      return isColor ? 'green' : '〇';
    }
    return isColor ? 'red' : '✖';
  }

  const columns = [
    {
      title: '正誤',
      render: (_, record) => (
        <Tag
          color={renderTagResult(record, true)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: 24,
          }}
        >
          {renderTagResult(record, false)}
        </Tag>
      ),
      dataIndex: 'isRight',
      width: 60,
    },
    {
      title: '問目',
      dataIndex: 'questionIndex',
      width: 100,
    },
    {
      title: '問名',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      dataIndex: 'name',
      width: 300,
    },
    {
      title: '選択',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.studentAnswer ? record.studentAnswer : '未回答'}
        </TooltipParagraph>
      ),
      dataIndex: 'studentAnswer',
      width: 200,
    },
    {
      title: '正解',
      render: (_, record) => {
        if (record.questionType !== 'upload_file_type') {
          return (
            <TooltipParagraph isPreWrap rows={2}>
              {record.rightAnswer ? record.rightAnswer : '未回答'}
            </TooltipParagraph>
          );
        }
        return 'ー';
      },
      dataIndex: 'rightAnswer',
      width: 200,
    },
    {
      title: '得点',
      dataIndex: 'score',
      render: (_, record) => {  
        if (!record.isScored) return '採点なし';
        if ((record.questionType === 'upload_file_type')
          && modalData.testScore.completionStatus !== 'done' && record.answerFile) {
          return '未採点';
        }
        return record.score;
      },
      width: 100,
    },
  ];

  const tableData = isArray(modalData.testResults)
    ? modalData.testResults.map((item, index) => ({
      key: item.id,
      id: item.id,
      isRight: (item.question.questionType === 'upload_file_type' && item.question.isScored)
        ? item.score > 0
        : item.isRight,
      questionIndex: `${index + 1}問目`,
      name: item.question.name,
      ...item.answerInput
        ? { studentAnswer: item.answerInput }
        : { studentAnswer: item.answerFile?.filename || item.answerOptionIndex },
      rightAnswer:modalData.displayResults === "details" ?
        item.question.rightInput ? item.question.rightInput : item.question.rightOptionIndex : "ー",
      score: item.isRight === false ? 0 : item.question.score,
      questionType: item.question.questionType,
      isScored: item.question.isScored,
      answerFile: item.answerInput

    }))
    : [];

  function renderScoreTitle() {
    if ((modalData.testScore.completionStatus === 'waiting' || testNotScoredYet)
      && modalData.testScore.completionStatus !== 'done') {
      return <Text xxxl w6>採点待ち</Text>;
    }
    return (
      <Style.TestResultDetail>
        <Style.TestResultScore>
          <Text xxxl w6>得点</Text>
          <Text
            w6
            style={{ fontSize: '56px', lineHeight: '64px', transform: 'translateY(4px)' }}
          >
            {modalData.testScore.score}
          </Text>
          <Text xxxl w6>{`点（${modalData.testScore.testScorePercentage}%）`}</Text>
        </Style.TestResultScore>
        {(unitDetail.data.item.testType === 'finish_test' || modalData.testType === 'finish_test') && (
          <Style.TestResultStatus
            color={modalData.testScore.isPass ? 'green' : 'red'}
          >
            {modalData.testScore.isPass ? '合格' : '不合格'}
          </Style.TestResultStatus>
        )}
      </Style.TestResultDetail>
    );
  }

  return (
    <Style.TestResultContainer>
      <Style.TestResultOverview>
        {renderScoreTitle()}
        {modalData.testExplanationFile && modalData.testExplanationFile.explanationFile && (
          <Style.DownloadExplanation>
            <Button
              type="primary"
              onClick={() => modalData.isReview
                ? getFileDownloadURL({ id: modalData.testExplanationFile?.id })
                : getStudentFileDownloadURL({ id: modalData.testExplanationFile?.id })
              }
            >
              解説ダウンロード
            </Button>
          </Style.DownloadExplanation>
        )}
      </Style.TestResultOverview>
      {isArray(modalData.testResults) && (
        <Table
          className="table-header-student"
          pagination={false}
          columns={columns}
          dataSource={tableData}
          locale={{ emptyText: 'データが存在しません。' }}
          scroll={{ y: 300 }}
          style={{ width: '100%' }}
        />
      )}
      <Style.TestResultFooter>
        <Button type="primary" className="btn-secondary" onClick={() => closeModal()}>
          閉じる
        </Button>
      </Style.TestResultFooter>
    </Style.TestResultContainer>
  );
};

const mapStateToProps = (state) => {
  const { unitDetail } = state.studentUnitReducer;
  return {
    unitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
  getFileDownloadURL: (params) => dispatch(getFileDownloadURLAction(params)),
  getStudentFileDownloadURL: (params) => dispatch(getStudentFileDownloadURLAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestResultModal);

