// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { STUDENT, REQUEST } from '../../constant';

export function getStudentGroupDetail(params) {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.GET_GROUP),
    payload: params,
  };
}

export function getStudentTopGroupDetail(params) {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.GET_TOP_GROUP_DETAIL),
    payload: params,
  };
}

export function getStudentCourseDetail(params) {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.GET_DETAIL),
    payload: params,
  };
}


export function createStudentQuestion(params) {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.CREATE_QUESTION),
    payload: params,
  };
}

export function getStudentCourseDetailStatus(params) {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.GET_DETAIL_STATUS),
    payload: params,
  };
}

export function clearSidebarCourse() {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.CLEAR_SIDEBAR_COURSE),
  };
}

export function updateCourseProgress(params) {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.UPDATE_COURSE_PROGRESS),
    payload: params,
  };
}

export function getRecommendDate(params) {
  return {
    type: REQUEST(STUDENT.COURSE_ACTION.GET_RECOMMEND_DATE),
    payload: params,
  };
}
