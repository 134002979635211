// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';

import history from 'src/utils/history';

import { STUDENT, SUCCESS, FAILURE, REQUEST } from '../../constant';
import { StudentPath } from 'src/constants/routerConstants';

import { studentAccountServices } from 'src/services';

function* getConfirmationStatusSaga(action) {
  try {
    const result = yield studentAccountServices.getConfirmationStatus(action.payload);
    yield put({
      type: SUCCESS(STUDENT.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS),
      payload: result,
    });
  } catch (e) {
    yield history.push('/login');
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(STUDENT.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS), errors: e.errors });
  }
}

function* confirmAccountSaga(action) {
  try {
    yield studentAccountServices.confirmAccount(action.payload);
    yield put({
      type: SUCCESS(STUDENT.ACCOUNT_ACTION.CONFIRM),
    });
    yield localStorage.clear();
    yield history.push(StudentPath.Login);
    yield showAlertNotice({ type: 'success', message: 'アカウントの登録が成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(STUDENT.ACCOUNT_ACTION.CONFIRM), errors: e.errors });
  }
}

export default function* studentAccountSaga() {
  yield all([
    takeLatest(REQUEST(STUDENT.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS), getConfirmationStatusSaga),
    takeLatest(REQUEST(STUDENT.ACCOUNT_ACTION.CONFIRM), confirmAccountSaga),
  ]);
}
