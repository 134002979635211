// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty, isFinite } from "lodash/lang";
import { padStart } from 'lodash';
import { Row, Col, Button, Select, Space, Checkbox } from "antd";

import { MainWrapper } from "src/components/layouts/ContentLayout";
import { Text } from "src/components/styles";
import * as Style from "./styles";
import ConfirmModal from "src/components/Modal/components/ConfirmModal";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

interface IProgressNotificationProps {
  courseDetail: {
    data: {
      id: number;
      actualStatus: string;
      name: string;
      courseCategory: {
        name: string;
      };
      overview: string;
      startDate: string;
      remainingDays: number;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      recommendedStartDate: string;
      recommendedEndDate: string;
      recommendedCompletionDay: number;
      isDisplayLogo: boolean;
      logo: {
        logoName: string;
        logoUrl: string;
      },
      emailHoursSchedule: number | null;
      emailMinutesSchedule: number | null;
      emailWeeklySchedule: [];
      excludeFinishedUserSchedule: boolean;
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
      surveys: {
        id: number;
        item: {
          surveyType: string;
        };
        unit: {
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        item: {
          testType: string;
          questionsCount: number;
        };
        unit: {
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      groups: {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          totalUnits: number;
          totalVideosTime: number;
        }[];
      }[];
    };
    load: boolean;
  };
  setProgressNotification(params: any): void;
  showModal(params): void;
  closeModal(): void;
}

const ProgressNotification: React.FC<IProgressNotificationProps> = ({
  courseDetail,
  setProgressNotification,
  showModal,
  closeModal
}) => {
  const [dateSelected, setDateSelected] = useState<number[]>([]);
  const [hourSelected, setHourSelected] = useState<number | undefined>(undefined);
  const [minutesSelected, setMinutesSelected] = useState<number | undefined>(undefined);
  const [finishedUserScheduleSelected, setFinishedUserScheduleSelected] = useState<boolean>(false);

  const { emailHoursSchedule, emailMinutesSchedule, emailWeeklySchedule, excludeFinishedUserSchedule } = courseDetail.data;

  useEffect(() => {
    if (emailWeeklySchedule) {
      setDateSelected(emailWeeklySchedule);
      setHourSelected(
        emailHoursSchedule !== null ? emailHoursSchedule : undefined
      );
      setMinutesSelected(
        emailMinutesSchedule !== null ? emailMinutesSchedule : undefined
      );
      setFinishedUserScheduleSelected(excludeFinishedUserSchedule);
    }
  }, [courseDetail]);

  const date = ["月", "火", "水", "木", "金", "土"];

  const handleChooseDay = (index) => {
    let newDateSelected = [...dateSelected];
    const indexDate = dateSelected.findIndex((item) => index === item);
    if (indexDate === -1) {
      return setDateSelected([...newDateSelected, index]);
    }
    newDateSelected.splice(indexDate, 1);

    return setDateSelected([...newDateSelected]);
  };

  const renderHourSelect = () => {
    let data: any[] = [];
    for (let index = 0; index < 24; index++) {
      const html = (
        <Select.Option key={`hour-${index}`} value={index}>
          {index}
        </Select.Option>
      );
      data.push(html);
    }
    return data;
  };

  const renderMinutesSelect = () => {
    let data: any[] = [];
    for (let index = 0; index < 4; index++) {
      const html = (
        <Select.Option key={`minutes-${index * 15}`} value={index * 15}>
          {padStart(index * 15, 2, '0')}
        </Select.Option>
      );
      data.push(html);
    }
    return data;
  };

  const handleSubmit = () => {
    const requestData = {
      emailWeeklySchedule: dateSelected,
      emailHoursSchedule: hourSelected !== undefined ? hourSelected : null,
      emailMinutesSchedule: minutesSelected !== undefined ? minutesSelected : null,
      excludeFinishedUserSchedule: finishedUserScheduleSelected,
    };
    setProgressNotification({
      courseId: courseDetail.data.id, 
      requestData,
    });
  };


  const handleClearSetting = () => {
    const modalProps = {
      modalData: {
        title: "通知停止確認",
        message: "通知を停止しますが、よろしいですか。",
        okText: "はい",
        cancelText: "いいえ"
      },
      modalAction: {
        confirm: () => {
          const requestData = {
            emailWeeklySchedule: [],
            emailHoursSchedule: null,
            emailMinutesSchedule: null,
            excludeFinishedUserSchedule: false,
          }
          setProgressNotification({ courseId: courseDetail.data.id, requestData })
          closeModal()
        },
      },
      isConfirm: true,
      spaceEvenly: true
    };
    showModal(<ConfirmModal {...modalProps} />);
  };

  const isDisableButtonSubmit = isEmpty(dateSelected) || !isFinite(hourSelected) || !isFinite(minutesSelected)
  const isDisableButtonCancel = isEmpty(emailWeeklySchedule)

  return (
    <MainWrapper
      title={
        <div style={{ width: "60%" }}>
          <Text white lg w6>
            通知条件設定
          </Text>
        </div>
      }
      style={{ marginTop: "40px" }}
    >
      <Style.ProgressNotificationContainer>
        <Style.ProgressNotificationOverview>
          <Text style={{ lineHeight: '32px' }}>概要</Text>
          <Text>
            進捗通知設定をすることで、当コースの担当をしている企業管理者および受講者に進捗状況をメールにて通知することができます。
            <br />
            ※通知頻度を高く設定すると、迷惑メールと認識される場合があるため、週1回の設定をお勧めします。
          </Text>
        </Style.ProgressNotificationOverview>
        <Row style={{ marginTop: '40px' }}>
          <Col span="3">
            <Text>曜日</Text>
          </Col>
          <Col span="21">
            <Space size='middle'>
              {date.map((dateItem, index) => (
                <Button
                  key={`dataItem-${index}`}
                  type={dateSelected.includes(index + 1) ? "primary" : undefined}
                  onClick={() => handleChooseDay(index + 1)}
                >
                  {dateItem}
                </Button>
              ))}
              <Button
                key={`dataItem-${0}`}
                type={dateSelected.includes(0) ? "primary" : undefined}
                onClick={() => handleChooseDay(0)}
              >
                日
              </Button>
            </Space>
            
            <Text margin={"8px 0 0 0"}>※複数選択可</Text>
          </Col>
        </Row>
        <Row style={{ margin: "40px 0" }}>
          <Col span="3">
            <Text>時間</Text>
          </Col>
          <Col span="21">
            <Space>
              <Select
                allowClear
                onChange={(value) => setHourSelected(value)}
                value={hourSelected}
                placeholder="時"
              >
                {renderHourSelect()}
              </Select>
              <Style.TwoDots style={{margin: '0px 25px'}}>:</Style.TwoDots>
              <Select
                allowClear
                onChange={(value) => setMinutesSelected(value)}
                value={minutesSelected}
                placeholder="分"
              >
                {renderMinutesSelect()}
              </Select>
            </Space>
          </Col>
        </Row>
        <Row style={{ margin: '40px 0' }}>
          <Col span={3}>
            <Text>完了者除外</Text>
          </Col>
          <Col span={21}>
            <Space align="center">
              <Checkbox
                checked={finishedUserScheduleSelected}
                onChange={(e: CheckboxChangeEvent) => {
                  setFinishedUserScheduleSelected(e.target?.checked)
                }}
              />
              進捗率100％の人を通知から除外する
            </Space>
          </Col>
        </Row>
        <Row style={{ margin: "40px 0" }}>
          <Col span={3}></Col>
          <Col span={21}>
            <Button
              type="primary"
              onClick={handleSubmit}
              disabled={isDisableButtonSubmit}
              style={{ marginRight: 30 }}
            >
              設定する
            </Button>
            <Button
              type="primary"
              onClick={handleClearSetting}
              disabled={isDisableButtonCancel}
            >
              通知停止
            </Button>
          </Col>
        </Row>
      </Style.ProgressNotificationContainer>
    </MainWrapper>
  );
};

export default ProgressNotification;
