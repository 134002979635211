//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Space,
} from 'antd';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';
import { handleNavigateUnit } from '../../util';

import { Text, SkeletonLoading } from 'src/components/styles';

import {
  getOverviewGroupDetail as getOverviewGroupDetailAction,
  setCollapseData as setStudentCollapseDataAction,
  joinOverviewTest as joinOverviewTestAction,
} from 'src/redux/actions';

import prevUnitIcon from 'src/assets/images/videoPlayer/prev-video-icon.svg';
import nextUnitIcon from 'src/assets/images/videoPlayer/next-video-icon.svg';
import prevUnitDisabledIcon from 'src/assets/images/videoPlayer/prev-video-disabled-icon.svg';
import nextUnitDisabledIcon from 'src/assets/images/videoPlayer/next-video-disabled-icon.svg';

import * as Style from './styles';

interface ITestExplainProps extends StateProps, DispatchProps {
  getOverviewGroupDetail(params): void;
  setCollapseData(params): void;
  joinOverviewTest(params): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  overviewCourseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
  overviewUnitDetail: {
    data: {
      id: number;
      isDone: boolean;
      unit: {
        name: string;
      };
      item: {
        limitTime: number;
        description: string;
        questionsCount: number;
        testType: string;
        type: string;
        limitAttempt: number;
        usedAttemptsCount: number;
      };
    };
    meta: {
      back: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
      next: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
    };
    load: boolean;
  };
}

const TestExplain: React.FC<ITestExplainProps> = ({
  getOverviewGroupDetail,
  setCollapseData,
  joinOverviewTest,
  collapseData,
  overviewCourseDetail,
  overviewUnitDetail,
}) => {
  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[3];
  const unitVersionId = pathnameSplit[5];
  const groupId = getSearchParams('group') || '';
  const chapterId = getSearchParams('chapter') || '';

  return (
    <>
      {overviewCourseDetail.load
        ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
        : <Text white w6 xxl>{overviewCourseDetail.data.courseCategory?.name}</Text>
      }
      <Style.TestDetailContainer>
        <Style.TestDetailContent>
          <Style.TestDetailTitle>
            {overviewCourseDetail.load || overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text truncate white lg w6>{overviewUnitDetail.data.unit.name}</Text>
            }
          </Style.TestDetailTitle>
          <Style.TestDescription>
            {overviewCourseDetail.load || overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : <Text multiLine>{overviewUnitDetail.data.item.description}</Text>
            }
          </Style.TestDescription>
          <Style.TestFooter>
            <Style.TestAction>
              <Space align="center" size={32}>
              {overviewCourseDetail.load || overviewUnitDetail.load
                  ? (
                    <>
                      <Space align="center" size="large">
                        <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                      </Space>
                      <Space align="center" size="large">
                        <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                      </Space>
                      <Space align="center" size="large">
                        <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                      </Space>
                    </>
                  )
                  : (
                    <>
                      <Space align="center" size="large">
                        <Text xs subText w6>問題数</Text>
                        <Text headerText w6>{`${overviewUnitDetail.data.item.questionsCount}問`}</Text>
                      </Space>
                      <Space align="center" size="large">
                        <Text xs subText w6>制限時間</Text>
                        <Text headerText w6>
                          {overviewUnitDetail.data.item.limitTime > 0
                            ? `${overviewUnitDetail.data.item.limitTime / 60}分`
                            : '無制限'
                          }
                        </Text>
                      </Space>
                      <Space align="center" size="large">
                        <Text xs subText w6>受講回数</Text>
                        <Text headerText w6>
                          {overviewUnitDetail.data.item.limitAttempt === 0
                            ? '無制限'
                            : overviewUnitDetail.data.item.limitAttempt
                          }
                        </Text>
                      </Space>
                    </>
                  )
                }
              </Space>
              <Button
                type="primary"
                onClick={() => joinOverviewTest({
                  courseId: parseFloat(courseId),
                  groupId: parseFloat(groupId),
                  chapterId: parseFloat(chapterId),
                  unitVersionId: parseFloat(unitVersionId),
                })}
                disabled={overviewCourseDetail.load || overviewUnitDetail.load}
              >
                テストを受ける
              </Button>
            </Style.TestAction>
            <Style.TestNavigate>
              <Button
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(overviewUnitDetail.meta.back)}
                onClick={() => handleNavigateUnit({
                  action: 'back',
                  getStudentGroupDetail: getOverviewGroupDetail,
                  setCollapseData,
                  courseDetail: overviewCourseDetail,
                  unitDetail: overviewUnitDetail,
                  collapseData,
                })}
              >
                <img
                  src={overviewUnitDetail.meta.back ? prevUnitIcon : prevUnitDisabledIcon}
                  style={{ marginRight: 4 }}
                  alt=""
                />
                戻る
              </Button>
              <Button
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(overviewUnitDetail.meta.next)}
                onClick={() => handleNavigateUnit({
                  action: 'next',
                  getStudentGroupDetail: getOverviewGroupDetail,
                  setCollapseData,
                  courseDetail: overviewCourseDetail,
                  unitDetail: overviewUnitDetail,
                  collapseData,
                })}
              >
                次へ
                <img
                  src={overviewUnitDetail.meta.next ? nextUnitIcon : nextUnitDisabledIcon}
                  style={{ marginLeft: 4 }}
                  alt=""
                />
              </Button>
            </Style.TestNavigate>
          </Style.TestFooter>
        </Style.TestDetailContent>
      </Style.TestDetailContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { collapseData } = state.commonReducer;
  const { overviewCourseDetail } = state.courseReducer;
  const { overviewUnitDetail } = state.unitReducer;
  return {
    collapseData,
    overviewCourseDetail,
    overviewUnitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOverviewGroupDetail: (params) => dispatch(getOverviewGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setStudentCollapseDataAction(params)),
  joinOverviewTest: (params) => dispatch(joinOverviewTestAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestExplain);
