// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import userBackground from 'src/assets/images/common/user-background.png';

export const MainContainer = styled.div`
  position: relative;
  display: flex;
  min-height: inherit;
  background-color: white;

  ${(props) => props.isFlexColumn
    && css`
      flex-direction: column;
    `}

  ${(props) => props.direction
  && css`
    @media only screen and (max-width: 428px) {
      flex-direction: ${props.direction || 'row'};
    }S
  `}
`;

export const MainBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: ${(props) => props.backgroundHeight ? `${props.backgroundHeight}px` : '200px'};
  background-color: ${(props) => props.theme.colors.primaryBackground};
  z-index: 0;

  ${(props) => props.isStudent
    && css`
      background-color: none;
      background-image: url(${userBackground});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    `}

  ${(props) => props.isResponsive
    && css`
      @media only screen and (max-width: 428px) {
        background: none;
        padding: 0;
      }
    `}

  ${(props) =>
    props.isCourseDetailStudent &&
    css`
      background-color: ${() => props.theme.colors.primary};
      background-image: none;

      @media (max-width: 428px) {
        min-height: 126px;
      }
    `}

  ${(props) => props.isTabs
    && css`
      border-bottom: 1px solid ${props.theme.colors.secondaryBorder};
    `}
`;

export const FullContent = styled.div`
  position: relative;
  padding: 16px;
  z-index: 1;
`;

export const SidebarContent = styled.div`
  min-width: 350px;
  max-width: 350px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: 0.3s;

  ${(props) => props.isCollapse
    && css`
      min-width: 44px;
    `}

  ${(props) => props.isCourseView
    && css`
      @media only screen and (max-width: 428px) {
        order: 2;
        min-width: 100%;
        height: auto;
      }
    `}

  ${(props) => props.isFullWidth
  && css`
    @media only screen and (max-width: 428px) {
      min-width: 100%;
      max-width: 100%;
    }
  `}
`;

export const MainContent = styled.div`
  margin: 0 auto;
  padding: 16px;
  max-width: calc(100% - 350px);
  width: 100%;
  z-index: 1;
  transition: 0.3s;

  ${(props) => props.fullView
    && css`
      padding: 16px 16px 60px;
      max-width: 1400px;
    `}

  ${(props) => props.formView
    && css`
      max-width: 920px;
    `}

  ${(props) => props.sidebarView
    && css`
      max-width: calc(100% - 44px);
    `}
  ${(props) => props.previewQuestion
    && css`
      padding: 16px 16px 60px;
      max-width: 1400px;
      display: flex;
      justify-content: center;
    `}

  ${(props) => props.isResponsive
    && css`
      @media only screen and (max-width: 428px) {
          padding: 0;
          min-width: 100%;
          height: auto;
        }
      }
    `}

  ${(props) => props.isCourseView
  && css`
    @media only screen and (max-width: 428px) {
        max-width: 100%;
        order: 1;
        margin: 0;
        padding: 0;
      }
    }
  `}

`;

export const MainWrapper = styled.div`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  background-color: ${(props) => props.isStudent ? '#c08d01' : props.theme.colors.adminBackground};
  color: white;
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  margin: 10px 0 16px;
`;

export const PrimaryContent = styled.div`
  margin-right: 20px;
  width: ${(props) => props.width || 'calc(100% - 320px)'};
`;

export const SubContent = styled.div`
  width: 300px;
`;

export const CardWrapper = styled.div`
  border-top: 4px solid #0D122D;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;

  ${(props) => props.isStudent
    && css`
      border-top: 4px solid #BE8D06;
    `}
`;
