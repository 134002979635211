// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Table,
  Tooltip,
} from 'antd';

import {
  getPlanList as getPlanListAction,
  deletePlan as deletePlanAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import ModifyPlansModal from '../ModifyPlansModal';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import * as Style from './styles';

interface IPlansListPageProps extends StateProps, DispatchProps {
  getPlanList(): void;
  showModal(params): void;
  deletePlan(params): void;
  closeModal(): void;
  planList: {
    data: {
      id: number;
      name: string;
      description: string;
    }[];
    load: boolean;
  };
}

const PlansListPage: React.FC<IPlansListPageProps> = ({
  getPlanList,
  deletePlan,
  showModal,
  planList,
  closeModal
}) => {
  useEffect(() => {
    getPlanList();

    return () => {
      closeModal()
    }
  }, []);

  function handleModifyPlans(record, actionType) {
    const modalProps = {
      modalData: {
        title: actionType === 'add' ? 'プラン新規作成' : 'プラン内容',
        plan: {
          id: record?.id,
          name: record?.name,
          description: record?.description,
        },
      },
    };
    return showModal(<ModifyPlansModal {...modalProps} />);
  }

  function handleDeletePlans(id) {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'このプランを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deletePlan({ id }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  const tableData = planList.data.map((row) => ({
    key: row.id,
    id: row.id,
    name: row.name,
    description: row.description,
  }));

  const columns = [
    {
      title: 'プラン名',
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 250,
    },
    {
      title: 'プラン説明',
      dataIndex: 'description',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.description}</TooltipParagraph>
      ),
      width: 500,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Style.TableAction>
          <Button
            size="small"
            danger
            ghost
            style={{ marginRight: 16 }}
            onClick={() => handleDeletePlans(record.id)}
          >
            &nbsp;削除&nbsp;
          </Button>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() => handleModifyPlans(record, 'edit')}
          >
            &nbsp;編集&nbsp;
          </Button>
        </Style.TableAction>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Style.PlansAction>
        <Button
          type="primary"
          onClick={() => handleModifyPlans(null, 'add' )}
        >
          新規作成
        </Button>
      </Style.PlansAction>
      <Table
        pagination={false}
        columns={columns}
        dataSource={tableData}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={planList.load}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { planList } = state.planReducer;
  return {
    planList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  getPlanList: () => dispatch(getPlanListAction()),
  deletePlan: (params) => dispatch(deletePlanAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PlansListPage);
