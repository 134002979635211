// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const DATE_TIME_FORMAT = 'YYYY/MM/DD H:mm';
export const DATE_FORMAT = 'YYYY/MM/DD';
export const FILE_IMAGE_SIZE = 5 * 1024 * 1024;
export const ALLOWED_TYPE = '.pdf,.doc,.docx,.ppt,.pptx,.py,.ipynb';
export const FILE_SIZE = 31457280;
export const ALLOWED_TYPE_CSV = '.csv,.xls';
export const FILE_SIZE_CSV = 3145728;
export const SCORE_CHART_TICKS = [0, 1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
export const ALLOWED_TYPE_IMAGE_LOGO ='image/png, image/jpg, image/jpeg'
export const TYPE_IMAGE_LOGO ='png, jpg, jpeg'

export const UNIT_TYPE = {
  video: '動画',
  test: 'テスト',
  tip: 'Tips',
  survey: 'アンケート',
};

export const SURVEY_TYPE = {
  pre_survey: '事前アンケート',
  confirm_survey: '確認アンケート',
  finish_survey: '修了アンケート',
};

export const TEST_TYPE = {
  pre_test: '事前テスト',
  confirm_test: '知識テスト',
  finish_test: '修了テスト',
  verify_test: '確認テスト',
};

export const QUESTION_TYPE = {
  option_type: '選択式',
  input_type: '記述式',
  upload_file_type: 'アップロード',
};

export const QUESTION_SCORE = {
  option_type: 'optionScore',
  input_type: 'inputScore',
  upload_file_type: 'uploadScore',
};

export const UPLOAD_ANSWER_STATUS = {
  scored: '採点済',
  unscored: '未採点',
};

export const REGISTER_STATUS = {
  invited: '招待中',
  canceled: 'キャンセル',
  expired: '期限切れ',
};

export const LOGIN_TITLE = {
  student: 'ログイン',
  admin_company: '企業管理者ログイン',
  admin_service: 'サービス管理者ログイン',
};

export const ACCOUNT_ROLES = {
  student: '受講者',
  admin_company: '企業管理者',
  admin_service: 'サービス管理者',
};

export const ACCOUNT_STATUS = {
  active: 'アクティブ',
  banned: '非アクティブ',
};

export const COURSE_STATUS = {
  coming: '開始待ち',
  finished: '終了',
  opening: 'オープン',
  closed: '中止',
};

export const COURSE_ACTUAL_STATUS = {
  finished: '終了済みのコースです。',
  closed: '中止のコースです。',
};

export const JP_DAY_WEEK = {
  monday: '月',
  tuesday: '火',
  wednesday: '水',
  thursday: '木',
  friday: '金',
  saturday: '土',
  sunday: '日',
};

export const QUESTION_STATUS_OPTION = [
  {
    id: 'pending',
    name: '未対応',
  },
  {
    id: 'in_progress',
    name: '対応中',
  },
  {
    id: 'done',
    name: '対応済み',
  },
];
export const TEST_TYPE_OPTION = [
  {
    id: 'pre_test',
    name: '事前テスト',
  },
  {
    id: 'verify_test',
    name: '確認テスト',
  },
  {
    id: 'finish_test',
    name: '修了テスト',
  },
  {
    id: 'confirm_test',
    name: '知識テスト',
  },
];

export const QUESTION_STATUS = {
  pending: '未対応',
  in_progress: '対応中',
  done: '対応済み',
};

export const COMPANY_STATUS_OPTION = [
  {
    status: 'opening',
    title: 'アクティブ',
  },
  {
    status: 'closed',
    title: '停止中',
  },
];

export const COMPANY_STATUS = {
  opening: 'アクティブ',
  closed: '停止中',
};

export const GENDER_TYPE = {
  male: '男性',
  female: '女性',
};

export const NOTIFY_SETTING_TYPE = {
  accept: '受け取る',
  deny: '受け取らない',
};

export const TESTING_TYPE_URL = {
  finish_test: 'final-testing',
  pre_test: 'pre-testing',
  verify_test: 'confirm-testing',
};

export const TEST_TYPE_URL = {
  finish_test: 'final-test',
  pre_test: 'pre-test',
  verify_test: 'confirm-test',
};

export const SURVEY_TYPE_URL = {
  finish_survey: 'final-survey',
  pre_survey: 'pre-survey',
};

export const COURSE_DURATION_TITLE = {
  one_week: '1週間',
  two_weeks: '2週間',
  three_weeks: '3週間',
  one_month: '1ヶ月',
  two_months: '2ヶ月',
  three_months: '3ヶ月',
  four_months: '4ヶ月',
  five_months: '5ヶ月',
  six_months: '6ヶ月',
  seven_months: '7ヶ月',
  eight_months: '8ヶ月',
  nine_months: '9ヶ月',
  ten_months: '10ヶ月',
  eleven_months: '11ヶ月',
  twelve_months: '12ヶ月',
};

export const FINISH_TEST_STATUS = {
  passed: 0,
  waiting: 1,
  failed: 2,
  unfinished: 3,
  unexist: 4
}

export const COURSE_PERIOD = {
  inRecommendedPeriod: {
    status: 0,
    title: "完了推奨期間中",
  },
  inCoursePeriod: {
    status: 1,
    title: "受講期間中",
  },
  endedCourse: {
    status: 2,
    title: "受講期間終了",
  },
  unstartedCourse: {
    status: 3,
    title: "開始待ち",
  },
};

export const STATUS_USER = {
  BLOCK: "blocked",
  ACTIVE: "active"
}
