// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { MainContainer } from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';

import {
  getCheckVideo as getCheckVideoAction,
} from 'src/redux/actions';

import { PAGE_TITLE, CheckVideoTitle } from 'src/constants/pageTitle';

import * as Style from './styles';

declare global {
  interface Window { LMSCheckVideoPlayer: any; }
}
interface ICheckVideoPageProps extends StateProps, DispatchProps {
  getCheckVideo (): void;
  checkVideo: {
    data: {
      id: number;
      name: string;
      teacherName: string;
      vmsObfuscatedMid: string;
      vmsMid: number;
      duration: number;
      thumbnailUrl: string;
      smallThumbnailUrl: string;
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    errors: string;
  };
}

const CheckVideoPage: React.FC<ICheckVideoPageProps> = ({
  getCheckVideo,
  checkVideo,
}) => {
  const videoContentRef = useRef<any>(null);

  useEffect(() => {
    document.title = PAGE_TITLE(CheckVideoTitle);

    getCheckVideo();
  }, []);

  useEffect(() => {
    if (checkVideo.data.id) {
      const videoFunction = `(function initPlayer() {
        var player = jstream_t3.PlayerFactoryIF.create({
          b: "${process.env.REACT_APP_BASE_PLAYER_CONFIG}",
          c: "${process.env.REACT_APP_CONTRACT_ID}",
          m: "${checkVideo.data.vmsObfuscatedMid}",
          t: "0",
          s: {
            wp: "${window.innerWidth ? window.innerWidth : 1920}",
            hp: "${window.innerWidth ? window.innerWidth * 9 / 16 : 1080}",
            il: "off",
            pr: "0.5,0.7,1.2,1.5,2.0",
            sn: "",
            tg: "off",
            ip: "off",
            rp: "on",
            rs: "on",
            skb: "10",
            dq: "3",
            mdq: "3",
            is: "off",
            ti: "off",
          },
        }, "checkVideo");
        window.LMSCheckVideoPlayer = player;
      })();`;

      const videoScript = document.createElement('script');
      videoScript.innerHTML = videoFunction;
      videoScript.type = 'application/javascript';

      document.body.appendChild(videoScript);

      return function cleanVideoScript() {
        document.body.removeChild(videoScript);
      };
    }
  }, [checkVideo.data.id]);

  return (
    <MainContainer isStudent>
      <Style.VideoSidebarContent>
        <Style.SidebarContainer>
          <Text margin="0 0 10px 0" xxl w6>LMS動作確認</Text>
          <Text w6>動作確認のため、以下の動作に問題がないかご確認下さい。</Text>
          <Text w6>・動作の再生/停止できること</Text>
          <Text w6>・動作の音声が流れること</Text>
          <Text w6>・動作の再生速度を変更できること</Text>
          <Text w6>・動画の画質を変更できること</Text>
        </Style.SidebarContainer>
      </Style.VideoSidebarContent>
      <Style.VideoMainContent>
        <Text white h3 w6>LMS動作確認</Text>
        <Style.VideoContainer>
          <Style.VideoContent ref={videoContentRef}>
            <Style.VideoTitle>
              <Text white lg w6>動作確認用動画</Text>
            </Style.VideoTitle>
            <Style.VideoPlayerContainer>
              <Style.VideoPlayerContent>
                <div id="checkVideo" />
              </Style.VideoPlayerContent>
            </Style.VideoPlayerContainer>
          </Style.VideoContent>
        </Style.VideoContainer>
      </Style.VideoMainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { checkVideo } = state.masterDataReducer;
  return {
    checkVideo,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCheckVideo: (params) => dispatch(getCheckVideoAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CheckVideoPage);
