// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class PlanServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getPlanList() {
    return this.restClient.get('/admin_manage/plans');
  }

  createPlan(params) {
    return this.restClient.post('/admin_manage/plans', params);
  }

  updatePlan(params) {
    const { requestData , id } = params;
    return this.restClient.put(`/admin_manage/plans/${id}`, requestData);
  }

  deletePlan(params) {
    return this.restClient.delete(`/admin_manage/plans/${params.id}`);
  }
}
