//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Space,
  Progress,
  Tooltip,
} from 'antd';
import { isEmpty, isNumber } from 'lodash/lang';

import history from 'src/utils/history';
import { formatDateTime, convertSecondTime } from 'src/utils/dateTime';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import { FilterSidebar } from 'src/components/layouts/Sidebar';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { ServicePath } from 'src/constants/routerConstants';
import { DATE_FORMAT, ACCOUNT_ROLES } from 'src/constants/common';
import { LoginStorage } from 'src/utils/localStorge';
import { handleStopPropagation } from 'src/utils/common';

import * as Style from './styles';

interface ICompanyStudentsProps {
  getCompanyStudents(params): void;
  getCourseDetail(params): void;
  showModal(params): void;
  banCompanyStudent(params): void;
  companyIdSelected: any;
  activeTabKey: string;
  companyDetail: {
    data: {
      licensesCount: number;
      remainingLicensesCount: number;
    };
  };
  companyStudents: {
    data: {
      studentList: {
        id: number;
        jituName: string;
        numberOfCourses: number;
        courses: {
          courseCategory: {
            name: string;
          };
          name: string;
        }[];
        department: string;
        status: string;
        jobType: string;
        jobTitle: string;
        email: string,
        roles: string[],
        customUserInfos: {
          companyCustomUserInfoId: number;
          fieldValue: string;
        }[];
        lastLearningDate: number;
        totalLearningTime: number;
        entireCoursesHighestTestScore: number;
        entireCoursesPercentage: number;
      }[];
      companyCustomInfos: {
        id: number;
        fieldName: string;
        isDisplay: boolean;
      }[];
      customDisplayField: {
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const CompanyStudents: React.FC<ICompanyStudentsProps> = ({
  getCompanyStudents,
  getCourseDetail,
  showModal,
  banCompanyStudent,
  companyIdSelected,
  companyDetail,
  companyStudents,
  activeTabKey,
}) => {
  const filterParamsUrl = JSON.parse(history.location.state?.filterParams || '{}');
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const [paramsFilter, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});
  const userStorage = LoginStorage.getData();
  const { data, meta } = companyStudents;

  useEffect(() => {
    if (companyIdSelected && activeTabKey === '3') {
      setParamsFilter(filterParamsUrl || {})
      setSortCondition(sortConditionUrl || {})
      getCompanyStudents({
        id: companyIdSelected,
        requestData: {
          page: 1,
          ...pageUrl,
          items: pageUrl?.limit,
          ...filterParamsUrl,
          sort: sortConditionUrl
        },
      });
    }
  }, [companyIdSelected, activeTabKey]);

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey,
        filterParams: JSON.stringify(paramsFilter),
        sortCondition,
        page: meta.pageInfo,
        companyContractId: companyIdSelected,
        isSort: true,
      }
    })
  }, [sortCondition, paramsFilter, meta.pageInfo])

  useEffect(() => {
    setParamsFilter(filterParamsUrl || {})
    setSortCondition(sortConditionUrl || {})
  }, [activeTabKey])
  function redirectAddStudents() {
    return history.push({
      pathname: ServicePath.ManagerAccount,
      state: {
        key: '1',
        companyContractId: companyIdSelected,
      }, // activated add student account (of this company) tab in manager account screen
    });
  }

  function renderCoursesOfUser(coursesNames) {
    if (isEmpty(coursesNames)) return null;
    return coursesNames.map((name, courseIndex) => (
      <Text white key={`course-name-${courseIndex}`}>・{name}</Text>
    ));
  }

  function handleConfirmDeleteQuestion(e, userId) {
    handleStopPropagation(e);
    const modalProps = {
      isConfirm: true,
      modalData: {
        message: 'このユーザーを非アクティブしてもよろしいですか。',
        title: 'ユーザの非アクティブ化',
      },
      modalAction: {
        confirm: () => banCompanyStudent({ id: userId }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function getListCourseName(courses) {
    if (isEmpty(courses)) return [];
    return courses.map((course) => `${course.courseCategory.name}`);
  }

  const dataFilterFields = [
    {
      type: 'textInput',
      fieldParams: 'jituNameCont',
      title: '名前',
    },
    {
      type: 'textInput',
      fieldParams: 'departmentCont',
      title: '部署',
    },
    {
      type: 'textInput',
      fieldParams: 'jobTitleCont',
      title: '役職',
    },
    {
      type: 'textInput',
      fieldParams: 'jobTypeCont',
      title: '職種',
    },
    {
      type: 'multiSelect',
      title: '権限',
      fieldParams: 'roles',
      options: [
        { id: 'student', name: '受講者' },
        { id: 'admin_company', name: '企業管理者' },
        { id: 'admin_service', name: 'サービス管理者' },
      ],
    },
    {
      type: 'textInput',
      fieldParams: 'email',
      title: 'メールアドレス',
    },
  ];

  const tableData = data.studentList.map((user: any) => {
    const customInfoData = {};
    user.customUserInfos?.map((info) => {
      customInfoData[`item${info.companyCustomUserInfoId}`] = info.fieldValue;
      return null;
    });
    const newRoles = !isEmpty(user.roles)
      ? (user.roles || []).map((roleType) => ACCOUNT_ROLES[roleType]).toString()
      : user.roles;

    return {
      key: user.id,
      id: user.id,
      jituName: user.jituName,
      email: user.email,
      numberOfCourses: user.numberOfCourses,
      roles: newRoles,
      coursesNames: getListCourseName(user.courses),
      ...data.customDisplayField.department && { department: user.department },
      ...data.customDisplayField.jobType && { jobType: user.jobType },
      ...data.customDisplayField.jobTitle && { jobTitle: user.jobTitle },
      ...customInfoData,
      lastLearningDate: formatDateTime(user.lastLearningDate, DATE_FORMAT),
      totalLearningTime: convertSecondTime(user.totalLearningTime, 'HH:mm'),
      entireCoursesHighestTestScore: user.entireCoursesHighestTestScore,
      entireCoursesPercentage: user.entireCoursesPercentage || 0,
      status: user.status,
    };
  });

  const displayCompanyCustomInfos = data.companyCustomInfos.filter((info) => info.isDisplay);
  const customInfoColumns = displayCompanyCustomInfos.map((info) => ({
    title: info.fieldName,
    dataIndex: `item${info.id}`,
    render: (_, record) => (
      <TooltipParagraph isPreWrap rows={2}>
        {record[`item${info.id}`]}
      </TooltipParagraph>
    ),
    width: 200,
  }));

  const tableColumns = [
    {
      title:
        <SortableHeader
          type="jituName"
          title="名前"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={paramsFilter}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              ...paramsFilter,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'jituName',
      render: (_, record) => (
        <Style.CompanyStudentName {...(record.status === 'banned' && { style: { marginTop: 14 } } )}>
          <TooltipParagraph isPreWrap rows={2}>
            {record.jituName}
          </TooltipParagraph>
          {record.status === 'banned' && (
            <Style.CompanyStudentStatus>停止</Style.CompanyStudentStatus>
          )}
        </Style.CompanyStudentName>
      ),
      width: 300,
    },
    {
      title:
        <SortableHeader
          type="email"
          title="メールアドレス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={paramsFilter}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              ...paramsFilter,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'email',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.email}
        </TooltipParagraph>
      ),
      width: 300,
    },
    {
      title: 'アカウント権限',
      dataIndex: 'roles',
      width: 250,
    },
    {
      title:
        <SortableHeader
          type="numberOfCourses"
          title="コース数"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={paramsFilter}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              ...paramsFilter,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'numberOfCourses',
      render: (_, record) => (
        <Tooltip
          placement="right"
          title={renderCoursesOfUser(record.coursesNames)}
          overlayStyle={{ maxHeight: 600, overflow: 'scroll'}}
        >
          <Text>{record.numberOfCourses}</Text>
        </Tooltip>
      ),
      width: 150,
    },
    ...data.customDisplayField.department
      ? [{
        title:
          <SortableHeader
            type="department"
            title="部署"
            page={meta.pageInfo?.page}
            pageSize={meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={paramsFilter}
            dataParam={{
              id: companyIdSelected,
              requestData: {
                ...paramsFilter,
                page: meta.pageInfo?.page,
              },
            }}
            actionSort={getCompanyStudents}
            setAction={setSortCondition}
          />,
        dataIndex: 'department',
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.department}
          </TooltipParagraph>
        ),
        width: 200,
      }]
      : [],
    ...data.customDisplayField.jobType
      ? [{
        title:
          <SortableHeader
            type="jobTitle"
            title="役職"
            page={meta.pageInfo?.page}
            pageSize={meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={paramsFilter}
            dataParam={{
              id: companyIdSelected,
              requestData: {
                ...paramsFilter,
                page: meta.pageInfo?.page,
              },
            }}
            actionSort={getCompanyStudents}
            setAction={setSortCondition}
          />,
        dataIndex: 'jobTitle',
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.jobTitle}
          </TooltipParagraph>
        ),
        width: 200,
      }]
      : [],
    ...data.customDisplayField.jobTitle
      ? [{
        title:
          <SortableHeader
            type="jobType"
            title="職種"
            page={meta.pageInfo?.page}
            pageSize={meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={paramsFilter}
            dataParam={{
              id: companyIdSelected,
              requestData: {
                ...paramsFilter,
                page: meta.pageInfo?.page,
              },
            }}
            actionSort={getCompanyStudents}
            setAction={setSortCondition}
          />,
        dataIndex: 'jobType',
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.jobType}
          </TooltipParagraph>
        ),
        width: 200,
      }]
      : [],
    ...customInfoColumns,
    {
      title:
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={paramsFilter}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              ...paramsFilter,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'lastLearningDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={paramsFilter}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              ...paramsFilter,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="highestTestScore"
          title={
            <div>
              <Text white style={{ marginLeft: '2px' }}>テスト正答率</Text>
              <Text white style={{ marginLeft: '-10px' }}>（全テスト平均）</Text>
            </div>
          }
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={paramsFilter}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              ...paramsFilter,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'entireCoursesHighestTestScore',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>
            {isNumber(record.entireCoursesHighestTestScore) ? `${record.entireCoursesHighestTestScore}%` : 'ー'}
          </Text>
        </Space>
      ),
      width: 180,
    },
    {
      title:
        <SortableHeader
          type="coursesPercentage"
          title="進捗率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={paramsFilter}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              ...paramsFilter,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'entireCoursesPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.entireCoursesPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.entireCoursesPercentage} />
        </Space>
      ),
      width: 150,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Style.TableAction>
          <Button
            size="small"
            type="primary"
            danger
            ghost
            onClick={(e) => handleConfirmDeleteQuestion(e, record.id)}
            disabled={record.status === 'banned' || userStorage.user.id === record.id}
          >
            非アクティブ
          </Button>
        </Style.TableAction>
      ),
      width: 150,
    },
  ];

  const scrollPoint = 1380
    + (data.customDisplayField.department ? 200 : 0)
    + (data.customDisplayField.jobType ? 200 : 0)
    + (data.customDisplayField.jobTitle ? 200 : 0)
    + displayCompanyCustomInfos.length * 200;

  return (
    <>
      <Style.CompanyStudentAction>
        <Space align="center" size="middle">
          <Text xxxl>
            {`ユーザー数 ${companyStudents.meta.pageInfo?.count > 0
              ? companyStudents.meta.pageInfo?.count
              : 'ー'}`}
          </Text>
          <Text xxxl style={{ whiteSpace: 'inherit' }}>
            {`コースライセンス数 ${companyDetail.data.licensesCount > 0
              ? companyDetail.data.licensesCount
              : 'ー'}
            [残${companyDetail.data.remainingLicensesCount > 0
              ? companyDetail.data.remainingLicensesCount
              : 'ー'}] `}
          </Text>
        </Space>
        <Button type="primary" onClick={() => redirectAddStudents()}>
          ユーザー追加
        </Button>
      </Style.CompanyStudentAction>
      <Style.CompanyStudentContainer  >
        <Style.CompanyStudentSidebar>
          <FilterSidebar
            data={dataFilterFields}
            actionFilter={getCompanyStudents}
            setParamsFilter={setParamsFilter}
            setSortCondition={setSortCondition}
            dataParam={{
              id: companyIdSelected,
              requestData: {
                ...paramsFilter,
                page: meta.pageInfo?.page,
                items: meta.pageInfo?.limit,
              },
            }}
            filterParams={paramsFilter}
            pageSize={meta.pageInfo?.limit}
          />
        </Style.CompanyStudentSidebar>
        <Style.CompanyStudentContent>
          <Table
            className="table-cursor-pointer"
            pagination={false}
            scroll={{ x: scrollPoint, y: 506 }}
            columns={tableColumns}
            dataSource={tableData}
            onRow={(record) => ({
              onClick: () => {
                const urlProfile = userStorage.user.id === record.id ? 'my-profile' : `profile/${record.id}`;
                history.push(`/admin-service/${urlProfile}`);
              }
            })}
            locale={{ emptyText: 'データが存在しません。' }}
            loading={companyStudents.load}
          />
          {!isEmpty(companyStudents.data.studentList) && (
            <PaginationLayout
              pageInfo={meta.pageInfo}
              onChangePage={getCompanyStudents}
              filterParams={paramsFilter}
              sortCondition={sortCondition}
              dataParam={{
                id: companyIdSelected,
                requestData: {
                  ...paramsFilter,
                  page: meta.pageInfo?.page,
                  items: meta.pageInfo?.limit,
                },
              }}
            />
          )}
        </Style.CompanyStudentContent>
      </Style.CompanyStudentContainer>
    </>
  );
};

export default CompanyStudents;
