//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';
import { handleNavigateUnit } from '../../util';

import {
  getStudentGroupDetail as getStudentGroupDetailAction,
  setCollapseData as setStudentCollapseDataAction,
  finishedSurvey as finishedSurveyAction,
} from 'src/redux/actions';

import { Text, SkeletonLoading } from 'src/components/styles';

import prevUnitIcon from 'src/assets/images/videoPlayer/prev-video-icon.svg';
import nextUnitIcon from 'src/assets/images/videoPlayer/next-video-icon.svg';
import prevUnitDisabledIcon from 'src/assets/images/videoPlayer/prev-video-disabled-icon.svg';
import nextUnitDisabledIcon from 'src/assets/images/videoPlayer/next-video-disabled-icon.svg';

import * as Style from './styles';
import { ButtonBack, CourseNameTitle } from "../../styles"

interface ISurveyOverviewProps extends StateProps, DispatchProps {
  getStudentGroupDetail(params): void;
  setCollapseData(params): void;
  finishedSurvey(params): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  courseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
  unitDetail: {
    data: {
      id: number;
      unit: {
        name: string;
      };
      item: {
        description: string;
        url: string;
      };
    };
    meta: {
      back: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
      next: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
    };
    load: boolean;
  };
}

const SurveyOverview: React.FC<ISurveyOverviewProps> = ({
  getStudentGroupDetail,
  setCollapseData,
  finishedSurvey,
  collapseData,
  courseDetail,
  unitDetail,
}) => {
  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[2];
  const unitVersionId = pathnameSplit[4];
  const groupId = getSearchParams('group') || '';
  const chapterId = getSearchParams('chapter') || '';

  useEffect(() => {
    if (window.innerWidth < 428 && !!unitDetail.data.item.url) {
      finishedSurvey({ courseId, unitVersionId, groupId, chapterId });
      window.open(unitDetail.data.item.url, '_blank');
    }
  }, [unitDetail])

  return (
    <>
      {courseDetail.load
        ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
        : <CourseNameTitle white w6 xxl>{courseDetail.data.courseCategory?.name}</CourseNameTitle>
      }
      <Style.SurveyDetailContainer>
        <Style.SurveyDetailContent>
          <Style.SurveyDetailTitle>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text truncate white lg w7>{unitDetail.data.unit.name}</Text>
            }
          </Style.SurveyDetailTitle>
          <Style.SurveyDescription>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : (
                <Text multiLine>
                  {unitDetail.data.item.description}
                </Text>
              )
            }
          </Style.SurveyDescription>
          <Style.SurveyFooter>
            <ButtonBack
              type="link"
              style={{ padding: 0 }}
              disabled={isEmpty(unitDetail.meta.back)}
              onClick={() => handleNavigateUnit({
                action: 'back',
                getStudentGroupDetail,
                setCollapseData,
                courseDetail,
                unitDetail,
                collapseData,
              })}
            >
              <img
                src={unitDetail.meta.back ? prevUnitIcon : prevUnitDisabledIcon}
                style={{ marginRight: 4 }}
                alt=""
              />
              戻る
            </ButtonBack>
            <Style.ButtonConfirmServey
              type="primary"
              onClick={() => {
                finishedSurvey({ courseId, unitVersionId, groupId, chapterId });
                window.open(unitDetail.data.item.url, '_blank');
              }}
              disabled={courseDetail.load || unitDetail.load}
            >
              回答する
            </Style.ButtonConfirmServey>
            <ButtonBack
              type="link"
              style={{ padding: 0 }}
              disabled={isEmpty(unitDetail.meta.next)}
              onClick={() => handleNavigateUnit({
                action: 'next',
                getStudentGroupDetail,
                setCollapseData,
                courseDetail,
                unitDetail,
                collapseData,
              })}
            >
              次へ
              <img
                src={unitDetail.meta.next ? nextUnitIcon : nextUnitDisabledIcon}
                style={{ marginLeft: 4 }}
                alt=""
              />
            </ButtonBack>
          </Style.SurveyFooter>
        </Style.SurveyDetailContent>
      </Style.SurveyDetailContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { collapseData } = state.commonReducer;
  const { courseDetail } = state.studentCourseReducer;
  const { unitDetail } = state.studentUnitReducer;
  return {
    collapseData,
    courseDetail,
    unitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentGroupDetail: (params) => dispatch(getStudentGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setStudentCollapseDataAction(params)),
  finishedSurvey: (params) => dispatch(finishedSurveyAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOverview);
