//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import {
  Collapse,
  Space,
  Spin,
} from 'antd';
import ReactTooltip from 'react-tooltip';
import {
  PlusOutlined,
  MinusOutlined,
  CheckCircleTwoTone,
  QuestionCircleOutlined
} from '@ant-design/icons';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { sortBy } from 'lodash/collection';
import moment from 'moment';

import { convertSecondTime, formatDateTime } from 'src/utils/dateTime';

import NoData from 'src/components/layouts/NoData';
import { renderItemTypeIcon } from 'src/utils/common';

import { Text } from 'src/components/styles';

import * as Style from './styles';

interface ICourseContentProps {
  getGroupDetail(params: any): void;
  courseDetail: {
    data: {
      id: number,
      studentProgressPercentage: number;
      recommendedStartDate: string;
      recommendedEndDate: string;
      groups: {
        id: number;
        name: string;
        chapters: {
          totalUnits: number;
          totalVideosTime: number;
        }[];
        totalUnits: number;
        totalVideosTime: number;
      }[];
    };
    load: boolean;
  };
  recommendDates : {
    data: {
      groupRecommendedDate: {
        groupId: number;
        endDate: string;
        isDelayed: boolean;
      }[]
    },
  }
}

const CourseContent: React.FC<ICourseContentProps> = ({
  getGroupDetail,
  courseDetail,
  recommendDates
}) => {
  const [collapsesActivated, setCollapsesActivated] = useState<any>([]);
  const [groupActive, setGroupActive] = useState<number>(-1);

  const { groupRecommendedDate } = recommendDates.data

  useEffect(() => {
    if (!isEmpty(collapsesActivated) && groupActive !== -1) {
      const collapseNew = collapsesActivated.map((itemCollapse, indexCollapse) => {
        const groupIndex = itemCollapse.key.toString().substring(6);
        if (courseDetail.data.groups[groupIndex].chapters && groupActive === indexCollapse) {
          const chapters = (
            courseDetail?.data?.groups[groupIndex]?.chapters || []
          ).map((_, index) => ({ key: `${itemCollapse.key}-chapter-${index}` }));
          return { key: itemCollapse.key, chapters };
        }
        return { ...itemCollapse };
      });
      setCollapsesActivated(collapseNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetail]);

  function handleChangeGroupCollapse(collapseValues) {
    const newCollapseValues = sortBy(collapseValues);
    const newCollapseData = newCollapseValues.map((value, indexCollapse) => {
      const groupActiveIndex = findIndex(collapsesActivated, { key: value });
      if (groupActiveIndex === -1) {
        let chapters: any = [];
        const groupIndex = value.toString().substring(6);
        if (courseDetail?.data?.groups[groupIndex]?.chapters) {
          chapters = (
            courseDetail?.data?.groups[groupIndex]?.chapters || []
          ).map((item, index) => ({ key: `${value}-chapter-${index}` }));
        } else {
          setGroupActive(indexCollapse);
        }
        return { key: value, chapters };
      }
      return collapsesActivated[groupActiveIndex];
    });
    setCollapsesActivated([...newCollapseData]);
  }

  function handleChangeChapterCollapse(collapseValues, groupActiveIndex) {
    const newCollapseValues = sortBy(collapseValues);
    const newCollapseData = collapsesActivated;
    const chapterCollapse = isEmpty(newCollapseValues) ? [] : newCollapseValues.map((value) => {
      const chapterActiveIndex = findIndex(newCollapseData[groupActiveIndex].chapters, { key: value });
      if (chapterActiveIndex === -1) {
        return { key: value };
      }
      return newCollapseData[groupActiveIndex].chapters[chapterActiveIndex];
    });
    newCollapseData[groupActiveIndex].chapters = chapterCollapse;
    setCollapsesActivated([...newCollapseData]);
  }

  function handleViewGroupDetail(index, id) {
    if (!courseDetail.data.groups[index].chapters) {
      getGroupDetail({ courseId: courseDetail.data.id, groupId: id, groupIndex: index });
    }
  }

  function renderUnitItems(chapterData, indexKey, groupId) {
    if (isEmpty(chapterData.unitVersions)) {
      return (
        <Style.NoUnitItemData>
          <Text>データが存在しません。</Text>
        </Style.NoUnitItemData>
      );
    }
    return chapterData.unitVersions.map((unitItem, indexUnit) => (
      <Style.UnitItemContent
        key={`${indexKey}-unit-${indexUnit}`}
        onClick={() => window.open(
          `/learn-course/${courseDetail.data.id}/${unitItem.item.type}/${unitItem.id}?group=${groupId}&chapter=${chapterData.id}`,
          '_blank',
        )}
      >
        <Style.UnitItemName style={{ width: '75%' }}>
          <Space align="center" size={4}>
            <Style.ItemTypeIcon>
              <img src={renderItemTypeIcon(unitItem.item.type)} alt="" />
            </Style.ItemTypeIcon>
            <Text lg truncate isPreWrap>{unitItem.unit.name}</Text>
          </Space>
        </Style.UnitItemName>
        <div style={{ width: '15%' }}>
          {unitItem.item.type === 'video' && (
            <Text headerText lg>
              {convertSecondTime(unitItem.item.duration, 'HH:mm:ss')}
            </Text>
          )}
          {unitItem.item.type === 'test' && (
            <Text headerText lg>{`${unitItem.item.questionsCount}問`}</Text>
          )}
        </div>
        <Style.DoneUnitIcon>
          {unitItem.isDone && <CheckCircleTwoTone style={{ fontSize: 16 }} twoToneColor="#D29C09" />}
        </Style.DoneUnitIcon>
      </Style.UnitItemContent>
    ));
  }

  function renderChapterCollapse(chapters, indexKey, chapterActiveKey, groupId) {
    if (isEmpty(chapters)) {
      return (
        <Style.NoChapterData>
          <Text>データが存在しません。</Text>
        </Style.NoChapterData>
      );
    }
    return chapters.map((chapterCollapse, indexChapterCollapse) => (
      <Collapse.Panel
        key={`${indexKey}-chapter-${indexChapterCollapse}`}
        header={
          <Style.CollapseHeader >
            <div style={{ width: '60%' }}>
              <Text lg w6 style={{ lineHeight: '20px', width: 'calc(100% - 100px)' }}>
                {chapterCollapse.name}
              </Text>
            </div>
            {!chapterActiveKey.includes(`${indexKey}-chapter-${indexChapterCollapse}`) && (
              <>
                <div style={{ width: '15%' }}>
                  <Text lg>{chapterCollapse.totalUnits}レッスン</Text>
                </div>
                <div style={{ width: '25%' }}>
                  <Text lg>
                    {convertSecondTime(chapterCollapse.totalVideosTime, 'HH:mm:ss')}
                  </Text>
                </div>
                <div style={{width: "25%"}}></div>
              </>
            )}
          </Style.CollapseHeader>
        }
      >
        {renderUnitItems(chapterCollapse, `${indexKey}-chapter-${indexChapterCollapse}`, groupId)}
      </Collapse.Panel>
    ));
  }

  function renderGroupCollapse() {
    if (isEmpty(courseDetail.data.groups)) return <NoData message="データが存在しません。" />;
    return courseDetail.data.groups.map((groupCollapse, groupCollapseIndex) => {
      const groupActiveIndex = findIndex(collapsesActivated, { key: `group-${groupCollapseIndex}` });
      const chapterCollapseData = groupActiveIndex !== -1 ? collapsesActivated[groupActiveIndex].chapters : [];
      const chapterActiveKey = chapterCollapseData.map((collapse) => collapse.key);
      const dataRecommendDateItem = groupRecommendedDate.filter((groupItem) => groupItem.groupId === groupCollapse.id)

      return (
        <Collapse.Panel
          key={`group-${groupCollapseIndex}`}
          header={
            <Style.CollapseHeader onClick={() => handleViewGroupDetail(groupCollapseIndex, groupCollapse.id)}>
              <div style={{ width: '60%' }}>
                <Text xl w6 style={{ lineHeight: '20px', width: 'calc(100% - 100px)' }}>
                  {groupCollapse.name}
                </Text>
              </div>
              {groupActiveIndex === -1 && (
                <>
                  <div style={{ width: '15%' }}>
                    <Text lg>{groupCollapse.totalUnits}レッスン</Text>
                  </div>
                  <div style={{ width: '25%' }}>
                    <Text lg>
                      {convertSecondTime(groupCollapse.totalVideosTime, 'HH:mm:ss')}
                    </Text>
                  </div>
                  <div style={{ width: '25%' }}>
                    {isEmpty(dataRecommendDateItem) || !dataRecommendDateItem[0].endDate ?
                      <Text margin={"0 0 0 24px"}>ー</Text>
                      : (
                        <Text lg color={dataRecommendDateItem[0].isDelayed && "red"}>
                          {moment(dataRecommendDateItem[0].endDate).format('YYYY/MM/DD')}
                        </Text>
                      )
                    }
                  </div>
                </>
              )}
              {/* Fix onClick Icon not call API  */}
              <Style.CollapseIcon>
                {groupActiveIndex === -1
                  ? <PlusOutlined style={{ fontSize: 12 }} />
                  : <MinusOutlined  style={{ fontSize: 12 }} />
                }
              </Style.CollapseIcon>
            </Style.CollapseHeader>
          }
        >
          <Collapse
            activeKey={chapterActiveKey}
            bordered={false}
            expandIconPosition="right"
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined /> : <PlusOutlined />
            }
            style={{ backgroundColor: '#FAFAFA' }}
            onChange={(values) => handleChangeChapterCollapse(values, groupActiveIndex)}
          >
            {groupCollapse.chapters
              && renderChapterCollapse(
                groupCollapse.chapters,
                `group-${groupCollapseIndex}`,
                chapterActiveKey,
                groupCollapse.id,
              )}
          </Collapse>
        </Collapse.Panel>
      );
    });
  }

  function renderUnitItemsMobile(chapterData, indexKey, groupId) {
    if (isEmpty(chapterData.unitVersions)) {
      return (
        <Style.NoUnitItemData>
          <Text>データが存在しません。</Text>
        </Style.NoUnitItemData>
      );
    }
    return chapterData.unitVersions.map((unitItem, indexUnit) => (
      <Style.UnitItemContent
        key={`${indexKey}-unit-${indexUnit}`}
        onClick={() =>
          window.open(
            `/learn-course/${courseDetail.data.id}/${unitItem.item.type}/${unitItem.id}?group=${groupId}&chapter=${chapterData.id}`,
            "_blank"
          )
        }
      >
        <Style.UnitItemName style={{ width: "65%" }}>
          <Space align="center" size={4}>
            <Style.ItemTypeIcon>
              <img src={renderItemTypeIcon(unitItem.item.type)} alt="" />
            </Style.ItemTypeIcon>
            <Style.TextUnit lg truncate isPreWrap style={{ width: 130 }}>
              {unitItem.unit.name}
            </Style.TextUnit>
          </Space>
        </Style.UnitItemName>
        <div>
          {unitItem.item.type === "video" && (
            <Style.TextDuration headerText lg>
              {convertSecondTime(unitItem.item.duration, "HH:mm:ss")}
            </Style.TextDuration>
          )}
          {unitItem.item.type === "test" && (
            <Style.TextDuration
              headerText
              lg
            >{`${unitItem.item.questionsCount}問`}</Style.TextDuration>
          )}
        </div>
        <Style.DoneUnitIcon>
          {unitItem.isDone && (
            <CheckCircleTwoTone
              style={{ fontSize: 16 }}
              twoToneColor="#D29C09"
            />
          )}
        </Style.DoneUnitIcon>
      </Style.UnitItemContent>
    ));
  }

  function renderChapterCollapseMobile(chapters, indexKey, chapterActiveKey, groupId) {
    if (isEmpty(chapters)) {
      return (
        <Style.NoChapterData>
          <Text>データが存在しません。</Text>
        </Style.NoChapterData>
      );
    }
    return chapters.map((chapterCollapse, indexChapterCollapse) => (
      <Collapse.Panel
        key={`${indexKey}-chapter-${indexChapterCollapse}`}
        header={
          <Style.CollapseHeader >
            <div style={{ width: '40%' }}>
              <Style.ChapterName md w6>
                {chapterCollapse.name}
              </Style.ChapterName>
            </div>
            {!chapterActiveKey.includes(`${indexKey}-chapter-${indexChapterCollapse}`) && (
              <>
                <div style={{ width: '30%' }}>
                  <Text sm right style={{ marginRight: 13 }}>{chapterCollapse.totalUnits}</Text>
                </div>
                <div style={{ width: '30%' }}>
                  <Text sm center>
                    {convertSecondTime(chapterCollapse.totalVideosTime, 'HH:mm:ss')}
                  </Text>
                </div>
              </>
            )}
          </Style.CollapseHeader>
        }
      >
        {renderUnitItemsMobile(chapterCollapse, `${indexKey}-chapter-${indexChapterCollapse}`, groupId)}
      </Collapse.Panel>
    ));
  }

  function renderGroupCollapseMobile() {
    if (isEmpty(courseDetail.data.groups)) return <NoData message="データが存在しません。" />;
    return courseDetail.data.groups.map((groupCollapse, groupCollapseIndex) => {
      const groupActiveIndex = findIndex(collapsesActivated, { key: `group-${groupCollapseIndex}` });
      const chapterCollapseData = groupActiveIndex !== -1 ? collapsesActivated[groupActiveIndex].chapters : [];
      const chapterActiveKey = chapterCollapseData.map((collapse) => collapse.key);

      return (
        <Collapse.Panel
          key={`group-${groupCollapseIndex}`}
          header={
            <Style.CollapseHeader onClick={() => handleViewGroupDetail(groupCollapseIndex, groupCollapse.id)}>
              <div style={{ width: '40%' }}>
                <Style.GroupName md w6 >
                  {groupCollapse.name}
                </Style.GroupName>
              </div>
              {groupActiveIndex === -1 && (
                <>
                  <div style={{ width: '30%' }}>
                    <Text sm right style={{ marginRight: 13 }}>{groupCollapse.totalUnits}</Text>
                  </div>
                  <div style={{ width: '30%' }}>
                    <Text sm center>
                      {convertSecondTime(groupCollapse.totalVideosTime, 'HH:mm:ss')}
                    </Text>
                  </div>
                </>
              )}
            </Style.CollapseHeader>
          }
        >
          <Collapse
            activeKey={chapterActiveKey}
            bordered={false}
            expandIconPosition="right"
            expandIcon={() => null}
            style={{ backgroundColor: '#FAFAFA' }}
            onChange={(values) => handleChangeChapterCollapse(values, groupActiveIndex)}
          >
            {groupCollapse.chapters
              && renderChapterCollapseMobile(
                groupCollapse.chapters,
                `group-${groupCollapseIndex}`,
                chapterActiveKey,
                groupCollapse.id,
              )}
          </Collapse>
        </Collapse.Panel>
      );
    });
  }

  return (
    <>
      <Style.CourseContentHeader>
        <Style.CourseContentTitle>
          <Style.TextHeader xxxl style={{ marginRight: 16 }}>コース内容</Style.TextHeader>
          <Style.ProgressDesktop
            type="circle"
            strokeColor="#D29C09"
            percent={courseDetail.data.studentProgressPercentage}
            width={40}
          />
          <Style.ProgressMobile
            type="circle"
            strokeColor="#D29C09"
            percent={courseDetail.data.studentProgressPercentage}
            width={40}
            format={(percent) => `${percent}%`}
          />
        </Style.CourseContentTitle>
        {courseDetail.data.recommendedStartDate && courseDetail.data.recommendedEndDate && !isEmpty(groupRecommendedDate) &&
          <Style.TextTitle margin={"0 4px 0 0"} lg>
            {`完了推奨期間 ${formatDateTime(courseDetail.data.recommendedStartDate, 'YYYY/MM/DD')} ~ ${formatDateTime(courseDetail.data.recommendedEndDate, 'YYYY/MM/DD')}`}
          </Style.TextTitle>
        }
      </Style.CourseContentHeader>
      <Style.CourseContent>
        <Spin spinning={courseDetail.load}>
          <Style.HeaderWrapper>
            <div style={{ width: '60%' }}>
              <Text white lg w6>レッスン名</Text>
            </div>
            <div style={{ width: '15%' }}>
              <Text white lg w6>レッスン数</Text>
            </div>
            <div style={{ width: '25%' }}>
              <Text white lg w6>時間・問題数</Text>
            </div>
            <div style={{ width: '25%' }}>
              <Text white lg w6>推奨完了日&nbsp;&nbsp;
                <QuestionCircleOutlined data-tip data-for="questionTip"/>
              </Text>
              <ReactTooltip id="questionTip" place="top" effect="solid" backgroundColor="#0D132D">
                <Text white>各レッスンを計画的に進める <br/> 目安となる完了日を表します</Text>
              </ReactTooltip>
            </div>
          </Style.HeaderWrapper>
          <Collapse
            bordered={false}
            expandIconPosition="right"
            expandIcon={() => null} // Fix onClick Icon not call API
            style={{ backgroundColor: '#FFFFFF' }}
            onChange={(values) => handleChangeGroupCollapse(values)}
          >
            {renderGroupCollapse()}
          </Collapse>
        </Spin>
      </Style.CourseContent>
      <Style.CourseContentMobile>
        <Spin spinning={courseDetail.load}>
          <Style.HeaderWrapper>
            <div style={{ width: '40%' }}>
              <Style.TextHeaderTable white sm center>レッスン名</Style.TextHeaderTable>
            </div>
            <div style={{ width: '30%' }}>
              <Style.TextHeaderTable white sm center>レッスン数</Style.TextHeaderTable>
            </div>
            <div style={{ width: '30%' }}>
              <Style.TextHeaderTable white sm center>時間</Style.TextHeaderTable>
            </div>
          </Style.HeaderWrapper>
          <Collapse
            bordered={false}
            expandIconPosition="right"
            expandIcon={() => null} // Fix onClick Icon not call API
            style={{ backgroundColor: '#FFFFFF' }}
            onChange={(values) => handleChangeGroupCollapse(values)}
          >
            {renderGroupCollapseMobile()}
          </Collapse>
        </Spin>
      </Style.CourseContentMobile>
    </>
  );
};

export default CourseContent;
