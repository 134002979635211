// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Tag, Button } from 'antd';
import { Text } from 'src/components/styles';
import { CheckOutlined } from '@ant-design/icons';
export const TestResultContainer = styled.div`
  padding: 24px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & .ant-collapse-header .ant-tag {
      padding: 3px 8px 0px 6px;
    }
    & .ant-collapse-header .ant-typography {
      margin-top: 16px;
    }
    & .ant-collapse-header p { 
      margin-top: 16px;
    }
  }
  & .ant-collapse-header{
    min-height: 50px !important ;
    padding: 0 40px 0 16px !important;
  }

  & .ant-collapse-item{
    border-bottom: 1px solid #CCCCCC !important;
  }

  & .ant-collapse-content-active{
    min-height: 50px;
  }

  @media only screen and (max-width: 428px) {
    padding: 0;

    & .ant-collapse-header{
      min-height: 50px !important ;
      padding: 0 8px !important;
    }

    & .header-wrapper {
      padding: 0 8px !important;
    }

    & .answer-radio-button {
      width: 100% !important;
      min-height: 30px;
      font-size: 14px;
      border: none;
      padding: 0 16px;
      margin: 0;
    }

    .ant-radio-button-wrapper-checked {
      background: none !important;
      color: #343A40 !important;
      border: 2px solid #D29C09;
    }
    .ant-radio-button-wrapper {
      height: unset !important;
    }
  }
`;

export const CheckOutlinedMobile = styled(CheckOutlined)`
  display: none;
  margin-right: 16px;
  color: #5FC73A;
  font-size: 14px;
  @media only screen and (max-width: 428px) {
    display: block;
  }
`

export const CheckOutlinedDesktop = styled(CheckOutlined)`
  display: block;
  margin-left: 16px;
  color: #5FC73A;
  @media only screen and (max-width: 428px) {
    display: none;
  }
`

export const TestResultOverview = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  @media only screen and (max-width: 428px) {
    margin-bottom: 24px;
  }
`;

export const TestResultDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TestResultScore = styled.div`
  display: flex;
  align-items: baseline;
`;

export const TestResultStatus = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  height: 24px;
  font-size: 14px;
  width: 64px;

  @media only screen and (max-width: 428px) {
    margin-top: 8px;
    font-size: 11px;
  }
`;

export const DownloadExplanation = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  @media only screen and (max-width: 428px) {
    bottom: -24px;
  }
`;

export const TestResultFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  padding: 0 24px 24px;
`;

export const CollapseItemName = styled.div`
  width: ${(props) => props.width || 'auto'};

  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const CollapseItemNameMobile = styled.div`
  display: none;
  width: ${(props) => props.width || 'auto'};

  @media only screen and (max-width: 428px) {
    display: block;
    text-align: center;
  }
`;

export const  CollapseHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;

  & .collapse-header-button {
    display: none;
  }
`;

export const TestContainer = styled.div`
  background-color: #FAFAFA;
  padding: 18px 16px;
  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const TestContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 428px) {
    display: block;
  }
`

export const AnswerOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${(props) => props.isRight
    && css`
      justify-content: space-between;
    `}
`;

export const AnswerOptionWrapper = styled.div`
    @media only screen and (max-width: 428px) {
      display: flex;
      align-items: center;
      justify-content: start;
      width: 100% !important;

      ${(props) => !props.isRight
        && css`
          margin-left: 32px;
      `}
  }
`

export const TestQuestion = styled.div`
  min-height: 50px;
  border-bottom: 2px solid #a09f9f;
  box-sizing: border-box;
  padding-bottom: 16px;

  @media only screen and (max-width: 428px) {
    border: none;
    padding-bottom: 0;
  }
`

export const TestAnswer = styled.div`
  display: flex;
  flex-direction: column;
`

export const QuestionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;

  @media only screen and (max-width: 428px) {
    padding: 8px 16px;
  }
`;

export const QuestionImageContent = styled.img`
  max-width: 100%;
  max-height: 500px;
`;

export const QuestionContent = styled.div`
  padding: 16px;
  background-color: white;

  & p {
    word-break: break-word;
  }

  & img{
    max-width: 100%;
  }
  @media only screen and (max-width: 428px) {
    padding: 8px 16px;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      height: 5px;
    }
  }
`;

export const UploadFileType = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  & p {
    display: block;
    background-color: #d29c09;
    width: 350px;
    color: white; 
    padding: 12px 16px;
    border-radius: 8px;
  }

  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const UploadFileTypeMobile = styled.div`
  display: none;
  height: 50px;
  padding: 0 16px 8px;

  @media only screen and (max-width: 428px) {
    display: flex;
    height: auto;
  }
`

export const InputTypeBlock = styled.div`
  padding: 16px;
  background-color: white;
  & div{
    display: flex ;
  }
`;

export const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: #FAFAFA;
`;

export const HeaderWrapper = styled.div`
  padding: 0 16px;
`;

export const EmptyData = styled.div`
  height: 50px;
  margin-top: 24px;
  text-align: center;
`

export const TextEllipsis = styled.p`
    white-space: nowrap; 
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis; 
`

export const TextTitle = styled(Text)`
  @media only screen and (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
  }
`

export const TextScore = styled(Text)`
  @media only screen and (max-width: 428px) {
    font-size: 32px;
    font-weight: 700;
  }
`

export const ButtonDownloadTestMobile = styled(Button)`
  display: none;
  @media only screen and (max-width: 428px) {
    display: block;
    font-size: 12px !important;
  }
`

export const ButtonDownloadTestDesktop = styled(Button)`
  display: block;
  @media only screen and (max-width: 428px) {
    display: none;
  }
`

export const TitleHeaderTable = styled(Text)`
  @media only screen and (max-width: 428px) {
    font-size: 13px;
    font-weight: 700;
  }
`

export const TextTitleAnswer = styled(Text)`
  margin: 0 8px 0 0;
  @media only screen and (max-width: 428px) {
    font-weight: normal;
  }
`

export const TagResultCustom = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  & > img {
    width: 12px;
    height: 12px;
  }
`
