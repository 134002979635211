// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { findIndex } from 'lodash/array';
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  teacherList: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
};

export type TeacherReducer = Readonly<typeof initialState>;

export default (state: TeacherReducer = initialState, action: any): TeacherReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.TEACHER_ACTION.GET_LIST): {
    return {
      ...state,
      teacherList: {
        ...state.teacherList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.TEACHER_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      teacherList: {
        data: data.teachers,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEACHER_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      teacherList: {
        ...state.teacherList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEACHER_ACTION.UPDATE_TEACHER): {
    const { data } = action.payload;
    const newTeacherList = state.teacherList.data;
    const updatedTeacherIndex = findIndex(state.teacherList.data, { id: data.id });
    newTeacherList.splice(updatedTeacherIndex, 1, data );
    return {
      ...state,
      teacherList: {
        ...state.teacherList,
        data: newTeacherList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEACHER_ACTION.UPDATE_TEACHER): {
    const { errors } = action;
    return {
      ...state,
      teacherList: {
        ...state.teacherList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEACHER_ACTION.DELETE_TEACHER): {
    const { data } = action.payload;
    const newTeacherList = state.teacherList.data;
    const removeTeacherIndex = findIndex(state.teacherList.data, { id: data.id });
    newTeacherList.splice(removeTeacherIndex, 1);
    return {
      ...state,
      teacherList: {
        ...state.teacherList,
        data: newTeacherList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEACHER_ACTION.DELETE_TEACHER): {
    const { errors } = action;
    return {
      ...state,
      teacherList: {
        ...state.teacherList,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
