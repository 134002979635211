//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from "react"
import { connect } from 'react-redux';
import * as Style from "./styles"
interface ISwitchProps extends StateToProps{
  status: boolean
  onSwitch(): void;
  isDisableRequest: boolean
}

const Switch: React.FC<ISwitchProps> = ({ status, onSwitch, isDisableRequest }) => {
  return <Style.Switch disable={isDisableRequest}>
    <button onClick={onSwitch} className={status ? "on" : "off"} disabled={isDisableRequest}>
      <span >{status ? "ON" : "OFF"}</span>
    </button>
  </Style.Switch>
}

const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, null)(Switch);

