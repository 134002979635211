//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Space,
} from 'antd';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';
import { handleNavigateUnit } from '../../util';

import { Text, SkeletonLoading } from 'src/components/styles';

import {
  getStudentGroupDetail as getStudentGroupDetailAction,
  setCollapseData as setStudentCollapseDataAction,
  joinStudentTest as joinStudentTestAction,
  getTestResults as getTestResultsAction,
} from 'src/redux/actions';

import prevUnitIcon from 'src/assets/images/videoPlayer/prev-video-icon.svg';
import nextUnitIcon from 'src/assets/images/videoPlayer/next-video-icon.svg';
import prevUnitDisabledIcon from 'src/assets/images/videoPlayer/prev-video-disabled-icon.svg';
import nextUnitDisabledIcon from 'src/assets/images/videoPlayer/next-video-disabled-icon.svg';

import * as Style from './styles';
import { isAndroidDevice } from 'src/utils/common';
import { ButtonBack, CourseNameTitle } from "../../styles"

interface ITestExplainProps extends StateProps, DispatchProps {
  getStudentGroupDetail(params): void;
  setCollapseData(params): void;
  joinStudentTest(params): void;
  getTestResults(params): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  courseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
  unitDetail: {
    data: {
      id: number;
      isDone: boolean;
      unit: {
        name: string;
      };
      item: {
        limitTime: number;
        description: string;
        questionsCount: number;
        testType: string;
        type: string;
        limitAttempt: number;
        usedAttemptsCount: number;
        latestTestsUserId: number;
        isUploadTypeQuestionExist: boolean;
      };
    };
    meta: {
      back: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
      next: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
    };
    load: boolean;
  };
}

const TestExplain: React.FC<ITestExplainProps> = ({
  getStudentGroupDetail,
  setCollapseData,
  joinStudentTest,
  getTestResults,
  collapseData,
  courseDetail,
  unitDetail,
}) => {
  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[2];
  const unitVersionId = pathnameSplit[4];
  const groupId = getSearchParams('group') || '';
  const chapterId = getSearchParams('chapter') || '';

  const [isAndroidMobile, setIsAndroidMobile] = useState<boolean>(false)

  useEffect(() => {
    setIsAndroidMobile((window.innerWidth < 428) && isAndroidDevice())
    window.addEventListener('resize', () => {
      setIsAndroidMobile((window.innerWidth < 428) && isAndroidDevice())
    });
  }, []);

  function renderTestLimit() {
    if (unitDetail.data.item.limitAttempt === 0) {
      return '無制限';
    } if (unitDetail.data.item.usedAttemptsCount > unitDetail.data.item.limitAttempt) {
      return `${unitDetail.data.item.limitAttempt}/${unitDetail.data.item.limitAttempt}`;
    } else {
      return `${unitDetail.data.item.usedAttemptsCount}/${unitDetail.data.item.limitAttempt}`;
    }
  }

  return (
    <>
      {courseDetail.load
        ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
        : <CourseNameTitle white w6 xxl>{courseDetail.data.courseCategory?.name}</CourseNameTitle>
      }
      <Style.TestDetailContainer>
        <Style.TestDetailContent>
          <Style.TestDetailTitle>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Style.TitleTest truncate white lg w7>{unitDetail.data.unit.name}</Style.TitleTest>
            }
          </Style.TestDetailTitle>
          <Style.TestDescription>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : <Text multiLine>{unitDetail.data.item.description}</Text>
            }
          </Style.TestDescription>
          <Style.TestFooter>
            <Style.TestAction>
              <Style.InfoTestContainer>
                {courseDetail.load || unitDetail.load
                  ? (
                    <>
                      <Space align="center" size="large">
                        <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                      </Space>
                      <Space align="center" size="large">
                        <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                      </Space>
                      <Space align="center" size="large">
                        <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                      </Space>
                      <Space align="center" size="large">
                        <SkeletonLoading active paragraph={false} title={{ width: 150 }} />
                      </Space>
                    </>
                  )
                  : (
                    <>
                      <Style.InfoTest align="center" size="large">
                        <Style.LabelInfoTest xs subText w6>問題数</Style.LabelInfoTest>
                        <Style.ValueInfoTest headerText w6>{`${unitDetail.data.item.questionsCount}問`}</Style.ValueInfoTest>
                      </Style.InfoTest>
                      <Style.InfoTest align="center" size="large">
                        <Style.LabelInfoTest xs subText w6>制限時間</Style.LabelInfoTest>
                        <Style.ValueInfoTest headerText w6>
                          {unitDetail.data.item.limitTime > 0 ?
                            `${unitDetail.data.item.limitTime / 60}分`
                            : '無制限'
                          }
                        </Style.ValueInfoTest>
                      </Style.InfoTest>
                      <Style.InfoTest align="center" size="large">
                        <Style.LabelInfoTest xs subText w6>受講回数</Style.LabelInfoTest>
                        <Style.ValueInfoTest headerText w6>
                          {renderTestLimit()}
                        </Style.ValueInfoTest>
                      </Style.InfoTest>
                      {unitDetail.data.isDone && (
                        <Style.ButtonContainer>
                          <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => getTestResults({
                              courseId,
                              requestData: {
                                testsUserId: unitDetail.data.item.latestTestsUserId,
                                groupId,
                                chapterId,
                              },
                            })}
                          >
                            最新のテスト結果を見る
                          </Button>
                        </Style.ButtonContainer>
                      )}
                    </>
                  )
                }
              </Style.InfoTestContainer>
              <Button
                type="primary"
                className="join-test"
                onClick={() => joinStudentTest({
                  courseId,
                  testType: unitDetail.data.item.testType,
                  requestData: {
                    groupId: parseFloat(groupId),
                    chapterId: parseFloat(chapterId),
                    unitVersionId: parseFloat(unitVersionId),
                  },
                })}
                disabled={courseDetail.load || unitDetail.load}
              >
                テストを受ける
              </Button>
            </Style.TestAction>
            <Style.NoteText color="red" xs >
              ※テスト開始後に途中でブラウザを閉じると、0点で受験回数がカウントされるため、受験回数に制限がある場合は注意が必要です。<br />
              時間が十分に取れるタイミングで、テストを受けるようにしてください。
            </Style.NoteText>
            <br/>
            {isAndroidMobile && unitDetail?.data?.item?.isUploadTypeQuestionExist &&
              <Style.NoteText color="red" xs >
                スマートフォンでの受験は、テストの回答を正常に提出できない可能性がございます。PCでの受験をお願いします。
              </Style.NoteText>}
            <Style.TestNavigate>
              <ButtonBack
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(unitDetail.meta.back)}
                onClick={() => handleNavigateUnit({
                  action: 'back',
                  getStudentGroupDetail,
                  setCollapseData,
                  courseDetail,
                  unitDetail,
                  collapseData,
                })}
              >
                <img
                  src={unitDetail.meta.back ? prevUnitIcon : prevUnitDisabledIcon}
                  style={{ marginRight: 4 }}
                  alt=""
                />
                戻る
              </ButtonBack>
              <ButtonBack
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(unitDetail.meta.next)}
                onClick={() => handleNavigateUnit({
                  action: 'next',
                  getStudentGroupDetail,
                  setCollapseData,
                  courseDetail,
                  unitDetail,
                  collapseData,
                })}
              >
                次へ
                <img
                  src={unitDetail.meta.next ? nextUnitIcon : nextUnitDisabledIcon}
                  style={{ marginLeft: 4 }}
                  alt=""
                />
              </ButtonBack>
            </Style.TestNavigate>
          </Style.TestFooter>
        </Style.TestDetailContent>
      </Style.TestDetailContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { collapseData } = state.commonReducer;
  const { courseDetail } = state.studentCourseReducer;
  const { unitDetail } = state.studentUnitReducer;
  return {
    collapseData,
    courseDetail,
    unitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentGroupDetail: (params) => dispatch(getStudentGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setStudentCollapseDataAction(params)),
  joinStudentTest: (params) => dispatch(joinStudentTestAction(params)),
  getTestResults: (params) => dispatch(getTestResultsAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestExplain);
