// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
/**
 * Appends REQUEST async action type
 */

export const REQUEST = (actionType: any) => `${actionType}_REQUEST`;

/**
 * Appends SUCCESS async action type
 */

export const SUCCESS = (actionType: any) => `${actionType}_SUCCESS`;

/**
 * Appends FAILURE async action type
 */

export const FAILURE = (actionType: any) => `${actionType}_FAILURE`;
