// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Button, Space } from 'antd';
import { isEmpty } from 'lodash/lang';

import { randomLoadingWidth } from 'src/utils/common';

import { SkeletonLoading } from 'src/components/styles';
import surveyFileIcon from 'src/assets/images/common/survey-file-icon.svg';

import * as Style from './styles';

interface ISurveyListProps {
  finishedSurvey(params): void;
  courseId: number;
  surveyData: {
    id: number;
    item: {
      url: string;
      name: string;
    };
    unit: {
      name: string;
      category: {
        name: string;
      };
    };
  }[];
  loading: boolean;
}

const SurveyList: React.FC<ISurveyListProps> = ({
  finishedSurvey,
  courseId,
  surveyData,
  loading,
}) => {
  function handleJoinSurvey(survey) {
    if (survey.item.surveyType === 'pre_survey') {
      return window.open(`/learn-course/${courseId}/pre-survey/${survey.id}`);
    }
    if (survey.item.surveyType === 'finish_survey') {
      return window.open(`/learn-course/${courseId}/final-survey/${survey.id}`);
    }
  }

  function renderSurveyItems() {
    if (loading) {
      return (
        <Space size={16}>
          {[...Array(3)].map((_, surveyIndex) => (
            <SkeletonLoading.Button
              key={`loading-survey-${surveyIndex}`}
              active
              style={{ width: randomLoadingWidth(150, 220) }}
            />
          ))}
        </Space>
      );
    }
    return surveyData.map((survey, surveyIndex) => (
      <Button
        key={`survey-number-${surveyIndex}`}
        type="primary"
        ghost
        onClick={() => handleJoinSurvey(survey)}
        style={{ margin: '0 16px 10px 0' }}
      >
        <img src={surveyFileIcon} alt="" style={{ marginRight: 5 }} />
        {survey.unit.name}
      </Button>
    ));
  }

  function renderSurveyItemsMobile() {
    if (loading) {
      return (
        <Space size={16}>
          {[...Array(3)].map((_, surveyIndex) => (
            <SkeletonLoading.Button
              key={`loading-survey-${surveyIndex}`}
              active
              style={{ width: randomLoadingWidth(150, 220) }}
            />
          ))}
        </Space>
      );
    }
    return surveyData.map((survey, surveyIndex) => (
      <Style.ButtonSurvey
        key={`survey-number-${surveyIndex}`}
        type="primary"
        ghost
        onClick={() => {
          finishedSurvey({ courseId, unitVersionId: survey.id });
          window.open(survey.item.url, '_blank');
        }}
      >
        <img src={surveyFileIcon} alt="" style={{ marginRight: 5 }} />
        {survey.unit.name}
      </Style.ButtonSurvey>
    ));
  }

  if (isEmpty(surveyData) && !loading) return null;
  return (
    <Style.CourseItemsContainer>
      <Style.TextHeader xxxl style={{ marginBottom: 16 }}>アンケート</Style.TextHeader>
      <Style.SurveyList>
        {renderSurveyItems()}
      </Style.SurveyList>
      <Style.SurveyListMobile>
        {renderSurveyItemsMobile()}
      </Style.SurveyListMobile>
    </Style.CourseItemsContainer>
  );
};

export default SurveyList;
