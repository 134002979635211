// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';

import {
  MODAL_ACTION,
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

import { companyServices, accountServices } from 'src/services';

function* getCompanyListSaga(action) {
  try {
    const result = yield companyServices.getCompanyList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getCompanyDetailSaga(action) {
  try {
    const result = yield companyServices.getCompanyDetail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_DETAIL),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_DETAIL), errors: e.errors });
  }
}

function* getCompanyContractListSaga() {
  try {
    const result = yield companyServices.getCompanyContractList();
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CONTRACTS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CONTRACTS), errors: e.errors });
  }
}

function* selectCompanyStatusSaga(action) {
  try {
    const result = yield companyServices.selectCompanyStatus(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.SELECT_COMPANY_STATUS),
      payload: {
        data: {
          ...result.data,
          status: action.payload.requestData.company.status,
        },
        meta: result.meta,
      },
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.SELECT_COMPANY_STATUS), errors: e.errors });
  }
}

function* getCompanyStudentsSaga(action) {
  try {
    const result = yield companyServices.getCompanyStudents(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_STUDENTS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_STUDENTS), errors: e.errors });
  }
}

function* getCompanyCoursesSaga(action) {
  try {
    const result = yield companyServices.getCompanyCourses(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_COURSES), errors: e.errors });
  }
}

function* getCompanyCustomInfosSaga(action) {
  try {
    const result = yield companyServices.getCompanyCustomInfos(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS), errors: e.errors });
  }
}

function* updateCompanyCustomInfosSaga(action) {
  try {
    const result = yield companyServices.updateCompanyCustomInfos(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS), errors: e.errors });
  }
}

function* deleteCompanyCustomInfosSaga(action) {
  try {
    yield companyServices.deleteCompanyCustomInfos(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS),
      payload: {
        data: {
          id: action.payload.infoId,
        },
      },
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS), errors: e.errors });
  }
}

function* updateCompanyInfosSaga(action) {
  const { id, requestData, requestAction } = action.payload;
  try {
    const result = yield companyServices.updateCompanyInfos({ id, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_INFOS),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
    yield requestAction.setIsEditForm(false);
  } catch (e) {
    if (e.errors[0].code === 1008) {
      requestAction.setCompanyInfoFields([{
        name: 'contractName',
        errors: [e.errors[0].message],
      }]);
    } else {
      yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    }
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_INFOS), errors: e.errors });
  }
}

function* banCompanyStudentSaga(action) {
  try {
    yield accountServices.banAccount(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.BAN_COMPANY_STUDENT),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: 'ユーザを非アクティブ化することに成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COMPANY_ACTION.BAN_COMPANY_STUDENT), errors: e.errors });
  }
}

export default function* companySaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_LIST), getCompanyListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_DETAIL), getCompanyDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CONTRACTS), getCompanyContractListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.SELECT_COMPANY_STATUS), selectCompanyStatusSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_STUDENTS), getCompanyStudentsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_COURSES), getCompanyCoursesSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS), getCompanyCustomInfosSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS), updateCompanyCustomInfosSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS), deleteCompanyCustomInfosSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_INFOS), updateCompanyInfosSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COMPANY_ACTION.BAN_COMPANY_STUDENT), banCompanyStudentSaga),
  ]);
}
