// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Progress,
  Space,
  Table,
  Button,
  Tooltip,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import moment from 'moment';

import { isNumber, isEmpty } from 'lodash/lang';

import { convertSecondTime } from 'src/utils/dateTime';
import history from 'src/utils/history';
import { LoginStorage } from 'src/utils/localStorge';
import { handleStopPropagation } from 'src/utils/common';

import SortableHeader from 'src/components/tables/SortableHeader';
import { PaginationLayout } from 'src/components/layouts/Pagination';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT, COURSE_ACTUAL_STATUS } from 'src/constants/common';

import ModifyCourseDurationModal from 'src/components/Modal/components/ModifyCourseDurationModal';

import * as Style from './styles';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

interface IStudentListPageProps extends StateProps {
  getCourseStudents(params): void;
  showModal(params): void;
  downloadCsvStudyStudent(params): void;
  closeModal(): void;
  companySelectedId: number;
  courseId: string | undefined;
  tabIndexSelected: string;
  actualStatus: string;
  courseStudents: {
    data: {
      id: number;
      jituName: string;
      email: string;
      coursesPercentage: number;
      totalLearningTime: number;
      progressGap: number;
      startDate: string;
      endDate: string;
      lastLearningDate: string;
      registerStatus: string;
      actualStatus: string;
    }[],
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    isUpdateSuccess: boolean;
  };
  courseDetail: {
    data: {
      id: number;
      actualStatus: string;
      name: string;
      overview: string;
      remainingDays: number;
      startDate: string;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      isLearned: boolean;
      recommendedEndDate: string;
      recommendedStartDate: string;
      recommendedCompletionDay: number;
      company: {
        id: number;
      }
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
    };
  };
  courseOverviewDetail: {
    data: {
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
      startDate: string;
      remainingDays: number;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      isLearned: boolean;
      recommendedStartDate: string;
      recommendedEndDate: string;
      recommendedCompletionDay: number;
      actualStatus: string;
      licenseId: number;
    };
    load: boolean;
  };
}

const StudentListPage: React.FC<IStudentListPageProps> = ({
  getCourseStudents,
  showModal,
  downloadCsvStudyStudent,
  closeModal,
  companySelectedId,
  courseId,
  courseStudents,
  courseDetail,
  tabIndexSelected,
  actualStatus,
  courseOverviewDetail
}) => {
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const { data, meta } = courseStudents;
  const [sortCondition, setSortCondition] = useState<object>({});
  const userStorage = LoginStorage.getData();

  useEffect(() => {
    if (tabIndexSelected === '2' || courseStudents.isUpdateSuccess) {
      setSortCondition(sortConditionUrl || {})
      getCourseStudents({
        id: courseId,
        requestData: {
          page: 1, companyId: companySelectedId,
          ...pageUrl,
          items: pageUrl?.limit,
          sort: sortConditionUrl
        }
      });
    }
  }, [tabIndexSelected, courseStudents.isUpdateSuccess, companySelectedId]);

  useEffect(() => {
    if (tabIndexSelected === "2") { 
      history.replace({
        state: {
          activeTabKey: tabIndexSelected,
          sortCondition,
          page: meta.pageInfo,
          companyContractId: companySelectedId,
          isSort: true,
        }
      })
    }
  }, [sortCondition, meta.pageInfo])

  const tableData = data.map((row: any) => ({
    ...row,
    id: row.id,
    key: row.id,
    jituName: row.jituName,
    email: row.email,
    coursesPercentage: row.coursesPercentage,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'H時間m分'),
    lastLearningDate: row.lastLearningDate ? moment(row.lastLearningDate).format(DATE_FORMAT) : '',
    startDate: moment(row.startDate).format(DATE_FORMAT),
    endDate: moment(row.endDate).format(DATE_FORMAT),
    progressGap: `${isNumber(row.progressGap) ? `${row.progressGap}%` : 'ー'}`,
  }));

  function handleModifyCourseStudentDuration(e, record) {
    handleStopPropagation(e);
    const modalProps = {
      width: 560,
      modalData: {
        title: 'ライセンス期間の変更',
        course: {
          courseId,
          userId: record.id,
          startDate: record.startDate,
          endDate: record.endDate,
          companyId: companySelectedId,
          courseStartDate: courseOverviewDetail.data.startDate,
          courseEndDate: courseOverviewDetail.data.endDate,
          recommendedEndDate: courseOverviewDetail.data.recommendedEndDate,
          recommendedStartDate: courseOverviewDetail.data.recommendedStartDate,
          recommendedCompletionDay: courseOverviewDetail.data.recommendedCompletionDay
        },
      },
    };
    return showModal(<ModifyCourseDurationModal {...modalProps} />);
  }

  const columns = [
    {
      title:
        <SortableHeader
          type="jituName"
          title="名前"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'jituName',
      render: (_, record) => (
        <Style.StudentName>
          <TooltipParagraph isPreWrap rows={2} {...(record.status === 'banned' && { style: { marginTop: 14 } } )}>
            {record.jituName}
          </TooltipParagraph>
          {record.status === 'banned' && (
            <Style.StudentStatus>停止</Style.StudentStatus>
          )}
        </Style.StudentName>
      ),
      width: 200,
    },
    {
      title:
        <SortableHeader
          type="email"
          title="メールアドレス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'email',
      width: 250,
    },
    {
      title:
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="coursesPercentage"
          title="進捗率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'coursesPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.coursesPercentage}%</Text>
          <Progress
            showInfo={false}
            strokeWidth={16}
            percent={record.coursesPercentage}
          />
        </Space>
      ),
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="progressGap"
          title="進捗ギャップ"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'progressGap',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'lastLearningDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="startDate"
          title="開始日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'startDate',
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="endDate"
          title="終了日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
          actionSort={getCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'endDate',
      render: (_, record) => (
        <Space
          {...{onClick: (e) =>  handleStopPropagation(e) }}
          style={{ cursor: 'default' }}
        >
          <Text>{record.endDate}</Text>
          <Tooltip
            placement="right"
            title={['opening', 'coming'].includes(actualStatus)
              ? null
              : COURSE_ACTUAL_STATUS[actualStatus]}
          >
            <Button
              type="link"
              icon={<EditOutlined style={{ color: '#3333' }} />}
              disabled={['finished', 'closed'].includes(actualStatus)}
              onClick={(e) => handleModifyCourseStudentDuration(e, record)}
            />
          </Tooltip>
        </Space>
      ),
      width: 180,
    },
  ];

  const handleShowModalConfirmExportCsv = () => {
    const modalProps = {
      modalData: {
        title: "CSV出力",
        message: "ユーザー毎の受講履歴をCSVで出力しますか？",
        okText: "出力する",
        cancelText: "キャンセル"
      },
      modalAction: {
        confirm: () => {
          downloadCsvStudyStudent({
            courseId,
            companyId: companySelectedId,
            closeModal: () => closeModal(),
          });
        },
      },
      isConfirm: true,
      spaceEvenly: true,
      isButtonLoading: true,
      styleContent: {
        paddingTop: "60px"
      },
    };
    showModal(<ConfirmModal {...modalProps} />);
  };

  return (
    <Style.StudentTableContainer>
     {!isEmpty(courseStudents.data) && (
        <Style.ButtonExportCsvWrapper>
          <Button
            size="small"
            type="primary"
            onClick={handleShowModalConfirmExportCsv}
            disabled={courseStudents.load}
          >
            CSV出力
          </Button>
        </Style.ButtonExportCsvWrapper>
      )}
      <Table
        className="table-cursor-pointer"
        pagination={false}
        scroll={{ x: 1310, y: 506 }}
        columns={columns}
        dataSource={tableData}
        onRow={(record) => ({
          onClick: () => {
            const urlProfile = userStorage.user.key === record.key ? 'my-profile' : `profile/${record.key}`;
            history.push(`/admin-service/${urlProfile}`);
          },
        })}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={courseStudents.load}
      />
      {!isEmpty(courseStudents.data) && (
        <PaginationLayout
          pageInfo={meta.pageInfo}
          onChangePage={getCourseStudents}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
              companyId: companySelectedId,
            },
          }}
        />
      )}
    </Style.StudentTableContainer>
  );
};

const mapStateToProps = (state) => {
  const { courseStudents } = state.courseReducer;
  return {
    courseStudents,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, null)(StudentListPage);
