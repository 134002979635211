// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';

import {
  MODAL_ACTION,
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

import { showAlertNotice } from 'src/utils/alert';
import { tagServices } from 'src/services';


function* getTagsSaga() {
  try {
    const result = yield tagServices.getTagList();
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TAG_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TAG_ACTION.GET_LIST), errors: e.errors });
  }
}

function* createTagsSaga(action) {
  try {
    yield tagServices.createTag(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TAG_ACTION.CREATE_TAG),
    });
    yield put({
      type: REQUEST(ADMIN_SERVICE.TAG_ACTION.GET_LIST),
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TAG_ACTION.GET_LIST), errors: e.errors });
  }
}

function* updateTagsSaga(action) {
  try {
    yield tagServices.updateTag(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG),
      payload: {
        data: {
          id: action.payload.id,
          name: action.payload.requestData.tag.name,
          description: action.payload.requestData.tag.description,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG), errors: e.errors });
  }
}

function* deleteTagsSaga(action) {
  try {
    yield tagServices.deleteTag(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に削除されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG), errors: e.errors });
  }
}

export default function* tagSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.TAG_ACTION.GET_LIST), getTagsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TAG_ACTION.CREATE_TAG), createTagsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG), updateTagsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG), deleteTagsSaga),
  ]);
}
