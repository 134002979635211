// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from "styled-components";

export const ShowErrorModalContainer = styled.div`
  align-items: center;
  padding: 32px 24px;
`;

export const ButtonWrapper = styled.div`
  border-top: 1px solid #c4c4c4;
  padding: 10px;
  text-align: center;
`;
