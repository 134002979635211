// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const TipDetailContainer = styled.div`
  display: flex;
  margin-top: 24px;

  @media only screen and (max-width: 428px) {
    margin-top: 0px;
  }
`;

export const TipDetailContent = styled.div`
  margin-right: 16px;
  width: calc(100% - 300px);
  min-width: 898px;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;

  @media only screen and (max-width: 428px) {
    width: 100%;
    min-width: 100%;
  }
`;

export const TipDetailTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const TipDescription = styled.div`
  padding: 16px;
  min-height: 54px;
  background-color: white;
  overflow: hidden;

  img {
    max-width: 100%;
  }
`;

export const TipFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: white;
`;
