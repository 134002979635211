// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { MY_PROFILE_ACTION, REQUEST } from '../constant';

export function getProfileInfo(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.GET_PROFILE_INFO),
    payload: params,
  };
}

export function getMyJoinedCourses(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.GET_MY_JOINED_COURSES),
    payload: params,
  };
}

export function updateMyCourseDuration(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_MY_COURSES_DURATION),
    payload: params,
  };
}

export function updateUserInfo(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_USER_INFO),
    payload: params,
  };
}

export function updateCustomUserInfo(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_CUSTOM_USER_INFO),
    payload: params,
  };
}

export function updateProfileAvatar(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_AVATAR),
    payload: params,
  };
}

export function updateProfileEmail(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_EMAIL),
    payload: params,
  };
}

export function updateProfilePassword(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_PASSWORD),
    payload: params,
  };
}

export function updateProfilePasswordAndEmail(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_PASSWORD_AND_EMAIL),
    payload: params,
  };
}

export function clearUpdateEmailErrors() {
  return {
    type: REQUEST(MY_PROFILE_ACTION.CLEAR_UPDATE_EMAIL_ERRORS),
  };
}

export function clearUpdatePasswordErrors() {
  return {
    type: REQUEST(MY_PROFILE_ACTION.CLEAR_UPDATE_PASSWORD_ERRORS),
  };
}

export function getMyTestList(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.GET_TEST_STUDENT),
    payload: params,
  };
}

export function resetMyTestHistory(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY),
    payload: params,
  };
}

export function getMyCourseCompany(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.GET_COURSE_COMPANY),
    payload: params,
  };
}

export function updateMyCourseManager(params) {
  return {
    type: REQUEST(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER),
    payload: params,
  };
}

export function resetErrorMessageChangeEmailPassword() {
  return {
    type: REQUEST(MY_PROFILE_ACTION.RESET_ERROR_CHANGE_MAIL_PASSWORD)
  };
}
