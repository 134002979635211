// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { fork } from 'redux-saga/effects';
import authSaga from './auth.saga';

import testSaga from './adminService/test.saga';
import videoSaga from './adminService/video.saga';
import unitSaga from './adminService/unit.saga';
import courseSaga from './adminService/course.saga';
import planSaga from './adminService/plan.saga';
import tagSaga from './adminService/tag.saga';
import teacherSaga from './adminService/teacher.saga';
import companySaga from './adminService/company.saga';
import surveySaga from './adminService/survey.saga';
import categorySaga from './adminService/category.saga';
import tipSaga from './adminService/tip.saga';
import questionSaga from './adminService/question.saga';
import accountSaga from './adminService/account.saga';
import serviceStatisticSaga from './adminService/statistic.saga';
import faqSaga from './adminService/faq.saga';
import profileSaga from './adminService/profile.saga';
import uploadAnswerSaga from './adminService/uploadAnswer.saga';
import licenseSaga from './adminService/license.saga';

import companyAccountSaga from './adminCompany/account.saga';
import companyStatisticSaga from './adminCompany/statistic.saga';
import companyCourseSaga from './adminCompany/course.saga';
import companyContractSaga from './adminCompany/company.saga';
import companyProfileSaga from './adminCompany/profile.saga';

import studentStatisticSaga from './student/statistic.saga';
import studentAccountSaga from './student/account.saga';
import studentCourseSaga from './student/course.saga';
import studentUnitSaga from './student/unit.saga';
import studentTestSaga from './student/test.saga';
import studentVideoSaga from './student/video.saga';
import studentSurveySaga from './student/survey.saga';

import masterDataSaga from './master.saga';
import myProfileSaga from './profile.saga';
import notificationSaga from './notification.saga';

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(testSaga);
  yield fork(videoSaga);
  yield fork(unitSaga);
  yield fork(courseSaga);
  yield fork(planSaga);
  yield fork(tagSaga);
  yield fork(teacherSaga);
  yield fork(companySaga);
  yield fork(surveySaga);
  yield fork(categorySaga);
  yield fork(tipSaga);
  yield fork(accountSaga);
  yield fork(questionSaga);
  yield fork(serviceStatisticSaga);
  yield fork(faqSaga);
  yield fork(profileSaga);
  yield fork(uploadAnswerSaga);
  yield fork(licenseSaga);

  yield fork(companyAccountSaga);
  yield fork(companyStatisticSaga);
  yield fork(companyCourseSaga);
  yield fork(companyContractSaga);
  yield fork(companyProfileSaga);

  yield fork(studentStatisticSaga);
  yield fork(studentAccountSaga);
  yield fork(studentCourseSaga);
  yield fork(studentUnitSaga);
  yield fork(studentTestSaga);
  yield fork(studentVideoSaga);
  yield fork(studentSurveySaga);

  yield fork(masterDataSaga);
  yield fork(myProfileSaga);
  yield fork(notificationSaga);
}
