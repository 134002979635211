// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class UnitServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getUnitList(params) {
    return this.restClient.get('/admin_manage/units', params);
  }

  getUnitDetail(params) {
    return this.restClient.get(`/admin_manage/units/${params.id}`);
  }

  updateUnit(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/units/${id}`, requestData);
  }

  createUnit(params) {
    return this.restClient.post('/admin_manage/units', params);
  }

  deleteUnit(params) {
    return this.restClient.delete(`/admin_manage/units/${params.id}`);
  }

  getVersionList(params) {
    const { requestData } = params;
    return this.restClient.get(`/admin_manage/units/${params.unitId}/unit_versions`, requestData);
  }

  getUnitVersionList(params) {
    return this.restClient.get('/admin_manage/unit_items/unit_versions', params);
  }

  getUnitItems(params) {
    const { requestData, unitType } = params;
    return this.restClient.get(`/admin_manage/unit_items/${unitType}s`, requestData);
  }

  createUnitVersion(params) {
    const { requestData, unitId } = params;
    return this.restClient.post(`/admin_manage/units/${unitId}/unit_versions`, requestData);
  }

  getCourseContentUnits(params) {
    return this.restClient.get('/admin_manage/course_contents/units', params);
  }

  getOverviewUnitDetail(params) {
    const { courseId, unitVersionId, groupId, chapterId } = params;
    return this.restClient.get(`/admin_manage/take_course/unit_versions/${unitVersionId}`, {
      courseId,
      ...groupId && { groupId },
      ...chapterId && { chapterId },
    });
  }
}
