// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash/lang';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import { PASSWORD_REGEX } from 'src/constants/regexValidate';

import { updateProfilePassword as updateProfilePasswordAction } from 'src/redux/actions';

import * as Style from './styles';

interface IChangePasswordModalProps extends StateProps, DispatchProps {
  updateProfilePassword(params): void;
  changePassword: {
    errors: {
      message: string;
    }[];
  };
}

const ChangePasswordModal: React.FC<IChangePasswordModalProps> = ({
  updateProfilePassword,
  changePassword,
}) => {
  const [changePasswordForm] = Form.useForm();

  useEffect(() => {
    if (!isEmpty(changePassword.errors)) {
      changePasswordForm.setFields([
        {
          name: 'currentPassword',
          errors: [changePassword.errors[0].message],
        },
      ]);
    }
  }, [changePassword.errors]);

  function handleSubmitForm(values) {
    updateProfilePassword({ user: values });
  }

  return (
    <Form
      form={changePasswordForm}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      name="updateProfilePasswordForm"
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.FieldContent>
        <Form.Item
          label="現在のパスワード"
          name="currentPassword"
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            { required: true, message: '現在のパスワードは必須項目です。' },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="新しいパスワード"
          name="newPassword"
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            { required: true, message: '新しいパスワードは必須項目です。' },
            { min: 8, message: '新しいパスワードは8文字以上で設定して下さい。' },
            { max: 20, message: '新しいパスワードが長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PASSWORD_REGEX),
              message: '新しいパスワードは大文字、小文字、数字、記号から3つ以上の組み合わせで入力してください。'
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="新しいパスワード（確認用）"
          name="confirmNewPassword"
          dependencies={['newPassword']}
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            { required: true, message: '新しいパスワード（確認用）は必須項目です。' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('新しいパスワードと確認用のパスワードが一致しません。ご確認ください。');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Style.FieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { changePassword } = state.myProfileReducer;
  return {
    changePassword,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateProfilePassword: (params) => dispatch(updateProfilePasswordAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal);
