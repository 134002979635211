// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import TestScoreStatus from './components/TestScoreStatus';
import TestScoreChart from './components/TestScoreChart';
import TestScoreStudents from './components/TestScoreStudents';

import {
  getCompanyTestScoreOverview as getCompanyTestScoreOverviewAction,
  getCompanyTestScoreChart as getCompanyTestScoreChartAction,
  getCompanyTestScoreStudents as getCompanyTestScoreStudentsAction,
  closeModal as closeModalAction,
  showModal as showModalAction,
  downloadCsvTestStudentByAdminCompany as downloadCsvTestStudentByAdminCompanyAction
} from 'src/redux/actions';

import * as Style from './styles';
import history from 'src/utils/history';

interface ITestScoreProps extends StateProps, DispatchProps {
  getCompanyTestScoreOverview(params): void;
  getCompanyTestScoreChart(params): void;
  getCompanyTestScoreStudents(params): void;
  showModal(params): void;
  closeModal(): void;
  downloadCsvTestStudentByAdminCompany(params): void;
  courseId: string | undefined;
  tabIndexSelected: string;
  testScoreOverview: {
    data: {
      overview: {
        avgScoreOfAllTest: number;
        avgScoreOfFinishTest: number;
        avgScoreOfPreTest: number;
        avgScoreOfVerifyTest: number;
        studentsJoinedTestsCount: number;
        isStudentsFinishedTests: boolean;
      };
    };
    load: boolean;
  };
  testScoreChart: {
    data: object[];
    load: boolean;
  };
  testScoreStudents: {
    data: {
      coursesUnitVersions: {
        id: number;
        itemType: string;
        unit: {
          name: string;
        };
      }[];
      usersScore: object[];
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const TestScore: React.FC<ITestScoreProps> = ({
  getCompanyTestScoreOverview,
  getCompanyTestScoreChart,
  getCompanyTestScoreStudents,
  showModal,
  closeModal,
  downloadCsvTestStudentByAdminCompany,
  courseId,
  testScoreOverview,
  testScoreChart,
  testScoreStudents,
  tabIndexSelected,
}) => {
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;

  useEffect(() => {
    if (tabIndexSelected === '3') {
      getCompanyTestScoreOverview({ id: courseId });
      getCompanyTestScoreChart({ id: courseId, requestData: { testType: 'all_test' } });
      getCompanyTestScoreStudents({ id: courseId, requestData: { page: 1,...pageUrl, items: pageUrl?.limit, sort: sortConditionUrl } });
    }
  }, [tabIndexSelected]);

  return (
    <>
      <TestScoreStatus testScoreOverview={testScoreOverview} />
      <TestScoreChart
        getCompanyTestScoreChart={getCompanyTestScoreChart}
        courseId={courseId}
        testScoreChart={testScoreChart}
      />
      <TestScoreStudents
        getCompanyTestScoreStudents={getCompanyTestScoreStudents}
        courseId={courseId}
        testScoreStudents={testScoreStudents}
        isStudentsFinishedTests={testScoreOverview?.data?.overview?.isStudentsFinishedTests}
        showModal={showModal}
        closeModal={closeModal}
        downloadCsvTestStudent={downloadCsvTestStudentByAdminCompany}
        activeTabKey={tabIndexSelected}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    testScoreOverview,
    testScoreChart,
    testScoreStudents,
  } = state.companyCourseReducer;
  return {
    testScoreOverview,
    testScoreChart,
    testScoreStudents,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyTestScoreOverview: (params) => dispatch(getCompanyTestScoreOverviewAction(params)),
  getCompanyTestScoreChart: (params) => dispatch(getCompanyTestScoreChartAction(params)),
  getCompanyTestScoreStudents: (params) => dispatch(getCompanyTestScoreStudentsAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  downloadCsvTestStudentByAdminCompany: (params) => dispatch(downloadCsvTestStudentByAdminCompanyAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestScore);
