// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const QuestionDetailTitle = styled.div`
  margin-bottom: 12px;
`;

export const QuestionDetailContent = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const QuestionDetailWrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;
