// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';

import history from 'src/utils/history';

import {
  ADMIN_COMPANY,
  LOADING_ACTION,
  SUCCESS,
  FAILURE,
  REQUEST,
} from '../../constant';
import { CompanyPath } from 'src/constants/routerConstants';

import { companyAccountServices } from 'src/services';

function* getConfirmationStatusSaga(action) {
  try {
    const result = yield companyAccountServices.getConfirmationStatus(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS),
      payload: result,
    });
  } catch (e) {
    yield history.push('/admin/login');
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS), errors: e.errors });
  }
}

function* confirmAccountSaga(action) {
  try {
    yield companyAccountServices.confirmAccount(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM),
    });
    yield localStorage.clear();
    yield history.push(CompanyPath.Login);
    yield showAlertNotice({ type: 'success', message: 'アカウントの登録が成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM), errors: e.errors });
  }
}

function* confirmAdminCompanySaga(action) {
  try {
    yield companyAccountServices.confirmAdminCompany(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM_COMPANY),
    });
    yield localStorage.clear();
    yield history.push(CompanyPath.Login);
    yield showAlertNotice({ type: 'success', message: 'アカウントの登録が成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM_COMPANY), errors: e.errors });
  }
}

function* getStudentsSaga(action) {
  try {
    const result = yield companyAccountServices.getStudents(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS), errors: e.errors });
  }
}

function* addStudentsToCourseSaga(action) {
  try {
    const { courseId } = action.payload;
    yield companyAccountServices.addStudentsToCourse(action.payload);
    const result = yield companyAccountServices.getStudents({
      courseId,
      page: 1,
    });
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: 'ユーザのライセンス登録が成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE), errors: e.errors });
  }
}

function* inviteStudentsSaga(action) {
  try {
    const result = yield companyAccountServices.inviteStudents({ ...action.payload });
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS),
      payload: result.data,
    });
    yield showAlertNotice({ type: 'success', message: 'アカウントの招待が成功しました。' });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* inviteAdminCompanySaga(action) {
  try {
    const result = yield companyAccountServices.inviteAdminCompany(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: 'アカウントの招待が成功しました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY), errors: e.errors });
  }
}

function* getInvitationListSaga(action) {
  try {
    const result = yield companyAccountServices.getInvitationList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.GET_INVITATION_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.GET_INVITATION_LIST), errors: e.errors });
  }
}

function* resendInvitationSaga(action) {
  try {
    const result = yield companyAccountServices.resendInvitation(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.RESEND_INVITATION),
      payload: {
        ...result.data.user,
        email: action.payload.user.email,
      },
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.RESEND_INVITATION), errors: e.errors });
  }
}

function* cancelInvitationSaga(action) {
  try {
    yield companyAccountServices.cancelInvitation(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.CANCEL_INVITATION),
      payload: action.payload,
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.CANCEL_INVITATION), errors: e.errors });
  }
}

function* addStudentsToCourseByCsvSaga(action) {
  try {
    const { courseId } = action.payload;
    const result = yield companyAccountServices.addStudentsToCourseByCsv(action.payload);
    yield put({
      type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS),
      payload: {
        courseId,
        page: 1,
      }
    })
    yield put({
      type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES)
    })
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV),
      payload: { numberSuccessEmails: result.data.numberSuccessEmails, courseId, numberInviteEmails: result.data.numberInvitedEmails  },
    });

    yield put({type:REQUEST(LOADING_ACTION.FINISHED_LOAD) })
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });

  }
}

function* bulkResendInvitationsSaga(action) {
  try {
    yield companyAccountServices.bulkResendInvitations(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS),
      payload: action.payload
    });
    yield showAlertNotice({ type: 'success', message: '一括再送を実行中です' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS), errors: e.errors });
  }
}

function* bulkCancelInvitationsSaga(action) {
  try {
    yield companyAccountServices.bulkCancelInvitations(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS),
      payload: action.payload,
    });
    yield showAlertNotice({ type: 'success', message: '一括キャンセルを実行中です' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS), errors: e.errors });
  }
}

export default function* companyAccountSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS), getConfirmationStatusSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM), confirmAccountSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM_COMPANY), confirmAdminCompanySaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS), getStudentsSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE), addStudentsToCourseSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS), inviteStudentsSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY), inviteAdminCompanySaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_INVITATION_LIST), getInvitationListSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.RESEND_INVITATION), resendInvitationSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CANCEL_INVITATION), cancelInvitationSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV), addStudentsToCourseByCsvSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS), bulkResendInvitationsSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS), bulkCancelInvitationsSaga),
  ]);
}
