// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const ProgressNotificationContainer = styled.div`
  margin: 24px 40px;
  & .ant-select{
    width: 150px !important;
  }
`;

export const ProgressNotificationOverview = styled.div`
  margin: 24px 0;
`;

export const TwoDots = styled.span`
  margin: 0 25px;
  font-size: 18px;
  font-weight: bold;
`
