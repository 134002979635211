// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';

import {
  ADMIN_SERVICE,
  MODAL_ACTION,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

import history from 'src/utils/history';
import { faqServices } from 'src/services';
import { showAlertNotice } from 'src/utils/alert';

import { ServicePath } from 'src/constants/routerConstants';

function* getFAQListSaga(action) {
  try {
    const result = yield faqServices.getFAQList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.FAQ_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.FAQ_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getFAQDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield faqServices.getFAQDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.FAQ_ACTION.GET_DETAIL),
      payload: result,
    });
    if (redirect) history.push(redirect);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.FAQ_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* createFAQSaga(action) {
  try {
    const result = yield faqServices.createFAQ(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ),
      payload: result,
    });
    yield history.push(ServicePath.FAQList);
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ), errors: e.errors });
  }
}

function* updateFAQSaga(action) {
  try {
    const result = yield faqServices.updateFAQ(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ),
      payload: result,
    });
    yield history.goBack();
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ), errors: e.errors });
  }
}

function* addFAQContentSaga(action) {
  try {
    const result = yield faqServices.addFAQContent(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT),
      payload: {
        data: {
          faqContent: {
            ...result.data.faqContent,
            position: action.payload.requestData.faqContent.position,
          },
        },
        meta: result.meta,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT), errors: e.errors });
  }
}

function* updateFAQContentSaga(action) {
  try {
    const { faqContentId, requestData } = action.payload;
    yield faqServices.updateFAQContent(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT),
      payload: {
        data: {
          faqContent: {
            id: faqContentId,
            question: requestData.faqContent.question,
            answer: requestData.faqContent.answer,
          },
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT), errors: e.errors });
  }
}

function* deleteFAQContentSaga(action) {
  try {
    const { faqContentId } = action.payload;
    yield faqServices.deleteFAQContent(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT),
      payload: {
        data: {
          faqContent: {
            id: faqContentId,
          },
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT), errors: e.errors });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  }
}

export default function* faqSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.FAQ_ACTION.GET_LIST), getFAQListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.FAQ_ACTION.GET_DETAIL), getFAQDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ), createFAQSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ), updateFAQSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT), addFAQContentSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT), updateFAQContentSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT), deleteFAQContentSaga),
  ]);
}
