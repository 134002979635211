// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { MODAL_ACTION, REQUEST } from '../constant';

const initialState = {
  isShowModal: false,
  children: {},
};

export type ModalReducer = Readonly<typeof initialState>;

export default (state: ModalReducer = initialState, action: any): ModalReducer => {
  switch (action.type) {
  case REQUEST(MODAL_ACTION.SHOW):
    return {
      ...state,
      isShowModal: true,
      children: action.payload,
    };
  case REQUEST(MODAL_ACTION.CLOSE):
    return {
      ...state,
      isShowModal: false,
      children: {},
    };
  default:
    return state;
  }
};
