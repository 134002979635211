// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Col } from 'antd';

export const EditFAQContent = styled.div`
  margin-top: 24px;

  & .ant-form-item-label {
    padding: 0;
  }
`;

export const EditFAQAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${(props) => props.hasErrorText
    && css`
      justify-content: space-between;
    `}
`;

export const FAQContentsContainer = styled.div`
  margin: 56px 0;
`;

export const FAQItemContainer = styled.div`
  position: relative;
  margin-top: 32px;

  &:hover > div:first-child {
    border: 2px solid ${(props) => props.theme.colors.primary};
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
    background-color: #FFF8E7;
  }

  &:first-child {
    margin-top: 0;
  }

  & .faq-item-button {
    display: none;
  }

  &:hover .faq-item-button {
    display: block;
  }
`;

export const FAQItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
`;

export const FAQCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const FAQAction = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > button {
    min-width: 76px;
  }
`;

export const AddFAQButton = styled.div`
  position: absolute;
  transform: translate(50%, 0);

  & > button {
    font-size: ${(props) => props.theme.fontSize.md};
  }

  ${(props) => props.position === 'top'
    && css`
      top: -30px;
      right: 50%;
    `}

  ${(props) => props.position === 'bottom'
    && css`
      bottom: -32px;
      right: 50%;
    `}
`;
