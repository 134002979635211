// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { ReactNode } from 'react';

import * as Style from './styles';

interface ICardWrapperProps {
  children: ReactNode;
  isStudent?: boolean;
}

const CardWrapper: React.FC<ICardWrapperProps> = ({
  children,
  isStudent,
}) => (
  <Style.CardWrapper isStudent={isStudent}>
    {children}
  </Style.CardWrapper>
);

export default CardWrapper;
