// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import history from 'src/utils/history';
import { showAlertNotice } from 'src/utils/alert';

import {
  MODAL_ACTION,
  MY_PROFILE_ACTION,
  MASTER_DATA_ACTION,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../constant';

import {
  myProfileServices,
  profileServices,
} from 'src/services';

function* getProfileInfoSaga(action) {
  try {
    const { redirect } = action.payload;
    const result = yield myProfileServices.getProfileInfo();
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.GET_PROFILE_INFO),
      payload: result,
    });
    if (redirect) history.push(redirect);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(MY_PROFILE_ACTION.GET_PROFILE_INFO), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getMyJoinedCoursesSaga(action) {
  try {
    const result = yield myProfileServices.getMyJoinedCourses(action.payload);
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.GET_MY_JOINED_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(MY_PROFILE_ACTION.GET_MY_JOINED_COURSES), errors: e.errors });
  }
}

function* updateMyCourseDurationSaga(action) {
  try {
    const result = yield profileServices.updateCourseDuration(action.payload);
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_MY_COURSES_DURATION),
      payload: {
        data: {
          id: action.payload.id,
          startDate: action.payload.requestData.startDate,
          endDate: action.payload.requestData.endDate,
          remainingDays: result.data.course.remainingDays,
          actualStatus: result.data.course.actualStatus,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(MY_PROFILE_ACTION.UPDATE_MY_COURSES_DURATION), errors: e.errors });
  }
}

function* updateUserInfoSaga(action) {
  try {
    const result = yield myProfileServices.updateUserInfo(action.payload);
    yield put({
      type: SUCCESS(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO),
      payload: result,
    });
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_USER_INFO),
      payload: {
        data: action.payload.user,
      },
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(MY_PROFILE_ACTION.UPDATE_USER_INFO), errors: e.errors });
  }
}

function* updateCustomUserInfoSaga(action) {
  try {
    yield myProfileServices.updateCustomUserInfo(action.payload);
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_CUSTOM_USER_INFO),
      payload: {
        data: action.payload.user,
      },
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(MY_PROFILE_ACTION.UPDATE_CUSTOM_USER_INFO), errors: e.errors });
  }
}

function* updateProfileEmailSaga(action) {
  try {
    yield myProfileServices.updateProfileEmail(action.payload);
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_EMAIL),
      payload: {
        data: action.payload.user,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(MY_PROFILE_ACTION.UPDATE_EMAIL), errors: e.errors });
  }
}

function* updateProfilePasswordSaga(action) {
  try {
    yield myProfileServices.updateProfilePassword(action.payload);
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_PASSWORD),
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(MY_PROFILE_ACTION.UPDATE_PASSWORD), errors: e.errors });
  }
}

function* updateProfilePasswordAndEmailSaga(action) {
  try {
    yield myProfileServices.updateProfileEmailAndPassword(action.payload);
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_EMAIL),
      payload: {
        data: action?.payload?.user,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(MY_PROFILE_ACTION.UPDATE_PASSWORD_AND_EMAIL), errors: e.errors });
  }
}

function* updateProfileAvatarSaga(action) {
  const { avatarUrl, requestData } = action.payload;
  try {
    const result = yield myProfileServices.updateProfileAvatar(requestData);
    yield put({
      type: SUCCESS(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO),
      payload: result,
    });
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_AVATAR),
      payload: {
        data: avatarUrl,
      },
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(MY_PROFILE_ACTION.UPDATE_AVATAR), errors: e.errors });
  }
}

function* getListTestStudent(action) {
  try {
    const result = yield myProfileServices.getMyTestList(action.payload)
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.GET_TEST_STUDENT),
      payload: result
    })
  } catch (e) {
    yield put({
      type: FAILURE(MY_PROFILE_ACTION.GET_TEST_STUDENT),
      errors: e.errors
    })
  }
}

function* resetMyTestHistory(action) {
  try {
    const result = yield myProfileServices.resetMyTestHistory(action.payload)
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY),
      payload: result
    })

    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })

    yield put({
      type: REQUEST(MY_PROFILE_ACTION.GET_TEST_STUDENT),
      payload: {
        page: 1
      }
    })
  } catch (e) {
    yield put({
      type: FAILURE(MY_PROFILE_ACTION.GET_TEST_STUDENT),
      errors: e.errors
    })
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE)
    })
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })

    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
  }
}

function* getListCourseCompany(action) {
  try {
    const result = yield myProfileServices.getMyListCourseCompany(action.payload)
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.GET_COURSE_COMPANY),
      payload: result
    })
  } catch (e) {
    yield put({
      type: FAILURE(MY_PROFILE_ACTION.GET_COURSE_COMPANY),
      errors: e.errors
    })
  }
}

function* updateCourseManager(action) {
  try {
    yield myProfileServices.updateMyCourseManager(action.payload)
    yield put({
      type: SUCCESS(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER),
      payload: action.payload.courseId
    })
    yield showAlertNotice({ type: 'success', message: '正常に変更されました。'});
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
  } catch (e) {
    yield put({
      type: FAILURE(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER),
      errors: e.errors
    })
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
  }
}

export default function* myProfileSaga() {
  yield all([
    takeLatest(REQUEST(MY_PROFILE_ACTION.GET_PROFILE_INFO), getProfileInfoSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.GET_MY_JOINED_COURSES), getMyJoinedCoursesSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_MY_COURSES_DURATION), updateMyCourseDurationSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_USER_INFO), updateUserInfoSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_CUSTOM_USER_INFO), updateCustomUserInfoSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_AVATAR), updateProfileAvatarSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_EMAIL), updateProfileEmailSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_PASSWORD), updateProfilePasswordSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_PASSWORD_AND_EMAIL), updateProfilePasswordAndEmailSaga),
    takeLatest(REQUEST(MY_PROFILE_ACTION.GET_TEST_STUDENT), getListTestStudent),
    takeLatest(REQUEST(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY), resetMyTestHistory),
    takeLatest(REQUEST(MY_PROFILE_ACTION.GET_COURSE_COMPANY), getListCourseCompany),
    takeLatest(REQUEST(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER), updateCourseManager)

  ]);
}
