// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';

import history from 'src/utils/history';
import { showAlertNotice } from 'src/utils/alert';

import {
  MODAL_ACTION,
  ADMIN_SERVICE,
  COMMON_ACTION,
  LOADING_ACTION,
  SUCCESS,
  FAILURE,
  REQUEST,
} from 'src/redux/constant';
import { TESTING_TYPE_URL } from 'src/constants/common';

import { testServices } from 'src/services';
import { ServicePath } from 'src/constants/routerConstants';

function* getTestListSaga(action) {
  try {
    const result = yield testServices.getTestList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getTestDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield testServices.getTestDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_DETAIL),
      payload: result,
    });
    if (redirect) history.push(redirect);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* createTestSaga(action) {
  try {
    const result = yield testServices.createTest(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST),
      payload: result,
    });
    yield history.push(`/admin-service/test/${result.data.test.id}/edit`);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST), errors: e.errors });
  }
}

function* updateTestSaga(action) {
  try {
    const result = yield testServices.updateTest(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.UPDATE_TEST),
      payload: result,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.UPDATE_TEST), errors: e.errors });
  }
}

function* getQuestionsSaga(action) {
  try {
    const result = yield testServices.getQuestions(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_QUESTIONS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_QUESTIONS), errors: e.errors });
  }
}

function* moveQuestionSaga(action) {
  try {
    yield testServices.moveQuestion(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.MOVE_QUESTION),
      payload: {
        meta: {
          dragIndex: action.payload.questionIndex,
          hoverIndex: action.payload.requestData.position,
        },
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.MOVE_QUESTION), errors: e.errors });
  }
}

function* getQuestionDetailSaga(action) {
  try {
    const result = yield testServices.getQuestionDetail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_QUESTION_DETAIL),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_QUESTION_DETAIL), errors: e.errors });
  }
}

function* createQuestionSaga(action) {
  try {
    const { questionForm } = action.payload;
    const result = yield testServices.createQuestion(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION),
      payload: result,
    });
    yield questionForm.resetFields();
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield showAlertNotice({ type: 'success', message: '質問の作成が完了しました。' });
  } catch (e) {
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION), errors: e.errors });
  }
}

function* updateQuestionSaga(action) {
  try {
    const { requestActions } = action.payload;
    const result = yield testServices.updateQuestion(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.UPDATE_QUESTION),
      payload: result,
    });
    yield requestActions.setDeletedAnswers([]);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.UPDATE_QUESTION), errors: e.errors });
  }
}

function* deleteQuestionSaga(action) {
  try {
    yield testServices.deleteQuestion(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION),
      payload: action.payload,
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に削除されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION), errors: e.errors });
  }
}

function* getFileDownloadURLSaga(action) {
  try {
    const result = yield testServices.getFileDownloadURL(action.payload);
    if (result.data.test?.explanationFile) {
      window.open(result.data.test?.explanationFile?.url, '_blank');
    }
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
  }
}

function* joinOverviewTestSaga(action) {
  try {
    const {
      courseId,
      unitVersionId,
      groupId,
      chapterId,
      testType,
    } = action.payload;
    const result = yield testServices.joinOverviewTest(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.JOIN_OVERVIEW_TEST),
      payload: {
        data: {
          unitVersionId: parseFloat(unitVersionId),
          questions: result.data.questions,
          test: result.data.test,
        },
        meta: result.meta,
      },
    });
    yield put({
      type: REQUEST(COMMON_ACTION.SELECT_QUESTION),
      payload: result.data.questions[0]?.id,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (groupId && chapterId) {
      yield history.push({
        pathname: `/admin-service/view-course/${courseId}/testing/${unitVersionId}`,
        search: `?group=${groupId}&chapter=${chapterId}`,
      });
    } else {
      return yield history.push(
        `/admin-service/view-course/${courseId}/${TESTING_TYPE_URL[testType]}/${unitVersionId}`,
      );
    }
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.JOIN_OVERVIEW_TEST), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* copyTest(action) {
  try {
    const result = yield testServices.copyTest(action.payload);
    yield put({ type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.COPY_TEST), payload: result.data });
    yield history.push(`/admin-service/test/${result.data.test.id}/edit`)
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.COPY_TEST), errors: e.errors });
  }
}

function* deleteTest(action) {
  try {
    const result = yield testServices.deleteTest(action.payload);
    yield put({ type: SUCCESS(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST), payload: result.data });
    yield history.push(ServicePath.TestList);
    yield showAlertNotice({ type: 'success', message: "正常に削除されました。" });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) })
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST), errors: e.errors });
    yield put({ type: REQUEST(MODAL_ACTION.CLOSE) })
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) })
  }
}


export default function* testSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_LIST), getTestListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_DETAIL), getTestDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST), createTestSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.UPDATE_TEST), updateTestSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_QUESTIONS), getQuestionsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.MOVE_QUESTION), moveQuestionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_QUESTION_DETAIL), getQuestionDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION), createQuestionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.UPDATE_QUESTION), updateQuestionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION), deleteQuestionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_FILE_DOWNLOAD_URL), getFileDownloadURLSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.JOIN_OVERVIEW_TEST), joinOverviewTestSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.COPY_TEST), copyTest),
    takeLatest(REQUEST(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST), deleteTest)
  ]);
}
