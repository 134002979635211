//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { Space, Progress, Tag } from 'antd';
import isNumber from 'lodash/isNumber';

import SortableHeader from 'src/components/tables/SortableHeader';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import * as Styles from './style';
import { convertSecondTime } from 'src/utils/dateTime';
import { DATE_FORMAT, FINISH_TEST_STATUS } from 'src/constants/common';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import * as Style from './style';
import { LoginStorage } from 'src/utils/localStorge';
import history from 'src/utils/history';

interface Props {
  userProgress: {
    data: {
      id: number;
      jituName: string;
      email: string;
      totalLearningTime: number;
      coursesPercentage: number;
      progressGap: number;
      lastLearningDate: string;
      joinedTestsRate: number;
      finishTestStatus: number;
    }[];
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
  };
  sortConditionAttendance: object;
  courseId?: string;
  progressRateSelect: number | null,
  isLoading: boolean;
  getCompanyUserProgressCourse(params: any): void;
  setSortConditionAttendance(params: any): void;
}

const AttendanceTable: React.FC<Props> = ({
  userProgress,
  getCompanyUserProgressCourse,
  sortConditionAttendance,
  setSortConditionAttendance,
  courseId,
  progressRateSelect,
  isLoading,
}) => {
  const { data, meta } = userProgress;
  const userStorage = LoginStorage.getData();
  
  const renderTagFinishTest = (status) => {
    switch (status) {
    case FINISH_TEST_STATUS.passed:
      return <Tag color="green">合格</Tag>;
    case FINISH_TEST_STATUS.waiting:
      return <Text>採点待ち</Text>;
    case FINISH_TEST_STATUS.failed:
      return <Tag color="red">不合格</Tag>;
    case FINISH_TEST_STATUS.unfinished:
      return <Text xs>未受験</Text>;
    case FINISH_TEST_STATUS.unexist:
      return <Text xs>ー</Text>;
    default:
      break;
    }
  };

  const dataTable = (data || []).map((row) => ({
    ...row,
    key: row.id,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'H時間m分'),
    progressGap: isNumber(row.progressGap) ? `${row.progressGap}%` : 'ー',
    joinedTestsRate: isNumber(row.joinedTestsRate) ? `${row.joinedTestsRate}%` : 'ー',
    lastLearningDate: row.lastLearningDate ? moment(row.lastLearningDate).format(DATE_FORMAT) : '',
  }));

  const columns = [
    {
      title: (
        <SortableHeader
          type="jituName"
          title="名前"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      dataIndex: 'jituName',
      render: (_, record) => (
        <Style.StudentName>
          <TooltipParagraph isPreWrap rows={2} {...(record.status === 'banned' && { style: { marginTop: 14 } } )}>
            {record.jituName}
          </TooltipParagraph>
          {record.status === 'banned' && (
            <Style.StudentStatus>停止</Style.StudentStatus>
          )}
        </Style.StudentName>
      ),
      width: 200,
    },
    {
      title: (
        <SortableHeader
          type="email"
          title="メールアドレス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      dataIndex: 'email',
      width: 250,
    },
    {
      title: (
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      dataIndex: 'totalLearningTime',
      width: 120,
    },
    {
      title: (
        <SortableHeader
          type="coursesPercentage"
          title="進捗率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.coursesPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.coursesPercentage} strokeColor='#0DA2FE' />
        </Space>
      ),
      dataIndex: 'coursesPercentage',
      width: 150,
    },
    {
      title: (
        <SortableHeader
          type="progressGap"
          title="進捗ギャップ"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      dataIndex: 'progressGap',
      width: 150,
    },
    {
      title: (
        <SortableHeader
          type="joinedTestsRate"
          title="テスト受講率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      dataIndex: 'joinedTestsRate',
      width: 150,
    },
    {
      title: (
        <SortableHeader
          type="finishTestStatus"
          title="合否"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      render: (_, record) => <Styles.TagWrapper>{renderTagFinishTest(record.finishTestStatus)}</Styles.TagWrapper>,
      dataIndex: 'finishTestStatus',
      width: 130,
    },
    {
      title: (
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyUserProgressCourse}
          setAction={setSortConditionAttendance}
        />
      ),
      dataIndex: 'lastLearningDate',
      width: 130,
    },
  ];
  return (
    <Styles.AttendanceTableWrapper>
      <Styles.TableCustom
        className="table-cursor-pointer"
        pagination={false}
        scroll={{ x: 1310, y: 506 }}
        columns={columns}
        dataSource={dataTable}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={isLoading}
        onRow={(record) => ({
          onClick: () => {
            const urlProfile = userStorage.user.id === record.key ? 'my-profile' : `profile/${record.key}`;
            history.push(`/admin/${urlProfile}`);
          }
        })}
      />
      {!_isEmpty(dataTable) && (
        <PaginationLayout
          pageInfo={meta.pageInfo}
          onChangePage={getCompanyUserProgressCourse}
          sortCondition={sortConditionAttendance}
          dataParam={{
            courseId,
            requestData: {
              progressRate: progressRateSelect,
              page: meta.pageInfo?.page,
            },
          }}
        />
      )}
    </Styles.AttendanceTableWrapper>
  );
};

export default AttendanceTable;
