// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import { ADMIN_COMPANY, SUCCESS, FAILURE, REQUEST } from '../../constant';

import { companyStatisticService } from 'src/services';

function* getStatisticCoursesSaga() {
  try {
    const result = yield companyStatisticService.getStatisticCourses();
    yield put({
      type: SUCCESS(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_COURSES), errors: e.errors });
  }
}

function* getCurrentWeekSaga() {
  try {
    const result = yield companyStatisticService.getCurrentWeek();
    yield put({
      type: SUCCESS(ADMIN_COMPANY.STATISTIC_ACTION.GET_CURRENT_WEEK),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.STATISTIC_ACTION.GET_CURRENT_WEEK), errors: e.errors });
  }
}

function* getStatisticStudentsSaga(action) {
  try {
    const result = yield companyStatisticService.getStatisticStudents();
    yield put({
      type: SUCCESS(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_STUDENTS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_STUDENTS), errors: e.errors });
  }
}

function* getWeekChartSaga(action) {
  try {
    const result = yield companyStatisticService.getWeekChart(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.STATISTIC_ACTION.GET_WEEK_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.STATISTIC_ACTION.GET_WEEK_CHART), errors: e.errors });
  }
}

function* getDayChartSaga(action) {
  try {
    const result = yield companyStatisticService.getDayChart(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.STATISTIC_ACTION.GET_DAY_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.STATISTIC_ACTION.GET_DAY_CHART), errors: e.errors });
  }
}

export default function* companyStatisticSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_COURSES), getStatisticCoursesSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_CURRENT_WEEK), getCurrentWeekSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_STUDENTS), getStatisticStudentsSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_WEEK_CHART), getWeekChartSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_DAY_CHART), getDayChartSaga),
  ]);
}
