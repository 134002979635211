// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  put,
  takeLatest,
  all,
  call,
} from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty'

import {
  ServicePath,
  CompanyPath,
  StudentPath,
} from 'src/constants/routerConstants';
import { LoginStorage, SessionStorage } from 'src/utils/localStorge';
import history from 'src/utils/history';
import { MODAL_ACTION } from '../constant/modal.constant';
import { AUTH_ACTION, SUCCESS, FAILURE, REQUEST } from '../constant';
import ConfirmErrorsLockUserModal from 'src/components/Modal/components/ConfirmErrorsLockUser'

import { authServices } from 'src/services';

function redirectRoleTopPage(tokenInfo) {
  let TopPath;
  if (tokenInfo.role === 'admin_service') {
    TopPath = ServicePath.AdminServiceTop;
  } else if (tokenInfo.role === 'admin_company') {
    TopPath = CompanyPath.AdminCompanyTop;
  } else {
    TopPath = StudentPath.StudentTop;
  }
  return history.push(TopPath);
}

function* loginSaga(action) {
  try {
    const result = yield authServices.login(action.payload);
    yield LoginStorage.setData({ ...result.data });
    yield SessionStorage.setData({
      mouseX: window.mouseX,
      mouseY: window.mouseY,
      lastActionTime: moment(),
    });
    yield put({
      type: SUCCESS(AUTH_ACTION.LOGIN),
      payload: result.data,
    });
    yield call(redirectRoleTopPage, result.data.tokenInfo);
    yield showAlertNotice({ type: 'success', message: 'ログインに成功しました。' });
  } catch (e) {
    yield put({ type: FAILURE(AUTH_ACTION.LOGIN), errors: e.errors });
  }
}

function* logoutSaga(action) {
  try {
    const { role } = action.payload;
    yield localStorage.clear();
    yield put({
      type: SUCCESS(AUTH_ACTION.LOGOUT),
    });
    if (role === 'admin_service') {
      yield history.push(ServicePath.Login);
    } else if (role === 'admin_company') {
      yield history.push(CompanyPath.Login);
    } else {
      yield history.push(StudentPath.Login);
    }
  } catch (e) {
    yield put({ type: FAILURE(AUTH_ACTION.LOGOUT), errors: e.errors });
  }
}

function* forgotPasswordSaga(action) {
  try {
    yield authServices.forgotPassword(action.payload);
    yield put({ type: SUCCESS(AUTH_ACTION.FORGOT_PASSWORD) });
    yield showAlertNotice({ type: 'success', message: '正常に送信されました。' });
  } catch (e) {
    yield put({ type: FAILURE(AUTH_ACTION.FORGOT_PASSWORD), errors: e.errors });
  }
}

function* resetPasswordSaga(action) {
  try {
    const { token, newPassword, role } = action.payload;
    yield authServices.resetPassword({ token, newPassword });
    yield put({ type: SUCCESS(AUTH_ACTION.RESET_PASSWORD) });
    yield showAlertNotice({ type: 'success', message: 'パスワードが正常に変更されました。' });
    if (role === 'admin_service') {
      yield history.push(ServicePath.Login);
    } else if (role === 'admin_company') {
      yield history.push(CompanyPath.Login);
    } else {
      yield history.push(StudentPath.Login);
    }
  } catch (e) {
    if (!isEmpty(e.errors) && e.errors[0].code !== 1314) {
      yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    }
    yield put({ type: FAILURE(AUTH_ACTION.RESET_PASSWORD), errors: e.errors });
  }
}

function* getResetPasswordStatusSaga(action) {
  const { token, role } = action.payload;
  try {
    yield authServices.getResetPasswordStatus({ token });
    yield put({ type: SUCCESS(AUTH_ACTION.GET_RESET_PASSWORD_STATUS) });
  } catch (e) {
    if (role === 'admin_service') {
      yield history.push(ServicePath.Login);
    } else if (role === 'admin_company') {
      yield history.push(CompanyPath.Login);
    } else {
      yield history.push(StudentPath.Login);
    }
    yield put({ type: FAILURE(AUTH_ACTION.GET_RESET_PASSWORD_STATUS), errors: e });
    if (!isEmpty(e.errors) && e.errors[0].code === 1314) {
      const modalProps = {
        modalData: {
          title: '解除の申請',
        },
        width: 500,
      };
      yield put({
        type: REQUEST(MODAL_ACTION.SHOW),
        payload: <ConfirmErrorsLockUserModal {...modalProps} />,
      });
    } else {
      yield showAlertNotice({ type: 'error', message: 'パスワードリセットトークンは無効です。' });
    }
  }
}

export default function* authSaga() {
  yield all([
    takeLatest(REQUEST(AUTH_ACTION.LOGIN), loginSaga),
    takeLatest(REQUEST(AUTH_ACTION.LOGOUT), logoutSaga),
    takeLatest(REQUEST(AUTH_ACTION.FORGOT_PASSWORD), forgotPasswordSaga),
    takeLatest(REQUEST(AUTH_ACTION.RESET_PASSWORD), resetPasswordSaga),
    takeLatest(REQUEST(AUTH_ACTION.GET_RESET_PASSWORD_STATUS), getResetPasswordStatusSaga),
  ]);
}
