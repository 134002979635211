//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Space, Button } from 'antd';

import { Text } from 'src/components/styles';

import ChangeRoleModal from '../ChangeRoleModal';

import { ACCOUNT_ROLES } from 'src/constants/common';
import { ACCOUNT_PROFILE_FIELDS } from '../../constants';

import * as Style from './styles';
import ChangeEmailModal from '../ChangeEmailModal';

interface IAccountProfileProps {
  clearUpdateRoleErrors(): void;
  clearUpdateEmailUserErrors(): void;
  showModal(params): void;
  role: string;
  profileDetail: {
    data: {
      id: number;
      department: string;
      email: string;
      jituName: string;
      status: string;
      roles: string[];
    };
    load: boolean;
  };
}

const AccountProfile: React.FC<IAccountProfileProps> = ({
  clearUpdateRoleErrors,
  clearUpdateEmailUserErrors,
  showModal,
  role,
  profileDetail,
}) => {
  const { roles } = profileDetail.data;
  const isAdminService = (roles || []).includes("admin_service");

  function showChangeRoleModal() {
    clearUpdateRoleErrors();
    const modalProps = {
      width: 560,
      modalData: {
        title: 'アカウント種別変更',
        profileDetail: profileDetail.data,
        editorRole: role,
      },
    };
    return showModal(<ChangeRoleModal {...modalProps} />);
  }

  function showChangeEmailModal() {
    clearUpdateEmailUserErrors();
    const modalProps = {
      width: 560,
      modalData: {
        title: "メールアドレス変更",
        id: profileDetail.data.id,
        email: profileDetail.data.email,
      },
    };
    return showModal(<ChangeEmailModal {...modalProps} />);
  }

  function renderProfileContent() {
    return ACCOUNT_PROFILE_FIELDS.map((field, fieldIndex) => (
      <Style.ProfileContent key={`overview-profile-${fieldIndex}`}>
        <Space>
          <Text lg w6 style={{ minWidth: 150 }}>
            {field.title}
          </Text>
          <Text lg>
            {fieldIndex === 1
              ? (profileDetail.data[field.fieldName] || [])
                  .map((roleType) => ACCOUNT_ROLES[roleType])
                  .toString()
              : profileDetail.data[field.fieldName]}
          </Text>
        </Space>
        {fieldIndex === 1 && (
          <Button
            type="primary"
            onClick={() => {
              showChangeRoleModal();
            }}
            disabled={
              profileDetail.load || profileDetail.data.status === "banned"
            }
          >
            変更する
          </Button>
        )}
        {fieldIndex === 2 && !isAdminService && (
          <Button
            type="primary"
            onClick={() => {
              showChangeEmailModal();
            }}
            disabled={profileDetail.load}
          >
            変更する
          </Button>
        )}
      </Style.ProfileContent>
    ));
  }

  return (
    <Style.ProfileWrapper>
      <Style.HeaderWrapper>
        <Text white lg w6>アカウント情報</Text>
      </Style.HeaderWrapper>
      {renderProfileContent()}
    </Style.ProfileWrapper>
  );
};

export default AccountProfile;
