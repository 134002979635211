// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash/lang';
import CKEditor from 'ckeditor4-react';

import * as Style from './styles';

interface ITipContentProps {
  content?: string;
  tipForm: any;
}

const TipContent: React.FC<ITipContentProps> = ({
  content,
  tipForm,
}) => {
  const contentConfig = {
    extraPlugins: 'justify, font, colorbutton',
    toolbar: [
      [ 'Styles', 'FontSize'],
      ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'],
      ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
      ['Link', 'Unlink'],
      ['Image', 'Table'],
      ['TextColor', 'BGColor'],
      ['Maximize'],
    ],
    removeButtons: '',
    removePlugins: 'elementspath, resize',
    language: 'ja',
  };

  return (
    <>
      <Style.TipContent>
        <CKEditor
          id="tip-content"
          {...(!isEmpty(content) && { data: content })}
          onChange={(event) => tipForm.setFieldsValue({ content: event.editor.getData() })}
          limit={300}
          config={contentConfig}
        />
      </Style.TipContent>
      <Form.Item
        name="content"
        rules={[
          { required: true, message: 'Tip内容は必須項目です。' },
          { whitespace: true, message: 'Tip内容は必須項目です。' },
          () => ({
            validator(_, value) {
              const element = document.createElement('DIV');
              element.innerHTML = value;
              const textData = element.textContent || element.innerText || '';
              // remove line break
              if (textData.replace(/(?:\r\n|\r|\n)/g, '').length <= 300) {
                return Promise.resolve();
              }
              return Promise.reject('Tipsの内容は300文字以内で入力下さい。');
            },
          }),
        ]}
      >
        <Input style={{ display: 'none' }} />
      </Form.Item>
    </>
  );
};

export default TipContent;
