// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const ACCOUNT_ACTION = {
  GET_CONFIRMATION_STATUS: 'ADMIN_SERVICE/ACCOUNT_ACTION/GET_CONFIRMATION_STATUS',
  INVITE_SERVICE: 'ADMIN_SERVICE/ACCOUNT_ACTION/INVITE_SERVICE',
  INVITE_COMPANY: 'ADMIN_SERVICE/ACCOUNT_ACTION/INVITE_COMPANY',
  INVITE_STUDENTS: 'ADMIN_SERVICE/ACCOUNT_ACTION/INVITE_STUDENTS',
  INVITE_SERVICES: 'ADMIN_SERVICE/ACCOUNT_ACTION/INVITE_SERVICES',
  INVITE_ADMIN_COMPANY: 'ADMIN_SERVICE/ACCOUNT_ACTION/INVITE_ADMIN_COMPANY',
  RESEND_INVITATION: 'ADMIN_SERVICE/ACCOUNT_ACTION/RESEND_INVITATION',
  CANCEL_INVITATION: 'ADMIN_SERVICE/ACCOUNT_ACTION/CANCEL_INVITATION',
  ADD_STUDENTS_TO_COURSE: 'ADMIN_SERVICE/ACCOUNT_ACTION/ADD_STUDENTS_TO_COURSE',
  GET_INVITATION_LIST: 'ADMIN_SERVICE/ACCOUNT_ACTION/GET_INVITATION_LIST',
  GET_ACCOUNT_LIST: 'ADMIN_SERVICE/ACCOUNT_ACTION/GET_ACCOUNT_LIST',
  GET_STUDENTS_BY_COMPANY: 'ADMIN_SERVICE/ACCOUNT_ACTION/GET_STUDENTS_BY_COMPANY',
  CLEAR_STUDENTS_BY_COMPANY: 'ADMIN_SERVICE/ACCOUNT_ACTION/CLEAR_STUDENTS_BY_COMPANY',
  BAN_ACCOUNT: 'ADMIN_SERVICE/ACCOUNT_ACTION/BAN_ACCOUNT',
  CONFIRM_ACCOUNT: 'ADMIN_SERVICE/ACCOUNT_ACTION/CONFIRM_ACCOUNT',
  ADD_STUDENTS_TO_COURSE_BY_CSV: "ADMIN_SERVICE/ACCOUNT_ACTION/ADD_STUDENTS_TO_COURSE_BY_CSV",
  BULK_RESEND_INVITATIONS: 'ADMIN_SERVICE/ACCOUNT_ACTION/BULK_RESEND_INVITATIONS',
  BULK_CANCEL_INVITATIONS: 'ADMIN_SERVICE/ACCOUNT_ACTION/BULK_CANCEL_INVITATIONS',
};
