//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Select,
  Form,
  Input,
  Checkbox,
  Space,
  Spin,
} from 'antd';
import { CaretDownOutlined, PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';


import { MainWrapper } from 'src/components/layouts/ContentLayout';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';

import {
  getCompanyContractList as getCompanyContractListAction,
  getCompanyCustomInfos as getCompanyCustomInfosAction,
  updateCompanyCustomInfos as updateCompanyCustomInfosAction,
  deleteCompanyCustomInfos as deleteCompanyCustomInfosAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import { DEFAULT_INFO_FIELDS } from './constants';

import * as Style from './styles';

interface IProfileSettingProps extends StateProps, DispatchProps {
  getCompanyContractList(): void;
  getCompanyCustomInfos(params): void;
  updateCompanyCustomInfos(params): void;
  deleteCompanyCustomInfos(params): void;
  showModal(params): void;
  closeModal(): void;
  companyContracts: {
    data: {
      id: number,
      contractName: string,
    }[],
  };
  companyCustomInfos: {
    data: {
      id: number;
      isDisplay: boolean;
      isRequired: boolean;
      fieldName: string;
    }[];
    load: boolean;
  };
  companyCustomDisplay: {
    data: {
      department: boolean;
      jobTitle: boolean;
      jobType: boolean;
      birthday: boolean;
    };
    load: boolean;
  };
}

const ProfileSetting: React.FC<IProfileSettingProps> = ({
  getCompanyContractList,
  getCompanyCustomInfos,
  updateCompanyCustomInfos,
  deleteCompanyCustomInfos,
  showModal,
  closeModal,
  companyContracts,
  companyCustomInfos,
  companyCustomDisplay,
}) => {
  const [companyIdSelected, setCompanyIdSelected] = useState<number | undefined>();
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [customDisplayField, setCustomDisplayField] = useState<object>({});
  const [customInfosForm] = Form.useForm();

  const infosInitialFormValues = {
    customInfos: [
      ...companyCustomInfos.data,
    ],
  };

  useEffect(() => {
    getCompanyContractList();

     return () => {
       closeModal()
     }
  }, []);

  useEffect(() => {
    if (companyIdSelected) {
      getCompanyCustomInfos({ id: companyIdSelected });
    }
  }, [companyIdSelected]);

  useEffect(() => {
    if (companyIdSelected) {
      customInfosForm.resetFields();
    }
  }, [companyIdSelected, companyCustomInfos.data]);

  useEffect(() => {
    setCustomDisplayField(companyCustomDisplay.data);
  }, [companyCustomDisplay.data]);

  function handleSubmitForm(values) {
    let customRequestData = [] as any;
    values.customInfos.map((customInfo) => {
      if (customInfo.id) {
        customRequestData = [
          ...customRequestData,
          {
            id: customInfo.id,
            isDisplay: customInfo.isDisplay,
            isRequired: customInfo.isRequired,
          },
        ];
      } else {
        customRequestData = [
          ...customRequestData,
          {
            isDisplay: customInfo.isDisplay,
            isRequired: customInfo.isRequired,
            fieldName: customInfo.fieldName.trim(),
          },
        ];
      }
      return null;
    });
    updateCompanyCustomInfos({
      id: companyIdSelected,
      requestData: {
        companyCustomUserInfos: customRequestData,
        customDisplayField,
      },
    });
    return setIsEditForm(false);
  }

  function handleChangeDisplayDefaultField(e) {
    const { name, checked } = e.target;
    setCustomDisplayField({
      ...customDisplayField,
      [name]: checked,
    });
  }

  function handleCheckboxCustomField(e, index, type) {
    if (type === 'display' ? !e.target.checked : e.target.checked) {
      const customInfosFieldValue = customInfosForm.getFieldValue('customInfos');
      customInfosFieldValue[index] = type === 'display'
        ? {
          ...customInfosForm.getFieldValue('customInfos')[index],
          isRequired: false,
        }
        : {
          ...customInfosForm.getFieldValue('customInfos')[index],
          isDisplay: true,
        };
      customInfosForm.setFields([
        {
          name: ['customInfos'],
          value: [
            ...customInfosFieldValue,
          ],
        },
      ]);
    }
  }

  function handleDeleteField({ fieldIndex, removeField }) {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: '削除してもよろしいですか。削除した場合、アカウントの該当情報が削除されます。',
      },
      modalAction: {
        confirm: () => {
          deleteCompanyCustomInfos({
            companyId: companyIdSelected,
            infoId: companyCustomInfos.data[fieldIndex].id,
          });
          removeField();
        },
      },
    };
    if (companyCustomInfos.data[fieldIndex]?.id) {
      return showModal(<ConfirmModal {...modalProps} />);
    } else {
      return removeField();
    }
  }

  function renderCompanyOptions() {
    if (isEmpty(companyContracts.data)) return null;
    return companyContracts.data.map((company) => (
      <Select.Option key={`company-${company.id}`} value={company.id}>
        <TooltipParagraph
          style={{
            lineHeight: 'unset',
            fontSize: 24,
          }}
        >
          {company.contractName}
        </TooltipParagraph>
      </Select.Option>
    ));
  }

  function renderDefaultField() {
    const newDefaultInfoField = DEFAULT_INFO_FIELDS.map((field) => ({
      isDisplay:
        ['department', 'jobTitle', 'jobType', 'birthday'].includes(field.fieldKey)
        && !isEmpty(companyCustomDisplay.data)
          ? companyCustomDisplay.data[field.fieldKey]
          : field.isDisplay,
      isRequired: field.isRequired,
      fieldName: field.fieldName,
      fieldKey: field.fieldKey,
    }));
    if (isEditForm) {
      return newDefaultInfoField.map((field, fieldIndex) => (
        <Style.TabWrapperContent key={`edit-default-field-${fieldIndex}`} isEdit>
          <div style={{ minWidth: 80 }}>
            <Checkbox
              name={field.fieldKey}
              defaultChecked={field.isDisplay}
              disabled={!['department', 'jobTitle', 'jobType', 'birthday'].includes(field.fieldKey)}
              onChange={(e) => handleChangeDisplayDefaultField(e)}
              style={{ marginBottom: 24 }}
            />
          </div>
          <div style={{ minWidth: 80 }}>
            <Checkbox
              checked={field.isRequired}
              disabled
              style={{ margin: '0 0 24px' }}
            />
          </div>
          <Input value={field.fieldName} disabled style={{ marginBottom: 24 }} />
        </Style.TabWrapperContent>
      ));
    }
    return newDefaultInfoField.map((field, fieldIndex) => (
      <Style.TabWrapperContent key={`default-field-${fieldIndex}`}>
        <Text lg style={{ width: 80 }}>
          {field.isDisplay ? '表示' : '非表示'}
        </Text>
        <Text lg style={{ width: 80 }}>
          {field.isRequired ? '必須' : '任意'}
        </Text>
        <Text lg>{field.fieldName}</Text>
      </Style.TabWrapperContent>
    ));
  }

  function renderCustomField() {
    if (isEditForm) {
      return (
        <>
          <Form.List name="customInfos" >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, fieldIndex) => (
                  <Style.TabWrapperContent key={`${field.key}-${fieldIndex}`} isEdit>
                    <Form.Item
                      {...field}
                      name={[field.name, 'id']}
                      fieldKey={[field.fieldKey, 'id'] as any}
                      style={{ display: 'none' }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'isDisplay']}
                      fieldKey={[field.fieldKey, 'isDisplay'] as any}
                      shouldUpdate={(prevValues, currentValues) => !prevValues.isDisplay && currentValues.isRequired}
                      valuePropName="checked"
                      style={{ minWidth: 80 }}
                    >
                      <Checkbox
                        onChange={(e) => handleCheckboxCustomField(e, fieldIndex, 'display')}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'isRequired']}
                      fieldKey={[field.fieldKey, 'isRequired'] as any}
                      valuePropName="checked"
                      style={{ minWidth: 80 }}
                    >
                      <Checkbox
                        onChange={(e) => handleCheckboxCustomField(e, fieldIndex, 'required')}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'fieldName']}
                      fieldKey={[field.fieldKey, 'fieldName'] as any}
                      rules={[
                        { required: true, message: 'この項目は必須項目です。' },
                        { whitespace: true, message: 'この項目は必須項目です。' },
                        { max: 10, message: '10桁以下で入力してください。' },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Input disabled={!!companyCustomInfos.data[fieldIndex]?.id} />
                    </Form.Item>
                    <Style.FieldRemoveAction>
                      <Button
                        type="link"
                        ghost
                        onClick={() => handleDeleteField({
                          fieldIndex,
                          removeField: () => remove(field.name),
                        })}
                        icon={<CloseCircleOutlined style={{ fontSize: 20, color: '#d25109' }} />}
                      />
                    </Style.FieldRemoveAction>
                  </Style.TabWrapperContent>
                ))}
                {fields.length < 5 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add({ isRequired: false, fieldName: '' })}
                      style={{ margin: '6px 20px 0', width: 'calc(100% - 40px)' }}
                    >
                      <PlusOutlined /> フィールド追加
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </>
      );
    }
    return companyCustomInfos.data.map((info, infoIndex) => (
      <Style.TabWrapperContent key={`info-${infoIndex}`}>
        <Text lg style={{ width: 80 }}>
          {info.isDisplay ? '表示' : '非表示'}
        </Text>
        <Text lg style={{ width: 80 }}>
          {info.isRequired ? '必須' : '任意'}
        </Text>
        <Text lg>{info.fieldName}</Text>
      </Style.TabWrapperContent>
    ));
  }

  return (
    <Form
      form={customInfosForm}
      name="customInfosForm"
      initialValues={infosInitialFormValues}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.ProfileSettingTop>
        <Select
          bordered={false}
          allowClear
          showSearch
          suffixIcon={<CaretDownOutlined />}
          dropdownClassName="container-dropdown-select"
          optionFilterProp="children"
          placeholder="選択してください"
          notFoundContent="データが存在しません。"
          value={companyIdSelected}
          onChange={(value) => setCompanyIdSelected(value)}
          disabled={isEditForm}
          filterOption={(input: any, option: any) => {
            const optionTitle = option.children.props.children;
            const filterValue = (input || '').trim();
            return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
          }}
          style={{ minWidth: 220, width: 'auto' }}
        >
          {renderCompanyOptions()}
        </Select>
        <Space>
          {isEditForm
            ? (
              <Space align="center" size="middle">
                <Button
                  type="primary"
                  htmlType="button"
                  className="btn-secondary"
                  onClick={() => {
                    setIsEditForm(false);
                    customInfosForm.resetFields();
                  }}
                >
                  キャンセル
                </Button>
                <Button type="primary" htmlType="submit">保存する</Button>
              </Space>
            )
            : <Button
              type="primary"
              htmlType="button"
              onClick={() => setIsEditForm(true)}
              disabled={!companyIdSelected}
            >
              修正する
            </Button>
          }
        </Space>
      </Style.ProfileSettingTop>
      <Spin spinning={companyCustomInfos.load || companyCustomDisplay.load}>
        <MainWrapper
          title={
            <>
              <Text white lg style={{ width: 80, marginLeft: 4 }}>表示</Text>
              <Text white lg style={{ width: 80 }}>必須</Text>
              <Text white lg>項目</Text>
            </>
          }
          style={{ marginTop: 16 }}
        >
          <Style.ProfileSettingContent isEdit={isEditForm}>
            {renderDefaultField()}
            {renderCustomField()}
          </Style.ProfileSettingContent>
        </MainWrapper>
      </Spin>
    </Form>
  );
};

const mapStateToProps = (state) => {
  const {
    companyContracts,
    companyCustomInfos,
    companyCustomDisplay,
  } = state.companyReducer;
  return {
    companyContracts,
    companyCustomInfos,
    companyCustomDisplay,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  getCompanyCustomInfos: (params) => dispatch(getCompanyCustomInfosAction(params)),
  updateCompanyCustomInfos: (params) => dispatch(updateCompanyCustomInfosAction(params)),
  deleteCompanyCustomInfos: (params) => dispatch(deleteCompanyCustomInfosAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSetting);
