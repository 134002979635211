// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { Pagination } from 'antd';
import { isEmpty } from 'lodash/lang';

import * as Style from './styles';

interface IPaginationProps {
  pageInfo: {
    count: number;
    page: number;
    limit: number;
  };
  filterParams?: object;
  sortCondition?: object;
  dataParam?: any;
  onChangePage(params): void;
  pageSizeOptions?: string[];
}

const PaginationLayout: React.FC<IPaginationProps> = ({
  pageInfo,
  filterParams,
  sortCondition,
  dataParam,
  onChangePage,
  pageSizeOptions = ['10', '20', '50'],
}) => {
  const [limit, setLimit] = useState<number>(10)

  useEffect(() => {
    setLimit(pageInfo?.limit || 10)
  }, [pageInfo])

  function handleChangePage(page, pageSize) {
    const metaParam = !isEmpty(sortCondition)
      ? { ...filterParams, sort: sortCondition }
      : (!isEmpty(filterParams) ? filterParams : {});

    if (!isEmpty(dataParam)) {
      const newDataParam = {
        ...dataParam,
        requestData: {
          ...dataParam.requestData,
          ...metaParam,
          page,
          items: parseInt(pageSize, 10),
        },
      };
      onChangePage({ ...newDataParam });
    } else {
      onChangePage({
        ...metaParam,
        page,
        items: parseInt(pageSize, 10),
      });
    }
  }

  if (pageInfo) {
    return (
      <Style.PaginateContainer>
        <Pagination
          onChange={(page) => handleChangePage(page, pageInfo?.limit)}
          defaultPageSize={limit}
          pageSize={limit}
          current={pageInfo.page}
          total={pageInfo.count}
          pageSizeOptions={pageSizeOptions}
          locale={{ items_per_page: '件' }}
          onShowSizeChange={(_, size) => handleChangePage(1, size)}
          showSizeChanger
        />
      </Style.PaginateContainer>
    );
  }
  return null;
};

export { PaginationLayout };
