// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { findIndex } from 'lodash/array';
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  tagList: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
};

export type TagReducer = Readonly<typeof initialState>;

export default (state: TagReducer = initialState, action: any): TagReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.TAG_ACTION.GET_LIST): {
    return {
      ...state,
      tagList: {
        ...state.tagList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.TAG_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      tagList: {
        data: data.tags,
        load: false,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TAG_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      tagList: {
        ...state.tagList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG): {
    const { data } = action.payload;
    const newTagList = state.tagList.data;
    const updatedTagListIndex = findIndex(state.tagList.data, { id: data.id });
    newTagList.splice(updatedTagListIndex, 1, data );
    return {
      ...state,
      tagList: {
        ...state.tagList,
        data: newTagList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG): {
    const { errors } = action;
    return {
      ...state,
      tagList: {
        ...state.tagList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG): {
    const { data } = action.payload;
    const newTagList = state.tagList.data;
    const  removeTagListIndex = findIndex(state.tagList.data, { id: data.id });
    newTagList.splice(removeTagListIndex, 1);
    return {
      ...state,
      tagList: {
        ...state.tagList,
        data: newTagList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG): {
    const { errors } = action;
    return {
      ...state,
      tagList: {
        ...state.tagList,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
