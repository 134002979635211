// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const CoursesListContainer = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
`;

export const CoursesListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;
