// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { merge } from 'lodash/object';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  courseDetail: {
    data: {
      plan: {},
      company: {},
      teachers: [],
      tags: [],
      category: {},
      surveys: [] as {
        id: number;
        item: object;
        unit: object;
        version: string;
      }[],
      tests: [] as {
        id: number;
        item: object;
        unit: object;
        version: string;
      }[],
      groups: [] as {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        endDate: number;
        isDelayed: boolean;
        chapters: {
          id: number;
          name: string;
          totalUnits: number;
          totalVideosTime: number;
          faqs: {
            id: number;
            name: string;
            question: string;
            answer: string;
          }[];
          unitVersions: {
            id: string;
            item: object;
            unit: object;
          }[];
        }[];
      }[],
      emailWeeklySchedule: [],
      emailHoursSchedule: undefined,
      emailMinutesSchedule: undefined,
      excludeFinishedUserSchedule: false,
      contentOverview: {},
      lastUpdater: {},
      updatedAt: '',
    },
    meta: {},
    load: false,
    errors: [] as object[],
  },
  courseOverviewDetail: {
    data: {} as {
      contentOverview: {
        videosTotalDuration: number,
      },
      teachers: [],
      isLearn: string,
      remainingRecommendedDays: number;
    },
    meta: {},
    load: false,
    errors: [],
  },
  learningStatus: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  learningChart: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
  learningUsersProgress: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  attendanceStatus: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  attendanceChart: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  courseStudents: {
    data: [] as {
      id: number,
    }[],
    meta: {},
    load: false,
    errors: [],
    isUpdateSuccess: false,
  },
  testScoreOverview: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  testScoreChart: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  testScoreStudents: {
    data: {
      coursesUnitVersions: [],
      usersScore: [],
    },
    meta: {},
    load: false,
    errors: [],
  },
  courseList: {
    data: [] as {
      name: string;
      versions: {
        id: number;
        name: string;
        versionDescription: string;
        updatedAt: string;
        contractCount: number;
        usedLicensesCount: number;
        remainingLicensesCount: number;
        studyTime: number;
        contentOverview: {
          videosCount: number;
          videosTotalDuration: number;
        };
      }[];
    }[],
    meta: {},
    load: false,
    loadVersion: false,
    errors: [] as object[],
  },
  coursesByCompany: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  chapterFAQs: {
    data: [] as {
      category: {},
      id: number;
      name: string;
      question: string;
      updatedAt: string
    }[],
    meta: {},
    load: false,
    errors: [],
  },
  faqList: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  overviewCourseDetail: {
    data: {
      groups: [] as {
        id: number;
        name: string;
        chapters: {
          id: number;
          name: string;
          unitVersions: {
            id: string;
            isDone: boolean;
            item: object;
            unit: object;
          }[];
        }[];
      }[],
    },
    meta: {},
    load: false,
    errors: [] as object[],
  },
  courseVersions: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  masterDataCourses: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  masterDataCourseCategories: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  overviewCourseContent: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  recommendDates : {
    data: {
      groupRecommendedDate: []
    },
  }
};

export type CourseReducer = Readonly<typeof initialState>;

export default (state: CourseReducer = initialState, action: any): CourseReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL): {
    const { redirect } = action.payload;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        load: !redirect,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseDetail: {
        data: data.course,
        load: false,
        meta,
        errors: [],
      },
      courseOverviewDetail: {
        data: {
          ...state.courseOverviewDetail.data,
          contentOverview: data.course.contentOverview,
          teachers: data.course.teachers,
          isLearn: data.course.isLearned,
          remainingRecommendedDays: data.course.remainingRecommendedDays,
        },
        load: false,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        load: false,
        errors,
      },
      courseOverviewDetail: {
        ...state.courseOverviewDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_COURSE_DETAIL): {
    return {
      ...state,
      courseDetail: initialState.courseDetail,
    }
  }
      
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_LEARNING_TAB): {
    return {
      ...state,
      learningChart: initialState.learningChart,
      learningUsersProgress: initialState.learningUsersProgress,
    }
  }
  
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_COURSE_OVERVIEW): {
    return {
      ...state,
      courseOverviewDetail: {
        ...initialState.courseOverviewDetail,
        data: {
          ...initialState.courseOverviewDetail.data,
          contentOverview: {
            ...initialState.courseOverviewDetail.data.contentOverview,
            videosTotalDuration: state.courseOverviewDetail.data?.contentOverview?.videosTotalDuration,
          },
          teachers: state.courseOverviewDetail.data.teachers,
        }
      },
    }
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_COMPANY_INFO): {
    return {
      ...state,
      courseOverviewDetail: {
        ...state.courseOverviewDetail,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_COMPANY_INFO): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseOverviewDetail: {
        data: data.course,
        load: false,
        meta,
        errors: [],
      },
    };
  }
  
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP_RECOMMENDED_DATE): {
    const { data } = action.payload;
    return {
      ...state,
      recommendDates: {
        ...state.recommendDates,
        data: data
      }
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP_RECOMMENDED_DATE): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_COMPANY_INFO): {
    const { errors } = action;
    return {
      ...state,
      courseOverviewDetail: {
        ...state.courseOverviewDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const groupSelectedData = state.courseDetail.data.groups[meta.groupIndex];
    merge(groupSelectedData, { chapters: data.group.chapters });
    newGroups.splice(meta.groupIndex, 1, groupSelectedData);
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LIST): {
    return {
      ...state,
      courseList: {
        ...state.courseList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseList: {
        data: data.courseCategories,
        meta,
        load: false,
        loadVersion: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      courseList: {
        ...state.courseList,
        load: false,
        errors,
      },
    };
  }


  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_VERSIONS): {
    return {
      ...state,
      courseList: {
        ...state.courseList,
        loadVersion: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_VERSIONS): {
    const { data, courseIndex } = action.payload;
    const newCourses = state.courseList.data;
    const courseSelectedData = state.courseList.data[courseIndex];
    merge(courseSelectedData, { versions: data.courses });
    newCourses.splice(courseIndex, 1, courseSelectedData);
    return {
      ...state,
      courseList: {
        ...state.courseList,
        data: newCourses,
        errors: [],
        loadVersion: false,
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_VERSIONS): {
    const { errors } = action;
    return {
      ...state,
      courseList: {
        ...state.courseList,
        loadVersion: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE): {
    const { data } = action.payload;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: data.course,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_STATUS): {
    return {
      ...state,
      attendanceStatus: {
        ...state.attendanceStatus,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_STATUS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      attendanceStatus: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_STATUS): {
    const { errors } = action;
    return {
      ...state,
      attendanceStatus: {
        ...state.attendanceStatus,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_CHART): {
    return {
      ...state,
      attendanceChart: {
        ...state.attendanceChart,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_CHART): {
    const { data, meta } = action.payload;
    return {
      ...state,
      attendanceChart: {
        data: data.joiningStatuses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_CHART): {
    const { errors } = action;
    return {
      ...state,
      attendanceChart: {
        ...state.attendanceChart,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_STUDENT_LIST): {
    return {
      ...state,
      courseStudents: {
        ...state.courseStudents,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_STUDENT_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseStudents: {
        data: data.users,
        meta,
        load: false,
        errors: [],
        isUpdateSuccess: false
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_STUDENT_LIST): {
    const { errors } = action;
    return {
      ...state,
      courseStudents: {
        ...state.courseStudents,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW): {
    return {
      ...state,
      testScoreOverview: {
        ...state.testScoreOverview,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testScoreOverview: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW): {
    const { errors } = action;
    return {
      ...state,
      testScoreOverview: {
        ...state.testScoreOverview,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_CHART): {
    return {
      ...state,
      testScoreChart: {
        ...state.testScoreChart,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_CHART): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testScoreChart: {
        data: data.chartData,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_CHART): {
    const { errors } = action;
    return {
      ...state,
      testScoreChart: {
        ...state.testScoreChart,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_STUDENTS): {
    return {
      ...state,
      testScoreStudents: {
        ...state.testScoreStudents,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_STUDENTS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testScoreStudents: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_STUDENTS): {
    const { errors } = action;
    return {
      ...state,
      testScoreStudents: {
        ...state.testScoreStudents,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION): {
    const { data } = action.payload;
    const newCourseStudents = state.courseStudents.data;
    const courseStudentsIndex = findIndex(state.courseStudents.data, { id: data.id });
    const newCourseData = {
      ...state.courseStudents.data[courseStudentsIndex],
      ...data,
    };
    newCourseStudents.splice(courseStudentsIndex, 1, newCourseData);
    return {
      ...state,
      courseStudents: {
        ...state.courseStudents,
        data: newCourseStudents,
        isUpdateSuccess: true,
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION): {
    const { errors } = action;
    return {
      ...state,
      courseStudents: {
        ...state.courseStudents,
        errors,
        isUpdateSuccess: false
      },
    };
  }
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION): {
    return {
      ...state,
      courseStudents: {
        ...state.courseStudents,
        isUpdateSuccess: false
      },
    };
  }

  // Course Unit
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT): {
    const { data } = action.payload;
    if (data.unitVersion.item.type === 'survey') {
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          data: {
            ...state.courseDetail.data,
            surveys: [
              ...state.courseDetail.data.surveys,
              data.unitVersion,
            ],
          },
          errors: [],
        },
      };
    } else {
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          data: {
            ...state.courseDetail.data,
            tests: [
              ...state.courseDetail.data.tests,
              data.unitVersion,
            ],
          },
          errors: [],
        },
      };
    }
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT): {
    const { data, meta } = action.payload;
    if (data.unitVersion.item.type === 'survey') {
      const newSurveys = state.courseDetail.data.surveys;
      newSurveys.splice(meta.unitIndex, 1, data.unitVersion);
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          data: {
            ...state.courseDetail.data,
            surveys: newSurveys,
          },
          errors: [],
        },
      };
    } else {
      const newTests = state.courseDetail.data.tests;
      newTests.splice(meta.unitIndex, 1, data.unitVersion);
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          data: {
            ...state.courseDetail.data,
            tests: newTests,
          },
          errors: [],
        },
      };
    }
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT): {
    const { data, meta } = action.payload;
    if (data.unitVersion.item.type === 'survey') {
      const newSurveys = state.courseDetail.data.surveys;
      newSurveys.splice(meta.unitIndex, 1);
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          data: {
            ...state.courseDetail.data,
            surveys: newSurveys,
          },
          errors: [],
        },
      };
    } else {
      const newTests = state.courseDetail.data.tests;
      newTests.splice(meta.unitIndex, 1);
      return {
        ...state,
        courseDetail: {
          ...state.courseDetail,
          data: {
            ...state.courseDetail.data,
            tests: newTests,
          },
          errors: [],
        },
      };
    }
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  // Course Group
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP): {
    const { data } = action.payload;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: [
            ...state.courseDetail.data.groups,
            {
              ...data.group,
              chapters: [],
              isLearned: false,
            },
          ],
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    newGroups[meta.groupIndex].name = data.group.name;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP): {
    const { meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    newGroups.splice(meta.groupIndex, 1);
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  // Group Chapter
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    newGroups[meta.groupIndex].chapters = [
      ...newGroups[meta.groupIndex].chapters,
      data.chapter,
    ];
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const newChapters = newGroups[meta.groupIndex].chapters;
    newChapters[meta.chapterIndex].name = data.chapter.name;
    newGroups[meta.groupIndex].chapters = newChapters;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER): {
    const { meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const newChapters = newGroups[meta.groupIndex].chapters;
    newChapters.splice(meta.chapterIndex, 1);
    newGroups[meta.groupIndex].chapters = newChapters;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  // Chapter Unit
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const newChapters = newGroups[meta.groupIndex].chapters;
    const newUnitVersions = newChapters[meta.chapterIndex].unitVersions
      ? newChapters[meta.chapterIndex].unitVersions
      : [];
    newUnitVersions.splice(meta.unitIndex, 0, data.unitVersion);
    newChapters[meta.chapterIndex].unitVersions = newUnitVersions;
    newGroups[meta.groupIndex].chapters = newChapters;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const newChapters = newGroups[meta.groupIndex].chapters;
    const newUnitVersions = newChapters[meta.chapterIndex].unitVersions;
    newUnitVersions.splice(meta.unitIndex, 1, data.unitVersion);
    newChapters[meta.chapterIndex].unitVersions = newUnitVersions;
    newGroups[meta.groupIndex].chapters = newChapters;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT): {
    const { meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const newChapters = newGroups[meta.groupIndex].chapters;
    const newUnitVersions = newChapters[meta.chapterIndex].unitVersions;
    newUnitVersions.splice(meta.unitIndex, 1);
    newChapters[meta.chapterIndex].unitVersions = newUnitVersions;
    newGroups[meta.groupIndex].chapters = newChapters;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW): {
    const { updater, updatedAt, contentOverview } = action.payload;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          contentOverview,
          lastUpdater: updater,
          updatedAt,
        },
        errors: [],
      },
    };
  }

  // Courses by company
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY): {
    return {
      ...state,
      coursesByCompany: {
        ...state.coursesByCompany,
        load: true,
        errors: [],
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY): {
    const { data, meta } = action.payload;
    return {
      ...state,
      coursesByCompany: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY): {
    const { errors } = action;
    return {
      ...state,
      coursesByCompany: {
        ...state.coursesByCompany,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_COURSE_DETAIL_ERRORS): {
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors: [],
      },
    };
  }


  // FAQ Chapter
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_CHAPTER_FAQS): {
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_CHAPTER_FAQS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      chapterFAQs: {
        data: data.faqs,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_CHAPTER_FAQS): {
    const { errors } = action;
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ): {
    const { data } = action.payload;
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        data: [
          ...state.chapterFAQs.data,
          data.faq,
        ],
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ): {
    const { errors } = action;
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ): {
    const { data } = action.payload;
    const newFAQList = state.chapterFAQs.data;
    const removeFQAIndex = findIndex(state.chapterFAQs.data, { id: data.id });
    newFAQList.splice(removeFQAIndex, 1);
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        data: newFAQList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ): {
    const { errors } = action;
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.MOVE_CHAPTER_FAQ): {
    const { data } = action.payload;
    const newFAQList = state.chapterFAQs.data;
    const drapFAQ = state.chapterFAQs.data[data.dragIndex];
    newFAQList.splice(data.dragIndex, 1);
    newFAQList.splice(data.hoverIndex, 0, drapFAQ);
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        data: newFAQList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.MOVE_CHAPTER_FAQ): {
    const { errors } = action;
    return {
      ...state,
      chapterFAQs: {
        ...state.chapterFAQs,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_FAQS): {
    const { data, meta, more } = action.payload;
    if (more) {
      return {
        ...state,
        faqList: {
          data: [
            ...state.faqList.data,
            ...data.faqs,
          ],
          meta,
          load: false,
          errors: [],
        },
      };
    }
    return {
      ...state,
      faqList: {
        data: data.faqs,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_FAQS): {
    const { errors } = action;
    return {
      ...state,
      faqList: {
        ...state.faqList,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL): {
    return {
      ...state,
      overviewCourseDetail: {
        ...state.overviewCourseDetail,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL): {
    const { data, meta } = action.payload;
    let newGroups = [] as any;
    if (isEmpty(state.overviewCourseDetail.data.groups)) {
      newGroups = data.groups;
    } else {
      newGroups = state.overviewCourseDetail.data.groups;
    }
    const groupIndex = findIndex(newGroups, { id: data.group.id });
    newGroups.splice(groupIndex, 1, data.group);
    return {
      ...state,
      overviewCourseDetail: {
        data: {
          ...state.overviewCourseDetail.data,
          ...data.course,
          groups: newGroups,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      overviewCourseDetail: {
        ...state.overviewCourseDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_OVERVIEW_SIDEBAR): {
    return {
      ...state,
      overviewCourseDetail: {
        data: {
          groups: [],
        },
        meta: {},
        load: false,
        errors: [],
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSES): {
    const { data, meta, more } = action.payload;
    if (more) {
      return {
        ...state,
        masterDataCourses: {
          data: [
            ...state.masterDataCourses.data,
            ...data.courses,
          ],
          meta,
          load: false,
          errors: [],
        },
      };
    }
    return {
      ...state,
      masterDataCourses: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSES): {
    const { errors } = action;
    return {
      ...state,
      masterDataCourses: {
        ...state.masterDataCourses,
        errors,
      },
    };
  }
  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSE_LIST): {
    return {
      ...state,
      masterDataCourseCategories: {
        ...state.masterDataCourseCategories,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSE_LIST): {
    const { data, meta, more } = action.payload;
    if (more) {
      return {
        ...state,
        masterDataCourseCategories: {
          data: [
            ...state.masterDataCourseCategories.data,
            ...data.courseCategories,
          ],
          meta,
          load: false,
          errors: [],
        },
      };
    }
    return {
      ...state,
      masterDataCourseCategories: {
        data: data.courseCategories,
        meta,
        load: false,
        errors: [],
      },
    };
  }

  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSE_LIST): {
    const { errors } = action;
    return {
      ...state,
      masterDataCourseCategories: {
        ...state.masterDataCourseCategories,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_COURSE_CONTENT): {
    return {
      ...state,
      overviewCourseContent: {
        data: [],
        meta: {},
        load: true,
        errors: [],
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_COURSE_CONTENT): {
    const { data, meta } = action.payload;
    return {
      ...state,
      overviewCourseContent: {
        data: data.groups,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_COURSE_CONTENT): {
    const { errors } = action;
    return {
      ...state,
      overviewCourseContent: {
        ...state.overviewCourseContent,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_VERSIONS_OF_COURSE): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseVersions: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_VERSIONS_OF_COURSE): {
    const { errors } = action;
    return {
      ...state,
      courseVersions: {
        ...state.courseVersions,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME): {
    const { data, courseIndex } = action.payload;
    const newCourse = state.courseList.data;
    newCourse[courseIndex].name = data.name;
    return {
      ...state,
      courseList: {
        ...state.courseList,
        data: newCourse,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME): {
    const { errors } = action;
    return {
      ...state,
      courseList: {
        ...state.courseList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_PROGRESS_NOTIFICATION_SETTING): {
    const { emailWeeklySchedule, emailHoursSchedule, emailMinutesSchedule, excludeFinishedUserSchedule } = action.payload;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          emailWeeklySchedule,
          emailHoursSchedule,
          emailMinutesSchedule,
          excludeFinishedUserSchedule,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_PROGRESS_NOTIFICATION_SETTING): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
    }
      
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_STATUS): {
      return {
        ...state,
        learningStatus: {
          ...state.learningStatus,
          load: true,
        },
      };
    }
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_STATUS): {
      const { data, meta } = action.payload;
      return {
        ...state,
        learningStatus: {
          data,
          meta,
          load: false,
          errors: [],
        },
      };
    }
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_STATUS): {
      const { errors } = action;
      return {
        ...state,
        learningStatus: {
          ...state.learningStatus,
          load: false,
          errors,
        },
      };
    }
  
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_CHART): {
      return {
        ...state,
        learningChart: {
          ...state.learningChart,
          load: true,
        },
      };
    }
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_CHART): {
      const { data, meta } = action.payload;

      return {
        ...state,
        learningChart: {
          data,
          meta,
          load: false,
          errors: [],
        },
      };
    }
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_CHART): {
      const { errors } = action;
      return {
        ...state,
        learningChart: {
          ...state.learningChart,
          load: false,
          errors,
        },
      };
    }
      
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_USER_PROGRESS): {
      return {
        ...state,
        learningUsersProgress: {
          ...state.learningUsersProgress,
          load: true,
        },
      };
    }
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_USER_PROGRESS): {
      const { data, meta } = action.payload;
      return {
        ...state,
        learningUsersProgress: {
          data: data.users,
          meta,
          load: false,
          errors: [],
        },
      };
    }
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_USER_PROGRESS): {
      const { errors } = action;
      return {
        ...state,
        learningUsersProgress: {
          ...state.learningUsersProgress,
          load: false,
          errors,
        },
      };
    }

  default:
    return state;
  }
};


