// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_COMPANY, REQUEST } from '../../constant';
import moment from 'moment';

export function getCompanyStatisticCourses() {
  return {
    type: REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_COURSES),
  };
}

export function getCompanyStatisticStudents() {
  return {
    type: REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_STATISTIC_STUDENTS),
  };
}

export function getCompanyCurrentWeek() {
  return {
    type: REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_CURRENT_WEEK),
  };
}

export function getCompanyWeekChart(params) {
  const newParams = {
    learningDateGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    learningDateLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
  };
  return {
    type: REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_WEEK_CHART),
    payload: newParams,
  };
}

export function getCompanyDayChart(params) {
  const newParams = {
    learningDateGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    learningDateLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
  };
  return {
    type: REQUEST(ADMIN_COMPANY.STATISTIC_ACTION.GET_DAY_CHART),
    payload: newParams,
  };
}
