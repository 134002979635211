// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Button } from 'antd';

export const ProfileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ProfileInfoContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const ProfileAvatar = styled.div`
  margin-right: 20px;
  min-width: 120px;
  height: 120px;

  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ButtonUnblock = styled(Button)`
  margin-top: 6px;
`
