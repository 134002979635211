// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class TagServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getTagList() {
    return this.restClient.get('/admin_manage/tags');
  }

  createTag(params) {
    return this.restClient.post('/admin_manage/tags', params);
  }

  updateTag(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/tags/${id}`, requestData);
  }

  deleteTag(params) {
    return this.restClient.delete(`/admin_manage/tags/${params.id}`);
  }
}
