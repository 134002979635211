// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Tag } from 'antd'
import { Text } from 'src/components/styles';

export const QuestionDetailContainer = styled.div`
  margin-top: 24px;
`;

export const QuestionDetailContent = styled.div`
  margin-right: 16px;
  width: calc(100% - 300px);
  min-width: 898px;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  overflow: hidden;

  @media (max-width: 428px) {
    width: 100%;
    min-width: unset;
    margin-right: 0;
  }
`;

export const QuestionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};

  @media (max-width: 428px) {
    flex-wrap: wrap;
    min-height: 50px;
    height: auto;
    padding: 16px;

    .icon-clock {
      width: 16px;
      height:18px
    }
  }
`;

export const QuestionNameMobile = styled(Text)`
  display: none;

  @media (max-width: 428px) {
    display: block ;
    flex-basis: 100%;
    font-weight: 700;
  }
`;

export const QuestionNumber = styled(Text)`
  display: none;

  @media (max-width: 428px) {
    display: block ;
    font-weight: 700;
  }
`;

export const QuestionContent = styled.div`
  padding: 16px;
  background-color: white;

  & p {
    word-break: break-word;

    @media (max-width: 428px) {
      font-size: 16px;
      overflow-x: auto;
      overflow-y: hidden;

      &::-webkit-scrollbar {
        height: 5px;
      }
    }
  }

  & img{
    max-width: 100%;
  }
`;

export const QuestionStatus = styled(Tag)`
  display: flex;
  justify-content: center;
  width: 70px;

  @media (max-width: 428px) {
    font-size: 11px;
    font-weight: 700;
  }
`;

export const AnswerContainer = styled.div`
  margin-top: 16px;
  width: calc(100% - 300px);
  min-width: 898px;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;

  @media (max-width: 428px) {
    width: 100%;
    min-width: unset;
    margin-right: 0;
    margin-top: 0;
  }
`;

export const AnswerTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};

  @media (max-width: 428px) {
    font-weight: 700;
  }
`;

export const AnswerContent = styled.div`
  padding: 16px;

  @media (max-width: 428px) {
    padding-bottom: 34px;

    .answer-radio-button {
      height: auto;
      width: 100% !important;
      font-size: 16px;
      font-weight: 300;
    }
  }
`;

export const AnswerAction = styled.div`
  display: flex;
  justify-content: ${(props) => props.isFirstQuestion ? 'flex-end' : 'space-between'};
  margin-top: 16px;

  & > button {
    width: 100px;
  }

  @media (max-width: 428px) {
    .btn-previous {
      font-weight: 700;
      color: ${(props) => props.theme.colors.primary};
      background-color: #fff;
    }

    .btn-next {
      font-weight: 700;
      color: ${(props) => props.isLastQuestion ? '#fff' : props.theme.colors.primary};
      background-color: ${(props) => props.isLastQuestion ? props.theme.colors.primary : '#fff'};
    }
  }
`;

export const ConfirmAnswerAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  @media (max-width: 428px) {
    flex-direction: column;
  }

  & > button {
    width: 100px;

    @media (max-width: 428px) {
      width: 260px;
      margin: 16px 0;
  }
  }
`;

export const QuestionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const QuestionImageContent = styled.img`
  max-width: 100%;
  max-height: 500px;
`;

export const FieldInput = styled.input`
  display: none !important;
`;

export const FileInputField = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

export const UploadField = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  border: 1px dashed ${(props) => props.isDrag ? '#2196f3' : 'gray'};
  justify-content: space-between;

  @media (max-width: 428px) {
    min-height: 180px;
    height: auto;
    justify-content: center;
  }
`;

export const GroupAction = styled.div`
  display: flex;

  .btn-select-file-mobile {
    display: none;
  }

  @media (max-width: 428px) {
    flex-direction: column;

    & > button {
      font-weight: 700;
    }

    .btn-upload {
      display: none;
    }

    .btn-select-file-desktop {
      display: none;
    }

    .btn-select-file-mobile {
      display: block;
  }
  }
`;

export const CourseName = styled(Text)`
  @media (max-width: 428px) {
    padding: 26px 16px 0 16px;
    font-size: 14px;
    font-weight: 700;
  }
`;

export const UnitName = styled(Text)`
  display: none;

  @media (max-width: 428px) {
    display: block;
    padding: 0 16px;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const QuestionName = styled(Text)`
  display: block;

  @media (max-width: 428px) {
    display: none;
    font-weight: 700;
  }
`;

export const LimitTime = styled(Text)`
  @media (max-width: 428px) {
    font-size: 12px;
    font-weight: 300;
  }
`;

export const Time = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 300;
  }
`;

export const FileName = styled(Text)`
  @media (max-width: 428px) {
    width: auto !important;
    font-size: 12px;
    font-weight: 300;
    white-space: pre-wrap;
    overflow: unset;
  }
`;

export const QuestionIndex = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`;

export const AnswerNumber = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`;

export const BtnUploadMobile = styled.div`
  display: none;

  @media (max-width: 428px) {
    display: flex;
    justify-content: center;
    margin-top: 16px;

    & > button {
      width: 260px;
      font-weight: 700;
    }
  }
`;

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const PlacehoderUploadMobile = styled(Text)`
  display: none;

  @media (max-width: 428px) {
    display: block;
    margin-top: 5px;
    font-size: 11px;
    font-weight: 300;
    color: #999999;
    text-align: center;
  }
`;
