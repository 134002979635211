// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { COMMON_ACTION, REQUEST } from '../constant';

export function setCollapseData(params) {
  return {
    type: REQUEST(COMMON_ACTION.SET_COLLAPSE_DATA),
    payload: params,
  };
}

export function selectQuestion(params) {
  return {
    type: REQUEST(COMMON_ACTION.SELECT_QUESTION),
    payload: params,
  };
}
