// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import { getSearchParams, getFilterOption } from 'src/utils/common';

import {
  MainContainer,
  MainContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles';
import { PASSWORD_REGEX, PHONE_NUMBER_REGEX, POSTAL_CODE_REGEX } from 'src/constants/regexValidate';
import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import {
  getCareerList as getCareerListAction,
  getProvinceList as getProvinceListAction,
  confirmAdminCompany as confirmAdminCompanyAction,
  getCompanyConfirmationStatus as getCompanyConfirmationStatusAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IConfirmAdminCompanyPageProps extends StateProps, DispatchProps {
  getCareerList(): void;
  getProvinceList(): void;
  confirmAdminCompany(params): void;
  getCompanyConfirmationStatus(params): void;
  careerList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  provinceList: {
    data: {
      id: number;
      name: string;
    }[];
  };
}

const ConfirmAdminCompanyPage: React.FC<IConfirmAdminCompanyPageProps> = ({
  getCareerList,
  getProvinceList,
  confirmAdminCompany,
  getCompanyConfirmationStatus,
  careerList,
  provinceList,
}) => {
  const [companyConfirmStep, setCompanyConfirmStep] = useState<string>('company');
  const [companyConfirmValues, setCompanyConfirmValues] = useState<object>({});
  const [confirmAdminCompanyForm] = Form.useForm();

  const companyId = getSearchParams('company_id');
  const companyName = getSearchParams('company_name');
  const email = getSearchParams('email');
  const confirmationToken = getSearchParams('confirmation_token');

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.ConfirmCompany);

    getCareerList();
    getProvinceList();
    getCompanyConfirmationStatus({
      email,
      confirmationToken,
    });
  }, []);

  function handleSubmitCompanyForm(values) {
    const newValues = {
      postalCode: values.postalCode?.trim(),
      departmentInCharge: values.departmentInCharge.trim(),
      provinceId: values?.provinceId,
      addressCity: values.addressCity?.trim(),
      addressBuilding: values.addressBuilding?.trim(),
      phoneNumber: values.phoneNumber?.trim(),
      ...(values.careerId && { careerId: values.careerId }),
      ...(values.fax && { fax: values.fax.trim() }),
    };
    setCompanyConfirmValues(newValues);
    setCompanyConfirmStep('user');
  }

  function handleSubmitConfirmForm(values) {
    const requestData = {
      user: {
        email,
        confirmationToken,
        role: 'admin_company',
        jituName: values.jituName.trim(),
        password: values.password,
      },
      company: companyConfirmValues,
    };
    confirmAdminCompany(requestData);
  }

  function renderCareerOptions() {
    if (isEmpty(careerList.data)) return null;
    return careerList.data.map((career) => (
      <Select.Option key={`career-${career.id}`} value={career.id}>{career.name}</Select.Option>
    ));
  }

  function renderProvinceOptions() {
    if (isEmpty(provinceList.data)) return null;
    return provinceList.data.map((province) => (
      <Select.Option key={`province-${province.id}`} value={province.id}>{province.name}</Select.Option>
    ));
  }

  function renderCompanyForm() {
    return (
      <Form
        form={confirmAdminCompanyForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="companyConfirmForm"
        onFinish={(values) => handleSubmitCompanyForm(values)}
        scrollToFirstError={true}
      >
        <Style.FormRow>
          <Style.FormCol span={6}>
            <Text>企業ID</Text>
          </Style.FormCol>
          <Style.FormCol span={18}>
            <Input value={companyId ? companyId : ''} disabled />
          </Style.FormCol>
        </Style.FormRow>
        <Style.FormRow>
          <Style.FormCol span={6}>
            <Text>法人名</Text>
          </Style.FormCol>
          <Style.FormCol span={18}>
            <Input value={companyName ? companyName : ''} disabled />
          </Style.FormCol>
        </Style.FormRow>
        <Form.Item
          label="担当部署"
          name="departmentInCharge"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, whitespace: true, message: '担当部署は必須項目です。' },
            { max: 50, message: '担当部署が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="郵便番号"
          name="postalCode"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 10, message: '郵便番号が長すぎます。（最大は10桁です）' },
            {
              pattern: new RegExp(POSTAL_CODE_REGEX),
              message: '郵便番号のフォーマットが正しくありません。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="都道府県"
          name="provinceId"
          labelAlign="left"
          colon={false}
        >
          <Select
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            optionFilterProp="children"
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            filterOption={getFilterOption}
            style={{ width: 200 }}
          >
            {renderProvinceOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label="市町村・番地"
          name="addressCity"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 50, message: '市町村・番地が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="建物名・部屋番号等"
          name="addressBuilding"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 50, message: '建物名・部屋番号等が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="電話番号"
          name="phoneNumber"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 20, message: '電話番号が長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PHONE_NUMBER_REGEX),
              message: '電話番号の形式が正しくありません。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ファックス番号"
          name="fax"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 20, message: 'ファックス番号が長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PHONE_NUMBER_REGEX),
              message: 'ファックス番号の形式が正しくありません。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="業種"
          name="careerId"
          labelAlign="left"
          colon={false}
        >
          <Select
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            optionFilterProp="children"
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            filterOption={getFilterOption}
            style={{ width: 200 }}
          >
            {renderCareerOptions()}
          </Select>
        </Form.Item>
        <Style.GroupAction>
          <Button
            type="primary"
            htmlType="submit"
          >
            次へ
          </Button>
        </Style.GroupAction>
      </Form>
    );
  }

  function renderUserForm() {
    return (
      <Form
        form={confirmAdminCompanyForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="userConfirmForm"
        onFinish={(values) => handleSubmitConfirmForm(values)}
      >
        <Style.FormRow>
          <Style.FormCol span={6}>
            <Text>登録アドレス</Text>
          </Style.FormCol>
          <Style.FormCol span={18}>
            <Input value={email ? email : ''} disabled />
          </Style.FormCol>
        </Style.FormRow>
        <Form.Item
          label="名前"
          name="jituName"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, whitespace: true, message: '名前は必須項目です。' },
            { max: 50, message: '名前が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="パスワード"
          name="password"
          labelAlign="left"
          colon={false}
          validateFirst
          rules={[
            { required: true, message: 'パスワードは必須項目です。' },
            { min: 8, message: 'パスワードは8文字以上で設定して下さい。' },
            { max: 20, message: 'パスワードが長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PASSWORD_REGEX),
              message: 'パスワードは大文字、小文字、数字、記号から3つ以上の組み合わせで入力してください。'
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="パスワード確認用"
          name="confirmPassword"
          dependencies={['password']}
          labelAlign="left"
          colon={false}
          validateFirst
          rules={[
            { required: true, message: 'パスワード確認用は必須項目です。' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('新しいパスワードと確認用のパスワードが一致しません。ご確認ください。');
              },
            }),
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Row>
          <Col span={6} />
          <Col span={18}>
            <Form.Item
              name="agreeTerm"
              wrapperCol={{ span: 24 }}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => value
                    ? Promise.resolve()
                    : Promise.reject('ご利用には利用規約とプライバシーポリシーへ同意が必要です。'),
                },
              ]}
            >
              <Style.GroupCheckbox>
                <Checkbox />
                <Style.GroupCheckbox style={{ marginLeft: 6 }}>
                  アカウントを作成すると、
                  <Style.Link
                    href="https://skillup-next.co.jp/lms-scai-terms-of-use/"
                    target="_blank"
                  >
                    利用規約
                  </Style.Link>
                  、および
                  <Style.Link
                    href="https://skillup-next.co.jp/privacy/"
                    target="_blank"
                  >
                    プライバシーポリシー
                  </Style.Link>
                  に同意したことになります。
                </Style.GroupCheckbox>
              </Style.GroupCheckbox>
            </Form.Item>
          </Col>
        </Row>
        <Style.GroupAction>
          <Button
            type="primary"
            htmlType="submit"
          >
            登録する
          </Button>
        </Style.GroupAction>
      </Form>
    );
  }

  return (
    <MainContainer>
      <MainContent formView>
        <MainWrapper title="新規登録" style={{ marginTop: 24 }}>
          <Style.CompanyConfirmContainer>
            {companyConfirmStep === 'company' ? renderCompanyForm() : renderUserForm()}
          </Style.CompanyConfirmContainer>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { careerList, provinceList } = state.masterDataReducer;
  return {
    careerList,
    provinceList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCareerList: () => dispatch(getCareerListAction()),
  getProvinceList: () => dispatch(getProvinceListAction()),
  confirmAdminCompany: (params) => dispatch(confirmAdminCompanyAction(params)),
  getCompanyConfirmationStatus: (params) => dispatch(getCompanyConfirmationStatusAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAdminCompanyPage);
