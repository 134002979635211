// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from "styled-components";

export const ConfirmResetModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 120px;
`;
export const ConfirmResetFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px 0 24px 0;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-content: space-around;
    padding: 24px 48px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & button span {
      padding-top: 5px;
    }
  }
`;
