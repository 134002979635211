// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Table, Progress, Space } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { showAlertNotice } from 'src/utils/alert';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { convertSecondTime } from 'src/utils/dateTime';
import { DATE_FORMAT, COURSE_STATUS } from 'src/constants/common';

import * as Style from './styles';

interface ICourseListProps {
  getStudentCourseDetail(params): void;
  statisticCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      };
      totalLearningDays: number;
      totalLearningTime: number;
      studentProgressPercentage: number;
      testInfo: {
        usersRank: number;
        totalUsers: number;
        highestTestScore: number;
      };
      remainingDays: number;
      startDate: string;
      endDate: string;
      actualStatus: string;
    }[];
    load: boolean;
  };
}

const CourseList: React.FC<ICourseListProps> = ({
  getStudentCourseDetail,
  statisticCourses,
}) => {
  const tableData = statisticCourses.data.map((courseItem) => ({
    key: courseItem.id,
    id: courseItem.id,
    name: courseItem.courseCategory?.name,
    totalLearningDays: `${courseItem.totalLearningDays}日`,
    totalLearningTime: convertSecondTime(courseItem.totalLearningTime, 'H時間m分'),
    studentProgressPercentage: courseItem.studentProgressPercentage,
    testInfo: courseItem.testInfo,
    startDate: moment(courseItem.startDate).format(DATE_FORMAT),
    endDate: `${moment(courseItem.endDate).format(DATE_FORMAT)} [残り${courseItem.remainingDays}日]`,
    actualStatus: courseItem.actualStatus,
  }));

  const tableDataMobile = statisticCourses.data.map((courseItem) => ({
    key: courseItem.id,
    id: courseItem.id,
    name: courseItem.courseCategory?.name,
    studentProgressPercentage: courseItem.studentProgressPercentage,
    startDate: moment(courseItem.startDate).format(DATE_FORMAT),
    endDate: moment(courseItem.endDate).format(DATE_FORMAT),
    actualStatus: courseItem.actualStatus,
  }));

  const columns = [
    {
      title: 'コース',
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: "22.5%",
    },
    {
      title: 'ステータス',
      dataIndex: 'actualStatus',
      render: (_, record) => (
        <Text>{COURSE_STATUS[record.actualStatus]}</Text>
      ),
      width: "10%",
    },
    {
      title: '学習日数',
      dataIndex: 'totalLearningDays',
      width: "8.5%",
    },
    {
      title: '学習時間',
      dataIndex: 'totalLearningTime',
      width: "8.5%",
    },
    {
      title: '進歩率',
      dataIndex: 'studentProgressPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.studentProgressPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.studentProgressPercentage} />
        </Space>
      ),
      width: "10%",
    },
    {
      title: (
        <>
          <Text white style={{ marginLeft: '2px' }}>テスト正答率</Text>
          <Text white style={{ marginLeft: '-10px' }}>（全テスト平均）</Text>
        </>
      ),
      dataIndex: 'testInfo' as any,
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text>
            {(isEmpty(record.testInfo) || record.testInfo.highestTestScore === null) ? 'ー'
              : `${record.testInfo.highestTestScore}%[${record.testInfo.usersRank}位/${record.testInfo.totalUsers}人]`
            }
          </Text>
        </Space>
      ),
      width: "16%",
    },
    {
      title: '開始日',
      dataIndex: 'startDate',
      width: "8.5%",
    },
    {
      title: '終了日',
      dataIndex: 'endDate',
      width: "16%",
    },
  ];

  const columnsForMobile = [
    {
      title: () => <Text w6 white>コース</Text>,
      dataIndex: 'name',
      render: (_, record) => (
        <Text w6>
          <TooltipParagraph rows={2}> {record.name} </TooltipParagraph>
        </Text>
      ),
      width: "50%",
    },
    {
      title: () => <Text w6 white>進歩率</Text>,
      dataIndex: 'studentProgressPercentage',
      render: (_, record) => (
        <Progress
          type="circle"
          percent={record.studentProgressPercentage}
          width={50}
          strokeColor="#D29C09"
          format={() => `${record.studentProgressPercentage}%`}
        />
      ),
      width: "20%",
    },
    {
      title: () => <Text w6 white>開始日</Text>,
      dataIndex: 'startDate',
      width: "30%",
    },
  ];

  return (
    <>
      <Style.TextHeader xxxl style={{ margin: '40px 0 16px' }}>受講コース</Style.TextHeader>
      <Style.CoursesTableContainer>
        <Table
          className="table-header-student table-student-top-desktop table-cursor-pointer"
          pagination={false}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {
              if (record.actualStatus === 'coming') {
              showAlertNotice({ type: 'success', message: `このコースは${record.startDate}から受講可能です。` });
            } else {
              getStudentCourseDetail({
                id: record.id,
                redirect: `/course/${record.key}`,
              });
            }}
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={statisticCourses.load}
        />
        <Table
          className="table-header-student table-student-top-mobile table-cursor-pointer"
          pagination={false}
          columns={columnsForMobile}
          dataSource={tableDataMobile}
          onRow={(record) => ({
            onClick: () => {
              if (record.actualStatus === 'coming') {
              showAlertNotice({ type: 'success', message: `このコースは${record.startDate}から受講可能です。` });
            } else {
              getStudentCourseDetail({
                id: record.id,
                redirect: `/course/${record.key}`,
              });
            }}
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={statisticCourses.load}
        />
      </Style.CoursesTableContainer>
    </>
  );
};

export default CourseList;
