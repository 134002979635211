// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { Tag } from 'antd';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
`;

export const DistanceMiddle = styled.div`
  display: flex;
  width: 22px;
  height: 40px;
  justify-content: center;
  align-items: center;
`;

export const DatePickerWrapper = styled.div`
  display: flex;
`;

export const InputNumberWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const FilterSubmitButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TagSelected = styled(Tag)`
  display: flex;
  align-items: center;
  padding: 0 12px 0 16px;
  height: 24px;
  border-radius: ${(props) => props.theme.radius.circle};

  .ant-typography {
    color: white;
  }
`;
