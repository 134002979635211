//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Radio,
  Button,
  Input,
  List,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { maxBy } from 'lodash/math';
import MathJax from 'react-mathjax-preview';
import { useDropzone } from 'react-dropzone';

import history from 'src/utils/history';
import { showAlertNotice } from 'src/utils/alert';
import {
  ALLOWED_TYPE,
  FILE_SIZE,
} from 'src/constants/common';

import { MainContainer, SidebarContent, MainContent } from 'src/components/layouts/ContentLayout';
import { TestingSidebar } from 'src/components/layouts/Sidebar';

import TestResultModal from 'src/components/Modal/components/TestResultModal';

import { TEST_TYPE_URL } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getCourseDetail as getCourseDetailAction,
  selectQuestion as selectQuestionAction,
  showModal as showModalAction,
  closeModal as closeModalAction,

} from 'src/redux/actions';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import dateFileIcon from 'src/assets/images/common/date-file-icon.svg';
import { Text } from 'src/components/styles';

import * as Style from './styles';

interface ITestingFormPageProps extends DispatchProps {
  getCourseDetail(params): void;
  selectQuestion(params): void;
  submitTestAnswers(params): void;
  showModal(params): void;
  closeModal(): void;
  role: string;
  questionIdSelected: number | string;
  courseDetail: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      }
    },
  };
  testQuestions: {
    data: {
      unitVersionId: number;
      test: {
        displayResults: string;
        explanationFile: {
          id: number;
          explanationFile: object;
        };
      };
      questions: {
        id: number;
        name: string;
        isScored: boolean;
        image: {
          url: string;
        };
        content: string;
        questionType: string;
        questionOptions: {
          id: number;
          content: string;
          isRight: boolean;
        }[];
        rightInput: string;
        score: number;
      }[];
    };
  };
  overviewUnitDetail: {
    data: {
      id: number;
      item: {
        limitTime: number;
        name: string;
        description: number;
        questionsCount: number;
        testType: string;
        type: string;
      };
    };
  };
}

const TestingFormPage: React.FC<ITestingFormPageProps> = ({
  courseDetail,
  testQuestions,
  questionIdSelected,
  overviewUnitDetail,
  getCourseDetail,
  selectQuestion,
  showModal,
}) => {
  const [isShowSidebar, setIsShowSidebar] = useState<boolean>(true);
  const [countdownTime, setCountdownTime] = useState<any>(Date.now());
  const [answerForm, setAnswerForm] = useState<{
    questionId: number;
    questionName: string;
    answer: number | string | undefined;
    rightAnswer: number | string | undefined;
    score: number;
    questionType: string;
    isScored: boolean;
  }[]>([]);
  const [fileName, setFileName] = useState<number | string | undefined>('');
  const [fileError, setFileError] = useState<string>('');

  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[3];
  const unitVersionId = pathnameSplit[5];
  const questionData = testQuestions.data.questions;
  const testType = overviewUnitDetail.data.item.testType;
  const questionIndex = questionIdSelected !== 'confirm'
    ? findIndex(questionData, { id: questionIdSelected })
    : -2;

  const onDrop = (file) => {
    setFileName(file[0].name);
    const fileType = file[0].name.slice(file[0].name.lastIndexOf('.') + 1);
    // setFileImage(file);
    if (!ALLOWED_TYPE.includes(fileType)) {
      setFileError('ファイルの形式が正しくありません。選択可能ファイルはpdf、word、powerpoint、ipynb、pyです。');
    } else if (file[0].size > FILE_SIZE) {
      setFileError('ファイルサイズが大きすぎます。(最大サイズ：30MB）');
    } else {
      setFileError('');
    }
    const reader = new FileReader();

    reader.readAsDataURL(file[0]);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.TestingForm);

    getCourseDetail({ id: courseId });
  }, []);

  useEffect(() => {
    if (overviewUnitDetail.data.id && overviewUnitDetail.data.item.limitTime > 0) {
      setCountdownTime(Date.now() + (overviewUnitDetail.data.item.limitTime * 1000));
    }
  }, [overviewUnitDetail.data.id]);

  useEffect(() => {
    if (questionIdSelected !== 'confirm') {
      answerForm.forEach((file) => {
        if (file.questionId === questionIdSelected) setFileName(file.answer);
      });
    }
    setFileError('');
  }, [questionIdSelected]);

  useEffect(() => {
    if (isEmpty(testQuestions.data?.questions)) {
      if (pathnameSplit[4] === 'final-testing') {
        history.push(`/admin-service/view-course/${courseId}/final-test/${unitVersionId}`);
      } else if (pathnameSplit[4] === 'pre-testing') {
        history.push(`/admin-service/view-course/${courseId}/pre-test/${unitVersionId}`);
      } else {
        history.push(`/admin-service/view-course/${courseId}/confirm-test/${unitVersionId}`);
      }
    } else {
      const newAnswerForm = questionData.map((question) => {
        const newRightAnswer = question.questionType === 'option_type'
          ? (question.questionOptions.findIndex((option) => option.isRight)) + 1
          : question.rightInput;
        return {
          questionId: question.id,
          questionName: question.name,
          answer: undefined,
          rightAnswer: newRightAnswer,
          score: question.score,
          questionType: question.questionType,
          isScored: question.isScored,
        };
      });
      setAnswerForm([...newAnswerForm]);
    }
  }, [testQuestions.data?.questions]);

  function handleChangeAnswer(e, id, isUpload?: boolean) {
    const newAnswerForm = answerForm;
    const answerFormIndex = findIndex(answerForm, { questionId: id });
    newAnswerForm.splice(answerFormIndex, 1, {
      ...answerForm[answerFormIndex],
      answer: isUpload ? e : e.target.value,
    });
    setAnswerForm([...newAnswerForm]);
  }

  function handleMoveQuestion(isNext) {
    if (!isNext && questionIndex !== 0) {
      selectQuestion(questionData[questionIndex - 1].id);
    } else if (questionIndex + 1 < questionData.length) {
      selectQuestion(questionData[questionIndex + 1].id);
    } else {
      selectQuestion('confirm');
    }
  }

  function handleConfirmSubmitAnswer() {
    const modalProps = {
      isConfirm: true,
      modalData: {
        message: 'このテストを提出しますか？',
        title: 'テスト提出確認',
      },
      modalAction: {
        confirm: () => submitTestAnswers(),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function submitTestAnswers() {
    let totalScore = 0;
    let answerScore = 0;
    const newTestResults = answerForm.map((item, itemIndex) => {
      const newAnswer = typeof item.answer === 'string' ? item.answer.trim() : item.answer;
      const questionScore = item.rightAnswer === newAnswer ? (item.score || 0) : 0;
      totalScore = totalScore + (item.score || 0);
      answerScore = answerScore + questionScore;
      return {
        id: itemIndex,
        question: {
          id: item.questionId,
          score: item.score,
          name: item.questionName,
          rightOptionIndex: item.questionType === 'option_type' ? item.rightAnswer : null,
          rightInput: item.questionType === 'input_type' ? item.rightAnswer : null,
          questionType: item.questionType,
          isScored: item.isScored,
        },
        answerOptionIndex: item.questionType === 'option_type' ? newAnswer : null,
        answerInput: ['input_type', 'upload_file_type'].includes(item.questionType) ? newAnswer : null,
        isRight: item.rightAnswer === newAnswer,
      };
    });
    const modalProps = {
      isStudent: true,
      toggleClickOutside: true,
      width: testQuestions.data.test?.displayResults !== "hidden" ? 1100 : 700,
      modalData: {
        title: 'テスト結果',
        isReview: true,
        testScore: {
          score: answerScore,
          isPass: answerScore === totalScore,
          testScorePercentage: Math.floor(answerScore / totalScore * 100),
        },
        ...testQuestions.data.test?.explanationFile && {
          testExplanationFile: testQuestions.data.test,
        },
        ...testQuestions.data.test?.displayResults !== "hidden" && { testResults: newTestResults },
        displayResults: testQuestions.data.test?.displayResults
      },
    };
    localStorage.setItem("modalPropsTest", JSON.stringify(modalProps))
    return history.push(
      `/admin-service/view-course/${courseId}/${TEST_TYPE_URL[testType]}/${unitVersionId}`,
    );
  }

  function renderQuestionContent() {
    if (questionIdSelected !== 'confirm') {
      return (
        <>
          {questionData[questionIndex].image && (
            <Style.QuestionImageContainer>
              <Style.QuestionImageContent src={questionData[questionIndex].image.url} alt="" />
            </Style.QuestionImageContainer>
          )}
          {questionData[questionIndex].content && (
            <Style.QuestionContent>
              <MathJax math={questionData[questionIndex].content} />
            </Style.QuestionContent>
          )}
        </>
      );
    } else {
      return (
        <Style.QuestionContent>
          <List
            bordered
            dataSource={questionData}
            renderItem={(questionItem, questionItemIndex) => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => selectQuestion(questionItem.id)}
              >
                <Space align="center" size="middle">
                  {!!answerForm[questionItemIndex]?.answer
                    ? <Style.QuestionStatus color="green">解答済み</Style.QuestionStatus>
                    : <Style.QuestionStatus color="gold">未解答</Style.QuestionStatus>
                  }
                  <Text headerText w6 style={{ width: 60 }}>
                    {`${questionItemIndex + 1}問目`}
                  </Text>
                  <Text>{questionItem.name}</Text>
                </Space>
              </List.Item>
            )}
          />
          <Style.ConfirmAnswerAction>
            <Space align="center" size="middle">
              <Text xs subText w6>解答済み：</Text>
              <Text headerText w6>
                {`${answerForm?.filter((studentAnswer) => !!studentAnswer.answer).length}/${questionData?.length}問`}
              </Text>
            </Space>
            <Button type="primary" onClick={() => handleConfirmSubmitAnswer()}>提出する</Button>
          </Style.ConfirmAnswerAction>
        </Style.QuestionContent>
      );
    }
  }

  function renderOptionRadio(questionOptions) {
    const maxLengthAnswer = maxBy(questionOptions, (answer) => answer.content.length);
    let answerWidth = '';
    const isMediumContent = maxLengthAnswer.content.length > 10 && maxLengthAnswer.content.length <= 30;
    const isLongContent = maxLengthAnswer.content.length > 30;
    if (questionOptions.length === 2 && !isLongContent) {
      answerWidth = 'calc(50% - 16px)';
    } else if ([3, 5, 6].includes(questionOptions.length) && !isLongContent) {
      answerWidth = 'calc(100% / 3 - 16px)';
    } else if (maxLengthAnswer.content.length <= 10) {
      answerWidth = 'calc(25% - 16px)';
    } else if (isMediumContent) {
      answerWidth = 'calc(50% - 16px)';
    } else {
      answerWidth = '100%';
    }
    return questionOptions.map((option, optionIndex) => (
      <Radio.Button
        key={`option-${option.id}-${optionIndex}`}
        className="answer-radio-button"
        value={optionIndex + 1}
        style={{ width: answerWidth }}
      >
        <Style.AnswerOption isRight={option.isRight}>
          {`${optionIndex + 1}. ${option.content}`}
          {option.isRight && <CheckOutlined style={{ marginLeft: 16, color: '#5FC73A', fontSize: 20 }} />}
        </Style.AnswerOption>
      </Radio.Button>
    ));
  }

  function renderAnswerContent() {
    const answerIndex = findIndex(answerForm, { questionId: questionData[questionIndex].id });
    switch (questionData[questionIndex].questionType) {
      case 'option_type':
        return (
          <Radio.Group
            className="answer-radio-group"
            buttonStyle="solid"
            {...answerIndex !== -1 && { value: answerForm[answerIndex].answer }}
            onChange={(e) => handleChangeAnswer(e, questionData[questionIndex].id)}
          >
            {renderOptionRadio(questionData[questionIndex].questionOptions)}
          </Radio.Group>
        );
      case 'input_type':
        return (
          <Input.TextArea
            placeholder={
              questionData[questionIndex].rightInput
                ? questionData[questionIndex].rightInput
                : '解答を入力してください'
            }
            value={answerIndex !== -1 ? answerForm[answerIndex].answer : ''}
            onChange={(e) => handleChangeAnswer(e, questionData[questionIndex].id)}
            style={{ height: 200 }}
          />
        );
      case 'upload_file_type':
        return renderDragFileArea();
      default:
        return null;
    }
  }

  function renderDragFileArea() {
    return (
      <Style.UploadField
        key={questionData[questionIndex].id}
        {...getRootProps()}
        isDrag={isDragActive}
      >
        <input {...getInputProps()} />
        {!fileName
          ? (
            <>
              <Text lg w6 subText>ここにファイルをドラッグ</Text>
              <Text margin="0 0 10px" lg w6 subText>または</Text>
            </>
          ) : (
            <Text
              truncate
              center
              lg
              w6
              style={{ width: 400, lineHeight: `${fileError ? '24px' : '54px'}` }}
            >
              {fileName}
            </Text>
          )
        }
        {fileError && (
          <Text error margin="0 0 10px">{fileError}</Text>
        )}
        <Style.GroupAction>
          <Button
            size="middle"
            type="primary"
            onClick={open}
            ghost
          >
            {!!fileName ? 'ファイルを再選択' : 'パソコンからファイルを選択'}
          </Button>
          <Button
            size="middle"
            type="primary"
            onClick={() => {
              handleChangeAnswer(acceptedFiles[0].name, questionData[questionIndex].id, true);
              showAlertNotice({ type: 'success', message: 'アップロードが成功しました。' });
            }}
            style={{ marginLeft: 10 }}
            disabled={!!fileError || !fileName}
          >
            ファイルを提出
          </Button>
        </Style.GroupAction>
      </Style.UploadField>
    );
  }

  function renderAnswerTitle() {
    switch (questionData[questionIndex].questionType) {
      case 'option_type':
        return '解答を選択';
      case 'input_type':
        return '解答を入力';
      case 'upload_file_type':
        return '解答ファイルをアップロード';
      default:
        return null;
    }
  }

  if (questionIndex === -1) return null;
  return (
    <MainContainer isStudent>
      <SidebarContent isCollapse={!isShowSidebar}>
        <TestingSidebar
          isShowSidebar={isShowSidebar}
          setIsShowSidebar={setIsShowSidebar}
          courseTopDetail={courseDetail}
          testQuestions={testQuestions}
          questionIdSelected={questionIdSelected}
          selectQuestion={selectQuestion}
        />
      </SidebarContent>
      <MainContent sidebarView={!isShowSidebar}>
        <>
          <Text white w6 xxl>{courseDetail.data.courseCategory?.name}</Text>
          <Style.QuestionDetailContainer>
            <Style.QuestionDetailContent>
              <Style.QuestionTitle>
                <Text truncate white lg w6 style={{ width: 'calc(100% - 250px)' }}>
                  {questionIdSelected !== 'confirm'
                    ? `${questionIndex + 1}問目 ${questionData[questionIndex].name}`
                    : '解答確認'
                  }
                </Text>
                <Space align="center" size="middle">
                  <Text white lg w5>テスト制限時間</Text>
                  <Space align="center" size={4}>
                    <img src={dateFileIcon} height="24" width="auto" alt="" />
                    {overviewUnitDetail.data.item.limitTime > 0
                      ? (
                        <Countdown
                          date={countdownTime}
                          onTick={(props) => {
                            setCountdownTime(Date.now() + props.total);
                          }}
                          onComplete={() => submitTestAnswers()}
                          renderer={(props) => (
                            <Text white lg>
                              {`${props.formatted.hours}:${props.formatted.minutes}:${props.formatted.seconds}`}
                            </Text>
                          )}
                        />
                      )
                      : <Text white lg>無制限</Text>
                    }
                  </Space>
                </Space>
              </Style.QuestionTitle>
              {renderQuestionContent()}
            </Style.QuestionDetailContent>
            {questionIdSelected !== 'confirm' && (
              <Style.AnswerContainer>
                <Style.AnswerTitle>
                  <Text white lg w5>{renderAnswerTitle()}</Text>
                </Style.AnswerTitle>
                <Style.AnswerContent>
                  {renderAnswerContent()}
                  <Style.AnswerAction isFirstQuestion={questionIndex === 0}>
                    {questionIndex !== 0 && (
                      <Button
                        type="primary"
                        onClick={() => handleMoveQuestion(false)}
                      >
                        戻る
                      </Button>
                    )}
                    <Button type="primary" onClick={() => handleMoveQuestion(true)}>
                      {questionIndex === questionData.length - 1 ? '解答する' : '次へ'}
                    </Button>
                  </Style.AnswerAction>
                </Style.AnswerContent>
              </Style.AnswerContainer>
            )}
          </Style.QuestionDetailContainer>
        </>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { courseDetail } = state.courseReducer;
  const { questionIdSelected } = state.commonReducer;
  const { testQuestions } = state.testReducer;
  const { overviewUnitDetail } = state.unitReducer;
  return {
    courseDetail,
    testQuestions,
    questionIdSelected,
    overviewUnitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectQuestion: (params) => dispatch(selectQuestionAction(params)),
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  showModal: (params) => dispatch(showModalAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestingFormPage);
