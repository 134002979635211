// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Text } from 'src/components/styles';

export const CourseOverviewContainer = styled.div`
  display: none;

  @media (max-width: 428px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CourseInfo = styled.div`
  display: none;

  @media (max-width: 428px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

    .ant-progress-bg {
      background-color: ${(props) => props.theme.colors.primary} !important;
    }
  }
`;

export const TextLabel = styled(Text)`
  min-width: 130px;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
`;
