// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from '../../constant';

export function getServiceCurrentWeek() {
  return {
    type: REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_CURRENT_WEEK),
  };
}

export function getServiceStatisticCourses() {
  return {
    type: REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_COURSES),
  };
}

export function getServiceRecentCourses() {
  return {
    type: REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_RECENT_COURSES),
  };
}
