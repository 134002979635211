//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Space } from 'antd';
import { keys } from 'lodash/object';
import { findIndex } from 'lodash/array';

import { formatDateTime } from 'src/utils/dateTime';

import { Text } from 'src/components/styles';

import { DATE_FORMAT, NOTIFY_SETTING_TYPE } from 'src/constants/common';

import * as Style from './styles';
import { SkeletonDesktop, TextInfoUser, TextTitleInfo } from 'src/pages/Profile/styles';
import { randomLoadingWidth } from 'src/utils/common';

interface IOtherProfileProps {
  updateUserProfileInfo?(params): void;
  type: string;
  title: string;
  fieldData: {
    title: string;
    fieldName: string;
    type: string;
    option?: {
      title: string;
      value: string;
    }[];
  }[];
  profileDetail: {
    data: {
      id: number;
      company: {
        name: string;
      };
      department: string;
      jituName: string;
      kanaName: string;
      birthday: string;
      jobTitle: string;
      jobType: string;
      systemNotificationSetting: string;
      roles: string[];
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      customUserInfos: {
        id: number;
        companyCustomUserInfoId: number;
        fieldValue: string;
      }[];
      customDisplayField: {
        birthday: boolean;
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    },
    load: boolean
  };
}

const OtherProfile: React.FC<IOtherProfileProps> = ({
  updateUserProfileInfo,
  type,
  title,
  fieldData,
  profileDetail,
}) => {
  const [otherProfileForm] = Form.useForm();
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const { data } = profileDetail;
  const { roles } = data;
  const isAdminService = (roles || []).includes("admin_service");

  useEffect(() => {
    otherProfileForm.setFieldsValue(userProfileInitialValues)
  }, [data])

  useEffect(() => {
    if (data.id) {
      otherProfileForm.resetFields();
    }
  }, [data.id]);

  const customUserInfos = data.companyCustomUserInfos?.map((item) => {
    const infoIndex = findIndex(
      (data.customUserInfos || []),
      { companyCustomUserInfoId: item.id },
    );
    return {
      id: infoIndex !== -1 ? data.customUserInfos[infoIndex].id : '',
      fieldName: item.fieldName,
      companyCustomUserInfoId: item.id,
      fieldValue: infoIndex !== -1 ? data.customUserInfos[infoIndex].fieldValue : '',
    };
  });

  const userProfileInitialValues = {
    jituName: data.jituName,
    kanaName: data.kanaName,
    systemNotificationSetting: data.systemNotificationSetting,
  };

  function renderFieldValue(field) {
    switch (field.fieldName) {
    case 'companyName': {
      return data.company?.name;
    }
    case 'birthday': {
      return formatDateTime(data[field.fieldName], DATE_FORMAT);
    }
    case 'systemNotificationSetting': {
      return NOTIFY_SETTING_TYPE[data[field.fieldName]];
    }
    default:
      return data[field.fieldName];
    }
  }

  function renderFieldRule(field) {
    switch (field.fieldName) {
    case 'jituName': {
      return [
        { required: true, message: '名前は必須項目です。' },
        { whitespace: true, message: '名前は必須項目です。' },
        () => ({
          validator(_, value) {
            if ((value || '').trim().length > 50) {
              return Promise.reject('名前が長すぎます。（最大は50桁です）');
            }
            return Promise.resolve();
          },
        }),
      ];
    }
    case 'kanaName': {
      return [
        () => ({
          validator(_, value) {
            if ((value || '').trim().length > 50) {
              return Promise.reject(`${field.title}が長すぎます。（最大は50桁です）`);
            }
            return Promise.resolve();
          },
        }),
      ];
    }
    default:
      return [];
    }
  }

  function renderFieldType(field, fieldIndex) {
    if (field.type === 'select') {
      return (
        <Select style={{ width: 200 }}>
          {field.option?.map((option, optionIndex) => (
            <Select.Option key={optionIndex} value={option.value}>
              {option.title}
            </Select.Option>
          ))}
        </Select>
      );
    } else {
      return (
        <Input
          disabled={field.fieldName === "companyName"}
          onBlur={() => {
            const customValue =
              otherProfileForm.getFieldValue(field.fieldName);
            otherProfileForm.setFieldsValue({
              [field.fieldName]: customValue.trim(),
            });
          }}
        />
      )
    }
  }

  function handleSubmitForm(values) {
    const requestData = {
      user: {
        ...values,
        jituName: values.jituName.trim(),
        kanaName: (values.kanaName || '').trim()
      },
    };
    if (updateUserProfileInfo) updateUserProfileInfo({id: data.id, requestData });
    return setIsEditForm(false);
  }

  function renderProfileView() {
    if (type === 'user' && profileDetail.load) {
      return fieldData.map((field, fieldIndex) => (
        <Style.ProfileContent key={`loading-overview-profile-${fieldIndex}`}>
          <Space>
            <TextTitleInfo lg w6>
              {field.title}
            </TextTitleInfo>
            <>
              <SkeletonDesktop
                active
                paragraph={false}
                title={{ width: randomLoadingWidth(250, 350) }}
                height={16}
              />
            </>
          </Space>
        </Style.ProfileContent>
      ));
    }
    return (
      <>
        {fieldData.map((field, fieldIndex) => {
          if (keys(data.customDisplayField).indexOf(field.fieldName) !== -1
            && !data.customDisplayField?.[field.fieldName]) {
            return null;
          }
          return (
            <Style.ProfileContent key={`overview-profile-${fieldIndex}`}>
              <Space>
                <Text lg w6 style={{ minWidth: 150 }}>
                  {field.title}
                </Text>
                <Text lg>
                  {renderFieldValue(field)}
                </Text>
              </Space>
            </Style.ProfileContent>
          );
        })}
        {type === 'company' && customUserInfos?.map((field, fieldIndex) => {
          const { isDisplay } = data.companyCustomUserInfos[fieldIndex];
          if (!isDisplay) return null;
          return (
            <Style.ProfileContent key={`custom-profile-${fieldIndex}`}>
              <Space>
                <Text lg w6 style={{ minWidth: 150 }}>{field.fieldName}</Text>
                <Text lg>{field.fieldValue}</Text>
              </Space>
            </Style.ProfileContent>
          );
        })}
      </>
    );
  }
  

  function renderProfileEdit() {
    return (
      <Style.EditProfileContent>
        {fieldData.map((field, fieldIndex) => {
          if (keys(data.customDisplayField).indexOf(field.fieldName) !== -1
            && !data.customDisplayField?.[field.fieldName]) {
            return null;
          }
          return (
            <Form.Item
              key={`overview-profile-${fieldIndex}`}
              label={
                <Text lg w6>
                  {field.title}
                </Text>
              }
              name={field.fieldName}
              labelAlign="left"
              colon={false}
              rules={renderFieldRule(field)}
            >
              {renderFieldType(field, fieldIndex)}
            </Form.Item>
          );
        })}
      </Style.EditProfileContent>
    );
  }

  return (
    <>
      {type === 'user' ?
        (
          <Form
            form={otherProfileForm}
            name='userProfileForm'
            initialValues={userProfileInitialValues}
            onFinish={(values) => handleSubmitForm(values)}
          >
            {
              !isAdminService && (
                <Style.ModifyProfile>
                  {isEditForm
                    ? (
                      <Space align="center" size="middle">
                        <Style.ButtonCancel
                          type="primary"
                          htmlType="button"
                          className="btn-secondary"
                          onClick={() => {
                            setIsEditForm(false);
                            otherProfileForm.resetFields();
                          }}
                        >
                          キャンセル
                        </Style.ButtonCancel>
                        <Button type="primary" htmlType="submit">保存する</Button>
                      </Space>
                    )
                    : <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => {
                        setIsEditForm(true);
                      }}
                    >
                      修正する
                    </Button>
                  }
                </Style.ModifyProfile>  
              )
            }
            <Style.ProfileWrapper marginTop={isAdminService && "40px"}>
              <Style.HeaderWrapper>
                <TextInfoUser white lg w6>{title}</TextInfoUser>
              </Style.HeaderWrapper>
              {isEditForm ? renderProfileEdit() : renderProfileView()}
            </Style.ProfileWrapper>
          </Form>) :
        (
          <Style.ProfileWrapper marginTop="40px">
            <Style.HeaderWrapper>
              <Text white lg w6>{title}</Text>
            </Style.HeaderWrapper>
            {renderProfileView()}
          </Style.ProfileWrapper>
        )
      }
    </>
  );
};

export default OtherProfile;
