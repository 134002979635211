// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from './restClient';
import AuthServices from './auth.service';

import TestServices from './adminService/test.service';
import VideoServices from './adminService/video.service';
import UnitServices from './adminService/unit.service';
import CourseServices from './adminService/course.service';
import CompanyServices from './adminService/company.service';
import TeacherServices from './adminService/teacher.service';
import PlanServices from './adminService/plan.service';
import TagServices from './adminService/tag.service';
import SurveyServices from './adminService/survey.service';
import CategoryServices from './adminService/category.service';
import TipServices from './adminService/tip.service';
import QuestionServices from './adminService/question.service';
import AccountServices from './adminService/account.service';
import ServiceStatisticServices from './adminService/statistic.service';
import FAQServices from './adminService/faq.service';
import ProfileServices from './adminService/profile.service';
import UploadAnswerServices from './adminService/uploadAnswer.service';
import LicenseServices from './adminService/license.service';
// Company
import CompanyAccountServices from './adminCompany/account.service';
import CompanyStatisticService from './adminCompany/statistic.service';
import CompanyCourseServices from './adminCompany/course.service';
import CompanyContractServices from './adminCompany/company.service';
import CompanyProfileServices from './adminCompany/profile.service';
// Student
import StudentStatisticService from './student/statistic.service';
import StudentAccountServices from './student/account.service';
import StudentCourseServices from './student/course.service';
import StudentUnitServices from './student/unit.service';
import StudentTestServices from './student/test.service';
import StudentVideoServices from './student/video.service';
import StudentSurveyServices from './student/survey.service';
// Master Data
import MasterDataServices from './master.service';
import MyProfileServices from './profile.service';
import NotificationServices from './notification.service';

const restClient = new RestClient();

export const authServices = new AuthServices(restClient);
export const testServices = new TestServices(restClient);
export const videoServices = new VideoServices(restClient);
export const unitServices = new UnitServices(restClient);
export const courseServices = new CourseServices(restClient);
export const companyServices = new CompanyServices(restClient);
export const tagServices = new TagServices(restClient);
export const planServices = new PlanServices(restClient);
export const teacherServices = new TeacherServices(restClient);
export const surveyServices = new SurveyServices(restClient);
export const categoryServices = new CategoryServices(restClient);
export const tipServices = new TipServices(restClient);
export const accountServices = new AccountServices(restClient);
export const serviceStatisticServices  = new ServiceStatisticServices(restClient);
export const questionServices = new QuestionServices(restClient);
export const faqServices = new FAQServices(restClient);
export const profileServices = new ProfileServices(restClient);
export const uploadAnswerServices = new UploadAnswerServices(restClient);
export const licenseServices = new LicenseServices(restClient);
// Company
export const companyAccountServices = new CompanyAccountServices(restClient);
export const companyStatisticService = new CompanyStatisticService(restClient);
export const companyCourseServices = new CompanyCourseServices(restClient);
export const companyContractServices = new CompanyContractServices(restClient);
export const companyProfileServices = new CompanyProfileServices(restClient);
// Student
export const studentStatisticService = new StudentStatisticService(restClient);
export const studentAccountServices = new StudentAccountServices(restClient);
export const studentCourseServices = new StudentCourseServices(restClient);
export const studentUnitServices = new StudentUnitServices(restClient);
export const studentTestServices = new StudentTestServices(restClient);
export const studentVideoServices = new StudentVideoServices(restClient);
export const studentSurveyServices = new StudentSurveyServices(restClient);
// Common
export const masterDataServices = new MasterDataServices(restClient);
export const myProfileServices = new MyProfileServices(restClient);
export const notificationServices = new NotificationServices(restClient);
