// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class FAQServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getFAQList(params) {
    return this.restClient.get('/admin_manage/faqs', params);
  }

  getFAQDetail(params) {
    return this.restClient.get(`/admin_manage/faqs/${params.id}`);
  }

  createFAQ(params) {
    return this.restClient.post('/admin_manage/faqs', params);
  }

  updateFAQ(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/faqs/${id}`, requestData);
  }

  addFAQContent(params) {
    const { requestData, faqId } = params;
    return this.restClient.post(`/admin_manage/faqs/${faqId}/faq_contents`, requestData);
  }

  updateFAQContent(params) {
    const { requestData, faqId, faqContentId } = params;
    return this.restClient.put(`/admin_manage/faqs/${faqId}/faq_contents/${faqContentId}`, requestData);
  }

  deleteFAQContent(params) {
    const { faqId, faqContentId } = params;
    return this.restClient.delete(`/admin_manage/faqs/${faqId}/faq_contents/${faqContentId}`);
  }
}
