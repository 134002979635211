// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Select,
  Input,
  Tooltip,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';
import { find } from 'lodash/collection';

import { onScrollLoadMore, getFilterOption } from 'src/utils/common';
import { convertSecondTime } from 'src/utils/dateTime';

import { UNIT_TYPE } from 'src/constants/common';
import ModalFooter from 'src/components/Modal/components/ModalFooter';
import { Text } from 'src/components/styles/Texts';
import {
  getCourseContentUnits as getCourseContentUnitsAction,
  clearUnitList as clearUnitListAction,
  getVersionList as getVersionListAction,
  clearVersionList as clearVersionListAction,
  getCategoryList as getCategoryListAction,
  createCourseUnit as createCourseUnitAction,
  updateCourseUnit as updateCourseUnitAction,
  createChapterUnit as createChapterUnitAction,
  updateChapterUnit as updateChapterUnitAction,
  clearCourseDetailErrors as clearCourseDetailErrorsAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModifyUnitModalProps extends StateProps, DispatchProps {
  getCourseContentUnits(params): void;
  getCourseContentsUnit(params): void;
  clearUnitList(): void;
  getVersionList(params): void;
  clearVersionList(): void;
  getCategoryList(): void;
  createCourseUnit(params): void;
  createChapterUnit(params): void;
  clearCourseDetailErrors(): void;
  modalData: {
    title: string;
    type: string;
    course: {
      id: string;
    };
    group: {
      index: number,
    };
    chapter: {
      id: string;
      index: number,
    };
    unitVersion: {
      id: number;
      isInChapter: boolean;
      item: {
        type: string;
      };
      unit: {
        id: number;
        name: string;
        category: {
          id: number;
        };
      }
      version: string;
      index: number,
    },
    actionType: string;
  };
  modalAction: {
    setActiveKey(params): void;
  };
  courseDetail: {
    errors: {
      message: string;
    }[];
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  courseContentUnits: {
    data: {
      id: number;
      name: string;
      item: object;
    }[];
    meta: {
      pageInfo: {
        next: number;
      };
    };
    load: boolean;
  };
  unitVersions: {
    data: {
      id: number;
      version: string,
      item: {
        name: string,
      };
    }[];
  };
}

const ModifyUnitModal: React.FC<IModifyUnitModalProps> = ({
  getCourseContentUnits,
  clearUnitList,
  getVersionList,
  clearVersionList,
  getCategoryList,
  createCourseUnit,
  updateCourseUnit,
  createChapterUnit,
  updateChapterUnit,
  clearCourseDetailErrors,
  modalData,
  modalAction,
  courseDetail,
  categoryList,
  courseContentUnits,
  unitVersions,
}) => {
  const [versionList, setVersionList] = useState<any>([]);
  const [unitVersionSelected, setUnitVersionSelected] = useState<any>(null);
  const [unitSearchKey, setUnitSearchKey] = useState<string>('');
  const [modifyUnitForm] = Form.useForm();

  const unitInitialFormValues = {
    type: (modalData.unitVersion.item || {}).type ? UNIT_TYPE[modalData.unitVersion.item.type] : undefined,
    category: (modalData.unitVersion.unit || {}).category ? modalData.unitVersion.unit.category.id : undefined,
    unitId: (modalData.unitVersion.unit || {}).id
      ? {
        key: modalData.unitVersion.unit.id,
        value: modalData.unitVersion.unit.id,
        label: (
          <Tooltip title={modalData.unitVersion.unit.name}>
            <Text truncate style={{ lineHeight: 'unset' }}>{modalData.unitVersion.unit.name}</Text>
          </Tooltip>
        ),
      }
      : undefined,
    version: modalData.unitVersion.id ? modalData.unitVersion.id : undefined,
  };

  useEffect(() => {
    getCategoryList();
    clearCourseDetailErrors();
  }, []);

  useEffect(() => {
    if (modalData.actionType === 'add') {
      if (modalData.unitVersion.item && modalData.unitVersion.item.type) {
        getCourseContentUnits({
          itemType: modalData.unitVersion.item.type,
          page: 1,
          isInChapter: modalData.unitVersion.isInChapter,
        });
      }
    } else {
      getCourseContentUnits({
        itemType: modalData.unitVersion.item.type,
        categoryIdEq: modalData.unitVersion.unit.category?.id,
        page: 1,
        isInChapter: modalData.unitVersion.isInChapter,
      });
      getVersionList({
        unitId: modalData.unitVersion.unit.id,
        requestData: {
          isInChapter: modalData.unitVersion.isInChapter,
        },
      });
    }
  }, [modalData.actionType, modalData.unitVersion.item]);

  useEffect(() => {
    if (!isEmpty(unitVersions.data)) setVersionList(unitVersions.data);
  }, [unitVersions.data]);

  useEffect(() => {
    if (!isEmpty(versionList) && !unitVersionSelected) {
      setUnitVersionSelected(find(versionList, { id: modalData.unitVersion.id }));
    }
  }, [versionList]);

  function handleSubmitForm() {
    if (modalData.type === 'modifyCourseUnit') {
      if (modalData.actionType === 'add') {
        createCourseUnit({
          id: modalData.course.id,
          unitType: modalData.unitVersion.item.type,
          requestData: {
            coursesUnitVersion: {
              unitVersionId: unitVersionSelected.id,
            },
          },
          requestAction: {
            setActiveKey: modalAction.setActiveKey,
          },
        });
      } else {
        updateCourseUnit({
          id: modalData.course.id,
          requestData: {
            oldUnitVersionId: modalData.unitVersion.id,
            coursesUnitVersion: {
              unitVersionId: unitVersionSelected.id,
            },
          },
          unitIndex: modalData.unitVersion.index,
        });
      }
    } else {
      if (modalData.actionType === 'add') {
        createChapterUnit({
          id: modalData.chapter.id,
          requestData: {
            position: modalData.unitVersion.index,
            chaptersUnitVersion: {
              unitVersionId: unitVersionSelected.id,
            },
          },
          groupIndex: modalData.group.index,
          chapterIndex: modalData.chapter.index,
        });
      } else {
        updateChapterUnit({
          id: modalData.chapter.id,
          requestData: {
            position: modalData.unitVersion.index,
            oldUnitVersionId: modalData.unitVersion.id,
            chaptersUnitVersion: {
              unitVersionId: unitVersionSelected.id,
            },
          },
          groupIndex: modalData.group.index,
          chapterIndex: modalData.chapter.index,
        });
      }
    }
  }

  function handleLoadMoreUnits(e) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const itemType = (modalData.unitVersion.item || {}).type
      ? modalData.unitVersion.item.type
      : modifyUnitForm.getFieldValue('type');
    const category = modifyUnitForm.getFieldValue('category');
    return onScrollLoadMore(
      {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
      !!courseContentUnits.meta.pageInfo.next,
      () => getCourseContentUnits({
        itemType,
        ...(category && { categoryIdEq: category }),
        nameCont: unitSearchKey.trim(),
        isInChapter: modalData.unitVersion.isInChapter,
        page: courseContentUnits.meta.pageInfo.next,
        more: true,
      }),
    );
  }

  function renderUnitTypeField() {
    if (modalData.unitVersion.item && modalData.unitVersion.item.type) {
      return (
        <Input disabled style={{ width: 120 }} />
      );
    }
    return (
      <Select
        allowClear
        suffixIcon={<CaretDownOutlined />}
        placeholder="選択してください"
        onChange={(value) => {
          if (value) {
            getCourseContentUnits({
              itemType: value,
              isInChapter: modalData.unitVersion.isInChapter,
              page: 1,
            });
          } else {
            clearUnitList();
            clearVersionList();
          }
          modifyUnitForm.setFieldsValue({ category: undefined, unitId: undefined, version: undefined });
          setVersionList([]);
          setUnitVersionSelected({});
        }}
        style={{ width: 120 }}
      >
        <Select.Option value="video">動画</Select.Option>
        <Select.Option value="test">テスト</Select.Option>
        <Select.Option value="tip">Tips</Select.Option>
        <Select.Option value="survey">アンケート</Select.Option>
      </Select>
    );
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`unit-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  function renderUnitOptions() {
    if (isEmpty(courseContentUnits.data)) return null;
    return courseContentUnits.data.map((unit) => (
      <Select.Option key={`unit-${unit.id}`} value={unit.id}>
        <Tooltip title={unit.name}>
          <Text truncate style={{ lineHeight: 'unset' }}>{unit.name}</Text>
        </Tooltip>
      </Select.Option>
    ));
  }

  function renderVersionOptions() {
    if (isEmpty(versionList)) return null;
    return versionList.map((version) => (
      <Select.Option key={`unit-${version.id}`} value={version.id}>{version.version}</Select.Option>
    ));
  }

  function renderUnitDetail() {
    if (!unitVersionSelected || !unitVersionSelected.item ) return null;
    switch (unitVersionSelected.item.type) {
    case 'video':
      return (
        <Row>
          <Col span={4}>
            <Text xs w6>動画時間</Text>
          </Col>
          <Col span={20}>
            <Text headerText w6>
              {convertSecondTime(unitVersionSelected.item.duration, 'HH:mm:ss')}
            </Text>
          </Col>
        </Row>
      );
    case 'test':
      return (
        <Row>
          <Col span={4}>
            <Text xs w6>問題数</Text>
          </Col>
          <Col span={20}>
            <Text headerText xs w6>{unitVersionSelected.item.questionsCount}</Text>
          </Col>
        </Row>
      );
    default:
      return null;
    }
  }

  return (
    <Form
      form={modifyUnitForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      name="modifyUnitToCourseForm"
      initialValues={unitInitialFormValues}
      onFinish={() => handleSubmitForm()}
    >
      <Style.UnitFieldContent>
        <Form.Item
          label="レッスンタイプ"
          name="type"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: 'レッスンタイプは必須項目です。' },
          ]}
        >
          {renderUnitTypeField()}
        </Form.Item>
        <Form.Item
          label="講座タイプ"
          name="category"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: '講座タイプは必須項目です。' },
          ]}
        >
          <Select
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            optionFilterProp="children"
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            filterOption={getFilterOption}
            onChange={(value) => {
              const itemType = (modalData.unitVersion.item || {}).type
                ? modalData.unitVersion.item.type
                : modifyUnitForm.getFieldValue('type');
              if (value && itemType) {
                getCourseContentUnits({
                  itemType,
                  categoryIdEq: value,
                  isInChapter: modalData.unitVersion.isInChapter,
                  page: 1,
                });
              } else {
                clearUnitList();
                clearVersionList();
              }
              modifyUnitForm.setFieldsValue({ unitId: undefined, version: undefined });
              setVersionList([]);
              setUnitVersionSelected({});
            }}
            disabled={!modifyUnitForm.getFieldValue('type')}
            style={{ width: 260 }}
          >
            {renderCategoryOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label="レッスン名"
          name="unitId"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: 'レッスン名は必須項目です。' },
          ]}
        >
          <Select
            allowClear
            showSearch
            labelInValue
            suffixIcon={<CaretDownOutlined />}
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            filterOption={(input: any, option: any) => {
              const optionTitle = option.children.props.title;
              const filterValue = (input || '').trim();
              return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
            }}
            onSearch={(searchKey) => {
              const itemType = (modalData.unitVersion.item || {}).type
                ? modalData.unitVersion.item.type
                : modifyUnitForm.getFieldValue('type');
              const categoryId = modifyUnitForm.getFieldValue('category');
              if (searchKey && itemType) {
                setUnitSearchKey(searchKey);
                getCourseContentUnits({
                  itemType,
                  ...(categoryId && { categoryIdEq: categoryId }),
                  nameCont: searchKey,
                  isInChapter: modalData.unitVersion.isInChapter,
                  page: 1,
                });
              }
            }}
            onChange={(option: any) => {
              if (option && option.value) {
                getVersionList({
                  unitId: option.value,
                  requestData: {
                    isInChapter: modalData.unitVersion.isInChapter,
                  },
                });
              } else {
                clearVersionList();
              }
              modifyUnitForm.setFieldsValue({ version: undefined });
              setUnitVersionSelected({});
            }}
            onBlur={() => {
              const itemType = (modalData.unitVersion.item || {}).type
                ? modalData.unitVersion.item.type
                : modifyUnitForm.getFieldValue('type');
              const categoryId = modifyUnitForm.getFieldValue('category');
              if (itemType && unitSearchKey !== '') {
                setUnitSearchKey('');
                getCourseContentUnits({
                  itemType,
                  ...(categoryId && { categoryIdEq: categoryId }),
                  nameCont: '',
                  isInChapter: modalData.unitVersion.isInChapter,
                  page: 1,
                });
              }
            }}
            onPopupScroll={(e) => handleLoadMoreUnits(e)}
            disabled={!modifyUnitForm.getFieldValue('category')}
            style={{ width: 260 }}
          >
            {renderUnitOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label="バージョン"
          name="version"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: 'バージョンは必須項目です。' },
          ]}
        >
          <Select
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            optionFilterProp="children"
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            filterOption={getFilterOption}
            onChange={(value) => {
              if (value) setUnitVersionSelected(versionList.find((ob) => ob.id === value));
            }}
            disabled={!modifyUnitForm.getFieldValue('unitId')}
            style={{ width: 180 }}
          >
            {renderVersionOptions()}
          </Select>
        </Form.Item>
        {unitVersionSelected && unitVersionSelected.id && (
          <Row>
            <Col span={5} />
            <Col span={19}>
              <Row>
                <Col span={4}>
                  <Text xs w6>ファイル名</Text>
                </Col>
                <Col span={20}>
                  <Text headerText xs w6>{unitVersionSelected.item.name}</Text>
                </Col>
              </Row>
              {renderUnitDetail()}
            </Col>
          </Row>
        )}
      </Style.UnitFieldContent>
      <ModalFooter
        okText={modalData.actionType === 'add' ? '追加する' : '保存する'}
        cancelText="キャンセル"
        error={!isEmpty(courseDetail.errors) && courseDetail.errors[0].message}
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { courseDetail } = state.courseReducer;
  const { courseContentUnits, unitVersions } = state.unitReducer;
  const { categoryList } = state.categoryReducer;
  return {
    courseDetail,
    courseContentUnits,
    unitVersions,
    categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCourseContentUnits: (params) => dispatch(getCourseContentUnitsAction(params)),
  clearUnitList: () => dispatch(clearUnitListAction()),
  getVersionList: (params) => dispatch(getVersionListAction(params)),
  clearVersionList: () => dispatch(clearVersionListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  createCourseUnit: (params) => dispatch(createCourseUnitAction(params)),
  updateCourseUnit: (params) => dispatch(updateCourseUnitAction(params)),
  createChapterUnit: (params) => dispatch(createChapterUnitAction(params)),
  updateChapterUnit: (params) => dispatch(updateChapterUnitAction(params)),
  clearCourseDetailErrors: () => dispatch(clearCourseDetailErrorsAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyUnitModal);
