// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const CollapseItemContainer = styled.div`
  display: flex;
  background-color: white;
  min-height: 50px;
  padding: 0 40px 0 16px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  cursor: pointer;

  &:hover {
    background-color: #FAFAFA;
  }
`;

export const CollapseItemContent = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: center;
  width: ${(props) => props.width || 'auto'};
`;

export const CollapseHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
   /* Fix onClick call API collapse header */
  margin: 0 -40px 0 -16px;
  padding: 0 40px 0 16px;
  width: calc(100% + 56px);
  min-height: 50px;

  &:hover {
    background-color: #FAFAFA;
  }

  & .collapse-header-button {
    display: none;
  }

  &:hover .collapse-header-button {
    display: block;
    margin-right: 20px;
  }
`;

export const NoVersionsData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: #FAFAFA;
`;

export const CollapseIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  display: flex;
  transform: translate(0, -50%);
`;

export const CollapseItemName = styled.div`
  width: ${(props) => props.width || 'auto'};
`;

export const CourseCollapseContent = styled.div`
  padding: 30px;
  background-color: white;
`;

export const WrapperTable = styled.div`
  width: 100%;
  overflow-x: scroll;
  background-color: #EEEEEE;
`;
