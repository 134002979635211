// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { STUDENT, REQUEST } from '../../constant';
import moment from 'moment';

export function getStudentStatisticCourses() {
  return {
    type: REQUEST(STUDENT.STATISTIC_ACTION.GET_STATISTIC_COURSES),
  };
}

export function getStudentCurrentWeek() {
  return {
    type: REQUEST(STUDENT.STATISTIC_ACTION.GET_CURRENT_WEEK),
  };
}

export function getStudentLearningHistories(params) {
  return {
    type: REQUEST(STUDENT.STATISTIC_ACTION.GET_LEARNING_HISTORIES),
    payload: params,
  };
}

export function getStudentLearningTimes(params) {
  const newParams = {
    learningDateGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    learningDateLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
  };
  return {
    type: REQUEST(STUDENT.STATISTIC_ACTION.GET_LEARNING_TIMES),
    payload: newParams,
  };
}
