// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

const initialState = {
  licenseList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  licenseDetail: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
};

export type LicenseReducer = Readonly<typeof initialState>;

export default (state: LicenseReducer = initialState, action: any): LicenseReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.LICENSE_ACTION.GET_LIST): {
    return {
      ...state,
      licenseList: {
        ...state.licenseList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      licenseList: {
        data: data.coursesCompanies,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.LICENSE_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      licenseList: {
        ...state.licenseList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      licenseDetail: {
        data: data.coursesCompany,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.LICENSE_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      licenseDetail: {
        ...state.licenseDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.UPDATE_LICENSE): {
    const { data, meta } = action.payload;
    return {
      ...state,
      licenseDetail: {
        ...state.licenseDetail,
        data: data.coursesCompany,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.LICENSE_ACTION.UPDATE_LICENSE): {
    const { errors } = action;
    return {
      ...state,
      licenseDetail: {
        ...state.licenseDetail,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.LICENSE_ACTION.CLEAR_LICENSE_DATA): {
    return {
      ...state,
      licenseDetail: initialState.licenseDetail,
    };
  }

  default:
    return state;
  }
};
