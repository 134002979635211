// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const ACCOUNT_PROFILE_FIELDS = [
  {
    title: 'アカウントID',
    fieldName: 'id',
  },
  {
    title: 'アカウント種別',
    fieldName: 'roles',
  },
  {
    title: 'メールアドレス',
    fieldName: 'email',
  },
];

export const USER_PROFILE_FIELDS = [
  {
    title: '名前',
    fieldName: 'jituName',
    type: 'input',
  },
  {
    title: 'フリガナ',
    fieldName: 'kanaName',
    type: 'input',
  },
  {
    title: 'お知らせ',
    fieldName: 'systemNotificationSetting',
    type: 'select',
    option: [
      {
        title: '受け取る',
        value: 'accept',
      },
      {
        title: '受け取らない',
        value: 'deny',
      },
    ],
  },
];

export const COMPANY_PROFILE_FIELDS = [
  {
    title: '企業名',
    fieldName: 'companyName',
    type: 'input',
  },
  {
    title: '部署',
    fieldName: 'department',
    type: 'input',
  },
  {
    title: '役職',
    fieldName: 'jobTitle',
    type: 'input',
  },
  {
    title: '職種',
    fieldName: 'jobType',
    type: 'input',
  },
  {
    title: '生年月日',
    fieldName: 'birthday',
    type: 'datepicker',
  },
];
