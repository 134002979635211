// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Row, Col } from 'antd';

export const ConfirmAccountContainer = styled.div`
  padding: 24px;
`;

export const GroupAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    width: 100px;
  }
  ${(props) => props.hasSubText
    && css`
      justify-content: space-between;
    `}
`;

export const FormRow = styled(Row)`
  margin-bottom: 24px;
`;

export const FormCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const Link = styled.a`
  font-size: 14px;
  color: #0DA2FE;

  &:hover {
    color: #0DA2FE;
    opacity: 0.75;
  }
`;

export const GroupCheckbox = styled.div`
  display: flex;
  align-items: center;
`;
