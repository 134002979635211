// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const PROFILE_ACTION = {
  GET_PROFILE_DETAIL: 'ADMIN_COMPANY/PROFILE_ACTION/GET_PROFILE_DETAIL',
  UPDATE_USER_ROLE: 'ADMIN_COMPANY/PROFILE_ACTION/UPDATE_USER_ROLE',
  CLEAR_UPDATE_ROLE_ERRORS: 'ADMIN_COMPANY/PROFILE_ACTION/CLEAR_UPDATE_ROLE_ERRORS',
  GET_JOINED_COURSES: 'ADMIN_SERVICE/PROFILE_ACTION/GET_JOINED_COURSES',
  GET_TEST_STUDENT: 'ADMIN_COMPANY/PROFILE_ACTION/GET_TEST_STUDENT',
  RESET_TEST_COMPANY: 'ADMIN_COMPANY/PROFILE_ACTION/RESET_TEST_COMPANY',
  UNLOCK_USER: 'ADMIN_COMPANY/PROFILE_ACTION/UNLOCK_USER'
};
