// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CourseOverview from '../components/CourseOverview';
import SurveyAndTest from '../components/SurveyAndTest';
import CourseContent from '../components/CourseContent';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  showModal as showModalAction,
  getCourseDetail as getCourseDetailAction,
  getGroupDetail as getGroupDetailAction,
  deleteCourseUnit as deleteCourseUnitAction,
  updateCourseGroup as updateCourseGroupAction,
  deleteCourseGroup as deleteCourseGroupAction,
  updateGroupChapter as updateGroupChapterAction,
  deleteGroupChapter as deleteGroupChapterAction,
  deleteChapterUnit as deleteChapterUnitAction,
  clearCourseDetailErrors as clearCourseDetailErrorsAction,
  closeModal as closeModalAction,

} from 'src/redux/actions';

interface IEditCourseProps extends StateProps, DispatchProps {
  showModal(params: any): void;
  getCourseDetail(params: any): void;
  getGroupDetail(params: any): void;
  deleteCourseUnit(params: any): void;
  updateCourseGroup(params: any): void;
  deleteCourseGroup(params: any): void;
  updateGroupChapter(params: any): void;
  deleteGroupChapter(params: any): void;
  deleteChapterUnit(params: any): void;
  clearCourseDetailErrors(): void;
  closeModal(): void;
  isDisableRequest: boolean;
  courseId: string;
  courseDetail: {
    data: {
      id: number,
      name: string,
      status: string,
      category: {
        id: number;
        name: string;
      },
      overview: string,
      updatedAt: string,
      updater: string,
      plan: {
        id: number,
        name: string,
      },
      contract: {
        id: number,
        name: string,
      },
      tags: {
        id: number,
        name: string,
      }[],
      teachers: {
        id: number,
        name: string,
      }[],
      contentOverview: {
        videosCount: number,
        videosTotalDuration: number,
        finishTestsCount: number,
        finishTestsTotalQuestion: number,
        preTestsCount: number,
        preTestsTotalQuestion: number,
        confirmTestsCount: number,
        confirmTestsTotalQuestion: number,
      };
      surveys: {
        id: number;
        isLearned: boolean;
        item: {
          surveyType: string;
        };
        unit: {
          id: number;
          name: string;
          category: {
            id: number;
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        isLearned: boolean;
        item: {
          testType: string;
          questionsCount: number;
        };
        unit: {
          id: number;
          name: string;
          category: {
            id: number;
            name: string;
          };
        };
      }[];
      groups: {
        id: number;
        name: string;
        chapters: object[];
        isLearned: boolean;
      }[];
    },
  };
}

const EditCourse: React.FC<IEditCourseProps> = ({
  showModal,
  getCourseDetail,
  getGroupDetail,
  deleteCourseUnit,
  updateCourseGroup,
  deleteCourseGroup,
  updateGroupChapter,
  deleteGroupChapter,
  deleteChapterUnit,
  clearCourseDetailErrors,
  courseId,
  courseDetail,
  closeModal,
  isDisableRequest
}) => {
  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.EditCourse);

    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    if (courseId && parseFloat(courseId) !== courseDetail.data.id) {
      getCourseDetail({ id: courseId });
    }
  }, [courseId, courseDetail.data.id]);

  return (
    <>
      <CourseOverview
        courseId={courseId}
        courseDetail={courseDetail}
        clearCourseDetailErrors={clearCourseDetailErrors}
      />
      <SurveyAndTest
        showModal={showModal}
        deleteCourseUnit={deleteCourseUnit}
        courseId={courseId}
        courseDetail={courseDetail}
      />
      <CourseContent
        getGroupDetail={getGroupDetail}
        showModal={showModal}
        updateCourseGroup={updateCourseGroup}
        deleteCourseGroup={deleteCourseGroup}
        updateGroupChapter={updateGroupChapter}
        deleteGroupChapter={deleteGroupChapter}
        deleteChapterUnit={deleteChapterUnit}
        clearCourseDetailErrors={clearCourseDetailErrors}
        courseId={courseId}
        courseDetail={courseDetail}
        isDisableRequest={isDisableRequest}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { courseDetail } = state.courseReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    courseDetail,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
  getGroupDetail: (params) => dispatch(getGroupDetailAction(params)),
  deleteCourseUnit: (params) => dispatch(deleteCourseUnitAction(params)),
  updateCourseGroup: (params) => dispatch(updateCourseGroupAction(params)),
  deleteCourseGroup: (params) => dispatch(deleteCourseGroupAction(params)),
  updateGroupChapter: (params) => dispatch(updateGroupChapterAction(params)),
  deleteGroupChapter: (params) => dispatch(deleteGroupChapterAction(params)),
  deleteChapterUnit: (params) => dispatch(deleteChapterUnitAction(params)),
  clearCourseDetailErrors: () => dispatch(clearCourseDetailErrorsAction()),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
