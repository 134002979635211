// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const TableAction = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    width: 76px;
  }
`;
