//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { isEmpty } from 'lodash/lang';

import { handleNavigateUnit } from '../../util';

import { Text, SkeletonLoading } from 'src/components/styles';

import {
  getStudentGroupDetail as getStudentGroupDetailAction,
  setCollapseData as setStudentCollapseDataAction,
} from 'src/redux/actions';

import prevUnitIcon from 'src/assets/images/videoPlayer/prev-video-icon.svg';
import nextUnitIcon from 'src/assets/images/videoPlayer/next-video-icon.svg';
import prevUnitDisabledIcon from 'src/assets/images/videoPlayer/prev-video-disabled-icon.svg';
import nextUnitDisabledIcon from 'src/assets/images/videoPlayer/next-video-disabled-icon.svg';

import * as Style from './styles';
import { ButtonBack, CourseNameTitle } from "../../styles"
import history from 'src/utils/history';

interface ITipOverviewProps extends StateProps, DispatchProps {
  getStudentGroupDetail(params): void;
  setCollapseData(params): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  courseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
  unitDetail: {
    data: {
      id: number;
      unit: {
        name: string;
      };
      item: {
        content: string;
        url: string;
      };
    };
    meta: {
      back: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
      next: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
    };
    load: boolean;
  };
}

const TipOverview: React.FC<ITipOverviewProps> = ({
  getStudentGroupDetail,
  setCollapseData,
  collapseData,
  courseDetail,
  unitDetail,
}) => (
  <>
    {courseDetail.load
      ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
      : <CourseNameTitle white w6 xxl>{courseDetail.data.courseCategory?.name}</CourseNameTitle>
    }
    <Style.TipDetailContainer>
      <Style.TipDetailContent>
        <Style.TipDetailTitle>
          {courseDetail.load || unitDetail.load
            ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
            : <Text truncate white lg w7>{unitDetail.data.unit.name}</Text>
          }
        </Style.TipDetailTitle>
        <Style.TipDescription>
          {courseDetail.load || unitDetail.load
            ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
            : (
              <div
                dangerouslySetInnerHTML={{
                  __html: unitDetail.data.item.content,
                }}
              />
            )
          }
        </Style.TipDescription>
        <Style.TipFooter>
          <ButtonBack
            type="link"
            style={{ padding: 0 }}
            disabled={isEmpty(unitDetail.meta.back)}
            onClick={() => handleNavigateUnit({
              action: 'back',
              getStudentGroupDetail,
              setCollapseData,
              courseDetail,
              unitDetail,
              collapseData,
            })}
          >
            <img
              src={unitDetail.meta.back ? prevUnitIcon : prevUnitDisabledIcon}
              style={{ marginRight: 4 }}
              alt=""
            />
            戻る
          </ButtonBack>
          <ButtonBack
            type="link"
            style={{ padding: 0 }}
            disabled={isEmpty(unitDetail.meta.next)}
            onClick={() => handleNavigateUnit({
              action: 'next',
              getStudentGroupDetail,
              setCollapseData,
              courseDetail,
              unitDetail,
              collapseData,
            })}
          >
            次へ
            <img
              src={unitDetail.meta.next ? nextUnitIcon : nextUnitDisabledIcon}
              style={{ marginLeft: 4 }}
              alt=""
            />
          </ButtonBack>
        </Style.TipFooter>
      </Style.TipDetailContent>
    </Style.TipDetailContainer>
  </>
);

const mapStateToProps = (state) => {
  const { collapseData } = state.commonReducer;
  const { courseDetail } = state.studentCourseReducer;
  const { unitDetail } = state.studentUnitReducer;
  return {
    collapseData,
    courseDetail,
    unitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentGroupDetail: (params) => dispatch(getStudentGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setStudentCollapseDataAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TipOverview);
