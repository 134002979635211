//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Row,
  Col,
  Tooltip,
  Button,
  Space,
  Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { convertSecondTime, formatDateTime } from 'src/utils/dateTime';
import { getFilterOption } from 'src/utils/common';

import { Text, SkeletonLoading } from 'src/components/styles';
import { ServicePath } from 'src/constants/routerConstants';
import { DATE_FORMAT } from 'src/constants/common';

import history from 'src/utils/history';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import * as Style from './styles';

interface ICourseOverviewProps {
  deleteCourse(params): void;
  getCourseDetail(params): void;
  showModal(params: any): void;
  setCompanySelectedId(params): void;
  getCourseCompanyInfo(params): void;
  getGroupRecommendedDate(params): void;
  clearCourseOverview(): void;
  clearDataLearningTab(): void;
  resetStateLearningTab(): void;
  companySelectedId;
  courseId: string | undefined;
  courseOverviewElement: any;
  courseOverviewDetail: {
    data: {
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
      startDate: string;
      remainingDays: number;
      endDate: string;
      recommendedStartDate: string;
      recommendedEndDate: string;
      recommendedCompletionDay: number;
      remainingRecommendedDays: number;
      licensesCount: number;
      usedLicensesCount: number;
      isLearned: boolean;
      actualStatus: string;
      licenseId: number;
    };
    load: boolean;
  };
  courseDetail: {
    data: {
      id: number;
      contractCount: number;
      courseCategory: {
        id: number;
        name: string;
      };
      companies: {
        id: number;
        contractName: string;
      }[];
      actualStatus: string;
      name: string;
      overview: string;
      remainingDays: number;
      startDate: string;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      isLearned: boolean;
      isDisplayLogo: boolean,
      logo:{
        logoName: string,
        logoUrl: string,
      },
      company: {
        id: number;
      }
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
    };
    load: boolean;
  };
}

const CourseOverview: React.FC<ICourseOverviewProps> = ({
  deleteCourse,
  getCourseDetail,
  showModal,
  setCompanySelectedId,
  getCourseCompanyInfo,
  getGroupRecommendedDate,
  clearCourseOverview,
  clearDataLearningTab,
  resetStateLearningTab,
  companySelectedId,
  courseId,
  courseOverviewElement,
  courseDetail,
  courseOverviewDetail,
}) => {
  const { data } = courseOverviewDetail; 

  const teacherList = !isEmpty(data.teachers)
    ? data.teachers.map((teacher) => teacher.name)
    : [];

  function handleDeleteCourse() {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'このコースを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deleteCourse({ id: courseId }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function renderCompaniesOption() {
    return courseDetail.data.companies?.map((company) => (
      <Select.Option key={company.id} value={company.id}>
        <TooltipParagraph
          style={{
            lineHeight: 'unset',
          }}
        >
          {company.contractName}
        </TooltipParagraph>
      </Select.Option>
    ));
  }

  function renderRecommendDate() {
    const {recommendedStartDate, recommendedEndDate } = data
    if (courseOverviewDetail.load) {
      return <SkeletonLoading active paragraph={false} title={{ width: 80 }} />;
    }
    if (recommendedStartDate && recommendedEndDate) {
      return (
        <Text headerText w7 sm>
          {`${formatDateTime(recommendedStartDate, DATE_FORMAT)} ~ ${formatDateTime(recommendedEndDate, DATE_FORMAT)}`}
        </Text>
      );
    } else {
      return <Text headerText w7 sm> 未設定 </Text>;
    }
  }

  function renderRecommendedCompletionDay() {
    const { remainingRecommendedDays } = data;

    if (courseOverviewDetail.load) {
      return <SkeletonLoading active paragraph={false} title={{ width: 80 }} />;
    }
    if (remainingRecommendedDays || remainingRecommendedDays === 0) {
      return <Text headerText w7 sm> あと {remainingRecommendedDays} 日 </Text>;
    }
    return <Text headerText w7 sm> あと - 日 </Text>;
  }

  return (
    <Style.CourseOverviewContainer ref={courseOverviewElement}>
      <Row gutter={100}>
        <Col span={13}>
          {courseDetail.load
            ? (
              <>
                <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={24} />
                <SkeletonLoading
                  active
                  paragraph={{ rows: 3 }}
                  title={false}
                  height={16}
                  margin="8px 0 0 0"
                />
              </>
            )
            : (
              <>
               <Style.CourseNameBlock>
                  {courseDetail?.data?.isDisplayLogo && (
                      <Style.LogoCourse src={courseDetail?.data?.logo?.logoUrl} />
                    )}
                  <Text headerText xxxl w6>
                    {courseDetail.data.courseCategory?.name}
                  </Text>
                </Style.CourseNameBlock>
                <Text xl w6 style={{ margin: '8px 0' }}>バージョン: {courseDetail.data.name}</Text>
                <Text lg style={{ margin: '8px 0' }}>
                  {courseDetail.data.overview}
                </Text>
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="契約を選択してください。"
                  notFoundContent="データが存在しません。"
                  style={{ width: 350 }}
                  filterOption={(input: any, option: any) => {
                    const optionTitle = option.children.props.children;
                    const filterValue = (input || '').trim();
                    return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  }}
                  onChange={(value) => {
                    history.replace({
                      state: {
                        companyContractId: value,
                        activeTabKey: '1'
                      }
                    })
                    clearDataLearningTab();
                    resetStateLearningTab();
                    setCompanySelectedId(value);
                    if (value) {
                      getCourseCompanyInfo({
                        courseId,
                        companyId: value,
                      });
                      getCourseDetail({
                        id: courseId,
                        requestData: { companyId: value }
                      })
                      getGroupRecommendedDate({ 
                        id: courseId,
                        requestData: { companyId: value } 
                      });
                    } else {
                      clearCourseOverview();
                    }
                  }}
                  {...(companySelectedId && courseDetail.data.companies.length > 0)
                    && { defaultValue: companySelectedId }
                  }
                >
                  {renderCompaniesOption()}
                </Select>
              </>
            )
          }
        </Col>
        <Col span={11}>
          <Row  style={{minHeight: 110}}>
            <Col span={11}>
              <Row>
                <Style.OverviewCol span={10}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>動画時間</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={12}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Text headerText w6>
                        {data.contentOverview?.videosTotalDuration
                          ? convertSecondTime(
                            data.contentOverview?.videosTotalDuration,
                            'HH:mm:ss',
                          )
                          : 'ー'
                        }
                      </Text>
                    )
                  }
                </Style.OverviewCol>
              </Row>
              <Row>
                <Style.OverviewCol span={10}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>担当講師</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={12}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Tooltip placement="topLeft" title={teacherList.length > 0 ? teacherList.toString() : ''}>
                        <Text truncate w6>
                          {teacherList.length > 0 ? teacherList.toString() : 'ー'}
                        </Text>
                      </Tooltip>
                    )
                  }
                </Style.OverviewCol>
              </Row>
              <Row>
                <Style.OverviewCol span={10}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>講座タイプ</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={12}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Tooltip placement="topLeft" title={courseDetail.data.category ? courseDetail.data.category.name : ''}>
                        <Text truncate w6>
                          {!isEmpty(courseDetail.data.category) ? courseDetail.data.category.name : 'ー'}
                        </Text>
                      </Tooltip>
                    )
                  }
                </Style.OverviewCol>
              </Row>
              {!companySelectedId && (
                <Row>
                  <Style.OverviewCol span={10}>
                    {courseDetail.load
                      ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                      : <Text xs secondaryText w6>契約数</Text>
                    }
                  </Style.OverviewCol>
                  <Style.OverviewCol span={12}>
                    {courseDetail.load
                      ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                      : (
                        <Text headerText w6>
                          {courseDetail.data.contractCount}
                        </Text>
                      )
                    }
                  </Style.OverviewCol>
                </Row>
              )}
              <Row>
                <Style.OverviewCol span={10}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text style={{ marginBottom: !companySelectedId && 6 }} xs secondaryText w6>ライセンス数</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={12}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Text headerText w6>
                        {
                          !!companySelectedId ?
                            `${data.licensesCount || 0}[残り${(data.licensesCount - data.usedLicensesCount) || 0}]`
                            : 'ー'
                        }
                      </Text>
                    )
                  }
                  </Style.OverviewCol>
                </Row>
            </Col>
            <Col span={13}>
              <Row>
                <Style.OverviewCol span={10}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>受講開始日</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={10}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Text headerText w6>
                        {
                          data.startDate && !!companySelectedId
                            ? formatDateTime(data.startDate, DATE_FORMAT)
                            : 'ー'
                        }
                      </Text>
                    )
                  }
                </Style.OverviewCol>
              </Row>
              <Row>
                <Col span={10}>
                  {courseDetail.load
                    ? (
                      <SkeletonLoading
                        active
                        paragraph={false}
                        title={{ width: 70 }}
                        height={12}
                        style={{ marginTop: 2 }}
                      />
                    )
                    : <Text xs secondaryText w6 style={{ marginTop: 2 }}>受講終了日</Text>
                  }
                </Col>
                <Col span={10}>
                  {courseDetail.load
                    ? (
                      <>
                        <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                        <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                      </>
                    )
                    : (
                      <>
                        <Text headerText w6>
                          {
                            data.endDate && !!companySelectedId
                              ? formatDateTime(data.endDate, DATE_FORMAT)
                              : 'ー'
                          }
                        </Text>
                        {(data.endDate && !!companySelectedId) && (
                          <Text xs headerText w6>
                            [ 残り{data.remainingDays > 0 ? data.remainingDays : 0}日 ]
                          </Text>
                        )}
                      </>
                    )
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Style.RowCustom>
              <Button
                size="small"
                type="primary"
                onClick={() => history.push({
                  pathname: ServicePath.LicenseGrant,
                  state: {
                    companyContractId: companySelectedId,
                    courseId: courseDetail.data?.id,
                  },
                })}
                disabled={courseDetail.load || ['finished', 'closed'].includes(data?.actualStatus)}
              >
                ライセンス付与
              </Button>
              <Style.RecommendDateBlock>
                <Style.RecommendDateWrapper>
                  <Row>
                    <Col span={8}>
                      {courseDetail.load
                        ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                        : <Text headerText w7 sm>完了推奨期間</Text>
                      }
                    </Col>
                    <Col span={16}>
                      {renderRecommendDate()}
                    </Col>
                  </Row>
                  {
                    courseOverviewDetail.load || courseOverviewDetail.data.recommendedStartDate &&
                    courseOverviewDetail.data.recommendedEndDate && (
                      <Row style={{ marginTop: 6 }}>
                        <Col span={8}>
                          {courseDetail.load ? (
                            <SkeletonLoading
                              active
                              paragraph={false}
                              title={{ width: 70 }}
                              height={12}
                            />
                          ) : (
                            <Text headerText w7 sm>
                              完了推奨日まで
                            </Text>
                          )}
                        </Col>
                        <Col span={16}>
                          {renderRecommendedCompletionDay()}
                        </Col>
                      </Row>
                    )
                  }
                </Style.RecommendDateWrapper>
                {!courseOverviewDetail.load && !courseOverviewDetail.data.recommendedStartDate &&
                  !courseOverviewDetail.data.recommendedEndDate && (
                    <Text xs>
                      ※完了推奨期間の設定はスキルアップAIまでご連絡ください
                    </Text>
                  )}
              </Style.RecommendDateBlock>
            </Style.RowCustom>
            <Row style={{ marginTop: 20 }}>
              <Style.OverviewRowButton>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => history.push({
                    pathname: ServicePath.CreateLicense,
                    state: {
                      course: {
                        key: courseDetail.data.courseCategory?.id,
                        value: courseDetail.data.courseCategory?.id,
                        label: courseDetail.data.courseCategory?.name,
                      },
                      version: {
                        key: courseDetail.data?.id,
                        value: courseDetail.data?.id,
                        label: courseDetail.data?.name,
                      },
                    },
                  })}
                  disabled={courseDetail.load}
                >
                  新規ライセンス設定
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => history.push(`/admin-service/license/${data.licenseId}/edit`)}
                  disabled={!companySelectedId}
                >
                  ライセンス修正
                </Button>
                <Button
                  size="small"
                  type="primary"
                  onClick={() => getCourseDetail({
                    id: courseDetail.data?.id,
                    redirect: `/admin-service/course/${courseDetail.data?.id}/edit`,
                  })}
                  disabled={courseDetail.load}
                >
                  コース修正
                </Button>
                <Button
                  size="small"
                  type="primary"
                  danger
                  ghost
                  htmlType="button"
                  disabled={courseDetail.load || data.isLearned}
                  onClick={() => handleDeleteCourse()}
                >
                  コース削除
                </Button>
              </Style.OverviewRowButton>
            </Row>
          </Row>
        </Col>
      </Row>
    </Style.CourseOverviewContainer>
  );
};

export default CourseOverview;
