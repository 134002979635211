// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { find } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { convertSecondTime } from 'src/utils/dateTime';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import {
  getVersionList as getVersionListAction,
  selectUnitVersion as selectUnitVersionAction,
} from 'src/redux/actions';

interface ISelectVersionModalProps extends StateProps, DispatchProps {
  getVersionList(params): void;
  selectUnitVersion(params): void;
  modalData: {
    currentVersion: string;
    unitId: number;
    unitType: string;
  };
  unitVersions: {
    data: {
      id: number,
      version: string,
      item: {
        id: number,
        name: string,
        duration: number,
        type: string,
      },
    } [],
    meta: object,
    errors: string,
  };
}

const SelectVersionModal: React.FC<ISelectVersionModalProps> = ({
  getVersionList,
  selectUnitVersion,
  modalData,
  unitVersions,
}) => {
  const [versionSelected, setVersionSelected] = useState<any>([]);
  const [selectItemError, setSelectItemError] = useState<any>('');

  useEffect(() => {
    if (modalData.unitId) {
      getVersionList({
        requestData: {
          unitId: modalData.unitId,
        },
        isModifyUnit: true,
      });
    }
  }, [modalData.unitId]);

  useEffect(() => {
    if (!isEmpty(unitVersions.data)) {
      const unitVersionSelected = find(unitVersions.data, { version: modalData.currentVersion });
      if (unitVersionSelected) setVersionSelected([unitVersionSelected.id]);
    }
  }, [unitVersions.data]);

  function handleSubmitModal() {
    if (isEmpty(versionSelected)) {
      return setSelectItemError('バージョンは必須項目です。');
    }
    setSelectItemError('');
    const unitVersionsSelected = find(unitVersions.data, { id: versionSelected[0] });
    return selectUnitVersion({
      currentVersion: unitVersionsSelected.version,
      item: unitVersionsSelected.item,
    });
  }

  const videoColumns = [{
    dataIndex: 'duration',
  }];

  const testColumns = [{
    dataIndex: 'questionsCount',
  }];


  const initialColumns = [
    {
      dataIndex: 'version',
    },
    {
      dataIndex: 'name',
    },
    ...(modalData.unitType === 'video'
      ? videoColumns
      : (modalData.unitType === 'test' ? testColumns : [])
    ),
  ];


  const tableData =  unitVersions.data.map((row: any) => ({
    ...row,
    key: row.id,
    name: row.item.name,
    ...(modalData.unitType === 'video'
      ? { duration: convertSecondTime(row.item.duration, 'HH:mm:ss') }
      : { questionsCount: `${row.item.questionsCount}問` }
    ),
  }));

  return (
    <>
      <Table
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [...versionSelected],
          onChange: (selectedRowKeys) => setVersionSelected([...selectedRowKeys]),
        }}
        columns={initialColumns}
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />
      <ModalFooter
        onSubmit={() => handleSubmitModal()}
        okText="&nbsp;反映&nbsp;"
        cancelText="キャンセル"
        messageText="バージョンを変更するとコンテンツ全体に反映されます"
        error={!isEmpty(selectItemError) && selectItemError}
        isConfirm
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { unitVersions } = state.unitReducer;
  return {
    unitVersions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getVersionList: (params) => dispatch(getVersionListAction(params)),
  selectUnitVersion: (params) => dispatch(selectUnitVersionAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SelectVersionModal);
