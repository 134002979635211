// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  ADMIN_COMPANY,
  REQUEST,
  MY_PROFILE_ACTION,
  SUCCESS,
  FAILURE,
  STUDENT,
  MASTER_DATA_ACTION,
} from "../constant";

const initialState = {
  isDisableRequest: false,
};

export type DisableRequestReducer = Readonly<typeof initialState>;

export default (
  state: DisableRequestReducer = initialState,
  action: any
): DisableRequestReducer => {
  switch (action.type) {
    case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST):
    case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY):
    case REQUEST(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY):
    case REQUEST(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER):
    case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER):
    case REQUEST(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_VERSION):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE):
    case REQUEST(ADMIN_SERVICE.UNIT_ACTION.CREATE):
    case REQUEST(ADMIN_SERVICE.UNIT_ACTION.UPDATE):
    case REQUEST(ADMIN_SERVICE.UNIT_ACTION.DELETE):
    case REQUEST(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION):
    case REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST):
    case REQUEST(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST):
    case REQUEST(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION):
    case REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION):
    case REQUEST(ADMIN_SERVICE.SURVEY_ACTION.CREATE_SURVEY):
    case REQUEST(ADMIN_SERVICE.TIP_ACTION.CREATE_TIP):
    case REQUEST(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ):
    case REQUEST(ADMIN_SERVICE.LICENSE_ACTION.CREATE_LICENSE):
    case REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.CREATE_CATEGORY):
    case REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY):
    case REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY):
    case REQUEST(ADMIN_SERVICE.TAG_ACTION.CREATE_TAG):
    case REQUEST(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG):
    case REQUEST(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG):
    case REQUEST(ADMIN_SERVICE.PLAN_ACTION.CREATE_PLAN):
    case REQUEST(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN):
    case REQUEST(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN):
    case REQUEST(ADMIN_SERVICE.TEACHER_ACTION.CREATE_TEACHER):
    case REQUEST(ADMIN_SERVICE.TEACHER_ACTION.DELETE_TEACHER):
    case REQUEST(ADMIN_SERVICE.TEACHER_ACTION.UPDATE_TEACHER):
    case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
    case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
    case REQUEST(ADMIN_SERVICE.TEST_ACTION.COPY_TEST):
    case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS):
    case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY):
    case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY):
    case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES):
    case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY):
    case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS):
    case REQUEST(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT):
    case REQUEST(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT):
    case REQUEST(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT):
    case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE):
    case REQUEST(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT):
    case REQUEST(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_TESTS):
    case REQUEST(MY_PROFILE_ACTION.UPDATE_PASSWORD_AND_EMAIL):
    case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL):
    case REQUEST(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_TESTS): {
      return {
        ...state,
        isDisableRequest: true,
      };
    }

    case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST):
    case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY):
    case SUCCESS(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY):
    case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST):
    case FAILURE(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY):
    case FAILURE(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY):
    case FAILURE(MY_PROFILE_ACTION.GET_TEST_STUDENT):
    case SUCCESS(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER):
    case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER):
    case FAILURE(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER):
    case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER):
    case FAILURE(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS):
    case SUCCESS(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_VERSION):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_VERSION):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER):
    case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.CREATE):
    case FAILURE(ADMIN_SERVICE.UNIT_ACTION.CREATE):
    case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION):
    case FAILURE(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION):
    case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.UPDATE):
    case SUCCESS(ADMIN_SERVICE.UNIT_ACTION.DELETE):
    case FAILURE(ADMIN_SERVICE.UNIT_ACTION.DELETE):
    case FAILURE(ADMIN_SERVICE.UNIT_ACTION.UPDATE):
    case SUCCESS(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST):
    case SUCCESS(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST):
    case SUCCESS(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION):
    case SUCCESS(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION):
    case FAILURE(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST):
    case FAILURE(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST):
    case FAILURE(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION):
    case FAILURE(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION):
    case SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.CREATE_SURVEY):
    case SUCCESS(ADMIN_SERVICE.TIP_ACTION.CREATE_TIP):
    case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ):
    case SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.CREATE_LICENSE):
    case FAILURE(ADMIN_SERVICE.SURVEY_ACTION.CREATE_SURVEY):
    case FAILURE(ADMIN_SERVICE.TIP_ACTION.CREATE_TIP):
    case FAILURE(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT):
    case FAILURE(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT):
    case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT):
    case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT):
    case FAILURE(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT):
    case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT):
    case FAILURE(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ):
    case FAILURE(ADMIN_SERVICE.LICENSE_ACTION.CREATE_LICENSE):
    case SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.CREATE_CATEGORY):
    case SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY):
    case SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY):
    case SUCCESS(ADMIN_SERVICE.TAG_ACTION.CREATE_TAG):
    case SUCCESS(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG):
    case SUCCESS(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG):
    case SUCCESS(ADMIN_SERVICE.PLAN_ACTION.CREATE_PLAN):
    case SUCCESS(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN):
    case SUCCESS(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN):
    case SUCCESS(ADMIN_SERVICE.TEACHER_ACTION.CREATE_TEACHER):
    case SUCCESS(ADMIN_SERVICE.TEACHER_ACTION.DELETE_TEACHER):
    case SUCCESS(ADMIN_SERVICE.TEACHER_ACTION.UPDATE_TEACHER):
    case FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.CREATE_CATEGORY):
    case FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY):
    case FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY):
    case FAILURE(ADMIN_SERVICE.TAG_ACTION.CREATE_TAG):
    case FAILURE(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG):
    case FAILURE(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG):
    case FAILURE(ADMIN_SERVICE.PLAN_ACTION.CREATE_PLAN):
    case FAILURE(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN):
    case FAILURE(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN):
    case FAILURE(ADMIN_SERVICE.TEACHER_ACTION.CREATE_TEACHER):
    case FAILURE(ADMIN_SERVICE.TEACHER_ACTION.DELETE_TEACHER):
    case FAILURE(ADMIN_SERVICE.TEACHER_ACTION.UPDATE_TEACHER):
    case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
    case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
    case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
    case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV):
    case SUCCESS(ADMIN_SERVICE.TEST_ACTION.COPY_TEST):
    case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS):
    case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY):
    case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY):
    case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES):
    case FAILURE(ADMIN_SERVICE.TEST_ACTION.COPY_TEST):
    case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS):
    case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY):
    case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY):
    case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES):
    case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY):
    case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS):
    case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY):
    case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS):
    case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE):
    case FAILURE(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE):
    case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE):
    case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT):
    case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT):
    case FAILURE(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT):
    case SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_TESTS):
    case FAILURE(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_TESTS):
    case FAILURE(MY_PROFILE_ACTION.UPDATE_PASSWORD_AND_EMAIL):
    case FAILURE(MY_PROFILE_ACTION.UPDATE_PASSWORD):
    case SUCCESS(MY_PROFILE_ACTION.UPDATE_EMAIL):
    case SUCCESS(MY_PROFILE_ACTION.UPDATE_PASSWORD_AND_EMAIL):
    case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL):
    case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL):
    case SUCCESS(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_TESTS):
    case FAILURE(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_TESTS): {
      return {
        ...state,
        isDisableRequest: false,
      };
    }

    default:
      return state;
  }
};
