// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";

import * as Style from "./styles";
import ModalFooter from "src/components/Modal/components/ModalFooter";
import { Text } from "src/components/styles";
import {
  updatePositionCourse as updatePositionCourseAction,
  closeModal as CloseModalAction,
} from "src/redux/actions";

interface DragDropCourseModalProps {
  companyId: string,
  updatePositionCourse: (params) => void;
  data: {
    id: number;
    courseCategory: {
      name: string;
    };
    name: string;
  }[];
  modalData: {
    title: string;
  };
  closeModalAction: () => void;
  CloseModal: () => {};
}

const DragDropCourseModal: React.FC<DragDropCourseModalProps> = ({
  data,
  companyId,
  updatePositionCourse,
  closeModalAction,
  CloseModal
 }) => {
  const [listCourses, setListCourses] = useState(data);

  const onDragEnd = (result: any) => {
    const {
      destination: { index: newIndex },
      source: { index: oldIndex },
      draggableId: courseId,
    } = result;
    const newListCourse = Array.from(listCourses);
    const [removed] = newListCourse.splice(oldIndex, 1);
    newListCourse.splice(newIndex, 0, removed);
    setListCourses(newListCourse)

    updatePositionCourse({
      requestData: {
        targetCourseId: listCourses[newIndex].id
      },
      courseId,
      companyId,
    });
  };

  return (
    <>
      <Style.CourseContent>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="courses">
            {(provided, snapshot) => (
              <Style.CourseDroppableBackground
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {listCourses.map((group, index) => (
                  <Draggable
                    key={group.id}
                    draggableId={`${group.id}`}
                    index={index}
                  >
                    {(chapterProvided, chapterSnapshot) => (
                      <Style.GroupContainer
                        key={group.id}
                        ref={chapterProvided.innerRef}
                        isLastChild={index + 1 === listCourses.length}
                        isDragging={chapterSnapshot.isDragging}
                        className="group-item"
                        {...chapterProvided.draggableProps}
                        {...chapterProvided.dragHandleProps}
                        style={{ ...chapterProvided.draggableProps.style }}
                      >
                        <Text xl w6>
                        {`${group.courseCategory.name} - バージョン : ${group.name}`}
                        </Text>
                      </Style.GroupContainer>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Style.CourseDroppableBackground>
            )}
          </Droppable>
        </DragDropContext>
      </Style.CourseContent>
      <ModalFooter
        cancelText="閉じる"
        onClose={() => {
          closeModalAction();
          CloseModal();
        }}
        isCloseAction
      />
    </>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  updatePositionCourse: (params) => dispatch(updatePositionCourseAction(params)),
  CloseModal: () => dispatch(CloseModalAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragDropCourseModal);
