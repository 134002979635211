// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import { isEmpty } from 'lodash/lang';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import {
  addFAQContent as addFAQContentAction,
  updateFAQContent as updateFAQContentAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModifyFAQContentModalProps extends StateProps, DispatchProps {
  addFAQContent(params): void;
  updateFAQContent(params): void;
  modifyFAQContent: {
    errors: {
      message: string;
    }[];
  };
  modalData: {
    title: string;
    actionType: string;
    faqId: string;
    position?: number;
    faqContent?: {
      id: number;
      question: string;
      answer: string;
    };
  };
}

const ModifyFAQContentModal: React.FC<IModifyFAQContentModalProps> = ({
  addFAQContent,
  updateFAQContent,
  modifyFAQContent,
  modalData,
}) => {
  const [modifyFAQContentForm] = Form.useForm();

  const faqInitialFormValues = {
    question: modalData.faqContent?.question,
    answer: modalData.faqContent?.answer,
  };

  useEffect(() => {
    if (!isEmpty(modifyFAQContent.errors)) {
      modifyFAQContentForm.setFields([{
        name: 'question',
        errors: [modifyFAQContent.errors[0].message],
      }]);
    }
  }, [modifyFAQContent.errors]);

  function handleSubmitForm(values) {
    if (modalData.actionType === 'add') {
      addFAQContent({
        faqId: modalData.faqId,
        requestData: {
          faqContent: {
            question: values.question.trim(),
            answer: values.answer.trim(),
            position: modalData.position,
          },
        },
      });
    } else {
      updateFAQContent({
        faqId: modalData.faqId,
        faqContentId: modalData.faqContent?.id,
        requestData: {
          faqContent: {
            question: values.question.trim(),
            answer: values.answer.trim(),
          },
        },
      });
    }
  }

  return (
    <Form
      form={modifyFAQContentForm}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 21 }}
      name={modalData.actionType === 'add' ? 'addFAQContentForm' : 'editFAQContentForm'}
      {...modalData.actionType === 'edit' && { initialValues: faqInitialFormValues }}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.ModifyFAQFieldContent>
        <Form.Item
          label="質問"
          name="question"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, whitespace: true, message: '質問は必須項目です。' },
            { max: 1000, message: '質問が長すぎます。（最大は1000桁です）' },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item
          label="回答"
          name="answer"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, whitespace: true, message: '回答は必須項目です。' },
            { max: 1000, message: '回答が長すぎます。（最大は1000桁です）' },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 8 }} />
        </Form.Item>
      </Style.ModifyFAQFieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { modifyFAQContent } = state.faqReducer;
  return {
    modifyFAQContent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  addFAQContent: (params) => dispatch(addFAQContentAction(params)),
  updateFAQContent: (params) => dispatch(updateFAQContentAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyFAQContentModal);
