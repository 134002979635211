// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const ProcessLoading = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 3px;
  background-color: ${(props) => props.theme.colors.primary};
  z-index: 1001;
  animation: loader 1.5s ease;
  animation-fill-mode: forwards;

  @keyframes loader {
    0% {
      width: 0;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 80%;
    }
  }

  ${(props) => props.isDone
    && css`
      animation: done 0.5s ease;

      @keyframes done {
        0% {
          width: 80%;
        }
        100% {
          width: 100%;
        }
      }
    `}
`;
