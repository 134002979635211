// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Space } from 'antd';
import { SkeletonLoading, Text } from 'src/components/styles';


export const TestOverviewContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const TestOverviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};

  @media (max-width: 428px) {
    min-height: 50px;
    height: auto;
    padding: 16px;
  }
`;

export const  TestOverviewDescription = styled.div`
  padding: 16px;
  background-color: white;
`;

export const TestOverviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};

  @media (max-width: 428px) {
    flex-direction: column;

    .join-test {
      width: 260px;
      margin-top: 24px;
      font-weight: 700;
    }
}
`;

export const InfoTestContainer = styled.div`
  display: flex;
  @media (max-width: 428px) {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    .ant-space-item {
      margin-right: 0 !important;
    }
}`;

export const InfoTest = styled(Space)`
  margin-right: 32px;

  @media (max-width: 428px) {
    flex-direction: column;
    flex: 1;
    margin-right: 0;
}`;

export const LabelInfoTest = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 300;
    margin-right: 0 !important;
}
`;

export const ValueInfoTest = styled(Text)`
  @media (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
}
`;

export const ButtonContainer = styled.div`
  @media (max-width: 428px) {
    flex-basis: 100%;
    margin-top: 16px;
    margin-bottom: -24px;
    text-align: center;

    & > button {
      font-weight: 700;
    }
}`;

export const CourseName = styled(Text)`
  @media (max-width: 428px) {
    padding: 26px 16px 0 16px;
    font-size: 20px;
    font-weight: 700;
}
`;

export const CourseNameLoading = styled(SkeletonLoading)`
  @media (max-width: 428px) {
    padding-top: 26px;
    padding-left: 16px;
}
`;

export const TitleTest = styled(Text)`
  @media (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
    overflow: unset;
    text-overflow: unset;
    white-space: pre-line;
  }
`;

export const NoteTest = styled(Text)`
  @media (max-width: 428px) {
    font-size: 14px;
    font-weight: 700;
}
`;
