// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs, Space } from 'antd';

import TestOverview from '../components/TestOverview';

import { Text } from 'src/components/styles';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getTagList as getTagListAction,
  getCategoryList as getCategoryListAction,
  createTest as createTestAction,
} from 'src/redux/actions';

import * as Style from '../styles';

interface ICreateTestProps extends StateProps, DispatchProps {
  getTagList(): void;
  getCategoryList(): void;
  createTest(params): void;
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
}

const CreateTest: React.FC<ICreateTestProps> = ({
  getTagList,
  getCategoryList,
  createTest,
  tagList,
  categoryList,
}) => {

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CreateTest);

    getTagList();
    getCategoryList();
  }, []);

  return (
    <>
      <Style.ModifyTestTitle>
        <Space align="center" size={32}>
          <Text headerText xxxl w6>テスト新規作成</Text>
        </Space>
      </Style.ModifyTestTitle>
      <Tabs type="card">
        <Tabs.TabPane
          tab={<Text xl headerText w6>テスト概要</Text>}
          key="1"
        >
          <TestOverview
            tagList={tagList}
            categoryList={categoryList}
            createTest={createTest}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<Text xl headerText w6>問設定</Text>}
          disabled
        />
      </Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  return {
    tagList,
    categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTagList: () => dispatch(getTagListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  createTest: (params) => dispatch(createTestAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CreateTest);
