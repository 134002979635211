// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { Space } from 'antd';
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';

export const LicenseOverviewContainer = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
`;

export const ModifyLicenseContainer = styled.div`
  padding: 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: white;
`;

export const ModifyLicenseTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.adminBackground};
`;

export const DynamicInputField = styled.div`
  display: flex;
`;

export const FieldRemoveAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
  min-width: 28px;
  height: 38px;
`;

export const ModifyLicenseAction = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
`;

export const SubmitActionContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${(props) =>
    props.hasSubText &&
    css`
      justify-content: space-between;
    `}
`;

export const SettingRecommendDateWrapper = styled(Space)`
  width: 100%;
  margin: 24px 0 0px 100px;
`;

export const TextCustom = styled(Text)`
  margin-top: 10px;
`
