// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Button,
  Table,
  Tooltip,
  Space,
} from 'antd';
import moment from 'moment';
import { isEmpty, isNumber } from 'lodash/lang';

import history from 'src/utils/history';
import { convertSecondTime } from 'src/utils/dateTime';
import { LoginStorage } from 'src/utils/localStorge';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';

import * as Style from './styles';

interface IStudentListProps {
  statisticStudents: {
    data: {
      studentList: {
        id: number;
        jituName: string;
        company: {
          contractName: string;
        };
        department: string;
        jobType: string;
        jobTitle: string;
        customUserInfos: {
          companyCustomUserInfoId: number;
          fieldValue: string;
        }[];
        lastLearningDate: string;
        registerDate: string
        numberOfCourses: number;
        courses: {
          courseCategory: {
            name: string;
          };
          name: string;
        }[];
        totalLearningTime: number;
        entireCoursesHighestTestScore: string;
      }[];
      companyCustomInfos: {
        id: number;
        fieldName: string;
        isDisplay: boolean;
      }[];
      customDisplayField: {
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    load: boolean;
  };
}

const StudentList: React.FC<IStudentListProps> = ({
  statisticStudents,
}) => {
  const { data } = statisticStudents;
  const userStorage = LoginStorage.getData();

  function renderCoursesOfUser(coursesNames) {
    if (isEmpty(coursesNames)) return null;
    return coursesNames.map((name, courseIndex) => (
      <Text white key={`course-name-${courseIndex}`}>・{name}</Text>
    ));
  }

  function getListCourseName(courses) {
    if (isEmpty(courses)) return [];
    return courses.map((course) => `${course.courseCategory.name}`);
  }

  const tableData = data.studentList.map((student: any) => {
    const customInfoData = {};
    student.customUserInfos?.map((info) => {
      customInfoData[`item${info.companyCustomUserInfoId}`] = info.fieldValue;
      return null;
    });

    return {
      key: student.id,
      jituName: student.jituName,
      numberOfCourses: student.numberOfCourses,
      coursesNames: getListCourseName(student.courses),
      ...data.customDisplayField.department && { department: student.department },
      ...data.customDisplayField.jobType && { jobType: student.jobType },
      ...data.customDisplayField.jobTitle && { jobTitle: student.jobTitle },
      ...customInfoData,
      lastLearningDate: student.lastLearningDate ? moment(student.lastLearningDate).format(DATE_FORMAT) : '',
      registerDate: student.registerDate ? moment(student.registerDate).format(DATE_FORMAT) : '',
      totalLearningTime: convertSecondTime(student.totalLearningTime, 'H時間m分'),
      entireCoursesHighestTestScore: student.entireCoursesHighestTestScore,
    };
  });

  const displayCompanyCustomInfos = data.companyCustomInfos.filter((info) => info.isDisplay);
  const customInfoColumns = displayCompanyCustomInfos.map((info) => ({
    title: info.fieldName,
    dataIndex: `item${info.id}`,
    render: (_, record) => (
      <TooltipParagraph isPreWrap rows={2}>
        {record[`item${info.id}`]}
      </TooltipParagraph>
    ),
    ellipsis: true,
    width: 200,
  }));

  const columns = [
    {
      title: '名前',
      dataIndex: 'jituName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.jituName}
        </TooltipParagraph>
      ),
      width: 250,
    },
    {
      title: 'コース数',
      dataIndex: 'numberOfCourses',
      render: (_, record) => (
        <Tooltip
          placement="right"
          title={renderCoursesOfUser(record.coursesNames)}
          overlayStyle={{ maxHeight: 600, overflow: 'scroll'}}
        >
          <Text>{record.numberOfCourses}</Text>
        </Tooltip>
      ),
      width: 100,
    },
    ...data.customDisplayField.department
      ? [{
        title: '部署',
        dataIndex: 'department',
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.department}
          </TooltipParagraph>
        ),
        width: 200,
      }]
      : [],
    ...data.customDisplayField.jobType
      ? [{
        title: '役職',
        dataIndex: 'jobTitle',
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.jobTitle}
          </TooltipParagraph>
        ),
        width: 200,
      }]
      : [],
    ...data.customDisplayField.jobTitle
      ? [{
        title: '職種',
        dataIndex: 'jobType',
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.jobType}
          </TooltipParagraph>
        ),
        width: 200,
      }]
      : [],
    ...customInfoColumns,
    {
      title: '最終学習日',
      dataIndex: 'lastLearningDate',
      width: 150,
    },
    {
      title: '学習時間',
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title: (
        <>
          <Text white style={{ marginLeft: '2px' }}>テスト正答率</Text>
          <Text white style={{ marginLeft: '-10px' }}>（全テスト平均）</Text>
        </>
      ),
      dataIndex: 'entireCoursesHighestTestScore',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>
            {isNumber(record.entireCoursesHighestTestScore) ? `${record.entireCoursesHighestTestScore}%` : 'ー'}
          </Text>
        </Space>
      ),
      width: 180,
    },
    {
      title: '登録日',
      dataIndex: 'registerDate',
      width: 150,
    },
  ];

  function redirectCompanyStudentList() {
    return history.push({
      pathname: '/admin/company-detail',
      state: { key: '3' }, // activated student tab in company detail screen
    });
  }

  const scrollPoint = 980
    + (data.customDisplayField.department ? 200 : 0)
    + (data.customDisplayField.jobType ? 200 : 0)
    + (data.customDisplayField.jobTitle ? 200 : 0)
    + displayCompanyCustomInfos.length * 200;

  return (
    <>
      <Style.StatisticStudentsTitle>
        <Text xxxl>新規登録ユーザー</Text>
        <Button
          type="primary"
          size="small"
          onClick={() => redirectCompanyStudentList()}
        >
          一覧を見る
        </Button>
      </Style.StatisticStudentsTitle>
      <Style.StudentTableContainer>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          scroll={{ x: scrollPoint }}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {
              const urlProfile = userStorage.user.id === record.key ? 'my-profile' : `profile/${record.key}`;
              history.push(`/admin/${urlProfile}`);
            } 
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={statisticStudents.load}
        />
      </Style.StudentTableContainer>
    </>
  );
};

export default StudentList;
