// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import {
  NOTIFICATION_ACTION,
  SUCCESS,
  FAILURE,
  REQUEST,
  LOADING_ACTION,
} from '../constant';

import { notificationServices } from 'src/services';

function* getNotificationUnreadSaga() {
  try {
    const result = yield notificationServices.getNotificationUnread();
    yield put({
      type: SUCCESS(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD), errors: e.errors });
  }
}

function* getNotificationListSaga(action) {
  try {
    const result = yield notificationServices.getNotificationList(action.payload);
    yield put({
      type: SUCCESS(NOTIFICATION_ACTION.GET_NOTIFICATION_LIST),
      payload: result,
    });
    yield put({ type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD) });
  } catch (e) {
    yield put({ type: FAILURE(NOTIFICATION_ACTION.GET_NOTIFICATION_LIST), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getDropdownNotificationsSaga(action) {
  try {
    const result = yield notificationServices.getNotificationList(action.payload);
    const { data, meta } = result;
    yield put({
      type: SUCCESS(NOTIFICATION_ACTION.GET_DROPDOWN_NOTIFICATIONS),
      payload: {
        data,
        meta,
        more: !!action.payload.more,
      },
    });
    yield put({ type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD) });
  } catch (e) {
    yield put({ type: FAILURE(NOTIFICATION_ACTION.GET_DROPDOWN_NOTIFICATIONS), errors: e.errors });
  }
}

function* markNotificationsAsReadSaga() {
  try {
    yield notificationServices.markNotificationsAsRead();
    yield put({ type: SUCCESS(NOTIFICATION_ACTION.MARK_NOTIFICATIONS_AS_READ) });
    yield put({ type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD) });
  } catch (e) {
    yield put({ type: FAILURE(NOTIFICATION_ACTION.MARK_NOTIFICATIONS_AS_READ), errors: e.errors });
  }
}

export default function* notificationSaga() {
  yield all([
    takeLatest(REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD), getNotificationUnreadSaga),
    takeLatest(REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_LIST), getNotificationListSaga),
    takeLatest(REQUEST(NOTIFICATION_ACTION.GET_DROPDOWN_NOTIFICATIONS), getDropdownNotificationsSaga),
    takeLatest(REQUEST(NOTIFICATION_ACTION.MARK_NOTIFICATIONS_AS_READ), markNotificationsAsReadSaga),
  ]);
}
