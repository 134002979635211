// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Input,
  Spin,
  Select,
  Form
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { find } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

import { convertSecondTime, formatDateTime } from 'src/utils/dateTime';

import NoData from 'src/components/layouts/NoData';
import ModalFooter from 'src/components/Modal/components/ModalFooter';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { UNIT_TYPE, DATE_FORMAT } from 'src/constants/common';
import { getFilterOption } from 'src/utils/common';

import {
  getUnitItems as getUnitItemsAction,
  getCategoryList as getCategoryListAction,
  selectItemVersion as selectItemVersionAction,
  createUnitVersion as createUnitVersionAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface ICreateVersionModalProps extends StateProps, DispatchProps {
  getUnitItems(params): void;
  selectItemVersion(params): void;
  createUnitVersion(params): void;
  getCategoryList(): void;
  modalData: {
    unitType: string;
    unitId: number;
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  unitVersions: {
    errors: {
      message: string;
    }[];
  };
  unitItems: {
    data: {
      id: number;
      name: string;
      category: any;
      updatedAt: string;
      duration?: number;
      questionsCount?: number;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        page: number;
        next: number;
      };
    };
    type: string;
    load: boolean;
  };
}

const CreateVersionModal: React.FC<ICreateVersionModalProps> = ({
  getUnitItems,
  getCategoryList,
  selectItemVersion,
  createUnitVersion,
  modalData,
  categoryList,
  unitVersions,
  unitItems,
}) => {
  const [itemSelected, setItemSelected] = useState<any>({});
  const [selectItemError, setSelectItemError] = useState<any>('');
  const [categorySelected, setCategorySelected] = useState<any>(undefined);
  const [filterByName, setFilterByName] = useState<string | undefined>(undefined);
  const [filterByCategory, setFilterByCategory] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  useEffect(() => {
    getUnitItems({  
      requestData: {
        page: 1,
      },
      unitType: modalData.unitType,
    });
  }, []);

  useEffect(() => {
    unitItems.meta.pageInfo?.next && setPage(unitItems.meta.pageInfo.next)
  }, [unitItems.meta.pageInfo])

  useEffect(() => {
    setPage(1)
  }, [modalData.unitType])
  function handleSubmitModal() {
    if (isEmpty(itemSelected)) {
      return setSelectItemError('コンテンツは必須項目です。');
    }
    setSelectItemError('');
    if (modalData.unitId) {
      return createUnitVersion({
        requestData: {
          unitVersion: {
            itemType: modalData.unitType,
            itemId: itemSelected.id,
          },
        },
        unitId: modalData.unitId,
      });
    }
    return selectItemVersion({ item: itemSelected });
  }

  function handleSelectUnitItem(e) {
    const { value } = e.target;
    setItemSelected(find(unitItems.data, { id: parseFloat(value) }));
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>
        {category.name}
      </Select.Option>
    ));
  }

  function handleSubmitForm(value) {
    value.FilterByCategory ? setFilterByCategory(value.FilterByCategory) : setCategorySelected(undefined)
    value.CategorySelected ? setCategorySelected(value.CategorySelected) : setCategorySelected(undefined)
    value.name ? setFilterByName(value.name) : setFilterByName("")

    const requestData = {
      nameCont: value.name ? value.name.trim() : '',
      ...value.CategorySelected && { categoryIdEq: value.CategorySelected },
      ...value.FilterByCategory && { categoryCont: value.FilterByCategory ? value.FilterByCategory.trim() : '' },
      page: 1,
    };
    getUnitItems({ requestData, unitType: modalData.unitType })
    setPage(1);
  }

  function renderUnitItemsHeader() {
    switch (modalData.unitType) {
    case 'video': {
      return (
        <Style.UnitItemHeaderRow>
          <Style.UnitItemHeaderCol width="5%" />
          <Style.UnitItemHeaderCol width="40%">
            <Text white>コンテンツ名</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="15%">
            <Text white>講座タイプ</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="15%">
            <Text white>動画時間</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="15%">
            <Text white>登録日</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="10%" />
        </Style.UnitItemHeaderRow>
      );
    }
    case 'test': {
      return (
        <Style.UnitItemHeaderRow>
          <Style.UnitItemHeaderCol width="5%" />
          <Style.UnitItemHeaderCol width="40%">
            <Text white>コンテンツ名</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="15%">
            <Text white>講座タイプ</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="15%">
            <Text white>問題数</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="15%">
            <Text white>登録日</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="10%" />
        </Style.UnitItemHeaderRow>
      );
    }
    case 'tip':
    case 'survey': {
      return (
        <Style.UnitItemHeaderRow>
          <Style.UnitItemHeaderCol width="5%" />
          <Style.UnitItemHeaderCol width="45%">
            <Text white>コンテンツ名</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="20%">
            <Text white>講座タイプ</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="20%">
            <Text white>登録日</Text>
          </Style.UnitItemHeaderCol>
          <Style.UnitItemHeaderCol width="10%" />
        </Style.UnitItemHeaderRow>
      );
    }
    default:
      return null;
    }
  }

  function renderUnitItemListContainer() {
    if (isEmpty(unitItems.data)) {
      return <NoData message="データが存在しません。" />;
    }
    return unitItems.data.map((unitItem, unitItemIndex) => {
      switch (modalData.unitType) {
      case 'video': {
        return (
          <Style.UnitItemListRow
            key={`unit-item-${unitItemIndex}-${unitItem.id}`}
            active={unitItem.id === itemSelected.id}
          >
            <Style.UnitItemListCol width="5%">
              <Style.RadioCustom>
                <input
                  type="radio"
                  name="unitItem"
                  value={unitItem.id}
                  onChange={(e) => {
                    handleSelectUnitItem(e);
                  }}
                />
                <span />
              </Style.RadioCustom>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="40%">
              <TooltipParagraph isPreWrap rows={2}>{unitItem.name}</TooltipParagraph>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="15%">
              <TooltipParagraph isPreWrap rows={2}>
                {unitItem.category?.name ? unitItem.category?.name : unitItem.category}
              </TooltipParagraph>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="15%">
              <Text>{convertSecondTime(unitItem.duration, 'HH:mm:ss')}</Text>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="15%">
              <Text>{formatDateTime(unitItem.updatedAt, DATE_FORMAT)}</Text>
            </Style.UnitItemListCol>
            <Style.TableAction style={{ width: '10%' }}>
              <Button size="small" type="primary" ghost>&nbsp;詳細&nbsp;</Button>
            </Style.TableAction>
          </Style.UnitItemListRow>
        );
      }
      case 'test': {
        return (
          <Style.UnitItemListRow
            key={`unit-item-${unitItemIndex}-${unitItem.id}`}
            active={unitItem.id === itemSelected.id}
          >
            <Style.UnitItemListCol width="5%">
              <Style.RadioCustom>
                <input
                  type="radio"
                  name="unitItem"
                  value={unitItem.id}
                  onChange={(e) => handleSelectUnitItem(e)}
                />
                <span />
              </Style.RadioCustom>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="40%">
              <TooltipParagraph isPreWrap rows={2}>{unitItem.name}</TooltipParagraph>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="15%">
              <TooltipParagraph isPreWrap rows={2}>{unitItem.category?.name}</TooltipParagraph>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="15%">
              <Text>{unitItem.questionsCount}</Text>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="15%">
              <Text>{formatDateTime(unitItem.updatedAt, DATE_FORMAT)}</Text>
            </Style.UnitItemListCol>
            <Style.TableAction style={{ width: '10%' }}>
              <Button
                size="small"
                type="primary"
                ghost
                onClick={() => window.open(
                  ` /admin-service/test/${unitItem.id}/edit`,
                  '_blank',
                )}
              >
                &nbsp;詳細&nbsp;
              </Button>
            </Style.TableAction>
          </Style.UnitItemListRow>
        );
      }
      case 'tip':
      case 'survey': {
        return (
          <Style.UnitItemListRow
            key={`unit-item-${unitItemIndex}-${unitItem.id}`}
            active={unitItem.id === itemSelected.id}
          >
            <Style.UnitItemListCol width="5%">
              <Style.RadioCustom>
                <input
                  type="radio"
                  name="unitItem"
                  value={unitItem.id}
                  onChange={(e) => handleSelectUnitItem(e)}
                />
                <span />
              </Style.RadioCustom>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="45%">
              <TooltipParagraph isPreWrap rows={2}>{unitItem.name}</TooltipParagraph>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="20%">
              <TooltipParagraph isPreWrap rows={2}>{unitItem.category?.name}</TooltipParagraph>
            </Style.UnitItemListCol>
            <Style.UnitItemListCol width="20%">
              <Text>{formatDateTime(unitItem.updatedAt, DATE_FORMAT)}</Text>
            </Style.UnitItemListCol>
            <Style.TableAction style={{ width: '10%' }}>
              <Button
                size="small"
                type="primary"
                ghost
                onClick={() => window.open(
                  `/admin-service/${modalData.unitType}/${unitItem.id}/edit`,
                  '_blank',
                )}
              >
                &nbsp;詳細&nbsp;
              </Button>
            </Style.TableAction>
          </Style.UnitItemListRow>
        );
      }
      default:
        return null;
      }
    });
  }

  return (
    <>
      <Style.TypeFieldContent>
        <Text style={{ width: 120 }}>コンテンツタイプ</Text>
        <Input disabled value={UNIT_TYPE[modalData.unitType]} style={{ marginBottom: 8, width: 180 }} />
      </Style.TypeFieldContent>
      <Form
        onFinish={handleSubmitForm}
        style={{ paddingBottom: "0" }}>
      <Style.TypeFieldWrapper>
        <Style.SearchFieldContent>
          <Text style={{ width: 120 }} margin={"0px 0px 24px 0"}>講座タイプ</Text>
          {modalData.unitType === 'video'
            ? (
              <Form.Item
                name="FilterByCategory"
                >
                <Input
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter') {
                      const { value } = e.target;
                      getUnitItems({
                        requestData: {
                          nameCont: filterByName ? filterByName.trim() : '',
                          categoryCont: value ? value.trim() : '',
                          page: 1,
                        },
                        unitType: modalData.unitType,
                      });
                    }
                  }}
                  style={{ width: 180 }}
                />
              </Form.Item>
              
            )
            : (
              <Form.Item
                name="CategorySelected"
                >
                <Select
                allowClear
                showSearch
                suffixIcon={<CaretDownOutlined />}
                placeholder="選択してください"
                notFoundContent="データが存在しません。"
                filterOption={getFilterOption}
                style={{ width: 180 }}
                >
                  {renderCategoryOptions()}
                </Select>
              </Form.Item>
            )
          }
        </Style.SearchFieldContent>
        <Style.SearchFieldContent>
          <Text style={{ width: 120 }} margin={"0px 0px 24px 0"}>コンテンツ名</Text>
          <Form.Item
            name="name"
          >
            <Input
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  const { value } = e.target;
                  getUnitItems({
                    requestData: {
                      nameCont: value ? value.trim() : '',
                      ...categorySelected && { categoryIdEq: categorySelected },
                      ...filterByCategory && { categoryCont: filterByCategory ? filterByCategory.trim() : '' },
                      page: 1,
                    },
                    unitType: modalData.unitType,
                  });
                }
              }}
              style={{ width: 180 }}
            />
          </Form.Item>
        </Style.SearchFieldContent>
        <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
        >
          絞り込む
        </Button>
        </Form.Item>
      </Style.TypeFieldWrapper>
      </Form>
      <Spin spinning={unitItems.load}>
        {renderUnitItemsHeader()}
        <Style.UnitItemListContainer>
          <InfiniteScroll
            pageStart={0}
            hasMore={!!unitItems.meta.pageInfo?.next && !unitItems.load && page !==1 }
            useWindow={false}
            loadMore={() => {
              if (!!unitItems.meta.pageInfo?.next && !unitItems.load && page !==1 ) {
                getUnitItems({
                  requestData: {
                    nameCont: filterByName ? filterByName.trim() : '',
                    ...categorySelected && { categoryIdEq: categorySelected },
                    ...filterByCategory && { categoryCont: filterByCategory ? filterByCategory.trim() : '' },
                    page: page,
                  },
                  unitType: modalData.unitType,
                  more: true,
                });
              }
            }}
          >
            {renderUnitItemListContainer()}
          </InfiniteScroll>
        </Style.UnitItemListContainer>
      </Spin>
      <ModalFooter
        onSubmit={() => handleSubmitModal()}
        okText="追加する"
        cancelText="キャンセル"
        error={!isEmpty(unitVersions.errors)
          ? (unitVersions.errors[0] || {}).message
          : (!isEmpty(selectItemError) ? selectItemError : null)
        }
        isConfirm
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { unitVersions, unitItems } = state.unitReducer;
  const { categoryList } = state.categoryReducer;
  return {
    unitVersions,
    unitItems,
    categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUnitItems: (params) => dispatch(getUnitItemsAction(params)),
  getCategoryList: () => dispatch(getCategoryListAction()),
  selectItemVersion: (params) => dispatch(selectItemVersionAction(params)),
  createUnitVersion: (params) => dispatch(createUnitVersionAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CreateVersionModal);
