// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';

import history from 'src/utils/history';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import { Text } from 'src/components/styles';

import {
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IFooterLayoutProps extends DispatchProps {
  showModal(params): void;
  closeModal(): void;
  role: string;
}

const FooterLayout: React.FC<IFooterLayoutProps> = ({
  showModal,
  closeModal,
  role,
}) => {
  const isStudent = role === 'student';
  const isCompany = role === 'admin_company';

  function handleRedirectLink(path) {
    const pathnameSplit = history.location.pathname.split('/');
    const isTesting = pathnameSplit[3]?.includes('testing');
    if (isTesting) {
      const modalProps = {
        isConfirm: true,
        modalData: {
          message: 'テストを途中で修了するとテスト結果が保存されない可能性があります。テストを中断しますか？',
          title: 'テスト中断確認',
        },
        modalAction: {
          confirm: () => {
            closeModal();
            return history.push(path);
          },
        },
      };
      return showModal(<ConfirmModal {...modalProps} />);
    } else {
      return history.push(path);
    }
  }

  function renderFooterLinks() {
    if (isStudent || isCompany) {
      return (
        <Style.FooterLinks>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/company/', '_blank')}
            >
              <Text
                white
                pointer
              >
                会社概要
              </Text>
            </Style.FooterItem>
            <Style.FooterItem
              onClick={() => window.open('https://share.hsforms.com/118qwvfYSTH6mTxLq8hFoJg2mtef', '_blank')}
            >
              <Text
                white
                pointer
              >
                LMSに関するお問い合わせ
              </Text>
            </Style.FooterItem>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/lms-scai-terms-of-use/', '_blank')}
            >
              <Text
                white
                pointer
              >
                利用規約
              </Text>
            </Style.FooterItem>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/privacy/', '_blank')}
            >
              <Text
                white
                pointer
              >
                プライバシーポリシー
              </Text>
            </Style.FooterItem>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/security-policy/', '_blank')}
            >
              <Text
                white
                pointer
              >
                情報セキュリティーに関する方針
              </Text>
            </Style.FooterItem>
        </Style.FooterLinks>
      );
    }
    return null;
  }

  return (
    <Style.FooterContainer isStudent={isStudent}>
      <Style.FooterContent>
        {renderFooterLinks()}
        <Style.CopyrightContent>
          <Style.TextFooter white sm w3><span>©</span>SKILLUP NeXt, Ltd.</Style.TextFooter>
        </Style.CopyrightContent>
      </Style.FooterContent>
    </Style.FooterContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(FooterLayout);
