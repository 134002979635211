// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';
import moment from 'moment';

export function getSurveyList(params) {
  const newParams = {
    updatedAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    updatedAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    categoryIdEq: params?.category,
    nameCont: (params.name || '').trim(),
    page: params?.page,
    sort: params.sort,
    items: params?.items,
  };
  return {
    type: REQUEST(ADMIN_SERVICE.SURVEY_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getSurveyDetail(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.SURVEY_ACTION.GET_DETAIL),
    payload,
  };
}

export function createSurvey(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.SURVEY_ACTION.CREATE_SURVEY),
    payload,
  };
}

export function updateSurvey(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.SURVEY_ACTION.UPDATE_SURVEY),
    payload,
  };
}
