// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { ReactNode } from 'react';

import * as Style from './styles';

interface IHeaderContentProps {
  children: ReactNode;
  height?: string;
}

const TopContent: React.FC<IHeaderContentProps> = ({ children, height }) => (
  <Style.TopContent height={height}>
    {children}
  </Style.TopContent>
);

export default TopContent;
