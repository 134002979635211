// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import { closeModal as closeModalAction } from 'src/redux/actions';

import * as Style from './styles';

import iconUploadSuccess from 'src/assets/images/modalImage/upload-success.svg';

interface IStatusUploadModalProps extends DispatchProps {
  closeModal(): void;
  modalData: {
    title: string;
    message: string;
    status: string;
  }
}

const StatusUploadModal: React.FC<IStatusUploadModalProps> = ({
  closeModal,
  modalData,
}) => (
  <Style.StatusUploadContainer>
    <Style.StatusUploadContent>
      <Style.Message w3>{modalData.message}</Style.Message>
      {
        modalData.status === 'success' && (
          <img src={iconUploadSuccess} alt="iconUploadSuccess" draggable={false} />
        )
      }
    </Style.StatusUploadContent>
    <Style.StatusUploadFooter>
      <Button type="primary" className="btn-secondary" onClick={() => closeModal()}>
        閉じる
      </Button>
    </Style.StatusUploadFooter>
  </Style.StatusUploadContainer>
);

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(StatusUploadModal);
