// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export default {
  breakpoints: {
    xs: '0',
    sm: '400px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1920px',
  },
  colors: {
    primary: 'rgb(210, 156, 9)', // #D29C09
    primary75: 'rgba(210, 156, 9, 0.75)',
    primary50: 'rgba(210, 156, 9, 0.5)',
    primary25: 'rgba(210, 156, 9, 0.25)',
    primary20: "rgba(210, 156, 9, 0.2)",
    primary15: "rgba(13, 162, 254, 0.15)",
    primaryText: '#343A40',
    white: "#FFFFFF",
    secondaryText: '#666666',
    headerText: '#333333',
    subText: '#999999',
    primaryIcon: '#CCCCCC',
    adminBackground: '#0D122D',
    userBackground: '#333333',
    primaryBackground: '#F8F9FF',
    secondaryBackground: '#FAFAFA',
    secondaryBackground45: "rgba(183, 183, 183, 0.45)",
    secondaryBorder45: "#B7B7B7",
    tertiaryBackground: '#F6F6F6',
    primaryBorder: '#CCCCCC',
    secondaryBorder: '#EEEEEE',
    secondaryBorder63: "#636363",
    tertiaryBorder: '#999999',
    primary15Border: "#0DA2FE",

    success: '#5FC73A',
    error: '#D25109',
    warning: '#F2994A',
    info: '#36ADD3',
  },
  fontWeight: {
    w3: '300',
    w5: '500',
    w6: '600',
    w7: '700',
  },
  fontSize: {
    xxxs: '8px',
    xxs: '10px',
    xs: '12px',
    sm: '13px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    xxl: '22px',
    xxxl: '24px',
    h3: '32px',
    h2: '45px',
    h1: '52px',
  },
  lineHeight: {
    xxs: '16px',
    xs: '18px',
    sm: '19px',
    md: '22px',
    lg: '24px',
    xl: '26px',
    xxl: '30px',
    xxxl: '32px',
    h3: '50px',
    h2: '67px',
    h1: '78px',
  },
  radius: {
    sm: '2px',
    md: '4px',
    lg: '8px',
    circle: '999px',
  },
  shadow: {
    top: '0 -4px 8px -4px rgb(216, 216, 216)',
    bottom: '0px 4px 8px -4px rgb(216, 216, 216)',
    sm: '3px 6px 10px 0px rgba(0, 0, 0, 0.1);',
    md: '0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)',
    lg: '3px 6px 10px 0px rgba(0, 0, 0, 0.3)',
  },
};
