// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Button,
  Collapse,
  Spin,
} from 'antd';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';

import { DATE_FORMAT, TEST_TYPE } from 'src/constants/common';
import { MainWrapper } from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles/Texts';
import { TEST_TYPE_URL } from 'src/constants/common';
import { handleStopPropagation } from 'src/utils/common';

import * as Style from './styles';

interface ITestListProps {
  getTestResults(params): void;
  courseId?: string;
  testsData: {
    id: number;
    item: {
      id: number;
      name: string;
      questionsCount: number;
      testType: string;
      testHistories: {
        completionStatus: string;
        testScorePercentage: number;
        createdAt: string;
        score: number;
      }[];
    };
    unit: {
      name: string;
      category: {
        name: string;
      };
    };
  }[];
  loading: boolean;
}

const TestList: React.FC<ITestListProps> = ({
  getTestResults,
  testsData,
  courseId,
  loading,
}) => {

  function handleJoinTest(e, test) {
    handleStopPropagation(e);
    return window.open(`/learn-course/${courseId}/${TEST_TYPE_URL[test.item.testType]}/${test.id}`);
  }

  function handleShowTestResult(e, item, testType) {
    handleStopPropagation(e);
    getTestResults({ courseId, testType, requestData: { testsUserId: item.id } });
  }

  function renderHistoriesTest(test) {
    const historiesTests = test.item.testHistories.slice(1);
    if (isEmpty(historiesTests)) {
      return (
        <Style.NoHistoryData>
          データが存在しません。
        </Style.NoHistoryData>
      );
    }
    return historiesTests.map((record) => (
      <Style.CollapseItemContainer key={`test-user-${record.id}`} isChild>
        <Style.CollapseItemContent width="50%" />
        <Style.CollapseItemContent width="14%">
          {record.completionStatus !== 'waiting'
            ? (
              <Text lg>
                {`${record.score}点（${record.testScorePercentage}%）`}
              </Text>
            ) : <Text lg>採点待ち</Text>
          }
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="14%">
          <Text lg>
            {moment(record.createdAt).format(DATE_FORMAT)}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent isActionButton>
          <Style.GroupButton>
            <Button
              className="collapse-header-button"
              size="small"
              type="primary"
              ghost
              onClick={(e) => handleShowTestResult(e, record, test.item.testType)}
            >
              テスト結果確認
            </Button>
          </Style.GroupButton>
        </Style.CollapseItemContent>
      </Style.CollapseItemContainer>
    ));
  }

  function renderTestItems() {
    return testsData.map((test, unitIndex) => (
      <Collapse.Panel
        key={`$history-${unitIndex}`}
        header={
          <Style.CollapseItemContainer key={`test-${test.id}`}>
            <Style.CollapseItemContent width="10%">
              <Text lg w6>
                {TEST_TYPE[test.item.testType]}
              </Text>
            </Style.CollapseItemContent>
            <Style.CollapseItemContent width="30%">
              <Text
                lg
                isPreWrap
                style={{ lineHeight: '20px', width: 'calc(100% - 24px)' }}
              >
                {test.unit.name}
              </Text>
            </Style.CollapseItemContent>
            <Style.CollapseItemContent width="10%">
              <Text lg w6>
                {test.item.questionsCount > 0 ? `${test.item.questionsCount}問` : 'ー'}
              </Text>
            </Style.CollapseItemContent>
            <Style.CollapseItemContent width="14%">
              {test.item.testHistories[0]?.completionStatus !== 'waiting'
                ? (
                  <Text lg>
                    {!isEmpty(test.item.testHistories)
                      ? `${test.item.testHistories[0].score}点（${test.item.testHistories[0].testScorePercentage}%）`
                      : 'ー'
                    }
                  </Text>
                ) : <Text lg>採点待ち</Text>
              }
            </Style.CollapseItemContent>
            <Style.CollapseItemContent width="14%">
              <Text lg>
                {!isEmpty(test.item.testHistories)
                  ? moment(test.item.testHistories[0].createdAt).format(DATE_FORMAT)
                  : 'ー'
                }
              </Text>
            </Style.CollapseItemContent>
            <Style.CollapseItemContent isActionButton width="22%">
              <Style.GroupButton isFlexEnd={test.item.testHistories.length === 0}>
                {test.item.testHistories.length > 0 && (
                  <Button
                    className="collapse-header-button"
                    size="small"
                    type="primary"
                    ghost
                    onClick={(e) => handleShowTestResult(e, test.item.testHistories[0], test.item.testType)}
                    style={{ marginRight: 16 }}
                  >
                    テスト結果確認
                  </Button>
                )}
                <Button
                  className="collapse-header-button"
                  size="small"
                  type="primary"
                  ghost
                  onClick={(e) => handleJoinTest(e, test)}
                  style={{ marginRight: 16 }}
                >
                  テスト実施
                </Button>
              </Style.GroupButton>
            </Style.CollapseItemContent>
          </Style.CollapseItemContainer>
        }
      >
        {renderHistoriesTest(test)}
      </Collapse.Panel>
    ));
  }

  function renderHeader() {
    return (
      <Style.CollapseHeader>
        <div style={{ width: '10%' }}>
          <Text white lg w6>テスト種別</Text>
        </div>
        <div style={{ width: '30%' }}>
          <Text white lg w6>レッスン名</Text>
        </div>
        <div style={{ width: '10%' }}>
          <Text white lg w6>問題数</Text>
        </div>
        <div style={{ width: '14%' }}>
          <Text white lg w6>得点（得点率）</Text>
        </div>
        <div style={{ width: '14%' }}>
          <Text white lg w6>受講日</Text>
        </div>
      </Style.CollapseHeader>
    );
  }

  function renderHeaderMobile() {
    return (
      <Style.CollapseHeader>
        <div style={{ width: '45%' }}>
          <Style.TextHeaderTable white sm center>レッスン名</Style.TextHeaderTable>
        </div>
        <div style={{ width: '35%' }}>
          <Style.TextHeaderTable white sm center>得点</Style.TextHeaderTable>
          <Style.TextHeaderTable white xxs center>（得点率）</Style.TextHeaderTable>
        </div>
        <div style={{ width: '30%' }} />
      </Style.CollapseHeader>
    );
  }

  function renderTestItemsMobile() {
    return testsData.map((test, unitIndex) => (
      <Collapse.Panel
        key={`$history-${unitIndex}`}
        showArrow={false}
        header={
          <Style.CollapseItemContainer key={`test-${test.id}`}>
            <Style.CollapseItemContent width="40%" align="flex-start">
              <Text
                isPreWrap
                md
                w6
              >
                {test.unit.name}
              </Text>
            </Style.CollapseItemContent>
            <Style.CollapseItemContent width="35%">
              {test.item.testHistories[0]?.completionStatus !== 'waiting'
                ? (
                  <>
                    {!isEmpty(test.item.testHistories)
                      ? <>
                          <Text md center lineHeight="10px">{test.item.testHistories[0].score}点</Text>
                          <Text xs center lineHeight="10px">（{test.item.testHistories[0].testScorePercentage}%）</Text>
                        </>
                      : 'ー'
                    }
                  </>
                ) : <Text lg>採点待ち</Text>
              }
              {
                test.item.testHistories.length > 0 && <Button
                  size="small"
                  type="link"
                  className="show-result"
                  onClick={(e) => handleShowTestResult(e, test.item.testHistories[0], test.item.testType)}
                >
                  詳細確認
                </Button>
              }
            </Style.CollapseItemContent>
            <Style.CollapseItemContent isActionButton width="35%">
              <Button
                className="join-test"
                size="small"
                type="primary"
                ghost
                onClick={(e) => handleJoinTest(e, test)}
              >
                テスト実施
              </Button>
            </Style.CollapseItemContent>
          </Style.CollapseItemContainer>
        }
      />
    ));
  }

  if (isEmpty(testsData) && !loading) return null;
  return (
    <Style.TestItemsContainer>
      <Style.TextHeader xxxl style={{ marginBottom: 16 }}>テスト</Style.TextHeader>
      <Spin spinning={loading}>
        <Style.TableDesktop>
          <MainWrapper
            title={renderHeader()}
            isStudent
          >
            <Collapse
              bordered={false}
              expandIconPosition="right"
              expandIcon={({ isActive }) =>
                isActive ? <MinusOutlined /> : <PlusOutlined />
              }
              style={{ backgroundColor: '#FFFFFF' }}
            >
              {renderTestItems()}
            </Collapse>
          </MainWrapper>
        </Style.TableDesktop>
        <Style.TableMobile>
          <MainWrapper
            title={renderHeaderMobile()}
            isStudent
          >
            <Collapse
              bordered={false}
              style={{ backgroundColor: '#FFFFFF' }}
            >
              {renderTestItemsMobile()}
            </Collapse>
          </MainWrapper>
        </Style.TableMobile>
      </Spin>
    </Style.TestItemsContainer>
  );
};

export default TestList;
