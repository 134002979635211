// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { combineReducers } from 'redux';

import { AUTH_ACTION, SUCCESS } from '../constant';

import modalReducer, { ModalReducer } from './modal.reducer';
import authReducer, { AuthReducer } from './auth.reducer';

import testReducer, { TestReducer } from './adminService/test.reducer';
import videoReducer, { VideoReducer } from './adminService/video.reducer';
import unitReducer, { UnitReducer } from './adminService/unit.reducer';
import courseReducer, { CourseReducer } from './adminService/course.reducer';
import tagReducer, { TagReducer } from './adminService/tag.reducer';
import teacherReducer, { TeacherReducer } from './adminService/teacher.reducer';
import planReducer, { PlanReducer } from './adminService/plan.reducer';
import companyReducer, { CompanyReducer } from './adminService/company.reducer';
import surveyReducer, { SurveyReducer } from './adminService/survey.reducer';
import categoryReducer, { CategoryReducer } from './adminService/category.reducer';
import questionReducer, { QuestionReducer } from './adminService/question.reducer';
import accountReducer, { AccountReducer } from './adminService/account.reducer';
import serviceStatisticReducer, { ServiceStatisticReducer } from './adminService/statistic.reducer';
import tipReducer, { TipReducer } from './adminService/tip.reducer';
import faqReducer, { FAQReducer } from './adminService/faq.reducer';
import profileReducer, { ProfileReducer } from './adminService/profile.reducer';
import uploadAnswerReducer, { UploadAnswerReducer } from './adminService/uploadAnswer.reducer';
import licenseReducer, { LicenseReducer } from './adminService/license.reducer';
// Company
import companyAccountReducer, { CompanyAccountReducer } from './adminCompany/account.reducer';
import companyStatisticReducer, { CompanyStatisticReducer } from './adminCompany/statistic.reducer';
import companyCourseReducer, { CompanyCourseReducer } from './adminCompany/course.reducer';
import companyContractReducer, { CompanyContractReducer } from './adminCompany/company.reducer';
import companyProfileReducer, { CompanyProfileReducer } from './adminCompany/profile.reducer';

// Student
import studentStatisticReducer, { StudentStatisticReducer } from './student/statistic.reducer';
import studentAccountReducer, { StudentAccountReducer } from './student/account.reducer';
import studentCourseReducer, { StudentCourseReducer } from './student/course.reducer';
import studentUnitReducer, { StudentUnitReducer } from './student/unit.reducer';
import studentTestReducer, { StudentTestReducer } from './student/test.reducer';
import studentVideoReducer, { StudentVideoReducer } from './student/video.reducer';
// Master Data
import commonReducer, { CommonReducer } from './common.reducer';
import masterDataReducer, { MasterDataReducer } from './master.reducer';
import myProfileReducer, { MyProfileReducer } from './profile.reducer';
import loadingReducer, { LoadingReducer } from './loading.reducer';
import notificationReducer, { NotificationReducer } from './notification.reducer';
import disableRequestReducer, { DisableRequestReducer } from  "./disableRequest.reducer"

export interface IRootState {
  readonly modalReducer: ModalReducer;
  readonly authReducer: AuthReducer;
  readonly testReducer: TestReducer;
  readonly videoReducer: VideoReducer;
  readonly unitReducer: UnitReducer;
  readonly courseReducer: CourseReducer;
  readonly tagReducer: TagReducer;
  readonly teacherReducer: TeacherReducer;
  readonly planReducer: PlanReducer;
  readonly companyReducer: CompanyReducer;
  readonly surveyReducer: SurveyReducer;
  readonly categoryReducer: CategoryReducer;
  readonly accountReducer: AccountReducer;
  readonly serviceStatisticReducer: ServiceStatisticReducer;
  readonly questionReducer: QuestionReducer;
  readonly tipReducer: TipReducer;
  readonly faqReducer: FAQReducer;
  readonly profileReducer: ProfileReducer;
  readonly uploadAnswerReducer: UploadAnswerReducer;
  readonly licenseReducer: LicenseReducer;
  // Company
  readonly companyAccountReducer: CompanyAccountReducer;
  readonly companyStatisticReducer: CompanyStatisticReducer;
  readonly companyCourseReducer: CompanyCourseReducer;
  readonly companyContractReducer: CompanyContractReducer;
  readonly companyProfileReducer: CompanyProfileReducer;
  // Student
  readonly studentStatisticReducer: StudentStatisticReducer;
  readonly studentAccountReducer: StudentAccountReducer;
  readonly studentCourseReducer: StudentCourseReducer;
  readonly studentUnitReducer: StudentUnitReducer;
  readonly studentTestReducer: StudentTestReducer;
  readonly studentVideoReducer: StudentVideoReducer;
  // Master Data
  readonly commonReducer: CommonReducer;
  readonly masterDataReducer: MasterDataReducer;
  readonly myProfileReducer: MyProfileReducer;
  readonly loadingReducer: LoadingReducer;
  readonly notificationReducer: NotificationReducer;
  readonly disableRequestReducer: DisableRequestReducer
}

const appReducer = combineReducers<IRootState>({
  modalReducer,
  authReducer,
  testReducer,
  videoReducer,
  unitReducer,
  courseReducer,
  tagReducer,
  teacherReducer,
  planReducer,
  companyReducer,
  surveyReducer,
  categoryReducer,
  accountReducer,
  questionReducer,
  serviceStatisticReducer,
  tipReducer,
  faqReducer,
  profileReducer,
  uploadAnswerReducer,
  licenseReducer,
  // Company
  companyAccountReducer,
  companyStatisticReducer,
  companyCourseReducer,
  companyContractReducer,
  companyProfileReducer,
  // Student
  studentStatisticReducer,
  studentAccountReducer,
  studentCourseReducer,
  studentUnitReducer,
  studentTestReducer,
  studentVideoReducer,
  // Master Data
  commonReducer,
  masterDataReducer,
  myProfileReducer,
  loadingReducer,
  notificationReducer,
  disableRequestReducer
});

// Reset reducer when logout
const rootReducer = (state, action) => {
  if (action.type === SUCCESS(AUTH_ACTION.LOGOUT)) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
