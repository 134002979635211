// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
  MODAL_ACTION,
} from 'src/redux/constant';

const initialState = {
  invitationCompany: {
    errors: [],
  },
  invitationStudents: {
    errors: [],
  },
  invitationServices: {
    errors: [],
  },
  invitationAdminCompany: {
    errors: [],
  },
  invitationList: {
    data: [] as {
      id: string;
      email: string;
      registerStatus: string;
    }[],
    meta: {},
    load: false,
    errors: [],
  },
  accountList: {
    data: [] as {
      id: number;
      status: string;
    }[],
    meta: {},
    load: false,
    errors: [],
  },
  studentsByCompany: {
    data: {
      studentList: [],
      customDisplayField: {},
    },
    meta: {},
    load: false,
    errors: [],
  },
  confirmationService: {
    errors: [],
  },
  dataAddCsv: {
    statusAddCsv: "",
    courseIdSelected: null,
    error: [],
  }
};

export type AccountReducer = Readonly<typeof initialState>;

export default (state: AccountReducer = initialState, action: any): AccountReducer => {
  switch (action.type) {
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS): {
    const { errors } = action;
    return {
      ...state,
      confirmationService: {
        ...state.confirmationService,
        errors,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY): {
    return {
      ...state,
      invitationCompany: {
        ...state.invitationCompany,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY): {
    const { errors } = action;
    return {
      ...state,
      invitationCompany: {
        ...state.invitationCompany,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS): {
    return {
      ...state,
      invitationStudents: {
        ...state.invitationStudents,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS): {
    const { errors } = action;
    return {
      ...state,
      invitationStudents: {
        ...state.invitationStudents,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES): {
    return {
      ...state,
      invitationServices: {
        ...state.invitationServices,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES): {
    const { errors } = action;
    return {
      ...state,
      invitationServices: {
        ...state.invitationServices,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY): {
    return {
      ...state,
      invitationAdminCompany: {
        ...state.invitationAdminCompany,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY): {
    const { errors } = action;
    return {
      ...state,
      invitationAdminCompany: {
        ...state.invitationAdminCompany,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_INVITATION_LIST): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.GET_INVITATION_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      invitationList: {
        data: data.users,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_INVITATION_LIST): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY): {
    return {
      ...state,
      studentsByCompany: {
        ...state.studentsByCompany,
        load: true,
        errors: [],
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY): {
    const { data, meta } = action.payload;
    return {
      ...state,
      studentsByCompany: {
        data: {
          studentList: data.users,
          customDisplayField: data.company.customDisplayField,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY): {
    const { errors } = action;
    return {
      ...state,
      studentsByCompany: {
        ...state.studentsByCompany,
        load: false,
        errors,
      },
    };
  }
  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.CLEAR_STUDENTS_BY_COMPANY): {
    return {
      ...state,
      studentsByCompany: {
        data: {
          studentList: [],
          customDisplayField: {},
        },
        meta: {},
        load: false,
        errors: [],
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_ACCOUNT_LIST): {
    return {
      ...state,
      accountList: {
        ...state.accountList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.GET_ACCOUNT_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      accountList: {
        data: data.users,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_ACCOUNT_LIST): {
    const { errors } = action;
    return {
      ...state,
      accountList: {
        ...state.accountList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.RESEND_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.RESEND_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => user.email === action.payload.email
        ? { ...user, registerStatus: 'invited', confirmationSentAt: action.payload.confirmationSentAt }
        : user),
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.RESEND_INVITATION): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.CANCEL_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.CANCEL_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => user.email === action.payload.user.email
        ? { ...user, registerStatus: 'canceled' }
        : user),
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.CANCEL_INVITATION): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE): {
    const { data, meta } = action.payload;
    return {
      ...state,
      studentsByCompany: {
        data: {
          studentList: data.users,
          customDisplayField: data.company.customDisplayField,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE): {
    const { errors } = action;
    return {
      ...state,
      studentsByCompany: {
        ...state.studentsByCompany,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.CONFIRM_ACCOUNT): {
    return {
      ...state,
      confirmationService: {
        ...state.confirmationService,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.CONFIRM_ACCOUNT): {
    const { errors } = action;
    return {
      ...state,
      confirmationService: {
        ...state.confirmationService,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.BAN_ACCOUNT): {
    const { data } = action.payload;
    const newAccountList = state.accountList.data;
    const studentIndex = state.accountList.data.findIndex((item) => item.id === data.id);
    newAccountList.splice(studentIndex, 1, {
      ...state.accountList.data[studentIndex],
      status: 'banned',
    });
    return {
      ...state,
      accountList: {
        ...state.accountList,
        data: newAccountList,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV): {
    return {
      ...state,
      dataAddCsv: {
        ...state.dataAddCsv,
        statusAddCsv: "success",
        courseIdSelected: action.payload.courseId,
      }
    }
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV): {
    return {
      ...state,
      dataAddCsv: {
        ...state.dataAddCsv,
        statusAddCsv: "error",
        error: action.errors
      }
    }
  }
  case REQUEST(MODAL_ACTION.CLOSE): {
    return {
      ...state,
      dataAddCsv: {
        ...state.dataAddCsv,
        statusAddCsv: "",
        courseIdSelected: null,
      }
    }
  }

  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS): {
    const { userIds } = action.payload;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => {
          const isResendUser = userIds.find((userId) => userId === user.id);
          return isResendUser ? { ...user, isResendInvitationProcessing: true } : user;
        }),
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS): {
    const { userIds } = action.payload;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => {
          const isCancelUser = userIds.find((userId) => userId === user.id);
          return isCancelUser ? { ...user, isCancelInvitationProcessing: true } : user;
        }),
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }
  default:
    return state;
}
};
