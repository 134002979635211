//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';

import history from 'src/utils/history';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import UserRegistration from './components/UserRegistration';
import RegistrationStatus from './components/RegistrationStatus';
import CompanyAccountRegistration from './components/CompanyAccountRegistration';

import { Text } from 'src/components/styles';

import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import * as Style from './styles';
import { connect } from 'react-redux';

interface IManagerAccountPageProps extends StateToProps{
  isDisableRequest: boolean
}

const ManagerAccountPage: React.FC<IManagerAccountPageProps> = ({isDisableRequest}) => {
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const urlActiveKey = history.location.state?.key;

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.ManagerAccount);
  }, []);

  useEffect(() => {
    if (urlActiveKey) setActiveTabKey(urlActiveKey);
  }, [urlActiveKey]);

  return (
    <MainContainer isTabs>
      <MainContent fullView>
        <Style.ManagerAccountTitle>
          <Text xxxl w6>アカウント登録</Text>
        </Style.ManagerAccountTitle>
        <Tabs
          activeKey={activeTabKey}
          type="card"
          onChange={(activeKey) => setActiveTabKey(activeKey)}
          style={{ marginBottom: 60 }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>受講者登録</Text>}
            key="1"
          >
            <UserRegistration isDisableRequest={isDisableRequest}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>企業管理者登録</Text>}
            key="2"
          >
            <CompanyAccountRegistration isDisableRequest={isDisableRequest}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>登録状況</Text>}
            key="3"
          >
            <RegistrationStatus />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, null)(ManagerAccountPage);