// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import {
  Row,
  Space,
  Button,
  Input,
  Collapse,
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  PlusCircleOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { sortBy } from 'lodash/collection';
import history from 'src/utils/history';

import { convertSecondTime } from 'src/utils/dateTime';
import { renderItemTypeIcon, handleStopPropagation } from 'src/utils/common';


import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import AddGroupChapterModal from '../AddGroupChapterModal';
import ModifyUnitModal from '../ModifyUnitModal';
import DragDropModal from '../DragDropModal';
import NoData from 'src/components/layouts/NoData';

import { ServicePath } from 'src/constants/routerConstants';

import * as Style from './styles';

interface ICourseContentProps {
  showModal(params: any): void;
  getGroupDetail(params: any): void;
  updateCourseGroup(params: any): void;
  deleteCourseGroup(params: any): void;
  updateGroupChapter(params: any): void;
  deleteGroupChapter(params: any): void;
  deleteChapterUnit(params: any): void;
  clearCourseDetailErrors(): void;
  isDisableRequest:boolean;
  courseId: string;
  courseDetail: {
    data: {
      id: number;
      groups: {
        id: number;
        name: string;
        chapters: object[];
        isLearned: boolean;
      }[];
    };
  };
}

const CourseContent: React.FC<ICourseContentProps> = ({
  showModal,
  getGroupDetail,
  updateCourseGroup,
  deleteCourseGroup,
  updateGroupChapter,
  deleteGroupChapter,
  deleteChapterUnit,
  clearCourseDetailErrors,
  courseId,
  courseDetail,
  isDisableRequest
}) => {
  const [collapsesActivated, setCollapsesActivated] = useState<any>([]);
  const [unitSelected, setUnitSelected] = useState<string>('');
  const [changeNameKey, setChangeNameKey] = useState<{ key?: string, name?: string }>({});
  const [changeNameError, setChangeNameError] = useState<{ key?: string, error?: string }>({});
  const [titleName, setTitleName] = useState<string>(''); // Optimal performance typing
  const [recentlyGroupIndexCreated, setRecentlyGroupIndexCreated] = useState<number>(-1);

  useEffect(() => {
    if (recentlyGroupIndexCreated >= 0) {
      setCollapsesActivated([...collapsesActivated, {
        chapters: [],
        key: `group-${recentlyGroupIndexCreated}`,
      }])
    }
  }, [recentlyGroupIndexCreated])

  function handleChangeGroupCollapse(collapseValues) {
    const newCollapseValues = sortBy(collapseValues);
    const newCollapseData = newCollapseValues.map((value) => {
      const groupActiveIndex = findIndex(collapsesActivated, { key: value });
      if (groupActiveIndex === -1) {
        return { key: value, chapters: [] };
      }
      return collapsesActivated[groupActiveIndex];
    });
    setCollapsesActivated([...newCollapseData]);
  }

  function handleChangeChapterCollapse(collapseValues, groupActiveIndex) {
    const newCollapseValues = sortBy(collapseValues);
    const newCollapseData = collapsesActivated;
    const chapterCollapse = isEmpty(newCollapseValues) ? [] : newCollapseValues.map((value) => {
      const chapterActiveIndex = findIndex(newCollapseData[groupActiveIndex].chapters, { key: value });
      if (chapterActiveIndex === -1) {
        return { key: value };
      }
      return newCollapseData[groupActiveIndex].chapters[chapterActiveIndex];
    });
    newCollapseData[groupActiveIndex].chapters = chapterCollapse;
    setCollapsesActivated([...newCollapseData]);
  }

  function handleViewGroupDetail(type, index, id) {
    if (type !== 'group') return null;
    if (!courseDetail.data.groups[index.groupIndex].chapters) {
      getGroupDetail({ id, groupIndex: index.groupIndex });
    }
  }

  function openChangeName(e, key, name) {
    handleStopPropagation(e);
    setChangeNameKey({ key, name });
    setTitleName(name.trim());
  }

  function closeChangeName(e) {
    handleStopPropagation(e);
    setChangeNameKey({});
    setChangeNameError({});
  }

  function handleChangeName(e, type, key) {
    const { value } = e.target;
    setTitleName(value.trim());
    if (value.trim().length === 0) {
      setChangeNameError({
        key,
        error: `${type === 'group' ? 'グループ名' : 'チャプター名' }は必須項目です。`,
      });
    } else if (value.trim().length > 50) {
      setChangeNameError({
        key,
        error: type === 'group' ? 'グループ名が長すぎます。（最大は50桁です）' : 'チャプター名が長すぎます。\n（最大は50桁です）' ,
      });
    } else {
      setChangeNameError({});
    }
  }

  function submitChangeName(e, type, key, index, id) {
    handleStopPropagation(e);
    if (titleName.length > 0 && titleName.length <= 50) {
      if (type === 'group') {
        updateCourseGroup({
          id,
          requestData: {
            group: {
              name: titleName.trim(),
            },
          },
          groupIndex: index.groupIndex,
        });
      } else {
        updateGroupChapter({
          id,
          requestData: {
            chapter: {
              name: titleName.trim(),
            },
          },
          groupIndex: index.groupIndex,
          chapterIndex: index.chapterIndex,
        });
      }
      setChangeNameKey({});
    }
  }

  function handleAddGroup() {
    const modalProps = {
      setRecentlyGroupIndexCreated,
      modalData: {
        type: 'group',
        title: 'グループ新規追加',
        courseId,
        currentNumberGroups: courseDetail.data.groups.length,
      },
    };
    return showModal(<AddGroupChapterModal {...modalProps} />);
  }

  function handleAddChapter(groupId, groupIndex) {
    const modalProps = {
      modalData: {
        type: 'chapter',
        title: 'チャプター新規追加',
        groupId,
        groupIndex,
      },
    };
    return showModal(<AddGroupChapterModal {...modalProps} />);
  }

  function handleDeleteGroupChapter(e, type, index, id, isLearned, name = "") {
    if (isLearned) return null;
    handleStopPropagation(e);
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: `${type === 'group' ? `${name}の削除は取り消しができません。` : 'このチャプターを削除してもよろしいですか？'}`,
        messageTwo: type === 'group' ? `この${name}を削除したもよろしいですか？` : ''
      },
      modalAction: {
        confirm: () => type === 'group'
          ? deleteCourseGroup({ id, groupIndex: index.groupIndex })
          : deleteGroupChapter({ id, groupIndex: index.groupIndex, chapterIndex: index.chapterIndex }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function handleAddUnit(chapterId, unitIndex, index) {
    clearCourseDetailErrors();
    const modalProps = {
      modalData: {
        title: 'レッスン新規追加',
        type: 'modifyChapterUnit',
        group: {
          index: index.groupIndex,
        },
        chapter: {
          id: chapterId,
          index: index.chapterIndex,
        },
        unitVersion: {
          index: unitIndex,
          isInChapter: true,
        },
        actionType: 'add',
      },
    };
    return showModal(<ModifyUnitModal {...modalProps} />);
  }

  function handleEditUnit(e, chapterId, unitData, unitIndex, index, isLearned) {
    if (isLearned) return null;
    handleStopPropagation(e);
    clearCourseDetailErrors();
    const modalProps = {
      modalData: {
        title: 'レッスン編集',
        type: 'modifyChapterUnit',
        group: {
          index: index.groupIndex,
        },
        chapter: {
          id: chapterId,
          index: index.chapterIndex,
        },
        unitVersion: {
          ...unitData,
          index: unitIndex,
          isInChapter: true,
        },
        actionType: 'edit',
      },
    };
    return showModal(<ModifyUnitModal {...modalProps} />);
  }

  function handleDeleteUnit(e, chapterId, unitId, unitIndex, index, isLearned) {
    if (isLearned) return null;
    handleStopPropagation(e);
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'このレッスンを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deleteChapterUnit({
          requestData: {
            chapterId,
            unitId,
          },
          groupIndex: index.groupIndex,
          chapterIndex: index.chapterIndex,
          unitIndex,
        }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function handleDragDrop() {
    const modalProps = {
      modalData: {
        title: '順番の入れ替え',
        courseId,
      },
      modalAction: {},
    };
    return showModal(<DragDropModal {...modalProps} />);
  }

  function redirectChapterFAQs(id, name) {
    return history.push({
      pathname: ServicePath.ModifyChapterFAQ,
      state: { chapterId: id, chapterName: name }, // chapter id in Add Chapter FAQ  screen
    });
  }

  function renderCollapseHeader(type, key, index, id, name, isLearned) {
    return (
      <Style.CollapseHeader onClick={() => handleViewGroupDetail(type, index, id)}>
        <Space align="center" size="middle">
          {changeNameKey.key === key
            ? (
              <>
                <Input
                  size="small"
                  defaultValue={changeNameKey.name}
                  onClick={(e) => handleStopPropagation(e)}
                  onChange={(e) => handleChangeName(e, type, key)}
                  style={{ width: 500 }}
                />
                <Button
                  type="primary"
                  size="small"
                  ghost
                  onClick={(e) => submitChangeName(e, type, key, index, id)}
                >
                  保存する
                </Button>
                <Button
                  type="primary"
                  size="small"
                  ghost
                  danger
                  onClick={(e) => closeChangeName(e)}
                >
                  キャンセル
                </Button>
                {changeNameError.key === key && (
                  <Text error dangerouslySetInnerHTML={{ __html: changeNameError.error }} />
                )}
              </>
            )
            : (
              <>
                <Text
                  white={type === 'group'}
                  headerText={type === 'chapter'}
                  lg
                  w6
                >
                  {name}
                </Text>
                <Button
                  className="collapse-header-button"
                  type="link"
                  ghost
                  icon={<EditOutlined />}
                  onClick={(e) => openChangeName(e, key, name)}
                  disabled={isDisableRequest  }
                />
              </>
            )
          }
        </Space>
        <Style.ChapterCol style={{ width: type === 'group' ? 100 : 220 }}>
          <Style.ChapterAction isDisabled={type === 'group' && isLearned}>
            <Button
              className="collapse-header-button"
              size="small"
              danger
              ghost
              onClick={(e) => handleDeleteGroupChapter(e, type, index, id, isLearned, name)}
              style={{ width: 76,  marginRight: 16 }}
              disabled={isLearned}
            >
              &nbsp;削除&nbsp;
            </Button>
            {type === 'chapter' &&
              <Button
                className="collapse-header-button"
                type="primary"
                size="small"
                onClick={(e) => redirectChapterFAQs(id, name)}
              >
                FAQを追加する
              </Button>
            }
          </Style.ChapterAction>
        </Style.ChapterCol>
        {/* Fix onClick Icon not call API  */}
        {type === 'group' && (
          <Style.CollapseIcon>
            {index.groupActiveIndex === -1
              ? <PlusOutlined style={{ fontSize: 12, color: 'white' }} />
              : <MinusOutlined  style={{ fontSize: 12, color: 'white' }} />
            }
          </Style.CollapseIcon>
        )}
      </Style.CollapseHeader>
    );
  }

  function renderUnitItems(chapterData, keyIndex, index, groupId) {
    if (isEmpty(chapterData.unitVersions)) {
      return (
        <>
          <NoData message="データが存在しません。" style={{ marginTop: '-32px' }} />
          <Button
            type="dashed"
            size="large"
            block
            icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
            onClick={() => handleAddUnit(chapterData.id, 0, index)}
            style={{ borderRadius: 0 }}
          >
            レッスン追加
          </Button>
        </>
      );
    }
    return chapterData.unitVersions.map((unitItem, unitIndex) => {
      const isActive = unitSelected === `${keyIndex}-unit-${unitIndex}`;
      return (
        <Style.UnitItemContainer key={`${keyIndex}-unit-${unitIndex}`}>
          <Style.UnitItemContent
            active={isActive}
            onClick={() => isActive
              ? setUnitSelected('')
              : setUnitSelected(`${keyIndex}-unit-${unitIndex}`)
            }
          >
            <Row style={{ width: '100%' }}>
              <Style.UnitCol span={12}>
                <Space align="center" size={4}>
                  <Style.ItemTypeIcon
                    onClick={() => window.open(
                      `/admin-service/view-course/${courseDetail.data.id}/${unitItem.item.type}/${unitItem.id}?group=${groupId}&chapter=${chapterData.id}`,
                      '_blank',
                    )}
                  >
                    <img src={renderItemTypeIcon(unitItem.item.type)} alt="" />
                  </Style.ItemTypeIcon>
                  <TooltipParagraph style={{ marginRight: 8 }}>
                    {unitItem.unit.name}
                  </TooltipParagraph>
                </Space>
              </Style.UnitCol>
              <Style.UnitCol span={6}>
                <Space align="center" size={48}>
                  <Text lg>{`ver${unitItem.version}`}</Text>
                  <Text lg>
                    {unitItem.item.type === 'video'
                      ? convertSecondTime(unitItem.item.duration, 'HH:mm:ss')
                      : (unitItem.item.questionsCount && `${unitItem.item.questionsCount}問`)
                    }
                  </Text>
                </Space>
              </Style.UnitCol>
              <Style.UnitCol span={6}>
                <Style.UnitAction>
                  <Button
                    size="small"
                    danger
                    ghost
                    onClick={(e) => handleDeleteUnit(
                      e,
                      chapterData.id,
                      unitItem.id,
                      unitIndex,
                      index,
                      unitItem.isLearned,
                    )}
                    style={{ marginRight: 16 }}
                  >
                    &nbsp;削除&nbsp;
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    onClick={(e) => handleEditUnit(e, chapterData.id, unitItem, unitIndex, index, unitItem.isLearned)}
                    disabled={unitItem.isLearned}
                  >
                    &nbsp;編集&nbsp;
                  </Button>
                </Style.UnitAction>
              </Style.UnitCol>
            </Row>
          </Style.UnitItemContent>
          <Style.AddUnitButton position="top" show={isActive}>
            <Button
              type="link"
              size="small"
              icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
              onClick={() => handleAddUnit(chapterData.id, unitIndex, index)}
              style={{ padding: 0 }}
            >
              新規追加
            </Button>
          </Style.AddUnitButton>
          <Style.AddUnitButton position="bottom" show={isActive}>
            <Button
              type="link"
              size="small"
              icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
              onClick={() => handleAddUnit(chapterData.id, unitIndex + 1, index)}
              style={{ padding: 0 }}
            >
              新規追加
            </Button>
          </Style.AddUnitButton>
        </Style.UnitItemContainer>
      );
    });
  }

  function renderChapterCollapse(chapters, keyIndex, groupCollapseIndex, groupId) {
    return chapters.map((chapterCollapse, chapterCollapseIndex) => (
      <Collapse.Panel
        key={`${keyIndex}-chapter-${chapterCollapseIndex}`}
        header={
          renderCollapseHeader(
            'chapter',
            `${keyIndex}-chapter-${chapterCollapseIndex}`,
            {
              groupIndex: groupCollapseIndex,
              chapterIndex: chapterCollapseIndex,
            },
            chapterCollapse.id,
            chapterCollapse.name,
            chapterCollapse.isLearned,
          )
        }
      >
        <Style.ChapterCollapseContent>
          {renderUnitItems(
            chapterCollapse,
            `${keyIndex}-chapter-${chapterCollapseIndex}`,
            {
              groupIndex: groupCollapseIndex,
              chapterIndex: chapterCollapseIndex,
            },
            groupId,
          )}
        </Style.ChapterCollapseContent>
      </Collapse.Panel>
    ));
  }

  function renderGroupCollapse() {
    if (isEmpty(courseDetail.data.groups)) return null;
    return courseDetail.data.groups.map((groupCollapse, groupCollapseIndex) => {
      const groupActiveIndex = findIndex(collapsesActivated, { key: `group-${groupCollapseIndex}` });
      return (
        <Collapse.Panel
          key={`group-${groupCollapseIndex}`}
          header={renderCollapseHeader(
            'group',
            `group-${groupCollapseIndex}`,
            {
              groupIndex: groupCollapseIndex,
              groupActiveIndex,
            },
            groupCollapse.id,
            groupCollapse.name,
            groupCollapse.isLearned,
          )}
        >
          <Style.GroupCollapseContent>
            {isEmpty(groupCollapse.chapters)
              ? <NoData message="データが存在しません。" />
              : (
                <Collapse
                  bordered={false}
                  defaultActiveKey={[]}
                  expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
                  expandIconPosition="right"
                  className="modify-course-group-collapse"
                  onChange={(values) => handleChangeChapterCollapse(values, groupActiveIndex)}
                >
                  {groupCollapse.chapters &&
                    renderChapterCollapse(
                      groupCollapse.chapters,
                      `group-${groupCollapseIndex}`,
                      groupCollapseIndex,
                      groupCollapse.id,
                    )
                  }
                </Collapse>
              )
            }
            <Button
              type="dashed"
              size="large"
              block
              icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
              onClick={() => handleAddChapter(groupCollapse.id, groupCollapseIndex)}
              style={{ borderRadius: 0 }}
            >
              チャプタ追加
            </Button>
          </Style.GroupCollapseContent>
        </Collapse.Panel>
      );
    });
  }

  return (
    <>
      <Style.CourseItemsHeader>
        <Text xxxl style={{ marginBottom: 8 }}>コース内容</Text>
        <Button
          type="primary"
          disabled={isEmpty(courseDetail.data.groups)}
          onClick={() => {
            setCollapsesActivated([]);
            handleDragDrop();
          }}
        >
          順番の入れ替え
        </Button>
      </Style.CourseItemsHeader>
      <Style.CourseContentContainer>
        {isEmpty(courseDetail.data.groups)
          ? <NoData message="データが存在しません。" />
          : (
            <Collapse
              bordered={false}
              defaultActiveKey={[]}
              activeKey={[...collapsesActivated.map((collapse) => collapse.key)]}
              expandIcon={() => null} // Fix onClick Icon not call API
              expandIconPosition="right"
              style={{ backgroundColor: '#0D122D' }}
              onChange={(values) => handleChangeGroupCollapse(values)}
            >
              {renderGroupCollapse()}
            </Collapse>
          )
        }
        <Button
          type="dashed"
          size="large"
          block
          icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
          onClick={() => handleAddGroup()}
          style={{ borderRadius: 0 }}
        >
          グループ追加
        </Button>
      </Style.CourseContentContainer>
    </>
  );
};

export default CourseContent;
