// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { Space } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { LoginStorage } from 'src/utils/localStorge';

import history from 'src/utils/history';

import {
  MainContainer,
  MainContent,
} from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles';

import {
  ServicePath,
  CompanyPath,
  StudentPath,
} from 'src/constants/routerConstants';
import { PAGE_TITLE, NotFoundTitle } from 'src/constants/pageTitle';

import * as Style from './styles';

interface INotFoundPageProps {}

const NotFoundPage: React.FC<INotFoundPageProps> = () => {
  useEffect(() => {
    document.title = PAGE_TITLE(NotFoundTitle);
  }, []);

  const handleBackToHome = () => {
    const userStorage = LoginStorage.getData();
    if (!isEmpty(userStorage)) {
      if (userStorage.tokenInfo.role === 'admin_service') {
        return history.replace(ServicePath.AdminServiceTop);
      } else if (userStorage.tokenInfo.role === 'admin_company') {
        return history.replace(CompanyPath.AdminCompanyTop);
      } else {
        return history.replace(StudentPath.StudentTop);
      }
    } else {
      return history.replace(StudentPath.Login);
    }
  };
  return (
    <MainContainer isStudent>
      <MainContent fullView>
        <Style.NotFoundType>
          <Text h2 white w6>4 0 4 NOT FOUND</Text>
        </Style.NotFoundType>
        <Style.NotFoundContent>
          <Style.NotFoundTitle>
            <Text h3 w6 style={{ fontSize: 34 }}>お探しのページが見つかりませんでした。</Text>
          </Style.NotFoundTitle>
          <Text xl w6>申し訳ありません。</Text>
          <Text xl w6>お探しのページは一時的にアクセス出来ない状況にあるか、もしくは移動、削除され見つけることができません。</Text>
          <Space align="center" size={0} style={{ marginTop: 16 }}>
            <RightOutlined />
            <Style.Link onClick={() => handleBackToHome()}>
              このサイトのトップへ戻る
            </Style.Link>
          </Space>
        </Style.NotFoundContent>
      </MainContent>
    </MainContainer>
  );
};

export default NotFoundPage;
