//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Input ,
  Form,
  Space,
} from 'antd';
import { isEmpty } from 'lodash/lang';

import { MainWrapper } from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';

import {
  inviteCompany as inviteCompanyAction,
} from 'src/redux/actions';

import * as Style from './styles';
import history from 'src/utils/history';

interface ICompanyRegistrationProps extends StateProps, DispatchProps {
  isDisableRequest: boolean;
  inviteCompany(params): void;
  invitationCompany: {
    errors: {
      message: string;
    }[];
  };
  activeTabKey: string
}

const CompanyRegistration: React.FC<ICompanyRegistrationProps> = ({
  inviteCompany,
  invitationCompany,
  isDisableRequest,
  activeTabKey
}) => {
  const initialFormValues = {
    email: [''],
  };

  useEffect(() => {
    history.replace({
      state: {
        key: activeTabKey
      }
    })
  }, [activeTabKey])

  return (
    <MainWrapper title="新規登録" style={{ marginTop: 24 }}>
      <Style.TabWrapperContent>
        <Style.FormWrapper
          name="inviteCompanyForm"
          onFinish={(values) => inviteCompany({
            company: {
              contractName: values.contractName.trim(),
              name: values.name.trim(),
              adminEmail: values.adminEmail.trim(),
              ...(values.sfa && { sfa: values.sfa.trim() }),
            },
          })}
          initialValues={initialFormValues}
          scrollToFirstError={true}
        >
          <Space direction="vertical">
            <Space size={0}>
              <Form.Item
                label="契約名"
                name="contractName"
                rules={[
                  { required: true, message: '契約名は必須項目です。' },
                  { whitespace: true, message: '契約名は必須項目です。' },
                  { max: 50, message: '契約名が長すぎます。（最大は50桁です）' },
                ]}
                labelAlign="left"
                colon={false}
              >
                <Input style={{ width: 350 }} />
              </Form.Item>
            </Space>
            <Space size={0}>
              <Form.Item
                label="企業名"
                name="name"
                rules={[
                  { required: true, message: '企業名は必須項目です。' },
                  { whitespace: true, message: '企業名は必須項目です。' },
                  { max: 50, message: '企業名が長すぎます。（最大は50桁です）' },
                ]}
                labelAlign="left"
                colon={false}
              >
                <Input style={{ width: 350 }} />
              </Form.Item>
            </Space>
            <Space size={0}>
              <Form.Item
                label="企業管理者Email"
                name="adminEmail"
                normalize={(value) => value.trim()}
                validateFirst
                rules={[
                  { required: true, message: '企業管理者Emailは必須項目です。' },
                  { type: 'email', message: 'メールアドレスのフォーマットが正しくありません。' },
                  { max: 50, message: '企業管理者Emailが長すぎます。（最大は50桁です）' },
                ]}
                labelAlign="left"
                colon={false}
              >
                <Input style={{ width: 350 }} />
              </Form.Item>
            </Space>
            <Space size={0}>
              <Form.Item
                label="SFAリンク"
                name="sfa"
                rules={[
                  { max: 255, message: 'SFAリンクが長すぎます。（最大は255 桁です）' },
                  { type: 'url', message: '入力したリンクのフォーマットは正しくありません。' },
                ]}
                labelAlign="left"
                colon={false}
              >
                <Input style={{ width: 350 }} />
              </Form.Item>
            </Space>
          </Space>
          <Style.GroupAction hasSubText={!isEmpty(invitationCompany.errors)}>
            {!isEmpty(invitationCompany.errors) && (
              <Text error>{invitationCompany.errors[0].message}</Text>
            )}
            <Button
              type="primary"
              htmlType="submit"
              disabled={isDisableRequest}
            >
              登録
            </Button>
          </Style.GroupAction>
        </Style.FormWrapper>
      </Style.TabWrapperContent>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => {
  const { invitationCompany } = state.accountReducer;
  return {
    invitationCompany,
  };
};

const mapDispatchToProps = (dispatch) => ({
  inviteCompany: (params) => dispatch(inviteCompanyAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyRegistration);
