// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';
import { isArray, isEmpty } from 'lodash/lang';
import _get from "lodash/get"

import {
  MODAL_ACTION,
  ADMIN_SERVICE,
  COMMON_ACTION,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';
import { ServicePath } from 'src/constants/routerConstants';

import { courseServices } from 'src/services';
import history from 'src/utils/history';
import { ServerTimeStorage } from 'src/utils/localStorge';
import { downloadCsvFromArray } from 'src/utils/common'

function* getCourseDetailSaga(action) {
  try {
    const { isCloseModal, redirect, requestData, ...payload } = action.payload;
    const result = yield courseServices.getCourseDetail({ requestData, ...payload });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL),
      payload: {
        ...result,
        requestData,
      },
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (isCloseModal) {
      yield put({
        type: REQUEST(MODAL_ACTION.CLOSE),
      });
    }
    if (redirect) yield history.push(redirect);
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getGroupRecommendedDateSaga(action) {
  try {
    const result = yield courseServices.getGroupRecommendedDate(action.payload)
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP_RECOMMENDED_DATE),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP_RECOMMENDED_DATE), errors: e.errors });
  }
}

function* getCourseCompanyInfoSaga(action) {
  try {
    const result = yield courseServices.getCourseCompanyInfo(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_COMPANY_INFO),
      payload: result,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_COMPANY_INFO), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getGroupDetailSaga(action) {
  try {
    const result = yield courseServices.getGroupDetail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP),
      payload: {
        data: result.data,
        meta: {
          ...result.meta,
          groupIndex: action.payload.groupIndex,
        },
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP), errors: e.errors });
  }
}

function* getLearningStatusSaga(action) {
  try {
    const result = yield courseServices.getLearningStatus(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_STATUS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_STATUS), errors: e.errors });
  }
}

function* getLearningChartSaga(action) {
  try {
    const result = yield courseServices.getLearningChart(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_CHART), errors: e.errors });
  }
}

function* getLearningUserProgressSaga(action) {
  try {
    const result = yield courseServices.getLearningUserProgress(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_USER_PROGRESS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_USER_PROGRESS), errors: e.errors });
  }
}

// Learning tab
function* getAttendanceStatusSaga(action) {
  try {
    const result = yield courseServices.getAttendanceStatus(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_STATUS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_STATUS), errors: e.errors });
  }
}

function* getCourseStudentsSaga(action) {
  try {
    const result = yield courseServices.getCourseStudents(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_STUDENT_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_STUDENT_LIST), errors: e.errors });
  }
}

function* updateStudentCoursesDurationSaga(action) {
  try {
    yield courseServices.updateStudentCoursesDuration(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION),
      payload: {
        data: {
          id: action.payload.requestData.userId,
          startDate: action.payload.requestData.startDate,
          endDate: action.payload.requestData.endDate,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION), errors: e.errors });
  }
}

function* getAttendanceChartSaga(action) {
  try {
    const result = yield courseServices.getCourseChartData(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_CHART), errors: e.errors });
  }
}

// Test score tab
function* getTestScoreOverviewSaga(action) {
  try {
    const result = yield courseServices.getTestScoreOverview(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW), errors: e.errors });
  }
}

function* getTestScoreChartSaga(action) {
  try {
    const result = yield courseServices.getTestScoreChart(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_CHART), errors: e.errors });
  }
}

function* getTestScoreStudentsSaga(action) {
  try {
    const result = yield courseServices.getTestScoreStudents(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_STUDENTS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_STUDENTS), errors: e.errors });
  }
}

// progress notification setting tab
function* updateProgressNotificationSettingSaga(action) {
  try {
    const { requestData: { companyId, ...payload } } = action.payload;
    yield courseServices.updateProgressNotificationSetting(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_PROGRESS_NOTIFICATION_SETTING),
      payload,
    });
    if (isEmpty(payload.emailWeeklySchedule)) {
      yield showAlertNotice({ type: 'success', message: '通知停止が完了しました。' });
    } else {
      yield showAlertNotice({ type: 'success', message: '通知設定が完了しました' });
    }
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: (e.errors || [])[0]?.message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_PROGRESS_NOTIFICATION_SETTING), errors: e.errors });
  }
}

// Course Action
function* createCourseSaga(action) {
  try {
    const result = yield courseServices.createCourse(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE),
      payload: result,
    });
    yield history.push(`/admin-service/course/${result.data.course.id}/edit`);
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE), errors: e.errors });
  }
}
function* updateCourseSaga(action) {
  try {
    const result = yield courseServices.updateCourse(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    if (isArray(e.errors) && e.errors[0].field !== 'version_name') {
      yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    }
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE), errors: e.errors });
  }
}

function* deleteCourseSaga(action) {
  try {
    yield courseServices.deleteCourse(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に削除されました。' });
    yield history.push(ServicePath.CourseList);
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE), errors: e.errors });
  }
}

function* copyCourseSaga(action) {
  try {
    const result = yield courseServices.copyCourse(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.COPY_COURSE),
      payload: result,
    });
    yield window.open(`/admin-service/course/${result.data.course.id}/edit`, '_blank');
    yield showAlertNotice({ type: 'success', message: 'コースのコピーに成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.COPY_COURSE), errors: e.errors });
  }
}

function* getCourseListSaga(action) {
  try {
    const params = {
      ...action.payload,
      course_category_name_cont: _get(action, "payload.course_category_name_cont", "").trim(),
      version_name_cont: _get(action, "payload.version_name_cont", "").trim(),
      version_description_cont: _get(action, "payload.version_description_cont", "").trim(),
    };
    const result = yield courseServices.getCourseList(params);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_LIST),
      payload: {
        ...result,
        more: action.payload.more,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getCourseVersionsSaga(action) {
  try {
    const requestData = {
      course_category_name_cont: _get(action, "payload.requestData.course_category_name_cont", "").trim(),
      version_name_cont: _get(action, "payload.requestData.version_name_cont", "").trim(),
      version_description_cont: _get(action, "payload.requestData.version_description_cont", "").trim(),
      category_id_eq: _get(action, "payload.requestData.category_id_eq", ""),
    };
    const result = yield courseServices.getCourseVersions({ ...action.payload, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_VERSIONS),
      payload: {
        ...result,
        courseIndex: action.payload.courseIndex,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_VERSIONS), errors: e.errors });
  }
}

// Course Unit
function* createCourseUnitSaga(action) {
  try {
    const { unitType, requestAction } = action.payload;
    const result = yield courseServices.createCourseUnit(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT),
      payload: result,
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield requestAction.setActiveKey([unitType]);
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT), errors: e.errors });
  }
}
function* updateCourseUnitSaga(action) {
  try {
    const { id, requestData, unitIndex } = action.payload;
    const result = yield courseServices.updateCourseUnit({ id, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT),
      payload: {
        data: result.data,
        meta: {
          ...result.meta,
          unitIndex,
        },
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT), errors: e.errors });
  }
}
function* deleteCourseUnitSaga(action) {
  try {
    const { requestData, unitIndex, unitType } = action.payload;
    const result = yield courseServices.deleteCourseUnit(requestData);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT),
      payload: {
        data: {
          unitVersion: {
            item: {
              type: unitType,
            },
          },
        },
        meta: {
          ...result.meta,
          unitIndex,
        },
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT), errors: e.errors });
  }
}

// Course Group
function* createCourseGroupSaga(action) {
  try {
    const result = yield courseServices.createCourseGroup(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP),
      payload: result,
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield action.payload.requestAction.setRecentlyGroupIndexCreated(
      action.payload.requestData.currentNumberGroups
    );
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP), errors: e.errors });
  }
}
function* updateCourseGroupSaga(action) {
  try {
    const { id, requestData, groupIndex } = action.payload;
    const result = yield courseServices.updateCourseGroup({ id, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP),
      payload: {
        data: {
          ...requestData,
        },
        meta: {
          ...result.meta,
          groupIndex,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP), errors: e.errors });
  }
}
function* deleteCourseGroupSaga(action) {
  try {
    const { id, groupIndex } = action.payload;
    const result = yield courseServices.deleteCourseGroup({ id });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP),
      payload: {
        meta: {
          ...result.meta,
          groupIndex,
        },
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP), errors: e.errors });
  }
}

// Group Chapter
function* createGroupChapterSaga(action) {
  try {
    const { requestData, groupIndex } = action.payload;
    const result = yield courseServices.createGroupChapter(requestData);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER),
      payload: {
        data: result.data,
        meta: {
          ...result.meta,
          groupIndex,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER), errors: e.errors });
  }
}
function* updateGroupChapterSaga(action) {
  try {
    const {
      id,
      requestData,
      groupIndex,
      chapterIndex,
    } = action.payload;
    const result = yield courseServices.updateGroupChapter({ id, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER),
      payload: {
        data: {
          ...requestData,
        },
        meta: {
          ...result.meta,
          groupIndex,
          chapterIndex,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER), errors: e.errors });
  }
}
function* deleteGroupChapterSaga(action) {
  try {
    const { id, groupIndex, chapterIndex } = action.payload;
    const result = yield courseServices.deleteGroupChapter({ id });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER),
      payload: {
        meta: {
          ...result.meta,
          groupIndex,
          chapterIndex,
        },
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER), errors: e.errors });
  }
}

// Chapter Unit
function* createChapterUnitSaga(action) {
  try {
    const {
      id,
      requestData,
      groupIndex,
      chapterIndex,
    } = action.payload;
    const result = yield courseServices.createChapterUnit({ id, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT),
      payload: {
        data: result.data,
        meta: {
          ...result.meta,
          groupIndex,
          chapterIndex,
          unitIndex: requestData.position,
        },
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT), errors: e.errors });
  }
}

function* updateChapterUnitSaga(action) {
  try {
    const {
      id,
      requestData,
      groupIndex,
      chapterIndex,
    } = action.payload;
    const result = yield courseServices.updateChapterUnit({ id, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT),
      payload: {
        data: result.data,
        meta: {
          ...result.meta,
          groupIndex,
          chapterIndex,
          unitIndex: requestData.position,
        },
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT), errors: e.errors });
  }
}
function* deleteChapterUnitSaga(action) {
  try {
    const {
      requestData,
      groupIndex,
      chapterIndex,
      unitIndex,
    } = action.payload;
    const result = yield courseServices.deleteChapterUnit(requestData);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT),
      payload: {
        meta: {
          ...result.meta,
          groupIndex,
          chapterIndex,
          unitIndex,
        },
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_OVERVIEW),
      payload: {
        updater: result.data.course.lastUpdater,
        updatedAt: result.data.course.updatedAt,
        contentOverview: result.data.course.contentOverview,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT), errors: e.errors });
  }
}

//  FQA Chapter Course
function* getChapterFAQsSaga(action) {
  try {
    const result = yield courseServices.getChapterFAQs(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_CHAPTER_FAQS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_CHAPTER_FAQS), errors: e.errors });
  }
}

function* createChapterFAQSaga(action) {
  try {
    const result = yield courseServices.createChapterFAQ(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ), errors: e.errors });
  }
}

function* deleteChapterFAQSaga(action) {
  try {
    const { id } = action.payload;
    yield courseServices.deleteChapterFAQ(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ),
      payload: {
        data: {
          id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に削除されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ), errors: e.errors });
  }
}

function* moveChapterFAQSaga(action) {
  try {
    const { chapterId, requestData, dragIndex, hoverIndex } = action.payload;
    yield courseServices.moveChapterFAQ({ chapterId, requestData });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.MOVE_CHAPTER_FAQ),
      payload: {
        data: {
          dragIndex,
          hoverIndex,
        },
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.MOVE_CHAPTER_FAQ), errors: e.errors });
  }
}

// Courses by company
function* getCoursesByCompanySaga(action) {
  try {
    const result = yield courseServices.getCoursesByCompany(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY), errors: e.errors });
  }
}

// Courses FAQ Chapter

function* getCourseFAQsSaga(action) {
  try {
    const result = yield courseServices.getCourseFAQs(action.payload);
    const { data, meta } = result;
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_FAQS),
      payload: {
        data,
        meta,
        more: !!action.payload.more,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_FAQS), errors: e.errors });
  }
}

function* getOverviewGroupDetailSaga(action) {
  try {
    const { requestData, meta } = action.payload;
    const result = yield courseServices.getOverviewGroupDetail(requestData);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL),
      payload: result,
    });
    if (meta.type) {
      const newCollapseData = meta.collapseData;
      if (meta.type === 'next') {
        newCollapseData.splice(meta.groupActiveIndex + 1, 0, {
          key: `group-${meta.groupDataIndex + 1}`,
          chapters: [
            {
              key: `group-${meta.groupDataIndex + 1}-chapter-0`,
            },
          ],
        });
      } else {
        const chaptersLength = result.data.group.chapters.length;
        newCollapseData.splice(meta.groupActiveIndex - 1, 0, {
          key: `group-${meta.groupDataIndex - 1}`,
          chapters: [
            {
              key: `group-${meta.groupDataIndex - 1}-chapter-${chaptersLength - 1}`,
            },
          ],
        });
      }
      yield put({
        type: REQUEST(COMMON_ACTION.SET_COLLAPSE_DATA),
        payload: newCollapseData,
      });
    }
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL), errors: e.errors });
  }
}

// Master Data course
function* getMasterDataCoursesSaga(action) {
  try {
    const result = yield courseServices.getMasterDataCourses(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSES),
      payload: {
        data: result.data,
        meta: result.meta,
        more: !!action.payload.more,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSES), errors: e.errors });
  }
}

function* getMasterDataCourseListSaga(action) {
  try {
    const result = yield courseServices.getMasterDataCourseList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSE_LIST),
      payload: {
        ...result,
        more: action.payload.more,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSE_LIST), errors: e.errors });
  }
}

function* getOverviewCourseContentSaga(action) {
  try {
    const result = yield courseServices.getOverviewCourseContent(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_COURSE_CONTENT),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_COURSE_CONTENT), errors: e.errors });
  }
}

// Change course content position
function* changeGroupPositionSaga(action) {
  try {
    const result = yield courseServices.changeGroupPosition(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CHANGE_GROUP_POSITION),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CHANGE_GROUP_POSITION), errors: e.errors });
  }
}

function* changeChapterPositionSaga(action) {
  try {
    const result = yield courseServices.changeChapterPosition(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CHANGE_CHAPTER_POSITION),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CHANGE_CHAPTER_POSITION), errors: e.errors });
  }
}

function* changeUnitPositionSaga(action) {
  try {
    const result = yield courseServices.changeUnitPosition(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CHANGE_UNIT_POSITION),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CHANGE_UNIT_POSITION), errors: e.errors });
  }
}

// Course categories/version

function* getVersionsOfCourseSaga(action) {
  try {
    const result = yield courseServices.getCourseVersions(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.GET_VERSIONS_OF_COURSE),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.GET_VERSIONS_OF_COURSE), errors: e.errors });
  }
}

function* createCourseVersionSaga(action) {
  try {
    const {
      courseCollapseIndex,
      requestAction,
    } = action.payload;
    const result = yield courseServices.createCourseVersion(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_VERSION),
      payload: result,
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield requestAction.setIndexCourseWillOpen(courseCollapseIndex);
    yield requestAction.setIsReloadVersionList(true);
    yield history.push(`/admin-service/course/${result.data.course.id}/edit`);
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: (e.errors || [])[0]?.message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_VERSION), errors: e.errors });
  }
}

function* updateCourseNameSaga(action) {
  try {
    const {
      requestData,
      courseIndex,
    } = action.payload;
    const result = yield courseServices.updateCourseName(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME),
      payload: {
        data: requestData,
        courseIndex,
        meta: result.meta,
      },
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: (e.errors || [])[0]?.message });
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME), errors: e.errors });
  }
}

function* downloadCsvStudyStudentSaga(action) {
  try {
    const result = yield courseServices.downloadCsvStudyStudent(action.payload);
    const blob = new Blob([result]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    const serverTime = ServerTimeStorage.getData();
    link.href = url;
    link.setAttribute("download", `Learning_Data_${serverTime}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    if(action.payload?.closeModal){
      action.payload.closeModal()
    }
    yield showAlertNotice({
      type: "success",
      message: "ダウンロードに成功しました。"
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT)
    });
  } catch (e) {
    if(action.payload?.closeModal){
      action.payload.closeModal()
    }
    yield showAlertNotice({
      type: "error",
      message: (e.errors || [])[0]?.message || "ダウンロードに失敗しました。",
    });
    yield put({
      type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT)
    });
  }
}

function* downloadCsvTestStudentSaga(action){
  try {
    const result = yield courseServices.downloadCsvTestsStudent(action.payload)
    const serverTime = ServerTimeStorage.getData();
    const newResult = result.data.testsUsers.map(item => {
      return {
        course_name: item.courseName ?? "",
        department: item.department ?? "",
        name: item.name ?? "",
        email: item.email ?? "",
        test_type: item.testType ?? "",
        lesson_name: item.lessonName ?? "",
        score: item.score ?? "",
        score_percentage: `${item.testScorePercentage}%` ?? "",
        done_at: item.doneAt ?? "",
        banned_date: item.bannedDate ?? "",
      }
    })

    yield downloadCsvFromArray(newResult,`Test_Data_${serverTime}.csv`)
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_TESTS),
    });
    yield showAlertNotice({
      type: "success",
      message: "ダウンロードに成功しました。"
    });
  } catch (e) {
    yield showAlertNotice({
      type: "error",
      message: (e.errors || [])[0]?.message || "ダウンロードに失敗しました。",
    });
    yield put({
      type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_TESTS)
    });
  }
  finally{
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  }
}

function* updatePositionCourseSaga(action) {
  try {
    const result = yield courseServices.updatePositionCourse(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.COURSE_ACTION.UPDATE_POSITION_COURSE),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.COURSE_ACTION.UPDATE_POSITION_COURSE), errors: e.errors });
  }
}

export default function* courseSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL), getCourseDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_COMPANY_INFO), getCourseCompanyInfoSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP_RECOMMENDED_DATE), getGroupRecommendedDateSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP), getGroupDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LIST), getCourseListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL), getOverviewGroupDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_VERSIONS), getCourseVersionsSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_STATUS), getAttendanceStatusSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_CHART), getAttendanceChartSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_STUDENT_LIST), getCourseStudentsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION), updateStudentCoursesDurationSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW), getTestScoreOverviewSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_CHART), getTestScoreChartSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_STUDENTS), getTestScoreStudentsSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE), createCourseSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE), updateCourseSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE), deleteCourseSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.COPY_COURSE), copyCourseSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT), createCourseUnitSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT), updateCourseUnitSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT), deleteCourseUnitSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP), createCourseGroupSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP), updateCourseGroupSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP), deleteCourseGroupSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER), createGroupChapterSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER), updateGroupChapterSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER), deleteGroupChapterSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT), createChapterUnitSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT), updateChapterUnitSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT), deleteChapterUnitSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ), createChapterFAQSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_CHAPTER_FAQS), getChapterFAQsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ), deleteChapterFAQSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.MOVE_CHAPTER_FAQ), moveChapterFAQSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_FAQS), getCourseFAQsSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY), getCoursesByCompanySaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL), getOverviewGroupDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_COURSE_CONTENT), getOverviewCourseContentSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSES), getMasterDataCoursesSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSE_LIST), getMasterDataCourseListSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CHANGE_GROUP_POSITION), changeGroupPositionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CHANGE_CHAPTER_POSITION), changeChapterPositionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CHANGE_UNIT_POSITION), changeUnitPositionSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_VERSIONS_OF_COURSE), getVersionsOfCourseSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_VERSION), createCourseVersionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME), updateCourseNameSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT), downloadCsvStudyStudentSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_PROGRESS_NOTIFICATION_SETTING), updateProgressNotificationSettingSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_TESTS), downloadCsvTestStudentSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_POSITION_COURSE), updatePositionCourseSaga),

    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_STATUS), getLearningStatusSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_CHART), getLearningChartSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_USER_PROGRESS), getLearningUserProgressSaga)
  ]);
}
