// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { isEmpty } from 'lodash';

import history from 'src/utils/history';
import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';
import SortableHeader from 'src/components/tables/SortableHeader';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT, TEST_TYPE } from 'src/constants/common';
import { ServicePath } from 'src/constants/routerConstants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getTestList as getTestListAction,
  getTestDetail as getTestDetailAction,
  getCategoryList as getCategoryListAction,
} from 'src/redux/actions';

interface ITestListPageProps extends StateProps, DispatchProps {
  getTestList(params): void;
  getTestDetail(params): void;
  getCategoryList(): void;
  categoryList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  testList: {
    data: {
      id: number;
      updatedAt: string;
      avgScore: number;
      category: {
        name: string;
      };
      testType: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: string;
  };
}

const TestListPage: React.FC<ITestListPageProps> = ({
  getTestList,
  getTestDetail,
  getCategoryList,
  testList,
  categoryList,
}) => {
  const { data, meta } = testList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.TestList);

    getTestList({ page: 1 });
    getCategoryList();
  }, []);

  const columns = [
    {
      title: <SortableHeader
        type="name"
        title="テスト名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      width: 300,
    },
    {
      title: <SortableHeader
        type="testType"
        title="テスト種別"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'testType',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.testType}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title: <SortableHeader
        type="category"
        title="講座タイプ"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'category',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.category}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title: <SortableHeader
        type="questionsCount"
        title="問題数"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'questionsCount',
      width: 130,
    },
    {
      title: <SortableHeader
        type="coursesCount"
        title="コース数"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'coursesCount',
      width: 130,
    },
    {
      title: <SortableHeader
        type="answersCount"
        title="総解答数"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'answersCount',
      width: 130,
    },
    {
      title: <SortableHeader
        type="avgScore"
        title="平均得点率"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'avgScore',
      width: 130,
    },
    {
      title: <SortableHeader
        type="updatedAt"
        title="更新日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTestList}
        setAction={setSortCondition}
      />,
      dataIndex: 'updatedAt',
      width: 130,
    },
  ];
  const tableData = data.map((item) => ({
    ...item,
    key: item.id,
    category: item.category?.name,
    testType: TEST_TYPE[item.testType],
    updatedAt: formatDateTime(item.updatedAt, DATE_FORMAT),
    avgScore: `${Math.floor(item.avgScore)}%`,
  }));

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '更新日',
    },
    {
      type: 'select',
      fieldParams: 'category',
      options: categoryList.data,
      title: '講座タイプ',
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: 'テスト名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getTestList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>テスト一覧</Text>
          <Button type="primary" onClick={() => history.push(ServicePath.CreateTest)}>
            新規作成
          </Button>
        </TopContent>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          columns={columns}
          scroll={{ x: 1210, y: 506 }}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => { getTestDetail({
              id: record.id,
              redirect: `/admin-service/test/${record.id}/edit`,
            })} 
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={testList.load}
        />
        {!isEmpty(testList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getTestList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  testList: state.testReducer.testList,
  categoryList: state.categoryReducer.categoryList,
});

const mapDispatchToProps = (dispatch) => ({
  getTestList: (params) => dispatch(getTestListAction(params)),
  getTestDetail: (params) => dispatch(getTestDetailAction(params)),
  getCategoryList: () => dispatch(getCategoryListAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestListPage);
