// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { isEmpty } from "lodash/lang";
import { Button, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { TooltipParagraph } from "src/components/layouts/TooltipParagraph";
import SortableHeader from "src/components/tables/SortableHeader";
import {
  closeModal as closeModalAction,
  showModal as showModalAction
} from "src/redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import { DATE_FORMAT, TEST_TYPE } from "src/constants/common";
import { PaginationLayout } from "src/components/layouts/Pagination";
import * as Style from "./styles";
import ConfirmResetModal from "src/components/Modal/components/ConfirmResetModal";
import history from 'src/utils/history';

interface ITestListProps extends DispatchProps {
  showModal(params): void;
  getTestStudentCompany(params): void;
  resetTestStudent(params): void;
  closeModal(): void;
  userId: any;
  listTestsStudent: {
    data: {
      id: number;
      name: string;
      test: {
        id: number,
        name: string,
        testType: string
      };
      category: {
        id: number;
        name: string;
      };
      course: {
        id: number;
        name: string
      }
      isDoingUnlimitedTest: boolean;
      usedAttemptsCount: number;
      testScore: number;
      testScorePercentage: number;
      lastTestingDate: string;
      isWaitingTest: boolean;
      unit: {
        id: number,
        name: string,
      };
      unitVersionAble: {
        id: number,
        type: string
      },
      manageTestStatus: string
    }[];
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
  },
  isResetSucess: boolean;
  activeKey: string;
}

const TestListStudent: React.FC<ITestListProps> = (
  {
    showModal,
    getTestStudentCompany,
    userId,
    listTestsStudent,
    resetTestStudent,
    isResetSucess,
    closeModal,
    activeKey
  }) => {

  const sortConditionUrl = history.location.state?.sortCondition || {};
  const filterParamsUrl = history.location.state?.filterParams;
  const pageUrl = history.location.state?.page;

  const [sortCondition, setSortCondition] = useState<object>({});
  const [filterParams, setFilterParams] = useState<object>({});

  useEffect(() => {
    isResetSucess && resetSuccess()
  }, [isResetSucess])

  useEffect(() => {
    if (sortConditionUrl) {
      setSortCondition(sortConditionUrl)
    }
    if (filterParamsUrl) {
      setFilterParams(filterParamsUrl)
    }
  }, [])

  useEffect(() => {
    setSortCondition(sortConditionUrl)
    setFilterParams(filterParamsUrl)
  }, [activeKey])

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey: activeKey,
        sortCondition,
        filterParams,
        page: listTestsStudent.meta.pageInfo,
        isSort: true,
      }
    })
  }, [activeKey, sortCondition, filterParams, listTestsStudent.meta.pageInfo])

  useEffect(() => {
    if(activeKey === '3'){
      getTestStudentCompany({
        user_id: userId,
        requestData: {
          sort: sortConditionUrl,
          ...pageUrl,
          items: pageUrl?.limit,
          ...filterParams
        }
      })
    }
    return () => {
      closeModal()
    }
  }, [activeKey])

  function handleBtnResetTestStudent(
    user_id,
    course_id,
    unit_version_able_id,
    unit_version_able_type,
    isDoingUnlimitedTest
  ) {
    const modalProps = {
      isClose: true,
      isOk: true,
      isDoingUnlimitedTest,
      modalData: {
        title: "テスト受講履歴のリセット",
        okeText: "リセット",
        closeText: "キャンセル",
      },

      modalAction: {
        confirm: () => {
          resetTestStudent({
            requestData: {
              user_id,
              course_id,
              unit_version_able_id,
              unit_version_able_type
            }
          });
        },
      },
    };

    return showModal(<ConfirmResetModal {...modalProps} />);
  }

  function resetSuccess() {
    const modalProps = {
      isOk: true,
      isDone: true,
      modalData: {
        title: "テスト受講履歴のリセット",
        okeText: "完了",
      },
      modalAction: {
        confirm: () => {
          closeModal()
        }
      }
    };
    return showModal(<ConfirmResetModal {...modalProps} />);
  }

  const columns = [
    {
      title: (
        <SortableHeader
          dataParam={{ user_id: userId }}
          type="unit_name"
          title="レッスン名 "
          page={listTestsStudent.meta.pageInfo?.page}
          pageSize={listTestsStudent.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getTestStudentCompany}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "name",
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      width: "28%",
    },
    {
      title: (
        <SortableHeader
          dataParam={{ user_id: userId }}
          type="testType"
          title="テスト種別"
          page={listTestsStudent.meta.pageInfo?.page}
          pageSize={listTestsStudent.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getTestStudentCompany}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "testType",
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {TEST_TYPE[record.testType]}
        </TooltipParagraph>
      ),
      width: "12%",
    }, {
      title: (
        <SortableHeader
          dataParam={{ user_id: userId }}
          type="categoryName"
          title="講座タイプ"
          page={listTestsStudent.meta.pageInfo?.page}
          pageSize={listTestsStudent.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getTestStudentCompany}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "categoryName",
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.category || "-"}
        </TooltipParagraph>
      ),
      width: "12%",
    },
    {
      title: (
        <SortableHeader
          dataParam={{ user_id: userId }}
          type="usedAttemptsCount"
          title="受講回数"
          page={listTestsStudent.meta.pageInfo?.page}
          pageSize={listTestsStudent.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getTestStudentCompany}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "usedAttemptsCount",
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.usedAttemptsCount > 0 ? record.usedAttemptsCount : "-"}
        </TooltipParagraph>
      ),
      width: "12%",
    },

    {
      title: (
        <SortableHeader
          dataParam={{ user_id: userId }}
          type="testScore"
          title="得点（得点率)"
          page={listTestsStudent.meta.pageInfo?.page}
          pageSize={listTestsStudent.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getTestStudentCompany}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "testScore",
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {
             record.isWaitingTest ? "採点待ち" : `${record.score ?? 0}点(${record.testScorePercentage ?? 0}%)`
          }
        </TooltipParagraph>
      ),
      width: "12%",
    },
    {
      title: (
        <SortableHeader
          dataParam={{ user_id: userId }}
          type="lastTestingDate"
          title="最終受講日"
          page={listTestsStudent.meta.pageInfo?.page}
          pageSize={listTestsStudent.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getTestStudentCompany}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "lastTestingDate",
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {
            !!record.lastTestingDate && moment(record.lastTestingDate).format(DATE_FORMAT)
          }
        </TooltipParagraph>
      ),
      width: "12%",
    },
    {
      render: (_, record) => {
        return (
          (
            <Tooltip
              placement="right"
              title={record.disabled ? "管理対象外のテストです" : null}
             >
              <Button ghost type="primary" onClick={() => {
                handleBtnResetTestStudent(
                  parseInt(userId), record.courseId, record.unitVersionAbleId, record.unitVersionAbleType, record.isDoingUnlimitedTest)
              }}
              disabled={record.disabled}
              >
                リセット
              </Button>
            </Tooltip>
          )
        );
      },
    },
  ];

  const dataTable = listTestsStudent.data.map((item, index) => ({
    ...item,
    usedAttemptsCount: item.usedAttemptsCount,
    key: item.unitVersionAble.id,
    id: item.id,
    courseId: item.course?.id,
    name: item.unit.name,
    testType: item.test.testType,
    category: item.category?.name,
    score: item.testScore,
    testScorePercentage: item.testScorePercentage,
    lastTestingDate: item.lastTestingDate,
    unitVersionAbleType: item.unitVersionAble.type,
    unitVersionAbleId: item.unitVersionAble.id,
    disabled: item.manageTestStatus === "disabled"
  }));

  return (
    <div>
      <Style.ListTestStudentContainer>
        <Table
          columns={columns}
          dataSource={dataTable}
          loading={listTestsStudent.load}
          pagination={false}
          scroll={{ y: 506 }}
          locale={{ emptyText: "データが存在しません。" }}
        />
      </Style.ListTestStudentContainer>
      {!isEmpty(listTestsStudent.data) && (
        <PaginationLayout
          pageInfo={listTestsStudent.meta.pageInfo}
          onChangePage={getTestStudentCompany}
          sortCondition={sortCondition}
          dataParam={{
            user_id: userId
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => { };

const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestListStudent);
