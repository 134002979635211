// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Space } from 'antd';
import { isNumber } from 'lodash/lang';

import { Text, SkeletonLoading } from 'src/components/styles';

import { convertSecondTime } from 'src/utils/dateTime';
import { CardWrapper } from 'src/components/layouts/ContentLayout';

import learningCourseIcon from 'src/assets/images/statistic/learning-course-icon.svg';
import docFileIcon from 'src/assets/images/common/doc-file-icon.svg';
import tipFileIcon from 'src/assets/images/common/tip-file-icon.svg';
import surveyFileIcon from 'src/assets/images/common/survey-file-icon.svg';

import * as Style from './styles';

interface ICourseStatusProps {
  courseTopDetailStatus: {
    data: {
      avgLearningTime: number;
      avgLearningDays: number;
      studentProgressPercentage: number | null;
      avgTestScorePercentage: {
        finishTest: number | null | string;
        preTest: number | null | string;
        verifyTest: number | null | string;
      },
      latestUnitVersion: {
        unitVersion: {
          id: number;
          item: {
            type: string;
            thumbnailUrl: string;
            url?: string;
          };
          unit: {
            name: string;
          }
        };
        course: {
          chapterId: number;
          groupId: number;
          id: number;
          name: string;
        }
      }
    };
    load: boolean;
  };
}

const CourseStatus: React.FC<ICourseStatusProps> = ({
  courseTopDetailStatus,
}) => {
  const learningTime = courseTopDetailStatus.data.avgLearningTime;
  const { latestUnitVersion } = courseTopDetailStatus.data;

  function renderHistoryImage(unitVersion) {
    switch (unitVersion.item.type) {
    case 'video':
      return unitVersion.item.thumbnailUrl;
    case 'test':
      return docFileIcon;
    case 'tip':
      return tipFileIcon;
    case 'survey':
      return surveyFileIcon;
    default:
      return undefined;
    }
  }

  function renderLatestUnit() {
    if (courseTopDetailStatus.load) {
      return (
        <Style.HistoryCardContainer>
          <Style.HistoryCardContent>
            <Style.LoadingHistoryCardImage />
            <Style.HistoryCardInfo>
              <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
              <SkeletonLoading
                active
                paragraph={{ rows: 1 }}
                title={false}
                height={16}
                margin="4px 0 0 0"
              />
            </Style.HistoryCardInfo>
          </Style.HistoryCardContent>
        </Style.HistoryCardContainer>
      );
    }
    if (latestUnitVersion) {
      return (
        <Style.HistoryCardContainer>
          <Style.HistoryCardContent
            onClick={() => window.open(
            `/learn-course/${latestUnitVersion.course.id}/${latestUnitVersion.unitVersion.item.type}/${latestUnitVersion.unitVersion.id}?group=${latestUnitVersion.course.groupId}&chapter=${latestUnitVersion.course.chapterId}`,
            '_blank',
          )}
          >
            <Style.HistoryCardImage
              isThumbnailUrl={latestUnitVersion.unitVersion.item.type === 'video'}
              image={renderHistoryImage(latestUnitVersion.unitVersion)}
            />
            <Style.HistoryCardInfo>
              <Style.ContentHistoryCard align="center">
                <img src={learningCourseIcon} alt="" />
                <Text xs w6>{latestUnitVersion.course.name}</Text>
              </Style.ContentHistoryCard>
              <Style.TextNameHistory lg truncate style={{ marginTop: 4 }}>
                {latestUnitVersion.unitVersion.unit.name}
              </Style.TextNameHistory>
            </Style.HistoryCardInfo>
          </Style.HistoryCardContent>
        </Style.HistoryCardContainer>
      );
    }
  }

  function renderStatusCards() {
    if (courseTopDetailStatus.load) {
      return [...Array(6)].map((_, statusCardIndex) => (
        <Style.OverviewContent key={`loading-status-card-${statusCardIndex}`}>
          <CardWrapper isStudent>
            <Style.OverviewCardContainer>
              <Style.OverviewCard>
                <Style.LoadingStatusCardContent>
                  <div>
                    <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={16} />
                  </div>
                  <SkeletonLoading active paragraph={false} title={{ width: 150 }} height={32} />
                </Style.LoadingStatusCardContent>
              </Style.OverviewCard>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
      ));
    } else {
      return (
        <>
          <Style.OverviewContent>
            <CardWrapper isStudent>
              <Style.OverviewCardContainer>
                <Style.OverviewCard>
                  <Text headerText lg w6>学習日数</Text>
                  <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                    {isNumber(courseTopDetailStatus.data.avgLearningDays)
                      ? `${courseTopDetailStatus.data.avgLearningDays}日`
                      : 'ー'
                    }
                  </Text>
                </Style.OverviewCard>
              </Style.OverviewCardContainer>
            </CardWrapper>
          </Style.OverviewContent>
          <Style.OverviewContent>
            <CardWrapper isStudent>
              <Style.OverviewCardContainer>
                <Style.OverviewCard>
                  <Text headerText lg w6>学習時間</Text>
                  <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                    {learningTime > 0
                      ? convertSecondTime(learningTime, 'H時間m分')
                      : '0時間0分'
                    }
                  </Text>
                </Style.OverviewCard>
              </Style.OverviewCardContainer>
            </CardWrapper>
          </Style.OverviewContent>
          <Style.OverviewContent>
            <CardWrapper isStudent>
              <Style.OverviewCardContainer>
                <Style.OverviewCard>
                  <Text headerText lg w6>進捗率</Text>
                  <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                    {isNumber(courseTopDetailStatus.data.studentProgressPercentage)
                      ? `${courseTopDetailStatus.data.studentProgressPercentage}%`
                      : 'ー'
                    }
                  </Text>
                </Style.OverviewCard>
              </Style.OverviewCardContainer>
            </CardWrapper>
          </Style.OverviewContent>
          {courseTopDetailStatus.data.avgTestScorePercentage?.preTest !== 'no_test'
            ?
            <Style.OverviewContent>
              <CardWrapper isStudent>
                <Style.OverviewCardContainer>
                  <Style.OverviewCard>
                    <Text headerText lg w6>事前テスト</Text>
                    <Text headerText lg w6>正答率</Text>
                    <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                      {isNumber(courseTopDetailStatus.data.avgTestScorePercentage?.preTest)
                        ? `${courseTopDetailStatus.data.avgTestScorePercentage?.preTest}%`
                        : 'ー'
                      }
                    </Text>
                  </Style.OverviewCard>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Style.OverviewContent>
            : ''
          }
          {courseTopDetailStatus.data.avgTestScorePercentage?.verifyTest !== 'no_test'
            ?
            <Style.OverviewContent>
              <CardWrapper isStudent>
                <Style.OverviewCardContainer>
                  <Style.OverviewCard>
                    <Text headerText lg w6>確認テスト</Text>
                    <Text headerText lg w6>正答率</Text>
                    <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                      {isNumber(courseTopDetailStatus.data.avgTestScorePercentage?.verifyTest)
                        ? `${courseTopDetailStatus.data.avgTestScorePercentage?.verifyTest}%`
                        : 'ー'
                      }
                    </Text>
                  </Style.OverviewCard>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Style.OverviewContent>
            : ''
          }
          {courseTopDetailStatus.data.avgTestScorePercentage?.finishTest !== 'no_test'
            ?
            <Style.OverviewContent>
              <CardWrapper isStudent>
                <Style.OverviewCardContainer>
                  <Style.OverviewCard>
                    <Text headerText lg w6>修了テスト</Text>
                    <Text headerText lg w6>正答率</Text>
                    <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                      {isNumber(courseTopDetailStatus.data.avgTestScorePercentage?.finishTest)
                        ? `${courseTopDetailStatus.data.avgTestScorePercentage?.finishTest}%`
                        : 'ー'
                      }
                    </Text>
                  </Style.OverviewCard>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Style.OverviewContent>
            : ''
          }
        </>
      );
    }
  }

  return (
    <>
      <Style.TextHeaderDesktop xxxl style={{ margin: '40px 0 16px' }}>受講状況</Style.TextHeaderDesktop>
      {latestUnitVersion ?
        <Style.TextHeaderMobile lg style={{ margin: '40px 0 16px' }}>受講中のレッスン</Style.TextHeaderMobile>
        : null
      }
      <Style.OverviewContainer>
        {renderLatestUnit()}
        <Style.GroupCardStatus>
          {renderStatusCards()}
        </Style.GroupCardStatus>
      </Style.OverviewContainer>
    </>
  );
};

export default CourseStatus;
