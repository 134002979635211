//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Space,
} from 'antd';

import history from 'src/utils/history';

import {
  getOverviewUnitDetail as getOverviewUnitDetailAction,
  getCourseDetail as getCourseDetailAction,
  joinOverviewTest as joinOverviewTestAction,
  showModal as showModalAction,
  closeModal as closeModalAction
} from 'src/redux/actions';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import * as Style from './styles';
import TestResultModal from 'src/components/Modal/components/TestResultModal';

interface ITestOverviewPageProps extends StateProps, DispatchProps {
  getOverviewUnitDetail(params): void;
  getCourseDetail(params): void;
  joinOverviewTest(params): void;
  showModal(params): void;
  closeModal(): void;
  overviewUnitDetail: {
    data: {
      id: number;
      name: string;
      isDone: boolean;
      item: {
        limitTime: number;
        name: string;
        description: number;
        questionsCount: number;
        testType: string;
        type: string;
        limitAttempt: number;
        usedAttemptsCount: number;
      };
      unit: {
        name: string;
      };
    };
    load: boolean;
  };
  courseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
}

const TestOverviewPage: React.FC<ITestOverviewPageProps> = ({
  overviewUnitDetail,
  courseDetail,
  getOverviewUnitDetail,
  getCourseDetail,
  joinOverviewTest,
  showModal,
  closeModal
}) => {
  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[3];
  const unitVersionId = pathnameSplit[5];
  const modalProps = localStorage.getItem("modalPropsTest");

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.TestOverview);
    modalProps && showModal(<TestResultModal {...JSON.parse(modalProps)}/>);
    localStorage.removeItem("modalPropsTest");
    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    getOverviewUnitDetail({
      courseId,
      unitVersionId,
    });
    if (parseFloat(courseId) !== courseDetail.data.id) {
      getCourseDetail({ id: courseId });
    }
  }, [courseId, unitVersionId]);

  return (
    <MainContainer isStudent>
      <MainContent fullView>
        {courseDetail.load
          ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
          : <Text white w6 xxl>{courseDetail.data.courseCategory?.name}</Text>
        }
        <Style.TestOverviewContainer>
          <Style.TestOverviewTitle>
            {overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text truncate white lg w6>{overviewUnitDetail.data.unit.name}</Text>
            }
          </Style.TestOverviewTitle>
          <Style.TestOverviewDescription>
            {overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : <Text multiLine>{overviewUnitDetail.data.item.description}</Text>
            }
          </Style.TestOverviewDescription>
          <Style.TestOverviewFooter>
            <Space align="center" size={32}>
              {overviewUnitDetail.load
                ? (
                  <>
                    <Space align="center" size="large">
                      <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                    </Space>
                    <Space align="center" size="large">
                      <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                    </Space>
                    <Space align="center" size="large">
                      <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                    </Space>
                  </>
                )
                : (
                  <>
                    <Space align="center" size="large">
                      <Text xs subText w6>問題数</Text>
                      <Text headerText w6>{`${overviewUnitDetail.data.item.questionsCount}問`}</Text>
                    </Space>
                    <Space align="center" size="large">
                      <Text xs subText w6>制限時間</Text>
                      <Text headerText w6>
                        {overviewUnitDetail.data.item.limitTime > 0
                          ? `${overviewUnitDetail.data.item.limitTime / 60}分`
                          : '無制限'
                        }
                      </Text>
                    </Space>
                    <Space align="center" size="large">
                      <Text xs subText w6>受講回数</Text>
                      <Text headerText w6>
                        {overviewUnitDetail.data.item.limitAttempt === 0
                          ? '無制限'
                          : overviewUnitDetail.data.item.limitAttempt
                        }
                      </Text>
                    </Space>
                  </>
                )
              }
            </Space>
            <Button
              type="primary"
              onClick={() => joinOverviewTest({
                courseId: parseFloat(courseId),
                unitVersionId: parseFloat(unitVersionId),
                testType: overviewUnitDetail.data.item.testType,
              })}
              disabled={courseDetail.load || overviewUnitDetail.load}
            >
              テストを受ける
            </Button>
          </Style.TestOverviewFooter>
        </Style.TestOverviewContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { overviewUnitDetail } = state.unitReducer;
  const { courseDetail } = state.courseReducer;
  return {
    overviewUnitDetail,
    courseDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  joinOverviewTest: (params) => dispatch(joinOverviewTestAction(params)),
  getOverviewUnitDetail: (params) => dispatch(getOverviewUnitDetailAction(params)),
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestOverviewPage);
