// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Dropdown,
  Menu,
  Badge,
  Space,
} from 'antd';
import { UserOutlined, ProfileOutlined, LogoutOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import history from 'src/utils/history';
import { LoginStorage } from 'src/utils/localStorge';

import HeaderMenuItem from './components/HeaderMenuItem';
import NotificationDropdown from './components/NotificationDropdown';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import { Text } from 'src/components/styles/Texts';

import { ADMIN_HEADER_ITEM, COMPANY_DROPDOWN_ITEMS } from './constants';
import { ServicePath, CompanyPath, StudentPath } from 'src/constants/routerConstants';

import {
  logout as logoutAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
  getCurrentUserInfo as getCurrentUserInfoAction,
  getProfileInfo as getProfileInfoAction,
  getCompanyContractDetail as getCompanyContractDetailAction,
  getDropdownNotifications as getDropdownNotificationsAction,
} from 'src/redux/actions';

import brandLogo from 'src/assets/images/common/brand-logo.svg';
import brandName from 'src/assets/images/common/brand-name.svg';
import whiteBrandName from 'src/assets/images/common/white-brand-name.svg';

import * as Style from './styles';

interface IHeaderLayoutProps extends DispatchProps {
  logout(params): void;
  showModal(params): void;
  closeModal(): void;
  getCurrentUserInfo(): void;
  getProfileInfo(params): void;
  getCompanyContractDetail(params): void;
  getDropdownNotifications(params): void;
  currentPath: string;
  role: string;
  isRegister?: boolean;
  currentUser: {
    data: {
      avatarUrl: string;
      jituName: string;
    },
  };
  notificationUnreadCount: number;
}

const HeaderLayout: React.FC<IHeaderLayoutProps> = ({
  logout,
  showModal,
  closeModal,
  getCurrentUserInfo,
  getProfileInfo,
  getCompanyContractDetail,
  getDropdownNotifications,
  currentPath,
  role,
  isRegister,
  currentUser,
  notificationUnreadCount,
}) => {
  const [isShowNotiDropdown, setIsShowNotiDropdown] = useState(false);
  const [isShowMenuDropdown, setIsShowMenuDropdown] = useState(-1);
  const [isVisible, setIsVisible] = useState(false);

  const isStudent = role === 'student';
  const isCompany = role === 'admin_company';

  const userStorage = LoginStorage.getData();
  const location = useLocation();
  const showDrawer = () => {
    setIsVisible(true);
  };

  const onClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if (!isEmpty(userStorage)) {
      getCurrentUserInfo();
    }
  }, []);

  function handleRedirectLink(params) {
    onClose();
    const { path, state, action } = params;
    const pathnameSplit = history.location.pathname.split('/');
    const isTesting = pathnameSplit[3]?.includes('testing');
    if (isTesting) {
      const modalProps = {
        isConfirm: true,
        modalData: {
          message: 'テストを途中で修了するとテスト結果が保存されない可能性があります。テストを中断しますか？',
          title: 'テスト中断確認',
        },
        modalAction: {
          confirm: () => {
            closeModal();
            if (path) {
              if ([
                StudentPath.StudentProfile,
                CompanyPath.CompanyProfile,
                ServicePath.ServiceProfile,
              ].includes(path)) {
                return getProfileInfo({ redirect: path });
              }
              return history.push(path);
            } else {
              return action();
            }
          },
        },
      };
      return showModal(<ConfirmModal {...modalProps} />);
    } else {
      if (path) {
        switch (path) {
        case StudentPath.StudentProfile:
        case CompanyPath.CompanyProfile:
        case ServicePath.ServiceProfile: {
          return getProfileInfo({ redirect: path });
        }
        case CompanyPath.CompanyDetail:
        case CompanyPath.MasterSetting: {
          if (path !== currentPath) {
            return getCompanyContractDetail({ redirect: state ? { pathname: path, state } : path });
          }
          return history.push(state ? { pathname: path, state } : path);
        }
        default:
          return history.push(state ? { pathname: path, state } : path);
        }
      } else {
        return action();
      }
    }
  }

  function renderHeaderItems() {
    let headerItems;
    if (isCompany || isStudent) {
      headerItems = [];
    } else {
      headerItems = ADMIN_HEADER_ITEM;
    }
    return headerItems.map((headerItem, headerItemIndex) => (
      <Dropdown
        key={`header-item-${headerItemIndex}`}
        visible={isShowMenuDropdown === headerItemIndex}
        onVisibleChange={(visible) => setIsShowMenuDropdown(visible ? headerItemIndex : -1)}
        overlay={
          <HeaderMenuItem
            headerMenuIndex={headerItemIndex}
            setIsShowMenuDropdown={setIsShowMenuDropdown}
          />
        }
        placement="bottomLeft"
        trigger={['click']}
      >
        <Style.HeaderItem
          active={headerItem.path.includes(currentPath)}
        >
          <Text white={!isStudent} w6>{headerItem.title}</Text>
        </Style.HeaderItem>
      </Dropdown>
    ));
  }

  function renderCompanyDropdownItems() {
    if (!isCompany) return null;
    return COMPANY_DROPDOWN_ITEMS.map((dropdownItem, dropdownItemIndex) => (
      <Menu.Item
        key={`company-dropdown-${dropdownItemIndex}`}
        onClick={() => handleRedirectLink({
          path: dropdownItem.path,
          state: dropdownItem.state,
        })}
      >
        <Space align="center">
          <Text lg>{dropdownItem.title}</Text>
        </Space>
      </Menu.Item>
    ));
  }

  function renderMenuOnMobile() {
    let profilePath = '';
    if (isStudent) {
      profilePath = StudentPath.StudentProfile;
    } else if (isCompany) {
      profilePath = CompanyPath.CompanyProfile;
    } else {
      profilePath = ServicePath.ServiceProfile;
    }
    return (
      <Style.MenuProfile>
        <Style.ItemMenuProfile align="start" onClick={() => handleRedirectLink({ path: profilePath })}>
          <ProfileOutlined style={{ fontSize: 22 }} />
          <Text lg>プロフィール</Text>
        </Style.ItemMenuProfile>
          <Style.ItemMenuProfile align="start" onClick={() => handleRedirectLink({ action: () => logout({ role }) })}>
            <LogoutOutlined style={{ fontSize: 23 }} />
            <Text lg>ログアウト</Text>
          </Style.ItemMenuProfile>
      </Style.MenuProfile>
    );
  }
  function renderCommonDropdownItems() {
    let profilePath = '';
    if (isStudent) {
      profilePath = StudentPath.StudentProfile;
    } else if (isCompany) {
      profilePath = CompanyPath.CompanyProfile;
    } else {
      profilePath = ServicePath.ServiceProfile;
    }
    return (
      <Menu style={{ width: 190 }}>
        {renderCompanyDropdownItems()}
        <Menu.Item
          onClick={() => handleRedirectLink({ path: profilePath })}
          {...isCompany && { style: { borderTop: '1px solid #CCCCCC' } }}
        >
          <Space align="center">
            <ProfileOutlined style={{ fontSize: 16 }} />
            <Text lg>プロフィール</Text>
          </Space>
        </Menu.Item>
        <Menu.Item onClick={() => handleRedirectLink({ action: () => logout({ role }) })}>
          <Space align="center">
            <LogoutOutlined style={{ fontSize: 16 }} />
            <Text lg>ログアウト</Text>
          </Space>
        </Menu.Item>
      </Menu>
    );
  }

  return (
    <Style.HeaderContainer isStudent={isStudent}>
      <Style.BrandContent
        onClick={() => {
          let topPagePath = '';
          if (currentPath.includes('admin-service') || (location.search?.includes('admin_service') && isRegister)) {
            topPagePath = ServicePath.AdminServiceTop;
          } else if (currentPath.includes('admin') || (location.search?.includes('admin_company') && isRegister)) {
            topPagePath = CompanyPath.AdminCompanyTop;
          } else {
            topPagePath = StudentPath.StudentTop;
          }
          handleRedirectLink({ path: topPagePath });
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <img src={brandLogo} alt="" height="31px" width="auto" />
          <img src={isStudent ? brandName : whiteBrandName} style={{ marginLeft: 8 }} alt="" />
        </div>
        {(isCompany && !isRegister) && <Style.AdminBadge>管理者画面</Style.AdminBadge>}
        {(!isStudent && !isCompany && !isRegister) && <Style.AdminBadge>サービス管理者</Style.AdminBadge>}
      </Style.BrandContent>
      {!isRegister && (
        <>
          <Style.HeaderContent>
            {renderHeaderItems()}
          </Style.HeaderContent>
          <Style.Profile size={32}>
              <Dropdown
                visible={isShowNotiDropdown}
                onVisibleChange={(visible) => {
                  if (visible) getDropdownNotifications({ page: 1, items: 10 });
                  setIsShowNotiDropdown(visible);
                }}
                overlay={
                  <NotificationDropdown
                    setIsShowNotiDropdown={setIsShowNotiDropdown}
                    isStudent={isStudent}
                    isCompany={isCompany}
                  />
                }
                trigger={['click']}
                placement="bottomRight"
              >
                <Badge count={notificationUnreadCount}>
                  <Style.NotificationIcon style={{ color: isStudent ? '#343A40' : 'white' }} />
                </Badge>
              </Dropdown>
            <Dropdown
              overlay={renderCommonDropdownItems()}
              trigger={['click']}
              placement="bottomRight"
            >
              <Space>
                {currentUser?.data?.avatarUrl
                  ? <Style.ProfileAvatar image={currentUser?.data?.avatarUrl} />
                  : <Avatar size={40}><UserOutlined /></Avatar>
                }
                <Text white={!isStudent} w6 truncate style={{ maxWidth: 300 }} pointer>
                  {currentUser?.data?.jituName}
                </Text>
              </Space>
            </Dropdown>
          </Style.Profile>
          <Style.Menu onClick={showDrawer} />
          <Style.DrawerProfile placement="right" onClose={onClose} visible={isVisible}>
            {renderMenuOnMobile()}
          </Style.DrawerProfile>
        </>
      )}
    </Style.HeaderContainer>
  );
};

const mapStateToProps = (state) => {
  const { currentUser } = state.masterDataReducer;
  const { notificationUnreadCount } = state.notificationReducer;
  return {
    currentUser,
    notificationUnreadCount,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: (params) => dispatch(logoutAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  getCurrentUserInfo: () => dispatch(getCurrentUserInfoAction()),
  getProfileInfo: (params) => dispatch(getProfileInfoAction(params)),
  getCompanyContractDetail: (params) => dispatch(getCompanyContractDetailAction(params)),
  getDropdownNotifications: (params) => dispatch(getDropdownNotificationsAction(params)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLayout);
