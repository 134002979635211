// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';
import { findIndex } from 'lodash/array';

const initialState = {
  companyList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  companyDetail: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
  companyContracts: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  companyStudents: {
    data: {
      studentList: [],
      companyCustomInfos: [],
      customDisplayField: {},
    } as {
      studentList: {
        id: number;
        status: string;
      }[];
      companyCustomInfos: object[];
      customDisplayField: object;
    },
    meta: {},
    load: false,
    errors: [],
  },
  companyCourses: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  companyCustomInfos: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  companyCustomDisplay: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
};

export type CompanyReducer = Readonly<typeof initialState>;

export default (state: CompanyReducer = initialState, action: any): CompanyReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_LIST): {
    return {
      ...state,
      companyList: {
        ...state.companyList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyList: {
        data: data.companies,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      companyList: {
        ...state.companyList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyDetail: {
        data: data.company,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CONTRACTS): {
    return {
      ...state,
      companyContracts: {
        ...state.companyContracts,
        load: true,
        errors: [],
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CONTRACTS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyContracts: {
        data: data.companies,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CONTRACTS): {
    const { errors } = action;
    return {
      ...state,
      companyContracts: {
        ...state.companyContracts,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.SELECT_COMPANY_STATUS): {
    const { data } = action.payload;
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        data: {
          ...state.companyDetail.data,
          status: data.status,
        },
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.SELECT_COMPANY_STATUS): {
    const { errors } = action;
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_STUDENTS): {
    return {
      ...state,
      companyStudents: {
        ...state.companyStudents,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_STUDENTS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyStudents: {
        data: {
          studentList: data.users,
          companyCustomInfos: data.companyCustomUserInfos,
          customDisplayField: data.company.customDisplayField,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_STUDENTS): {
    const { errors } = action;
    return {
      ...state,
      companyStudents: {
        ...state.companyStudents,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_COURSES): {
    return {
      ...state,
      companyCourses: {
        ...state.companyCourses,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyCourses: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_COURSES): {
    const { errors } = action;
    return {
      ...state,
      companyCourses: {
        ...state.companyCourses,
        load: false,
        errors,
      },
    };
  }
  case REQUEST(ADMIN_SERVICE.COMPANY_ACTION.CLEAR_COMPANY_DATA): {
    return {
      ...state,
      companyDetail: {
        data: {},
        meta: {},
        load: false,
        errors: [],
      },
      companyStudents: {
        data: {
          studentList: [],
          companyCustomInfos: [],
          customDisplayField: {},
        },
        meta: {},
        load: false,
        errors: [],
      },
      companyCourses: {
        data: [],
        meta: {},
        load: false,
        errors: [],
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS): {
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: true,
      },
      companyCustomDisplay: {
        ...state.companyCustomDisplay,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyCustomInfos: {
        data: data.companyCustomUserInfos,
        meta,
        load: false,
        errors: [],
      },
      companyCustomDisplay: {
        data: data.company.customDisplayField,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS): {
    const { errors } = action;
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: false,
        errors,
      },
      companyCustomDisplay: {
        ...state.companyCustomDisplay,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyCustomInfos: {
        data: data.companyCustomUserInfos,
        meta,
        load: false,
        errors: [],
      },
      companyCustomDisplay: {
        data: data.company.customDisplayField,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS): {
    const { errors } = action;
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: false,
        errors,
      },
      companyCustomDisplay: {
        ...state.companyCustomDisplay,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS): {
    const { data } = action.payload;
    const newCompanyCustomInfos = state.companyCustomInfos.data;
    const customInfoIndex = findIndex(newCompanyCustomInfos, { id: data.id });
    newCompanyCustomInfos.splice(customInfoIndex, 1);
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        data: newCompanyCustomInfos,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS): {
    const { errors } = action;
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_INFOS): {
    const { data } = action.payload;
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        data: data.company,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.COMPANY_ACTION.BAN_COMPANY_STUDENT): {
    const { data } = action.payload;
    const newStudentList = state.companyStudents.data.studentList;
    const studentIndex = newStudentList.findIndex((item) => item.id === data.id);
    newStudentList.splice(studentIndex, 1, {
      ...state.companyStudents.data.studentList[studentIndex],
      status: 'banned',
    });
    return {
      ...state,
      companyStudents: {
        ...state.companyStudents,
        data: {
          ...state.companyStudents.data,
          studentList: newStudentList,
        },
      },
    };
  }
  default:
    return state;
  }
};
