// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { TextParagraph } from 'src/components/styles/TextParagraph';
interface ITooltipParagraphProps {
  children?: any;
  ellipsis?: any;
  style?: any;
  rows?: number;
  isPreWrap?: any;
  isEllipsis?: boolean;
  overlayStyle?: {};
  placement?: any;
  autoAdjustOverflow?: boolean
}

const TooltipParagraph: React.FC<ITooltipParagraphProps> = ({
  children,
  ellipsis,
  style,
  rows,
  isPreWrap,
  isEllipsis,
  overlayStyle,
  placement,
  autoAdjustOverflow,
  ...props
}) => {
  const [truncated, setTruncated] = useState(false);
  return (
    <Tooltip
      title={truncated ? children : undefined} 
      overlayStyle={overlayStyle} 
      placement={placement}
      autoAdjustOverflow={autoAdjustOverflow}
      >
      <TextParagraph
        style={style}
        isPreWrap={isPreWrap}
        ellipsis={ !isEllipsis ? { ...ellipsis, onEllipsis: setTruncated, rows: rows || 1 } : isEllipsis}
        {...props}
      >
        <>{children}</>
      </TextParagraph>
    </Tooltip>
  );
};
export { TooltipParagraph };
