// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from '../../constant';
import moment from 'moment';

export function createLicense(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.LICENSE_ACTION.CREATE_LICENSE),
    payload,
  };
}

export function getLicenseList(params) {
  const newParams = {
    startDateGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    startDateLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    companyIdEq: params.company || '',
    courseNameCont: (params.course || '').trim(),
    page: params?.page,
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.LICENSE_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getLicenseDetail(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.LICENSE_ACTION.GET_DETAIL),
    payload,
  };
}

export function updateLicense(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.LICENSE_ACTION.UPDATE_LICENSE),
    payload,
  };
}

export function clearLicenseData() {
  return {
    type: REQUEST(ADMIN_SERVICE.LICENSE_ACTION.CLEAR_LICENSE_DATA),
  };
}
