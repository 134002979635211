// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Input,
  Select,
  Form,
  InputNumber,
  Button,
  Row,
  Col,
} from 'antd';
import {
  CaretDownOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import * as Style from './styles';
interface IModifyAnswerProps {
  setAnswerType(params): void;
  setAnswerCount(params): void;
  setDeletedAnswers(params): void;
  setIsScored(params): void;
  answerType: string;
  answerCount: number;
  deletedAnswers: object[];
  isScored: string;
  questionForm: any;
  hasBeenUsed: boolean;
  questionIdSelected: number | string;
}

const ModifyAnswer: React.FC<IModifyAnswerProps> = ({
  setAnswerType,
  setAnswerCount,
  setDeletedAnswers,
  setIsScored,
  answerType,
  answerCount,
  deletedAnswers,
  isScored,
  questionForm,
  hasBeenUsed,
  questionIdSelected,
}) => {
  function handleChangeAnswerType(value) {
    const questionOptions = questionForm.getFieldValue('questionOptions');
    if (value === 'option_type' && !questionOptions) {
      questionForm.setFieldsValue({
        questionOptions: [
          { content: '' },
          { content: '' },
        ],
      });
    }
    if (value !== 'upload_file_type') {
      questionForm.setFieldsValue({
        isScored: 'true',
      });
      setIsScored('true');
    }
    setAnswerType(value);
  }

  function handleDeleteQuestionOption(name, answerIndex, remove) {
    const questionOptions = questionForm.getFieldValue('questionOptions');
    const correctAnswer = questionForm.getFieldValue('correctAnswer') || '';
    const correctAnswerNum = parseInt(correctAnswer.slice(correctAnswer.length - 1), 10);
    if (answerIndex + 1 <= correctAnswerNum && correctAnswerNum < questionOptions.length) {
      const newQuestionOption = questionOptions.map((question, index) => {
        if (correctAnswerNum - 1 === index && question.isRight) {
          return {
            ...question,
            isRight: false,
          };
        }
        if (correctAnswerNum === index
          && !question.isRight
          && !questionOptions[answerIndex]?.isRight
        ) {
          return {
            ...question,
            isRight: true,
          };
        }
        return question;
      });
      questionForm.setFieldsValue({ questionOptions: newQuestionOption });
    }
    if (questionOptions[answerIndex]?.id) {
      setDeletedAnswers([
        ...deletedAnswers,
        {
          ...questionOptions[answerIndex],
          isDeleted: true,
        },
      ]);
    }
    if (questionOptions[answerIndex]?.isRight) {
      questionForm.setFieldsValue({ correctAnswer: undefined });
    } else if (correctAnswer === `answer${answerCount}`) {
      questionForm.setFieldsValue({ correctAnswer: `answer${answerCount - 1}` });
    }
    remove(name);
    setAnswerCount(answerCount - 1);
  }

  function handleChangeCorrectAnswer(value) {
    let questionOptions = [];
    questionOptions = questionForm.getFieldValue('questionOptions');
    questionOptions.forEach((question: any, index) => {
      question.isRight = value === `answer${index + 1}`;
    });
    questionForm.setFieldsValue({ questionOptions });
  }

  function renderQuestionOptions() {
    return (
      <Form.List name="questionOptions">
        {(fields, { add, remove }) => (
          <div>
            {fields.map((field, answerIndex) => (
              <Style.AnswerOptionWrapper key={field.key}>
                <Form.Item
                  {...field}
                  name={[field.name, 'content']}
                  fieldKey={[field.fieldKey, 'content'] as any}
                  label={`解答${answerIndex + 1}`}
                  labelAlign="left"
                  labelCol={{ span: 3 }}
                  wrapperCol={{ span: 21 }}
                  validateFirst
                  rules={[
                    { required: true, message: `解答${answerIndex + 1}は必須項目です。` },
                    { whitespace: true, message: `解答${answerIndex + 1}は必須項目です。` },
                    { max: 100, message: `解答${answerIndex + 1}が長すぎます。（最大は100桁です）` },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const questionOptions = getFieldValue('questionOptions');
                        for (const optionIndex in questionOptions) {
                          if ((value || '').trim() === (questionOptions[optionIndex].content || '').trim()
                            && answerIndex !== parseFloat(optionIndex)
                          ) {
                            return Promise.reject('解答は重複しています。');
                          }
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  style={{ width: '100%' }}
                >
                  <Input disabled={hasBeenUsed && questionIdSelected === 'create'} />
                </Form.Item>
                {fields.length > 2 && (
                  <Button
                    type="link"
                    ghost
                    onClick={() => handleDeleteQuestionOption(field.name, answerIndex, remove)}
                    icon={<CloseCircleOutlined style={{ fontSize: 20, color: '#d25109' }} />}
                  />
                )}
              </Style.AnswerOptionWrapper>
            ))}
            {answerCount < 8 && (
              <Style.AddAnswerButton>
                <Button
                  type="link"
                  size="small"
                  disabled={hasBeenUsed && questionIdSelected === 'create'}
                  icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
                  onClick={() => {
                    add({ content: '' });
                    setAnswerCount(answerCount + 1);
                  }}
                >
                  新規追加
                </Button>
              </Style.AddAnswerButton>
            )}
          </div>
        )}
      </Form.List>
    );
  }

  function renderCorrectAnswerOption() {
    return [...Array(answerCount)].map((_, optionIndex) => (
      <Select.Option key={optionIndex} value={`answer${optionIndex + 1}`}>
        {`解答${optionIndex + 1}`}
      </Select.Option>
    ));
  }

  function renderIsScoredOption() {
    return (
      <Col
        span={8}
        {...answerType !== 'upload_file_type' && { style: { display: 'none' } }}
      >
        <Form.Item
          label="採点有無"
          name="isScored"
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          colon={false}
          rules={[
            { required: true, message: '採点有無は必須項目です。' },
          ]}
        >
          <Select
            suffixIcon={<CaretDownOutlined />}
            placeholder="選択してください"
            disabled={hasBeenUsed && questionIdSelected === 'create'}
            onChange={(value) => {
              if (value === 'false') {
                questionForm.setFieldsValue({
                  uploadScore: null,
                });
              }
              setIsScored(value);
              questionForm.setFields([{
                name: ['uploadScore'],
                errors: [],
              }]);
            }}
            allowClear
          >
            <Select.Option value="true">有り</Select.Option>
            <Select.Option value="false">無し</Select.Option>
          </Select>
        </Form.Item>
      </Col>
    );
  }

  function renderCorrectAnswer() {
    if (answerType === 'upload_file_type') return null;
    if (answerType === 'option_type') {
      return (
        <Col span={8}>
          <Form.Item
            label="正解"
            name="correctAnswer"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            colon={false}
            rules={[
              { required: true, message: '正解は必須項目です。' },
            ]}
          >
            <Select
              suffixIcon={<CaretDownOutlined />}
              placeholder="選択してください"
              disabled={hasBeenUsed && questionIdSelected === 'create'}
              onChange={(value) => handleChangeCorrectAnswer(value)}
            >
              {renderCorrectAnswerOption()}
            </Select>
          </Form.Item>
        </Col>
      );
    } else {
      return (
        <Col span={8}>
          <Form.Item
            label="正解"
            name="rightInput"
            labelCol={{ span: 9 }}
            wrapperCol={{ span: 15 }}
            colon={false}
            rules={[
              { required: true, message: '正解は必須項目です。' },
              { max: 100, message: '正解が長すぎます。（最大は100 桁です）' },
            ]}
          >
            <Input disabled={hasBeenUsed && questionIdSelected === 'create'} />
          </Form.Item>
        </Col>
      );
    }
  }

  function renderQuestionScore() {
    let scoreName;
    if (answerType === 'option_type') {
      scoreName = 'optionScore';
    } else if (answerType === 'input_type') {
      scoreName = 'inputScore';
    } else {
      scoreName = 'uploadScore';
    }
    return (
      <Col span={8} style={{ display: isScored === 'false' ? 'none' : 'block' }}>
        <Form.Item
          label="点数"
          name={scoreName}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 15 }}
          colon={false}
          validateFirst
          rules={isScored === 'false'
            ? []
            : [
              { required: true, message: '点数は必須項目です。' },
              () => ({
                validator(_, value) {
                  if (value === 0) {
                    return Promise.reject('点数は0以上で入力してください。');
                  }
                  if (value > 100) {
                    return Promise.reject('点数は100以下で入力してください。');
                  }
                  return Promise.resolve();
                },
              }),
            ]
          }
        >
          <InputNumber
            formatter={(value: any) => `${value}`.replace(/\D/g, '')}
            min={0}
            disabled={hasBeenUsed && questionIdSelected === 'create'}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
    );
  }

  return (
    <Style.AnswerContainer>
      <Style.AnswerTypeContent>
        <Row>
          <Col span={8}>
            <Form.Item
              name="questionType"
              label="解答タイプ"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              labelAlign="left"
              colon={false}
              rules={[
                { required: true, message: '解答タイプは必須項目です。' },
              ]}
            >
              <Select
                suffixIcon={<CaretDownOutlined />}
                placeholder="選択してください"
                onChange={(value) => handleChangeAnswerType(value)}
                disabled={hasBeenUsed && questionIdSelected === 'create'}
              >
                <Select.Option value="option_type">選択式</Select.Option>
                <Select.Option value="input_type">記述式</Select.Option>
                <Select.Option value="upload_file_type">アップロード</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {renderIsScoredOption()}
          {renderCorrectAnswer()}
          {renderQuestionScore()}
        </Row>
      </Style.AnswerTypeContent>
      {answerType === 'option_type' && (
        <Style.AnswerMainContent>
          {renderQuestionOptions()}
        </Style.AnswerMainContent>
      )}
    </Style.AnswerContainer>
  );
};
export default ModifyAnswer;
