// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Space,
  DatePicker,
  Checkbox,
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash/lang';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import { DATE_FORMAT } from 'src/constants/common';
import { PASSWORD_REGEX } from 'src/constants/regexValidate';
import { PAGE_TITLE, StudentTitle } from 'src/constants/pageTitle';

import {
  getStudentConfirmationStatus as getStudentConfirmationStatusAction,
  studentConfirmAccount as confirmAccountAction,
} from 'src/redux/actions';

import { getSearchParams } from 'src/utils/common';

import datePickerItem from 'src/assets/images/common/datepicker-icon.png';

import * as Style from './styles';

interface IConfirmStudentProps extends StateProps, DispatchProps {
  getStudentConfirmationStatus(params): void;
  studentConfirmAccount(params): void;
  confirmationStatus: {
    data: {
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      company: {
        id: number;
        customDisplayField: {
          department: boolean;
          jobType: boolean;
          jobTitle: boolean;
          birthday: boolean;
        };
      };
    };
  };
}

const ConfirmStudentPage: React.FC<IConfirmStudentProps > = ({
  getStudentConfirmationStatus,
  studentConfirmAccount,
  confirmationStatus,
}) => {
  const [confirmStudentForm] = Form.useForm();
  const email = getSearchParams('email');
  const registerToken = getSearchParams('confirmation_token');

  const customUserInfos = confirmationStatus.data.companyCustomUserInfos?.map((item) => ({
    companyCustomUserInfoId: item.id,
    fieldValue: '',
  }));

  const confirmStudentInitialValues = {
    customUserInfos,
  };

  useEffect(() => {
    document.title = PAGE_TITLE(StudentTitle.ConfirmStudent);

    getStudentConfirmationStatus({
      email,
      confirmationToken: registerToken,
    });
  }, []);

  useEffect(() => {
    if (confirmationStatus.data.company?.id) {
      confirmStudentForm.resetFields();
    }
  }, [confirmationStatus.data]);

  function handleSubmitForm(values) {
    const requestCustomInfos =  values.customUserInfos
      ? values.customUserInfos.map((customInfo) => ({
        companyCustomUserInfoId: customInfo.companyCustomUserInfoId,
        fieldValue: (customInfo.fieldValue || '').trim(),
      }))
      : [];
    const requestData = {
      user: {
        email,
        token: registerToken,
        password: values.password,
        role: 'student',
        jituName: values.jituName.trim(),
        department: (values.department || '').trim(),
        jobTitle: (values.jobTitle || '').trim(),
        jobType: (values.jobType || '').trim(),
        ...(!isEmpty(values.birthday) && { birthday: moment(values.birthday).format(DATE_FORMAT) }),
        customUserInfos: requestCustomInfos,
      },
    };
    studentConfirmAccount(requestData);
  }

  function renderCustomInfos() {
    return (
      <Form.List name="customUserInfos" >
        {(fields) => (
          <>
            {fields.map((field, fieldIndex) => {
              const {
                fieldName,
                isRequired,
                isDisplay,
              } = confirmationStatus.data.companyCustomUserInfos[field.fieldKey];
              return (
                <Space key={`${field.key}-${fieldIndex}`} style={{ display: 'block' }}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'companyCustomUserInfoId']}
                    fieldKey={[field.fieldKey, 'companyCustomUserInfoId'] as any}
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={fieldName}
                    name={[field.name, 'fieldValue']}
                    fieldKey={[field.fieldKey, 'fieldValue'] as any}
                    labelAlign="left"
                    colon={false}
                    rules={[
                      { ...isRequired && { required: true, whitespace: true, message: `${fieldName}は必須項目です。` } },
                      { max: 50, message: `${fieldName}が長すぎます。（最大は50桁です）` },
                    ]}
                    {...!isDisplay && { style: { display: 'none' } }}
                  >
                    <Input />
                  </Form.Item>
                </Space>
              );
            })}
          </>
        )}
      </Form.List>
    );
  }

  return (
    <MainContainer isStudent>
      <MainContent formView>
        <Style.ConfirmStudentContainer>
          <Style.ConfirmStudentTitle>
            新規登録
          </Style.ConfirmStudentTitle>
          <Style.ConfirmStudentContent>
            <Form
              form={confirmStudentForm}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              name="studentConfirmForm"
              initialValues={confirmStudentInitialValues}
              onFinish={(values) => handleSubmitForm(values)}
              scrollToFirstError={true}
            >
              <Form.Item
                label="登録アドレス"
                labelAlign="left"
                colon={false}
              >
                <Input
                  disabled
                  value={email ? email : ''}
                  readOnly
                />
              </Form.Item>
              <Form.Item
                label="名前"
                name="jituName"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, whitespace: true, message: '名前は必須項目です。' },
                  { max: 50, message: '名前が長すぎます。（最大は50桁です）' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="部署"
                name="department"
                labelAlign="left"
                colon={false}
                rules={[
                  { max: 50, message: '部署が長すぎます。（最大は50桁です）' },
                ]}
                {...!confirmationStatus.data.company?.customDisplayField.department &&
                  { style: { display: 'none' } }
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="役職"
                name="jobTitle"
                labelAlign="left"
                colon={false}
                rules={[
                  { max: 50, message: '役職が長すぎます。（最大は50桁です）' },
                ]}
                {...!confirmationStatus.data.company?.customDisplayField.jobType &&
                  { style: { display: 'none' } }
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="職種"
                name="jobType"
                labelAlign="left"
                colon={false}
                rules={[
                  { max: 50, message: '職種が長すぎます。（最大は50桁です）' },
                ]}
                {...!confirmationStatus.data.company?.customDisplayField.jobTitle &&
                  { style: { display: 'none' } }
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="生年月日"
                name="birthday"
                labelAlign="left"
                colon={false}
                {...!confirmationStatus.data.company?.customDisplayField.birthday &&
                  { style: { display: 'none' } }
                }
              >
                <DatePicker
                  placeholder=""
                  suffixIcon={<img src={datePickerItem} alt="" />}
                  style={{ width: 150 }}
                />
              </Form.Item>
              {renderCustomInfos()}
              <Form.Item
                label="パスワード"
                name="password"
                labelAlign="left"
                colon={false}
                validateFirst
                rules={[
                  { required: true, message: 'パスワードは必須項目です。' },
                  { min: 8, message: 'パスワードは8文字以上で設定して下さい。' },
                  { max: 20, message: 'パスワードが長すぎます。（最大は20桁です）' },
                  {
                    pattern: new RegExp(PASSWORD_REGEX),
                    message: 'パスワードは大文字、小文字、数字、記号から3つ以上の組み合わせで入力してください。'
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label="パスワード確認用"
                name="confirmPassword"
                dependencies={['password']}
                labelAlign="left"
                colon={false}
                validateFirst
                rules={[
                  { required: true, message: 'パスワード確認用は必須項目です。' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('新しいパスワードと確認用のパスワードが一致しません。ご確認ください。');
                    },
                  }),
                ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
              <Row>
                <Col xl={6} xs={0}/>
                <Col xl={18} xs={24}>
                  <Form.Item
                    name="agreeTerm"
                    wrapperCol={{ span: 24 }}
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) => value
                          ? Promise.resolve()
                          : Promise.reject('ご利用には利用規約とプライバシーポリシーへ同意が必要です。'),
                      },
                    ]}
                  >
                    <Style.GroupCheckbox>
                      <Checkbox />
                      <Style.GroupTextCheckbox style={{ marginLeft: 6 }}>
                        アカウントを作成すると、
                        <Style.Link
                          href="https://skillup-next.co.jp/lms-scai-terms-of-use/"
                          target="_blank"
                        >
                          利用規約
                        </Style.Link>
                        、および
                        <Style.Link
                          href="https://skillup-next.co.jp/privacy/"
                          target="_blank"
                        >
                          プライバシーポリシー
                        </Style.Link>
                        に同意したことになります。
                      </Style.GroupTextCheckbox>
                    </Style.GroupCheckbox>
                  </Form.Item>
                </Col>
              </Row>
              <Style.GroupAction>
                <Button
                  type="primary"
                  htmlType="submit"
                >
                  登録する
                </Button>
              </Style.GroupAction>
            </Form>
          </Style.ConfirmStudentContent>
        </Style.ConfirmStudentContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { confirmationStatus } = state.studentAccountReducer;
  return {
    confirmationStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentConfirmationStatus: (params) => dispatch(getStudentConfirmationStatusAction(params)),
  studentConfirmAccount: (params) => dispatch(confirmAccountAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmStudentPage);
