// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { BellOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer, Space } from 'antd';

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 56px;
  background-color: ${(props) => props.theme.colors.adminBackground};
  color: white;
  z-index: 98;

  ${(props) => props.isStudent
    && css`
      background-color: white;
      border-bottom: 1px solid ${props.theme.colors.secondaryBorder};
    `}

    @media (max-width: 428px) {
      padding: 0 12px;
    }
`;

export const BrandContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AdminBadge = styled.div`
  display: flex;
  align-items: center;
  margin-left: 16px;
  padding: 0 16px;
  height: 30px;
  background-color: #be8d06;
  color: white;
`;

export const HeaderContent = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  height: inherit;
  transform: translate(-50%, 0);
`;

export const HeaderItem = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  ${(props) => props.active
    && css`
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 4px;
        background-color: #be8d06;
      }

      &:hover {
        opacity: 1;
      }
    `}

  ${(props) => props.isStudent
    && css`
      &:hover {
        opacity: 1;

        &::after {
          content: "";
          position: absolute;
          bottom: 1px;
          width: 100%;
          height: 4px;
          border-radius: 2px;
          background-color: #be8d06;
        }
      }
    `}
`;

export const ProfileAvatar = styled.div`
  width: 40px;
  height: 40px;

  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
`;

export const NotificationIcon = styled(BellOutlined)`
  font-size: 24px;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`;

export const Profile = styled(Space)`
  @media (max-width: 428px) {
    display: none;
  }
`;

export const MenuProfile = styled.div`
  width: 190px;
  padding-top: 26px;
`;

export const ItemMenuProfile = styled(Space)`
  padding-top: 26px;
  display: flex;
  align-items: center;
`;

export const DrawerProfile = styled(Drawer)`
  display: none;

  @media (max-width: 428px) {
    display: block;

    .ant-drawer-close {
      font-size: 22px;
    }
  }
`;

export const Menu = styled(MenuOutlined)`
  display: none;
  font-size: 24px;
  color: #D9D9D9;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  @media (max-width: 428px) {
    display: block;
  }
`;

export const BrandNameDesktop = styled.img`
  @media (max-width: 428px) {
    display: none;
  }
`

export const BrandNameMobile = styled.img`
  display: none;
  @media (max-width: 428px) {
    display: block;
  }
`
