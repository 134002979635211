// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  STUDENT,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

export const initialState = {
  unitDetail: {
    data: {
      isDone: false,
      unit: {},
      item: {
        usedAttemptsCount: 0,
        latestTestsUserId: 0,
      },
    },
    meta: {},
    load: false,
    errors: [],
  },
};

export type StudentUnitReducer = Readonly<typeof initialState>;

export default (state: StudentUnitReducer = initialState, action: any): StudentUnitReducer => {
  switch (action.type) {
  case REQUEST(STUDENT.UNIT_ACTION.GET_DETAIL): {
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        load: true,
      },
    };
  }
  case SUCCESS(STUDENT.UNIT_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      unitDetail: {
        data: data.unitVersion,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.UNIT_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(STUDENT.TEST_ACTION.JOIN_TEST): {
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        data: {
          ...state.unitDetail.data,
          isDone: true,
        },
      },
    };
  }

  case SUCCESS(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS): {
    const newUsedAttemptsCount = state.unitDetail.data.item.usedAttemptsCount + 1;
    return {
      ...state,
      unitDetail: {
        ...state.unitDetail,
        data: {
          ...state.unitDetail.data,
          item: {
            ...state.unitDetail.data.item,
            usedAttemptsCount: newUsedAttemptsCount,
            latestTestsUserId: action.payload.data.testScore.id,
          },
        },
      },
    };
  }
  default:
    return state;
  }
};
