// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Table,
} from 'antd';

import {
  getCategoryList as getCategoryListAction,
  deleteCategory as deleteCategoryAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import ModifyCategoryModal from '../ModifyCategoryModal';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import * as Style from './styles';

interface ICategoryListPageProps extends StateProps, DispatchProps {
  showModal(params): void;
  getCategoryList(): void;
  deleteCategory(params): void;
  closeModal(): void;
  categoryList: {
    data: {
      id: number;
      name: string;
      description: string;
    }[];
    load: boolean;
  };
}

const CategoryListPage: React.FC<ICategoryListPageProps> = ({
  getCategoryList,
  deleteCategory,
  showModal,
  categoryList,
  closeModal
}) => {
  useEffect(() => {
    getCategoryList();

    return () => {
      closeModal()
    }
  }, []);

  function handleModifyCategory(record, actionType) {
    const modalProps = {
      modalData: {
        title: actionType === 'add' ? 'グループ新規作成' : 'グループ修正',
        category: {
          id: record?.id,
          name: record?.name,
          description: record?.description,
        },
      },
    };
    return showModal(<ModifyCategoryModal {...modalProps} />);
  }

  function handleDeleteCategory(id) {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'このグループを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deleteCategory({ id }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }


  const tableData = categoryList.data.map((row) => ({
    key: row.id,
    id: row.id,
    name: row.name,
    description: row.description,
  }));

  const columns = [
    {
      title: 'グループ名',
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 250,
    },
    {
      title: 'グループ説明',
      dataIndex: 'description',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.description}</TooltipParagraph>
      ),
      width: 400,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Style.TableAction>
          <Button
            size="small"
            danger
            ghost
            style={{ marginRight: 16 }}
            onClick={() => handleDeleteCategory(record.id)}
          >
            &nbsp;削除&nbsp;
          </Button>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() => handleModifyCategory(record, 'edit')}
          >
            &nbsp;編集&nbsp;
          </Button>
        </Style.TableAction>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Style.CategoryAction >
        <Button
          type="primary"
          onClick={() => handleModifyCategory(null, 'add')}
        >
          新規作成
        </Button>
      </Style.CategoryAction>
      <Table
        pagination={false}
        columns={columns}
        dataSource={tableData}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={categoryList.load}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { categoryList } = state.categoryReducer;
  return {
    categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  getCategoryList: () => dispatch(getCategoryListAction()),
  deleteCategory: (params) => dispatch(deleteCategoryAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListPage);
