// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';
import SortableHeader from 'src/components/tables/SortableHeader';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getTipList as getTipListAction,
  getTipDetail as getTipDetailAction,
  getTagList as getTagListAction,
  getCategoryList as getCategoryListAction,
} from 'src/redux/actions';

interface ITipListPageProps extends StateProps, DispatchProps {
  getTipList(params): void;
  getTipDetail(params): void;
  getTagList(): void;
  getCategoryList(): void;
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  tipList: {
    data: {
      id: number;
      name: string;
      updatedAt: string;
      category: {
        id: number;
        name: string;
      },
      tags: {
        id: number;
        name: string;
      }[],
      creator: {
        id: number,
        jituName: string;
      }
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const TipListPage: React.FC<ITipListPageProps> = ({
  getTipList,
  getTipDetail,
  getTagList,
  getCategoryList,
  tagList,
  categoryList,
  tipList,
}) => {
  const { data, meta } = tipList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.TipList);

    getTipList({ page: 1 });
    getTagList();
    getCategoryList();
  }, []);

  const columns = [
    {
      title: <SortableHeader
        type="name"
        title="Tips名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTipList}
        setAction={setSortCondition}
      />,
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      width: 300,
    },
    {
      title: <SortableHeader
        type="creatorName"
        title="作者名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTipList}
        setAction={setSortCondition}
      />,
      dataIndex: 'creatorName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.creatorName}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title: <SortableHeader
        type="category"
        title="講座タイプ"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTipList}
        setAction={setSortCondition}
      />,
      dataIndex: 'category',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.category}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.tags}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title: <SortableHeader
        type="updatedAt"
        title="更新日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getTipList}
        setAction={setSortCondition}
      />,
      dataIndex: 'updatedAt',
      width: 130,
    },
  ];

  const tableData = data.map((item) => {
    const tagsList = !isEmpty(item.tags)
      ? item.tags.map((tag) => tag.name)
      : [];

    return ({
      ...item,
      key: item.id,
      creatorName: item.creator?.jituName,
      category: item.category?.name,
      tags: tagsList.toString(),
      updatedAt: formatDateTime(item.updatedAt, DATE_FORMAT),
    });
  });

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '更新日',
    },
    {
      type: 'select',
      fieldParams: 'category',
      options: categoryList.data,
      title: '講座タイプ',
    },
    {
      type: 'select',
      fieldParams: 'tag',
      options: tagList.data,
      title: 'Tags',
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: 'Tips名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getTipList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>Tips一覧</Text>
          <Button type="primary" onClick={() => history.push('/admin-service/tip/new')}>
            新規作成
          </Button>
        </TopContent>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          scroll={{ y: 506 }}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {getTipDetail({
              id: record.id,
              redirect: `/admin-service/tip/${record.id}/edit`,
            })} 
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={tipList.load}
        />
        {!isEmpty(tipList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getTipList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { tipList } = state.tipReducer;
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  return {
    tipList,
    tagList,
    categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTipList: (params) => dispatch(getTipListAction(params)),
  getTipDetail: (params) => dispatch(getTipDetailAction(params)),
  getCategoryList: () => dispatch(getCategoryListAction()),
  getTagList: () => dispatch(getTagListAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TipListPage);
