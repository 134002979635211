// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CompanyContractServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }
  getCompanyList(params) {
    return this.restClient.get('/admin_manage/account_manage/companies', params);
  }

  getCompanyDetail(params) {
    return this.restClient.get(`/admin_manage/companies/${params.id}`);
  }

  getCompanyContractList() {
    return this.restClient.get('/admin_manage/companies');
  }

  selectCompanyStatus(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/companies/${id}/statuses`, requestData);
  }

  getCompanyStudents(params) {
    const { requestData, id } = params;
    return this.restClient.get(`/admin_manage/companies/${id}/users`, requestData);
  }

  getCompanyCourses(params) {
    const { requestData, id } = params;
    return this.restClient.get(`/admin_manage/companies/${id}/courses`, requestData);
  }

  getCompanyCustomInfos(params) {
    return this.restClient.get(`/admin_manage/companies/${params.id}/company_custom_user_infos`);
  }

  updateCompanyCustomInfos(params) {
    const { requestData, id } = params;
    return this.restClient.post(`/admin_manage/companies/${id}/company_custom_user_infos`, requestData);
  }

  deleteCompanyCustomInfos(params) {
    const { companyId, infoId } = params;
    return this.restClient.delete(`/admin_manage/companies/${companyId}/company_custom_user_infos/${infoId}`);
  }

  updateCompanyInfos(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/companies/${id}`, requestData);
  }
}
