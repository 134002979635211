// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Space,
  Select,
  Spin,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  Bar,
} from 'recharts';

import { CardWrapper } from 'src/components/layouts/ContentLayout';

import { SCORE_CHART_TICKS } from 'src/constants/common';
import { Text } from 'src/components/styles';

import * as Style from './styles';

interface ITestScoreChartProps {
  getTestScoreChart(params: any): void;
  companySelectedId: number;
  courseId: string | undefined;
  testScoreChart: {
    data: object[];
    load: boolean;
  };
}
const TestScoreChart: React.FC<ITestScoreChartProps> = ({
  getTestScoreChart,
  companySelectedId,
  courseId,
  testScoreChart,
}) => {

  const formattedChartData = Object.keys(testScoreChart.data).map((keyName) => ({
    name: parseFloat(keyName),
    '人数': testScoreChart.data[keyName] === 0 ? null : testScoreChart.data[keyName],
  }));

  function renderChart() {
    return (
      <Spin spinning={testScoreChart.load}>
        <Text margin="6px 0 0 0">人数（人）</Text>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={formattedChartData}
            margin={{ top: 20, right: 16, bottom: 16, left: -14 }}
          >
            <CartesianGrid vertical={false} stroke="#eeeeee" />
            <XAxis
              dataKey="name"
              type="number"
              unit="%"
              ticks={SCORE_CHART_TICKS}
              padding={{ right: 19 }}
            >
              <Label offset={-10} position="insideBottomRight">得点率（％）</Label>
            </XAxis>
            <YAxis
              dataKey="人数"
              type="number"
              domain={[0, (dataMax) => (dataMax < 4 ? 4 : dataMax)]}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              cursor={{ strokeDasharray: '3 3' }}
              labelStyle={{ color: '#D29C09' }}
              labelFormatter={(value) => `得点率: ${value}％`}
            />
            <Bar
              dataKey="人数"
              barSize={40}
              fill="#D29C09"
              unit="人"
            />
          </BarChart>
        </ResponsiveContainer>
      </Spin>
    );
  }
  return (
    <CardWrapper>
      <Style.TestScoreChart>
        <Style.InputChartField>
          <Space align="center" size={16}>
            <Select
              allowClear
              suffixIcon={<CaretDownOutlined />}
              defaultValue="all_test"
              className="transparent-input"
              style={{ marginBottom: 8, minWidth: 150, width: 'auto' }}
              onChange={(value) => {
                getTestScoreChart({ id: courseId, requestData: { testType: value, companyId: companySelectedId } });
              }}
            >
              <Select.Option value="all_test">全テスト</Select.Option>
              <Select.Option value="pre_test">事前テスト</Select.Option>
              <Select.Option value="verify_test">確認テスト</Select.Option>
              <Select.Option value="finish_test">修了テスト</Select.Option>
            </Select>
          </Space>
        </Style.InputChartField>
        {renderChart()}
      </Style.TestScoreChart>
    </CardWrapper>
  );
};

export default TestScoreChart;
