// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Space } from 'antd';
import { isNumber } from 'lodash/lang';

import { convertSecondTime } from 'src/utils/dateTime';

import { CardWrapper } from 'src/components/layouts/ContentLayout';

import { Text, SkeletonLoading } from 'src/components/styles';

import * as Style from './styles';

interface ITopOverviewProps {
  startOfWeek: any;
  endOfWeek: any;
  currentWeek: {
    data: {
      companies: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      students: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      usedLicenses: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      learningTime: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      joinedInTests: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
    };
    load: boolean;
  };
}

const TopOverview: React.FC<ITopOverviewProps> = ({
  currentWeek,
  startOfWeek,
  endOfWeek,
}) => {
  const {
    companies,
    students,
    usedLicenses,
    learningTime,
    joinedInTests,
  } = currentWeek.data;

  function renderTimeLastWeek(comparedWithLastWeek) {
    if (currentWeek.load) {
      return (
        <Space>
          <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={18} />
        </Space>
      );
    }
    if (isNumber(comparedWithLastWeek)) {
      if (comparedWithLastWeek >= 3600) {
        return (
          <Text
            headerText
            xl
            w6
          >
            {convertSecondTime(comparedWithLastWeek, 'h')}
          </Text>
        );
      } else {
        return <Text headerText xl w6>0</Text>;
      }
    } else {
      return <Text headerText xl w6>ー</Text>;
    }
  }

  function renderCountLastWeek(comparedWithLastWeek) {
    if (currentWeek.load) {
      return (
        <Space>
          <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={18} />
        </Space>
      );
    }
    if (isNumber(comparedWithLastWeek)) {
      if (comparedWithLastWeek !== 0) {
        return (
          <Text
            headerText
            xl
            w6
          >
            {comparedWithLastWeek}
          </Text>
        );
      } else {
        return <Text headerText xl w6>0</Text>;
      }
    } else {
      return <Text headerText xl w6>ー</Text>;
    }
  }

  return (
    <>
      <Text xxxl style={{ margin: '24px 0 16px' }}>
        {`今週の学習データ [ ${startOfWeek.format('MM/DD')}~${endOfWeek.format('MM/DD')} ]`}
      </Text>
      <Style.OverviewContainer>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCardTop>
                <Text headerText lg w6>契約社数</Text>
                {currentWeek.load
                  ? (
                    <Space>
                      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={64} />
                    </Space>
                  )
                  : (
                    <Text headerText h1 w6 style={{ fontSize: 64, lineHeight: '68px' }}>
                      {isNumber(companies.currentWeek) ? companies.currentWeek : 'ー'}
                    </Text>
                  )
                }
              </Style.OverviewCardTop>
              <Style.OverviewCardBottom>
                <Style.BottomCardContent>
                  <Text headerText lg w6 >累計</Text>
                  {currentWeek.load
                    ? (
                      <Space>
                        <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={18} />
                      </Space>
                    )
                    : (
                      <Text headerText xl w6>
                        {isNumber(companies.total) ? companies.total : 'ー'}
                      </Text>
                    )
                  }
                </Style.BottomCardContent>
                <Style.BottomCardLine />
                <Style.BottomCardContent>
                  <Text headerText lg w6>先週</Text>
                  {renderCountLastWeek(companies.lastWeek)}
                </Style.BottomCardContent>
              </Style.OverviewCardBottom>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCardTop>
                <Text headerText lg w6>ユーザー数</Text>
                {currentWeek.load
                  ? (
                    <Space>
                      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={64} />
                    </Space>
                  )
                  : (
                    <Text headerText h1 w6 style={{ fontSize: 64, lineHeight: '68px' }}>
                      {isNumber(students.currentWeek) ? students.currentWeek : 'ー'}
                    </Text>
                  )
                }
              </Style.OverviewCardTop>
              <Style.OverviewCardBottom>
                <Style.BottomCardContent>
                  <Text headerText lg w6>累計</Text>
                  {currentWeek.load
                    ? (
                      <Space>
                        <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={18} />
                      </Space>
                    )
                    : (
                      <Text headerText xl w6>
                        {isNumber(students.total) ? students.total : 'ー'}
                      </Text>
                    )
                  }
                </Style.BottomCardContent>
                <Style.BottomCardLine />
                <Style.BottomCardContent>
                  <Text headerText lg w6>先週</Text>
                  {renderCountLastWeek(students.lastWeek)}
                </Style.BottomCardContent>
              </Style.OverviewCardBottom>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCardTop>
                <Text headerText lg w6>コースライセンス数</Text>
                {currentWeek.load
                  ? (
                    <Space>
                      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={64} />
                    </Space>
                  )
                  : (
                    <Text headerText h1 w6 style={{ fontSize: 64, lineHeight: '68px' }}>
                      {isNumber(usedLicenses.currentWeek) ? usedLicenses.currentWeek : 'ー'}
                    </Text>
                  )
                }
              </Style.OverviewCardTop>
              <Style.OverviewCardBottom>
                <Style.BottomCardContent>
                  <Text headerText lg w6>累計</Text>
                  {currentWeek.load
                    ? (
                      <Space>
                        <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={18} />
                      </Space>
                    )
                    : (
                      <Text headerText xl w6 >
                        {isNumber(usedLicenses.total) ? usedLicenses.total : 'ー'}
                      </Text>
                    )
                  }
                </Style.BottomCardContent>
                <Style.BottomCardLine />
                <Style.BottomCardContent>
                  <Text headerText lg w6>先週</Text>
                  {renderCountLastWeek(usedLicenses.lastWeek)}
                </Style.BottomCardContent>
              </Style.OverviewCardBottom>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCardTop>
                <Text headerText lg w6>学習時間</Text>
                {currentWeek.load
                  ? (
                    <Space>
                      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={64} />
                    </Space>
                  )
                  : (
                    <Text headerText h1 w6 style={{ fontSize: 64, lineHeight: '68px' }}>
                      {learningTime.currentWeek >= 0
                        ? convertSecondTime(learningTime.currentWeek, 'h')
                        : 'ー'
                      }
                    </Text>
                  )
                }
              </Style.OverviewCardTop>
              <Style.OverviewCardBottom>
                <Style.BottomCardContent>
                  <Text headerText lg w6>累計</Text>
                  {currentWeek.load
                    ? (
                      <Space>
                        <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={18} />
                      </Space>
                    )
                    : (
                      <Text headerText xl w6>
                        {learningTime.total >= 0
                          ? convertSecondTime(learningTime.total, 'h')
                          : 'ー'
                        }
                      </Text>
                    )
                  }
                </Style.BottomCardContent>
                <Style.BottomCardLine />
                <Style.BottomCardContent>
                  <Text headerText lg w6>先週</Text>
                  {renderTimeLastWeek(learningTime.lastWeek)}
                </Style.BottomCardContent>
              </Style.OverviewCardBottom>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
        <Style.OverviewContent>
          <CardWrapper>
            <Style.OverviewCardContainer>
              <Style.OverviewCardTop>
                <Text headerText lg w6>テスト受講回数</Text>
                {currentWeek.load
                  ? (
                    <Space>
                      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={64} />
                    </Space>
                  )
                  : (
                    <Text headerText h1 w6 style={{ fontSize: 64, lineHeight: '68px' }}>
                      {isNumber(joinedInTests.currentWeek) ? joinedInTests.currentWeek : 'ー'}
                    </Text>
                  )
                }
              </Style.OverviewCardTop>
              <Style.OverviewCardBottom>
                <Style.BottomCardContent>
                  <Text headerText lg w6>累計</Text>
                  {currentWeek.load
                    ? (
                      <Space>
                        <SkeletonLoading active paragraph={false} title={{ width: 80 }} height={18} />
                      </Space>
                    )
                    : (
                      <Text headerText xl w6>
                        {isNumber(joinedInTests.total) ? joinedInTests.total : 'ー'}
                      </Text>
                    )
                  }
                </Style.BottomCardContent>
                <Style.BottomCardLine />
                <Style.BottomCardContent>
                  <Text headerText lg w6>先週</Text>
                  {renderCountLastWeek(joinedInTests.lastWeek)}
                </Style.BottomCardContent>
              </Style.OverviewCardBottom>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Style.OverviewContent>
      </Style.OverviewContainer>
    </>
  );
};

export default TopOverview;
