// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CourseServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getCourseDetail(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${id}`, requestData);
  }

  getGroupRecommendedDate(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${id}/calculate_recommended_date`, requestData);
  }

  getCourseCompanyInfo(params) {
    const { courseId, companyId } = params;
    return this.restClient.get(`/admin_manage/courses/${courseId}/courses_informations/${companyId}`);
  }

  getGroupDetail(params) {
    return this.restClient.get(`/admin_manage/groups/${params.id}`);
  }

  getCourseList(params) {
    return this.restClient.get('/admin_manage/course_categories', params);
  }

  getLearningStatus(params) {
    const { courseId, requestData } = params;

    return this.restClient.get(`/admin_manage/courses/${courseId}/statistics/learning_statuses`, requestData);
  }

  getLearningChart(params) {
    const { courseId, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${courseId}/statistics/chart`, requestData);
  }

  getLearningUserProgress(params) {
    const { courseId, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${courseId}/statistics/users_progress`, requestData);
  }

  // Learning tab
  getAttendanceStatus(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${id}/attendance_statuses`, requestData);
  }

  getCourseStudents(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${id}/users`, requestData);
  }

  getCourseChartData(params) {
    const { courseId, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${courseId}/joining_statuses`, requestData);
  }

  updateStudentCoursesDuration(params) {
    const { requestData, id } = params;
    return this.restClient.patch(`/admin_manage/courses/${id}/courses_users`, requestData);
  }

  // Test score tab
  getTestScoreOverview(params) {
    const { requestData, id } = params;
    return this.restClient.get(`/admin_manage/courses/${id}/tests_statistics/overview`, requestData);
  }

  getTestScoreChart(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${id}/tests_statistics/chart`, requestData);
  }

  getTestScoreStudents(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/admin_manage/courses/${id}/tests_statistics/users_score`, requestData);
  }

  // progress notification setting tab
  updateProgressNotificationSetting(params) {
    const { id, requestData } = params;
    return this.restClient.put(`/admin_manage/courses/${id}/setting_schedule_progress_emails`, requestData);
  }

  // Course Action
  createCourse(params) {
    return this.restClient.post('/admin_manage/course_categories', params, {}, 'formData');
  }
  updateCourse(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/courses/${id}`, requestData, {}, 'formData');
  }

  deleteCourse(params) {
    return this.restClient.delete(`/admin_manage/courses/${params.id}`);
  }

  copyCourse(params) {
    return this.restClient.post(`/admin_manage/courses/${params}/copy`, {});
  }

  // Course Unit
  createCourseUnit(params) {
    const { requestData, id } = params;
    return this.restClient.post(`/admin_manage/courses/${id}/courses_unit_versions`, requestData);
  }
  updateCourseUnit(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/courses/${id}/courses_unit_versions`, requestData);
  }
  deleteCourseUnit(params) {
    const { courseId, unitId } = params;
    return this.restClient.delete(`/admin_manage/courses/${courseId}/courses_unit_versions?unit_version_id=${unitId}`);
  }

  // Course Group
  createCourseGroup(params) {
    return this.restClient.post('/admin_manage/groups', params);
  }
  updateCourseGroup(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/groups/${id}`, requestData);
  }
  deleteCourseGroup(params) {
    return this.restClient.delete(`/admin_manage/groups/${params.id}`);
  }

  // Group Chapter
  createGroupChapter(params) {
    return this.restClient.post('/admin_manage/chapters', params);
  }
  updateGroupChapter(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/chapters/${id}`, requestData);
  }
  deleteGroupChapter(params) {
    return this.restClient.delete(`/admin_manage/chapters/${params.id}`);
  }

  // Chapter Unit
  createChapterUnit(params) {
    const { requestData, id } = params;
    return this.restClient.post(`/admin_manage/chapters/${id}/chapters_unit_versions`, requestData);
  }
  updateChapterUnit(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/chapters/${id}/chapters_unit_versions`, requestData);
  }
  deleteChapterUnit(params) {
    const { chapterId, unitId } = params;
    // tslint:disable-next-line: max-line-length
    return this.restClient.delete(`/admin_manage/chapters/${chapterId}/chapters_unit_versions?unit_version_id=${unitId}`);
  }

  //  FAQ Chapter
  getChapterFAQs(params) {
    return this.restClient.get(`/admin_manage/chapters/${params.id}/chapters_faqs`);
  }
  createChapterFAQ(params) {
    const { requestData, id } = params;
    return this.restClient.post(`/admin_manage/chapters/${id}/chapters_faqs`, requestData);
  }
  deleteChapterFAQ(params) {
    const { chapterId, id } = params;
    return this.restClient.delete(`/admin_manage/chapters/${chapterId}/chapters_faqs/?faq_id=${id}`);
  }
  moveChapterFAQ(params) {
    const { requestData, chapterId } = params;
    return this.restClient.patch(`/admin_manage/chapters/${chapterId}/chapters_faqs/position`, requestData);
  }

  // Courses by company
  getCoursesByCompany(params) {
    return this.restClient.get(`/admin_manage/companies/${params.id}/license_grant/courses`);
  }

  //  FAQ Chapter Course
  getCourseFAQs(params) {
    return this.restClient.get('admin_manage/course_contents/faqs', params);
  }

  getOverviewGroupDetail(params) {
    return this.restClient.get(`/admin_manage/take_course/groups/${params.groupId}`);
  }

  getOverviewCourseContent(params) {
    return this.restClient.get('/admin_manage/course_contents/groups', params);
  }

 // Course MasterData
  getMasterDataCourses(params) {
    return this.restClient.get('/admin_manage/master_data/courses', params);
  }

  getMasterDataCourseList(params) {
    return this.restClient.get('/admin_manage/master_data/course_categories', params);
  }

  // Change position course content
  changeGroupPosition(params) {
    const { groupId } = params;
    return this.restClient.patch(`/admin_manage/groups/${groupId}/position`, params);
  }

  changeChapterPosition(params) {
    const { chapterId } = params;
    return this.restClient.patch(`/admin_manage/chapters/${chapterId}/position`, params);
  }

  changeUnitPosition(params) {
    const { chapterId } = params;
    return this.restClient.patch(`/admin_manage/chapters/${chapterId}/chapters_unit_versions/position`, params);
  }

  // Course categories/version
  getCourseVersions(params) {
    const { courseCategoryId, requestData } = params;
    return this.restClient.get(`/admin_manage/course_categories/${courseCategoryId}/courses`,requestData);
  }

  createCourseVersion(params) {
    const { courseCategoryId, requestData } = params;
    return this.restClient.post(`/admin_manage/course_categories/${courseCategoryId}/courses`, requestData);
  }

  updateCourseName(params) {
    const { id, requestData } = params;
    return this.restClient.put(`/admin_manage/course_categories/${id}`, requestData);
  }

  downloadCsvStudyStudent({courseId, companyId}) {
    return this.restClient.get(`/admin_manage/courses/${courseId}/download_csv_learning_datas/${companyId}`,);
  }

  downloadCsvTestsStudent({courseId, companyId}) {
    return this.restClient.get(`/admin_manage/courses/${courseId}/download_csv_tests/${companyId}`,);
  }

  updatePositionCourse(params) {
    const { companyId, courseId, requestData } = params;
    return this.restClient.patch(`/admin_manage/companies/${companyId}/courses/${courseId}/position`, requestData);
  }
}
