// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';
import moment from 'moment';

export function getServiceConfirmationStatus(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS),
    payload,
  };
}

export function inviteCompany(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY),
    payload: params,
  };
}

export function inviteStudents(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS),
    payload: params,
  };
}

export function inviteServices(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES),
    payload: params,
  };
}

export function inviteAdminCompany(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY),
    payload: params,
  };
}

export function getInvitationList(params) {
  const newParams = {
    confirmationSentAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    confirmationSentAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    roleEq: params.type || '',
    companyIdEq: params.company || '',
    emailCont: (params.email || '').trim(),
    page: params.page || '',
    sort: params.sort,
    items: params?.items,
    actualRegisterStatus: params?.actualRegisterStatus
  };
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_INVITATION_LIST),
    payload: newParams,
  };
}

export function getStudentsByCompany(params) {
  const newParams = {
    ...params,
    requestData: {
      emailCont: (params.requestData.email || '').trim(),
      jituNameCont: (params.requestData.name || '').trim(),
      page: params.requestData.page || '',
      items: params?.requestData.items,
      courseId: params.requestData.courseId,
    },
  };
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY),
    payload: newParams,
  };
}

export function clearStudentsByCompany() {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.CLEAR_STUDENTS_BY_COMPANY),
  };
}

export function getAccountList(params) {
  const newParams = {
    lastLearningDateGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    lastLearningDateLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    numberOfCoursesGteq: params.numberGteq,
    numberOfCoursesLteq: params.numberLteq,
    companyIdEq: params.companyId || '',
    emailCont: (params.email || '').trim(),
    jituNameCont: (params.name || '').trim(),
    page: params.page || '',
    sort: params.sort,
    items: params?.items,
    roles: params?.roles
  };
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_ACCOUNT_LIST),
    payload: newParams,
  };
}

export function serviceConfirmAccount(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.CONFIRM_ACCOUNT),
    payload,
  };
}

export function resendInvitations(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.RESEND_INVITATION),
    payload: params,
  };
}

export function cancelInvitations(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.CANCEL_INVITATION),
    payload: params,
  };
}

export function addStudentsToCourse(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE),
    payload: params,
  };
}

export function banAccount(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BAN_ACCOUNT),
    payload,
  };
}

export function adminAddStudentsToCourseByCsv(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV),
    payload: params,
  };
}

export function bulkResendInvitations(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS),
    payload: params,
  };
}

export function bulkCancelInvitations(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS),
    payload: params,
  };
}

