// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Select,
  InputNumber,
  DatePicker,
  Tooltip,
  Radio
} from 'antd';
import {
  CaretDownOutlined,
  PlusCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { find } from 'lodash/collection';
import { isEmpty, isNumber } from 'lodash/lang';
import moment from 'moment';
import history from 'src/utils/history';
import { onScrollLoadMore, getFilterOption } from 'src/utils/common';
import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { LICENSE_DURATION_OPTION } from './constants';
import { Text, RequiredSymbol } from 'src/components/styles';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'src/constants/common';

import { CHECK_NUMBER_REGEX } from 'src/constants/regexValidate';
import { ServicePath } from 'src/constants/routerConstants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getCompanyContractList as getCompanyContractListAction,
  getMasterDataCourseList as getMasterDataCourseListAction,
  getMasterDataCourse as getMasterDataCourseAction,
  createLicense as createLicenseAction,
  updateLicense as updateLicenseAction,
  getLicenseDetail as getLicenseDetailAction,
  clearLicenseData as clearLicenseDataAction,
} from 'src/redux/actions';

import datePickerItem from 'src/assets/images/common/datepicker-icon.png';

import * as Style from './styles';

enum SETTING_RECOMMEND_DATE {
  BY_CALENDAR = 1,
  BY_NUMBER = 2,
}

interface IModifyLicensePageProps {
  getCompanyContractList(): void;
  getMasterDataCourse(params): void;
  getMasterDataCourseList(params): void;
  createLicense(params): void;
  updateLicense(params): void;
  getLicenseDetail(params): void;
  clearLicenseData(): void;
  isDisableRequest: boolean;
  match: {
    params: {
      id?: string;
    };
  };
  companyContracts: {
    data: {
      id: number;
      contractName: string;
    }[],
  };
  masterDataCourses: {
    data: {
      id: number;
      name: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
        next: number;
      };
    };
    errors: object[];
  };
  masterDataCourseCategories: {
    data: {
      id: number;
      name: string;
    }[];
    meta: {
      pageInfo: {
        next: number;
      };
    };
    load: boolean;
  };

  licenseDetail: {
    data: {
      id: number;
      name: string;
      updatedAt: string;
      lastUpdater: {
        id: number;
        jituName: string;
      }
      company: {
        id: number;
        name: string;
        contractName: string;
      };
      course: {
        id: number;
        name: string;
        courseCategory: {
          id: number;
          name: string;
        };
      };
      startDate: string;
      endDate: string;
      recommendedStartDate: string;
      recommendedEndDate: string;
      recommendedCompletionDay: number;
      licensesCount: number;
      courseDurations: string[];
      maxEndDate: string;
      minStartDate: string;
      isLearned: boolean;
      usedLicensesCount: number;
      numberDateRecommendDate: number;
    },
  };
}

const ModifyLicensePage: React.FC<IModifyLicensePageProps> = ({
  getCompanyContractList,
  getMasterDataCourseList,
  getMasterDataCourse,
  createLicense,
  updateLicense,
  getLicenseDetail,
  clearLicenseData,
  companyContracts,
  masterDataCourseCategories,
  masterDataCourses,
  licenseDetail,
  match,
  isDisableRequest
}) => {
  const licenseId = match.params.id;
  const [licenseForm] = Form.useForm();
  const { data } = licenseDetail;

  const [contractSelected, setContractSelected] = useState<any>(undefined);
  const [courseSelected, setCourseSelected] = useState<any>(undefined);
  const [versionsSelected, setVersionsSelected] = useState<any>(undefined);
  const [isPeriodSelected, setIsPeriodSelected] = useState<boolean>(false);
  const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);
  const [unitSearchKey, setCourseSearchKey] = useState<string>('');
  const [versionSearchKey, setVersionSearchKey] = useState<string>('');
  const [requestCoursePage, setRequestCoursePage] = useState<number>(masterDataCourseCategories.meta.pageInfo?.next);
  const [requestVersionPage, setRequestVersionPage] = useState<number>(masterDataCourses.meta.pageInfo?.next);
  const [recommendDateSelected, setRecommendDateSelected] = useState<any>({})
  const [totalDays, setTotalDays] = useState<number>(0);
  const [settingRecommendDateMethod, setSettingRecommendDateMethod] = useState<number>(SETTING_RECOMMEND_DATE.BY_CALENDAR);
  const urlSelectCourse = history.location.state?.course;
  const urlSelectVersion = history.location.state?.version;

  const MAX_NUMBER = 99999999;

  useEffect(() => {
    return (() => {
      clearLicenseData();
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(masterDataCourseCategories.meta.pageInfo)) {
      setRequestCoursePage(masterDataCourseCategories.meta.pageInfo?.next);
    }
  }, [masterDataCourseCategories.meta.pageInfo]);

  useEffect(() => {
    if (!isEmpty(masterDataCourses.meta.pageInfo)) {
      setRequestVersionPage(masterDataCourses.meta.pageInfo?.next);
    }
  }, [masterDataCourses.meta.pageInfo]);

  useEffect(() => {
    if (!!urlSelectCourse && !!urlSelectVersion) {
      setCourseSelected(urlSelectCourse);
      setVersionsSelected(urlSelectVersion);
    }
  }, [urlSelectCourse, urlSelectVersion]);

  useEffect(() => {
    if (licenseId) {
      document.title = PAGE_TITLE(ServiceTitle.EditLicense);
      if (parseFloat(licenseId) !== data.id) {
        getLicenseDetail({ id: licenseId });
      }
    } else {
      clearLicenseData();
      if (!urlSelectCourse || !urlSelectVersion) {
        setCourseSelected(undefined);
        setVersionsSelected(undefined);
      }
      document.title = PAGE_TITLE(ServiceTitle.CreateLicense);
    }
    getCompanyContractList();
    getMasterDataCourseList({ page: 1 });

    return () => {
      licenseForm.resetFields();
    };
  }, [licenseId]);

  useEffect(() => {
    if (data.id) {
      licenseForm.resetFields();
      setContractSelected(data.company?.id);
      setCourseSelected(data.course.courseCategory?.id);
      setVersionsSelected(data.course?.id);
      if (data.course.courseCategory?.id) {
        getMasterDataCourse({ courseCategoryId: data.course.courseCategory?.id });
      }
      if (!!licenseDetail.data.startDate && !!licenseDetail.data.endDate) {
        setIsPeriodSelected(true)
      }
    } else {
      setIsPeriodSelected(false)
    }
  }, [data.id]);

  useEffect(() =>{
    if(recommendDateSelected.startDate && recommendDateSelected.endDate){
      setTotalDays(recommendDateSelected.endDate.diff(recommendDateSelected.startDate, 'days') + 1 )
    }
    else{
      setTotalDays(0)
    }
  }, [recommendDateSelected])

  useEffect(() => {
    setRecommendDateSelected({
      startDate: licenseDetail?.data.recommendedStartDate 
        ? moment(licenseDetail?.data.recommendedStartDate)
        : undefined,
      endDate: licenseDetail?.data.recommendedEndDate
        ? moment(licenseDetail?.data.recommendedEndDate)
        : undefined
    })
    setSettingRecommendDateMethod(
      licenseDetail?.data.recommendedCompletionDay
        ? SETTING_RECOMMEND_DATE.BY_NUMBER
        : SETTING_RECOMMEND_DATE.BY_CALENDAR
    );
    licenseForm.setFieldsValue({
      recommendedCompletionDay: licenseDetail?.data?.recommendedCompletionDay,
      recommendedStartDate: licenseDetail?.data.recommendedStartDate
        ? moment(licenseDetail?.data.recommendedStartDate)
        : undefined,
      recommendedEndDate: licenseDetail?.data.recommendedEndDate
        ? moment(licenseDetail?.data.recommendedEndDate)
        : undefined,
    });
  }, [licenseDetail])


  const licenseInitialFormValues = !!urlSelectCourse
    ? {
      courseId: urlSelectCourse,
      versionId: urlSelectVersion,
      licensesCount: 50,
    }
    : {
      companyId: data.company?.id,
      courseId: (data.course?.courseCategory || {}).id
        ? {
          key: data.course.courseCategory.id,
          value: data.course.courseCategory.id,
          label: data.course.courseCategory.name,
        }
        : undefined,
      versionId: (data.course || {}).id
        ? {
          key: data.course.id,
          value: data.course.id,
          label: data.course.name,
        }
        : undefined,
      licensesCount: data.licensesCount,
      startDate: data.startDate ? moment(data.startDate, 'YYYY/MM/DD') : undefined,
      endDate: data.endDate ? moment(data.endDate, 'YYYY/MM/DD') : undefined,
      durationTypes: data.courseDurations,
      recommendedStartDate: data.recommendedStartDate ? moment(data.recommendedStartDate, 'YYYY/MM/DD') : undefined,
      recommendedEndDate: data.recommendedEndDate ? moment(data.recommendedEndDate, 'YYYY/MM/DD') : undefined,
      recommendedCompletionDay: data?.recommendedCompletionDay,
    };

  function renderCompanyOptions() {
    if (isEmpty(companyContracts.data)) return null;
    return companyContracts.data.map((company) => (
      <Select.Option key={`company-${company.id}`} value={company.id}>
        <TooltipParagraph
          style={{
            color: licenseId ? 'rgba(0, 0, 0, 0.25)' : '',
            lineHeight: 'unset',
          }}
        >
          {company.contractName}
        </TooltipParagraph>
      </Select.Option>
    ));
  }

  function renderCoursesOptions() {
    if (isEmpty(masterDataCourseCategories.data)) return null;
    if (urlSelectCourse) {
      return (
        <Select.Option key={`course-${urlSelectCourse.key}`} value={urlSelectCourse.key}>
          <TooltipParagraph
            style={{
              color: licenseId ? 'rgba(0, 0, 0, 0.25)' : '',
              lineHeight: 'unset',
            }}
          >
            {urlSelectCourse.label}
          </TooltipParagraph>
        </Select.Option>
      );
    }
    return masterDataCourseCategories.data.map((course) => (
      <Select.Option key={`version-${course.id}`} value={course.id}>
        <TooltipParagraph
          style={{
            color: licenseId ? 'rgba(0, 0, 0, 0.25)' : '',
            lineHeight: 'unset',
          }}
        >
          {course.name}
        </TooltipParagraph>
      </Select.Option>
    ));
  }

  function renderVersionOptions() {
    if (isEmpty(masterDataCourses.data)) return null;
    if (urlSelectVersion) {
      return (
        <Select.Option key={`course-${urlSelectVersion.key}`} value={urlSelectVersion.key}>
          <TooltipParagraph style={{ lineHeight: 'unset' }}>{urlSelectVersion.label}</TooltipParagraph>
        </Select.Option>
      );
    }
    return masterDataCourses.data.map((version) => (
      <Select.Option key={`version-${version.id}`} value={version.id}>
        <TooltipParagraph style={{ lineHeight: 'unset' }}>{version.name}</TooltipParagraph>
      </Select.Option>
    ));
  }

  function handleLoadMoreCourses(e) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    return onScrollLoadMore(
      {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
      !!requestCoursePage,
      () => getMasterDataCourseList({
        nameCont: unitSearchKey,
        page: requestCoursePage,
        more: true,
      }),
    );
  }

  
  function handleLoadMoreVersion(e) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    return onScrollLoadMore(
      {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
      !!requestVersionPage,
      () => getMasterDataCourse({
        courseCategoryId: courseSelected.id,
        nameCont: versionSearchKey,
        page: requestVersionPage,
        more: true,
      }),
    );
  }

  function handleSubmitForm(values) {
    const licenseRequestData = {
      coursesCompany: {
        licensesCount: values.licensesCount,
        ...(!isEmpty(values.startDate) && { startDate: moment(values.startDate).format(DATE_FORMAT) }),
        ...(!isEmpty(values.endDate) && { endDate: moment(values.endDate).format(DATE_FORMAT) }),
        durationTypes: values.durationTypes ? values.durationTypes : [],
        ...(settingRecommendDateMethod === SETTING_RECOMMEND_DATE.BY_CALENDAR
          ? {
              ...(!isEmpty(values.recommendedStartDate) && {
                recommendedStartDate: moment(
                  values.recommendedStartDate
                ).format(DATE_FORMAT),
              }),
              ...(!isEmpty(values.recommendedEndDate) && {
                recommendedEndDate: moment(values.recommendedEndDate).format(
                  DATE_FORMAT
                ),
              }),
            }
          : { recommendedCompletionDay: values.recommendedCompletionDay }),
      },
    };
    if (licenseId) {
      updateLicense({ requestData: licenseRequestData, id: licenseId });
    } else {
      const requestCreateData = {
        ...(isNumber(values.companyId) && { companyId: values.companyId }),
        courseId: values.versionId.key,
        ...licenseRequestData,
      };
      createLicense(requestCreateData);
    }
  }

  function handlePeriodChange() {
    const startDateValue = licenseForm.getFieldValue('startDate');
    const endDateValue = licenseForm.getFieldValue('endDate')
    const recommendedStartDateValue = licenseForm.getFieldValue('recommendedStartDate')
    const recommendedEndDateValue = licenseForm.getFieldValue('recommendedEndDate')
    if (startDateValue && endDateValue) {
      setIsPeriodSelected(true)
      if ((startDateValue > recommendedStartDateValue && startDateValue > recommendedEndDateValue)
        || (endDateValue < recommendedEndDateValue && endDateValue < recommendedStartDateValue)
      ) {
        licenseForm.setFieldsValue({
          recommendedStartDate: undefined,
          recommendedEndDate: undefined,
        })
      } else if (startDateValue > recommendedStartDateValue) {
        licenseForm.setFieldsValue({
          recommendedStartDate: undefined,
        })
      } else if (endDateValue < recommendedEndDateValue) {
        licenseForm.setFieldsValue({
          recommendedEndDate: undefined,
        })
      }
    } else {
      licenseForm.setFieldsValue({
        recommendedStartDate: undefined,
        recommendedEndDate: undefined,
      })
      setRecommendDateSelected({
        startDate: null,
        endDate: null
      })
      setIsPeriodSelected(false)
    }
  }

  return (
    <MainContainer>
      <MainContent fullView>
        <TopContent>
          {licenseId
            ? <Text w6 xxxl>ライセンス修正</Text>
            : (
              <div>
                <Text w6 xxxl>新規ライセンス設定</Text>
                <Text xl subText>ライセンス修正はライセンス一覧画面から選択し修正して下さい。</Text>
              </div>
            )
          }
        </TopContent>
        <Style.LicenseOverviewContainer>
          <Form
            form={licenseForm}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            {...licenseId || !!urlSelectCourse
              ? { initialValues: licenseInitialFormValues }
              : { initialValues: { status: 'opening', licensesCount: 50 } }
            }
            name="modifyLicenseForm"
            onFinish={(values) => {
              const recommendedCompletionDay = moment(values.recommendedEndDate).diff(moment(values.recommendedStartDate), 'days') + 1;
              if (recommendedCompletionDay > MAX_NUMBER) {
                return null;
              } else {
                handleSubmitForm(values);
              }
            }}
            scrollToFirstError={true}
          >
            <>
              <Style.ModifyLicenseTitle>
                <Text white w6>コース概要</Text>
              </Style.ModifyLicenseTitle>
              <Style.ModifyLicenseContainer>
                <Form.Item
                  label="契約名"
                  name="companyId"
                  labelAlign="left"
                  colon={false}
                  rules={[
                    { required: true, message: '契約名は必須項目です。' },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    suffixIcon={<CaretDownOutlined />}
                    optionFilterProp="children"
                    placeholder="選択してください"
                    notFoundContent="データが存在しません。"
                    filterOption={(input: any, option: any) => {
                      const optionTitle = option.children.props.children;
                      const filterValue = (input || '').trim();
                      return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    }}
                    onChange={(value) => {
                      setContractSelected(find(companyContracts.data, { id: value }));
                    }}
                    disabled={!isEmpty(licenseId)}
                    style={{ width: 600 }}
                  >
                    {renderCompanyOptions()}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="コース名"
                  name="courseId"
                  labelAlign="left"
                  colon={false}
                  rules={[
                    { required: true, message: 'コース名は必須項目です。' },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    labelInValue
                    suffixIcon={<CaretDownOutlined />}
                    placeholder="選択してください"
                    notFoundContent="データが存在しません。"
                    filterOption={(input: any, option: any) => {
                      const optionTitle = option.children.props.children;
                      const filterValue = (input || '').trim();
                      return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    }}
                    onSearch={(searchKey) => {
                      if (searchKey) {
                        setCourseSearchKey(searchKey);
                        getMasterDataCourseList({
                          nameCont: searchKey,
                          page: 1,
                        });
                        setRequestCoursePage(1);
                      }
                    }}
                    onChange={(option: any) => {
                      if (option && option.value) {
                        getMasterDataCourse({ courseCategoryId: option.value });
                        setCourseSelected(find(masterDataCourseCategories.data, { id: option.value }));
                      } else {
                        setCourseSelected(undefined);
                        getMasterDataCourseList({
                          nameCont: '',
                          page: 1,
                        });
                      }
                      licenseForm.setFieldsValue({ versionId: undefined });
                      setVersionsSelected(undefined);
                    }}
                    onBlur={() => {
                      if (unitSearchKey !== '') {
                        setCourseSearchKey('');
                        getMasterDataCourseList({
                          nameCont: '',
                          page: 1,
                        });
                      }
                    }}
                    onPopupScroll={(e) => handleLoadMoreCourses(e)}
                    disabled={!(isEmpty(licenseId) && !urlSelectCourse)}
                    style={{ width: 600 }}
                  >
                    {renderCoursesOptions()}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="バージョン名"
                  name="versionId"
                  labelAlign="left"
                  colon={false}
                  rules={[
                    { required: true, message: 'バージョン名は必須項目です。' },
                  ]}
                >
                  <Select
                    allowClear
                    showSearch
                    labelInValue
                    suffixIcon={<CaretDownOutlined />}
                    placeholder="選択してください"
                    notFoundContent="データが存在しません。"
                    filterOption={(input: any, option: any) => {
                      const optionTitle = option.children.props.children;
                      const filterValue = (input || '').trim();
                      return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                    }}
                    onChange={(option: any) => {
                      setVersionsSelected(find(masterDataCourses.data, { id: option?.value }));
                    }}
                    onSearch={(searchKey) => {
                      if (searchKey) {
                        setVersionSearchKey(searchKey);
                        getMasterDataCourse({
                          nameCont: searchKey,
                          page: 1,
                        });
                        setRequestVersionPage(1);
                      }
                    }}
                    onBlur={() => {
                      if (versionSearchKey !== '') {
                        setVersionSearchKey('');
                        getMasterDataCourse({
                          courseCategoryId: courseSelected.id,
                          nameCont: '',
                          page: 1,
                        });
                      }
                    }}
                    onPopupScroll={(e) => handleLoadMoreVersion(e)}
                    disabled={!courseSelected || !(isEmpty(licenseId) && !urlSelectVersion)}
                    style={{ width: 600 }}
                  >
                    {renderVersionOptions()}
                  </Select>
                </Form.Item>
              </Style.ModifyLicenseContainer>
              <Style.ModifyLicenseTitle>
                <Text white w6>ライセンス設定</Text>
              </Style.ModifyLicenseTitle>
              <Style.ModifyLicenseContainer>
                <Row>
                  <Col span={3}>
                    <Style.TextCustom><RequiredSymbol />ライセンス</Style.TextCustom>
                  </Col>
                  <Col span={21}>
                    <Space align="start" size={0}>
                      <Style.TextCustom style={{ width: 100 }}>ライセンス数</Style.TextCustom>
                      <Form.Item
                        name="licensesCount"
                        labelAlign="left"
                        colon={false}
                        validateFirst
                        rules={[
                          { required: true, message: 'ライセンス数は必須項目です。' },
                          () => ({
                            validator(_, value) {
                              if (value === 0) {
                                return Promise.reject('ライセンス数は0より大きな値で入力してください。');
                              }
                              if (value > 9999) {
                                return Promise.reject('ライセンス数は9999未満で入力してください。');
                              }
                              if (data.usedLicensesCount && data.usedLicensesCount > value) {
                                return Promise.reject('ライセンス数が追加したユーザ数より大きい必要があります。');
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        style={{ width: 500 }}
                      >
                        <InputNumber
                          formatter={(value: any) => `${value}`.replace(/\D/g, '')}
                          parser={(value) => `${value}`.replace(CHECK_NUMBER_REGEX, '')}
                          min={0}
                          style={{ width: 250 }}
                          disabled={!versionsSelected}
                        />
                      </Form.Item>
                    </Space>
                    {licenseId && (
                      <div style={{ marginBottom: 24, height: 38 }}>
                        <Space align="start" size={0}>
                          <Text style={{ width: 100 }}>登録済み</Text>
                          <Text>{`${data.usedLicensesCount ? data.usedLicensesCount : 0}人`}</Text>
                        </Space>
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={3}>
                    <Style.TextCustom>コース期間</Style.TextCustom>
                  </Col>
                  <Col span={21}>
                    <Space align="start" size={0}>
                      <Style.TextCustom style={{ width: 100 }}>利用可能期間</Style.TextCustom>
                      <Row>
                        <Col span ={8}>
                          <Tooltip
                            title="受講している受講者がいるので開始日が変更できません。"
                            overlayClassName="ant-tooltip-one-line"
                            visible={visibleTooltip}
                            onVisibleChange={() => {
                              if (licenseDetail.data.isLearned && !!licenseId) {
                                setVisibleTooltip(!visibleTooltip);
                              }
                            }}
                          >
                            <Form.Item
                              name="startDate"
                              validateTrigger={['onBlur']}
                              validateFirst
                              dependencies={['endDate']}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value && getFieldValue('endDate')) {
                                      return Promise.reject('開始日と終了日は必須項目です。');
                                    }
                                    if (!value && !getFieldValue('endDate') && licenseDetail.data.usedLicensesCount > 0) {
                                      return Promise.reject('開始日は必須項目です。');
                                    }
                                    if (!isEmpty(licenseDetail.data.minStartDate)
                                      && value > moment(licenseDetail.data.minStartDate)) {
                                      return Promise.reject(
                                        `開始日は${formatDateTime(licenseDetail.data.minStartDate, DATE_FORMAT)}よりの前の日付を入力してください。`,
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              style={{ width: 400 }}
                            >
                              <DatePicker
                                placeholder=""
                                format="YYYY/MM/DD"
                                suffixIcon={<img src={datePickerItem} alt="" />}
                                style={{ width: 250 }}
                                disabled={licenseDetail.data.isLearned || !versionsSelected}
                                disabledDate={current => current > licenseForm.getFieldValue('endDate')?.endOf('day')}
                                onChange={handlePeriodChange}
                              />
                            </Form.Item>
                          </Tooltip>
                        </Col>
                        <Col span={1} style={{ paddingTop: "7px" }}>～</Col>
                        <Col span ={8}>
                          <Form.Item
                              name="endDate"
                              validateTrigger={['onBlur']}
                              validateFirst
                              dependencies={['startDate']}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value && getFieldValue('startDate')) {
                                      return Promise.reject('開始日と終了日は必須項目です。');
                                    }
                                    if (!value && !getFieldValue('startDate') && licenseDetail.data.usedLicensesCount > 0) {
                                      return Promise.reject('終了日は必須項目です。');
                                    }
                                    if (!isEmpty(licenseDetail.data.maxEndDate)
                                      && value < moment(licenseDetail.data.maxEndDate)) {
                                      return Promise.reject(
                                        `終了日は${
                                          formatDateTime(licenseDetail.data.maxEndDate, DATE_FORMAT)
                                        }よりの後の日付を入力してください。`,
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              style={{ width: 400 }}
                            >
                              <DatePicker
                                placeholder=""
                                format="YYYY/MM/DD"
                                suffixIcon={<img src={datePickerItem} alt="" />}
                                disabled={!versionsSelected}
                                disabledDate={current => {
                                  if(licenseDetail.data.isLearned) {
                                    return current < moment().startOf('day');
                                  }
                                  return current < licenseForm.getFieldValue('startDate')?.startOf('day')
                                }}
                                style={{ width: 250 }}
                                onChange={handlePeriodChange}
                              />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <div style={{width:'100%'}}></div>
                        </Col>
                      </Row>
                    </Space>
                    <Space align="start" size={0}>
                      <Text style={{ width: 100 }}>完了推奨期間</Text>
                      <Row>
                        <Radio.Group
                          onChange={(e) => {
                            setSettingRecommendDateMethod(e.target.value);
                          }}
                          value={settingRecommendDateMethod}
                          disabled={!versionsSelected}
                        >
                          <Radio value={SETTING_RECOMMEND_DATE.BY_CALENDAR}>
                            日付で設定
                          </Radio>
                          <Radio value={SETTING_RECOMMEND_DATE.BY_NUMBER}>
                            日数で設定
                          </Radio>
                        </Radio.Group>
                      </Row>
                    </Space>
                    <Style.SettingRecommendDateWrapper>
                      {settingRecommendDateMethod ===
                      SETTING_RECOMMEND_DATE.BY_CALENDAR ? (
                        <Row>
                          <Col span={7}>
                            <Form.Item
                              name="recommendedStartDate"
                              validateTrigger={["onBlur"]}
                              validateFirst
                              dependencies={[
                                "recommendedEndDate",
                                "startDate",
                                "endDate",
                              ]}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value && getFieldValue("startDate") && getFieldValue("endDate") && getFieldValue("recommendedEndDate")) {
                                      return Promise.reject(
                                        "完了推奨期間の開始日は必須項目です。"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              style={{ width: 400 }}
                            >
                              <DatePicker
                                placeholder=""
                                format="YYYY/MM/DD"
                                suffixIcon={<img src={datePickerItem} alt="" />}
                                style={{ width: 250 }}
                                disabled={
                                  !versionsSelected || !isPeriodSelected
                                }
                                disabledDate={(current) => {
                                  if (licenseForm.getFieldValue( "recommendedEndDate" )) {
                                    return (current < licenseForm.getFieldValue("startDate")?.startOf("day") || current > licenseForm.getFieldValue("recommendedEndDate")?.endOf("day"));
                                  }
                                  return current < licenseForm.getFieldValue("startDate")?.startOf("day") || current > licenseForm.getFieldValue("endDate")?.endOf("day");
                                }}
                                onChange={(startDate) => {
                                  setRecommendDateSelected({
                                    ...recommendDateSelected,
                                    startDate,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={1} style={{ padding: "7px 0 0 4px" }}>
                            ～
                          </Col>
                          <Col span={7}>
                            <Form.Item
                              name="recommendedEndDate"
                              validateTrigger={["onBlur"]}
                              validateFirst
                              dependencies={[
                                "recommendedStartDate",
                                "startDate",
                                "endDate",
                              ]}
                              rules={[
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (!value && getFieldValue("startDate") && getFieldValue("endDate") && getFieldValue("recommendedStartDate")) {
                                      return Promise.reject(
                                        "完了推奨期間の終了日は必須項目です。"
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                }),
                              ]}
                              style={{ width: 400 }}
                            >
                              <DatePicker
                                placeholder=""
                                format="YYYY/MM/DD"
                                suffixIcon={<img src={datePickerItem} alt="" />}
                                disabled={
                                  !versionsSelected || !isPeriodSelected
                                }
                                disabledDate={(current) => {
                                  if (licenseForm.getFieldValue("recommendedStartDate")) {
                                    return (current < licenseForm.getFieldValue("recommendedStartDate")?.startOf("day") || current > licenseForm.getFieldValue("endDate")?.endOf("day"))
                                  }
                                  return (current <licenseForm.getFieldValue("startDate")?.startOf("day") || current > licenseForm.getFieldValue("endDate")?.endOf("day"));
                                }}
                                style={{ width: 250 }}
                                onChange={(endDate) => {
                                  setRecommendDateSelected({
                                    ...recommendDateSelected,
                                    endDate,
                                  });
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={7} style={{ width: "100px" }}>
                            <Button disabled={true}>
                              {totalDays > 0 ? totalDays : 0}日
                            </Button>
                          </Col>
                        </Row>
                      ) : (
                        <Form.Item
                        name="recommendedCompletionDay"
                        validateTrigger={["onBlur"]}
                        validateFirst
                        rules={[
                          () => ({
                            validator(_, value) {
                              if(isNumber(value)){
                                if (value <= 0) {
                                  return Promise.reject(
                                    "完了推奨期間は0より大きな値で入力してください。"
                                  );
                                }
                                if (value > moment(licenseForm.getFieldValue('endDate')).diff(moment(licenseForm.getFieldValue('startDate')), 'days') + 1) {
                                  return Promise.reject(
                                    "完了推奨日数はコースの利用可能日数より小さな値で入力してください。"
                                  );
                                }
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <InputNumber
                          formatter={(value: any) =>
                            `${value}`.replace(/\D/g, "")
                          }
                          parser={(value) => `${value}`.replace(CHECK_NUMBER_REGEX, '')}
                          min={0}
                          style={{ width: 250 }}
                          disabled={
                            !versionsSelected || !isPeriodSelected
                          }
                        />
                      </Form.Item>
                      )}
                    </Style.SettingRecommendDateWrapper>
                  </Col>
                  <Col span={3}>
                    <Style.TextCustom>ライセンス期間</Style.TextCustom>
                  </Col>
                  <Col span={21}>
                    <Space align="start" size={0}>
                      <Style.TextCustom style={{ width: 100 }}>期間設定</Style.TextCustom>
                      <Form.List name="durationTypes">
                        {(fields, { add, remove }) => (
                          <div>
                            {fields.map((field) => (
                              <Style.DynamicInputField key={field.key}>
                                <Form.Item
                                  {...field}
                                  rules={[
                                    { required: true, message: '期間設定は必須項目です。' },
                                  ]}
                                >
                                  <Select
                                    suffixIcon={<CaretDownOutlined />}
                                    placeholder="選択してください"
                                    notFoundContent="データが存在しません。"
                                    options={LICENSE_DURATION_OPTION}
                                    disabled={!versionsSelected}
                                    style={{ width: 250 }}
                                  />
                                </Form.Item>
                                <Style.FieldRemoveAction>
                                  <Button
                                    type="link"
                                    ghost
                                    onClick={() => remove(field.name)}
                                    icon={<CloseCircleOutlined style={{ fontSize: 20, color: '#d25109' }} />}
                                  />
                                </Style.FieldRemoveAction>
                              </Style.DynamicInputField>
                            ))}
                            <Button
                              type="dashed"
                              icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
                              disabled={!versionsSelected}
                              onClick={() => add()}
                              style={{ width: 250 }}
                            >
                              新規追加
                            </Button>
                          </div>
                        )}
                      </Form.List>
                    </Space>
                  </Col>
                </Row>
              </Style.ModifyLicenseContainer>
            </>
            <Style.ModifyLicenseAction>
              <Row style={{ width: '100%' }}>
                <Col span={3} />
                <Col span={21}>
                  <Style.SubmitActionContent hasSubText={!!licenseId}>
                    {licenseId && (
                      <Space align="center" size={32}>
                        <Space align="center" size="large">
                          <Text xs subText w6>更新者</Text>
                          <TooltipParagraph
                            style={{ maxWidth: 300, color: '#333333', fontWeight: '600' }}
                          >
                            {data.lastUpdater?.jituName}
                          </TooltipParagraph>
                        </Space>
                        <Space align="center" size="large">
                          <Text xs subText w6>更新日</Text>
                          <Text headerText w6>{formatDateTime(data.updatedAt, DATE_TIME_FORMAT)}</Text>
                        </Space>
                      </Space>
                    )}
                    <Space align="center" size="middle">
                      <Button
                        type="primary"
                        htmlType="button"
                        className="btn-secondary"
                        onClick={() => history.push(ServicePath.LicenseList)}
                      >
                        キャンセル
                      </Button>
                      <Button type="primary" htmlType="submit" disabled={isDisableRequest}>保存する</Button>
                    </Space>
                  </Style.SubmitActionContent>
                </Col>
              </Row>
            </Style.ModifyLicenseAction>
          </Form>
        </Style.LicenseOverviewContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { companyContracts } = state.companyReducer;
  const { licenseDetail } = state.licenseReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  const {
    masterDataCourseCategories,
    masterDataCourses,
  } = state.courseReducer;
  return {
    companyContracts,
    masterDataCourseCategories,
    masterDataCourses,
    licenseDetail,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  getMasterDataCourseList: (params) => dispatch(getMasterDataCourseListAction(params)),
  getMasterDataCourse: (params) => dispatch(getMasterDataCourseAction(params)),
  createLicense: (params) => dispatch(createLicenseAction(params)),
  updateLicense: (params) => dispatch(updateLicenseAction(params)),
  getLicenseDetail: (params) => dispatch(getLicenseDetailAction(params)),
  clearLicenseData: () => dispatch(clearLicenseDataAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyLicensePage);
