// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const COURSE_ACTION = {
  GET_DETAIL: 'ADMIN_COMPANY/COURSE_ACTION/GET_DETAIL',
  GET_GROUP: 'ADMIN_COMPANY/COURSE_ACTION/GET_GROUP',

  GET_ATTENDANCE_STATUS: 'ADMIN_COMPANY/COURSE_ACTION/GET_ATTENDANCE_STATUS',
  GET_ATTENDANCE_CHART: 'ADMIN_COMPANY/COURSE_ACTION/GET_ATTENDANCE_CHART',
  GET_TEST_SCORE_CHART: 'ADMIN_COMPANY/COURSE_ACTION/GET_TEST_SCORE_CHART',
  GET_STUDENT_LIST: 'ADMIN_COMPANY/COURSE_ACTION/GET_STUDENT_LIST',
  GET_COURSES: 'ADMIN_COMPANY/COURSE_ACTION/GET_COURSES',
  CLEAR_LEARNING_TAB: 'ADMIN_COMPANY/COURSE_ACTION/CLEAR_LEARNING_TAB',

  GET_TEST_SCORE_OVERVIEW: 'ADMIN_COMPANY/COURSE_ACTION/GET_TEST_SCORE_OVERVIEW',
  GET_TEST_SCORE_STUDENTS: 'ADMIN_COMPANY/COURSE_ACTION/GET_TEST_SCORE_STUDENTS',

  DOWNLOAD_CSV_STUDY_STUDENT: 'ADMIN_COMPANY/COURSE_ACTION/DOWNLOAD_CSV_STUDY_STUDENT',
  SET_PROGRESS_NOTIFICATION: 'ADMIN_COMPANY/COURSE_ACTION/SET_PROGRESS_NOTIFICATION',
  GET_RECOMMEND_DATE: 'ADMIN_COMPANY/COURSE_ACTION/GET_RECOMMEND_DATE',
  
  DOWNLOAD_CSV_TESTS: 'ADMIN_COMPANY/TEST_ACTION/DOWNLOAD_CSV_TESTS',
  GET_PROGRESS_RATE_CHART: 'ADMIN_COMPANY/TEST_ACTION/GET_PROGRESS_RATE_CHART',
  GET_USER_PROGRESS_COURSE: 'ADMIN_COMPANY/TEST_ACTION/GET_USER_PROGRESS_COURSE',
};
