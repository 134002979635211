// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';
import history from 'src/utils/history';

import {
  ADMIN_COMPANY,
  MODAL_ACTION,
  LOADING_ACTION,
  SUCCESS,
  FAILURE,
  REQUEST,
} from 'src/redux/constant';

import { companyProfileServices } from 'src/services';

function* getProfileDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield companyProfileServices.getProfileDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL),
      payload: result,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (redirect) history.push(redirect);
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* updateUserRoleSaga(action) {
  try {
    yield companyProfileServices.updateUserRole(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE),
      payload: {
        data: action.payload.usersRole,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE), errors: e.errors });
  }
}

function* getJoinedCoursesSaga(action) {
  try {
    const result = yield companyProfileServices.getJoinedCourses(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.GET_JOINED_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.PROFILE_ACTION.GET_JOINED_COURSES), errors: e.errors });
  }
}

function* getListTestStudent(action) {
  try {
    const result = yield companyProfileServices.getListTestStudent(action.payload)
    yield put({
      type: SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT),
      payload: result
    })
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT),
      errors: e.errors
    })
  }
}

function* resetHistoryTest(action) {
  try {
    const result = yield companyProfileServices.resetHistoryTest(action.payload)
    yield put({
      type: SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY),
      payload: result
    })

    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })

    yield put({
      type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT),
      payload: {
        user_id: action.payload.requestData.user_id,
        requestData: {
          page: 1
        }
      }
    })
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY),
      errors: e.errors
    })
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE)
    })
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
  }
}

function* unlockUserSaga(action) {
  try {
    yield companyProfileServices.unlockUser(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.UNLOCK_USER)
    });
    yield showAlertNotice({ type: "success", message: "解除に成功しました。" });
  } catch (e) {
    yield showAlertNotice({ type: "error", message: "解除に失敗しました。" });
  } finally {
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD),
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  }
}

export default function* companyProfileSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL), getProfileDetailSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE), updateUserRoleSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_JOINED_COURSES), getJoinedCoursesSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT), getListTestStudent),
    takeLatest(REQUEST(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY), resetHistoryTest),
    takeLatest(REQUEST(ADMIN_COMPANY.PROFILE_ACTION.UNLOCK_USER), unlockUserSaga),
  ]);
}
