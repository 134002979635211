// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const COMPANY_ACTION = {
  GET_DETAIL: 'ADMIN_COMPANY/COMPANY_ACTION/GET_DETAIL',
  GET_COMPANY_STUDENTS: 'ADMIN_COMPANY/COMPANY_ACTION/GET_COMPANY_STUDENTS',
  GET_COMPANY_COURSES: 'ADMIN_COMPANY/COMPANY_ACTION/GET_COMPANY_COURSES',
  GET_COMPANY_CUSTOM_INFOS: 'ADMIN_COMPANY/COMPANY_ACTION/GET_COMPANY_CUSTOM_INFOS',
  UPDATE_COMPANY_CUSTOM_INFOS: 'ADMIN_COMPANY/COMPANY_ACTION/UPDATE_COMPANY_CUSTOM_INFOS',
  DELETE_COMPANY_CUSTOM_INFOS: 'ADMIN_COMPANY/COMPANY_ACTION/DELETE_COMPANY_CUSTOM_INFOS',
  UPDATE_COMPANY_INFOS: 'ADMIN_COMPANY/COMPANY_ACTION/UPDATE_COMPANY_INFOS',
  GET_COMPANY_COURSES_STATUS: "ADMIN_COMPANY/COMPANY_ACTION/GET_COMPANY_COURSES_STATUS",
  CLEAR_COMPANY_COURSES_STATUS: "ADMIN_COMPANY/COMPANY_ACTION/CLEAR_COMPANY_COURSES_STATUS",
};
