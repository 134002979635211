//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import {
  Radio,
  Button,
  Input,
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { maxBy } from 'lodash/math';
import MathJax from 'react-mathjax-preview';
import { useDropzone } from 'react-dropzone';

import { QuestionStorage } from 'src/utils/localStorge';
import { showAlertNotice } from 'src/utils/alert';

import {
  ALLOWED_TYPE,
  FILE_SIZE,
} from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';

import * as Style from './styles';

const PreviewQuestion = () => {
  const question = QuestionStorage.getData();

  const [fileName, setFileName] = useState<number | string | undefined>('');
  const [fileError, setFileError] = useState<string>('');

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.PreviewQuestion);
  }, []);

  const onDrop = (file) => {
    setFileName(file[0].name);
    const fileType = file[0].name.slice(file[0].name.lastIndexOf('.') + 1);
    // setFileImage(file);
    if (!ALLOWED_TYPE.includes(fileType)) {
      setFileError('ファイルの形式が正しくありません。選択可能ファイルはpdf、word、powerpoint、ipynb、pyです。');
    } else if (file[0].size > FILE_SIZE) {
      setFileError('ファイルサイズが大きすぎます。(最大サイズ：30MB）');
    } else {
      setFileError('');
    }
    const reader = new FileReader();

    reader.readAsDataURL(file[0]);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  function renderQuestionContent() {
    return (
      <>
        {question.image && (
          <Style.QuestionImageContainer>
            <Style.QuestionImageContent src={question.image} alt="" />
          </Style.QuestionImageContainer>
        )}
        <Style.QuestionContent>
          {question.content && (
            <MathJax math={question.content} />
          )}
        </Style.QuestionContent>
      </>
    );
  }

  function renderOptionRadio(questionOptions) {
    const maxLengthAnswer = maxBy(questionOptions, (answer) => answer.content.length);
    let answerWidth = '';
    const isMediumContent = maxLengthAnswer.content.length > 10 && maxLengthAnswer.content.length <= 30;
    const isLongContent = maxLengthAnswer.content.length > 30;
    if (questionOptions.length === 2 && !isLongContent) {
      answerWidth = 'calc(50% - 16px)';
    } else if ([3, 5, 6].includes(questionOptions.length) && !isLongContent) {
      answerWidth = 'calc(100% / 3 - 16px)';
    } else if (maxLengthAnswer.content.length <= 10) {
      answerWidth = 'calc(25% - 16px)';
    } else if (isMediumContent) {
      answerWidth = 'calc(50% - 16px)';
    } else {
      answerWidth = '100%';
    }
    return questionOptions.map((option, optionIndex) => (
      <Radio.Button
        key={`option-${option.id}-${optionIndex}`}
        className="answer-radio-button"
        value={optionIndex + 1}
        style={{ width: answerWidth }}
      >
        <Style.AnswerOption isRight={option.isRight}>
          {`${optionIndex + 1}. ${option.content}`}
          {option.isRight && <CheckOutlined style={{ marginLeft: 16, color: '#5FC73A', fontSize: 20 }} />}
        </Style.AnswerOption>
      </Radio.Button>
    ));
  }

  function renderAnswerContent() {
    switch (question.questionType) {
    case 'option_type':
      return (
        <Radio.Group
          className="answer-radio-group"
          buttonStyle="solid"
        >
          {renderOptionRadio(question.questionOptions)}
        </Radio.Group>
      );
    case 'input_type':
      return (
        <Input.TextArea
          placeholder={
            question.rightInput
              ? question.rightInput
              : '解答を入力してください'
          }
          style={{ height: 200 }}
        />
      );
    case 'upload_file_type':
      return renderDragFileArea();
    default:
      return null;
    }
  }

  function renderDragFileArea() {
    return (
      <Style.UploadField
        {...getRootProps()}
        isDrag={isDragActive}
      >
        <input {...getInputProps()} />
        {!fileName
          ? (
            <>
              <Text lg w6 subText>ここにファイルをドラッグ</Text>
              <Text margin="0 0 10px" lg w6 subText>または</Text>
            </>
          ) : (
            <Text
              truncate
              center
              lg
              w6
              style={{ width: 400, lineHeight: `${fileError ? '24px' : '54px'}` }}
            >
              {fileName}
            </Text>
          )
        }
        {fileError && (
          <Text error margin="0 0 10px">{fileError}</Text>
        )}
        <Style.GroupAction>
          <Button
            size="middle"
            type="primary"
            onClick={open}
            ghost
          >
            {!!fileName ? 'ファイルを再選択' : 'パソコンからファイルを選択'}
          </Button>
          <Button
            size="middle"
            type="primary"
            onClick={() => {
              showAlertNotice({ type: 'success', message: 'アップロードが成功しました。' });
            }}
            style={{ marginLeft: 10 }}
            disabled={!!fileError || !fileName}
          >
            ファイルを提出
          </Button>
        </Style.GroupAction>
      </Style.UploadField>
    );
  }

  function renderAnswerTitle() {
    switch (question.questionType) {
    case 'option_type':
      return '解答を選択';
    case 'input_type':
      return '解答を入力';
    case 'upload_file_type':
      return '解答ファイルをアップロード';
    default:
      return null;
    }
  }

  return (
    <MainContainer isStudent>
      <MainContent previewQuestion>
        <Style.QuestionDetailContainer>
          <Style.QuestionDetailContent>
            <Style.QuestionTitle>
              <Text truncate white lg w6 style={{ width: 'calc(100% - 250px)' }}>
                  {`${question.questionSelectedIndex}問目 ${question.name || ''}`}
              </Text>
            </Style.QuestionTitle>
            {renderQuestionContent()}
          </Style.QuestionDetailContent>
            <Style.AnswerContainer>
              <Style.AnswerTitle>
                <Text white lg w5>{renderAnswerTitle()}</Text>
              </Style.AnswerTitle>
              <Style.AnswerContent>
                {renderAnswerContent()}
              </Style.AnswerContent>
            </Style.AnswerContainer>
        </Style.QuestionDetailContainer>
      </MainContent>
    </MainContainer>
  );
};

export default PreviewQuestion;
