//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Spin, Tabs } from 'antd';
import { sumBy } from 'lodash/math';
import { isNumber, isEmpty, isEqual, omit } from "lodash";

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import CourseOverview from './components/CourseOverview';
import SurveyAndTest from './components/SurveyAndTest';
import CourseContent from './components/CourseContent';
import AttendanceStatus from './components/AttendanceStatus';
import AttendanceChart from './components/AttendanceChart';
import StudentList from './components/StudentList';
import TestScore from './components/TestScore';
import ProgressNotification from './components/ProgressNotification';

import history from 'src/utils/history';
import { convertSecondTime } from 'src/utils/dateTime';
import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  deleteCourse as deleteCourseAction,
  getCourseDetail as getCourseDetailAction,
  getGroupRecommendedDate as getGroupRecommendedDateAction,
  getGroupDetail as getGroupDetailAction,
  updateProgressNotificationSetting as updateProgressNotificationSettingAction,
  getLearningStatus as getLearningStatusAction,
  getLearningChart as getLearningChartAction,
  getLearningUserProgress as getLearningUserProgressAction,
  getTestScoreChart as getTestScoreChartAction,
  showModal as showModalAction,
  getTestScoreOverview as getTestScoreOverviewAction,
  getCourseCompanyInfo as getCourseCompanyInfoAction,
  getCourseStudents as getCourseStudentsAction,
  getTestScoreStudents as getTestScoreStudentsAction,
  clearCourseDetail as clearCourseDetailAction,
  clearCourseOverview as clearCourseOverviewAction,
  clearDataLearningTab as clearDataLearningTabAction,
  closeModal as closeModalAction,
  downloadCsvStudyStudent as downloadCsvStudyStudentAction,
  getCurrentTime as getCurrentTimeAction,
} from 'src/redux/actions';
import * as Style from './styles';
import AttendanceTable from './components/AttendanceTable';

interface ICourseDetailPageProps extends StateProps, DispatchProps {
  deleteCourse(params): void;
  showModal(params: any): void;
  getCourseDetail(params: any): void;
  getGroupDetail(params: any): void;
  updateProgressNotificationSetting(params: any): void;
  getLearningStatus(param: any): void;
  getLearningChart(param: any): void;
  getLearningUserProgress(param: any): void;
  getTestScoreOverview(params): void;
  getTestScoreChart(params: any): void;
  getCourseCompanyInfo(params): void;
  getCourseStudents(params): void;
  getGroupRecommendedDate(params): void;
  getTestScoreStudents(params): void;
  clearCourseDetail(): void;
  clearCourseOverview(): void;
  clearDataLearningTab(): void;
  closeModal(): void;
  getCurrentTime(): void;
  currentTime: {
    data: string;
  };
  match: {
    params: {
      id?: string;
    };
  };
  courseOverviewDetail: {
    data: {
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
      startDate: string;
      remainingDays: number;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      isLearned: boolean;
      recommendedStartDate: string;
      recommendedEndDate: string;
      recommendedCompletionDay: number;
      remainingRecommendedDays: number;
      actualStatus: string;
      licenseId: number;
    };
    load: boolean;
  };
  courseDetail: {
    data: {
      id: number;
      contractCount: number;
      courseCategory: {
        id: number;
        name: string;
      };
      companies: {
        id: number;
        contractName: string;
      }[];
      actualStatus: string;
      name: string;
      overview: string;
      startDate: string;
      remainingDays: number;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      isLearned: boolean;
      recommendedEndDate: string;
      recommendedStartDate: string;
      recommendedCompletionDay: number;
      isDisplayLogo: boolean,
      logo:{
        logoName: string,
        logoUrl: string,
      },
      company: {
        id: number;
      }
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
      surveys: {
        id: number;
        item: {
          surveyType: string;
        };
        unit: {
          id: number;
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        item: {
          testType: string;
          questionsCount: number;
        };
        unit: {
          id: number;
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      groups: {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          totalUnits: number;
          totalVideosTime: number;
        }[];
        endDate: string;
        isDelayed: boolean;
      }[];
      emailWeeklySchedule: [];
      emailHoursSchedule: number;
      emailMinutesSchedule: number;
      excludeFinishedUserSchedule: boolean;
    };
    load: boolean;
  };
  learningStatus: {
    data: {
      usersBehindSchedule: {
        usersBehindSchedulePercentage: number;
        total: number;
      };
      usersOnSchedule: {
        usersOnSchedulePercentage: number
        total: number;
      };
      coursesConsumptionPercentage: number;
      avgLearningTime: number;
      joinTestsPercentage: number;
      passedFinishTest: {
        passedFinishTestsPercentage: number;
        total: number;
      };
    };
    load: boolean;
  };
  learningChart: {
    data: {
      chartData: {
        [key: string]: number;
      };
      targetProgressGapLine?: number
    }
    load: boolean;
  };
  learningUsersProgress: {
    data: {
      id: number;
      jituName: string;
      email: string;
      totalLearningTime: number;
      coursesPercentage: number;
      progressGap: number;
      lastLearningDate: string;
      joinedTestsRate: number;
      finishTestStatus: number;
    }[];
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
        to: number;
      };
    };
  };
  recommendDates : {
    data: {
      groupRecommendedDate: {
        groupId: number;
        endDate: string;
        isDelayed: boolean;
      }[]
    },
  };
}

const CourseDetailPage: React.FC<ICourseDetailPageProps> = ({
  deleteCourse,
  showModal,
  getCourseDetail,
  getGroupDetail,
  getGroupRecommendedDate,
  updateProgressNotificationSetting,
  getCourseCompanyInfo,
  getCourseStudents,
  getTestScoreOverview,
  getTestScoreChart,
  clearCourseDetail,
  clearCourseOverview,
  clearDataLearningTab,
  closeModal,
  downloadCsvStudyStudent,
  getLearningStatus,
  getLearningChart,
  getLearningUserProgress,
  match,
  courseDetail,
  courseOverviewDetail,
  recommendDates,
  learningStatus,
  learningChart,
  learningUsersProgress,
}) => {
  const sortConditionUrl = history.location.state?.sortCondition;
  const progressRateSelectUrl = history.location.state?.progressRateSelect;
  const pageUrl = history.location.state?.page;
  const urlSelectCompanyId = history.location.state?.companyContractId;
  const activeIndexBarUrl = history.location.state?.activeIndexBar;
  const activeTabKeyUrl = history.location.state?.activeTabKey;

  const [progressRateSelect, setProgressRateSelect] = useState<number | null>(null)
  const [tabIndexSelected, setTabIndexSelected] = useState<string>(activeTabKeyUrl || '1');
  const [backgroundHeight, setBackgroundHeight] = useState<number>(200);
  const [companySelectedId, setCompanySelectedId] = useState<number | null>();
  const [activeIndexBar, setActiveIndexBar] = useState(-1); 
  const [sortConditionAttendance, setSortConditionAttendance] = useState<object>({});
  const [pageUserProgress, setPageUserProgress] = useState({});

  const courseOverviewElement = useRef(null);
  const courseId = match.params.id;

  useEffect(() => {
    if(courseId && companySelectedId){
      getGroupRecommendedDate({
        id: courseId,
        requestData: { companyId: companySelectedId }
      });
    }
  }, [courseDetail.data?.id])

  useEffect(() => {
    setTabIndexSelected(activeTabKeyUrl || '1')
    setCompanySelectedId(urlSelectCompanyId)

    if (tabIndexSelected === '1') {
      setSortConditionAttendance(sortConditionUrl || {});
      setProgressRateSelect(isNumber(progressRateSelectUrl) ? progressRateSelectUrl : null);
    }
    
    window.addEventListener("beforeunload", function () {
      history.replace({
        state: {
          activeTabKey: 1
        }}
      )
    })
  }, [])

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CourseDetail);
    if (!!urlSelectCompanyId) {
      getCourseDetail({ id: courseId, requestData: { companyId: urlSelectCompanyId }});
      setCompanySelectedId(urlSelectCompanyId);
      getCourseCompanyInfo({
        courseId,
        companyId: urlSelectCompanyId,
      });
      getGroupRecommendedDate({
        id: courseId,
        requestData: { companyId: urlSelectCompanyId }
      });
    } 

    return (() =>{
      clearCourseDetail();
      clearCourseOverview();
      clearDataLearningTab();
      closeModal();
    });
  }, []);

  useEffect(() => {
    if(courseId && companySelectedId){
      getGroupRecommendedDate({
        id: courseId,
        requestData: { companyId: companySelectedId }
      });
    }
  }, [companySelectedId])

  useEffect(() => {
    if (!!companySelectedId || !!urlSelectCompanyId) {
      const companyId = companySelectedId || urlSelectCompanyId;

      if (courseId && tabIndexSelected === '1') {
        getLearningStatus({ courseId, requestData: { companyId } });
        getLearningChart({ courseId, requestData: { companyId } });
      }
    }
  }, [companySelectedId, courseId, tabIndexSelected])

  useEffect(() => {
    if (courseId && tabIndexSelected === '1') {
      setActiveIndexBar(isNumber(activeIndexBarUrl) ? activeIndexBarUrl : -1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndexSelected])

  useEffect(() => {
    if ((!!companySelectedId || !!urlSelectCompanyId)
      && (courseId && tabIndexSelected === '1' && !isEmpty(learningChart.data.chartData))) {
      const companyId = companySelectedId || urlSelectCompanyId;
      const chartData = learningChart.data.chartData;

      let requestData = {
        ...pageUrl,
        companyId,
        items: pageUrl?.limit,
        sort: sortConditionAttendance,
      };
      if (isNumber(progressRateSelectUrl)) {
        if (chartData[progressRateSelectUrl] > 0) {
          requestData = {
            ...requestData,
            progressRate: progressRateSelectUrl
          }
        } else {
          requestData = { companyId };
          resetStateLearningTab();
        }
      }

      getLearningUserProgress({
        courseId,
        requestData
      });
    }
  }, [learningChart.data.chartData, tabIndexSelected])

  useEffect(() => {
    if (!!companySelectedId || !!urlSelectCompanyId) {
      const companyId = companySelectedId || urlSelectCompanyId;

      if (tabIndexSelected === '4') {
        getTestScoreOverview({ id: courseId, requestData: { companyId } });
        getTestScoreChart({ id: courseId, requestData: { testType: 'all_test', companyId } });
      }

      getCourseDetail({
        id: courseId,
        requestData: { companyId },
      });
    } else if (tabIndexSelected !== '1') {
      setTabIndexSelected('1');
    }
  }, [companySelectedId]);

  useEffect(() => {
    const actualStatus = courseOverviewDetail.data.actualStatus;

    if (!!companySelectedId && tabIndexSelected === '4' && actualStatus && !['opening', 'coming'].includes(actualStatus)) {
      setTabIndexSelected('1')
    }
  }, [companySelectedId, courseOverviewDetail.data.actualStatus])

  useEffect(() => {
    if (courseId) {
      switch (tabIndexSelected) {
        case '1': {
          if (parseFloat(courseId) !== courseDetail.data.id) {
            getCourseDetail({ id: courseId });
          }
        break;
        }
        case '5': {
          if (parseFloat(courseId) !== courseDetail.data.id) {
            if (!!companySelectedId) {
              getCourseCompanyInfo({
                courseId,
                companyId: companySelectedId,
              });
              getGroupRecommendedDate({
                id: courseId,
                requestData: { companyId: companySelectedId }
              });
              return
            }
            else{
              getCourseDetail({ id: courseId });
            }
          }
          break;
        }
        default: break;
      }
    }
  }, [courseId, tabIndexSelected]);

  useEffect(() => {
    if (tabIndexSelected === '1') {
      setPageUserProgress(learningUsersProgress.meta.pageInfo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learningUsersProgress.meta.pageInfo]);

  useEffect(() => {
    if (tabIndexSelected === '1' && !isEmpty(learningChart.data) && !learningChart.load && !learningUsersProgress.load) {
      const pageUser = omit(pageUserProgress, "count");
      const state = {
        companyContractId: companySelectedId,
        activeTabKey: tabIndexSelected,
        sortCondition: sortConditionAttendance,
        page: {
          ...pageUser,
          to: pageUser?.to >= 10 ? pageUser?.to : 10,
        },
        isSort: true,
        progressRateSelect,
        activeIndexBar
      }

      if (isEqual(state, history.location.state)) return;

      const pageInfo = omit(learningUsersProgress.meta.pageInfo, "count");

      history.replace({
        state: {
          companyContractId: companySelectedId,
          activeTabKey: tabIndexSelected,
          sortCondition: sortConditionAttendance,
          page: {
            ...pageInfo,
            to: pageInfo?.to >= 10 ? pageInfo?.to : 10,
          },
          isSort: true,
          progressRateSelect,
          activeIndexBar
        }
      });
    } else if (["4", "5"].includes(tabIndexSelected)) {
      history.replace({
        state: {
          companyContractId: companySelectedId,
          activeTabKey: tabIndexSelected,
        }
      });
    }
  }, [activeIndexBar, sortConditionAttendance, tabIndexSelected, pageUserProgress, progressRateSelect]);

  useEffect(() => {
    const courseOverviewHeight = (courseOverviewElement.current as any)?.offsetHeight;
    setBackgroundHeight(200 + (courseOverviewHeight - 118));
  }, [courseDetail]);

  function handleClickCard(progressRate){
    getLearningUserProgress({ courseId, requestData: {
      progressRate,
      companyId: companySelectedId
    }});
    setProgressRateSelect(progressRate)
    setSortConditionAttendance({})
  }

  function resetStateLearningTab() {
    if (tabIndexSelected === '1') {
      setActiveIndexBar(-1);
      setPageUserProgress({});
      setProgressRateSelect(null);
      setSortConditionAttendance({});
    }
  }

  return (
    <MainContainer isTabs backgroundHeight={backgroundHeight}>
      <MainContent fullView>
        <CourseOverview
          clearDataLearningTab={clearDataLearningTab}
          resetStateLearningTab={resetStateLearningTab}
          courseId={courseId}
          deleteCourse={deleteCourse}
          getCourseDetail={getCourseDetail}
          showModal={showModal}
          courseDetail={courseDetail}
          courseOverviewDetail={courseOverviewDetail}
          courseOverviewElement={courseOverviewElement}
          setCompanySelectedId={setCompanySelectedId}
          companySelectedId={companySelectedId}
          getCourseCompanyInfo={getCourseCompanyInfo}
          clearCourseOverview={clearCourseOverview}
          getGroupRecommendedDate={getGroupRecommendedDate}
        />
        <Tabs
          activeKey={tabIndexSelected}
          defaultActiveKey="1"
          type="card"
          onChange={(activeKey) => {
            clearDataLearningTab();
            history.replace({
              state: {
                activeTabKey: activeKey,
                sortCondition: {},
                page: {},
              }
            });
            setTabIndexSelected(activeKey);
            setProgressRateSelect(null);
            setSortConditionAttendance({});
            setPageUserProgress({});
          }}
        >
          {!!companySelectedId && (
            <Tabs.TabPane
              tab={<Text xl headerText w6>学習状況</Text>}
              key="1"
              disabled={!companySelectedId}
            >
              <AttendanceStatus attendanceStatus={learningStatus} />
              <AttendanceChart
                attendanceChart={learningChart}
                handleClickCard={handleClickCard}
                activeIndexBar={activeIndexBar} 
                setActiveIndexBar={setActiveIndexBar}/>
              <AttendanceTable
                learningUsersProgress={learningUsersProgress}
                getLearningUserProgress={getLearningUserProgress}
                sortConditionAttendance={sortConditionAttendance}
                setSortConditionAttendance={setSortConditionAttendance}
                courseId={courseId}
                companyId={companySelectedId}
                progressRateSelect={progressRateSelect}
                isLoading={learningChart.load || learningUsersProgress.load}
              />
            </Tabs.TabPane>
          )}
          {!!companySelectedId && (
            <Tabs.TabPane
              tab={<Text xl headerText w6>ユーザー一覧</Text>}
              key="2"
              disabled={!companySelectedId}
            >
              <StudentList
                courseId={courseId}
                courseDetail={courseDetail}
                companySelectedId={companySelectedId}
                tabIndexSelected={tabIndexSelected}
                showModal={showModal}
                getCourseStudents={getCourseStudents}
                actualStatus={courseOverviewDetail.data.actualStatus}
                downloadCsvStudyStudent={downloadCsvStudyStudent}
                closeModal={closeModal}
                courseOverviewDetail={courseOverviewDetail}
              />
            </Tabs.TabPane>
          )}
          {(courseDetail.data.tests.length > 0 && !!companySelectedId) && (
            <Tabs.TabPane
              tab={<Text xl headerText w6>テスト</Text>}
              key="3"
              disabled={!companySelectedId}
            >
              <TestScore
                courseId={courseId}
                companySelectedId={companySelectedId}
                tabIndexSelected={tabIndexSelected}
              />
            </Tabs.TabPane>
          )}
          {(['opening', 'coming'].includes(courseOverviewDetail.data.actualStatus) && !!companySelectedId) && (
            <Tabs.TabPane
              tab={<Text xl headerText w6>進捗通知設定</Text>}
              key="4"
              disabled={!companySelectedId}
            >
              <Spin spinning={courseDetail.load}>
                <ProgressNotification
                  updateProgressNotificationSetting={updateProgressNotificationSetting}
                  courseId={courseId}
                  companySelectedId={companySelectedId}
                  excludeFinishedUserSchedule={courseDetail.data.excludeFinishedUserSchedule}
                  emailWeeklySchedule={courseDetail.data.emailWeeklySchedule}
                  emailHoursSchedule={courseDetail.data.emailHoursSchedule}
                  emailMinutesSchedule={courseDetail.data.emailMinutesSchedule}
                  showModal={showModal}
                  closeModal={closeModal}
                />
              </Spin>
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={<Text xl headerText w6>内容</Text>}
            key={!!companySelectedId ? "5" : "1"}
          >
            <div style={{ marginTop: 24 }}>
              <SurveyAndTest courseDetail={courseDetail} />
              <CourseContent
                courseDetail={courseDetail}
                getGroupDetail={getGroupDetail}
                companySelectedId={companySelectedId}
                recommendDates={recommendDates}
                />
              <Style.OverallInfoField>
                <div style={{ width: '35%' }}></div>
                {courseDetail.load
                  ? (
                    <div style={{ marginRight: 50 }}>
                      <SkeletonLoading
                        active
                        paragraph={false}
                        title={{ width: 32 }}
                        height={16}
                      />
                    </div>
                  )
                  : <div style={{ width: '10%' }}><Text secondaryText lg w6 margin="0 50px 0 0">合計</Text></div>
                }
                <div style={{ width: '15%' }}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={16} />
                    : <Text lg>{`${sumBy(courseDetail.data.groups, (group) => group.totalUnits)}レッスン`}</Text>
                  }
                </div>
                <div style={{ width: '15%' }}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={16} />
                    : (
                      <Text lg>
                        {convertSecondTime(
                          sumBy(courseDetail.data.groups, (group) => group.totalVideosTime),
                          'HH:mm:ss',
                        )}
                      </Text>
                    )
                  }
                </div>
                <div style={{ width: '25%' }}></div>
              </Style.OverallInfoField>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const {
    courseDetail,
    learningChart,
    learningStatus,
    learningUsersProgress,
    testScoreOverview,
    testScoreChart,
    courseOverviewDetail,
    recommendDates
  } = state.courseReducer;
  const { currentTime } = state.masterDataReducer;
  return {
    courseDetail,
    learningChart,
    learningStatus,
    learningUsersProgress,
    testScoreOverview,
    testScoreChart,
    courseOverviewDetail,
    currentTime,
    recommendDates
  };
};

const mapDispatchToProps = (dispatch) => ({
  deleteCourse: (params) => dispatch(deleteCourseAction(params)),
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
  updateProgressNotificationSetting: (params) => dispatch(updateProgressNotificationSettingAction(params)),
  getGroupDetail: (params) => dispatch(getGroupDetailAction(params)),
  getGroupRecommendedDate: (params) => dispatch(getGroupRecommendedDateAction(params)),
  getLearningStatus: (params) => dispatch(getLearningStatusAction(params)),
  getLearningChart: (params) => dispatch(getLearningChartAction(params)),
  getLearningUserProgress: (params) => dispatch(getLearningUserProgressAction(params)),
  getTestScoreChart: (params) => dispatch(getTestScoreChartAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  getTestScoreOverview: (params) => dispatch(getTestScoreOverviewAction(params)),
  getCourseCompanyInfo: (params) => dispatch(getCourseCompanyInfoAction(params)),
  getCourseStudents: (params) => dispatch(getCourseStudentsAction(params)),
  getTestScoreStudents: (params) => dispatch(getTestScoreStudentsAction(params)),
  clearCourseDetail: () => dispatch(clearCourseDetailAction()),
  clearCourseOverview: () => dispatch(clearCourseOverviewAction()),
  clearDataLearningTab: () => dispatch(clearDataLearningTabAction()),
  closeModal: () => dispatch(closeModalAction()),
  downloadCsvStudyStudent: (param) => dispatch(downloadCsvStudyStudentAction(param)),
  getCurrentTime: () => dispatch(getCurrentTimeAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailPage);
