// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Text } from 'src/components/styles';

export const ConfirmModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
`;

export const Message = styled(Text)`
  font-size: 16px;
  font-weight: 300;
`;
