// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  DatePicker,
  Form,
} from 'antd';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import datePickerItem from 'src/assets/images/common/datepicker-icon.png'

import { DATE_FORMAT } from 'src/constants/common';

import {
  updateCourseDuration as updateCourseDurationAction,
  updateMyCourseDuration as updateMyCourseDurationAction,
  updateStudentCoursesDuration as updateStudentCoursesDurationAction,
} from 'src/redux/actions';

import * as Style from './styles';
import { Text } from 'src/components/styles';
import { handleShowMessageOnSettingLicense } from 'src/utils/common';

interface IModifyCourseDurationModalPageProps {
  updateCourseDuration (params): void;
  updateMyCourseDuration(params): void;
  updateStudentCoursesDuration(params): void;
  modalData: {
    isOtherProfile: boolean;
    isMyProfile: boolean;
    fromPage: string;
    course: {
      userId: string;
      courseId: number;
      companyId: number;
      courseStartDate: string;
      courseEndDate: string;
      endDate: string;
      startDate: string,
      recommendedStartDate: string;
      recommendedEndDate: string;
      recommendedCompletionDay: number;
    },
  };
}

const ModifyCourseDurationModal: React.FC<IModifyCourseDurationModalPageProps> = ({
  updateCourseDuration,
  updateMyCourseDuration,
  updateStudentCoursesDuration,
  modalData,
}) => {
  const [modifyStudentForm] = Form.useForm();
  const [isShowErrorMessage, setIsShowErrorMessage] = useState<boolean>(false)
  const [limitTimeSelected, setLimitTimeSelected] = useState<any>({
    startDate: moment(modalData.course.startDate, "YYYY-MM-DD"),
    endDate: moment(modalData.course.endDate, "YYYY-MM-DD"),
  });

  
  function disabledDate(current) {
    return current < moment(modalData.course.courseStartDate).startOf('day') || current > moment(modalData.course.courseEndDate).endOf('day');
  }

  const studentInitialFormValues = {
    startDate: moment(modalData.course.startDate),
    endDate: moment(modalData.course.endDate),
  };

  function handleSubmitForm(values) {
    const requestData = {
      companyId: modalData.course.companyId,
      userId: modalData.course.userId,
      ...(!isEmpty(values.startDate) && { startDate: moment(values.startDate).format(DATE_FORMAT) }),
      ...(!isEmpty(values.endDate) && { endDate: moment(values.endDate).format(DATE_FORMAT) }),
    };
    switch (modalData.fromPage) {
    case 'myProfile': {
      updateMyCourseDuration({ requestData, id: modalData.course.courseId });
      break;
    }
    case 'otherProfile': {
      updateCourseDuration({ requestData, id: modalData.course.courseId });
      break;
    }
    default: {
      updateStudentCoursesDuration({ requestData, id: modalData.course.courseId });
    }}
  }

  return (
    <Form
      form={modifyStudentForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      name="modifyPlanForm"
      initialValues={studentInitialFormValues}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.DurationFieldContent>
        <Form.Item
          label="開始日"
          name="startDate"
          validateTrigger={['onBlur']}
          validateFirst
          colon={false}
          rules={[
            { required: true, message: '開始日と終了日は必須項目です。' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value > moment(getFieldValue('endDate')).endOf('day')) {
                  return Promise.reject('開始日は終了日より前の日付を入力してください。');
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <DatePicker
            placeholder=""
            disabledDate={disabledDate}
            format="YYYY/MM/DD"
            suffixIcon={<img src={datePickerItem} alt="" />}
            style={{ width: 350 }}
            onChange={(values) => {
              setLimitTimeSelected({ ...limitTimeSelected, startDate: values });
              setIsShowErrorMessage(
                handleShowMessageOnSettingLicense(
                  modalData.course.recommendedStartDate,
                  modalData.course.recommendedEndDate,
                  { ...limitTimeSelected, startDate: values },
                  modalData.course.recommendedCompletionDay
                )
              );
            }}
          />
        </Form.Item>
        <Form.Item
          label="終了日"
          name="endDate"
          validateTrigger={['onBlur']}
          validateFirst
          colon={false}
          rules={[
            { required: true, message: '開始日と終了日は必須項目です。' },
          ]}
        >
          <DatePicker
            placeholder=""
            disabledDate={disabledDate}
            format="YYYY/MM/DD"
            suffixIcon={<img src={datePickerItem} alt="" />}
            style={{ width: 350 }}
            onChange={(values) => {
              setLimitTimeSelected({ ...limitTimeSelected, endDate: values });
              setIsShowErrorMessage(
                handleShowMessageOnSettingLicense(
                  modalData.course.recommendedStartDate,
                  modalData.course.recommendedEndDate,
                  { ...limitTimeSelected, endDate: values },
                  modalData.course.recommendedCompletionDay
                )
              );
            }}
          />
        </Form.Item>
        {isShowErrorMessage && 
        <Text color="red" padding={"0 36px"}>
          ※完了推奨期間がライセンス期間外のため、この状態では完了推奨日は表示されません。設定を再度確認してください。
        </Text>}
      </Style.DurationFieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => { };

const mapDispatchToProps = (dispatch) => ({
  updateCourseDuration: (params) => dispatch(updateCourseDurationAction(params)),
  updateMyCourseDuration: (params) => dispatch(updateMyCourseDurationAction(params)),
  updateStudentCoursesDuration: (params) => dispatch(updateStudentCoursesDurationAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyCourseDurationModal);
