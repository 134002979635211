// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class StudentUnitServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getUnitDetail(params) {
    const { courseId, unitVersionId, groupId, chapterId } = params;
    if (groupId && chapterId) {
      return this.restClient.get(`/student/courses/${courseId}/unit_versions/${unitVersionId}?group_id=${groupId}&chapter_id=${chapterId}`);
    } else {
      return this.restClient.get(`/student/courses/${courseId}/unit_versions/${unitVersionId}`);
    }
  }
}
