// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';
import ChapterDetailContents from './ChapterDetailContents';
import { Text } from 'src/components/styles';

import {
  getCourseDetail as getCourseDetailAction,
  getOverviewCourseContent as getOverviewCourseContentAction,
  changeGroupPosition as changeGroupPositionAction,
  changeChapterPosition as changeChapterPositionAction,
  changeUnitPosition as changeUnitPositionAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IDragDropModalProps extends StateProps, DispatchProps {
  getCourseDetail(params): void;
  getOverviewCourseContent(params): void;
  changeGroupPosition(params): void;
  changeChapterPosition(params): void;
  changeUnitPosition(params): void;
  modalData: {
    type: string;
    title: string;
    courseId: string;
    groupId?: number;
    groupIndex?: number;
  };
  overviewCourseContent: {
    data: {
      groups: {
        id: number;
        name: string;
        chapters: object[];
      }[];
    };
  };
}

const DragDropModal: React.FC<IDragDropModalProps> = ({
  getCourseDetail,
  getOverviewCourseContent,
  changeGroupPosition,
  changeChapterPosition,
  changeUnitPosition,
  overviewCourseContent,
  modalData,
}) => {
  const [listGroup, setListGroup] = useState<any>(overviewCourseContent.data);

  useEffect(() => {
    getOverviewCourseContent({ courseId: modalData.courseId });
  }, []);

  useEffect(() => {
    setListGroup(overviewCourseContent.data);
  }, [overviewCourseContent]);

  function reorderItems(list, oldIndex, newIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const oldIndex = result.source.index;
    const newIndex = result.destination.index;
    if (result.type === 'droppableGroup') {
      setListGroup(reorderItems(listGroup, oldIndex, newIndex));
      changeGroupPosition({
        groupId: result.draggableId,
        position: newIndex,
      });
    } else if (result.type === 'droppableChapter') {
      // Convert list group into object with key is group id and value is array of chapter
      const listChapterMap = listGroup.reduce((list, item) => {
        list[item.id] = item.chapters;
        return list;
      }, {});

      const oldGroupId = parseInt(result.source.droppableId.replace( /^\D+/g, ''), 10);
      const newGroupId = parseInt(result.destination.droppableId.replace( /^\D+/g, ''), 10);

      // Get list chapter of group have chapter dragged and group that chapter dragged drop
      const oldGroupItem = listChapterMap[oldGroupId];
      const newGroupItem = listChapterMap[newGroupId];
      let newListGroup = [...listGroup];

      if (oldGroupId === newGroupId) {
        const reorderedChapter = reorderItems(
          oldGroupItem,
          oldIndex,
          newIndex,
        );
        newListGroup = newListGroup.map((group) => {
          if (group.id === oldGroupId) {
            group.chapters = reorderedChapter;
          }
          return group;
        });
      } else {
        // Change list chapter of group have chapter dragged and group that chapter dragged drop
        const changedOldGroupItem = [...oldGroupItem];
        const chapterDragged = changedOldGroupItem.splice(oldIndex, 1);

        const changedNewGroupItem = [...newGroupItem];
        changedNewGroupItem.splice(newIndex, 0, ...chapterDragged);

        newListGroup = newListGroup.map((group) => {
          if (group.id === oldGroupId) {
            group.chapters = changedOldGroupItem;
          } else if (group.id === newGroupId) {
            group.chapters = changedNewGroupItem;
          }
          return group;
        });
      }
      setListGroup(newListGroup);
      changeChapterPosition({
        chapterId: result.draggableId.replace( /^\D+/g, ''),
        newGroupId,
        position: newIndex,
      });
    } else if (result.type === 'droppableUnit') {
      // Convert list group into object with key is group id and have value is object with key is chapter id
      const listUnitChapterMap = listGroup.reduce((listGroupMap, group) => {
        // Convert list chapter into object with key is chapter id and value is array of unit
        const listUnitMap = group.chapters.reduce((listChapterMap, chapter) => {
          listChapterMap[chapter.id] = chapter.unitVersions;
          return listChapterMap;
        }, {});

        listGroupMap[group.id] = listUnitMap;
        return listGroupMap;
      }, {});

      const oldGroupId = parseInt(result.source.droppableId.split('-')[1], 10);
      const oldChapterId = parseInt(result.source.droppableId.split('-')[3], 10);

      const newGroupId = parseInt(result.destination.droppableId.split('-')[1], 10);
      const newChapterId = parseInt(result.destination.droppableId.split('-')[3], 10);

      // Get list unit of chapter have unit dragged and chapter that unit dragged drop
      const oldChapterItem = listUnitChapterMap[oldGroupId][oldChapterId];
      const newChapterItem = listUnitChapterMap[newGroupId][newChapterId];

      let newListGroup = [...listGroup];

      if (oldChapterId === newChapterId) {
        const reorderedUnit = reorderItems(
          oldChapterItem,
          oldIndex,
          newIndex,
        );

        newListGroup = newListGroup.map((group) => {
          if (group.id === oldGroupId) {
            group.chapters.map((chapter) => {
              if (chapter.id === oldChapterId) {
                chapter.unitVersions = reorderedUnit;
              }
              return chapter;
            });
          }
          return group;
        });
      } else {
        // Change list unit of chapter have unit dragged and chapter that unit dragged drop
        const changedOldChapterItem = [...oldChapterItem];
        const unitDragged = changedOldChapterItem.splice(oldIndex, 1);

        const changedNewChapterItem = [...newChapterItem];
        changedNewChapterItem.splice(newIndex, 0, ...unitDragged);

        newListGroup = newListGroup.map((group) => {
          if (group.id === oldGroupId) {
            group.chapters.map((chapter) => {
              // Change list chapter of group source
              if (chapter.id === oldChapterId) {
                chapter.unitVersions = changedOldChapterItem;
              }
              return chapter;
            });
          }
          if (group.id === newGroupId) {
            // Change list chapter of group that got unit dropped
            group.chapters.map((chapter) => {
              if (chapter.id === newChapterId) {
                chapter.unitVersions = changedNewChapterItem;
              }
              return chapter;
            });
          }
          return group;
        });
      }
      setListGroup(newListGroup);
      changeUnitPosition({
        chapterId: oldChapterId,
        position: newIndex,
        unitVersionId: result.draggableId.replace( /^\D+/g, ''),
        newChapterId,
      });
    }
  }

  return (
    <>
      <Style.GroupChapterFieldContent>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="groups" type="droppableGroup">
            {(provided, snapshot) => (
              <Style.GroupDroppableBackground
                ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {listGroup.map((group, index) => (
                  <Draggable key={group.id} draggableId={`${group.id}`} index={index}>
                    {(chapterProvided, chapterSnapshot) => (
                      <Style.GroupContainer
                        key={group.id}
                        ref={chapterProvided.innerRef}
                        isLastChild={index + 1 === listGroup.length}
                        isDragging={chapterSnapshot.isDragging}
                        className="group-item"
                        {...chapterProvided.draggableProps}
                        {...chapterProvided.dragHandleProps}
                        style={{ ...chapterProvided.draggableProps.style }}
                      >
                        <Text xl w6>{group.name}</Text>
                        <ChapterDetailContents
                          chapters={group.chapters}
                          type={`group-${group.id}`}
                          groupId={group.id}
                        />
                      </Style.GroupContainer>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Style.GroupDroppableBackground>
            )}
          </Droppable>
        </DragDropContext>
      </Style.GroupChapterFieldContent>
      <ModalFooter
        onClose={() => getCourseDetail(({ id: modalData.courseId, isCloseModal: true }))}
        isCloseAction={true}
        cancelText="閉じる"
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { overviewCourseContent } = state.courseReducer;
  return {
    overviewCourseContent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
  getOverviewCourseContent: (params) => dispatch(getOverviewCourseContentAction(params)),
  changeGroupPosition: (params) => dispatch(changeGroupPositionAction(params)),
  changeChapterPosition: (params) => dispatch(changeChapterPositionAction(params)),
  changeUnitPosition: (params) => dispatch(changeUnitPositionAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DragDropModal);
