// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';

import { showAlertNotice } from 'src/utils/alert';
import history from 'src/utils/history';

import {
  ADMIN_SERVICE,
  MODAL_ACTION,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';
import { ServicePath } from 'src/constants/routerConstants';

import { unitServices } from 'src/services';

function* getUnitListSaga(action) {
  try {
    const result = yield unitServices.getUnitList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getUnitDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield unitServices.getUnitDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_DETAIL),
      payload: result,
    });
    if (redirect) history.push(redirect);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* updateUnitSaga(action) {
  try {
    const result = yield unitServices.updateUnit(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.UPDATE),
      payload: result,
    });
    yield history.push(ServicePath.UnitList);
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.UPDATE), errors: e.errors });
  }
}

function* createUnitSaga(action) {
  try {
    yield unitServices.createUnit(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.CREATE),
    });
    yield history.push(ServicePath.UnitList);
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.CREATE), errors: e.errors });
  }
}

function* deleteUnitSaga(action) {
  try {
    yield unitServices.deleteUnit(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.DELETE),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield history.push(ServicePath.UnitList);
    yield showAlertNotice({ type: 'success', message: '正常に削除されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.DELETE), errors: e.errors });
  }
}


function* getVersionListSaga(action) {
  const { isModifyUnit, requestData } = action.payload;
  try {
    const result = isModifyUnit ? yield unitServices.getUnitVersionList(requestData)
      : yield unitServices.getVersionList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_VERSIONS),
      payload: result,
    });
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_VERSIONS),
      errors: e.errors,
    });
  }
}

function* selectUnitVersionSaga(action) {
  try {
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.SELECT_VERSION),
      payload: action.payload,
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.SELECT_VERSION), errors: e.errors });
  }
}

function* getUnitItemsSaga(action) {
  try {
    const { unitType } = action.payload;
    const result = yield unitServices.getUnitItems(action.payload);
    const { data, meta } = result;
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_ITEMS),
      payload: {
        data: {
          ...data,
          type: unitType,
        },
        meta,
        more: !!action.payload.more,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_ITEMS), errors: e.errors });
  }
}

function* selectItemVersionSaga(action) {
  try {
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.SELECT_ITEM),
      payload: action.payload,
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.SELECT_ITEM), errors: e.errors });
  }
}

function* createUnitVersionSaga(action) {
  try {
    const result = yield unitServices.createUnitVersion(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION),
      payload: result,
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION), errors: e.errors });
  }
}

function* getCourseContentUnitsSaga(action) {
  try {
    const result = yield unitServices.getCourseContentUnits(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_COURSE_CONTENT_UNITS),
      payload: {
        data: result.data,
        meta: result.meta,
        more: !!action.payload.more,
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_COURSE_CONTENT_UNITS), errors: e.errors });
  }
}

function* getOverviewUnitDetailSaga(action) {
  try {
    const result = yield unitServices.getOverviewUnitDetail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL),
      payload: result,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

export default function* unitSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_LIST), getUnitListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_DETAIL), getUnitDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL), getOverviewUnitDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.UPDATE), updateUnitSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.CREATE), createUnitSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.DELETE), deleteUnitSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_VERSIONS), getVersionListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.SELECT_VERSION), selectUnitVersionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_ITEMS), getUnitItemsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.SELECT_ITEM), selectItemVersionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION), createUnitVersionSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_COURSE_CONTENT_UNITS), getCourseContentUnitsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL), getOverviewUnitDetailSaga),
  ]);
}
