// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H

import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { LoginStorage } from 'src/utils/localStorge';

import {
  ServicePath,
  CompanyPath,
  StudentPath,
} from 'src/constants/routerConstants';

import { redirectOnAccessSpecificPage } from 'src/utils/common';

import * as Style from './styles';

const LoginLayout = ({ component: Component, role, ...rest }) => {
  const userStorage = LoginStorage.getData();
  if (!isEmpty(userStorage)) {
    if (userStorage.tokenInfo.role !== role) {
      redirectOnAccessSpecificPage();
      return null;
    } else {
      if (userStorage.tokenInfo.role === 'admin_service') {
        return <Redirect to={ServicePath.AdminServiceTop} />;
      } else if (userStorage.tokenInfo.role === 'admin_company') {
        return <Redirect to={CompanyPath.AdminCompanyTop} />;
      } else {
        return <Redirect to={StudentPath.StudentTop} />;
      }
    }
  }
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Style.FullLayoutContainer>
          <Component {...matchProps} role={role} />
        </Style.FullLayoutContainer>
      )}
    />
  )
};

export default LoginLayout;
