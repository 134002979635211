// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from "react";

import ModalFooter from "src/components/Modal/components/ModalFooter";
import { Text } from "src/components/styles";

import * as Style from "./styles";

interface IConfirmModalProps {
  modalData: {
    title: string;
  };
}
const ConfirmErrorsLockUserModal: React.FC<IConfirmModalProps> = () => (
  <>
    <Style.ConfirmModalContainer>
      <Text error style={{ marginBottom: 2 }}>
        アカウントがロックされています。
        <br></br>解除の申請をする場合は
        <Style.Link
          href="https://share.hsforms.com/118qwvfYSTH6mTxLq8hFoJg2mtef"
          target="_blank"
        >
          こちら
        </Style.Link>
        からお問い合わせください。
      </Text>
    </Style.ConfirmModalContainer>
    <ModalFooter cancelText={"いいえ"} isSpaceCenter />
  </>
);
export default ConfirmErrorsLockUserModal;
