//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, Tooltip, Tabs } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';

import AccountProfile from './components/AccountProfile';
import OtherProfile from './components/OtherProfile';
import JoinedCourses from './components/JoinedCourses';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import {
  getProfileDetail as getProfileDetailAction,
  updateUserProfileInfo as updateUserProfileInfoAction,
  clearUpdateRoleErrors as clearUpdateRoleErrorsAction,
  clearUpdateEmailUserErrors as clearUpdateEmailUserErrorsAction,
  getJoinedCourses as getJoinedCoursesAction,
  showModal as showModalAction,
  getTestStudent as getTestStudentAction,
  resetHistoryTest as resetHistoryTestAction,
  closeModal as closeModalAction,
  getListCourseCompany as getListCourseCompanyAction,
  updateCourseManager as updateCourseManagerAction,
  unlockUserAdminService as unlockUserAdminServiceAction
} from 'src/redux/actions';

import { USER_PROFILE_FIELDS, COMPANY_PROFILE_FIELDS } from './constants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';
import { STATUS_USER } from 'src/constants/common'

import * as Style from './styles';
import TestListStudent from './components/TestListStudent';
import CourseManager from './components/CourseManager';

interface IProfileDetailPageProps extends StateProps, DispatchProps {
  getProfileDetail(params): void;
  updateUserProfileInfo(params): void;
  clearUpdateRoleErrors(): void;
  clearUpdateEmailUserErrors(): void;
  getJoinedCourses(params): void;
  unlockUserAdminService(params): void;
  showModal(params): void;
  getTestStudent(params): void;
  resetHistoryTest(params): void;
  getListCourseCompany(params): void;
  closeModal(): void;
  updateCourseManager(params): void;
  match: {
    params: {
      id: string;
    };
  };
  role: string;
  profileDetail: {
    data: {
      id: number;
      avatarUrl: string;
      company: {
        id: number;
        name: string;
      };
      department: string;
      email: string;
      jituName: string;
      roles: string[];
      kanaName: string;
      birthday: string;
      gender: string;
      jobTitle: string;
      jobType: string;
      systemNotificationSetting: string;
      status: string;
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      customUserInfos: {
        id: number;
        companyCustomUserInfoId: number;
        fieldValue: string;
      }[];
      customDisplayField: {
        birthday: boolean;
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    },
    load: boolean;
  };
  joinedCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string,
      },
      totalLearningDays: number;
      totalLearningTime: number;
      studentProgressPercentage: number;
      testInfo: {
        usersRank: number;
        totalUsers: number;
        highestTestScore: number;
      };
      remainingDays: number;
      courseActualStatus: string;
      courseStartDate: string;
      courseEndDate: string;
      startDate: string;
      endDate: string;
      lastLearningDate: string
      actualStatus: string;
      recommendedEndDate: string
      recommendedStartDate: string
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  listTestsStudent: {
    data: {
      id: number;
      name: string;
      test: {
        id: number,
        name: string,
        testType: string
      };
      category: {
        id: number;
        name: string;
      };
      usedAttemptsCount: number;
      testScore: number;
      testScorePercentage: number;
      lastTestingDate: string;
      isWaitingTest: boolean;
      unit: {
        id: number,
        name: string,
      };
      unitVersionAble: {
        id: number,
        type: string
      };
      course: {
        id: number;
        name: string
      },
      isDoingUnlimitedTest: boolean;
      manageTestStatus: string
    }[];
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
  },
  isResetSucess: boolean;
  listCourseCompany: {
    data: {
      id: number;
      course: {
        id: number;
        name: string;
        courseCategory: {
          id: number;
          name: string;
        }
      },
      courseActualStatus: string;
      startDate: string;
      endDate: string;
      manageStatus: string
    }[],
    meta: {
      pageInfo: {
        page: number;
        limit: number;
        count: number;
      }
    }
    load: boolean
  }
}

const ProfileDetailPage: React.FC<IProfileDetailPageProps> = ({
  getProfileDetail,
  updateUserProfileInfo,
  clearUpdateRoleErrors,
  clearUpdateEmailUserErrors,
  getJoinedCourses,
  unlockUserAdminService,
  showModal,
  match,
  role,
  profileDetail,
  joinedCourses,
  getListTestStudent,
  listTestsStudent,
  resetHistoryTest,
  isResetSucess,
  closeModal,
  getListCourseCompany,
  listCourseCompany,
  updateCourseManager
}) => {
  const [activeTabKey, setActiveTabKey] = useState<string>('1');

  const userId = match.params.id;

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.ProfileDetail);

    getProfileDetail({ id: userId });
    return () => {
      closeModal();
    }
  }, []);

  const handleShowModalConfirmUnblockUser = () => {
    const modalProps = {
      modalData: {
        title: "ロック解除確認",
        message: "アカウントのロックを解除して良いですか？",
      },
      modalAction: {
        confirm: () => {
          unlockUserAdminService(userId)
        },
      },
      isConfirm: true,
      spaceEvenly: true,
      isButtonLoading: true,
      styleContent: {
        paddingTop: "60px"
      }
    };
    showModal(<ConfirmModal {...modalProps} />);
  };

  return (
    <MainContainer backgroundHeight={250} isTabs>
      <MainContent fullView>
        <Style.ProfileInfoContainer>
          <Text w6 xxl style={{ marginBottom: 16 }}>
            プロフィール
          </Text>
          <Style.ProfileInfoContent>
            {profileDetail.data.avatarUrl
              ? <Style.ProfileAvatar image={profileDetail.data.avatarUrl} />
              : (
                <Avatar
                  size={120}
                  icon={<UserOutlined style={{ fontSize: 56 }} />}
                  style={{ marginRight: 20, minWidth: 120 }}
                />
              )
            }
            <div style={{ width: 'calc(100% - 140px)' }}>
              {profileDetail.data.status === STATUS_USER.BLOCK && (
                <Text color="red" xl>アカウントロック</Text>
              )}
              <Tooltip title={profileDetail.data.jituName} placement="top">
                <Text xxxl w6 truncate style={{ fontSize: 22 }}>
                  {profileDetail.data.jituName}
                </Text>
              </Tooltip>
              <Text xs>{profileDetail.data.id}</Text>
              {profileDetail.data.status === STATUS_USER.BLOCK && (
                <Style.ButtonUnblock
                  type="primary"
                  onClick={handleShowModalConfirmUnblockUser}
                >
                  ロック解除
                </Style.ButtonUnblock>
              )}
            </div>
          </Style.ProfileInfoContent>
        </Style.ProfileInfoContainer>
        <Tabs
          activeKey={activeTabKey}
          type="card"
          onChange={(activeKey) => setActiveTabKey(activeKey)}
          style={{ marginBottom: 60 }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>基本情報</Text>}
            key="1"
          >
            <AccountProfile
              role={role}
              profileDetail={profileDetail}
              clearUpdateRoleErrors={clearUpdateRoleErrors}
              clearUpdateEmailUserErrors={clearUpdateEmailUserErrors}
              showModal={showModal}
            />
            <OtherProfile
              type="user"
              title="ユーザー情報"
              fieldData={USER_PROFILE_FIELDS}
              profileDetail={profileDetail}
              updateUserProfileInfo={updateUserProfileInfo}
            />
            <OtherProfile
              type="company"
              title="所属情報"
              fieldData={COMPANY_PROFILE_FIELDS}
              profileDetail={profileDetail}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>受講コース</Text>}
            key="2"
          >
            {profileDetail.data.roles?.includes('student')
              ? (
                <JoinedCourses
                  showModal={showModal}
                  getJoinedCourses={getJoinedCourses}
                  userId={userId}
                  joinedCourses={joinedCourses}
                  profileDetail={profileDetail}
                />
              )
              : <Text lg w6 center margin="16px 0">現在受講可能なコースはありません。</Text>
            }
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>受講テスト</Text>}
            key="3"
          >
            <TestListStudent
              userId={userId}
              listTestsStudent={listTestsStudent}
              showModal={showModal}
              getListTestStudent={getListTestStudent}
              resetHistoryTest={resetHistoryTest}
              isResetSucess={isResetSucess}
              closeModal={closeModal}
              activeKey={activeTabKey}
            />
          </Tabs.TabPane>
          {
            profileDetail?.data?.roles?.includes("admin_company") && 
            <Tabs.TabPane
              tab={<Text xl headerText w6>管理コース</Text>}
              key="4"
            >
              <CourseManager 
                userId={userId} 
                getListCourseCompany={getListCourseCompany} 
                listCourseCompany={listCourseCompany} 
                updateCourseManager={updateCourseManager}
                />  
            </Tabs.TabPane>
          }
          
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { profileDetail, joinedCourses, listTestsStudent, isResetSucess, listCourseCompany } = state.profileReducer;
  return {
    profileDetail,
    joinedCourses,
    listTestsStudent,
    isResetSucess,
    listCourseCompany
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfileDetail: (params) => dispatch(getProfileDetailAction(params)),
  updateUserProfileInfo: (params) => dispatch(updateUserProfileInfoAction(params)),
  clearUpdateRoleErrors: () => dispatch(clearUpdateRoleErrorsAction()),
  clearUpdateEmailUserErrors: () => dispatch(clearUpdateEmailUserErrorsAction()),
  getJoinedCourses: (params) => dispatch(getJoinedCoursesAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  getListTestStudent: (params) => dispatch(getTestStudentAction(params)),
  resetHistoryTest: (params) => dispatch(resetHistoryTestAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  getListCourseCompany: (params) => dispatch(getListCourseCompanyAction(params)),
  updateCourseManager: (params) => dispatch(updateCourseManagerAction(params)),
  unlockUserAdminService: (params) => dispatch(unlockUserAdminServiceAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailPage);
