// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import {
  Table,
  Progress,
  Space,
  Button,
  Tooltip,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { convertSecondTime } from 'src/utils/dateTime';

import { Text } from 'src/components/styles';
import SortableHeader from 'src/components/tables/SortableHeader';
import { PaginationLayout } from 'src/components/layouts/Pagination';

import ModifyCourseDurationModal from 'src/components/Modal/components/ModifyCourseDurationModal';

import { COURSE_ACTUAL_STATUS, DATE_FORMAT } from 'src/constants/common';
import history from 'src/utils/history';
import { handleStopPropagation } from 'src/utils/common';

import * as Style from './style';

interface IJoinedCoursesProps {
  showModal(params: any): void;
  getJoinedCourses(params): void;
  userId: string;
  joinedCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string,
      },
      totalLearningDays: number;
      totalLearningTime: number;
      studentProgressPercentage: number;
      testInfo: {
        usersRank: number;
        totalUsers: number;
        highestTestScore: number;
      };
      remainingDays: number;
      courseActualStatus: string;
      courseStartDate: string;
      courseEndDate: string;
      startDate: string;
      endDate: string;
      lastLearningDate: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  profileDetail: {
    data: {
      status: string;
      company: {
        id: number;
        name: string;
      };
    },
  };
}

const JoinedCourses: React.FC<IJoinedCoursesProps> = ({
  getJoinedCourses,
  showModal,
  userId,
  joinedCourses,
  profileDetail,
}) => {
  const { meta } = joinedCourses;
  const [sortCondition, setSortCondition] = useState<any>({});

  useEffect(() => {
    getJoinedCourses({ id: userId, requestData: { page: 1 } });
  }, []);

  const tableData = joinedCourses.data.map((row) => ({
    ...row,
    key: row.id,
    id: row.id,
    name: row.courseCategory.name,
    totalLearningDays: `${row.totalLearningDays}日`,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'H時間m分'),
    studentProgressPercentage: row.studentProgressPercentage,
    testInfo: row.testInfo,
    remainingDays: row.remainingDays,
    startDate: moment(row.startDate).format(DATE_FORMAT),
    endDate: moment(row.endDate).format(DATE_FORMAT),
    lastLearningDate: row.lastLearningDate && moment(row.lastLearningDate).format(DATE_FORMAT),
  }));

  function handleModifyCourseDuration(e, record) {
    handleStopPropagation(e);
    const modalProps = {
      width: 560,
      modalData: {
        title: 'ライセンス期間の変更',
        fromPage: 'otherProfile',
        course: {
          userId,
          companyId: profileDetail.data.company.id,
          courseId: record.id,
          startDate: record.startDate,
          endDate: record.endDate,
          courseStartDate: record.courseStartDate,
          courseEndDate: record.courseEndDate,
          recommendedEndDate: record.recommendedEndDate,
          recommendedStartDate: record.recommendedStartDate,
          recommendedCompletionDay: record.recommendedCompletionDay
        },
      },
    };
    return showModal(<ModifyCourseDurationModal {...modalProps} />);
  }

  const columns = [
    {
      title:
        <SortableHeader
          type="name"
          title="コース"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'name',
      width: 400,
    },
    {
      title:
        <SortableHeader
          type="totalLearningDays"
          title="学習日数"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningDays',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="studentProgressPercentage"
          title="進歩率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'studentProgressPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.studentProgressPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.studentProgressPercentage} />
        </Space>
      ),
      width: 200,
    },
    {
      title:
        <SortableHeader
          type="highestTestScore"
          title={
            <div>
              <Text white style={{ marginLeft: '2px' }}>テスト正答率</Text>
              <Text white style={{ marginLeft: '-10px' }}>（全テスト平均）</Text>
            </div>
          }
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'testInfo' as any,
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text>
            {(isEmpty(record.testInfo) || record.testInfo.highestTestScore === null)
              ? 'ー'
              : `${record.testInfo.highestTestScore}%[${record.testInfo.usersRank}位/${record.testInfo.totalUsers}人]`
            }
          </Text>
        </Space>
      ),
      width: 200,
    },
    {
      title: (
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "lastLearningDate",
      render: (_, record) => (
        <Text>{record.lastLearningDate || ""}</Text>
      ),
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="startDate"
          title="開始日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'startDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="endDate"
          title="終了日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'endDate',
      render: (_, record) => (
        <Style.CourseDurationAction
          onClick={(e) =>  handleStopPropagation(e) }
        >
          <Text>
            {`${moment(record.endDate).format(DATE_FORMAT)}[残り${record.remainingDays}日]`}
          </Text>
          <Tooltip
            placement="right"
            title={['opening', 'coming'].includes(record.courseActualStatus)
              || profileDetail.data.status === 'banned'
                ? null
                : COURSE_ACTUAL_STATUS[record.courseActualStatus]}
          >
            <Button
              type="link"
              icon={<EditOutlined style={{ color: '#3333' }} />}
              disabled={profileDetail.data.status === 'banned'
                || ['finished', 'closed'].includes(record.courseActualStatus)
              }
              onClick={(e) => handleModifyCourseDuration(e, record)}
            />
          </Tooltip>
        </Style.CourseDurationAction>
      ),
      width: 230,
    },
  ];

  return (
    <>
      <div>
        <Style.CoursesListContainer>
          <Table
            className="table-cursor-pointer"
            loading={joinedCourses.load}
            pagination={false}
            scroll={{ x: 1500, y: 506 }}
            columns={columns}
            dataSource={tableData}
            onRow={(record) => ({
              onClick: () => {
                history.push({
                  pathname: `/admin-service/course/${record.key}`,
                  state: { companyContractId: profileDetail.data.company.id },
                });
              },
            })}
            locale={{ emptyText: "現在受講可能なコースはありません。" }}
          />
        </Style.CoursesListContainer>
        {!isEmpty(joinedCourses.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getJoinedCourses}
            sortCondition={sortCondition}
            dataParam={{
              id: userId,
              requestData: {
                page: meta.pageInfo?.page,
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default JoinedCourses;
