//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Row,
  Col,
  Button,
  Tooltip,
  Space,
} from 'antd';
import { isEmpty } from 'lodash/lang';

import { convertSecondTime, formatDateTime } from 'src/utils/dateTime';
import history from 'src/utils/history';

import { Text, SkeletonLoading } from 'src/components/styles';
import { CompanyPath } from 'src/constants/routerConstants';

import { DATE_FORMAT } from 'src/constants/common';

import * as Style from './styles';

interface ICourseOverviewProps {
  courseOverviewElement: any;
  courseDetail: {
    data: {
      id: number,
      actualStatus: string;
      name: string;
      courseCategory: {
        name: string;
      };
      overview: string;
      remainingDays: number;
      startDate: string;
      endDate: string;
      recommendedStartDate: string;
      recommendedEndDate: string;
      recommendedCompletionDay: number;
      remainingRecommendedDays: number;
      licensesCount: number;
      usedLicensesCount: number;
      isDisplayLogo: boolean;
      logo:{
        logoName: string;
        logoUrl: string;
      };
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
      versionName: string;
    };
    load: boolean;
  };
}

const CourseOverview: React.FC<ICourseOverviewProps> = ({
  courseOverviewElement,
  courseDetail,
}) => {
  const teacherList = !isEmpty(courseDetail.data.teachers)
    ? courseDetail.data.teachers.map((teacher) => teacher.name)
    : [];

  function renderRecommendDate() {
    const {recommendedStartDate, recommendedEndDate } = courseDetail.data
    if (courseDetail.load) {
      return <SkeletonLoading active paragraph={false} title={{ width: 80 }} />;
    }
    if (recommendedStartDate && recommendedEndDate) {
      return (
        <Text headerText w7 sm>
          {`${formatDateTime(recommendedStartDate, DATE_FORMAT)} ~ ${formatDateTime(recommendedEndDate, DATE_FORMAT)}`}
        </Text>
      );
    } else {
      return <Text headerText w7 sm> 未設定 </Text>;
    }
  }

  function renderRecommendedCompletionDay() {
    const { remainingRecommendedDays } = courseDetail.data
    if (courseDetail.load) {
      return <SkeletonLoading active paragraph={false} title={{ width: 80 }} />;
    }
    if (remainingRecommendedDays || remainingRecommendedDays === 0) {
      return <Text headerText w7 sm> あと {remainingRecommendedDays}日 </Text>;
    }
    return <Text headerText w7 sm> あと - 日 </Text>;
  }

  return (
    <Style.CourseOverviewContainer ref={courseOverviewElement}>
      <Row gutter={100}>
        <Col span={13}>
          {courseDetail.load
            ? (
              <>
                <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={24} />
                <SkeletonLoading
                  active
                  paragraph={{ rows: 3 }}
                  title={false}
                  height={16}
                  margin="8px 0 0 0"
                />
              </>
            )
            : (
              <>
                <Style.CourseNameBlock >
                  {courseDetail.data.isDisplayLogo && (
                    <Style.LogoCourse>
                      <img src={courseDetail?.data?.logo?.logoUrl} />
                    </Style.LogoCourse>
                    )}
                  <Text headerText xxxl w6 style={{ marginTop: -5 }}>
                    {courseDetail.data.courseCategory?.name}
                  </Text>
                </Style.CourseNameBlock>
                <Text lg w6 style={{ marginTop: 16 }}>
                  {courseDetail.data.name}
                </Text>
                <Text headerText lg style={{ marginTop: 8 }}>
                  {courseDetail.data.overview}
                </Text>
              </>
            )
          }
        </Col>
        <Col span={11}>
          <Row>
            <Col span={11}>
              <Row>
                <Style.OverviewCol span={9}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>動画時間</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={13}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Text headerText w6>
                        {courseDetail.data.contentOverview.videosTotalDuration > 0
                          ? convertSecondTime(courseDetail.data.contentOverview.videosTotalDuration, 'H時間m分')
                          : 'ー'
                        }
                      </Text>
                    )
                  }
                </Style.OverviewCol>
              </Row>
            </Col>
            <Col span={13}>
              <Row>
                <Style.OverviewCol span={10}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>受講開始日</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={12}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Text headerText w6>
                        {courseDetail.data.startDate ? formatDateTime(courseDetail.data.startDate, DATE_FORMAT) : 'ー'}
                      </Text>
                    )
                  }
                </Style.OverviewCol>
              </Row>
            </Col>
            <Col span={11}>
              <Row>
                <Style.OverviewCol span={9}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>担当講師</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={13}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Tooltip placement="topLeft" title={teacherList.length > 0 ? teacherList.toString() : ''}>
                        <Text truncate w6>
                          {teacherList.length > 0 ? teacherList.toString() : 'ー'}
                        </Text>
                      </Tooltip>
                    )
                  }
                </Style.OverviewCol>
              </Row>
              <Row>
                <Style.OverviewCol span={9}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                    : <Text xs secondaryText w6>講座タイプ</Text>
                  }
                </Style.OverviewCol>
                <Style.OverviewCol span={13}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Tooltip placement="topLeft" title={courseDetail.data.category ? courseDetail.data.category.name : ''}>
                        <Text truncate w6>
                          {!isEmpty(courseDetail.data.category) ? courseDetail.data.category.name : 'ー'}
                        </Text>
                      </Tooltip>
                    )
                  }
                </Style.OverviewCol>
              </Row>
            </Col>
            <Col span={13}>
              <Row>
                <Col span={10}>
                  {courseDetail.load
                    ? (
                      <SkeletonLoading
                        active
                        paragraph={false}
                        title={{ width: 70 }}
                        height={12}
                        style={{ marginTop: 2 }}
                      />
                    )
                    : <Text xs secondaryText w6 style={{ marginTop: 2 }}>受講終了日</Text>
                  }
                </Col>
                <Col span={10}>
                  {courseDetail.load
                    ? (
                      <>
                        <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                        <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                      </>
                    )
                    : (
                      <>
                        <Text headerText w6>
                          {courseDetail.data.endDate ? formatDateTime(courseDetail.data.endDate, DATE_FORMAT) : 'ー'}
                        </Text>
                        {courseDetail.data.endDate && (
                          <Text xs headerText w6>
                            [ 残り{courseDetail.data.remainingDays > 0 ? courseDetail.data.remainingDays : 0}日 ]
                          </Text>
                        )}
                      </>
                    )
                  }
                </Col>
              </Row>
            </Col>
            <Col span={11}>
              <Row>
                <Style.OverviewCol span={9}>
                  {courseDetail.load
                      ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                      : <Text xs secondaryText w6>ライセンス数</Text>
                    }
                </Style.OverviewCol>
                <Style.OverviewCol span={13}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 80 }} />
                    : (
                      <Text headerText w6>
                        {`${courseDetail.data.licensesCount || 0}[残り${(courseDetail.data.licensesCount - courseDetail.data.usedLicensesCount) || 0}]`}
                      </Text>
                    )
                  }
                </Style.OverviewCol>
              </Row>
              <Row style={{ marginTop: '16px' }}>
                <Space size="middle">
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => history.push({
                      pathname: CompanyPath.LicenseGrant,
                      state: {
                        courseId: courseDetail.data?.id,
                      },
                    })}
                    disabled={courseDetail.load || ['finished', 'closed'].includes(courseDetail.data?.actualStatus)}
                  >
                    ライセンス付与
                  </Button>
                </Space>
              </Row>
            </Col>
            <Col span={13}>
              <Style.RecommendDateBlock>
                <Style.RecommendDateWrapper>
                  <Row>
                    <Col span={8}>
                      {courseDetail.load
                        ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={12} />
                        : <Text headerText w7 sm>完了推奨期間</Text>
                      }
                    </Col>
                    <Col span={16}>
                      {renderRecommendDate()}
                    </Col>
                  </Row>
                  {((courseDetail.data.recommendedStartDate &&
                    courseDetail.data.recommendedEndDate) ||
                    courseDetail.load) && (
                    <Row style={{ marginTop: 6 }}>
                      <Col span={8}>
                        {courseDetail.load ? (
                          <SkeletonLoading
                            active
                            paragraph={false}
                            title={{ width: 70 }}
                            height={12}
                          />
                        ) : (
                          <Text headerText w7 sm>
                            完了推奨日まで
                          </Text>
                        )}
                      </Col>
                      <Col span={16}>{renderRecommendedCompletionDay()}</Col>
                    </Row>
                  )}
                </Style.RecommendDateWrapper>
                {!courseDetail.load && !courseDetail.data.recommendedStartDate &&
                  !courseDetail.data.recommendedEndDate && (
                    <Text xs>
                      ※完了推奨期間の設定はスキルアップAIまでご連絡ください
                    </Text>
                  )}
              </Style.RecommendDateBlock>
            </Col>
          </Row>
        </Col>
      </Row>
    </Style.CourseOverviewContainer>
  );
};

export default CourseOverview;
