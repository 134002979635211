// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import { ADMIN_SERVICE, SUCCESS, FAILURE, REQUEST } from 'src/redux/constant';

import { videoServices } from 'src/services';

function* getVideoListSaga(action) {
  try {
    const result = yield videoServices.getVideoList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.VIDEO_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.VIDEO_ACTION.GET_LIST), errors: e.errors });
  }
}

export default function* videoSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.VIDEO_ACTION.GET_LIST), getVideoListSaga),
  ]);
}
