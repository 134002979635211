// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import moveLeftIcon from 'src/assets/images/videoPlayer/move-left-icon.svg';
import moveRightIcon from 'src/assets/images/videoPlayer/move-right-icon.svg';

export const VideoDetailContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const VideoDetailContent = styled.div`
  margin-right: 16px;
  width: calc(100% - 400px);
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const VideoDetailTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const VideoPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  width: 100%;
`;

export const VideoPlayerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;

  ${(props) => props.load
    && css`
      background-color: #f2f2f2;
    `}
`;

export const VideoDescription = styled.div`
  padding: 24px;
`;

export const VideoTeacher = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  height: 36px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 40px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export const VideoActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const FAQContent = styled.div`
  width: 400px;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  overflow: hidden;
`;

export const FAQTitle = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const MoveLeftIcon = styled.div`
  width: 20px;
  height: 24px;
  background: url(${moveLeftIcon});
  background-repeat: no-repeat;
  background-size: 20px;
`;

export const MoveRightIcon = styled.div`
  width: 20px;
  height: 24px;
  background: url(${moveRightIcon});
  background-repeat: no-repeat;
  background-size: 20px;
`;

export const CollapseContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const AnswerContainer = styled.div`
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

  &:last-child {
    border-bottom: none;
  }
`;

export const AnswerContent = styled.div`
  margin-top: 16px;
  padding: 16px;
  background-color: #FFFCF2;
`;

export const ShowMoreAction = styled.div`
  margin: 16px 0;
  padding: 0 16px;
`;

export const QuestionFormContainer = styled.div`
  padding: 16px;
`;

export const QuestionFormTitle = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 8px;
`;

export const QuestionSubmitButton = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    width: 100px;
  }
`;
