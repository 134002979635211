// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Col, Row } from 'antd';

export const CourseOverviewContainer = styled.div`
  margin: 16px 0 10px;
  min-height: 150px;
`;

export const OverviewCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const LogoCourse = styled.img`
  width: 78px;
  height: 78px;
  margin-right: 10px;
  margin-top: 7px;
  object-fit: scale-down;
`;

export const CourseNameBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const OverviewRowButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
`;

export const RecommendDateBlock = styled.div`
  width: 324px;
  height: 77px;
  position: absolute;
  top: -45px;
  right: -10px;
  display: flex;
  flex-direction: column;
`;

export const RecommendDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 1px solid #cccccc;
  border-radius: 4px;
`;

export const RowCustom = styled(Row)`
  position: relative;
  width: 100%;
`;
