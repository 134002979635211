// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Select, Input, Radio, Form } from 'antd';
import {
  CaretDownOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import { getFilterOption } from 'src/utils/common';
import { ONLY_NUMBER } from 'src/constants/regexValidate';

import {
  getVersionsOfCourse as getVersionsOfCourseAction,
  createCourseVersion as createCourseVersionAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface ICreateVersionModalProps extends StateProps, DispatchProps {
  getVersionsOfCourse(params): void;
  createCourseVersion(params): void;
  setIndexCourseWillOpen(params): void;
  setIsReloadVersionList(params): void;
  courseVersions: {
    data: {
      id: number;
      name: string;
    }[];
  };
  modalData: {
    courseId: number;
    courseCollapseIndex: number;
  };
}

const CreateVersionModal: React.FC<ICreateVersionModalProps> = ({
  getVersionsOfCourse,
  createCourseVersion,
  setIndexCourseWillOpen,
  setIsReloadVersionList,
  courseVersions,
  modalData,
}) => {
  const [versionType, setVersionType] = useState<string>('automation');
  const [createVersionForm] = Form.useForm();

  useEffect(() => {
    getVersionsOfCourse({ courseCategoryId: modalData.courseId });
  }, []);

  const initialFormValues = {
    versionType: 'automation',
  };

  function handleSubmitForm(values) {
    createCourseVersion({
      courseCategoryId: modalData.courseId,
      courseCollapseIndex: modalData.courseCollapseIndex,
      requestData: {
        ...values,
        versionName: values.versionName?.trim(),
        versionDescription: values.versionDescription.trim(),
      },
      requestAction: {
        setIndexCourseWillOpen,
        setIsReloadVersionList,
      },
    });
  }

  function renderVersionOptions() {
    if (isEmpty(courseVersions.data)) return null;
    return courseVersions.data.map((version) => (
      <Select.Option key={`version-${version.id}`} value={version.id}>{version.name}</Select.Option>
    ));
  }

  return (
    <Form
      form={createVersionForm}
      name="createVersion"
      initialValues={initialFormValues}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.CreateVersionContent>
        <Form.Item
          name="versionType"
          labelAlign="left"
        >
          <Radio.Group
            onChange={(e) => {
              setVersionType(e.target.value);
              createVersionForm.setFields([
                {
                  name: 'versionSourceId',
                  errors: [],
                },
              ]);
            }}
          >
            <Radio value="automation">バージョン追加</Radio>
            <Radio value="custom">カスタム</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="コピー用バージョン"
          name="courseId"
          labelAlign="left"
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          rules={[
            { required: true, message: 'コピー用バージョンは必須項目です。' },
          ]}
        >
          <Select
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            optionFilterProp="children"
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            filterOption={getFilterOption}
            style={{ width: 350 }}
          >
            {renderVersionOptions()}
          </Select>
        </Form.Item>
        {versionType === 'custom' && (
          <Form.Item
            label="バージョン名"
            name="versionName"
            labelAlign="left"
            colon={false}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            validateFirst
            rules={[
              { required: true, whitespace: true, message: 'バージョン名は必須項目です。' },
              { max: 50, message: 'バージョン名が長すぎます。（最大は50桁です）' },
              () => ({
                validator(_, value) {
                  const duplicatedIndex = courseVersions.data.findIndex((version) => version.name === value);
                  if (duplicatedIndex >= 0 ) {
                    return Promise.reject('入力されたバージョン名がすでに存在しております。');
                  }
                  return Promise.resolve();
                },
              }),
              {
                pattern: new RegExp(ONLY_NUMBER),
                message: 'バージョン名は数字のみを入力しないでください。',
              },
            ]}
          >
            <Input style={{ width: 350 }} />
          </Form.Item>
        )}
        <Form.Item
          label="説明"
          name="versionDescription"
          labelAlign="left"
          colon={false}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          validateFirst
          rules={[
            { required: true, message: '説明は必須項目です。' },
            { whitespace: true, message: '説明は必須項目です。' },
            { max: 100, message: '説明が長すぎます。（最大は100桁です）' },
          ]}
        >
          <Input.TextArea style={{ width: 350 }} autoSize={{ minRows: 2, maxRows: 4 }} />
        </Form.Item>
      </Style.CreateVersionContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { courseVersions } = state.courseReducer;
  return { courseVersions };
};

const mapDispatchToProps = (dispatch) => ({
  getVersionsOfCourse: (params) => dispatch(getVersionsOfCourseAction(params)),
  createCourseVersion: (params) => dispatch(createCourseVersionAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CreateVersionModal);
