// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
} from 'antd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import {
  updateTag as updateTagAction,
  createTag as createTagAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModifyTagsModalProps {
  updateTag(params): void;
  createTag(params): void;
  modalData: {
    tag: {
      id: number;
      name: string;
      description: string,
    },
  };
}

const ModifyTagsModal: React.FC<IModifyTagsModalProps> = ({
  updateTag,
  createTag,
  modalData,
}) => {

  const [modifyTagForm] = Form.useForm();
  const tagId = modalData.tag?.id;

  const tagInitialFormValues = {
    name: modalData.tag?.name,
    description: modalData.tag?.description,
  };

  function handleSubmitForm(values) {
    const requestData = {
      tag: {
        name: values.name.trim(),
        description: (values.description || '').trim(),
      },
    };
    if (tagId) {
      return updateTag({ requestData, id: tagId });
    } else {
      return createTag(requestData);
    }
  }

  return (
    <Form
      form={modifyTagForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      name="modifyTagForm"
      initialValues={tagInitialFormValues}
      {...modalData.tag.id && { initialValues: tagInitialFormValues }}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.TagFieldContent>
        <Form.Item
          label="タグ名"
          name="name"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { required: true, message: 'タグ名は必須項目です。' },
            { whitespace: true, message: 'タグ名は必須項目です。' },
            { max: 25, message: 'タグ名が長すぎます。（最大は25桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="タグ説明"
          name="description"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { max: 125, message: 'タグ説明が長すぎます。（最大は125桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
      </Style.TagFieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => ({
  updateTag: (params) => dispatch(updateTagAction(params)),
  createTag: (params) => dispatch(createTagAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyTagsModal);
