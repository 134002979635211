// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';

export function getCategoryList() {
  return {
    type: REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST),
  };
}

export function updateCategory(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY),
    payload: params,
  };
}

export function createCategory(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.CREATE_CATEGORY),
    payload,
  };
}

export function deleteCategory(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY),
    payload: params,
  };
}
