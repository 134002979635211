// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ServicePath,
  CompanyPath,
} from 'src/constants/routerConstants';

export const ADMIN_HEADER_ITEM = [
  {
    title: 'コース管理',
    path: [
      ServicePath.CourseList,
      ServicePath.CourseDetail,
      ServicePath.EditCourse,
      ServicePath.CreateCourse,
    ],
  },
  {
    title: 'レッスン管理',
    path: [
      ServicePath.UnitList,
      ServicePath.UnitDetail,
      ServicePath.CreateUnit,
      ServicePath.VideoList,
      ServicePath.TestList,
      ServicePath.CreateTest,
      ServicePath.EditTest,
      ServicePath.UploadAnswerList,
      ServicePath.SurveyList,
      ServicePath.CreateSurvey,
      ServicePath.EditSurvey,
      ServicePath.TipList,
      ServicePath.CreateTip,
      ServicePath.EditTip,
      ServicePath.FAQList,
      ServicePath.CreateFAQ,
      ServicePath.EditFAQ,
    ],
  },
  {
    title: 'アカウント管理',
    path: [
      ServicePath.CompanyList,
      ServicePath.ManagerAccount,
      ServicePath.LicenseGrant,
      ServicePath.LicenseList,
      ServicePath.CreateLicense,
      ServicePath.EditLicense
    ],
  },
  {
    title: '設定',
    path: [
      ServicePath.MasterSetting,
    ],
  },
];

export const COMPANY_DROPDOWN_ITEMS = [
  {
    title: 'ユーザー一覧',
    path: CompanyPath.CompanyDetail,
    state: { key: '3' },
  },
  {
    title: 'ユーザー登録',
    path: CompanyPath.ManagerAccount,
    state: { key: '1' },
  },
  {
    title: '企業管理者登録',
    path: CompanyPath.ManagerAccount,
    state: { key: '2' },
  },
  {
    title: '登録状況',
    path: CompanyPath.ManagerAccount,
    state: { key: '3' },
  },
  {
    title: 'ライセンス付与',
    path: CompanyPath.LicenseGrant,
  },
  {
    title: '企業登録情報',
    path: CompanyPath.CompanyDetail,
    state: { key: '1' },
  },
  {
    title: 'プロフィール項目設定',
    path: CompanyPath.MasterSetting,
  },
];
