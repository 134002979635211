// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import {
  MODAL_ACTION,
  ADMIN_SERVICE,
  SUCCESS,
  FAILURE,
  REQUEST,
} from 'src/redux/constant';

import { showAlertNotice } from 'src/utils/alert';
import { categoryServices } from 'src/services';

function* getCategoryListSaga() {
  try {
    const result = yield categoryServices.getCategoryList();
    yield put({
      type: SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST), errors: e.errors });
  }
}

function* createCategorySaga(action) {
  try {
    yield categoryServices.createCategory(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.CREATE_CATEGORY),
    });
    yield put({
      type: REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST),
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST), errors: e.errors });
  }
}

function* updateCategorySaga(action) {
  try {
    yield categoryServices.updateCategory(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY),
      payload: {
        data: {
          id: action.payload.id,
          name: action.payload.requestData.category.name,
          description: action.payload.requestData.category.description,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY), errors: e.errors });
  }
}

function* deleteCategorySaga(action) {
  try {
    yield categoryServices.deleteCategory(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に削除されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY), errors: e.errors });
  }
}

export default function* categorySaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST), getCategoryListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.CREATE_CATEGORY), createCategorySaga),
    takeLatest(REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY), updateCategorySaga),
    takeLatest(REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY), deleteCategorySaga),
  ]);
}
