//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Space,
} from 'antd';

import history from 'src/utils/history';

import {
  getStudentUnitDetail as getStudentUnitDetailAction,
  getStudentCourseDetail as getStudentCourseDetailAction,
  joinStudentTest as joinStudentTestAction,
  getTestResults as getTestResultsAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, StudentTitle } from 'src/constants/pageTitle';

import * as Style from './styles';
import { isAndroidDevice } from 'src/utils/common';

interface ITestOverviewPageProps extends StateProps, DispatchProps {
  getStudentUnitDetail(params): void;
  getStudentCourseDetail(params): void;
  joinStudentTest(params): void;
  getTestResults(params): void;
  closeModal(): void;
  unitDetail: {
    data: {
      id: number;
      isDone: boolean;
      item: {
        limitTime: number;
        name: string;
        description: number;
        questionsCount: number;
        testType: string;
        type: string;
        limitAttempt: number;
        usedAttemptsCount: number;
        latestTestsUserId: number;
        isUploadTypeQuestionExist: boolean;
      };
      unit: {
        name: string;
      };
    };
    load: boolean;
  };
  courseTopDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
}

const TestOverviewPage: React.FC<ITestOverviewPageProps> = ({
  unitDetail,
  courseTopDetail,
  getStudentUnitDetail,
  getStudentCourseDetail,
  joinStudentTest,
  getTestResults,
  closeModal
}) => {
  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[2];
  const unitVersionId = pathnameSplit[4];

  const [isAndroidMobile, setIsAndroidMobile] = useState(Boolean)

  useEffect(() => {
    document.title = PAGE_TITLE(StudentTitle.TestOverview);

    setIsAndroidMobile((window.innerWidth < 428) && isAndroidDevice())
    window.addEventListener('resize', () => {
      setIsAndroidMobile((window.innerWidth < 428) && isAndroidDevice())
    });
    return () => {
      closeModal();
    }
  }, []);

  useEffect(() => {
    getStudentUnitDetail({
      courseId,
      unitVersionId,
    });
    if (parseFloat(courseId) !== courseTopDetail.data.id) {
      getStudentCourseDetail({ id: courseId });
    }
  }, [courseId, unitVersionId]);

  function renderTestLimit() {
    if (unitDetail.data.item.limitAttempt === 0) {
      return '無制限';
    } if (unitDetail.data.item.usedAttemptsCount > unitDetail.data.item.limitAttempt) {
      return `${unitDetail.data.item.limitAttempt}/${unitDetail.data.item.limitAttempt}`;
    } else {
      return `${unitDetail.data.item.usedAttemptsCount}/${unitDetail.data.item.limitAttempt}`;
    }
  }

  return (
    <MainContainer isStudent>
      <MainContent fullView isResponsive>
        {courseTopDetail.load
          ? <Style.CourseNameLoading active paragraph={false} title={{ width: 300 }} height={22} />
          : <Style.CourseName white w6 xxl>{courseTopDetail.data.courseCategory?.name}</Style.CourseName>
        }
        <Style.TestOverviewContainer>
          <Style.TestOverviewTitle>
            {unitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Style.TitleTest truncate white lg w6>{unitDetail.data.unit.name}</Style.TitleTest>
            }
          </Style.TestOverviewTitle>
          <Style.TestOverviewDescription>
            {unitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : <Text multiLine>{unitDetail.data.item.description}</Text>
            }
          </Style.TestOverviewDescription  >
          <Style.TestOverviewFooter>
            <Style.InfoTestContainer>
              {unitDetail.load
                ? (
                  <>
                    <Space align="center" size="large">
                      <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                    </Space>
                    <Space align="center" size="large">
                      <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                    </Space>
                    <Space align="center" size="large">
                      <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                    </Space>
                    <Space align="center" size="large">
                      <SkeletonLoading active paragraph={false} title={{ width: 150 }} />
                    </Space>
                  </>
                )
                : (
                  <>
                    <Style.InfoTest align="center" size="large">
                      <Style.LabelInfoTest xs subText w6>問題数</Style.LabelInfoTest>
                      <Style.ValueInfoTest headerText w6>
                        {`${unitDetail.data.item.questionsCount}問`}
                      </Style.ValueInfoTest>
                    </Style.InfoTest>
                    <Style.InfoTest align="center" size="large">
                      <Style.LabelInfoTest xs subText w6>制限時間</Style.LabelInfoTest>
                      <Style.ValueInfoTest headerText w6>
                        {unitDetail.data.item.limitTime > 0
                          ? `${unitDetail.data.item.limitTime / 60}分`
                          : '無制限'
                        }</Style.ValueInfoTest>
                    </Style.InfoTest>
                    <Style.InfoTest align="center" size="large">
                      <Style.LabelInfoTest xs subText w6>受講回数</Style.LabelInfoTest>
                      <Style.ValueInfoTest headerText w6>
                        {renderTestLimit()}
                      </Style.ValueInfoTest>
                    </Style.InfoTest>
                    {unitDetail.data.isDone && (
                      <Style.ButtonContainer>
                        <Button
                          type="link"
                          style={{ padding: 0 }}
                          onClick={() => getTestResults({
                            courseId,
                            requestData: { testsUserId: unitDetail.data.item.latestTestsUserId },
                          })}
                        >
                          最新のテスト結果を見る
                        </Button>
                      </Style.ButtonContainer>
                    )}
                  </>
                )
              }
            </Style.InfoTestContainer>
            <Button
              type="primary"
              className="join-test"
              onClick={() => joinStudentTest({
                courseId,
                testType: unitDetail.data.item.testType,
                requestData: {
                  unitVersionId: parseFloat(unitVersionId),
                },
              })}
              disabled={courseTopDetail.load || unitDetail.load}
            >
              テストを受ける
            </Button>
          </Style.TestOverviewFooter>
        </Style.TestOverviewContainer>
        <Style.NoteTest color="red" xs margin="0px 16px">
          ※テスト開始後に途中でブラウザを閉じると、0点で受験回数がカウントされるため、受験回数に制限がある場合は注意が必要です。<br />
          時間が十分に取れるタイミングで、テストを受けるようにしてください。
        </Style.NoteTest>
        <br/>
        {isAndroidMobile && unitDetail?.data?.item?.isUploadTypeQuestionExist &&
          <Style.NoteTest color="red" xs margin="0px 16px">
            スマートフォンでの受験は、テストの回答を正常に提出できない可能性がございます。PCでの受験をお願いします。
          </Style.NoteTest>}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { unitDetail } = state.studentUnitReducer;
  const { courseTopDetail } = state.studentCourseReducer;
  const { testQuestions } = state.studentTestReducer;
  return {
    unitDetail,
    courseTopDetail,
    testQuestions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  joinStudentTest: (params) => dispatch(joinStudentTestAction(params)),
  getStudentUnitDetail: (params) => dispatch(getStudentUnitDetailAction(params)),
  getStudentCourseDetail: (params) => dispatch(getStudentCourseDetailAction(params)),
  getTestResults: (params) => dispatch(getTestResultsAction(params)),
  closeModal: () => dispatch(closeModalAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestOverviewPage);
