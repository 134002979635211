//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { isEmpty } from 'lodash/lang';

import { formatDateTime } from 'src/utils/dateTime';
import history from 'src/utils/history';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';
import SortableHeader from 'src/components/tables/SortableHeader';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import {
  getUnitList as getUnitListAction,
  getUnitDetail as getUnitDetailAction,
  getCategoryList as getCategoryListAction,
} from 'src/redux/actions';
import { ServicePath } from 'src/constants/routerConstants';
import { UNIT_TYPE, DATE_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

interface IUnitListPageProps extends StateProps, DispatchProps {
  getUnitList(params): void;
  getUnitDetail(params): void;
  getCategoryList(): void;
  categoryList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  unitList: {
    data: {
      id: number;
      currentVersion: string;
      item: {
        type: string;
        typeText: string;
        id: number;
        name: string;
      }[];
      name: string;
      updatedAt: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: string;
  };
}

const UnitListPage: React.FC<IUnitListPageProps> = ({
  getUnitList,
  getUnitDetail,
  getCategoryList,
  unitList,
  categoryList,
}) => {
  const { data, meta } = unitList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.UnitList);

    getUnitList({ page: 1 });
    getCategoryList();
  }, []);

  const columns = [
    {
      title: <SortableHeader
        type="itemType"
        title="種別"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUnitList}
        setAction={setSortCondition}
      />,
      dataIndex: 'type',
      width: 100,
    },
    {
      title: <SortableHeader
        type="name"
        title="レッスン名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUnitList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      dataIndex: 'name',
      width: 250,
    },
    {
      title: <SortableHeader
        type="category"
        title="講座タイプ"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUnitList}
        setAction={setSortCondition}
      />,
      dataIndex: 'category',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.category}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title: 'バージョン',
      dataIndex: 'currentVersion',
      width: 130,
    },
    {
      title: 'コンテンツ名',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.itemName}
        </TooltipParagraph>
      ),
      dataIndex: 'itemName',
      width: 250,
    },
    {
      title: <SortableHeader
        type="updatedAt"
        title="更新日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUnitList}
        setAction={setSortCondition}
      />,
      dataIndex: 'updatedAt',
      width: 130,
    },
  ];
  const tableData = data.map((row: any) => ({
    ...row,
    category: row.category?.name,
    key: row.id,
    type: UNIT_TYPE[row.item.type],
    itemName: row.item.name,
    updatedAt: formatDateTime(row.updatedAt, DATE_FORMAT),
  }));

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '更新日',
    },
    {
      type: 'select',
      title: '種別',
      fieldParams: 'type',
      options: [
        { id: 'video', name: '動画' },
        { id: 'test', name: 'テスト' },
        { id: 'tip', name: 'Tips' },
        { id: 'survey', name: 'アンケート' },
      ],
    },
    {
      type: 'select',
      title: '講座タイプ',
      fieldParams: 'category',
      options: categoryList.data,
    },
    {
      type: 'textInput',
      fieldParams: 'unit',
      title: 'レッスン名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getUnitList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>レッスン一覧</Text>
          <Button type="primary" onClick={() => history.push(ServicePath.CreateUnit)}>
            新規作成
          </Button>
        </TopContent>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          scroll={{ x: 1000, y: 506 }}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {getUnitDetail({
              id: record.id,
              redirect: `/admin-service/lesson/${record.id}`,
            })} 
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={unitList.load}
        />
        {!isEmpty(unitList.data) && (
          <PaginationLayout
            pageInfo={meta?.pageInfo}
            onChangePage={getUnitList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { unitList } = state.unitReducer;
  const { categoryList } = state.categoryReducer;
  return {
    unitList,
    categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUnitList: (params) => dispatch(getUnitListAction(params)),
  getUnitDetail: (params) => dispatch(getUnitDetailAction(params)),
  getCategoryList: () => dispatch(getCategoryListAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnitListPage);
