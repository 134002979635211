// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const Switch = styled.div`
  button {
    width: 80px;
    height: 35px;
    position: relative;
    border-radius: 30px;
    border: solid 1px #eee;
    outline: none;
    transition: background 0.4s;
    background: ${(props) => props.disable ? "#eee" : "#eacf79" };
    span {
      position: absolute;
      top: 4px;
      left: 4px;
      width: 35px;
      height: 25px;
      border-radius: 20px;
      background: ${(props) => props.disable ? "#C4C4C4" : "#D29C09" };
      transition: all 0.2s cubic-bezier(0, 0, 0.46, 1.82);
      transition: all 0.2s cubic-bezier(0.24, 1.74, 0.92, 0.85);
      color: white;
      padding-top: 4px; 
    }

    &:active {
      span {
        width: 30px;
      }

      &.off span {
        margin-left: -50px;
      }
    }

    &.off {
      background: #eee;
      span {
        left: 100%;
        margin-left: -40px;
        background: #C4C4C4;
      }
    }
  }
`;
