// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Space, Button } from 'antd';

import history from 'src/utils/history';
import { formatDateTime } from 'src/utils/dateTime';

import TestOverview from '../components/TestOverview';
import TestContent from '../components/TestContent';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';

import { DATE_TIME_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getTestDetail as getTestDetailAction,
  getTagList as getTagListAction,
  getCategoryList as getCategoryListAction,
  getQuestions as getQuestionsAction,
  updateTest as updateTestAction,
  clearTestData as clearTestDataAction,
  copyTest as copyTestAction
} from 'src/redux/actions';

import * as Style from '../styles';

interface IEditTestProps extends StateProps, DispatchProps {
  getTestDetail(params): void;
  getTagList(): void;
  getCategoryList(): void;
  getQuestions(params): void;
  updateTest(params): void;
  clearTestData(): void;
  copyTest(params): void;
  isDisableRequest: boolean;
  testId: string;
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  testDetail: {
    data: {
      id: number;
      addedToCourse: boolean;
      hasBeenUsed: boolean;
      name: string;
      description: string;
      testType: string;
      passScorePercentage: number;
      limitTime: number;
      limitAttempt: number;
      displayResults: boolean;
      explanationFile: {
        filename: string;
        url: string;
      };
      isAddedToLesson: boolean;
      category: {
        id: number;
        name: string;
      };
      tags: {
        id: number;
        name: string;
      }[];
      lastUpdater: {
        jituName: string;
      };
      updatedAt: string;
    };
  };
}

const EditTest: React.FC<IEditTestProps> = ({
  getTestDetail,
  getTagList,
  getCategoryList,
  getQuestions,
  updateTest,
  clearTestData,
  testId,
  tagList,
  categoryList,
  testDetail,
  copyTest,
  isDisableRequest
}) => {
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const urlActiveKey = history.location.state?.key;

  const path = history.location.pathname;

  useEffect(() => {
    setActiveTabKey("1")
  }, [path])

  useEffect(() => {
    if (urlActiveKey) setActiveTabKey(urlActiveKey);
  }, [urlActiveKey]);

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.EditTest);

    getTagList();
    getCategoryList();
    return () => {
      clearTestData();
    };
  }, []);

  useEffect(() => {
    if (testId) {
      if (parseFloat(testId) !== testDetail.data.id) {
        getTestDetail({ id: testId });
      }
      getQuestions({ id: testId });
    }
  }, [testId]);

  function handleCopyTest(testId) {
    if (testId) {
      copyTest({ id: testId });
    }
  }

  return (
    <>
      <Style.ModifyTestTitle>
        <Space align="center" size={32}>
          <Text headerText xxxl w6>テスト修正</Text>
          {testId && (
            <Space align="center" size={24}>
              <Space align="center" size={24}>
                <Text secondaryText xs w6>更新者</Text>
                <TooltipParagraph
                  style={{ maxWidth: 300, color: '#333333', fontWeight: '600' }}
                >
                  {testDetail?.data.lastUpdater?.jituName}
                </TooltipParagraph>
              </Space>
              <Space align="center" size={24}>
                <Text secondaryText xs w6>更新日</Text>
                <Text headerText w6>
                  {formatDateTime(testDetail?.data.updatedAt, DATE_TIME_FORMAT)}
                </Text>
              </Space>
              <Button type="primary" disabled={isDisableRequest} onClick={() => { handleCopyTest(testId) }}>コピーして新規作成</Button>
            </Space>
          )}
        </Space>
      </Style.ModifyTestTitle>
      <Tabs
        activeKey={activeTabKey}
        type="card"
        onChange={(activeKey) => setActiveTabKey(activeKey)}
      >
        <Tabs.TabPane
          tab={<Text xl headerText w6>テスト概要</Text>}
          key="1"
        >
          <TestOverview
            testId={testId}
            tagList={tagList}
            categoryList={categoryList}
            testDetail={testDetail}
            updateTest={updateTest}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={<Text xl headerText w6>問設定</Text>}
          key="2"
        >
          <TestContent testId={testId} hasBeenUsed={testDetail.data.hasBeenUsed} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  const { testDetail } = state.testReducer;
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    testDetail,
    tagList,
    categoryList,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTestDetail: (params) => dispatch(getTestDetailAction(params)),
  getTagList: () => dispatch(getTagListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  getQuestions: (params) => dispatch(getQuestionsAction(params)),
  updateTest: (params) => dispatch(updateTestAction(params)),
  clearTestData: () => dispatch(clearTestDataAction()),
  copyTest: (params) => dispatch(copyTestAction(params))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(EditTest);
