// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CompanyServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getCompanyDetail() {
    return this.restClient.get('/company_manage/companies');
  }

  getCompanyStudents(params) {
    return this.restClient.get('/company_manage/users', params);
  }

  getCompanyCourses(params) {
    return this.restClient.get('/company_manage/courses', params);
  }

  getCompanyCoursesStatus(params) {
    return this.restClient.get(`/company_manage/statistics/current_courses`, params);
  }

  getCompanyCustomInfos() {
    return this.restClient.get('/company_manage/companies/company_custom_user_infos');
  }

  updateCompanyCustomInfos(params) {
    return this.restClient.post('/company_manage/companies/company_custom_user_infos', params);
  }

  deleteCompanyCustomInfos(params) {
    return this.restClient.delete(`/company_manage/companies/company_custom_user_infos/${params.id}`);
  }

  updateCompanyInfos(params) {
    return this.restClient.put('/company_manage/companies', params);
  }
}
