// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import { isArray, isEmpty } from 'lodash/lang';

import { showAlertNotice } from 'src/utils/alert';
import history from 'src/utils/history';

import TestResultModal from 'src/components/Modal/components/TestResultStudentModal';

import {
  STUDENT,
  COMMON_ACTION,
  MODAL_ACTION,
  LOADING_ACTION,
  NOTIFICATION_ACTION,
  SUCCESS,
  FAILURE,
  REQUEST,
} from '../../constant';
import { TEST_TYPE_URL, TESTING_TYPE_URL } from 'src/constants/common';

import { studentTestServices } from 'src/services';
import ShowErrorModal from 'src/components/Modal/components/ShowErrorModal';
import StatusUploadModal from 'src/components/Modal/components/StatusUploadModal';

function* joinTestSaga(action) {
  try {
    const { requestData, courseId, testType } = action.payload;
    const result = yield studentTestServices.joinTest(action.payload);
    yield put({
      type: SUCCESS(STUDENT.TEST_ACTION.JOIN_TEST),
      payload: {
        data: {
          unitVersionId: parseFloat(requestData.unitVersionId),
          questions: result.data.questions,
          testsUserId: result.data?.testsUserId
        },
        meta: result.meta,
      },
    });
    yield put({
      type: REQUEST(COMMON_ACTION.SELECT_QUESTION),
      payload: result.data.questions[0]?.id,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (requestData.groupId && requestData.chapterId) {
      yield history.push({
        pathname: `/learn-course/${courseId}/testing/${requestData.unitVersionId}`,
        search: `?group=${requestData.groupId}&chapter=${requestData.chapterId}`,
      });
    } else {
      return yield history.push(`/learn-course/${courseId}/${TESTING_TYPE_URL[testType]}/${requestData.unitVersionId}`)
    }
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(STUDENT.TEST_ACTION.JOIN_TEST), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* submitTestAnswersSaga(action) {
  try {
    const {
      requestData,
      courseId,
      testType,
    } = action.payload;
    const result = yield studentTestServices.submitTestAnswers(action.payload);
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: 'テスト提出が成功しました。' });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (requestData.usersAnswer.groupId && requestData.usersAnswer.chapterId) {
      yield history.push({
        pathname: `/learn-course/${courseId}/test/${requestData.usersAnswer.unitVersionId}`,
        search: `?group=${requestData.usersAnswer.groupId}&chapter=${requestData.usersAnswer.chapterId}`,
      });
    } else {
      yield history.push(
        `/learn-course/${courseId}/${TEST_TYPE_URL[testType]}/${requestData.usersAnswer.unitVersionId}`,
      );
    }
    yield put({
      type: SUCCESS(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS),
      payload: result,
    });
    const modalProps = {
      isStudent: true,
      toggleClickOutside: true,
      width: isArray(result.data.testResults) ? 1100 : 700,
      modalData: {
        title: 'テスト結果',
        ...result.data,
        ...result.meta
      },
    };
    yield put({
      type: REQUEST(MODAL_ACTION.SHOW),
      payload: (<TestResultModal {...modalProps} />),
    });
    if (!['finish_test', 'pre_test', 'verify_test'].includes(testType)) {
      yield put({
        type: SUCCESS(STUDENT.COURSE_ACTION.UPDATE_COURSE_PROGRESS),
        payload: {
          meta: result.meta,
          data: {
            unitVersionId: requestData.usersAnswer.unitVersionId,
            groupId: requestData.usersAnswer.groupId,
            chapterId: requestData.usersAnswer.chapterId,
          },
        },
      });
    }
  } catch (e) {
    if (isArray(e.errors)) yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getFileDownloadURLSaga(action) {
  try {
    const result = yield studentTestServices.getFileDownloadURL(action.payload);
    if (result.data.test?.explanationFile) {
      window.open(result.data.test?.explanationFile?.url, '_blank');
    }
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
  }
}

function* getTestResultsSaga(action) {
  try {
    const { requestData } = action.payload;
    const result = yield studentTestServices.getTestResults(action.payload);
    const modalProps = {
      isStudent: true,
      toggleClickOutside: true,
      width: isArray(result.data.testResults) ? 1100 : 700,
      modalData: {
        title: 'テスト結果',
        testType: action.payload.testType,
        ...result.data,
        ...result.meta
      },
    };
    yield put({
      type: REQUEST(MODAL_ACTION.SHOW),
      payload: (<TestResultModal {...modalProps} />),
    });
    yield put({
      type: SUCCESS(STUDENT.TEST_ACTION.GET_TEST_RESULTS),
      payload: {
        notificationId: requestData.markNotificationAsReadId,
        ...result,
      },
    });
    yield put({ type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD) });
  } catch (e) {
    const { isNotification } = action.payload.requestData;
    if(isNotification){
      const modalProps = {
        isConfirm: true,
        modalData: {
          message: e.errors[0].message,
          title: 'データが存在しません',
        },
      };
      yield put({
        type: REQUEST(MODAL_ACTION.SHOW),
        payload: (<ShowErrorModal {...modalProps} />),
      });
    }
    else{
      yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    }
    yield put({ type: FAILURE(STUDENT.TEST_ACTION.GET_TEST_RESULTS), errors: e.errors });
  }
}


function* uploadFileAnswerSaga(action) {
  try {
    yield studentTestServices.uploadFileAnswer(action.payload);
    yield put({
      type: SUCCESS(STUDENT.TEST_ACTION.UPLOAD_FILE_ANSWER),
      payload: { questionId: action.payload.questionId },
    });
    if (action?.payload?.callback) {
      action?.payload?.callback()
    }
    if (action.payload.isMobileDevice) {
      const modalProps = {
        modalData: {
          title: '解答を提出',
          message: 'アップロードが成功しました。',
          status: 'success'
        },
      };
      yield action.payload.isMobileDevice && put({
        type: REQUEST(MODAL_ACTION.SHOW),
        payload: (<StatusUploadModal {...modalProps} />),
      });
    } else {
      yield showAlertNotice({ type: 'success', message: 'アップロードが成功しました。' });
    }
  } catch (e) {
    if (isEmpty(e?.errors || [])) {
      yield showAlertNotice({
        type: "error",
        message:
          "ファイルのアップロードに失敗しました。PCに切り替えて受験をしてください。テストを終了したことで受験回数が上限に達した場合は、企業管理者かスキルアップAIに問い合わせをして、テスト結果のリセットを行ってください。",
      });
    } else {
      yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    }
    yield put({
      type: FAILURE(STUDENT.TEST_ACTION.UPLOAD_FILE_ANSWER),
      payload: { questionId: action.payload.questionId },
    });
  }
}

function* getDetailsQuestionSaga(action) {
  try {
    const result = yield studentTestServices.getDetailsQuestion(action.payload.id);
    yield put({
      type: SUCCESS(STUDENT.TEST_ACTION.GET_DETAILS_QUESTION),
      payload: {
        dataQuestion: result.data,
        indexQuestion: action.payload.index
      }
    })
  } catch (e) {
    yield put({
      type: FAILURE(STUDENT.TEST_ACTION.GET_DETAILS_QUESTION),
      payload: e.errors
    })
  }
}
export default function* studentTestSaga() {
  yield all([
    takeLatest(REQUEST(STUDENT.TEST_ACTION.JOIN_TEST), joinTestSaga),
    takeLatest(REQUEST(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS), submitTestAnswersSaga),
    takeLatest(REQUEST(STUDENT.TEST_ACTION.GET_FILE_DOWNLOAD_URL), getFileDownloadURLSaga),
    takeLatest(REQUEST(STUDENT.TEST_ACTION.GET_TEST_RESULTS), getTestResultsSaga),
    takeLatest(REQUEST(STUDENT.TEST_ACTION.UPLOAD_FILE_ANSWER), uploadFileAnswerSaga),
    takeLatest(REQUEST(STUDENT.TEST_ACTION.GET_DETAILS_QUESTION), getDetailsQuestionSaga),
  ]);
}
