// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';

import { Text } from 'src/components/styles/Texts';

import * as Style from './styles';

interface INoDataProps {
  message: string;
  transparent?: boolean;
  style?: object;
}

const NoData: React.FC<INoDataProps> = ({
  message,
  transparent,
  style,
}) => (
  <Style.NoDataWrapper transparent={transparent} style={style}>
    <Text>{message}</Text>
  </Style.NoDataWrapper>
);

export default NoData;
