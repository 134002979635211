// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';

import * as Style from './styles';

interface IProcessLoadingProps extends StateProps {
  isProcessLoading: boolean;
}

const ProcessLoading: React.FC<IProcessLoadingProps> = ({
  isProcessLoading,
}) => {
  if (isProcessLoading === undefined) return null;
  return (
    <Style.ProcessLoading isDone={!isProcessLoading} />
  );
};

const mapStateToProps = (state) => {
  const { isProcessLoading } = state.loadingReducer;
  return {
    isProcessLoading,
  };
};

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(ProcessLoading);
