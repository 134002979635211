// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import { STUDENT, SUCCESS, FAILURE, REQUEST } from '../../constant';

import { studentSurveyServices } from 'src/services';
import { showAlertNotice } from 'src/utils/alert';

function* finishSurveySaga(action) {
  try {
    const result = yield studentSurveyServices.finishSurvey(action.payload);
    yield put({
      type: SUCCESS(STUDENT.SURVEY_ACTION.FINISHED),
      payload: result,
    });
    yield put({
      type: SUCCESS(STUDENT.COURSE_ACTION.UPDATE_COURSE_PROGRESS),
      payload: {
        meta: result.meta,
        data: action.payload,
      },
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(STUDENT.SURVEY_ACTION.FINISHED), errors: e.errors });
  }
}

export default function* studentSurveySaga() {
  yield all([
    takeLatest(REQUEST(STUDENT.SURVEY_ACTION.FINISHED), finishSurveySaga),
  ]);
}
