// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { Space, Collapse, Progress } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { sortBy } from 'lodash/collection';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';
import { convertSecondTime } from 'src/utils/dateTime';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import NoData from 'src/components/layouts/NoData';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import videoFileIcon from 'src/assets/images/videoPlayer/video-file-icon.svg';
import docFileIcon from 'src/assets/images/videoPlayer/doc-file-icon.svg';
import tipFileIcon from 'src/assets/images/common/tip-file-icon.svg';
import surveyFileIcon from 'src/assets/images/common/survey-file-icon.svg';

import * as Style from './styles';

interface ICourseSidebarProps {
  setIsShowSidebar(params): void;
  getGroupDetail(params): void;
  setCollapseData(params): void;
  selectQuestion(params): void;
  showModal(params): void;
  closeModal(): void;
  isReview?: boolean;
  isShowSidebar: boolean;
  currentUnitId: number;
  isMobileDevice?: boolean;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  questionIdSelected: number | string | null;
  courseDetail: {
    data: {
      id: number,
      name: string,
      studentProgressPercentage?: number,
      groups: {
        id: number;
        name: string;
        chapters: {
          id: string;
          faqs: {
            id: number;
            name: string;
            faqContents: {
              question: string;
              answer: string;
            }[];
          }[];
        }[];
        totalUnits: string;
        totalVideosTime: string;
      }[];
    },
  };
  testQuestions: {
    data: {
      unitVersionId: number;
      questions: {
        id: number;
        name: string;
      }[];
    };
  };
  courseTopDetail?: {
    data: {
      groups: {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          totalUnits: number;
          totalVideosTime: number;
        }[];
      }[];
    };
    load: boolean;
  };
}

const CourseSidebar: React.FC<ICourseSidebarProps> = ({
  setIsShowSidebar,
  getGroupDetail,
  setCollapseData,
  selectQuestion,
  showModal,
  closeModal,
  isReview,
  isShowSidebar,
  isMobileDevice,
  collapseData,
  questionIdSelected,
  courseDetail,
  testQuestions,
  currentUnitId,
  courseTopDetail
}) => {
  const groupId = getSearchParams('group') || '';
  const chapterId = getSearchParams('chapter') || '';
  const isTesting = history.location.pathname.includes('testing');
  const groupIndex = findIndex(courseDetail.data.groups, { id: parseFloat(groupId as string) });
  const chapterIndex = groupIndex !== -1
    ? findIndex(courseDetail.data.groups[groupIndex].chapters, { id: parseFloat(chapterId as string) })
    : -1;

  const [groupActive, setGroupActive] = useState<number>(-1);
  useEffect(() => {
    if (isTesting) {
      document.getElementById('question-active')?.scrollIntoView(true)
      window.scrollTo(0, 0);
    }
  }, [isTesting]);

  useEffect(() => {
    if (courseDetail.data.id) {
      if (groupIndex !== -1 && chapterIndex !== -1 && isEmpty(collapseData)) {
        setCollapseData([
          {
            key: `group-${groupIndex}`,
            chapters: [
              {
                key: `group-${groupIndex}-chapter-${chapterIndex}`,
              },
            ],
          },
        ]);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetail.data.id]);

  useEffect(() => {
    if (!isEmpty(collapseData) && groupActive !== -1) {
      const collapseNew = collapseData.map((itemCollapse, indexCollapse) => {
        const groupIndex = itemCollapse.key.toString().substring(6);
        if (courseDetail.data.groups[groupIndex].chapters && groupActive === indexCollapse) {
          const chapters = (
            courseDetail?.data?.groups[groupIndex]?.chapters || []
          ).map((_, index) => ({ key: `${itemCollapse.key}-chapter-${index}` }));
          setGroupActive(-1);
          return { key: itemCollapse.key, chapters };
        }
        return { ...itemCollapse };
      });
      setCollapseData(collapseNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetail]);

  function handleViewGroupDetail(index, id) {
    if (!courseDetail.data.groups[index].chapters) {
      getGroupDetail({
        requestData: {
          courseId: courseDetail.data.id,
          groupId: id,
        },
      });
    }
  }

  function handleChangeGroupCollapse(collapseValues) {
    const newCollapseValues = sortBy(collapseValues);
    const newCollapseData = newCollapseValues.map((value, indexCollapse) => {
      const groupActiveIndex = findIndex(collapseData, { key: value });
      if (groupActiveIndex === -1) {
        let chapters: any = [];
        const groupIndex = value.toString().substring(6);
        if (courseDetail?.data?.groups[groupIndex]?.chapters) {
          chapters = (
            courseDetail?.data?.groups[groupIndex]?.chapters || []
          ).map((item, index) => ({ key: `${value}-chapter-${index}` }));
        } else {
          setGroupActive(indexCollapse);
        }
        return { key: value, chapters };
      }
      return collapseData[groupActiveIndex];
    });
    setCollapseData(newCollapseData);
  }

  function handleChangeChapterCollapse(collapseValues, groupActiveIndex) {
    const newCollapseValues = sortBy(collapseValues);
    const newCollapseData = collapseData;
    const chapterCollapse = isEmpty(newCollapseValues) ? [] : newCollapseValues.map((value) => {
      const chapterActiveIndex = findIndex(newCollapseData[groupActiveIndex].chapters, { key: value });
      if (chapterActiveIndex === -1) {
        return { key: value };
      }
      return newCollapseData[groupActiveIndex].chapters[chapterActiveIndex];
    });
    newCollapseData[groupActiveIndex].chapters = chapterCollapse;
    setCollapseData(newCollapseData);
  }

  function handleSelectUnit(unitItem, groupData, chapterData) {
    const prefixPathName = isReview ? 'admin-service/view-course' : 'learn-course'
    if (isTesting && !isReview) {
      const modalProps = {
        isConfirm: true,
        modalData: {
          message: 'テストを途中で修了するとテスト結果が保存されない可能性があります。テストを中断しますか？',
          title: 'テスト中断確認',
        },
        modalAction: {
          confirm: () => {
            closeModal();
            return history.push({
              pathname: `/${prefixPathName}/${courseDetail.data.id}/${unitItem.item.type}/${unitItem.id}`,
              search: `?group=${groupData.id}&chapter=${chapterData.id}`,
            });
          },
        },
      };
      return showModal(<ConfirmModal {...modalProps} />);
    } else {
      return history.push({
        pathname: `/${prefixPathName}/${courseDetail.data.id}/${unitItem.item.type}/${unitItem.id}`,
        search: `?group=${groupData.id}&chapter=${chapterData.id}`,
      });
    }
  }

  function renderQuestionItem() {
    return testQuestions?.data.questions?.map((questionItem, questionItemIndex) => (
      <Style.QuestionItem
        key={`question-item-${questionItemIndex}`}
        active={questionItem.id === questionIdSelected}
        onClick={() => {
          selectQuestion(questionItem.id);
          isMobileDevice && window.scrollTo(0, 0);
        }}
      >
        {isShowSidebar && (
          <TooltipParagraph>{questionItem.name}</TooltipParagraph>
        )}
      </Style.QuestionItem>
    ));
  }

  function renderUnitIcon(type) {
    switch (type) {
    case 'video': {
      return videoFileIcon;
    }
    case 'test': {
      return docFileIcon;
    }
    case 'tip': {
      return tipFileIcon;
    }
    default: {
      return surveyFileIcon;
    }}
  }

  function renderUnitItems(groupData, chapterData, indexKey) {
    if (isEmpty(chapterData.unitVersions)) {
      return (
        <Style.NoUnitItemData>
          <Text>データが存在しません。</Text>
        </Style.NoUnitItemData>
      );
    }
    return chapterData.unitVersions.map((unitItem, unitIndex) => (
      <div key={`${indexKey}-unit-${unitIndex}`}>
        <Style.UnitItem
          active={!isTesting && unitItem.id.toString() === currentUnitId}
          onClick={() => handleSelectUnit(unitItem, groupData, chapterData)}
        >
          <Style.UnitTitle
            unitType={unitItem.item.type}
            id={
              (isTesting &&
              testQuestions?.data?.unitVersionId === unitItem.id) &&
              "question-active"
            }
          >
            <img
              src={renderUnitIcon(unitItem.item.type)}
              height="24px"
              width="24px"
              alt=""
            />
            {isShowSidebar && (
              <TooltipParagraph style={{ margin: 8 }} 
              >
                {unitItem.unit.name}
              </TooltipParagraph>
            )}
          </Style.UnitTitle>
          <Space align="baseline" style={{ position: 'absolute', right: 14, top: 13 }}>
            {(unitItem.item.questionsCount >= 0 || unitItem.item.duration >= 0) && (
              <Style.TotalUnitsAndTimeLabel subText lg style={{ marginRight: !unitItem.isDone && 24 }}>
                {unitItem.item.type === 'video'
                  ? convertSecondTime(unitItem.item.duration, 'HH:mm:ss')
                  : `${unitItem.item.questionsCount}問`
                }
              </Style.TotalUnitsAndTimeLabel>
            )}
            {unitItem.isDone && <CheckCircleTwoTone style={{ fontSize: 16 }} twoToneColor="#D29C09" />}
          </Space>
        </Style.UnitItem>
        {isTesting && testQuestions?.data?.unitVersionId === unitItem.id && (
          <>
            {renderQuestionItem()}
            <Style.QuestionItem
              key="question-item-confirm"
              active={questionIdSelected === 'confirm'}
              onClick={() => selectQuestion('confirm')}
            >
              <Text>解答確認</Text>
            </Style.QuestionItem>
          </>
        )}
      </div>
      ));
  }

  function renderChapterCollapse(groupData, indexKey) {
    if (isEmpty(groupData.chapters)) {
      return (
        <Style.NoChapterData>
          <Text>データが存在しません。</Text>
        </Style.NoChapterData>
      );
    }
    return groupData.chapters.map((chapterCollapse, chapterCollapseIndex) => {
      const indexGroup = collapseData.findIndex((item) => item.key === indexKey)
      const isShowTotal = !collapseData[indexGroup]?.chapters[chapterCollapseIndex]?.key.includes(`${indexKey}-chapter-${chapterCollapseIndex}`);
      return (
        <Collapse.Panel
          key={`${indexKey}-chapter-${chapterCollapseIndex}`}
          header={
            <Style.CollapseHeader>
              {!isMobileDevice ? (
                <Text truncate lg>
                  {chapterCollapse.name}
                </Text>
              ) : (
                <>
                  <div style={{ width: "50%" }}>
                    <Text w7 style={{ lineHeight: "20px" }}>
                      {chapterCollapse.name}
                    </Text>
                  </div>
                  {isShowTotal && (
                    <>
                      <Style.CellTable style={{ width: "30%" }}>
                        <Style.TotalUnitsAndTimeLabel>
                          {chapterCollapse.totalUnits}
                        </Style.TotalUnitsAndTimeLabel>
                      </Style.CellTable>
                      <Style.CellTable style={{ width: "20%" }}>
                        <Style.TotalUnitsAndTimeLabel>
                          {convertSecondTime(
                            chapterCollapse.totalVideosTime,
                            "HH:mm:ss"
                          )}
                        </Style.TotalUnitsAndTimeLabel>
                      </Style.CellTable>
                    </>
                  )}
                </>
              )}
            </Style.CollapseHeader>
          }
        >
          {renderUnitItems(
            groupData,
            chapterCollapse,
            `${indexKey}-chapter-${chapterCollapseIndex}`
          )}
        </Collapse.Panel>
      );
    });
  }

  function renderGroupCollapse() {
    if (isEmpty(courseDetail.data.groups)) return <NoData message="データが存在しません。" />;
    return courseDetail.data.groups.map((groupCollapse, groupCollapseIndex) => {
      const groupActiveIndex = findIndex(collapseData, { key: `group-${groupCollapseIndex}` });
      const chapterCollapseData = groupActiveIndex !== -1 ? collapseData[groupActiveIndex].chapters : [];
      const chapterActiveKey = chapterCollapseData.map((collapse) => collapse.key);
      return (
        <Collapse.Panel
          key={`group-${groupCollapseIndex}`}
          header={
            <Style.CollapseHeader
              onClick={() =>
                handleViewGroupDetail(groupCollapseIndex, groupCollapse.id)
              }
            >
              {isMobileDevice ? (
                <>
                  <div style={{ width: "50%" }}>
                  <Text w7 style={{ lineHeight: "20px" }} >
                    {groupCollapse.name}
                  </Text>
                  </div>
                  {groupActiveIndex === -1 && (
                    <>
                      <Style.CellTable style={{ width: "30%" }}>
                        <Style.TotalUnitsAndTimeLabel>
                          {
                            courseTopDetail?.data?.groups[groupCollapseIndex]
                              ?.totalUnits
                          }
                        </Style.TotalUnitsAndTimeLabel>
                      </Style.CellTable>
                      <Style.CellTable style={{ width: "20%" }}>
                        <Style.TotalUnitsAndTimeLabel>
                          {convertSecondTime(
                            courseTopDetail?.data?.groups[groupCollapseIndex]
                              ?.totalVideosTime,
                            "HH:mm:ss"
                          )}
                        </Style.TotalUnitsAndTimeLabel>
                      </Style.CellTable>
                    </>
                  )}
                </>
                ) : (
                  <>
                    <Text truncate lg w6>
                      {groupCollapse.name}
                    </Text>
                    {/* Fix onClick Icon not call API  */}
                    <Style.CollapseIcon>
                      {groupActiveIndex === -1 ? (
                        <PlusOutlined style={{ fontSize: 12 }} />
                      ) : (
                        <MinusOutlined style={{ fontSize: 12 }} />
                      )}
                    </Style.CollapseIcon>
                  </>
                )}
              </Style.CollapseHeader>
          }
        >
          <Collapse
            bordered={false}
            activeKey={[...chapterActiveKey]}
            expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}
            expandIconPosition="right"
            onChange={(values: any) => handleChangeChapterCollapse(values, groupActiveIndex)}
            style={{ backgroundColor: '#FAFAFA' }}
          >
            {groupCollapse.chapters && renderChapterCollapse(groupCollapse, `group-${groupCollapseIndex}`)}
          </Collapse>
        </Collapse.Panel>
      );
    });
  }
  return (
    <>
      {isShowSidebar
        ? (
          <Style.TopSidebar>
            <Space align="center" size="middle">
              <Text lg w7>コース内容</Text>
              {!isReview &&
                (isMobileDevice ? (
                  <Progress
                    type="circle"
                    strokeColor="#D29C09"
                    percent={courseDetail.data.studentProgressPercentage}
                    width={40}
                    format={() =>
                      `${courseDetail.data.studentProgressPercentage}%`
                    }
                  />
                ) : (
                  <Progress
                    type="circle"
                    strokeColor="#D29C09"
                    percent={courseDetail.data.studentProgressPercentage}
                    width={30}
                  />
                ))}
            </Space>
            <Style.MenuButton onClick={() => setIsShowSidebar(false)} >
              <MenuFoldOutlined style={{ fontSize: 20 }} />
            </Style.MenuButton>
          </Style.TopSidebar>
        )
        : (
          <Style.MenuButton onClick={() => setIsShowSidebar(true)} >
            <MenuUnfoldOutlined style={{ fontSize: 20 }} />
          </Style.MenuButton>
        )
      }
      <Style.CollapseContainer isShowSidebar={isShowSidebar}>
        <Style.HeaderWrapper>
          <Style.CellTableHeader style={{ width: '50%' }}>
            <Text white w7>レッスン名</Text>
          </Style.CellTableHeader>
          <Style.CellTableHeader style={{ width: '30%' }}>
            <Text white w7>レッスン数</Text>
          </Style.CellTableHeader>
          <Style.CellTableHeader style={{ width: '20%' }}>
            <Text white w7>時間</Text>
          </Style.CellTableHeader>
        </Style.HeaderWrapper>
        <Collapse
          bordered={false}
          activeKey={[...collapseData.map((collapse) => collapse.key)]}
          expandIcon={() => null} // Fix onClick Icon not call API
          expandIconPosition="right"
          onChange={(values: any) => handleChangeGroupCollapse(values)}
          style={{ backgroundColor: 'white' }}
        >
          {renderGroupCollapse()}
        </Collapse>
      </Style.CollapseContainer>
    </>
  );
};

export { CourseSidebar };
