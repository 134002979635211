// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

history.listen((location) => {
  if(location.state?.isSort) {
    return
  }
  window.scrollTo(0, 0)
});

export default history;
