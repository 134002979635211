// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
  MODAL_ACTION,
} from 'src/redux/constant';
import { findIndex } from 'lodash/array';
import { STATUS_USER } from './../../../constants/common';

interface listCourseCompanyProps{
    id: number;
    course: {
      id: number;
      name: string;
      courseCategory: {
        id: number;
        name: string;
      }
    },
    actualStatus: string;
    startDate: string;
    endDate: string;
    manageStatus: string
}

const initialState = {
  profileDetail: {
    data: {
      avatarUrl: '',
      status: ""
    },
    meta: {},
    load: false,
    errors: [],
  },
  changeRole: {
    errors: [],
  },
  changeEmail: {
    errors: [],
  },
  joinedCourses: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
  listTestsStudent: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  isResetSucess: false,
  listCourseCompany: {
    data: [] as listCourseCompanyProps[],
    meta: {},
    load: false,
    errors: [],
  }
};

export type ProfileReducer = Readonly<typeof initialState>;

export default (state: ProfileReducer = initialState, action: any): ProfileReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL): {
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      profileDetail: {
        data: data.user,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE): {
    const { data } = action.payload;
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        data: {
          ...state.profileDetail.data,
          ...data,
        },
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE): {
    const { errors } = action;
    return {
      ...state,
      changeRole: {
        ...state.changeRole,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.CLEAR_UPDATE_ROLE_ERRORS): {
    return {
      ...state,
      changeRole: {
        ...state.changeRole,
        errors: [],
      },
    };
    }
      
  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL): {
    const { data } = action.payload;
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        data: {
          ...state.profileDetail.data,
          ...data,
        },
      },
    };
  }

  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL): {
    const { errors } = action;
      
    return {
      ...state,
      changeEmail: {
        ...state.changeEmail,
        errors,
      },
    };
  }
      
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.CLEAR_UPDATE_EMAIL_USER_ERRORS): {
    return {
      ...state,
      changeEmail: {
        ...state.changeEmail,
        errors: [],
      },
    };
    }
      
  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO): {
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        load: true,
      },
    };
  }
      
  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO): {
    const { data } = action.payload;
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        data: {
          ...state.profileDetail.data,
          ...data,
        },
        load: false,
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO): {
    const { errors } = action;
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_JOINED_COURSES): {
    return {
      ...state,
      joinedCourses: {
        ...state.joinedCourses,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_JOINED_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      joinedCourses: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_JOINED_COURSES): {
    const { errors } = action;
    return {
      ...state,
      joinedCourses: {
        ...state.joinedCourses,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSES_DURATION): {
    const { data } = action.payload;
    const newCourseList = state.joinedCourses.data;
    const courseIndex = findIndex(state.joinedCourses.data, { id: data.id });
    const newCourseData = {
      ...state.joinedCourses.data[courseIndex],
      ...data,
    };
    newCourseList.splice(courseIndex, 1, newCourseData);
    return {
      ...state,
      joinedCourses: {
        ...state.joinedCourses,
        data: newCourseList,
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSES_DURATION): {
    const { errors } = action;
    return {
      ...state,
      joinedCourses: {
        ...state.joinedCourses,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT): {
    return {
      ...state,
      listTestsStudent: {
        ...state.listTestsStudent,
        load: true
      }
    }
  }
  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT): {
    const { data, meta } = action.payload;
    return {
      ...state,
      listTestsStudent: {
        ...state.listTestsStudent,
        load: false,
        data: data.testsUsers,
        meta
      }
    }
  }
  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT): {
    const { errors } = action;
    return {
      ...state,
      listTestsStudent: {
        ...state.listTestsStudent,
        errors,
        load: false
      }
    }
  }

  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST): {
    return {
      ...state,
      isResetSucess: true,
    }
  }
  case REQUEST(MODAL_ACTION.CLOSE): {
    return {
      ...state,
      isResetSucess: false,
    }
  }

  case REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_COURSE_COMPANY): {
    return {
      ...state,
      listCourseCompany: {
        ...state.listCourseCompany,
        load: true
      }
    }
  }
  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_COURSE_COMPANY): {
    const { data, meta } = action.payload;
    return {
      ...state,
      listCourseCompany: {
        ...state.listCourseCompany,
        load: false,
        data: data.coursesCompanies,
        meta
      }
    }
  }
  case FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_COURSE_COMPANY): {
    const { errors } = action;
    return {
      ...state,
      listCourseCompany: {
        ...state.listCourseCompany,
        errors,
        load: false
      }
    }
  }

  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER): {
    const index = state.listCourseCompany.data.findIndex(( item ) => item.id === action.payload)
    const newDataCourse ={
      ...state.listCourseCompany.data[index],
      manageStatus:state.listCourseCompany.data[index].manageStatus === "enabled" ? "disabled" : "enabled"
    };
    const newListCourse = state.listCourseCompany.data;
    newListCourse.splice(index, 1, newDataCourse)
    return {
      ...state,
      listCourseCompany:{
        ...state.listCourseCompany,
        data: [...newListCourse]
      }
    };
  }
  
  case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UNLOCK_USER): {
    return {
      ...state,
      profileDetail: {
        ...state.profileDetail,
        data: {
          ...state.profileDetail.data,
          status: STATUS_USER.ACTIVE
        },
      },
    }
  }

  default:
    return state;
}
};
