// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Space } from 'antd';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';

import history from 'src/utils/history';

import { Text } from 'src/components/styles';

import * as Style from './styles';
import { TooltipParagraph } from '../../TooltipParagraph';

interface ITestingSidebarProps {
  setIsShowSidebar(params): void;
  selectQuestion(params): void;
  isShowSidebar: boolean;
  isHideBtnMenu?: boolean;
  questionIdSelected: number | string;
  isMobileDevice?: boolean;
  courseTopDetail: {
    data: {
      id: number,
      name: string,
    },
  };
  testQuestions: {
    data: {
      unitVersionId: number;
      questions: {
        id: number;
        name: string;
        content: string;
        questionType: string;
        questionOptions: {
          id: number;
          content: string;
        }[];
      }[];
    };
  };
}

const TestingSidebar: React.FC<ITestingSidebarProps> = ({
  setIsShowSidebar,
  selectQuestion,
  isShowSidebar,
  isHideBtnMenu,
  courseTopDetail,
  testQuestions,
  questionIdSelected,
  isMobileDevice,
}) => {
  function renderQuestionItem() {
    return testQuestions.data.questions?.map((questionItem, questionItemIndex) => (
      <Style.QuestionItem
        key={`question-item-${questionItemIndex}`}
        active={questionItem.id === questionIdSelected}
        onClick={() => {
          selectQuestion(questionItem.id);
          isMobileDevice && window.scrollTo(0, 0);
        }}
      >
        <TooltipParagraph isPreWrap isEllipsis={!isShowSidebar}>{questionItem.name}</TooltipParagraph>
      </Style.QuestionItem>
    ));
  }
  return (
    <>
      {isShowSidebar
        ? (
          <Style.TopSidebar>
            <Style.TitleCourse lg w6 truncate style={{ width: 290 }}>
              {courseTopDetail.data.name}
            </Style.TitleCourse>
            <Style.MenuButton onClick={() => setIsShowSidebar(false)} isHideBtnMenu={isHideBtnMenu}>
              <MenuFoldOutlined style={{ fontSize: 20 }} />
            </Style.MenuButton>
          </Style.TopSidebar>
        )
        : (
          <Style.MenuButton onClick={() => setIsShowSidebar(true)} >
            <MenuUnfoldOutlined style={{ fontSize: 20 }} />
          </Style.MenuButton>
        )
      }
      <Style.TestingContainer isShowSidebar={isShowSidebar}>
        {renderQuestionItem()}
        <Style.QuestionItem
          key="question-item-confirm"
          active={questionIdSelected === 'confirm'}
          onClick={() => selectQuestion('confirm')}
        >
          <Text>解答確認</Text>
        </Style.QuestionItem>
      </Style.TestingContainer>
    </>
  );
};

export { TestingSidebar };
