// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  COMMON_ACTION,
  REQUEST,
} from '../constant';

const initialState = {
  collapseData: [] as {
    key: string;
    chapters: {
      key: string;
    }[];
  }[],
  questionIdSelected: NaN as number | string,
};

export type CommonReducer = Readonly<typeof initialState>;

export default (state: CommonReducer = initialState, action: any): CommonReducer => {
  switch (action.type) {
  case REQUEST(COMMON_ACTION.SET_COLLAPSE_DATA): {
    return {
      ...state,
      collapseData: [...action.payload],
    };
  }

  case REQUEST(COMMON_ACTION.SELECT_QUESTION): {
    return {
      ...state,
      questionIdSelected: action.payload,
    };
  }
  default:
    return state;
  }
};
