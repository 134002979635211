// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState } from 'react';
import {
  Row,
  Col,
  Space,
  DatePicker,
  Button,
  Spin,
} from 'antd';
import { isNumber } from 'lodash/lang';
import {
  ComposedChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';

import { convertSecondTime } from 'src/utils/dateTime';
import { DATE_FORMAT } from 'src/constants/common';

import { CardWrapper } from 'src/components/layouts/ContentLayout';

import { Text, SkeletonLoading } from 'src/components/styles';

import ChartDot from 'src/components/chart/ChartDot';

import learningDateIcon from 'src/assets/images/statistic/learning-date-icon.svg';
import learningTimeIcon from 'src/assets/images/statistic/learning-time-icon.svg';
import doneUnitIcon from 'src/assets/images/statistic/done-unit-icon.svg';
import learningProgressIcon from 'src/assets/images/statistic/learning-progress-icon.svg';
import learningChartIcon from 'src/assets/images/statistic/learning-chart-icon.svg';

import * as Style from './styles';

interface ITopOverviewProps {
  getStudentLearningTimes(params): void;
  startOfWeek: any;
  endOfWeek: any;
  currentTime: {
    data: string;
  };
  currentWeek: {
    data: {
      learningDates: {
        currentWeek: number;
        total: number;
        avgOnCompany: number;
      },
      learningTime: {
        currentWeek: number,
        total: number,
        avgOnCompany: number,
      },
      doneUnits: {
        currentWeek: number,
        total: number,
        avgOnCompany: number,
      },
      learningProgress: {
        currentWeek: number,
        total: number,
        avgOnCompany: number,
      };
    };
    load: boolean;
  };
  learningTimeChart: {
    data: {
      learningDate: string,
      learningTime: number,
    }[];
    meta: {
      maxLearningTime: number;
    },
    load: boolean;
  };
}

const TopOverview: React.FC<ITopOverviewProps> = ({
  getStudentLearningTimes,
  startOfWeek,
  endOfWeek,
  currentTime,
  currentWeek,
  learningTimeChart,
}) => {
  const {
    learningDates,
    learningTime,
    doneUnits,
    learningProgress,
  } = currentWeek.data;
  const [dates, setDates] = useState<any>([moment(currentTime.data).subtract(13, 'd'), moment(currentTime.data)]);
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const formattedChartData = learningTimeChart.data.map((data) => ({
    name: moment(data.learningDate).format('MM/DD'),
    '学習時間': parseFloat(convertSecondTime(data.learningTime, 'm')),
  }));

  function disabledDate(current) {
    const inFuture = current && current > moment();
    if (!dates || dates.length === 0) {
      return inFuture || false;
    }

    const tooLate = !dates[1] && dates[0] && current.diff(dates[0], 'days') > 60;
    return inFuture || tooLate;
  }

  function renderChart() {
    return (
      <Spin spinning={learningTimeChart.load}>
        <ResponsiveContainer width="100%" height={150}>
          <ComposedChart
            data={formattedChartData}
            margin={{ top: 24, right: 0, bottom: -5, left: -24 }}
          >
            <CartesianGrid vertical={false} stroke="#eeeeee" />
            <XAxis tickLine={true} dataKey="name" />
            <YAxis
              width={70}
              padding={{ bottom: 10 }}
              tickLine={false}
              axisLine={false}
              type="number"
            />
            <Tooltip />
            <Area
              isAnimationActive={false}
              activeDot={{ stroke: 'white', strokeWidth: 2, r: 8 }}
              dot={<ChartDot dotColor="#D29C09" />}
              type="linear"
              stroke="#D29C09"
              strokeWidth={2}
              dataKey="学習時間"
              fill="rgba(210, 156, 9, 0.3)"
              unit="分"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Spin>
    );
  }

  return (
    <Style.StudentTopWapper>
      <Text white xxxl style={{ margin: '24px 0 16px' }}>
        {`今週の学習データ [ ${startOfWeek.format('MM/DD')}~${endOfWeek.format('MM/DD')} ]`}
      </Text>
      <Row gutter={16}>
        <Col span={14}>
          <Row gutter={16}>
            <Col span={6}>
              <CardWrapper isStudent>
                <Style.OverviewCardContainer>
                  <Style.OverviewCardTop>
                    <div style={{ marginBottom: 12 }}>
                      <Space align="center">
                        <img src={learningDateIcon} alt="" />
                        <Text headerText lg w6>学習日数</Text>
                      </Space>
                    </div>
                    {currentWeek.load
                      ? (
                        <Space>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={30} />
                        </Space>
                      )
                      : (
                        <Text headerText h3 w6 style={{ fontSize: 30 }}>
                          {isNumber(learningDates.currentWeek) ? `${learningDates.currentWeek}日` : 'ー'}
                        </Text>
                      )
                    }
                  </Style.OverviewCardTop>
                  <Style.OverviewCardBottom>
                    {currentWeek.load
                      ? (
                        <div>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                        </div>
                      )
                      : (
                        <>
                          <div>
                            <Space>
                              <Text headerText>合計:</Text>
                              <Text headerText w6>
                                {isNumber(learningDates.total) ? `${learningDates.total}日` : 'ー'}
                              </Text>
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Text headerText>社内平均:</Text>
                              <Text headerText w6>
                                {isNumber(learningDates.avgOnCompany) ? `${learningDates.avgOnCompany}日` : 'ー'}
                              </Text>
                            </Space>
                          </div>
                        </>
                      )
                    }
                  </Style.OverviewCardBottom>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Col>
            <Col span={6}>
              <CardWrapper isStudent>
                <Style.OverviewCardContainer>
                  <Style.OverviewCardTop>
                    <div style={{ marginBottom: 12 }}>
                      <Space align="center">
                        <img src={learningTimeIcon} alt="" />
                        <Text headerText lg w6>学習時間</Text>
                      </Space>
                    </div>
                    {currentWeek.load
                      ? (
                        <Space>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={30} />
                        </Space>
                      )
                      : (
                        <Text headerText h3 w6 style={{ fontSize: 30 }}>
                          {isNumber(learningTime.currentWeek)
                            ? convertSecondTime(learningTime.currentWeek, 'H時間m分')
                            : 'ー'}
                        </Text>
                      )
                    }
                  </Style.OverviewCardTop>
                  <Style.OverviewCardBottom>
                    {currentWeek.load
                      ? (
                        <div>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                        </div>
                      )
                      : (
                        <>
                          <div>
                            <Space>
                              <Text headerText>合計:</Text>
                              <Text headerText w6>
                                {isNumber(learningTime.total)
                                  ? convertSecondTime(learningTime.total, 'H時間m分')
                                  : 'ー'}
                              </Text>
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Text headerText>社内平均:</Text>
                              <Text headerText w6>
                                {isNumber(learningTime.avgOnCompany)
                                  ? convertSecondTime(learningTime.avgOnCompany, 'H時間m分')
                                  : 'ー'}
                              </Text>
                            </Space>
                          </div>
                        </>
                      )
                    }
                  </Style.OverviewCardBottom>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Col>
            <Col span={6}>
              <CardWrapper isStudent>
                <Style.OverviewCardContainer>
                  <Style.OverviewCardTop>
                    <div style={{ marginBottom: 12 }}>
                      <Space align="center">
                        <img src={doneUnitIcon} alt="" />
                        <Text headerText lg w6>レッスン数</Text>
                      </Space>
                    </div>
                    {currentWeek.load
                      ? (
                        <Space>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={30} />
                        </Space>
                      )
                      : (
                        <Text headerText h3 w6 style={{ fontSize: 30 }}>
                          {isNumber(doneUnits.currentWeek) ? doneUnits.currentWeek : 'ー'}
                        </Text>
                      )
                    }
                  </Style.OverviewCardTop>
                  <Style.OverviewCardBottom>
                    {currentWeek.load
                      ? (
                        <div>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                        </div>
                      )
                      : (
                        <>
                          <div>
                            <Space>
                              <Text headerText>コース合計:</Text>
                              <Text headerText w6>
                                {isNumber(doneUnits.total) ? doneUnits.total : 'ー'}
                              </Text>
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Text headerText>コース平均:</Text>
                              <Text headerText w6>
                                {isNumber(doneUnits.avgOnCompany) ? doneUnits.avgOnCompany : 'ー'}
                              </Text>
                            </Space>
                          </div>
                        </>
                      )
                    }
                  </Style.OverviewCardBottom>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Col>
            <Col span={6}>
              <CardWrapper isStudent>
                <Style.OverviewCardContainer>
                  <Style.OverviewCardTop>
                    <div style={{ marginBottom: 12 }}>
                      <Space align="center">
                        <img src={learningProgressIcon} alt="" />
                        <Text headerText lg w6>学習進捗率</Text>
                      </Space>
                    </div>
                    {currentWeek.load
                      ? (
                        <Space>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={30} />
                        </Space>
                      )
                      : (
                        <Space align="baseline" size={0}>
                          <Text headerText h3 w6 style={{ fontSize: 30 }}>
                            {isNumber(learningProgress.currentWeek) ? `+${learningProgress.currentWeek}%` : 'ー'}
                          </Text>
                        </Space>
                      )
                    }
                  </Style.OverviewCardTop>
                  <Style.OverviewCardBottom>
                    {currentWeek.load
                      ? (
                        <div>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} />
                        </div>
                      )
                      : (
                        <>
                          <div>
                            <Space>
                              <Text headerText>コース合計:</Text>
                              <Text headerText w6>
                                {isNumber(learningProgress.total) ? `${learningProgress.total}%` : 'ー'}
                              </Text>
                            </Space>
                          </div>
                          <div>
                            <Space>
                              <Text headerText>コース平均:</Text>
                              <Text headerText w6>
                                {isNumber(learningProgress.avgOnCompany) ? `${learningProgress.avgOnCompany}%` : 'ー'}
                              </Text>
                            </Space>
                          </div>
                        </>
                      )
                    }
                  </Style.OverviewCardBottom>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Col>
          </Row>
        </Col>
        <Col span={10}>
          <CardWrapper isStudent>
            <Style.OverviewCardContainer>
              <Style.OverviewChart>
                <Style.ChartTitle>
                  <Space align="center">
                    <img src={learningChartIcon} alt="" />
                    <Text headerText lg w6>学習時間</Text>
                  </Space>
                  <Space>
                    <Style.ChartDatePicker invalidDate={invalidDate}>
                      <DatePicker.RangePicker
                        size="small"
                        allowClear={false}
                        placeholder={['', '']}
                        allowEmpty={[false, false]}
                        disabledDate={disabledDate}
                        bordered={false}
                        value={dates}
                        format={DATE_FORMAT}
                        defaultValue={[moment(currentTime.data).subtract(13, 'd'), moment(currentTime.data)]}
                        onCalendarChange={(value, valueString) => {
                          if (invalidDate) setInvalidDate(false);
                          if (value && value[0] && value[1] && value[1].diff(value[0], 'days') > 59) {
                            setDates([value[0], moment(valueString[0]).add(59, 'd')]);
                          } else {
                            setDates(value);
                          }
                        }}
                        style={{ width: 240 }}
                      />
                    </Style.ChartDatePicker>
                    <Button
                      size="small"
                      ghost
                      type="primary"
                      onClick={() => {
                        if (!dates[1]) {
                          setInvalidDate(true);
                        } else {
                          getStudentLearningTimes({
                            startDate: dates[0],
                            endDate: dates[1],
                          });
                        }
                      }}
                    >
                      &nbsp;適用&nbsp;
                    </Button>
                  </Space>
                </Style.ChartTitle>
                <Style.YAxisTitle>時間(分)</Style.YAxisTitle>
                {renderChart()}
              </Style.OverviewChart>
            </Style.OverviewCardContainer>
          </CardWrapper>
        </Col>
      </Row>
    </Style.StudentTopWapper>
  );
};

export default TopOverview;
