// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  STUDENT,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  confirmationStatus: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
  confirmationStudent: {
    errors: [],
  },
};

export type StudentAccountReducer = Readonly<typeof initialState>;

export default (state: StudentAccountReducer = initialState, action: any): StudentAccountReducer => {
  switch (action.type) {
  case SUCCESS(STUDENT.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      confirmationStatus: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS): {
    const { errors } = action;
    return {
      ...state,
      confirmationStudent: {
        ...state.confirmationStudent,
        errors,
      },
    };
  }

  case SUCCESS(STUDENT.ACCOUNT_ACTION.CONFIRM): {
    return {
      ...state,
      confirmationStudent: {
        ...state.confirmationStudent,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.ACCOUNT_ACTION.CONFIRM): {
    const { errors } = action;
    return {
      ...state,
      confirmationStudent: {
        ...state.confirmationStudent,
        errors,
      },
    };
  }

  default:
    return state;
  }
};
