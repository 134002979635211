// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CompanyCourseServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getCourseDetail(params) {
    return this.restClient.get(`/company_manage/courses/${params.id}`);
  }

  getGroupDetail(params) {
    const { courseId, groupId } = params;
    return this.restClient.get(`/company_manage/courses/${courseId}/groups/${groupId}`);
  }

  getAttendanceStatus(params) {
    return this.restClient.get(`/company_manage/courses/${params.id}/statistics/learning_statuses`);
  }

  getCourseStudents(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/company_manage/courses/${id}/users`, requestData);
  }

  getCourseChartData(params) {
    const { courseId, requestData } = params;
    return this.restClient.get(`/company_manage/courses/${courseId}/joining_statuses`, requestData);
  }

  getCourses() {
    return this.restClient.get('company_manage/license_grant/courses');
  }

  getTestScoreOverview(params) {
    return this.restClient.get(`/company_manage/courses/${params.id}/tests_statistics/overview`);
  }

  getCompanyTestScoreChart(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/company_manage/courses/${id}/tests_statistics/chart`, requestData);
  }

  getTestScoreStudents(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/company_manage/courses/${id}/tests_statistics/users_score`, requestData);
  }

  downloadCsvStudyStudent({courseId}) {
    return this.restClient.get(`/company_manage/courses/${courseId}/download_csv_learning_datas`,);
  }

  downloadCsvTestsStudent({courseId}) {
    return this.restClient.get(`/company_manage/courses/${courseId}/download_csv_tests/`);
  }
  
  setProgressNotification(params) {
    const { courseId, requestData } = params;
    return this.restClient.put(`/company_manage/courses/${courseId}/setting_schedule_progress_emails`, requestData);
  }
  
  getRecommendDate(params) {
    const { courseId } = params;
    return this.restClient.get(`/company_manage/courses/${courseId}/calculate_recommended_date`);
  }

  getCompanyProgressRateChart(id) {
    return this.restClient.get(`/company_manage/courses/${id}/statistics/chart`);
  }

  getCompanyUserProgressCourse(params) {
    const { courseId, requestData } = params
    return this.restClient.get(`/company_manage/courses/${courseId}/statistics/users_progress`, requestData);
  }
}
