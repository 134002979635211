// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
} from 'antd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import {
  updateTeacher as updateTeacherAction,
  createTeacher as createTeacherAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModifyTeacherModalProps {
  updateTeacher(params): void;
  createTeacher(params): void;
  modalData: {
    teacher: {
      id: number;
      name: string;
      description: string,
    },
  };
}

const ModifyTeacherModal: React.FC<IModifyTeacherModalProps> = ({
  updateTeacher,
  createTeacher,
  modalData,
}) => {

  const [modifyTeacherForm] = Form.useForm();
  const teacherId = modalData.teacher?.id;

  const teacherInitialFormValues = {
    name: modalData.teacher?.name,
    description: modalData.teacher?.description,
  };

  function handleSubmitForm(values) {
    const requestData = {
      teacher: {
        name: values.name?.trim(),
        description: (values.description || '').trim(),
      },
    };
    if (teacherId) {
      return updateTeacher({ requestData, id: teacherId });
    } else {
      return createTeacher(requestData);
    }
  }
  return (
    <Form
      form={modifyTeacherForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      name="modifyTeacherForm"
      {...modalData.teacher.id && { initialValues: teacherInitialFormValues }}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.TeacherFieldContent>
        <Form.Item
          label="講師"
          name="name"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { required: true, message: '講師は必須項目です。' },
            { whitespace: true, message: '講師は必須項目です。' },
            { max: 25, message: '講師が長すぎます。（最大は25桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="担当科目"
          name="description"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { max: 125, message: '担当科目が長すぎます。（最大は125桁です）' }
          ]}
        >
          <Input />
        </Form.Item>
      </Style.TeacherFieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({
  updateTeacher: (params) => dispatch(updateTeacherAction(params)),
  createTeacher: (params) => dispatch(createTeacherAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyTeacherModal);
