// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Tag } from 'antd'

export const AnswerAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const DownloadFileButton = styled.div`
  display: flex;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const AnswerStatus = styled(Tag)`
  display: flex;
  justify-content: center;
  width: 70px;
  color: #D29C09;
  font-size: 16px;
  background-color: transparent;
  border-color: #D29C09;

  ${(props) => props.isScored
    && css`
      background-color:  #D29C09;;
      color: white;
    `}
`;
