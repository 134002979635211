// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const ChapterFAQTitle = styled.div`
  display: flex;
  align-items: center;
  height: 118px;
`;

export const ChapterFAQFilter = styled.div`
  display: flex;
  align-items: center;

  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`;

export const TabWrapperContent = styled.div`
  padding-top: 48px;
`;

export const TableAction = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    width: 76px;
  }
`;
