// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CategoryServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getCategoryList() {
    return this.restClient.get('/admin_manage/categories');
  }

  updateCategory(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/categories/${id}`, requestData);
  }

  createCategory(params) {
    return this.restClient.post('/admin_manage/categories', params);
  }

  deleteCategory(params) {
    return this.restClient.delete(`/admin_manage/categories/${params.id}`);
  }
}
