// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Text } from 'src/components/styles';
import { Progress } from 'antd';

export const CourseContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const CourseContentTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const CollapseHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Fix onClick call API collapse header */
  margin: 0 -40px 0 -16px;
  padding: 0 40px 0 16px;
  width: calc(100% + 56px);
  height: 50px;

  @media (max-width: 428px) {
    padding: 16px;
    margin: -12px -40px -12px -16px;
    min-height: 50px;
    height: unset;
  }
`;

export const CollapseIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  display: flex;
  transform: translate(0, -50%);
`;

export const UnitItemContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
  cursor: pointer;

  @media (max-width: 428px) {
    justify-content:space-between;
  }
`;

export const UnitItemContentMobile = styled.div`
  position: relative;
  display: none;
  padding: 0 40px 0 16px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
  cursor: pointer;

  @media (max-width: 428px) {
    display: flex;
    align-items: center;
    justify-content:space-between;
  }
`;

export const UnitItemContentDesktop = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
  cursor: pointer;

  @media (max-width: 428px) {
    display: none;
    justify-content:space-between;
  }
`;

export const UnitItemName = styled.div`
  display: flex;
  align-items: center;
`;

export const UnitAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > button {
    min-width: 76px;
  }
`;

export const ItemTypeIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 26px;
  width: 24px;
  height: 24px;
`;

export const CourseContent = styled.div`
  display: block;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  background-color: #C08D01;
  color: white;

  @media (max-width: 428px) {
    padding: 16px;
  }
`;

export const DoneUnitIcon = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  justify-content: flex-end;
  width: 10%;
`;

export const NoUnitItemData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
`;

export const NoChapterData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: #FAFAFA;
`;

export const CourseContentMobile = styled.div`
  display:none;
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;

  @media (max-width: 428px) {
    display: block;

    .ant-collapse-header {
      height: unset;
    }
  }
`;

export const TextTitle = styled(Text)`
  display:block;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const ProgressDesktop = styled(Progress)`
  display:block;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const ProgressMobile = styled(Progress)`
  display:none;

  @media (max-width: 428px) {
    display: block;
  }
`;

export const TextHeader = styled(Text)`
  @media (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const GroupName = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`;

export const ChapterName = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`;

export const TextUnit = styled(Text)`
  @media (max-width: 428px) {
    font-size: 14px;
    font-weight: 700;
  }
`;


export const TextDuration = styled(Text)`
  @media (max-width: 428px) {
    font-size: 13px;
  }
`;

export const TextHeaderTable = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`;
