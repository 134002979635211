// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const CreateFAQContent = styled.div`
  margin-top: 24px;
  border: 1px solid #EEEEEE;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  padding: 12px 24px;

  & .ant-form-item-label {
    padding: 0;
  }
`;

export const CreateFAQAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;

  ${(props) => props.hasErrorText
    && css`
      justify-content: space-between;
    `}
`;

export const AddUnitButton = styled.div`
  position: absolute;
  transform: translate(50%, 0);

  &:hover {
    display: block;
  }

  & > button {
    display: none;
    font-size: ${(props) => props.theme.fontSize.md};
  }

  ${(props) => props.position === 'top'
    && css`
      top: -32px;
      right: 50%;
    `}

  ${(props) => props.position === 'bottom'
    && css`
      bottom: -32px;
      right: 50%;
    `}

  ${(props) => props.show
    && css`
      display: block;
    `}
`;

export const ItemContainer = styled.div`
  position: relative;
  margin-top: 50px;

  &:hover {
    & button {
      display: block;
    }

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  &:first-child {
    margin-top: 0;
  }
`;

export const DeleteButton = styled.div`
  position: absolute;
  right: 24px;
  top: 10px;
  z-index: 1;

  & > button {
    display: none;
  }

  ${(props) => props.isHidden
    && css`
      display: none;
    `}
`;

export const FAQContents = styled.div`
  margin-top: 40px;
`;
