// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const OverviewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin: 24px -12px 0;
`;

export const OverviewContent = styled.div`
  padding: 12px;
  width: calc(100% / 5);
`;

export const OverviewCardContainer = styled.div`
  background-color: white;
`;

export const OverviewCardTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  height: 120px;
`;

export const OverviewCardBottom = styled.div`
  position: relative;
  display: flex;
  height: 80px;
`;

export const BottomCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
`;

export const BottomCardLine = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  width: 1px;
  height: 60px;
  background-color: ${(props) => props.theme.colors.secondaryBorder};
  transform: translate(-50%, -50%);
`;
