// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_COMPANY,
  REQUEST,
  SUCCESS,
  FAILURE,
  MODAL_ACTION,
} from 'src/redux/constant';

const initialState = {
  confirmationStatus: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
  confirmationCompany: {
    errors: [],
  },
  students: {
    data: {
      studentList: [],
      customDisplayField: {},
    },
    meta: {},
    load: false,
    errors: [],
  },
  invitationStudents: {
    errors: [],
  },
  invitationCompany: {
    errors: [],
  },
  invitationList: {
    data: [] as {
      id: string;
      email: string;
      registerStatus: string;
    }[],
    bulkInvitationProcess: {
      isCancel: false,
      isResend: false,
    },
    meta: {},
    load: false,
    errors: [],
  },
  dataAddCsv: {
    statusAddCsv: "",
    courseIdSelected: null,
    error: [],
  }
};

export type CompanyAccountReducer = Readonly<typeof initialState>;

export default (state: CompanyAccountReducer = initialState, action: any): CompanyAccountReducer => {
  switch (action.type) {
  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      confirmationStatus: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS): {
    const { errors } = action;
    return {
      ...state,
      confirmationStatus: {
        ...state.confirmationStatus,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM): {
    return {
      ...state,
      confirmationCompany: {
        ...state.confirmationCompany,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM): {
    const { errors } = action;
    return {
      ...state,
      confirmationCompany: {
        ...state.confirmationCompany,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM_COMPANY): {
    return {
      ...state,
      confirmationCompany: {
        ...state.confirmationCompany,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM_COMPANY): {
    const { errors } = action;
    return {
      ...state,
      confirmationCompany: {
        ...state.confirmationCompany,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS): {
    return {
      ...state,
      students: {
        ...state.students,
        load: true,
        errors: [],
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      students: {
        data: {
          studentList: data.users,
          customDisplayField: data.company.customDisplayField,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS): {
    const { errors } = action;
    return {
      ...state,
      students: {
        ...state.students,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CLEAR_STUDENTS): {
    return {
      ...state,
      students: {
        data: {
          studentList: [],
          customDisplayField: {},
        },
        meta: {},
        load: false,
        errors: [],
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE): {
    const { data, meta } = action.payload;
    return {
      ...state,
      students: {
        data: {
          studentList: data.users,
          customDisplayField: data.company.customDisplayField,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE): {
    const { errors } = action;
    return {
      ...state,
      students: {
        ...state.students,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS): {
    return {
      ...state,
      invitationStudents: {
        ...state.invitationStudents,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS): {
    const { errors } = action;
    return {
      ...state,
      invitationStudents: {
        ...state.invitationStudents,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY): {
    return {
      ...state,
      invitationCompany: {
        ...state.invitationCompany,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY): {
    const { errors } = action;
    return {
      ...state,
      invitationCompany: {
        ...state.invitationCompany,
        errors,
      },
    };
  }


  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_INVITATION_LIST): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.GET_INVITATION_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      invitationList: {
        data: data.users,
        bulkInvitationProcess: data.bulkInvitationProcess,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.GET_INVITATION_LIST): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.RESEND_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.RESEND_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => user.email === action.payload.email
        ? { ...user, registerStatus: 'invited', confirmationSentAt: action.payload.confirmationSentAt }
        : user),
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.RESEND_INVITATION): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CANCEL_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.CANCEL_INVITATION): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => user.email === action.payload.user.email
        ? { ...user, registerStatus: 'canceled' }
        : user),
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.CANCEL_INVITATION): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV): {
    return {
      ...state,
      dataAddCsv: {
        ...state.dataAddCsv,
        statusAddCsv: "success",
        courseIdSelected: action.payload.courseId,
      }
    }
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV): {
    return {
      ...state,
      dataAddCsv: {
        ...state.dataAddCsv,
        statusAddCsv: "error",
        error: action.errors
      }
    }
  }
  case REQUEST(MODAL_ACTION.CLOSE): {
    return {
      ...state,
      dataAddCsv: {
        ...state.dataAddCsv,
        statusAddCsv: "",
        courseIdSelected: null,
      }
    }
  }


  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS): {
    const { userIds } = action.payload;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => {
          const isResendUser = userIds.find((userId) => userId === user.id);
          return isResendUser ? { ...user, isResendInvitationProcessing: true } : user;
        }),
        bulkInvitationProcess: {
          ...state.invitationList.bulkInvitationProcess,
          isResend: true,
        },
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS): {
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS): {
    const { userIds } = action.payload;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        data: state.invitationList.data.map((user) => {
          const isCancelUser = userIds.find((userId) => userId === user.id);
          return isCancelUser ? { ...user, isCancelInvitationProcessing: true } : user;
        }),
        bulkInvitationProcess: {
          ...state.invitationList.bulkInvitationProcess,
          isCancel: true,
        },
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS): {
    const { errors } = action;
    return {
      ...state,
      invitationList: {
        ...state.invitationList,
        load: false,
        errors,
      },
    };
  }

  default:
    return state;
  }
};
