// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from '../../constant';
import moment from 'moment';

export function getQuestionList(params) {
  const newParams = {
    page: params.page || '',
    categoryIdEq: params.category || '',
    contentCont: (params.name || '').trim(),
    statusEq: params.status || '',
    createdAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    createdAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.QUESTION_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getCourseQuestionDetail(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.QUESTION_ACTION.GET_DETAIL),
    payload,
  };
}

export function selectQuestionStatus(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.QUESTION_ACTION.SELECT_QUESTION_STATUS),
    payload,
  };
}
