// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import CourseOverview from '../components/CourseOverview';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

interface ICreateCourseProps extends DispatchProps {
  courseDetail: {};
}

const CreateCourse: React.FC<ICreateCourseProps> = ({
  courseDetail,
}) => {
  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CreateCourse);
  }, []);

  return (
    <CourseOverview courseDetail={courseDetail} />
  );
};

const mapStateToProps = (state) => ({
  courseDetail: state.courseReducer.courseDetail,
});

const mapDispatchToProps = (dispatch) => ({});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CreateCourse);
