// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import reducers from './redux/reducers';
import rootSaga from './redux/sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = process.env.REACT_APP_BASE_ENVIRONMENT === 'development'
  ? [sagaMiddleware, logger] : [sagaMiddleware];
export default function configureStore() {
  const store = createStore(reducers, applyMiddleware(...middlewares));
  sagaMiddleware.run(rootSaga);
  return store;
}
