// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const NotificationDropdownContainer = styled.div`
  width: 360px;
  border-radius: ${(props) => props.theme.radius.md};
  box-shadow: ${(props) => props.theme.shadow.md};
  background-color: white;
  overflow: hidden;

  ${(props) => props.emptyNotify
    && css`
      height: 85px;
    `
  }
`;

export const NotificationDropdownContent = styled.div`
  height: 400px;
  overflow-y: auto;
`;

export const NotificationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 12px;
  height: 80px;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  cursor: pointer;

  &:hover {
    background-color: #fff8e7;
  }

  ${(props) => props.unread
    && css`
      background-color: white;
    `
  }
`;

export const NotificationShowMore = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  height: 38px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const ProfileAvatar = styled.div`
  width: 40px;
  height: 40px;

  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
