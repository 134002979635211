// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty, isNumber } from 'lodash/lang';
import { PlusCircleOutlined } from '@ant-design/icons';

import history from 'src/utils/history';
import { LoginStorage } from 'src/utils/localStorge';
import { getFilterOption } from 'src/utils/common';

import {
  MainContainer,
  MainContent,
} from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles/Texts';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getTagList as getTagListAction,
  getCategoryList as getCategoryListAction,
  createFAQ as createFAQAction,
  clearFAQErrors as clearFAQErrorsAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface ICreateFAQPageProps extends StateProps, DispatchProps {
  getFAQDetail(params): void;
  getCategoryList(): void;
  getTagList(): void;
  clearFAQErrors(): void;
  createFAQ(params): void;
  updateFAQ(params): void;
  isDisableRequest: boolean;
  match: {
    params: {
      id?: string;
    };
  };
  tagList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  categoryList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  faqDetail: {
    data: {
      creator: {
        jituName: string;
      }
    };
    errors: {
      message: string;
      field: string;
    }[];
  };
}

const CreateFAQPage: React.FC<ICreateFAQPageProps> = ({
  getTagList,
  getCategoryList,
  createFAQ,
  clearFAQErrors,
  match,
  categoryList,
  faqDetail,
  tagList,
  isDisableRequest
}) => {
  const { errors } = faqDetail;
  const faqId = match.params.id;
  const [faqForm] = Form.useForm();
  const userStorage = LoginStorage.getData();

  useEffect(() => {
    getTagList();
    getCategoryList();
  }, [faqId]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      errors.forEach((error) => {
        faqForm.setFields([ { name: error.field, errors: [error.message] } ]);
      });
    }
  }, [errors]);

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CreateFAQ);

    return () => clearFAQErrors();
  }, []);

  const faqInitialFormValues = {
    creator: userStorage?.user?.jituName,
    faqContents: [{}],
  };

  function handleSubmitForm(values) {
    const faqContents = values.faqContents.map((faq, index) => ({
      question: faq.question?.trim(),
      answer: faq.answer?.trim(),
      position: index + 1,
    }));

    const requestData = {
      faq: {
        name: values.name.trim(),
        tagIds: isEmpty(values.tagIds) ? [] : values.tagIds,
        ...(isNumber(values.categoryId) && { categoryId: values.categoryId }),
        faqContents,
      },
    };
    createFAQ(requestData);
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  function renderTagsOptions() {
    if (isEmpty(tagList.data)) return null;
    return tagList.data.map((tag) => (
      <Select.Option key={`tag-${tag.id}`} value={tag.id}>{tag.name}</Select.Option>
    ));
  }

  function renderQuestionFields(field, remove) {
    return (
      <Style.ItemContainer key={field.key}>
        <Style.CreateFAQContent>
          <Style.DeleteButton isHidden={faqForm.getFieldValue('faqContents').length === 1}>
            <Button
              danger
              size="small"
              onClick={() => remove(field.name)}
            >
              &nbsp;削除&nbsp;
            </Button>
          </Style.DeleteButton>
          <Form.Item
            {...field}
            label="質問"
            name={[field.name, 'question']}
            fieldKey={[field.fieldKey, 'question'] as any}
            labelAlign="left"
            labelCol={{ span: 24 }}
            colon={false}
            validateFirst
            rules={[
              { required: true, message: '質問は必須項目です。' },
              { whitespace: true, message: '質問は必須項目です。' },
              { max: 1000, message: '質問が長すぎます。（最大は1000桁です）' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const faqContents = getFieldValue('faqContents');
                  const questionsData = faqContents.map((item) => item.question);
                  const isDuplicate = questionsData.filter((question) => question === value).length > 1;
                  if (isDuplicate) {
                    return Promise.reject('入力された質問がすでに存在しております。');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 1, maxRows: 5 }} />
          </Form.Item>
          <Form.Item
            {...field}
            label="回答"
            name={[field.name, 'answer']}
            fieldKey={[field.fieldKey, 'answer'] as any}
            labelAlign="left"
            labelCol={{ span: 24 }}
            colon={false}
            validateFirst
            rules={[
              { required: true, message: '回答は必須項目です。' },
              { whitespace: true, message: '回答は必須項目です。' },
              { max: 1000, message: '回答が長すぎます。（最大は1000桁です）' },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 8 }} />
          </Form.Item>
        </Style.CreateFAQContent>
        <Style.AddUnitButton position="top" className="add-question">
          <Button
            type="link"
            size="small"
            icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
            onClick={() => {
              const faqContentsData = faqForm.getFieldValue('faqContents');
              faqContentsData.splice(field.key, 0, {});
              faqForm.setFieldsValue({ faqContents: faqContentsData });
            }}
            style={{ padding: 0 }}
          >
            新規追加
          </Button>
        </Style.AddUnitButton>
        <Style.AddUnitButton position="bottom" className="add-question">
          <Button
            type="link"
            size="small"
            icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
            onClick={() => {
              const faqContentsData = faqForm.getFieldValue('faqContents');
              faqContentsData.splice(field.key + 1, 0, {});
              faqForm.setFieldsValue({ faqContents: faqContentsData });
            }}
            style={{ padding: 0 }}
          >
            新規追加
          </Button>
        </Style.AddUnitButton>
      </Style.ItemContainer>
    );
  }

  return (
    <MainContainer>
      <MainContent fullView>
        <Form
          form={faqForm}
          initialValues={faqInitialFormValues}
          name="modifyFAQForm"
          onFinish={(values) => handleSubmitForm(values)}
          onFinishFailed={ ({ errorFields }) => {
            faqForm.scrollToField(errorFields[0].name);
            window.scrollBy(0, -40);
          }}
          scrollToFirstError={true}
        >
          <Space align="center" size={32}>
            <Text headerText xxxl w6>FAQ新規作成</Text>
          </Space>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item
                label="項目名"
                name="name"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
                rules={[
                  { required: true, message: '項目名は必須項目です。' },
                  { whitespace: true, message: '項目名は必須項目です。' },
                  { max: 100, message: '項目名が長すぎます。（最大は100桁です）' },
                ]}
                style={{ marginTop: 24 }}
              >
                <Input className="transparent-input" />
              </Form.Item>
              <Form.Item
                name="tagIds"
                label="Tags"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  className="transparent-input"
                  filterOption={getFilterOption}
                >
                  {renderTagsOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="categoryId"
                label="講座タイプ"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
                style={{ marginTop: 24 }}
              >
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="選択してください"
                  notFoundContent="データが存在しません。"
                  className="transparent-input"
                  filterOption={getFilterOption}
                  style={{ width: '35%' }}
                >
                  {renderCategoryOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                name="creator"
                label="作成者"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
              >
                <Input style={{ color: '#343A40' }} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Style.FAQContents>
            <Form.List name="faqContents">
              {(fields, { remove }) => (
                <div>
                  {fields.map((field) => renderQuestionFields(field, remove))}
                </div>
              )}
            </Form.List>
          </Style.FAQContents>
          <Style.CreateFAQAction>
            <Space align="center" size="middle">
              <Button
                type="primary"
                htmlType="button"
                className="btn-secondary"
                onClick={() => history.goBack()}
              >
                キャンセル
              </Button>
              <Button htmlType="submit" type="primary" disabled={isDisableRequest}>
                保存する
              </Button>
            </Space>
          </Style.CreateFAQAction>
        </Form>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  const { faqDetail } = state.faqReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    tagList,
    categoryList,
    faqDetail,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTagList: () => dispatch(getTagListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  createFAQ: (params) => dispatch(createFAQAction(params)),
  clearFAQErrors: () => dispatch(clearFAQErrorsAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CreateFAQPage);

