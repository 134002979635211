// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { findIndex } from 'lodash/array';
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  categoryList: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
};

export type CategoryReducer = Readonly<typeof initialState>;

export default (state: CategoryReducer = initialState, action: any): CategoryReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST): {
    return {
      ...state,
      categoryList: {
        ...state.categoryList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      categoryList: {
        data: data.categories,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      categoryList: {
        ...state.categoryList,
        load: false,
        errors,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY): {
    const { data } = action.payload;
    const newCategoryList = state.categoryList.data;
    const updatedCategoryIndex = findIndex(state.categoryList.data, { id: data.id });
    newCategoryList.splice(updatedCategoryIndex, 1, data );
    return {
      ...state,
      categoryList: {
        ...state.categoryList,
        data: newCategoryList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.UPDATE_CATEGORY): {
    const { errors } = action;
    return {
      ...state,
      categoryList: {
        ...state.categoryList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY): {
    const { data } = action.payload;
    const newCategoryList = state.categoryList.data;
    const  removeCategoryIndex = findIndex(state.categoryList.data, { id: data.id });
    newCategoryList.splice(removeCategoryIndex, 1);
    return {
      ...state,
      categoryList: {
        ...state.categoryList,
        data: newCategoryList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.CATEGORY_ACTION.DELETE_CATEGORY): {
    const { errors } = action;
    return {
      ...state,
      categoryList: {
        ...state.categoryList,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
