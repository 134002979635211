// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';

export const TopSidebar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 22px;
  height: 50px;

  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;

  ${(props) => props.isHideBtnMenu
    && css`
      @media only screen and (max-width: 428px) {
          display: none;
        }
      }
    `}
`;

export const TestingContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  width: 0;
  max-height: calc(100vh - 106px);
  overflow: auto;

  ${(props) => props.isShowSidebar
    && css`
      width: 100%;
    `}
`;

export const QuestionItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding-left: 70px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
  cursor: pointer;

  @media only screen and (max-width: 428px) {
    padding-left: 16px;
    font-weight: 300;
  }

  ${(props) => props.active
    && css`
      background-color: #FFF8E7;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 50px;
        background-color: ${props.theme.colors.primary};
      }
    `}
`;

export const TitleCourse = styled(Text)`
  @media only screen and (max-width: 428px) {
    width: 100% !important;
    font-weight: 700;
  }
`;
