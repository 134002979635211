// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const FullLayoutContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;

  @media (max-height: 780px) and (max-width: 428px) {
    min-height: 812px;
  }
`;
