// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class ProfileServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getProfileDetail(params) {
    return this.restClient.get(`/admin_manage/users/${params.id}`);
  }

  updateUserRole(params) {
    return this.restClient.put('/admin_manage/users_roles', params);
  }

  updateUserEmail(params) {
    const { id, requestData } = params;
    return this.restClient.put(`/admin_manage/users/${id}/emails`, requestData);
  }

  updateUserInfo(params) {
    const { id, requestData } = params;
    return this.restClient.put(`/admin_manage/users/${id}/infos`, requestData);
  }

  getJoinedCourses(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/admin_manage/users/${id}/courses`, requestData);
  }

  updateCourseDuration(params) {
    const { requestData, id } = params;
    return this.restClient.patch(`/admin_manage/courses/${id}/courses_users`, requestData);
  }


  getListTestStudent(params) {
    const { user_id, requestData } = params;
    return this.restClient.get(`/admin_manage/users/${user_id}/tests_users`, requestData)
  }

  resetHistoryTest(params) {
    const { requestData } = params;
    return this.restClient.post(`/admin_manage/reset_test_histories`, requestData,)
  }

  getListCourseCompany(params) {
    const { requestData, userId } = params;
    return this.restClient.get(`/admin_manage/users/${userId}/courses_companies`, requestData,)
  }

  updateCourseManager(params) {
    const { courseId, userId } = params;
    return this.restClient.put(`/admin_manage/users/${userId}/courses_companies/${courseId}`,{})
  }

  unlockUser(id) {
    return this.restClient.patch(`admin_manage/users/${id}/unlock`)
  }
}
