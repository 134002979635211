// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const TEST_ACTION = {
  GET_LIST: 'ADMIN_SERVICE/TEST_ACTION/GET_LIST',
  GET_DETAIL: 'ADMIN_SERVICE/TEST_ACTION/GET_DETAIL',
  CREATE_TEST: 'ADMIN_SERVICE/TEST_ACTION/CREATE_TEST',
  UPDATE_TEST: 'ADMIN_SERVICE/TEST_ACTION/UPDATE_TEST',
  GET_QUESTIONS: 'ADMIN_SERVICE/TEST_ACTION/GET_QUESTIONS',
  MOVE_QUESTION: 'ADMIN_SERVICE/TEST_ACTION/MOVE_QUESTION',
  GET_QUESTION_DETAIL: 'ADMIN_SERVICE/TEST_ACTION/GET_QUESTION_DETAIL',
  CREATE_QUESTION: 'ADMIN_SERVICE/TEST_ACTION/CREATE_QUESTION',
  UPDATE_QUESTION: 'ADMIN_SERVICE/TEST_ACTION/UPDATE_QUESTION',
  DELETE_QUESTION: 'ADMIN_SERVICE/TEST_ACTION/DELETE_QUESTION',
  GET_FILE_DOWNLOAD_URL: 'ADMIN_SERVICE/TEST_ACTION/GET_FILE_DOWNLOAD_URL',
  JOIN_OVERVIEW_TEST: 'ADMIN_SERVICE/TEST_ACTION/JOIN_OVERVIEW_TEST',
  CLEAR_TEST_DATA: 'ADMIN_SERVICE/TEST_ACTION/CLEAR_TEST_DATA',
  COPY_TEST: 'ADMIN_SERVICE/TEST_ACTION/COPY_TEST',
  DELETE_TEST: 'ADMIN_SERVICE/TEST_ACTION/DELETE_TEST',
};
