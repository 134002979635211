// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from './restClient';

export default class MasterDataServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getCareerList() {
    return this.restClient.get('/careers');
  }

  getProvinceList() {
    return this.restClient.get('/provinces');
  }

  getCurrentTime(params) {
    const requestData = params?.requestData || null ;
    if(requestData) {  
      return this.restClient.get('/current_time', requestData);
    }
    return this.restClient.get('/current_time'); 
  }

  getCheckVideo(params) {
    return this.restClient.get('/videos', params);
  }

  getCurrentUserInfo() {
    return this.restClient.get('/users/basic_info');
  }
}
