// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { Button } from 'antd';
import React from 'react';
import * as Style from './styles';

import { connect } from 'react-redux';

import {
  closeModal as closeModalAction,
  deleteTest as deleteTestAction
} from 'src/redux/actions';
import { Text } from 'src/components/styles';
import { idText } from 'typescript';

interface IConfirmModalProps extends DispatchProps, StateToProps   {
  isConfirm: boolean;
  closeModal(): void,
  deleteTest(param): void,
  testId: number,
  isDisableRequest:boolean
}

const ConfirmModalDeleteTest: React.FC<IConfirmModalProps> = ({
  closeModal,
  deleteTest,
  testId,
  isDisableRequest
}) => {

  return (
    <>
      <Style.ConfirmModalContainer>
        <Text fontSize="16px" w6>テストの削除は取り消しができません。</Text>
        <Text fontSize="16px" w6>削除した場合、該当の回答結果も削除されます。</Text>
        <Text fontSize="16px" w6>このテストを削除してもよろしいですか？</Text>
      </Style.ConfirmModalContainer>
      <Style.ConfirmResetFooter>
        <Button
          onClick={() => {
            closeModal();
          }}
          type="primary"
          className="btn-secondary"
        >
          キャンセル
        </Button>
        <Button
          onClick={() => {
            deleteTest({ id: testId })
          }}
          type="primary"
          disabled={isDisableRequest}
        >
          削除する
        </Button>

      </Style.ConfirmResetFooter>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
  deleteTest: (params) => dispatch(deleteTestAction(params))
});
const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModalDeleteTest);
