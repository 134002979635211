// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
} from 'antd';
import isEmpty from 'lodash/isEmpty'
import {
  MainContainer,
  MainContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles';

import { PAGE_TITLE, ForgotPasswordTitle } from 'src/constants/pageTitle';

import {
  forgotPassword as  forgotPasswordAction,
  clearError as clearErrorAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IForgotPasswordPageProps extends StateProps, DispatchProps {
  forgotPassword(params): void;
  clearError(): void;
  role: string;
  errors: {
    code?: number,
    message: string
  }[]
}

const ForgotPasswordPage: React.FC<IForgotPasswordPageProps> = ({
  forgotPassword,
  clearError,
  role,
  errors
}) => {
  const [forgotPasswordForm] = Form.useForm();

  useEffect(() => {
    document.title = PAGE_TITLE(ForgotPasswordTitle);
  }, []);

  function handleSubmitForm(values) {
    forgotPassword({
      email: values.email,
      role,
    });
  }

  function handleBlurInput() {
    clearError();
  }

  function renderForgotPasswordForm() {
    return (
      <Form
        form={forgotPasswordForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="forgotPasswordForm"
        onFinish={(values) => handleSubmitForm(values)}
      >
        <Text style={{ marginBottom: 24 }}>
          メールを入力すると、 リセット方法に関する説明の案内が届きます
        </Text>
        {!isEmpty(errors) && errors[0]?.code === 1314 && (
          <Style.TextError sm error>
            アカウントがロックされています。解除の申請をする場合は
            <Style.Link
              href="https://share.hsforms.com/118qwvfYSTH6mTxLq8hFoJg2mtef"
              target="_blank"
            >
              こちら
            </Style.Link>
            からお問い合わせください。
          </Style.TextError>
        )}
        <Form.Item
          label="メールアドレス"
          name="email"
          labelAlign="left"
          colon={false}
          normalize={(value) => value.trim()}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            { required: true, whitespace: true, message: 'メールアドレスは必須項目です。' },
            { type: 'email', message: 'メールアドレスのフォーマットが正しくありません。' },
          ]}
        >
          <Input onBlur={handleBlurInput} />
        </Form.Item>
        <Style.GroupAction>
          <Button
            type="primary"
            htmlType="submit"
          >
            &nbsp;送信&nbsp;
          </Button>
        </Style.GroupAction>
      </Form>
    );
  }

  return (
    <MainContainer>
      <MainContent formView>
        <MainWrapper title="パスワードを忘れた場合" style={{ marginTop: 24 }}>
          <Style.ForgotPasswordContainer>
            {renderForgotPasswordForm()}
          </Style.ForgotPasswordContainer>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  errors: state.authReducer.errorsForgot,
});

const mapDispatchToProps = (dispatch) => ({
  forgotPassword: (params) => dispatch(forgotPasswordAction(params)),
  clearError: () => dispatch(clearErrorAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
