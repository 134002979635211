// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { ReactNode } from 'react';

import * as Style from './styles';

interface IMainContainerProps {
  children: ReactNode;
  isStudent?: boolean;
  isResponsive?: boolean;
  isFlexColumn?: boolean;
  isTabs?: boolean;
  isCourseDetailStudent?: boolean;
  backgroundHeight?: number;
  direction?: string;
}

const MainContainer: React.FC<IMainContainerProps> = ({
  children,
  isStudent,
  isTabs,
  isResponsive,
  isFlexColumn,
  isCourseDetailStudent,
  backgroundHeight,
  direction,
}) => (
  <Style.MainContainer isFlexColumn={isFlexColumn} direction={direction}>
    <Style.MainBackground
      isStudent={isStudent}
      isTabs={isTabs}
      isCourseDetailStudent={isCourseDetailStudent}
      isResponsive={isResponsive}
      backgroundHeight={backgroundHeight}
    />
    {children}
  </Style.MainContainer>
);

export default MainContainer;
