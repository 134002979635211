//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Checkbox, Space } from 'antd';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import differenceBy from 'lodash/differenceBy';
import intersectionBy from 'lodash/intersectionBy';

import { formatDateTime } from 'src/utils/dateTime';

import { FilterSidebar } from 'src/components/layouts/Sidebar';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import { Text } from 'src/components/styles';

import {
  getCompanyInvitationList as getCompanyInvitationListAction,
  companyResendInvitations as companyResendInvitationsAction,
  companyCancelInvitations as companyCancelInvitationsAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
  companyBulkCancelInvitations as companyBulkCancelInvitationsAction,
  companyBulkResendInvitations as companyBulkResendInvitationsAction,
} from 'src/redux/actions';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT, REGISTER_STATUS, ACCOUNT_ROLES } from 'src/constants/common';

import * as Style from './styles';

interface IRegistrationStatusProps extends StateProps, DispatchProps {
  getCompanyInvitationList(params): void;
  companyResendInvitations(params): void;
  companyCancelInvitations(params): void;
  showModal(params): void;
  closeModal(): void;
  bulkResendInvitations(params): void;
  bulkCancelInvitations(params): void;
  invitationList: {
    data: {
      id: number;
      confirmationSentAt: string;
      email: string;
      registerStatus: string;
      roles: {};
      isResendInvitationProcessing: boolean,
      isCancelInvitationProcessing: boolean,
      company: {
        id: number;
        contractName: string;
      }[];
    }[];
    bulkInvitationProcess: {
      isResend: boolean,
      isCancel: boolean,
    }
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: string;
  };
  companyContracts: {
    data: {
      id: number;
      contractName: string;
    }[];
  };
}

const RegistrationStatus: React.FC<IRegistrationStatusProps> = ({
  getCompanyInvitationList,
  companyResendInvitations,
  companyCancelInvitations,
  invitationList,
  showModal,
  closeModal,
  bulkResendInvitations,
  bulkCancelInvitations,
}) => {
  const { data, meta , bulkInvitationProcess } = invitationList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [usersCancel, setUsersCancel] = useState<any>([]);
  const [usersResend, setUsersResend] = useState<any>([]);
  const idsUserSelected = [...usersResend, ...usersCancel].map((row) => row.id);
  const tableDataKeys = data.map((row) => row.id);
  const tableData = data.map((row: any) => ({
    ...row,
    key: row.id,
    itemType: ACCOUNT_ROLES[row.roles],
    registerStatus: REGISTER_STATUS[row.registerStatus],
    confirmationSentAt: formatDateTime(row.confirmationSentAt, DATE_FORMAT),
  }));
  const USER_SELECTED_MAX = 9999;
  const isMaxSelected = uniqBy([...usersResend, ...usersCancel, ...tableData], 'key').length > USER_SELECTED_MAX;

  useEffect(() => {
    getCompanyInvitationList({ page: 1 });
  }, []);

  useEffect(() => {
    const check = checkArrayInArray([...tableDataKeys], [...idsUserSelected]);
    const checkItem = checkArrayInArray([...tableDataKeys], [...idsUserSelected], true);
    setCheckAll(
      check && !isEmpty(data) ? true : false,
    );
    setIndeterminate(check ? false : checkItem)
  }, [data, idsUserSelected]);

  useEffect(() => {
    const checkItem = checkArrayInArray([...tableDataKeys], [...idsUserSelected], true);

    if (checkItem) {
      const newTableData = [...usersResend, ...usersCancel].map((user) => {
        const userFiltered = tableData.find((record) => record.id === user.id);
        return userFiltered ? userFiltered : user;
      });
      const { resend, cancel } = checkResendOrCancel(newTableData);
      setUsersResend(resend || []);
      setUsersCancel(cancel || []);
    }
  }, [data]);

  const checkArrayInArray = (arr1: any[], arr2: any[], isItem: boolean = false) => {
    let count = 0;
    arr1.forEach((arrItem1) => {
      arr2.forEach((arrItem2, index2) => {
        if (arrItem1 === arrItem2) {
          arr2.splice(index2, 1);
          count++;
        }
      });
    });
    if (isItem) {
      return count > 0;
    }
    return count === arr1.length ? true : false;
  };

  const checkResendOrCancel = (rows) => groupBy(rows, (row) =>
  (REGISTER_STATUS.canceled === row.registerStatus || REGISTER_STATUS.expired === row.registerStatus) ? 'resend' : 'cancel');

  const onCheckAllChange = (e) => {
    if (isMaxSelected) {
      return null;
    } else {
      setCheckAll(e.target.checked);
      setIndeterminate(false);
      const { resend, cancel } = checkResendOrCancel(tableData);

      if (resend) {
        setUsersResend(e.target.checked
          ? uniqBy([...usersResend, ...resend], 'key')
          : differenceBy(usersResend, resend, 'key'));
      }

      if (cancel) {
        setUsersCancel(e.target.checked
          ? uniqBy([...usersCancel, ...cancel], 'key')
          : differenceBy(usersCancel, cancel, 'key'));
      }
    }
  };

  const onChangeSelectRow = (keys, selectedRows) => {
    const selectedRowKeys = keys.map((key) => ({ id: key }));
    const usersFiltered = [...selectedRows].filter(Boolean);
    const users = intersectionBy([...usersCancel, ...usersResend, ...usersFiltered], selectedRowKeys, 'id');
    const check = checkArrayInArray([...tableDataKeys], [...keys]);
    const checkItem = checkArrayInArray([...tableDataKeys], [...keys], true);

    if (keys.length < 0) {
      return null;
    } else {
      const { resend, cancel } = checkResendOrCancel(users);
      setIndeterminate(!check && checkItem);
      setCheckAll(check);
      setUsersResend(resend || []);
      setUsersCancel(cancel || []);
    }
  }

  const handleBulkResend = () => {
    const modalProps = {
      isConfirm: false,
      spaceEvenly: true,
      modalData: {
        title: '一括再送',
        message: `一括再送は取り消しができません。
        選択した対象者に招待を再送してもよろしいですか？`,
        okText: '一括再送',
        cancelText: 'キャンセル',
      },
      width: '500px',
      modalAction: {
        confirm: () => {
          const userIds = usersResend.map((user) => user.id);
          bulkResendInvitations({ userIds });
          setUsersResend([]);
          closeModal();
        },
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  const handleBulkCancel = () => {
    const modalProps = {
      isConfirm: false,
      spaceEvenly: true,
      modalData: {
        title: '一括キャンセル',
        message: `一括キャンセルは取り消しができません。
        選択した対象者の招待をキャンセルしてもよろしいですか？`,
        okText: '一括キャンセル',
        cancelText: 'キャンセル',
      },
      width: '500px',
      modalAction: {
        confirm: () => {
          const userIds = usersCancel.map((user) => user.id);
          bulkCancelInvitations({ userIds });
          setUsersCancel([]);
          closeModal();
        },
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  };

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '招待日',
    },
    {
      type: 'select',
      title: '種別',
      fieldParams: 'type',
      options: [
        { id: 'student', name: '受講者' },
        { id: 'admin_company', name: '企業管理者' },
        { id: 'admin_service', name: 'サービス管理者' },
      ],
    },
    {
      type: 'textInput',
      fieldParams: 'email',
      title: 'メールアドレス',
    },
    {
      type: 'multiSelect',
      title: 'ステータス',
      fieldParams: 'actualRegisterStatus',
      options: [
        { id: 'invited', name: '招待中' },
        { id: 'canceled', name: 'キャンセル' },
        { id: 'expired', name: '期限切れ' },
      ],
    },
  ];

  const columns = [
    {
      title: '種別',
      dataIndex: 'itemType',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.itemType}
        </TooltipParagraph>
      ),
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="email"
          title="メールアドレス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyInvitationList}
          setAction={setSortCondition}
        />,
      dataIndex: 'email',
      width: 300,
    },
    {
      title:
        <SortableHeader
          type="confirmationSentAt"
          title="招待日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyInvitationList}
          setAction={setSortCondition}
        />,
      dataIndex: 'confirmationSentAt',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="actualRegisterStatus"
          title="ステータス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyInvitationList}
          setAction={setSortCondition}
        />,
      dataIndex: 'registerStatus',
      width: 150,
    },
    {
      title: 'アクション',
      dataIndex: 'action',
      key: 'actionManager',
      width: 150,
      render: (_, record) => (
        <Style.TableAction>
        {record.registerStatus === '招待中'
          ?
            <Button
              className="btn-secondary"
              size="small"
              type="primary"
              disabled={record.isCancelInvitationProcessing}
              onClick={() => companyCancelInvitations({
                user: {
                  email: record.email,
                },
              })}
            >
              キャンセル
            </Button>
          :
            <Button
              size="small"
              type="primary"
              ghost
              disabled={record.isResendInvitationProcessing}
              onClick={() => companyResendInvitations({
                user: {
                  email: record.email,
                },
              })}
            >
              再送する
            </Button>
        }
        </Style.TableAction>
      ),
    },
  ];
  return (
    <>
      <Space align="center" size="large" style={{ marginBottom: 5, marginLeft: 316 }}>
        <Style.HeaderBlock >
          <Text w6 headerText margin="0 5px 0 0" style={{ fontSize: 14 }}>
            選択数:
          </Text>
          <Text style={{ fontSize: 16 }}>
            {idsUserSelected.length || '0'}
          </Text>
        </Style.HeaderBlock>
      </Space>
      <Style.ManagerStatusContainer >
        <Style.ManagerStatusSidebar>
          <FilterSidebar
            data={dataFilterFields}
            actionFilter={getCompanyInvitationList}
            setParamsFilter={setParamsFilter}
            setSortCondition={setSortCondition}
            pageSize={meta.pageInfo?.limit}
          />
        </Style.ManagerStatusSidebar>
        <Style.ManageStatusContent>
          <Table
            rowSelection={{
              type: 'checkbox',
              columnTitle: (
                <div>
                  <Text w6 color="white">選択</Text>
                  <Checkbox
                    disabled={isEmpty(data) || isMaxSelected}
                    indeterminate={indeterminate}
                    onChange={onCheckAllChange}
                    checked={checkAll}
                  />
                </div>
              ),
              columnWidth: 100,
              selectedRowKeys: idsUserSelected,
              onChange: onChangeSelectRow,
              getCheckboxProps: (record) => ({
                disabled: idsUserSelected.length >= USER_SELECTED_MAX && !idsUserSelected.includes(record.id),
              }),
            }}
            pagination={false}
            scroll={{ x: 900, y: 506 }}
            columns={columns}
            dataSource={tableData}
            locale={{ emptyText: 'データが存在しません。' }}
            loading={invitationList.load}
          />
          {!isEmpty(invitationList.data) && (
            <PaginationLayout
              pageInfo={meta.pageInfo}
              onChangePage={getCompanyInvitationList}
              filterParams={filterParams}
              sortCondition={sortCondition}
            />
          )}
          <Style.RegistrationStatusAction>
            <Space size="middle">
              <Button
                type="primary"
                onClick={handleBulkCancel}
                disabled={!usersCancel.length || invitationList.load || bulkInvitationProcess.isCancel}
              >
                一括キャンセル
              </Button>
              <Button
                type="primary"
                disabled={!usersResend.length || invitationList.load || bulkInvitationProcess.isResend}
                onClick={handleBulkResend}
              >
                一括再送
              </Button>
            </Space>
          </Style.RegistrationStatusAction>
        </Style.ManageStatusContent>
      </Style.ManagerStatusContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { invitationList } = state.companyAccountReducer;
  return {
    invitationList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyInvitationList: (params) => dispatch(getCompanyInvitationListAction(params)),
  companyResendInvitations: (params) => dispatch(companyResendInvitationsAction(params)),
  companyCancelInvitations: (params) => dispatch(companyCancelInvitationsAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  bulkResendInvitations: (params) => dispatch(companyBulkResendInvitationsAction(params)),
  bulkCancelInvitations: (params) => dispatch(companyBulkCancelInvitationsAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationStatus);
