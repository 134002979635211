//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Input ,
  Select,
  Form,
  Space,
} from 'antd';
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { getFilterOption } from 'src/utils/common';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { MainWrapper } from 'src/components/layouts/ContentLayout';
import {
  getCompanyContractList as getCompanyContractListAction,
  inviteAdminCompany as inviteAdminCompanyAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface ICompanyAccountRegistrationProps extends StateProps, DispatchProps {
  getCompanyContractList(): void;
  inviteAdminCompany(params): void;
  isDisableRequest: boolean;
  companyContracts: {
    data: {
      id: number,
      contractName: string,
    }[],
  };
  invitationAdminCompany: {
    errors: {
      email: string,
      message: string,
    }[],
  };
  activeTabKey: string
}

const CompanyAccountRegistration: React.FC<ICompanyAccountRegistrationProps> = ({
  getCompanyContractList,
  inviteAdminCompany,
  companyContracts,
  invitationAdminCompany,
  isDisableRequest,
  activeTabKey
}) => {
  const { errors } = invitationAdminCompany;
  const [invitationCompanyForm] = Form.useForm();
  const [errorIndexField, setErrorIndexField] = useState<number>(-1);

  const scrollRef = useRef<any>(null);

  const urlSelectCompanyId = history.location.state?.companyContractId;

  useEffect(() => {
    getCompanyContractList();
  }, []);

  useEffect(() => {
    history.replace({
      state: {
        key: activeTabKey
      }
    })
  }, [activeTabKey])

  useEffect(() => {
    if (urlSelectCompanyId && !isEmpty(companyContracts.data)) {
      invitationCompanyForm.setFields([
        {
          name: 'companyId',
          value: parseFloat(urlSelectCompanyId),
        },
      ]);
    }
  }, [urlSelectCompanyId, companyContracts.data]);

  useEffect(() => {
    if (errors && errors.length >= 1) {
      errors.map((error) => {
        const emailList = invitationCompanyForm.getFieldsValue().emails;
        const invalidInputIndex = emailList.map((item, index) =>
          item.toLowerCase() === error.email.toLowerCase() ? index : '').filter(String);
        // filter string will remove '' and leave only index behind ^^
        return invalidInputIndex.map((inputIndex, index) => {
          if (index === 0) {
            setErrorIndexField(inputIndex);
          }

          invitationCompanyForm.setFields([
            {
              name: ['emails', inputIndex],
              errors: [error.message],
            },
          ]);
        });
      });
    }
  }, [errors]);

  useEffect(() => {
    if (errorIndexField >= 0) scrollRef.current.scrollIntoView();
  }, [errorIndexField]);

  function renderCompanyOptions() {
    if (isEmpty(companyContracts.data)) return null;
    return companyContracts.data.map((company) => (
      <Select.Option key={`company-${company.id}`} value={company.id}>
        <TooltipParagraph style={{ lineHeight: 'unset' }}>
          {company.contractName}
        </TooltipParagraph>
      </Select.Option>
    ));
  }

  const initialFormValues = {
    emails: [''],
  };

  function handleSubmitForm(values) {
    const duplicatedInputsIndex = values.emails.map((item, index) => {
      if (values.emails.filter((i) => i.toLowerCase() === item.toLowerCase()).length > 1) {
        return index;
      } else {
        return '';
      }
    }).filter(String);
    duplicatedInputsIndex.map((inputIndex, index) => {
      if (index === 0) {
        setErrorIndexField(inputIndex);
      }

      invitationCompanyForm.setFields([
        {
          name: ['emails', inputIndex],
          errors: ['メールアドレスが重複しています。'],
        },
      ]);
    });
    if (duplicatedInputsIndex.length < 1) {
      inviteAdminCompany(values);
      setErrorIndexField(-1);
    };
    if (duplicatedInputsIndex.length >= 1 && errorIndexField >= 0) {
      scrollRef.current.scrollIntoView();
    }
  }

  return (
    <MainWrapper title="新規登録" style={{ marginTop: 24 }}>
      <Style.TabWrapperContent>
        <Style.FormWrapper
          form={invitationCompanyForm}
          name="loginForm"
          onFinish={(values) => handleSubmitForm(values)}
          initialValues={initialFormValues}
          scrollToFirstError={true}
        >
          <Space size={0}>
            <Form.Item
              label="契約名"
              name="companyId"
              labelAlign="left"
              colon={false}
              rules={[
                { required: true, message: '契約名は必須項目です。' },
              ]}
            >
              <Select
                allowClear
                showSearch
                suffixIcon={<CaretDownOutlined />}
                optionFilterProp="children"
                placeholder="選択してください"
                notFoundContent="データが存在しません。"
                filterOption={(input: any, option: any) => {
                  const optionTitle = option.children.props.children;
                  const filterValue = (input || '').trim();
                  return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                }}
                style={{ width: 350 }}
              >
                {renderCompanyOptions()}
              </Select>
            </Form.Item>
          </Space>
          <Form.List name="emails">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index) => (
                  <Style.DynamicInputField key={field.key}>
                    {index === errorIndexField && <div ref={scrollRef} />}
                    <Form.Item
                      required={index === 0}
                      key={field.key}
                      label={index === 0 ? 'メールアドレス' : ' '}
                      colon={false}
                    >
                      <Space size={0}>
                        <div>
                          <Form.Item
                            {...field}
                            normalize={(value) => value.trim()}
                            validateTrigger={['onBlur']}
                            validateFirst
                            rules={[
                              { required: true, message: 'メールアドレスは必須項目です。' },
                              { type: 'email', message: 'メールアドレスのフォーマットが正しくありません。' },
                              { max: 50, message: 'メールアドレスが長すぎます。（最大は50桁です）' },
                            ]}
                            noStyle
                          >
                            <Input style={{ width: 350 }} />
                          </Form.Item>
                        </div>
                        {fields.length > 1
                          ? (
                            <Button
                              type="link"
                              ghost
                              onClick={() => remove(field.name)}
                              icon={<CloseCircleOutlined style={{ fontSize: 20, color: '#d25109' }} />}
                            />
                          )
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Style.DynamicInputField>
                ))}
                <Space size={0}>
                  <Style.FormItemLabel />
                  <Button
                    type="link"
                    size="small"
                    icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
                    onClick={() => add()}
                    style={{ padding: 0, marginLeft: 125 }}
                  >
                    新規追加
                  </Button>
                </Space>
              </div>
            )}
          </Form.List>
          <Style.GroupAction>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isDisableRequest}
            >
              登録する
            </Button>
          </Style.GroupAction>
        </Style.FormWrapper>
      </Style.TabWrapperContent>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => {
  const { companyContracts } = state.companyReducer;
  const { invitationAdminCompany } = state.accountReducer;
  return {
    companyContracts,
    invitationAdminCompany,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  inviteAdminCompany: (params) => dispatch(inviteAdminCompanyAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAccountRegistration);
