// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class StudentCourseServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getGroupDetail(params) {
    const { courseId, groupId } = params;
    return this.restClient.get(`/student/courses/${courseId}/groups/${groupId}`);
  }

  getCourseDetail(params) {
    return this.restClient.get(`/student/courses/${params.id}`);
  }

  createQuestion(params) {
    const { requestData, id } = params;
    return this.restClient.post(`/student/courses/${id}/course_questions`, requestData);
  }

  getStudentCourseDetailStatus(params) {
    return this.restClient.get(`/student/courses/${params.id}/attendance_statuses`);
  }


  getRecommendDate(params) {
    const { courseId } = params;
    return this.restClient.get(`/student/courses/${courseId}/calculate_recommended_date`);
  }
}
