// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { Button } from "antd";
import React, { useState } from "react";

import * as Style from "./styles";

import {
  closeModal as closeModalAction,
} from "src/redux/actions";
import { connect } from "react-redux";
import doneImage from "src/assets/images/modalImage/done-action.svg";
import fileCsvDone from "src/assets/images/modalImage/file-csv-done.svg";
import fileXlsDone from "src/assets/images/modalImage/file-xls.svg";
import fileCsvEmpty from "src/assets/images/modalImage/file-csv-empty.svg";
import warningImg from "src/assets/images/modalImage/warning.svg"
import { Text } from "src/components/styles";
import { useDropzone } from "react-dropzone";
import { isEmpty } from "lodash";
import { ALLOWED_TYPE_CSV, FILE_SIZE_CSV } from "src/constants/common";
import { TooltipParagraph } from "src/components/layouts/TooltipParagraph";

interface IAddCsvModalProps extends DispatchProps, StateToProps {
  closeModal: () => void;
  isConfirm?: boolean;
  isOk: boolean;
  isClose?: boolean;
  isDisableRequest:boolean;
  modalData: {
    title: string;
    okText: string;
    closeText?: string;
    errorText?: string;
    errorServer?: string;
    responeDataSuccess?: {
      numberSuccess: number,
      numberInviteEmails: number
    };
    contractName: string;
    courseName: string;
    watting?: boolean;
  };
  requestData?: {
    durationType?: string;
    startDate?: string;
    endDate?: string;
    companyId?: string
  };
  courseId?: any
  modalAction?: {
    AddStudentsToCourseByCsv(params): void
  }
}

const AddCsvModal: React.FC<IAddCsvModalProps> = ({
  modalData,
  isOk,
  isClose,
  isConfirm,
  closeModal,
  requestData,
  modalAction,
  courseId,
  isDisableRequest
}) => {

  const [fileError, setFileError] = useState<string>("");
  const [fileResult, setFileResult] = useState<any>();
  const fileFormData = new FormData();
  const [typeFile,setTypeFile] = useState<string>("");

  const onDrop = (acceptedFiles) => {
    setFileResult(acceptedFiles[0])
    const fileType = acceptedFiles[0].name.slice(
      acceptedFiles[0].name.lastIndexOf(".") + 1,
    );
    setTypeFile(fileType)
    if (!ALLOWED_TYPE_CSV.includes(fileType)) {
      setFileError(
        "ファイルの形式が正しくありません。選択可能ファイルはcsv、xlsです。",
      );
    } else if (acceptedFiles[0].size > FILE_SIZE_CSV) {
      setFileError("ファイルサイズが大きすぎます。(最大サイズ：3MB）");
    } else {
      setFileError("");
    }

    const reader = new FileReader();

    reader.readAsDataURL(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
    });

  function uploadFile() {
    if (!isEmpty(fileError)) return null;
    requestData?.durationType && fileFormData.append("duration_type", requestData.durationType)
    requestData?.startDate && fileFormData.append("start_date", requestData.startDate)
    requestData?.endDate && fileFormData.append("end_date", requestData.endDate)
    requestData?.companyId && fileFormData.append("company_id", requestData.companyId)
    fileResult && fileFormData.append("file", fileResult, fileResult?.name)

    modalAction && modalAction.AddStudentsToCourseByCsv({ courseId: courseId, requestData: fileFormData })
  }
  
  return (
    <>
      <Style.AddCsvModalHeader marginTop={"16px"}>
        <Text w6> 契約名： </Text>
        <Text margin={"0 16px 0 0"}>
          <TooltipParagraph style={{ lineHeight: 'unset' }}>{modalData.contractName}</TooltipParagraph>
        </Text>
      </Style.AddCsvModalHeader>
      <Style.AddCsvModalHeader>
        <Text w6> コース名： </Text>
        <Text margin={"0 16px 0 0"}>
          <TooltipParagraph style={{ lineHeight: 'unset' }}>{modalData.courseName}</TooltipParagraph>
        </Text>
      </Style.AddCsvModalHeader>

      <Style.AddCsvModalContainer
        isDrag={isDragActive}
        {...getRootProps()}
        isDone={!!modalData.responeDataSuccess || modalData.watting || modalData.errorServer}
      >
        <input {...getInputProps()} />
        {modalData.errorServer ?
          <>
            <img src={fileCsvEmpty} height="79px" width="62px" alt="fileCsvEmpty" draggable={false} />
            <Style.ErrorContent>
              <img src={warningImg} alt="warningImg" draggable={false}  />
              <Text color="#D25109">{modalData.errorServer}</Text>
            </Style.ErrorContent> 
          </> :
          !!modalData.responeDataSuccess ? (
            <>
              <img src={doneImage} height="83px" width="83px" alt="doneImage" draggable={false} />
              {
                modalData.responeDataSuccess.numberInviteEmails ?
                  <>
                    <Text w6 margin="24px 0px 0px 0px">
                      {modalData.responeDataSuccess.numberSuccess}件のライセンス付与、
                    </Text>
                    <Text w6>{modalData.responeDataSuccess.numberInviteEmails}件のユーザー登録を行いました。</Text>
                  </> :

                  <Text w6 margin="24px 0">
                    {modalData.responeDataSuccess.numberSuccess}件のライセンス付与を行いました。
                  </Text>

              }
            </>
          ) : !isEmpty(acceptedFiles) && isEmpty(fileError)? (
            typeFile === "csv" ? <img src={fileCsvDone} height="83px" width="83px" alt="fileCsvDone" draggable={false} />
            : <img src={fileXlsDone} height="83px" width="83px" alt="fileCsvDone"  draggable={false} /> 
          ) :
            modalData.watting ?
              <>
                <img src={doneImage} height="83px" width="83px" alt="doneImage" draggable={false} />
                <Text w6 margin="24px 0px 0px 0px">インポートが実行中です。</Text>
              </> :  (
              <img src={fileCsvEmpty} height="79px" width="62px" alt="fileCsvEmpty" draggable={false} />
            )
        }
        {!!modalData.responeDataSuccess || modalData.watting || !!modalData.errorServer || (
          <Button
            size="middle"
            type="primary"
            onClick={open}
            ghost
            style={{ marginTop: "16px" }}
          >
            {isEmpty(acceptedFiles)
              ? "パソコンからファイルを選択"
              : "ファイルを再選択"}
          </Button>
        )}
        {
          !!modalData.errorServer || isEmpty(acceptedFiles) && modalData.errorText &&
          <>
            <Style.ErrorContent>
              <img src={warningImg} alt="warningImg" draggable={false}  />
              <Text color="#D25109">{modalData.errorText}</Text>
            </Style.ErrorContent>
            <Text margin={"5px 0"}>修正して再度アップロードしてください。</Text>
          </>
        }

        {fileError && (
          <Text error margin="10px 0 10px 10px">
            <img src={warningImg} alt="warningImg" draggable={false}  />
            {fileError}
          </Text>
        )}
        {!isEmpty(acceptedFiles) ? (
          <Text
            notSelect
            truncate
            center
            lg
            w6
            style={{ width: 400, lineHeight: `${fileError ? "24px" : "54px"}` }}
          >
            {acceptedFiles[0].name}
          </Text>
        ) : (!!modalData.responeDataSuccess || !!modalData.errorText ||  modalData.watting || !!modalData.errorServer ||
          (
            <>
              <Text margin="5px 0 0 0" lg subText>
                ライセンスを付与するメールアドレスをA列に記載した
              </Text>
              <Text margin="0 0 10px" lg subText>
                CSVファイルを、こちらにドロップしてください。
              </Text>
            </>
          ))}
      </Style.AddCsvModalContainer>
      <Style.AddCsvModalFooter>
        {isClose && (
          <Button
            onClick={() => {
              closeModal();
            }}
            type="primary"
            className="btn-secondary"
            disabled={isDisableRequest}
          >
            {modalData.closeText}
          </Button>
        )}

        {isOk && (
          <Button
            onClick={() => { !!modalData.responeDataSuccess || modalData.watting || !!modalData.errorServer ? closeModal() : uploadFile() }}
            type="primary"
            disabled={!!modalData.responeDataSuccess || modalData.watting || modalData.errorServer ? false : isDisableRequest || (isEmpty(acceptedFiles) || fileError !== '')}
          >
            {modalData.okText}
          </Button>
        )}
      </Style.AddCsvModalFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateToProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(AddCsvModal);
