// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';
import { findIndex } from 'lodash/array';

const initialState = {
  faqList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  faqDetail: {
    data: {
      faqContents: [] as {
        id: number;
        question: string;
        answer: string;
      }[],
    },
    meta: {},
    errors: [],
  },
  modifyFAQContent: {
    errors: [],
  },
};

export type FAQReducer = Readonly<typeof initialState>;

export default (state: FAQReducer = initialState, action: any): FAQReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.FAQ_ACTION.GET_LIST): {
    return {
      ...state,
      faqList: {
        ...state.faqList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      faqList: {
        data: data.faqs,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.FAQ_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      faqList: {
        ...state.faqList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      faqDetail: {
        data: data.faq,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.FAQ_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      faqDetail: {
        ...state.faqDetail,
        errors,
      },
    };
  }

  case FAILURE(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ): {
    const { errors } = action;
    return {
      ...state,
      faqDetail: {
        ...state.faqDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ): {
    const { data, meta } = action.payload;
    return {
      ...state,
      faqDetail: {
        data: data.faq,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ): {
    const { errors } = action;
    return {
      ...state,
      faqDetail: {
        ...state.faqDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT): {
    const { data, meta } = action.payload;
    const { position } = data.faqContent;
    const newFAQContents = state.faqDetail.data.faqContents;
    const newFAQContent = {
      id: data.faqContent.id,
      question: data.faqContent.question,
      answer: data.faqContent.answer,
    };
    newFAQContents.splice(position, 0, newFAQContent);
    return {
      ...state,
      faqDetail: {
        data: {
          ...state.faqDetail.data,
          faqContents: newFAQContents,
        },
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT): {
    const { errors } = action;
    return {
      ...state,
      modifyFAQContent: {
        ...state.modifyFAQContent,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT): {
    const { data } = action.payload;
    const newFAQContents = state.faqDetail.data.faqContents;
    const faqContentIndex = findIndex(newFAQContents, { id: data.faqContent.id });
    newFAQContents.splice(faqContentIndex, 1, data.faqContent);
    return {
      ...state,
      faqDetail: {
        ...state.faqDetail,
        data: {
          ...state.faqDetail.data,
          faqContents: newFAQContents,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT): {
    const { errors } = action;
    return {
      ...state,
      modifyFAQContent: {
        ...state.modifyFAQContent,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT): {
    const { data } = action.payload;
    const newFAQContents = state.faqDetail.data.faqContents;
    const faqContentIndex = findIndex(newFAQContents, { id: data.faqContent.id });
    newFAQContents.splice(faqContentIndex, 1);
    return {
      ...state,
      faqDetail: {
        ...state.faqDetail,
        data: {
          ...state.faqDetail.data,
          faqContents: newFAQContents,
        },
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT): {
    const { errors } = action;
    return {
      ...state,
      modifyFAQContent: {
        ...state.modifyFAQContent,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.FAQ_ACTION.CLEAR_FAQ_ERRORS): {
    return {
      ...state,
      faqDetail: {
        ...state.faqDetail,
        errors: [],
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.FAQ_ACTION.CLEAR_FAQ_CONTENT_ERRORS): {
    return {
      ...state,
      modifyFAQContent: {
        ...state.modifyFAQContent,
        errors: [],
      },
    };
  }

  default:
    return state;
  }
};
