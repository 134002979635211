// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
} from 'antd';

import { getSearchParams } from 'src/utils/common';

import {
  MainContainer,
  MainContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles';

import { PASSWORD_REGEX } from 'src/constants/regexValidate';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getServiceConfirmationStatus as getServiceConfirmationStatusAction,
  serviceConfirmAccount as  serviceConfirmAccountAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IConfirmServiceAccountPageProps extends DispatchProps {
  getServiceConfirmationStatus(params): void;
  serviceConfirmAccount(params): void;
  autoComplete?: string | undefined;
}

const ConfirmServiceAccountPage: React.FC<IConfirmServiceAccountPageProps> = ({
  getServiceConfirmationStatus,
  serviceConfirmAccount,
}) => {
  const [confirmAccountForm] = Form.useForm();
  const email = getSearchParams('email');
  const confirmationToken = getSearchParams('confirmation_token');

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.ConfirmServiceAccount);

    getServiceConfirmationStatus({
      email,
      confirmationToken,
    });
  }, []);

  function handleSubmitConfirmForm(values) {
    const requestData = {
      user: {
        email,
        token: confirmationToken,
        role: 'admin_service',
        jituName: values.jituName.trim(),
        department: (values.department || '').trim(),
        jobTitle: (values.jobTitle || '').trim(),
        jobType: (values.jobType || '').trim(),
        password: values.password,
      },
    };
    serviceConfirmAccount(requestData);
  }

  function renderConfirmAccountForm() {
    return (
      <Form
        form={confirmAccountForm}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        name="userConfirmForm"
        onFinish={(values) => handleSubmitConfirmForm(values)}
        scrollToFirstError={true}
      >
        <Style.FormRow>
          <Style.FormCol span={6}>
            <Text>登録アドレス</Text>
          </Style.FormCol>
          <Style.FormCol span={18}>
            <Input value={email ? email : ''} disabled />
          </Style.FormCol>
        </Style.FormRow>
        <Form.Item
          label="名前"
          name="jituName"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, whitespace: true, message: '名前は必須項目です。' },
            { max: 50, message: '名前が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="部署"
          name="department"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 50, message: '部署が長すぎます。（最大は50桁です)' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="役職"
          name="jobTitle"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 50, message: '役職が長すぎます。（最大は50桁です)' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="職種"
          name="jobType"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 50, message: '職種が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="パスワード"
          name="password"
          labelAlign="left"
          colon={false}
          validateFirst
          rules={[
            { required: true, message: 'パスワードは必須項目です。' },
            { min: 8, message: 'パスワードは8文字以上で設定して下さい。' },
            { max: 20, message: 'パスワードが長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PASSWORD_REGEX),
              message: 'パスワードは大文字、小文字、数字、記号から3つ以上の組み合わせで入力してください。',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="パスワード確認用"
          name="confirmPassword"
          dependencies={['password']}
          labelAlign="left"
          colon={false}
          validateFirst
          rules={[
            { required: true, message: 'パスワード確認用は必須項目です。' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('新しいパスワードと確認用のパスワードが一致しません。ご確認ください。');
              },
            }),
          ]}
        >
          <Input.Password autoComplete="new-password" />
        </Form.Item>
        <Row>
          <Col span={6} />
          <Col span={18}>
            <Form.Item
              name="agreeTerm"
              wrapperCol={{ span: 24 }}
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => value
                    ? Promise.resolve()
                    : Promise.reject('ご利用には利用規約とプライバシーポリシーへ同意が必要です。'),
                },
              ]}
            >
              <Style.GroupCheckbox>
                <Checkbox />
                <Style.GroupCheckbox style={{ marginLeft: 6 }}>
                  アカウントを作成すると、
                  <Style.Link
                    href="https://skillup-next.co.jp/lms-scai-terms-of-use/"
                    target="_blank"
                  >
                    利用規約
                  </Style.Link>
                  、および
                  <Style.Link
                    href="https://skillup-next.co.jp/privacy/"
                    target="_blank"
                  >
                    プライバシーポリシー
                  </Style.Link>
                  に同意したことになります。
                </Style.GroupCheckbox>
              </Style.GroupCheckbox>
            </Form.Item>
          </Col>
        </Row>
        <Style.GroupAction>
          <Button
            type="primary"
            htmlType="submit"
          >
            登録する
          </Button>
        </Style.GroupAction>
      </Form>
    );
  }

  return (
    <MainContainer>
      <MainContent formView>
        <MainWrapper title="新規登録" style={{ marginTop: 24 }}>
          <Style.ConfirmAccountContainer>
            {renderConfirmAccountForm()}
          </Style.ConfirmAccountContainer>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({
  getServiceConfirmationStatus: (params) => dispatch(getServiceConfirmationStatusAction(params)),
  serviceConfirmAccount: (params) => dispatch(serviceConfirmAccountAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmServiceAccountPage);
