// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const SurveyDetailContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const SurveyDetailContent = styled.div`
  margin-right: 16px;
  width: calc(100% - 300px);
  min-width: 898px;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const SurveyDetailTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const SurveyDescription = styled.div`
  padding: 16px;
  min-height: 54px;
  background-color: white;
`;

export const SurveyFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: white;
`;
