// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Collapse,
  Input,
  Space,
  Spin,
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { sortBy } from 'lodash/collection';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  MainWrapper,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import CreateVersionModal from './components/CreateVersionModal';
import NoData from 'src/components/layouts/NoData';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import {
  getCourseList as getCourseListAction,
  getCourseVersions as getCourseVersionsAction,
  getCourseDetail as getCourseDetailAction,
  showModal as showModalAction,
  updateCourseName as updateCourseNameAction,
  closeModal as closeModalAction,
  getCategoryList as getCategoryListAction,
} from 'src/redux/actions';

import history from 'src/utils/history';
import { convertSecondTime, formatDateTime } from 'src/utils/dateTime';
import { DATE_FORMAT } from 'src/constants/common';
import { handleStopPropagation } from 'src/utils/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';


import { Text } from 'src/components/styles';

import { ServicePath } from 'src/constants/routerConstants';

import * as Style from './styles';
import { FilterSidebar } from 'src/components/layouts/Sidebar';

interface ICourseListPageProps extends StateProps, DispatchProps {
  showModal(params: any): void;
  getCourseList(params): void;
  getCourseVersions(params): void;
  getCourseDetail(params): void;
  updateCourseName(params): void;
  closeModal(): void;
  getCategoryList(): void;
  courseList: {
    data: {
      id: number;
      name: string;
      remainingLicensesCount: number;
      totalContractCount: number;
      totalLearningTime: number;
      usedLicensesCount: number;
      versions: {
        id: number;
        name: string;
        versionDescription: string;
        updatedAt: string;
        contractCount: number;
        usedLicensesCount: number;
        remainingLicensesCount: number;
        studyTime: number;
        contentOverview: {
          videosCount: number;
          videosTotalDuration: number;
        };
      }[];
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    loadVersion: boolean;
    errors: string,
  };
  categoryList: {
    data: {
      id: number;
      name: string;
    }[];
  };
}

const CourseListPage: React.FC<ICourseListPageProps> = ({
  showModal,
  getCourseList,
  getCourseVersions,
  getCourseDetail,
  updateCourseName,
  closeModal,
  getCategoryList,
  courseList,
  categoryList
}) => {
  const filterParamsUrl = history.location.state?.filterParams;
  const pageUrl = history.location.state?.page;
  const { data, meta } = courseList;
  const [collapsesActivated, setCollapsesActivated] = useState<any>([]);
  const [indexCourseWillOpen, setIndexCourseWillOpen] = useState<number>(-1);
  const [isReloadVersionList, setIsReloadVersionList] = useState<boolean>(false);
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});
  const [changeNameKey, setChangeNameKey] = useState<{ key?: number, name?: string }>({});
  const [changeNameError, setChangeNameError] = useState<{ key?: number, error?: string }>({});
  const [titleName, setTitleName] = useState<string>(''); // Optimal performance typing
  const [courseIndexSelect, setCourseIndexSelect] = useState<number>(-1);

  useEffect(() => {
    if (!isEmpty(filterParamsUrl)) {
      const categoryId = filterParamsUrl?.category_id_eq;
      const category = categoryList.data.find((cate) => cate.id === categoryId);

      category
        ? setParamsFilter(filterParamsUrl)
        : setParamsFilter({
            ...filterParamsUrl,
            category_id_eq: undefined,
          });
    }

    window.addEventListener("beforeunload", function () {
      history.replace()
    })
  }, [])

  useEffect(() => {
    history.replace({
      state: {
        filterParams,
        page: meta.pageInfo
      }
    })
  }, [sortCondition, filterParams, meta.pageInfo])

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CourseList);
    getCourseList({ page: 1,...pageUrl, items: pageUrl?.limit, ...filterParamsUrl  });
    getCategoryList();

    const headerWrapper = document.getElementsByClassName("header-wrapper") as HTMLCollectionOf<HTMLElement>
    const mainWrapperElement = document.getElementsByClassName("main-wrapper-table")[0]

    if(mainWrapperElement){
      mainWrapperElement.addEventListener('scroll', () => {
        headerWrapper[0].style.top = `${mainWrapperElement.scrollTop - 1}px`
      })
    }
    
    return () => {
      closeModal()
      mainWrapperElement.removeEventListener("scroll",() => {})
    }
  }, []);

  useEffect(() => {
    const courseAlreadyOpen = collapsesActivated.includes(`course-${indexCourseWillOpen}`);
    if (!courseAlreadyOpen && indexCourseWillOpen >= 0) {
      setCollapsesActivated([...collapsesActivated, `course-${indexCourseWillOpen}`]);
    }
  }, [indexCourseWillOpen]);

  useEffect(() => {
    if (isReloadVersionList) {
      getCourseVersions({
        courseCategoryId: data[indexCourseWillOpen].id,
        courseIndex: indexCourseWillOpen,
      });
      setIsReloadVersionList(false);
      setIndexCourseWillOpen(-1);
    }
  }, [isReloadVersionList]);

  useEffect(() => {
    setCollapsesActivated([]);
    setChangeNameKey({});
  }, [meta.pageInfo?.page]);

  function handleSelectVersionModal(e, courseId, courseCollapseIndex) {
    handleStopPropagation(e);
    const modalProps = {
      setIndexCourseWillOpen,
      setIsReloadVersionList,
      modalData: {
        title: 'バージョン追加',
        courseId,
        courseCollapseIndex,
      },
    };
    return showModal(<CreateVersionModal {...modalProps} />);
  }

  function handleChangeCourseCollapse(collapseValues) {
    const newCollapseValues = sortBy(collapseValues);
    const newCollapseData = newCollapseValues.map((value) => {
      const courseActiveIndex = findIndex(collapsesActivated, { key: value });
      if (courseActiveIndex === -1) {
        return value;
      }
      return collapsesActivated[courseActiveIndex];
    });
    setCollapsesActivated([...newCollapseData]);
  }

  function handleViewCourseVersions(courseIndex, id) {
    setCourseIndexSelect(courseIndex);
    if (courseIndex !== courseIndexSelect) {
      getCourseVersions({ courseCategoryId: id, courseIndex, requestData: {...filterParams} });
    }
  }

  function openChangeName(e, key, name) {
    handleStopPropagation(e);
    setChangeNameKey({ key, name });
    setTitleName(name.trim());
  }

  function closeChangeName(e) {
    handleStopPropagation(e);
    setChangeNameKey({});
    setChangeNameError({});
  }

  function handleChangeName(e, key) {
    const { value } = e.target;
    setTitleName(value.trim());
    if (value.trim().length === 0) {
      setChangeNameError({
        key,
        error: 'コース名は必須項目です。',
      });
    } else if (value.trim().length > 50) {
      setChangeNameError({
        key,
        error: 'コース名が長すぎます。（最大は50桁です）' ,
      });
    } else {
      setChangeNameError({});
    }
  }

  function submitChangeName(e, id, courseIndex) {
    if(isEmpty(changeNameError)){
      handleStopPropagation(e);
      updateCourseName({
        id,
        requestData: {
          name: titleName,
        },
        courseIndex,
      });
      setChangeNameKey({});
    }
  }

  function renderLicenseField(usedLicensesCount, remainingLicensesCount) {
    if (usedLicensesCount > 999 || remainingLicensesCount > 999) {
      return (
        <div>
          <Text w6>{usedLicensesCount}</Text>
          <Text w6>
            {`(残り${(remainingLicensesCount || 0)})`}
          </Text>
        </div>
      )
    }
    return (
      <Text w6>
        {`${usedLicensesCount}(残り${(remainingLicensesCount || 0)})`}
      </Text>
    )
  }

  function renderCourseCollapse() {
    if (isEmpty(data)) return null;
    return data.map((courseCollapse, courseCollapseIndex) => {
      const courseActiveIndex = collapsesActivated.indexOf(`course-${courseCollapseIndex}`);
      return (
        <Collapse.Panel
          key={`course-${courseCollapseIndex}`}
          style={{ borderTop: '1px solid #FFFFFF', minHeight: 50 }}
          header={
            <Style.CollapseHeader
              onClick={() => handleViewCourseVersions(
                courseCollapseIndex,
                courseCollapse.id,
              )}
            >
              <Style.CollapseItemName style={{ width: '70%' }}>
                <Space>
                  {changeNameKey.key === courseCollapseIndex
                    ? (
                      <div style={{ padding: '4px 0' }}>
                        <Space
                          align="center"
                          size="middle"
                        >
                          <Input
                            size="small"
                            defaultValue={changeNameKey.name}
                            onClick={(e) => handleStopPropagation(e)}
                            onChange={(e) => handleChangeName(e, courseCollapseIndex)}
                            style={{ width: 400 }}
                          />
                          <Button
                            type="primary"
                            size="small"
                            ghost
                            disabled={!isEmpty(changeNameError)}
                            onClick={(e) => submitChangeName(e, courseCollapse.id, courseCollapseIndex)}
                          >
                            保存する
                          </Button>
                          <Button
                            type="primary"
                            size="small"
                            ghost
                            danger
                            onClick={(e) => closeChangeName(e)}
                          >
                            キャンセル
                          </Button>
                        </Space>
                        {changeNameError.key === courseCollapseIndex && (
                          <Text error dangerouslySetInnerHTML={{ __html: changeNameError.error }} />
                        )}
                      </div>
                    )
                    : (
                    <>
                      <Text isPreWrap w6>
                        {courseCollapse.name}
                      </Text>
                      <Button
                        className="collapse-header-button"
                        type="link"
                        ghost
                        icon={<EditOutlined />}
                        onClick={(e) => openChangeName(e, courseCollapseIndex, courseCollapse.name)}
                      />
                    </>
                  )}
                </Space>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '6%', paddingLeft: 4 }}>
                <Text w6>{courseCollapse.totalContractCount}</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '8%', paddingLeft: 4 }}>
                {renderLicenseField(courseCollapse.usedLicensesCount, courseCollapse.remainingLicensesCount)}
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '8%', paddingLeft: 4 }}>
                <Text w6>
                  {convertSecondTime(courseCollapse.totalLearningTime, 'HH:mm:ss')}
                </Text>
              </Style.CollapseItemName>
              <div style={{ width: '8%' }}>
                <Button
                  size="small"
                  type="primary"
                  onClick={(e) => handleSelectVersionModal(e, courseCollapse.id, courseCollapseIndex)}
                  style={{ fontSize: 12 }}
                >
                  バージョン追加
                </Button>
              </div>
              <Style.CollapseIcon>
                {courseActiveIndex === -1
                  ? <PlusOutlined style={{ fontSize: 12 }} />
                  : <MinusOutlined style={{ fontSize: 12 }} />
                }
              </Style.CollapseIcon>
            </Style.CollapseHeader>
          }
        >
          <Collapse
            bordered={false}
            expandIconPosition="right"
            defaultActiveKey={[]}
            activeKey={[...collapsesActivated.map((collapse) => collapse.key)]}
            expandIcon={({ isActive }) =>
              isActive ? <MinusOutlined /> : <PlusOutlined />
            }
            style={{ backgroundColor: '#FAFAFA' }}
          >
            {renderVersionItems(
              courseCollapse.versions,
              `course-${courseCollapseIndex}`,
              courseCollapseIndex,
            )}
          </Collapse>
        </Collapse.Panel>
      );
    });
  }

  function renderCourseList() {
    if (courseList.load) {
      return <Spin style={{ width: '100%', margin: '60px 10px 0 0' }} />;
    }
    if (isEmpty(data)) {
      return <NoData style={{ marginTop: 50 }} message="データが存在しません。" />;
    }
    return (
      <Collapse
        bordered={false}
        className="course-list-collapse"
        defaultActiveKey={[]}
        activeKey={collapsesActivated}
        expandIcon={() => null}
        expandIconPosition="right"
        onChange={(values) => handleChangeCourseCollapse(values)}
        style={{
          minWidth: 1400,
          overflow: 'unset'
        }}
      >
        {renderCourseCollapse()}
      </Collapse>
    );
  }

  function renderVersionItems(versionsData, indexKey, courseCollapseIndex) {
    if (courseList.loadVersion && courseIndexSelect === courseCollapseIndex) {
      return (
        <Style.NoVersionsData>
          <Spin style={{ width: '100%', margin: '10px 0' }} />
        </Style.NoVersionsData>
      );
    }
    if (isEmpty(versionsData)) {
      return (
        <Style.NoVersionsData>
          <Text>データが存在しません。</Text>
        </Style.NoVersionsData>
      );
    }
    return versionsData.map((versionItem, indexVersions) => (
      <Style.CollapseItemContainer
        key={`${indexKey}-version-${indexVersions}`}
        onClick={() => getCourseDetail({
          id: versionItem.id,
          redirect: `/admin-service/course/${versionItem.id}`,
        })}
      >
        <Style.CollapseItemContent style={{ width: '24%', paddingLeft: 10 }}>
          <TooltipParagraph isPreWrap rows={2}>
            {versionItem.name}
          </TooltipParagraph>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent style={{ width: '24%', padding: '0 10px' }}>
          <TooltipParagraph rows={2}>
            {versionItem.versionDescription}
          </TooltipParagraph>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent style={{ width: '8%', paddingLeft: 4 }}>
          <Text>{formatDateTime(versionItem.updatedAt, DATE_FORMAT)}</Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent style={{ width: '6%', paddingLeft: 4 }}>
          <Text>{versionItem.contentOverview.videosCount}</Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent style={{ width: '8%', paddingLeft: 4 }}>
          <Text>{convertSecondTime(versionItem.contentOverview.videosTotalDuration, 'HH:mm:ss')}</Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent style={{ width: '6%', paddingLeft: 4 }}>
          <Text>{versionItem.contractCount}</Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent style={{ width: '8%', paddingLeft: 4 }}>
        {renderLicenseField(versionItem.usedLicensesCount, versionItem.remainingLicensesCount)}
        </Style.CollapseItemContent>
        <Style.CollapseItemContent style={{ width: '8%', paddingLeft: 4 }}>
          <Text>
            {convertSecondTime(versionItem.studyTime, 'HH:mm:ss')}
          </Text>
        </Style.CollapseItemContent>
      </Style.CollapseItemContainer>
    ));
  }

  const dataFilterFields = [
    {
      type: 'select',
      title: '講座タイプ',
      fieldParams: 'category_id_eq',
      options: categoryList.data || [],
    },
    {
      type: 'textInput',
      fieldParams: 'course_category_name_cont',
      title: 'コース名',
    },
    {
      type: 'textInput',
      fieldParams: 'version_name_cont',
      title: 'バージョン',
    },
    {
      type: 'textInput',
      fieldParams: 'version_description_cont',
      title: '説明',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={(data) => {
            getCourseList(data)
            setCollapsesActivated([]);
            setCourseIndexSelect(-1)
          }}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
          filterParams={filterParams}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>コース一覧</Text>
          <Button type="primary" onClick={() => history.push(ServicePath.CreateCourse)}>
            新規コース作成
          </Button>
        </TopContent>
      <Style.WrapperTable className="main-wrapper-table">
        <MainWrapper
          title={
            <>
              <Style.CollapseItemName style={{ width: '24%' }}>
                <Text white w6>バージョン</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '24%', paddingLeft: 8 }}>
                <Text white w6>説明</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '8%' }}>
                <Text white w6>更新日</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '6%' }}>
                <Text white w6>動画数</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '8%' }}>
                <Text white w6>動画時間</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '6%' }}>
                <Text white w6>契約数</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '8%' }}>
                <Text white w6>受講者数</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '16%' }}>
                <Text white w6>学習時間</Text>
              </Style.CollapseItemName>
            </>
          }
          style={{ position: "relative", minWidth: 1400, overflow: "unset" }}
            styleHeader={{
              minWidth: 1400,
              position: "absolute",
              width: "100%",
              zIndex: 1,
            }}
        >
          {renderCourseList()}
        </MainWrapper>
      </Style.WrapperTable>
        {!isEmpty(data) && (
        <PaginationLayout
          pageInfo={meta.pageInfo}
          onChangePage={(data) => {
            getCourseList(data);
            setCollapsesActivated([]);
            setCourseIndexSelect(-1);
          }}
          filterParams={filterParams}
          sortCondition={sortCondition}
        />
      )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { courseList } = state.courseReducer;
  const { categoryList } = state.categoryReducer;
  return {
    courseList,
    categoryList
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCourseList: (params) => dispatch(getCourseListAction(params)),
  getCourseVersions: (params) => dispatch(getCourseVersionsAction(params)),
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  updateCourseName: (params) => dispatch(updateCourseNameAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseListPage);
