// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Skeleton } from 'antd';

export const SkeletonLoading = styled(Skeleton)`
  margin: ${(props) => props.margin || 0};

  & h3 {
    display: inline-block;
    margin: 4px 0 !important;
    height: ${(props) => `${props.height}px` || '14px'} !important;
  }
  & li {
    height: ${(props) => `${props.height}px` || '14px'} !important;
  }
`;
