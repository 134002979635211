//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Space } from 'antd';
import { keys } from 'lodash/object';
import { findIndex } from 'lodash/array';

import { randomLoadingWidth } from 'src/utils/common';
import { formatDateTime } from 'src/utils/dateTime';

import { Text, SkeletonLoading } from 'src/components/styles';

import { DATE_FORMAT, NOTIFY_SETTING_TYPE } from 'src/constants/common';

import * as Style from './styles';

interface IOtherProfileProps {
  type: string;
  title: string;
  fieldData: {
    title: string;
    fieldName: string;
    type: string;
    option?: {
      title: string;
      value: string;
    }[];
  }[];
  profileDetail: {
    data: {
      id: number;
      company: {
        name: string;
      };
      department: string;
      jituName: string;
      kanaName: string;
      birthday: string;
      jobTitle: string;
      jobType: string;
      systemNotificationSetting: string;
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      customUserInfos: {
        id: number;
        companyCustomUserInfoId: number;
        fieldValue: string;
      }[];
      customDisplayField: {
        birthday: boolean;
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    load: boolean;
  };
}

const OtherProfile: React.FC<IOtherProfileProps> = ({
  type,
  title,
  fieldData,
  profileDetail,
}) => {
  const { data } = profileDetail;

  const customUserInfos = data.companyCustomUserInfos?.map((item) => {
    const infoIndex = findIndex(
      (data.customUserInfos || []),
      { companyCustomUserInfoId: item.id },
    );
    return {
      id: infoIndex !== -1 ? data.customUserInfos[infoIndex].id : '',
      fieldName: item.fieldName,
      companyCustomUserInfoId: item.id,
      fieldValue: infoIndex !== -1 ? data.customUserInfos[infoIndex].fieldValue : '',
    };
  });

  function renderFieldValue(field) {
    switch (field.fieldName) {
    case 'companyName': {
      return data.company?.name;
    }
    case 'birthday': {
      return formatDateTime(data[field.fieldName], DATE_FORMAT);
    }
    case 'systemNotificationSetting': {
      return NOTIFY_SETTING_TYPE[data[field.fieldName]];
    }
    default:
      return data[field.fieldName];
    }
  }

  function renderProfileView() {
    if (profileDetail.load) {
      return fieldData.map((field, fieldIndex) => (
        <Style.ProfileContent key={`loading-overview-profile-${fieldIndex}`}>
          <Space>
            <Text lg w6 style={{ minWidth: 150 }}>
              {field.title}
            </Text>
            <SkeletonLoading
              active
              paragraph={false}
              title={{ width: randomLoadingWidth(250, 350) }}
              height={16}
            />
          </Space>
        </Style.ProfileContent>
      ));
    }
    return (
      <>
        {fieldData.map((field, fieldIndex) => {
          if (keys(data.customDisplayField).indexOf(field.fieldName) !== -1
            && !data.customDisplayField?.[field.fieldName]) {
            return null;
          }
          return (
            <Style.ProfileContent key={`overview-profile-${fieldIndex}`}>
              <Space>
                <Text lg w6 style={{ minWidth: 150 }}>
                  {field.title}
                </Text>
                <Text lg isPreWrap>
                  {renderFieldValue(field)}
                </Text>
              </Space>
            </Style.ProfileContent>
          );
        })}
        {type === 'company' && customUserInfos?.map((field, fieldIndex) => {
          const { isDisplay } = data.companyCustomUserInfos[fieldIndex];
          if (!isDisplay) return null;
          return (
            <Style.ProfileContent key={`custom-profile-${fieldIndex}`}>
              <Space>
                <Text lg w6 style={{ minWidth: 150 }}>{field.fieldName}</Text>
                <Text lg isPreWrap>{field.fieldValue}</Text>
              </Space>
            </Style.ProfileContent>
          );
        })}
      </>
    );
  }

  return (
    <Style.ProfileWrapper>
      <Style.HeaderWrapper>
        <Text white lg w6>{title}</Text>
      </Style.HeaderWrapper>
      {renderProfileView()}
    </Style.ProfileWrapper>
  );
};

export default OtherProfile;
