// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class SurveyServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getSurveyLists(params) {
    return this.restClient.get('/admin_manage/surveys', params);
  }

  getSurveyDetail(params) {
    return this.restClient.get(`/admin_manage/surveys/${params.id}`);
  }

  createSurvey(params) {
    return this.restClient.post('/admin_manage/surveys', params);
  }

  updateSurvey(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/surveys/${id}`, requestData);
  }
}
