// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const LICENSE_DURATION_OPTION = [
  {
    label: '1週間',
    value: 'one_week',
  },
  {
    label: '2週間',
    value: 'two_weeks',
  },
  {
    label: '3週間',
    value: 'three_weeks',
  },
  {
    label: '1ヶ月',
    value: 'one_month',
  },
  {
    label: '2ヶ月',
    value: 'two_months',
  },
  {
    label: '3ヶ月',
    value: 'three_months',
  },
  {
    label: '4ヶ月',
    value: 'four_months',
  },
  {
    label: '5ヶ月',
    value: 'five_months',
  },
  {
    label: '6ヶ月',
    value: 'six_months',
  },
  {
    label: '7ヶ月',
    value: 'seven_months',
  },
  {
    label: '8ヶ月',
    value: 'eight_months',
  },
  {
    label: '9ヶ月',
    value: 'nine_months',
  },
  {
    label: '10ヶ月',
    value: 'ten_months',
  },
  {
    label: '11ヶ月',
    value: 'eleven_months',
  },
  {
    label: '12ヶ月',
    value: 'twelve_months',
  },
];
