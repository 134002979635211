// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from './restClient';

export default class MyProfileServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getProfileInfo() {
    return this.restClient.get('/profile/users');
  }

  getMyJoinedCourses(params) {
    return this.restClient.get('/profile/courses', params);
  }

  updateUserInfo(params) {
    return this.restClient.put('/profile/users', params);
  }

  updateCustomUserInfo(params) {
    return this.restClient.put('/profile/custom_user_infos', params);
  }

  updateProfileEmail(params) {
    return this.restClient.put('/profile/emails', params);
  }

  updateProfilePassword(params) {
    return this.restClient.put('/profile/passwords', params);
  }

  updateProfileEmailAndPassword(params) {
    return this.restClient.put('/profile/account_infos', params);
  }

  updateProfileAvatar(params) {
    return this.restClient.put('/profile/avatars', params, {}, 'formData');
  }

  getMyTestList(params) {
    return this.restClient.get(`/profile/tests_users`, params)
  }

  resetMyTestHistory(params) {
    const { requestData } = params;
    return this.restClient.post(`/profile/reset_test_histories`, requestData,)
  }

  getMyListCourseCompany(params) {
    const { requestData } = params;
    return this.restClient.get(`/profile/courses_companies`, requestData,)
  }

  updateMyCourseManager(params) {
    const { courseId } = params;
    return this.restClient.put(`/profile/courses_companies/${courseId}`, {})
  }
}
