// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';
import { isEmpty } from "lodash/lang";

import {
  ADMIN_COMPANY,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
  MODAL_ACTION,
} from 'src/redux/constant';

import { companyCourseServices } from 'src/services';
import { showAlertNotice } from 'src/utils/alert';
import { downloadCsvFromArray } from 'src/utils/common';
import history from 'src/utils/history';
import { ServerTimeStorage } from 'src/utils/localStorge';

function* getCourseDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield companyCourseServices.getCourseDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL),
      payload: result,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (redirect) history.push(redirect);
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getGroupDetailSaga(action) {
  try {
    const result = yield companyCourseServices.getGroupDetail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_GROUP),
      payload: {
        data: result.data,
        meta: {
          ...result.meta,
          groupIndex: action.payload.groupIndex,
        },
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_GROUP), errors: e.errors });
  }
}

function* getAttendanceStatusSaga(action) {
  try {
    const result = yield companyCourseServices.getAttendanceStatus(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_STATUS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_STATUS), errors: e.errors });
  }
}

function* getCourseAttendanceChartSaga(action) {
  try {
    const result = yield companyCourseServices.getCourseChartData(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_CHART), errors: e.errors });
  }
}

function* getCompanyTestScoreChartSaga(action) {
  try {
    const result = yield companyCourseServices.getCompanyTestScoreChart(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_CHART), errors: e.errors });
  }
}

function* getCourseStudentsSaga(action) {
  try {
    const result = yield companyCourseServices.getCourseStudents(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_STUDENT_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_STUDENT_LIST), errors: e.errors });
  }
}

function* getCoursesSaga() {
  try {
    const result = yield companyCourseServices.getCourses();
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES), errors: e.errors });
  }
}

function* getTestScoreOverviewSaga(action) {
  try {
    const result = yield companyCourseServices.getTestScoreOverview(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW), errors: e.errors });
  }
}

function* getTestScoreStudentsSaga(action) {
  try {
    const result = yield companyCourseServices.getTestScoreStudents(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_STUDENTS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_STUDENTS), errors: e.errors });
  }
}

function* downloadCsvStudyStudentSaga(action) {
  try {
    const result = yield companyCourseServices.downloadCsvStudyStudent(action.payload);
    const blob = new Blob([result])
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    const serverTime = ServerTimeStorage.getData()
    link.href=url
    link.setAttribute("download", `Learning_Data_${serverTime}.csv`);
    document.body.appendChild(link)
    link.click()
    ServerTimeStorage.clearData()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
    if(action.payload?.closeModal){
      action.payload.closeModal()
    }
    yield showAlertNotice({
      type: "success",
      message: "ダウンロードに成功しました。"
    })
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT)
    });
  } catch (e) {
    if(action.payload?.closeModal){
      action.payload.closeModal()
    }
    yield showAlertNotice({
      type: "error",
      message: (e.errors || [])[0]?.message || "ダウンロードに失敗しました。",
    });
    yield put({
      type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT)
    });
  }
}

function* setProgressNotification(action) {
  try {
    const { requestData } = action.payload;
    yield companyCourseServices.setProgressNotification(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.SET_PROGRESS_NOTIFICATION),
      payload: action.payload,
    });
    if (isEmpty(requestData.emailWeeklySchedule)) {
      yield showAlertNotice({ type: 'success', message: '通知停止が完了しました。' });
    } else {
      yield showAlertNotice({ type: 'success', message: '通知設定が完了しました' });
    }
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.SET_PROGRESS_NOTIFICATION), errors: e.errors });
  }
}

function* getRecommendDateSaga(action) {
  try {
    const result = yield companyCourseServices.getRecommendDate(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_RECOMMEND_DATE),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_RECOMMEND_DATE), errors: e.errors });
  }
}

function* downloadCsvTestStudentSaga(action){
  try {
    const result = yield companyCourseServices.downloadCsvTestsStudent(action.payload)
    const serverTime = ServerTimeStorage.getData();
    const newResult = result.data.testsUsers.map(item => {
      return {
        course_name: item.courseName ?? "",
        department: item.department ?? "",
        name: item.name ?? "",
        email: item.email ?? "",
        test_type: item.testType ?? "",
        lesson_name: item.lessonName ?? "",
        score: item.score ?? "",
        score_percentage: `${item.testScorePercentage}%` ?? "", 
        done_at: item.doneAt ?? "",
        banned_date: item.bannedDate ?? "",
      }
    })
    yield downloadCsvFromArray(newResult,`Test_Data_${serverTime}.csv`)
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_TESTS),
    });
    yield showAlertNotice({
      type: "success",
      message: "ダウンロードに成功しました。"
    });
  } catch (e) {
    yield showAlertNotice({
      type: "error",
      message: (e.errors || [])[0]?.message || "ダウンロードに失敗しました。",
    });
    yield put({
      type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_TESTS)
    });
  }
  finally{
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  }
}

function* getCompanyProgressRateChartSaga(action) {
  try {
    const result = yield companyCourseServices.getCompanyProgressRateChart(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_PROGRESS_RATE_CHART),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_PROGRESS_RATE_CHART), errors: e.errors });
  }
}

function* getCompanyUserProgressCourseSaga(action) {
  try {
    const result = yield companyCourseServices.getCompanyUserProgressCourse(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COURSE_ACTION.GET_USER_PROGRESS_COURSE),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COURSE_ACTION.GET_USER_PROGRESS_COURSE), errors: e.errors });
  }
}

export default function* companyCourseSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL), getCourseDetailSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_GROUP), getGroupDetailSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_STATUS), getAttendanceStatusSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_CHART), getCourseAttendanceChartSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_CHART), getCompanyTestScoreChartSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_STUDENT_LIST), getCourseStudentsSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES), getCoursesSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW), getTestScoreOverviewSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_STUDENTS), getTestScoreStudentsSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT), downloadCsvStudyStudentSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.SET_PROGRESS_NOTIFICATION), setProgressNotification),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_RECOMMEND_DATE), getRecommendDateSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_TESTS), downloadCsvTestStudentSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_PROGRESS_RATE_CHART), getCompanyProgressRateChartSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_USER_PROGRESS_COURSE), getCompanyUserProgressCourseSaga)
  ]);
}
