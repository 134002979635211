// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import TopOverview from './components/TopOverview';
import CourseList from './components/CourseList';
import HistoryCourses from './components/HistoryCourses';

import { PAGE_TITLE, StudentTitle } from 'src/constants/pageTitle';

import {
  getCurrentTime as getCurrentTimeAction,
  getStudentCurrentWeek as getStudentCurrentWeekAction,
  getStudentStatisticCourses as getStudentStatisticCoursesAction,
  getStudentLearningTimes as getStudentLearningTimesAction,
  getStudentLearningHistories as getStudentLearningHistoriesAction,
  getStudentCourseDetail as getStudentCourseDetailAction,
} from 'src/redux/actions';

interface IStudentTopPageProps extends StateProps, DispatchProps {
  getCurrentTime(): void;
  getStudentCurrentWeek(): void;
  getStudentStatisticCourses(): void;
  getStudentLearningTimes(params): void;
  getStudentLearningHistories(params): void;
  getStudentCourseDetail(params): void;
  currentTime: {
    data: string;
  };
  currentWeek: {
    data: {
      learningDates: {
        currentWeek: number;
        total: number;
        avgOnCompany: number;
      },
      learningTime: {
        currentWeek: number,
        total: number,
        avgOnCompany: number,
      },
      doneUnits: {
        currentWeek: number,
        total: number,
        avgOnCompany: number,
      },
      learningProgress: {
        currentWeek: number,
        total: number,
        avgOnCompany: number,
      };
    };
    load: boolean;
  };
  statisticCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      };
      totalLearningDays: number;
      totalLearningTime: number;
      studentProgressPercentage: number;
      testInfo: {
        usersRank: number;
        totalUsers: number;
        highestTestScore: number;
      };
      remainingDays: number;
      endDate: string;
      startDate: string;
      actualStatus: string,
    }[];
    load: boolean;
  };
  learningTimeChart: {
    data: {
      learningDate: string,
      learningTime: number,
    }[];
    meta: {
      maxLearningTime: number,
    },
    load: boolean;
  };
  learningHistories: {
    data: {
      course: {
        id: number;
        name: string;
        groupId: number;
        chapterId: number;
      };
      unitVersion: {
        id: string;
        unit: {
          name: string;
        };
        item: {
          type: string;
          thumbnailUrl: string;
        };
      };
    }[];
    meta: {
      pageInfo: {
        next: number;
      };
    };
    load: boolean;
  };
}

const StudentTopPage: React.FC<IStudentTopPageProps> = ({
  getCurrentTime,
  getStudentCurrentWeek,
  getStudentStatisticCourses,
  getStudentLearningTimes,
  getStudentLearningHistories,
  getStudentCourseDetail,
  learningTimeChart,
  currentTime,
  currentWeek,
  statisticCourses,
  learningHistories,
}) => {
  const startOfWeek = moment(currentTime.data).startOf('isoWeek');
  const endOfWeek = moment(currentTime.data).endOf('isoWeek');

  useEffect(() => {
    document.title = PAGE_TITLE(StudentTitle.StudentTop);

    getCurrentTime();
    getStudentCurrentWeek();
    getStudentStatisticCourses();
    getStudentLearningTimes({
      startDate: moment(currentTime.data).subtract(13, 'd'),
      endDate: moment(currentTime.data),
    });
    getStudentLearningHistories({ page: 1 });
  }, []);

  return (
    <MainContainer isStudent isResponsive>
      <MainContent fullView isResponsive>
        <TopOverview
          startOfWeek={startOfWeek}
          endOfWeek={endOfWeek}
          currentWeek={currentWeek}
          learningTimeChart={learningTimeChart}
          currentTime={currentTime}
          getStudentLearningTimes={getStudentLearningTimes}
        />
        <CourseList
          statisticCourses={statisticCourses}
          getStudentCourseDetail={getStudentCourseDetail}
        />
        <HistoryCourses
          getStudentLearningHistories={getStudentLearningHistories}
          learningHistories={learningHistories}
        />
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { currentTime } = state.masterDataReducer;
  const {
    currentWeek,
    statisticCourses,
    learningTimeChart,
    learningHistories,
  } = state.studentStatisticReducer;
  return {
    currentTime,
    currentWeek,
    statisticCourses,
    learningTimeChart,
    learningHistories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCurrentTime: () => dispatch(getCurrentTimeAction()),
  getStudentCurrentWeek: () => dispatch(getStudentCurrentWeekAction()),
  getStudentStatisticCourses: () => dispatch(getStudentStatisticCoursesAction()),
  getStudentLearningTimes: (params) => dispatch(getStudentLearningTimesAction(params)),
  getStudentLearningHistories: (params) => dispatch(getStudentLearningHistoriesAction(params)),
  getStudentCourseDetail: (params) => dispatch(getStudentCourseDetailAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(StudentTopPage);
