//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Input ,
  Form,
  Space,
} from 'antd';
import {
  PlusCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { MainWrapper } from 'src/components/layouts/ContentLayout';
import {
  inviteServices as inviteServicesAction,
} from 'src/redux/actions';
import { Text } from 'src/components/styles';

import * as Style from './styles';
import history from 'src/utils/history';

interface IServiceRegistrationProps extends StateProps, DispatchProps {
  inviteServices(params): void;
  isDisableRequest: boolean;
  invitationServices: {
    errors: {
      email: string,
      message: string,
    }[],
  };
  activeTabKey: string
}

const ServiceRegistration: React.FC<IServiceRegistrationProps> = ({
  inviteServices,
  invitationServices,
  isDisableRequest,
  activeTabKey
}) => {
  const { errors } = invitationServices;
  const [invitationServiceForm] = Form.useForm();
  const [errorIndexField, setErrorIndexField] = useState<number>(-1);

  const scrollRef = useRef<any>(null);

  useEffect(() => {
    history.replace({
      state: {
        key: activeTabKey
      }
    })
  }, [activeTabKey])

  useEffect(() => {
    if (errors && errors.length >= 1) {
      errors.map((error) => {
        const emailList = invitationServiceForm.getFieldsValue().emails;
        const invalidInputIndex = emailList.map((item, index) =>
          item.toLowerCase() === error.email.toLowerCase() ? index : '').filter(String);
        // filter string will remove '' and leave only index behind ^^
        return invalidInputIndex.map((inputIndex, index) => {
          if (index === 0) {
            setErrorIndexField(inputIndex);
          }

          invitationServiceForm.setFields([
            {
              name: ['emails', inputIndex],
              errors: [error.message],
            },
          ]);
        });
      });
    }
  }, [errors]);

  useEffect(() => {
    if (errorIndexField >= 0) scrollRef.current.scrollIntoView();
  }, [errorIndexField]);

  const initialFormValues = {
    emails: [''],
  };

  function handleSubmitForm(values) {
    const duplicatedInputsIndex = values.emails.map((item, index) => {
      if (values.emails.filter((i) => i.toLowerCase() === item.toLowerCase()).length > 1) {
        return index;
      } else {
        return '';
      }
    }).filter(String);
    duplicatedInputsIndex.map((inputIndex, index) => {
      if (index === 0) {
        setErrorIndexField(inputIndex);
      }

      invitationServiceForm.setFields([
        {
          name: ['emails', inputIndex],
          errors: ['メールアドレスが重複しています。'],
        },
      ]);
    });
    if (duplicatedInputsIndex.length < 1) {
      inviteServices(values);
      setErrorIndexField(-1);
    };
    if (duplicatedInputsIndex.length >= 1 && errorIndexField >= 0) {
      scrollRef.current.scrollIntoView();
    }
  }

  return (
    <MainWrapper title="新規登録" style={{ marginTop: 24 }}>
      <Style.TabWrapperContent>
        <Style.FormWrapper
          form={invitationServiceForm}
          name="loginForm"
          onFinish={(values) => handleSubmitForm(values)}
          initialValues={initialFormValues}
          scrollToFirstError={true}
        >
          <Text>・サービス管理者としての登録になります</Text>
          <Text style={{ marginBottom: 30 }}>・skillupai.comのドメインのみ登録できます。</Text>
          <Form.List name="emails">
            {(fields, { add, remove }) => (
              <div>
                {fields.map((field, index) => (
                  <Style.DynamicInputField key={field.key}>
                    {index === errorIndexField && <div ref={scrollRef} />}
                    <Form.Item
                      required={index === 0}
                      key={field.key}
                      label={index === 0 ? 'メールアドレス' : ' '}
                      colon={false}
                    >
                      <Space size={0}>
                        <div>
                          <Form.Item
                            {...field}
                            normalize={(value) => value.trim()}
                            validateTrigger={['onChange', 'onBlur']}
                            validateFirst
                            rules={[
                              { required: true, message: 'メールアドレスは必須項目です。' },
                              { type: 'email', message: 'メールアドレスのフォーマットが正しくありません。' },
                              { max: 50, message: 'メールアドレスが長すぎます。（最大は50桁です）' },
                            ]}
                            noStyle
                          >
                            <Input style={{ width: 350 }} />
                          </Form.Item>
                        </div>
                        {fields.length > 1 ? (
                          <Button
                            type="link"
                            ghost
                            onClick={() => remove(field.name)}
                            icon={<CloseCircleOutlined style={{ fontSize: 20, color: '#d25109' }} />}
                          />
                        ) : null}
                      </Space>
                    </Form.Item>
                  </Style.DynamicInputField>
                ))}
                <Space size={0}>
                  <Style.FormItemLabel />
                  <Button
                    type="link"
                    size="small"
                    icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
                    onClick={() => add()}
                    style={{ padding: 0, marginLeft: 125 }}
                  >
                    新規追加
                  </Button>
                </Space>
              </div>
            )}
          </Form.List>
          <Style.GroupAction>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isDisableRequest}
            >
              登録
            </Button>
          </Style.GroupAction>
        </Style.FormWrapper>
      </Style.TabWrapperContent>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => {
  const { invitationServices } = state.accountReducer;
  return {
    invitationServices,
  };
};

const mapDispatchToProps = (dispatch) => ({
  inviteServices: (params) => dispatch(inviteServicesAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRegistration);
