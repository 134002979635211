// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { MODAL_ACTION, REQUEST } from '../constant';

export function showModal(params) {
  return {
    type: REQUEST(MODAL_ACTION.SHOW),
    payload: params,
  };
}

export function closeModal() {
  return {
    type: REQUEST(MODAL_ACTION.CLOSE),
  };
}
