// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from '../../constant';
import moment from 'moment';

export function getUploadAnswerList(params) {
  const newParams = {
    createdAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    createdAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    statusEq: params?.status || '',
    testTypeEq: params?.testType || '',
    jituNameCont: (params.jituNameCont || '').trim(),
    courseIdEq: params?.courseId || '',
    companyIdEq: params?.companyId || '',
    page: params?.page,
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getUploadAnswerDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function scoreUploadAnswer(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.SCORE_UPLOAD_ANSWER),
    payload: params,
  };
}
