// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const CourseContentTitle = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

export const CollapseHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Fix onClick call API collapse header */
  margin: 0 -40px 0 -16px;
  padding: 0 40px 0 16px;
  width: calc(100% + 56px);
  height: 50px;
`;

export const CollapseIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  display: flex;
  transform: translate(0, -50%);
`;

export const UnitItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
`;

export const UnitItemName = styled.div`
  display: flex;
  align-items: center;
`;

export const UnitAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > button {
    min-width: 76px;
  }
`;

export const ItemTypeIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 26px;
  width: 24px;
  height: 24px;
`;

export const NoUnitItemData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
`;

export const NoChapterData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: #FAFAFA;
`;
