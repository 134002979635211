// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Button,
  Collapse,
  Spin,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import NoData from 'src/components/layouts/NoData';
import { Text } from 'src/components/styles/Texts';

import {
  SURVEY_TYPE,
  TEST_TYPE,
  SURVEY_TYPE_URL,
  TEST_TYPE_URL,
} from 'src/constants/common';

import * as Style from './styles';

interface ISurveyAndTestProps {
  courseDetail: {
    data: {
      id: number;
      surveys: {
        id: number;
        item: {
          surveyType: string;
        };
        unit: {
          id: number;
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        item: {
          testType: string;
          questionsCount: number;
        };
        unit: {
          id: number;
          name: string;
          category: {
            name: string;
          };
        };
      }[];
    };
    load: boolean;
  };
}

const SurveyAndTest: React.FC<ISurveyAndTestProps> = ({
  courseDetail,
}) => {
  const { surveys } = courseDetail.data;

  function renderSurveyItems() {
    if (!courseDetail.load && isEmpty(surveys)) return null;
    return surveys.map((survey, unitIndex) => (
      <Style.CollapseItemContainer key={`survey-${survey.id}`}>
        <Style.CollapseItemContent width="20%">
          <Text lg w6>
            {SURVEY_TYPE[survey.item.surveyType]}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="70%">
          <Text
            lg
            truncate
            style={{ width: 'calc(100% - 24px)' }}
          >
            {survey.unit.name}
          </Text>
        </Style.CollapseItemContent>
        <Style.UnitAction style={{ width: '10%' }}>
          <Button
            size="small"
            type="primary"
            onClick={() => window.open(
              `/admin-service/view-course/${courseDetail.data.id}/${SURVEY_TYPE_URL[survey.item.surveyType]}/${survey.unit.id}`,
              '_blank',
            )}
          >
            プレビュー
          </Button>
        </Style.UnitAction>
      </Style.CollapseItemContainer>
    ));
  }

  function renderTestItems() {
    if (!courseDetail.load && isEmpty(courseDetail.data.tests)) return null;
    return courseDetail.data.tests.map((test, unitIndex) => (
      <Style.CollapseItemContainer key={`test-${test.id}`}>
        <Style.CollapseItemContent width="20%">
          <Text lg w6>
            {TEST_TYPE[test.item.testType]}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="55%">
          <Text
            lg
            isPreWrap
            style={{ width: 'calc(100% - 24px)' }}
          >
            {test.unit.name}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="15%">
          <Text lg truncate>
            {`${test.item.questionsCount}問`}
          </Text>
        </Style.CollapseItemContent>
        <Style.UnitAction style={{ width: '10%' }}>
          <Button
            size="small"
            type="primary"
            onClick={() => window.open(
              `/admin-service/view-course/${courseDetail.data.id}/${TEST_TYPE_URL[test.item.testType]}/${test.id}`,
              '_blank',
            )}
          >
            プレビュー
          </Button>
        </Style.UnitAction>
      </Style.CollapseItemContainer>
    ));
  }

  return (
    <>
      <Style.CourseItemsContainer>
        <Text xxxl style={{ marginBottom: 16 }}>アンケート</Text>
        <Spin spinning={courseDetail.load}>
          <Collapse
            bordered={false}
            defaultActiveKey={isEmpty(surveys) ? ['noData'] : []}
            expandIconPosition="right"
            expandIcon={
              ({ isActive }) => isActive ? <MinusOutlined style={{ color: 'white' }} /> : <PlusOutlined style={{ color: 'white' }} />
            }
            style={{ backgroundColor: '#0D122D', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <Collapse.Panel
              key={!isEmpty(surveys) ? 'surveys' : 'noData'}
              header={
                <Style.CollapseHeader>
                  <div style={{ width: '20%' }}>
                    <Text white lg w6>アンケート種別</Text>
                  </div>
                  <div style={{ width: '35%' }}>
                    <Text white lg w6>アンケート名</Text>
                  </div>
                </Style.CollapseHeader>
              }
            >
              {courseDetail.load || !isEmpty(surveys)
                ? renderSurveyItems()
                : <NoData message="データが存在しません。" />
              }
            </Collapse.Panel>
          </Collapse>
        </Spin>
      </Style.CourseItemsContainer>
      <Style.CourseItemsContainer>
        <Text xxxl style={{ marginBottom: 16 }}>テスト</Text>
        <Spin spinning={courseDetail.load}>
          <Collapse
            bordered={false}
            defaultActiveKey={isEmpty(courseDetail.data.tests) ? ['noData'] : []}
            expandIconPosition="right"
            expandIcon={
              ({ isActive }) => isActive ? <MinusOutlined style={{ color: 'white' }} /> : <PlusOutlined style={{ color: 'white' }} />
            }
            style={{ backgroundColor: '#0D122D', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <Collapse.Panel
              key={!isEmpty(courseDetail.data.tests) ? 'tests' : 'noData'}
              header={
                <Style.CollapseHeader>
                  <div style={{ width: '20%' }}>
                    <Text white lg w6>テスト種別</Text>
                  </div>
                  <div style={{ width: '55%' }}>
                    <Text white lg w6>レッスン名</Text>
                  </div>
                  <div style={{ width: '15%' }}>
                    <Text white lg w6>問題数</Text>
                  </div>
                </Style.CollapseHeader>
              }
            >
              {courseDetail.load || !isEmpty(courseDetail.data.tests)
                ? renderTestItems()
                : <NoData message="データが存在しません。" />
              }
            </Collapse.Panel>
          </Collapse>
        </Spin>
      </Style.CourseItemsContainer>
    </>
  );
};

export default SurveyAndTest;
