// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
} from 'antd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import {
  updatePlan as updatePlanAction,
  createPlan as createPlanAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModifyPlansModalProps {
  updatePlan(params): void;
  createPlan(params): void;
  modalData: {
    plan: {
      id: number;
      name: string;
      description: string,
    },
  };
}

const ModifyPlansModal: React.FC<IModifyPlansModalProps> = ({
  updatePlan,
  createPlan,
  modalData,
}) => {

  const [modifyPlanForm] = Form.useForm();
  const planId = modalData.plan?.id;

  const planInitialFormValues = {
    name: modalData.plan?.name,
    description: modalData.plan?.description,
  };

  function handleSubmitForm(values) {
    const requestData = {
      plan: {
        categoryId: null,
        name: values.name?.trim(),
        description: (values.description || '').trim(),
      },
    };
    if (planId) {
      return updatePlan({ requestData, id: planId });
    } else {
      return createPlan(requestData);
    }
  }
  return (
    <Form
      form={modifyPlanForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      name="modifyPlanForm"
      {...modalData.plan.id && { initialValues: planInitialFormValues }}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.PlanFieldContent>
        <Form.Item
          label="プラン名"
          name="name"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { required: true, message: 'プラン名は必須項目です。' },
            { whitespace: true, message: 'プラン名は必須項目です。' },
            { max: 25, message: 'プラン名が長すぎます。（最大は25桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="プラン説明"
          name="description"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { max: 125, message: 'プラン説明が長すぎます。（最大は125桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
      </Style.PlanFieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => ({
  updatePlan: (params) => dispatch(updatePlanAction(params)),
  createPlan: (params) => dispatch(createPlanAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyPlansModal);
