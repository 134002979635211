// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Progress, Space, Table, Button} from 'antd';
import moment from 'moment';

import { isNumber, isEmpty } from 'lodash/lang';

import { convertSecondTime } from 'src/utils/dateTime';
import { LoginStorage } from 'src/utils/localStorge';
import history from 'src/utils/history';

import SortableHeader from 'src/components/tables/SortableHeader';
import { PaginationLayout } from 'src/components/layouts/Pagination';

import {
  getCompanyCourseStudents as getCompanyCourseStudentsAction,
  showModal as showModalAction,
  downloadCsvStudyStudentByAdminCompany as downloadCsvStudyStudentByAdminCompanyAction,
  closeModal as closeModalAction
} from "src/redux/actions";

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';

import * as Style from './styles';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

interface IStudentListPageProps extends StateProps, DispatchProps {
  getCompanyCourseStudents(params): void;
  showModal(params): void;
  closeModal(): void;
  courseId: string | undefined;
  tabIndexSelected: string;
  courseStudents: {
    data: {
      id: number;
      jituName: string;
      email: string;
      coursesPercentage: number;
      totalLearningTime: number;
      progressGap: number;
      startDate: string;
      endDate: string;
      lastLearningDate: string;
      registerStatus: string;
    }[],
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const StudentListPage: React.FC<IStudentListPageProps> = ({
  showModal,
  getCompanyCourseStudents,
  downloadCsvStudyStudent,
  closeModal,
  courseId,
  courseStudents,
  tabIndexSelected,
}) => {
  const { data, meta } = courseStudents;
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const [sortCondition, setSortCondition] = useState<object>({});
  const userStorage = LoginStorage.getData();


  useEffect(() => {
    if (sortConditionUrl) {
      setSortCondition(sortConditionUrl)
    }
  }, []);

  useEffect(() => {
    if (tabIndexSelected === "2") {
      history.replace({
        state: {
          activeTabKey: tabIndexSelected,
          sortCondition,
          page: meta.pageInfo,
          isSort: true,
        }
      })
    }
  }, [tabIndexSelected, sortCondition, meta.pageInfo])

  useEffect(() => {
    setSortCondition(sortConditionUrl)
  }, [tabIndexSelected])

  useEffect(() => {
    if (tabIndexSelected === '2') {
      getCompanyCourseStudents({ id: courseId, requestData: { page: 1,...pageUrl, items: pageUrl?.limit, sort: sortConditionUrl } });
    }
  }, [tabIndexSelected]);

  const tableData = data.map((row: any) => ({
    key: row.id,
    jituName: row.jituName,
    email: row.email,
    coursesPercentage: row.coursesPercentage,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'H時間m分'),
    lastLearningDate: row.lastLearningDate ? moment(row.lastLearningDate).format(DATE_FORMAT) : '',
    startDate: moment(row.startDate).format(DATE_FORMAT),
    endDate: moment(row.endDate).format(DATE_FORMAT),
    progressGap: `${isNumber(row.progressGap) ? `${row.progressGap}%` : 'ー'}`,
    status: row.status,
  }));

  const columns = [
    {
      title:
        <SortableHeader
          type="jituName"
          title="名前"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'jituName',
      render: (_, record) => (
        <Style.StudentName>
          <TooltipParagraph isPreWrap rows={2} {...(record.status === 'banned' && { style: { marginTop: 14 } } )}>
            {record.jituName}
          </TooltipParagraph>
          {record.status === 'banned' && (
            <Style.StudentStatus>停止</Style.StudentStatus>
          )}
        </Style.StudentName>
      ),
      width: 200,
    },
    {
      title:
        <SortableHeader
          type="email"
          title="メールアドレス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'email',
      width: 250,
    },
    {
      title:
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="coursesPercentage"
          title="進捗率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'coursesPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.coursesPercentage}%</Text>
          <Progress
            showInfo={false}
            strokeWidth={16}
            percent={record.coursesPercentage}
          />
        </Space>
      ),
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="progressGap"
          title="進捗ギャップ"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'progressGap',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'lastLearningDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="startDate"
          title="開始日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'startDate',
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="endDate"
          title="終了日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyCourseStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'endDate',
      width: 130,
    },
  ];

  const handleShowModalConfirmExportCsv = () => {
    const modalProps = {
      modalData: {
        title: "CSV出力",
        message: "ユーザー毎の受講履歴をCSVで出力しますか？",
        okText: "出力する",
        cancelText: "キャンセル"
      },
      modalAction: {
        confirm: () => {
          downloadCsvStudyStudent({ courseId, closeModal: () => closeModal() });
        },
      },
      isConfirm: true,
      spaceEvenly: true,
      isButtonLoading: true,
      styleContent: {
        paddingTop: "60px"
      }
    };
    showModal(<ConfirmModal {...modalProps} />);
  };

  return (
    <Style.StudentTableContainer>
       {!isEmpty(courseStudents.data) && (
        <Style.ButtonExportCsvWrapper>
          <Button
            size="small"
            type="primary"
            onClick={handleShowModalConfirmExportCsv}
            disabled={courseStudents.load}
          >
            CSV出力
          </Button>
        </Style.ButtonExportCsvWrapper>
      )}
      <Table
        className="table-cursor-pointer"
        pagination={false}
        scroll={{ x: 1310, y: 506 }}
        columns={columns}
        dataSource={tableData}
        onRow={(record) => ({
          onClick: () => {
            const urlProfile = userStorage.user.id === record.key ? 'my-profile' : `profile/${record.key}`;
            history.push(`/admin/${urlProfile}`);
          }
        })}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={courseStudents.load}
      />
      {!isEmpty(courseStudents.data) && (
        <PaginationLayout
          pageInfo={meta.pageInfo}
          onChangePage={getCompanyCourseStudents}
          sortCondition={sortCondition}
          dataParam={{
            id: courseId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
        />
      )}
    </Style.StudentTableContainer>
  );
};


const mapStateToProps = (state) => {
  const { courseStudents } = state.companyCourseReducer;
  return {
    courseStudents,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyCourseStudents: (params) => dispatch(getCompanyCourseStudentsAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  downloadCsvStudyStudent: (params) => dispatch(downloadCsvStudyStudentByAdminCompanyAction(params))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(StudentListPage);
