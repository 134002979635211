// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Row, Col, Radio } from 'antd';

export const CourseOverviewContainer = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
`;

export const CourseOverviewContent = styled.div`
  padding: 24px;
  background-color: white;
`;

export const CourseTotalDetail = styled.div`
  padding: 8px 16px;
  width: 100%;
  background: ${(props) => props.theme.colors.secondaryBorder};
`;

export const CourseOverviewFooter = styled.div`
  padding: 24px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: white;
`;

export const CourseOverviewAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FormRow = styled(Row)`
  margin-bottom: 24px;
`;

export const FormCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const DynamicInputField = styled.div`
  display: flex;
`;

export const FieldRemoveAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
  min-width: 28px;
  height: 38px;
`;

export const BlockAddLogo = styled.div`
  width: 560px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px dotted #cccccc;

  ${({isDragActive}) =>
    isDragActive &&
    css`
      border: 2px dotted blue;
    `}
`;

export const UploadImage = styled.img`
  width: 62px;
  height: 79px;
  margin-top: 60px;
  margin-bottom: 20px;
`;

export const OptionGroup = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

export const ImageLogoPreview = styled.img`
  margin-top: 60px;
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  object-fit: scale-down;
`;
