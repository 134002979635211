// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { sumBy } from 'lodash/math';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import { convertSecondTime } from 'src/utils/dateTime';

import CourseOverview from './components/CourseOverview';
import CourseContent from './components/CourseContent';
import SurveyList from './components/SurveyList';
import TestList from './components/TestList';
import CourseStatus from './components/CourseStatus';
import CourseOverviewMobile from './components/CourseOverviewMobile';

import {
  getStudentCourseDetail as getStudentCourseDetailAction,
  getStudentTopGroupDetail as getStudentTopGroupDetailAction,
  getStudentCourseDetailStatus as getStudentCourseDetailStatusAction,
  getTestResults as getTestResultsAction,
  closeModal as closeModalAction,
  getRecommendDate as getRecommendDateAction,
  finishedSurvey as finishedSurveyAction,
} from 'src/redux/actions';

import { PAGE_TITLE, StudentTitle } from 'src/constants/pageTitle';

import { Text } from 'src/components/styles';

import * as Style from './styles';

interface ICourseDetailPageProps extends StateProps, DispatchProps {
  getStudentCourseDetail(params): void;
  getStudentTopGroupDetail(params): void;
  getStudentCourseDetailStatus(params): void;
  getTestResults(params): void;
  getRecommendDate(params): void;
  closeModal(): void;
  finishedSurvey(params): void;
  match: {
    params: {
      id?: string;
    };
  };
  courseTopDetail: {
    data: {
      id: number
      name: string;
      courseCategory: {
        name: string;
      };
      overview: string;
      startDate: string;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      remainingDays: number;
      studentProgressPercentage: number;
      recommendedStartDate: string;
      recommendedEndDate: string;
      isDisplayLogo: boolean;
      logo: {
        logoName: string;
        logoUrl: string;
      };
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      totalVideosDuration: number;
      surveys: {
        id: number;
        item: {
          url: string;
          name: string;
        };
        unit: {
          id: number;
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        item: {
          id: number;
          name: string;
          questionsCount: number;
          testType: string;
          testHistories: {
            completionStatus: string;
            testScorePercentage: number;
            createdAt: string;
            score: number;
          }[];
        };
        unit: {
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      groups: {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          totalUnits: number;
          totalVideosTime: number;
        }[];
      }[];
    };
    load: boolean;
  };
  courseTopDetailStatus: {
    data: {
      avgLearningTime: number;
      avgLearningDays: number;
      studentProgressPercentage: number | null;
      avgTestScorePercentage: {
        finishTest: number | null;
        preTest: number | null;
        verifyTest: number | null;
      },
      latestUnitVersion: {
        unitVersion: {
          id: number;
          item: {
            type: string;
            thumbnailUrl: string;
          };
          unit: {
            name: string;
          }
        };
        course: {
          chapterId: number;
          groupId: number;
          id: number;
          name: string;
        }
      }
    };
    load: boolean;
  };
  recommendDates : {
    data: {
      groupRecommendedDate: {
        groupId: number;
        endDate: string;
        isDelayed: boolean;
      }[]
    },
  }
}

const CourseDetailPage: React.FC<ICourseDetailPageProps> = ({
  getStudentCourseDetail,
  getStudentTopGroupDetail,
  getStudentCourseDetailStatus,
  getTestResults,
  finishedSurvey,
  match,
  courseTopDetail,
  courseTopDetailStatus,
  closeModal,
  getRecommendDate,
  recommendDates,
}) => {
  const [backgroundHeight, setBackgroundHeight] = useState<number>(200);
  const courseOverviewElement = useRef(null);
  const courseId = match.params.id;

  useEffect(() => {
    document.title = PAGE_TITLE(StudentTitle.CourseDetail);

    return () => closeModal()
  }, []);

  useEffect(() => {
    if (courseId && parseFloat(courseId) !== courseTopDetail.data.id) {
      getStudentCourseDetail({ id: courseId });
    }
    getStudentCourseDetailStatus({ id: courseId });
    getRecommendDate({ courseId: courseId })
  }, [courseId]);

  useEffect(() => {
    const courseOverviewHeight = (courseOverviewElement.current as any)?.offsetHeight;
    setBackgroundHeight(200 + (courseOverviewHeight - 156));
  }, [courseTopDetail.data.id]);

  return (
    <MainContainer isStudent isCourseDetailStudent backgroundHeight={backgroundHeight} isResponsive>
      <MainContent fullView>
        <CourseOverview
          courseTopDetail={courseTopDetail}
          courseOverviewElement={courseOverviewElement}
        />
        <CourseOverviewMobile courseTopDetail={courseTopDetail} courseTopDetailStatus={courseTopDetailStatus} />
        <CourseStatus courseTopDetailStatus={courseTopDetailStatus} />
        <SurveyList
          courseId={courseTopDetail.data.id}
          surveyData={courseTopDetail.data.surveys}
          loading={courseTopDetail.load}
          finishedSurvey={finishedSurvey}
        />
        <TestList
          courseId={courseId}
          testsData={courseTopDetail.data.tests}
          loading={courseTopDetail.load}
          getTestResults={getTestResults}
        />
        <CourseContent
          courseDetail={courseTopDetail}
          getGroupDetail={getStudentTopGroupDetail}
          recommendDates={recommendDates}
        />
        <Style.OverallInfoField>
          <div style={{ width: '35%' }}/>
          <div style={{ width: '10%' }}><Text secondaryText lg w6 margin="0 50px 0 0">合計</Text></div>
          <div style={{ width: '15%' }}>
            <Text lg>{`${sumBy(courseTopDetail.data.groups, (group) => group.totalUnits)}レッスン`}</Text>
          </div>
          <div style={{ width: '15%' }}>
            <Text lg>
              {convertSecondTime(
                sumBy(courseTopDetail.data.groups, (group) => group.totalVideosTime),
                'HH:mm:ss',
              )}
            </Text>
          </div>
          <div style={{ width: '25%' }}/>
        </Style.OverallInfoField>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const {
    courseTopDetail,
    courseTopDetailStatus,
    recommendDates
  } = state.studentCourseReducer;
  return {
    courseTopDetail,
    courseTopDetailStatus,
    recommendDates
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentCourseDetail: (params) => dispatch(getStudentCourseDetailAction(params)),
  getStudentTopGroupDetail: (params) => dispatch(getStudentTopGroupDetailAction(params)),
  getStudentCourseDetailStatus: (params) => dispatch(getStudentCourseDetailStatusAction(params)),
  getTestResults: (params) => dispatch(getTestResultsAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  getRecommendDate: (params) => dispatch(getRecommendDateAction(params)),
  finishedSurvey: (params) => dispatch(finishedSurveyAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailPage);
