// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';

export function getCourseList(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LIST),
    payload: params,
  };
}

export function getCourseVersions(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_VERSIONS),
    payload: params,
  };
}

export function getCourseDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function clearCourseDetail() {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_COURSE_DETAIL),
  }
}

export function clearDataLearningTab() {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_LEARNING_TAB),
  }
}

export function clearCourseOverview() {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_COURSE_OVERVIEW),
  }
}

export function getCourseCompanyInfo(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_COMPANY_INFO),
    payload: params,
  }
}

export function getGroupDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP),
    payload: params,
  };
}

export function clearOverviewSidebar() {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_OVERVIEW_SIDEBAR),
  };
}

export function getLearningStatus(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_STATUS),
    payload: params,
  };
}

export function getLearningChart(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_CHART),
    payload: params,
  };
}

export function getLearningUserProgress(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_LEARNING_USER_PROGRESS),
    payload: params,
  };
}

// learning tab api
export function getAttendanceStatus(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_STATUS),
    payload: params,
  };
}

export function getCourseStudents(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_STUDENT_LIST),
    payload: params,
  };
}

export function updateStudentCoursesDuration(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_STUDENT_COURSES_DURATION),
    payload: params,
  };
}

export function getCourseChart(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_ATTENDANCE_CHART),
    payload: params,
  };
}

// Test score tab
export function getTestScoreOverview(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW),
    payload: params,
  };
}

export function getTestScoreChart(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_CHART),
    payload: params,
  };
}

export function getTestScoreStudents(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_TEST_SCORE_STUDENTS),
    payload: params,
  };
}

// progress notification setting tab
export function updateProgressNotificationSetting(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_PROGRESS_NOTIFICATION_SETTING),
    payload: params,
  };
}

// Course Action
export function createCourse(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE),
    payload,
  };
}
export function updateCourse(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE),
    payload,
  };
}

export function deleteCourse(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE),
    payload,
  };
}
export function copyCourse(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.COPY_COURSE),
    payload,
  };
}

// Course Unit
export function createCourseUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_UNIT),
    payload,
  };
}
export function updateCourseUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_UNIT),
    payload,
  };
}
export function deleteCourseUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_UNIT),
    payload,
  };
}

// Course Group
export function createCourseGroup(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_GROUP),
    payload,
  };
}
export function updateCourseGroup(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_GROUP),
    payload,
  };
}
export function deleteCourseGroup(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_COURSE_GROUP),
    payload,
  };
}

// Group Chapter
export function createGroupChapter(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_GROUP_CHAPTER),
    payload,
  };
}
export function updateGroupChapter(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_GROUP_CHAPTER),
    payload,
  };
}
export function deleteGroupChapter(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_GROUP_CHAPTER),
    payload,
  };
}

// Chapter Unit
export function createChapterUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_UNIT),
    payload,
  };
}
export function updateChapterUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_CHAPTER_UNIT),
    payload,
  };
}
export function deleteChapterUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_UNIT),
    payload,
  };
}

//  FAQ to Chapter
export function getChapterFAQs(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_CHAPTER_FAQS),
    payload,
  };
}

export function createChapterFAQ(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_CHAPTER_FAQ),
    payload,
  };
}

export function deleteChapterFAQ(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DELETE_CHAPTER_FAQ),
    payload,
  };
}

export function moveChapterFAQ(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.MOVE_CHAPTER_FAQ),
    payload,
  };
}

export function getCourseFAQs(params) {
  const newParams = {
    nameCont: (params.nameCont || '').trim(),
    page: params?.page,
    ...params.more && { more: params.more },
  };

  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSE_FAQS),
    payload: newParams,
  };
}

// Courses by company
export function getCoursesByCompany(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY),
    payload,
  };
}

export function clearCourseDetailErrors() {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CLEAR_COURSE_DETAIL_ERRORS),
  };
}

export function getOverviewGroupDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_GROUP_DETAIL),
    payload: params,
  };
}

export function getMasterDataCourse(params) {
  const newParams = {
    courseCategoryNameCont: params?.nameCont || '',
    companyIdEq: params?.companyId || '',
    courseCategoryIdEq: params?.courseCategoryId || '',
    page: params?.page,
    ...params?.more && { more: params?.more },
  };
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSES),
    payload: newParams,
  };
}

export function getMasterDataCourseList(params) {
  const newParams = {
    ...params,
    nameCont: (params.nameCont || '').trim(),
    page: params?.page,
  };
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_MASTER_DATA_COURSE_LIST),
    payload: newParams,
  };
}

export function getOverviewCourseContent(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_OVERVIEW_COURSE_CONTENT),
    payload: params,
  };
}

// Change course content position

export function changeGroupPosition(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CHANGE_GROUP_POSITION),
    payload: params,
  };
}

export function changeChapterPosition(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CHANGE_CHAPTER_POSITION),
    payload: params,
  };
}

export function changeUnitPosition(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CHANGE_UNIT_POSITION),
    payload: params,
  };
}

// Course categories/version

export function getVersionsOfCourse(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_VERSIONS_OF_COURSE),
    payload: params,
  };
}

export function createCourseVersion(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.CREATE_COURSE_VERSION),
    payload: params,
  };
}

export function updateCourseName(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_COURSE_NAME),
    payload: params,
  };
}

export function downloadCsvStudyStudent(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT),
    payload: params,
  };
}

export function getGroupRecommendedDate(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_GROUP_RECOMMENDED_DATE),
    payload: params,
  }
}

export function downloadCsvTestStudent(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.DOWNLOAD_CSV_TESTS),
    payload: params,
  };
}

export function updatePositionCourse(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.UPDATE_POSITION_COURSE),
    payload,
  };
}
