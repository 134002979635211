//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';

import history from 'src/utils/history';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import UserRegistration from './components/UserRegistration';
import UploadRegistration from './components/UploadRegistration';
import ServiceRegistration from './components/ServiceRegistration';
import CompanyRegistration from './components/CompanyRegistration';
import RegistrationStatus from './components/RegistrationStatus';
import RegistrationAccountList from './components/RegistrationAccountList';
import CompanyAccountRegistration from './components/CompanyAccountRegistration';

import { Text } from 'src/components/styles';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import * as Style from './styles';
import { connect } from 'react-redux';

interface IManagerAccountPageProps extends StateToProps {
  isDisableRequest: boolean
}

const ManagerAccountPage: React.FC<IManagerAccountPageProps> = ({ isDisableRequest }) => {
  const urlActiveKey = history.location.state?.key;
  const [activeTabKey, setActiveTabKey] = useState<string>(urlActiveKey || '1');

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.ManagerAccount);
  }, []);

  window.addEventListener("beforeunload", function () {
    history.replace({
      state: {
        key: activeTabKey
      }}
    )
  })

  useEffect(() => {
    if (urlActiveKey) setActiveTabKey(urlActiveKey);
  }, [urlActiveKey]);

  return (
    <MainContainer isTabs>
      <MainContent fullView>
        <Style.ManagerAccountTitle>
          <Text xxxl w6>アカウント登録</Text>
        </Style.ManagerAccountTitle>
        <Tabs
          activeKey={activeTabKey}
          type="card"
          onChange={(activeKey) => {
            history.replace({
              state: {
                key: activeKey,
              }
            })
            setActiveTabKey(activeKey)
          }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>受講者登録</Text>}
            key="1"
          >
            <UserRegistration isDisableRequest={isDisableRequest} activeTabKey={activeTabKey}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>企業管理者登録</Text>}
            key="3"
          >
            <CompanyAccountRegistration isDisableRequest={isDisableRequest} activeTabKey={activeTabKey}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>サービス管理者登録</Text>}
            key="4"
          >
            <ServiceRegistration isDisableRequest={isDisableRequest} activeTabKey={activeTabKey}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>企業登録</Text>}
            key="5"
          >
            <CompanyRegistration isDisableRequest={isDisableRequest} activeTabKey={activeTabKey}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>登録状況</Text>}
            key="6"
          >
            <RegistrationStatus activeTabKey={activeTabKey} />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>ユーザ一覧</Text>}
            key="7"
          >
            <RegistrationAccountList activeTabKey={activeTabKey} />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, null)(ManagerAccountPage);