// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { isNumber } from 'lodash/lang';

import { CardWrapper } from 'src/components/layouts/ContentLayout';
import { Text, SkeletonLoading } from 'src/components/styles';

import * as Style from './styles';

interface ITestScoreStatusProps {
  testScoreOverview: {
    data: {
      overview: {
        avgScoreOfAllTest: number | string;
        avgScoreOfFinishTest: number | string;
        avgScoreOfPreTest: number | string;
        avgScoreOfVerifyTest: number | string;
        studentsJoinedTestsCount: number | string;
      };
    };
    load: boolean;
  };
}

const TestScoreStatus: React.FC<ITestScoreStatusProps> = ({
  testScoreOverview,
}) => {
  const { overview } = testScoreOverview.data;
  return (
    <>
      <Text xxxl style={{ margin: '24px 0 16px' }}>テスト受講状況</Text>
        <Style.OverviewContainer>
          <Style.OverviewContent>
            <CardWrapper>
              <Style.OverviewCardContainer>
                <Style.OverviewCard>
                  <Text headerText lg w6 style={{ lineHeight: '48px' }}>受講者数</Text>
                  {testScoreOverview.load
                    ? (
                      <div>
                        <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={34} />
                      </div>
                    )
                    : (
                      <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                        {isNumber(overview?.studentsJoinedTestsCount) ? overview?.studentsJoinedTestsCount : 'ー'}
                      </Text>
                    )
                  }
                </Style.OverviewCard>
              </Style.OverviewCardContainer>
            </CardWrapper>
          </Style.OverviewContent>
          <Style.OverviewContent>
            <CardWrapper>
              <Style.OverviewCardContainer>
                <Style.OverviewCard>
                  <Text headerText lg w6 style={{ lineHeight: '48px' }}>全体正答率</Text>
                  {testScoreOverview.load
                    ? (
                      <div>
                        <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={34} />
                      </div>
                    )
                    : (
                      <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px'}}>
                        {isNumber(overview?.avgScoreOfAllTest) ? `${overview?.avgScoreOfAllTest}%` : 'ー'}
                      </Text>
                    )
                  }
                </Style.OverviewCard>
              </Style.OverviewCardContainer>
            </CardWrapper>
          </Style.OverviewContent>
          {(testScoreOverview.load || overview?.avgScoreOfPreTest !== 'no_test') && (
            <Style.OverviewContent>
              <CardWrapper>
                <Style.OverviewCardContainer>
                  <Style.OverviewCard>
                    <Text headerText lg w6>事前テスト</Text>
                    <Text headerText w6>正答率</Text>
                    {testScoreOverview.load
                      ? (
                        <div>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={34} />
                        </div>
                      )
                      : (
                        <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                          {isNumber(overview?.avgScoreOfPreTest) ? `${overview?.avgScoreOfPreTest}%` : 'ー'}
                        </Text>
                      )
                    }
                  </Style.OverviewCard>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Style.OverviewContent>
          )}
          {(testScoreOverview.load || overview?.avgScoreOfVerifyTest !== 'no_test') && (
            <Style.OverviewContent>
              <CardWrapper>
                <Style.OverviewCardContainer>
                  <Style.OverviewCard>
                    <Text headerText lg w6>確認テスト</Text>
                    <Text headerText w6>正答率</Text>
                    {testScoreOverview.load
                      ? (
                        <div>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={34} />
                        </div>
                      )
                      : (
                        <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                          {isNumber(overview?.avgScoreOfVerifyTest) ? `${overview?.avgScoreOfVerifyTest}%` : 'ー'}
                        </Text>
                      )
                    }
                  </Style.OverviewCard>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Style.OverviewContent>
          )}
          {(testScoreOverview.load || overview?.avgScoreOfFinishTest !== 'no_test') && (
            <Style.OverviewContent>
              <CardWrapper>
                <Style.OverviewCardContainer>
                  <Style.OverviewCard>
                    <Text headerText lg w6>修了テスト</Text>
                    <Text headerText w6>正答率</Text>
                    {testScoreOverview.load
                      ? (
                        <div>
                          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={34} />
                        </div>
                      )
                      : (
                        <Text headerText h3 w6 style={{ fontSize: 34, lineHeight: '51px' }}>
                          {isNumber(overview?.avgScoreOfFinishTest) ? `${overview?.avgScoreOfFinishTest}%` : 'ー'}
                        </Text>
                      )
                    }
                  </Style.OverviewCard>
                </Style.OverviewCardContainer>
              </CardWrapper>
            </Style.OverviewContent>
          )}
        </Style.OverviewContainer>
    </>
  );
};

export default TestScoreStatus;
