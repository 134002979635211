//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  Button,
  Popover,
  Space,
  Tabs,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';

import { LoginStorage } from 'src/utils/localStorge';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text, SkeletonLoading } from 'src/components/styles';

import AccountProfile from './components/AccountProfile';
import OtherProfile from './components/OtherProfile';
import JoinedCourses from './components/JoinedCourses';

import {
  getProfileInfo as getProfileInfoAction,
  updateUserInfo as updateUserInfoAction,
  updateCustomUserInfo as updateCustomUserInfoAction,
  clearUpdateEmailErrors as clearUpdateEmailErrorsAction,
  clearUpdatePasswordErrors as clearUpdatePasswordErrorsAction,
  clearUpdateRoleErrors as clearUpdateRoleErrorsAction,
  clearCompanyUpdateRoleErrors as clearCompanyUpdateRoleErrorsAction,
  showModal as showModalAction,
  updateProfileAvatar as updateProfileAvatarAction,
  getMyTestList as getMyTestListAction,
  closeModal as closeModalAction,
  getMyCourseCompany as getMyCourseCompanyAction,
  updateMyCourseManager as updateMyCourseManagerAction
} from 'src/redux/actions';

import { USER_PROFILE_FIELDS, COMPANY_PROFILE_FIELDS } from './constants';
import { FILE_IMAGE_SIZE } from 'src/constants/common';
import { PAGE_TITLE, MyProfileTitle } from 'src/constants/pageTitle';

import * as Style from './styles';
import TestListStudent from './components/TestListStudent';
import CourseManager from './components/CourseManager';
import history from 'src/utils/history';

interface IMyProfilePageProps extends StateProps, DispatchProps {
  getProfileInfo(params): void;
  updateUserInfo(params): void;
  updateCustomUserInfo(params): void;
  clearUpdateEmailErrors(): void;
  clearUpdatePasswordErrors(): void;
  clearUpdateRoleErrors(): void;
  clearCompanyUpdateRoleErrors(): void;
  showModal(params): void;
  updateProfileAvatar(params): void;
  closeModal(): void;
  getMyTestList(params): void;
  getMyCourseCompany(params): void;
  updateMyCourseManager(params): void;
  role: string;
  profileInfo: {
    data: {
      id: number;
      avatarUrl: string;
      company: {
        id: number;
        name: string;
      };
      department: string;
      email: string;
      jituName: string;
      kanaName: string;
      birthday: string;
      gender: string;
      jobTitle: string;
      jobType: string;
      systemNotificationSetting: string;
      roles: string[];
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      customUserInfos: {
        id: number;
        companyCustomUserInfoId: number;
        fieldValue: string;
      }[];
      customDisplayField: {
        birthday: boolean;
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    load: boolean;
  };
  listTestsStudent: {
    data: {
      id: number;
      name: string;
      test: {
        id: number,
        name: string,
        testType: string
      }
      category: {
        id: number;
        name: string;
      };
      course: {
        id: number;
        name: string
      }
      isDoingUnlimitedTest: boolean;
      usedAttemptsCount: number;
      testScore: number;
      testScorePercentage: number;
      lastTestingDate: string;
      isWaitingTest: boolean;
      unit: {
        id: number,
        name: string,
      };
      unitVersionAble: {
        id: number,
        type: string
      },
      manageTestStatus: string
    }[];
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
  }
  listCourseCompany: {
    data: {
      id: number;
      course: {
        id: number;
        name: string;
        courseCategory: {
          id: number;
          name: string;
        }
      },
      courseActualStatus: string;
      startDate: string;
      endDate: string;
      manageStatus: string
    }[],
    meta: {
      pageInfo: {
        page: number;
        limit: number;
        count: number;
      }
    }
    load: boolean
  }
}

const MyProfilePage: React.FC<IMyProfilePageProps> = ({
  getProfileInfo,
  updateUserInfo,
  updateCustomUserInfo,
  clearUpdateEmailErrors,
  clearUpdatePasswordErrors,
  clearUpdateRoleErrors,
  clearCompanyUpdateRoleErrors,
  showModal,
  updateProfileAvatar,
  role,
  profileInfo,
  listTestsStudent,
  getMyTestList,
  closeModal,
  getMyCourseCompany,
  updateMyCourseManager,
  listCourseCompany
}) => {
  const activeTabKeyUrl = history.location.state?.activeTabKey;

  const [fileImage, setFileImage] = useState<any>(null);
  const [imageError, setImageError] = useState<string>('');
  const [imageURL, setImageURL] = useState<any>(profileInfo.data.avatarUrl);
  const [avatarReviewVisible, setAvatarReviewVisible] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>(activeTabKeyUrl || '1');
  const isStudent = role === 'student';
  const avatarFormData = new FormData();
  const [positionPopover, setPositionPopover] = useState<any>('right');

  const userStorage = LoginStorage.getData();
  const uploadFileButton = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    window.addEventListener('resize', () => {
      setPositionPopover(window.innerWidth < 428 ? 'bottom' : 'right')
    });
    return () => window.removeEventListener('resize', () => {});
  }, [])

  useEffect(() => {
    document.title = PAGE_TITLE(MyProfileTitle);

    if (profileInfo.data?.id !== userStorage.user?.id) {
      getProfileInfo({});
    }

    if (activeTabKeyUrl) {
      setActiveTabKey(activeTabKeyUrl)
    }

    window.addEventListener("beforeunload", function () {
      history.replace()
    })

    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    setAvatarReviewVisible(false)
  }, [activeTabKey])

  useEffect(() => {
    if (!avatarReviewVisible) {
      setFileImage(null);
      setImageError('');
      setImageURL('');
      (document.getElementById('uploadFileField') as HTMLInputElement).value = '';
    }
  }, [avatarReviewVisible]);

  function handleUploadClick() {
    uploadFileButton.current.click();
  }

  function handleImageUpload(e) {
    const imageFile = e.target.files[0];
    setFileImage(imageFile);
    if (!imageFile?.type.includes('image')) {
      setImageError('png、jpg、jpeg、tiff、gif等の画像ファイルをアップロードしてください。');
      setImageURL('');
    } else if (imageFile.size > FILE_IMAGE_SIZE) {
      setImageError('ファイルサイズが大きすぎます。(最大サイズ：5MB）');
      setImageURL('');
    } else {
      setImageError('');
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageURL(reader.result);
      };
      reader.readAsDataURL(imageFile);
    }
  }

  function handleUpdateAvatar() {
    if (fileImage && !imageError) {
      avatarFormData.append('user[avatar]', fileImage, fileImage.name);
      updateProfileAvatar({
        avatarUrl: imageURL,
        requestData: avatarFormData,
      });
      setAvatarReviewVisible(false);
    }
  }

  function renderProfileAvatar() {
    if (profileInfo.load) {
      return (
        <SkeletonLoading.Avatar active size={120} />
      );
    }
    if (profileInfo.data.avatarUrl) {
      return (
        <Style.ProfileAvatar image={profileInfo.data.avatarUrl} />
      );
    } else {
      return (
        <>
          <Style.AvatarDefault
            size={120}
            icon={<UserOutlined style={{ fontSize: 56 }} />}
          />
          <Style.AvatarDefaultMobile
            size={64}
            icon={<UserOutlined style={{ fontSize: 35 }} />}
          />
        </>
      );
    }
  }

  return (
    <MainContainer backgroundHeight={250} isStudent={isStudent} isTabs>
      <MainContent fullView>
        <Style.ProfileInfoContainer>
          <Style.TitlePage white={isStudent} w6 xxl>
            プロフィール
          </Style.TitlePage>
          <Style.ProfileInfoContent>
            {renderProfileAvatar()}
            <Style.ProfileInfoContentBottom>
              {profileInfo.load
                ? (
                  <>
                    <SkeletonLoading active paragraph={false} title={{ width: 150 }} height={22} />
                    <SkeletonLoading active paragraph={false} title={{ width: 50 }} height={16} />
                  </>
                )
                : (
                  <>
                    <Style.TextName white={isStudent} xxxl w6 truncate style={{ fontSize: 22 }}>
                      {profileInfo.data.jituName}
                    </Style.TextName>
                    <Style.TextId white={isStudent} lg>{profileInfo.data.id}</Style.TextId>
                  </>
                )
              }
              <Popover
                placement={positionPopover}
                trigger="click"
                visible={avatarReviewVisible}
                content={
                  <Style.PreviewAvatarContainer>
                    {imageURL || profileInfo.data.avatarUrl
                      ? (
                        <Style.PreviewAvatar
                          image={isEmpty(imageError) && imageURL ? imageURL : profileInfo.data.avatarUrl}
                        />
                      )
                      : (
                        <Avatar
                          className="avatar"
                          size={240}
                          icon={<UserOutlined style={{ fontSize: 102 }} />}
                        />
                      )
                    }
                    {imageError && (
                      <Text margin={'0 0 10px 0'} error>{imageError}</Text>
                    )}
                    <Style.ButtonAvatarWrapper align="center" size="middle">
                      <Style.ButtonAvatar
                        type="primary"
                        ghost
                        onClick={() => handleUploadClick()}
                      >
                        ファイルを選択
                      </Style.ButtonAvatar>
                      <Style.ButtonAvatar
                        type="primary"
                        htmlType="submit"
                        onClick={() => handleUpdateAvatar()}
                        disabled={fileImage === null ? true : false }
                      >
                        保存する
                      </Style.ButtonAvatar>
                      <Style.ButtonAvatar
                        type="primary"
                        className="btn-secondary"
                        onClick={() => setAvatarReviewVisible(false)}
                      >
                        キャンセル
                      </Style.ButtonAvatar>
                    </Style.ButtonAvatarWrapper>
                  </Style.PreviewAvatarContainer>
                }
              >
                <Style.ButtonUploadAvatar
                  size="small"
                  type={isStudent ? 'default' : 'primary'}
                  onClick={() => setAvatarReviewVisible(true)}
                >
                  画像変更
                </Style.ButtonUploadAvatar>
              </Popover>
              <Style.FieldInput
                accept="image/*"
                id="uploadFileField"
                type="file"
                ref={uploadFileButton}
                onChange={(e) => handleImageUpload(e)}
              />
            </Style.ProfileInfoContentBottom>
          </Style.ProfileInfoContent>
        </Style.ProfileInfoContainer>
        <Style.ProfileTabWrapper className={isStudent ? 'student-tabs' : ''}>
          <Tabs
            activeKey={activeTabKey}
            type="card"
            onChange={(activeKey) => {
              history.replace({
                state: {
                  activeTabKey: activeKey,
                  sortCondition: {},
                }
              })
              setActiveTabKey(activeKey)
            }}
            style={{ marginBottom: 60 }}
          >
            <Tabs.TabPane
              tab={<Text xl headerText w6>基本情報</Text>}
              key="1"
            >
              <AccountProfile
                role={role}
                profileInfo={profileInfo}
                clearUpdateEmailErrors={clearUpdateEmailErrors}
                clearUpdatePasswordErrors={clearUpdatePasswordErrors}
                clearUpdateRoleErrors={clearUpdateRoleErrors}
                clearCompanyUpdateRoleErrors={clearCompanyUpdateRoleErrors}
                showModal={showModal}
                avatarReviewVisible={avatarReviewVisible}
                activeTabKey={activeTabKey}
              />
              <OtherProfile
                type="user"
                title="ユーザー情報"
                fieldData={USER_PROFILE_FIELDS}
                profileInfo={profileInfo}
                updateUserInfo={updateUserInfo}
                avatarReviewVisible={avatarReviewVisible}
              />
              <OtherProfile
                type="company"
                title="所属情報"
                fieldData={COMPANY_PROFILE_FIELDS}
                profileInfo={profileInfo}
                updateCustomUserInfo={updateCustomUserInfo}
                avatarReviewVisible={avatarReviewVisible}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<Text xl headerText w6>受講コース</Text>}
              key="2"
            >
              {profileInfo.data.roles?.includes('student')
                ? (
                  <JoinedCourses
                    role={role}
                    showModal={showModal}
                    profileInfo={profileInfo}
                    activeTabKey={activeTabKey}
                  />
                )
                : <Text lg w6 center margin="16px 0">現在受講可能なコースはありません。</Text>
              }
            </Tabs.TabPane>

            {
              role==="student" || (
                <Tabs.TabPane
                  tab = {
                    <Text xl headerText w6>
                      受講テスト
                    </Text>
                  }
                  key = "3">
                    <TestListStudent listTestsStudent={listTestsStudent} getMyTestList={getMyTestList} activeTabKey={activeTabKey} />
                  </Tabs.TabPane>
              )
            }

            {
              role !== "student" && profileInfo.data.roles?.includes('admin_company') && (
                <Tabs.TabPane
                  tab = {
                    <Text xl headerText w6>
                      管理コース
                    </Text>
                  }
                  key = "4">
                    <CourseManager
                      getMyCourseCompany={getMyCourseCompany}
                      updateCourseManager={updateMyCourseManager}
                      listCourseCompany={listCourseCompany}
                      activeTabKey={activeTabKey}
                      />
                  </Tabs.TabPane>
              )
            }
          </Tabs>
        </Style.ProfileTabWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { profileInfo, listTestsStudent, listCourseCompany} = state.myProfileReducer;
  return {
    profileInfo,
    listTestsStudent,
    listCourseCompany
  };
};

const mapDispatchToProps = (dispatch) => ({
  getProfileInfo: (params) => dispatch(getProfileInfoAction(params)),
  updateUserInfo: (params) => dispatch(updateUserInfoAction(params)),
  updateCustomUserInfo: (params) => dispatch(updateCustomUserInfoAction(params)),
  clearUpdateEmailErrors: () => dispatch(clearUpdateEmailErrorsAction()),
  clearUpdatePasswordErrors: () => dispatch(clearUpdatePasswordErrorsAction()),
  clearUpdateRoleErrors: () => dispatch(clearUpdateRoleErrorsAction()),
  clearCompanyUpdateRoleErrors: () => dispatch(clearCompanyUpdateRoleErrorsAction()),
  showModal: (params) => dispatch(showModalAction(params)),
  updateProfileAvatar: (params) => dispatch(updateProfileAvatarAction(params)),
  getMyTestList:(params)=>dispatch(getMyTestListAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  getMyCourseCompany: (params) => dispatch(getMyCourseCompanyAction(params)),
  updateMyCourseManager: (params) => dispatch(updateMyCourseManagerAction(params))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePage);
