// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { findIndex } from 'lodash/array';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';

export function handleNavigateUnit({
  action,
  getStudentGroupDetail,
  setCollapseData,
  courseDetail,
  unitDetail,
  collapseData,
}) {
  const groupId = getSearchParams('group');
  const chapterId = getSearchParams('chapter');

  const { next, back } = unitDetail.meta;
  const newCollapseData = collapseData;
  const groupDataIndex = findIndex(courseDetail.data.groups, { id: parseFloat(groupId as string) });
  const chapterDataIndex = groupDataIndex !== -1
    ? findIndex(courseDetail.data.groups[groupDataIndex]?.chapters, { id: parseFloat(chapterId as string) })
    : -1;
  const groupActiveIndex = groupDataIndex !== -1
    ? findIndex(collapseData, { key: `group-${groupDataIndex}` })
    : -1;
  const chapterActiveIndex = groupActiveIndex !== -1
    ? findIndex(
        collapseData[groupActiveIndex]?.chapters,
        { key: `group-${groupDataIndex}-chapter-${chapterDataIndex}` },
      )
    : -1;
  if (action === 'next') {
    if (!next) return null;
    if (groupId == next?.groupId && chapterId == next?.chapterId ){
      return history.push({
        pathname: `/learn-course/${next?.courseId}/${next?.type}/${next?.unitVersionId}`,
        search: `?group=${next?.groupId}&chapter=${next?.chapterId}`,
      });
    }
    if (chapterDataIndex + 1 === courseDetail.data.groups[groupDataIndex]?.chapters.length) {
      const nextGroupDataIndex = findIndex(courseDetail.data.groups, { id: next?.groupId });
      if (!courseDetail.data.groups[nextGroupDataIndex]?.chapters) {
        getStudentGroupDetail({
          requestData: {
            courseId: next?.courseId,
            groupId: next?.groupId,
          },
          meta: {
            type: 'next',
            groupDataIndex,
            groupActiveIndex,
            collapseData,
          },
        });
      } else {
        const nextGroupActiveIndex = nextGroupDataIndex !== -1
          ? findIndex(collapseData, { key: `group-${nextGroupDataIndex}` })
          : -1;
        const nextChapterActiveIndex = nextGroupActiveIndex !== -1
          ? findIndex(
              collapseData[nextGroupActiveIndex]?.chapters,
              { key: `group-${nextGroupActiveIndex}-chapter-0` },
            )
          : -1;
        if (nextGroupActiveIndex === -1) {
          newCollapseData.splice(groupActiveIndex + 1, 0, {
            key: `group-${groupDataIndex + 1}`,
            chapters: [
              {
                key: `group-${groupDataIndex + 1}-chapter-0`,
              },
            ],
          });
        } else if (nextChapterActiveIndex === -1) {
          newCollapseData[nextGroupActiveIndex].chapters = [
            {
              key: `group-${groupDataIndex + 1}-chapter-0`,
            },
            ...newCollapseData[nextGroupActiveIndex]?.chapters,
          ];
        }
        setCollapseData(newCollapseData);
      }
    } else if (parseFloat(chapterId as string) !== next?.chapterId) {
      newCollapseData[groupActiveIndex]?.chapters.splice(chapterActiveIndex + 1, 0, {
        key: `group-${groupDataIndex}-chapter-${chapterDataIndex + 1}`,
      });
      setCollapseData(newCollapseData);
    }
    return history.push({
      pathname: `/learn-course/${next?.courseId}/${next?.type}/${next?.unitVersionId}`,
      search: `?group=${next?.groupId}&chapter=${next?.chapterId}`,
    });
  } else {
    if (!back) return null;
    if (groupId == back?.groupId && chapterId == back?.chapterId ){
      return history.push({
        pathname: `/learn-course/${back?.courseId}/${back?.type}/${back?.unitVersionId}`,
        search: `?group=${back?.groupId}&chapter=${back?.chapterId}`,
      });
    }
    if (chapterDataIndex === 0) {
      const backGroupDataIndex = findIndex(courseDetail.data.groups, { id: back?.groupId });
      if (!courseDetail.data.groups[backGroupDataIndex]?.chapters) {
        getStudentGroupDetail({
          requestData: {
            courseId: back?.courseId,
            groupId: back?.groupId,
          },
          meta: {
            type: 'back',
            groupDataIndex,
            groupActiveIndex,
            collapseData,
          },
        });
      } else {
        const backChaptersLength = courseDetail.data.groups[backGroupDataIndex]?.chapters.length;
        const backGroupActiveIndex = backGroupDataIndex !== -1
          ? findIndex(collapseData, { key: `group-${backGroupDataIndex}` })
          : -1;
        const backChapterActiveIndex = backGroupActiveIndex !== -1
          ? findIndex(
              collapseData[backGroupActiveIndex]?.chapters,
              { key: `group-${backGroupActiveIndex}-chapter-${backChaptersLength - 1}` },
            )
          : -1;
        if (backGroupActiveIndex === -1) {
          newCollapseData.splice(groupActiveIndex, 0, {
            key: `group-${groupDataIndex - 1}`,
            chapters: [
              {
                key: `group-${groupDataIndex - 1}-chapter-${backChaptersLength - 1}`,
              },
            ],
          });
        } else if (backChapterActiveIndex === -1) {
          newCollapseData[backGroupActiveIndex].chapters = [
            ...newCollapseData[backGroupActiveIndex]?.chapters,
            {
              key: `group-${groupDataIndex - 1}-chapter-${backChaptersLength - 1}`,
            },
          ];
        }
        setCollapseData(newCollapseData);
      }
    } else if (parseFloat(chapterId as string) !== back?.chapterId) {
      newCollapseData[groupActiveIndex]?.chapters.splice(chapterActiveIndex, 0, {
        key: `group-${groupDataIndex}-chapter-${chapterDataIndex - 1}`,
      });
      setCollapseData(newCollapseData);
    }
    return history.push({
      pathname: `/learn-course/${back?.courseId}/${back?.type}/${back?.unitVersionId}`,
      search: `?group=${back?.groupId}&chapter=${back?.chapterId}`,
    });
  }
}
