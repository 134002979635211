// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const ResetPasswordPath = '/reset-password';
export const NotFoundPath = '/not-found';
export const ServerErrorPath = '/server-error';
export const CheckVideoPath = '/check-video';

// Admin Service Path
export const ServicePath = {
  Login: '/admin-service/login',
  ForgotPassword: '/admin-service/forgot-password',
  AdminServiceTop: '/admin-service',
  CourseList: '/admin-service/courses',
  CourseDetail: '/admin-service/course/:id',
  CreateCourse: '/admin-service/course/new',
  CourseView: '/admin-service/view-course/:id',
  VideoPlayer: '/admin-service/view-course/:id/video/:id',
  TestExplain: '/admin-service/view-course/:id/test/:id',
  TestingContent: '/admin-service/view-course/:id/testing/:id',
  SurveyOverview: '/admin-service/view-course/:id/survey/:id',
  TipOverview: '/admin-service/view-course/:id/tip/:id',
  ConfirmTestOverview: '/admin-service/view-course/:id/confirm-test/:id',
  ConfirmTestingForm: '/admin-service/view-course/:id/confirm-testing/:id',
  FinalTestOverview: '/admin-service/view-course/:id/final-test/:id',
  FinalTestingForm: '/admin-service/view-course/:id/final-testing/:id',
  PreTestOverview: '/admin-service/view-course/:id/pre-test/:id',
  PreTestingForm: '/admin-service/view-course/:id/pre-testing/:id',
  PreSurveyDetail: '/admin-service/view-course/:id/pre-survey/:id',
  FinalSurveyDetail: '/admin-service/view-course/:id/final-survey/:id',
  EditCourse: '/admin-service/course/:id/edit',
  ModifyChapterFAQ: '/admin-service/course/chapter/faq',
  UnitList: '/admin-service/lessons',
  CreateUnit: '/admin-service/lesson/new',
  UnitDetail: '/admin-service/lesson/:id', // Detail & Edit
  TestList: '/admin-service/tests',
  CreateTest: '/admin-service/test/new',
  EditTest: '/admin-service/test/:id/edit',
  QuestionList: '/admin-service/questions',
  QuestionDetail: '/admin-service/question/:id',
  VideoList: '/admin-service/videos',
  TipList: '/admin-service/tips',
  EditTip: '/admin-service/tip/:id/edit',
  CreateTip: '/admin-service/tip/new',
  ManagerAccount: '/admin-service/manager-account',
  LicenseGrant: '/admin-service/license-grant',
  LicenseList: '/admin-service/licenses',
  CreateLicense: '/admin-service/license/new',
  EditLicense: '/admin-service/license/:id/edit',
  CompanyList: '/admin-service/companies',
  CompanyDetail: '/admin-service/company-detail',
  SurveyList: '/admin-service/surveys',
  CreateSurvey: '/admin-service/survey/new',
  EditSurvey: '/admin-service/survey/:id/edit',
  FAQList: '/admin-service/faqs',
  CreateFAQ: '/admin-service/faq/new',
  EditFAQ: '/admin-service/faq/:id/edit',
  UploadAnswerList: '/admin-service/upload-answers',
  MasterSetting: '/admin-service/setting',
  ServiceProfile: '/admin-service/my-profile',
  ProfileDetail: '/admin-service/profile/:id',
  ConfirmServiceAccount: '/admin-service/confirm-account',
  PreviewQuestion: '/admin-service/preview-question',
  NotificationList: '/admin-service/notifications',
};

// Admin Company Path
export const CompanyPath = {
  Login: '/admin/login',
  ForgotPassword: '/admin/forgot-password',
  AdminCompanyTop: '/admin',
  CourseDetail: '/admin/course/:id',
  CompanyDetail: '/admin/company-detail',
  MasterSetting: '/admin/setting',
  ManagerAccount: '/admin/manager-account',
  CompanyProfile: '/admin/my-profile',
  LicenseGrant: '/admin/license-grant',
  ProfileDetail: '/admin/profile/:id',
  ConfirmAdminCompany: '/admin/confirm-account-company',
  ConfirmCompanyAccount: '/admin/confirm-account',
  NotificationList: '/admin/notifications',
};

// User Path
export const StudentPath = {
  Login: '/login',
  ForgotPassword: '/forgot-password',
  StudentTop: '/',
  ConfirmTestOverview: '/learn-course/:id/confirm-test/:id',
  ConfirmTestingForm: '/learn-course/:id/confirm-testing/:id',
  FinalTestOverview: '/learn-course/:id/final-test/:id',
  FinalTestingForm: '/learn-course/:id/final-testing/:id',
  PreTestOverview: '/learn-course/:id/pre-test/:id',
  PreTestingForm: '/learn-course/:id/pre-testing/:id',
  PreSurveyDetail: '/learn-course/:id/pre-survey/:id',
  FinalSurveyDetail: '/learn-course/:id/final-survey/:id',
  CourseView: '/learn-course/:id',
  VideoPlayer: '/learn-course/:id/video/:id',
  TestExplain: '/learn-course/:id/test/:id',
  TestingContent: '/learn-course/:id/testing/:id',
  SurveyOverview: '/learn-course/:id/survey/:id',
  TipOverview: '/learn-course/:id/tip/:id',
  CourseDetail: '/course/:id',
  StudentProfile: '/my-profile',
  ConfirmStudent: '/confirm-account',
  NotificationList: '/notifications',
};

export const HealthCheckPath = '/healthcheck';
