// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Table,
  Progress,
  Space,
  Button,
} from 'antd';
import { isNumber } from 'lodash/lang';
import moment from 'moment';

import history from 'src/utils/history';
import { convertSecondTime } from 'src/utils/dateTime';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';

import * as Style from './styles';

interface ICourseListProps {
  getCompanyCourseDetail(params): void;
  statisticCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      };
      totalLearningTime: number;
      usedLicensesPass: number;
      companyProgressPercentage: number;
      progressGap: number;
      usedLicensesCount: number;
      remainingLicensesCount: number;
      remainingDays: number;
      startDate: string;
      endDate: string;
    }[];
    load: boolean;
  };
}

const CourseList: React.FC<ICourseListProps> = ({
  statisticCourses,
  getCompanyCourseDetail,
}) => {
  const tableData = statisticCourses.data.map((row) => ({
    key: row.id,
    id: row.id,
    name: row.courseCategory?.name,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'H時間m分'),
    usedLicensesPass: row.usedLicensesPass,
    companyProgressPercentage: row.companyProgressPercentage,
    progressGap: `${isNumber(row.progressGap) ? `${row.progressGap}%` : 'ー'}`,
    usedLicensesCount: `${row.usedLicensesCount}(残り${row.remainingLicensesCount})`,
    startDate: moment(row.startDate).format(DATE_FORMAT),
    endDate: `${moment(row.endDate).format(DATE_FORMAT)} [残り${row.remainingDays}日]`,
  }));

  const columns = [
    {
      title: 'コース名',
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 400,
    },
    {
      title: '学習時間',
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title: '合格者数',
      dataIndex: 'usedLicensesPass',
      render: (_, record) => (
        <Text>{isNumber(record.usedLicensesPass) ? record.usedLicensesPass : 'ー'}</Text>
      ),
      width: 150,
    },
    {
      title: '進捗率',
      dataIndex: 'companyProgressPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.companyProgressPercentage}%</Text>
          <Progress
            showInfo={false}
            strokeWidth={16}
            percent={record.companyProgressPercentage}
          />
        </Space>
      ),
      width: 200,
    },
    {
      title: '進捗ギャップ',
      dataIndex: 'progressGap',
      width: 150,
    },
    {
      title: '受講者数',
      dataIndex: 'usedLicensesCount',
      width: 200,
    },
    {
      title: '受講開始',
      dataIndex: 'startDate',
      width: 150,
    },
    {
      title: '受講終了',
      dataIndex: 'endDate',
      width: 250,
    },
  ];

  function redirectCompanyCourseList() {
    return history.push({
      pathname: '/admin/company-detail',
      state: { key: '2' }, // activated course list in company detail page
    });
  }

  return (
    <>
    <Style.CompanyCourseTitle>
      <Text xxxl>契約コース</Text>
      <Button
        type="primary"
        size="small"
        onClick={() => redirectCompanyCourseList()}
      >
        一覧を見る
      </Button>
    </Style.CompanyCourseTitle>
      <Style.CoursesTableContainer>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          columns={columns}
          scroll={{ x: 1650 }}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {getCompanyCourseDetail({
              id: record.id,
              redirect: `/admin/course/${record.id}`,
            })} 
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={statisticCourses.load}
        />
      </Style.CoursesTableContainer>
    </>
  );
};

export default CourseList;
