// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const DEFAULT_INFO_FIELDS = [
  {
    isDisplay: true,
    isRequired: true,
    fieldName: '登録アドレス',
    fieldKey: 'email',
  },
  {
    isDisplay: true,
    isRequired: true,
    fieldName: '名前',
    fieldKey: 'name',
  },
  {
    isDisplay: true,
    isRequired: false,
    fieldName: '部署',
    fieldKey: 'department',
  },
  {
    isDisplay: true,
    isRequired: false,
    fieldName: '役職',
    fieldKey: 'jobTitle',
  },
  {
    isDisplay: true,
    isRequired: false,
    fieldName: '職種',
    fieldKey: 'jobType',
  },
  {
    isDisplay: true,
    isRequired: false,
    fieldName: '生年月日',
    fieldKey: 'birthday',
  },
];
