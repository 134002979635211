//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import {
  Space,
  Button,
  Form,
  Input,
  Select,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { omit, keys } from 'lodash/object';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import { formatDateTime } from 'src/utils/dateTime';
import { randomLoadingWidth } from 'src/utils/common';

import { Text } from 'src/components/styles';

import { DATE_FORMAT, NOTIFY_SETTING_TYPE } from 'src/constants/common';

import datePickerItem from 'src/assets/images/common/datepicker-icon.png';

import { TextInfoUser, TextTitleInfo } from '../../styles';
import * as Style from './styles';
import { SkeletonDesktop, SkeletonMobile } from '../../styles'

interface IOtherProfileProps {
  updateUserInfo?(params): void;
  updateCustomUserInfo?(params): void;
  type: string;
  title: string;
  avatarReviewVisible: boolean;
  fieldData: {
    title: string;
    fieldName: string;
    type: string;
    option?: {
      title: string;
      value: string;
    }[];
  }[];
  profileInfo: {
    data: {
      id: number;
      company: {
        name: string;
      };
      department: string;
      jituName: string;
      kanaName: string;
      birthday: string;
      jobTitle: string;
      jobType: string;
      systemNotificationSetting: string;
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      customUserInfos: {
        id: number;
        companyCustomUserInfoId: number;
        fieldValue: string;
      }[];
      customDisplayField: {
        birthday: boolean;
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    load: boolean;
  };
}

const OtherProfile: React.FC<IOtherProfileProps> = ({
  updateUserInfo,
  updateCustomUserInfo,
  avatarReviewVisible,
  type,
  title,
  fieldData,
  profileInfo,
}) => {
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [otherProfileForm] = Form.useForm();
  const { data } = profileInfo;

  const customUserInfos = data.companyCustomUserInfos?.map((item) => {
    const infoIndex = findIndex(
      (data.customUserInfos || []),
      { companyCustomUserInfoId: item.id },
    );
    return {
      id: infoIndex !== -1 ? data.customUserInfos[infoIndex].id : '',
      fieldName: item.fieldName,
      companyCustomUserInfoId: item.id,
      fieldValue: infoIndex !== -1 ? data.customUserInfos[infoIndex].fieldValue : '',
    };
  });

  const userProfileInitialValues = {
    jituName: profileInfo.data.jituName,
    kanaName: profileInfo.data.kanaName,
    systemNotificationSetting: profileInfo.data.systemNotificationSetting,
  };

  const companyProfileInitialValues = {
    companyName: data.company?.name,
    department: data.department,
    jobType: data.jobType,
    jobTitle: data.jobTitle,
    birthday: data.birthday ? moment(data.birthday, DATE_FORMAT) : undefined,
    customUserInfos,
  };

  useEffect(() => {
    otherProfileForm.setFieldsValue( type === 'user' ? userProfileInitialValues : companyProfileInitialValues)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (data.id) {
      otherProfileForm.resetFields();
    }
  }, [data.id]);

  function handleSubmitForm(values) {
    if (type === 'user') {
      const requestData = {
        ...values,
        jituName: values.jituName.trim(),
        kanaName: (values.kanaName || '').trim(),
        ...(!isEmpty(values.birthday) && { birthday: moment(values.birthday).format(DATE_FORMAT) }),
      };
      if (updateUserInfo) updateUserInfo({ user: requestData });
    } else {
      const newCustomUserInfos = (values.customUserInfos || []).map((info) =>
        omit({ ...info, fieldValue: info.fieldValue.trim() }, ["fieldName"])
      );
      const requestData = {
        ...omit(values, ['companyName']),
        department: (values.department || '').trim(),
        jobType: (values.jobType || '').trim(),
        jobTitle: (values.jobTitle || '').trim(),
        customUserInfos: newCustomUserInfos,
        ...(!isEmpty(values.birthday) && { birthday: (values.birthday).format(DATE_FORMAT) })
      };
      if (updateCustomUserInfo) updateCustomUserInfo({ user: requestData });
    }
    return setIsEditForm(false);
  }

  function renderFieldType(field, fieldIndex) {
    if (field.type === 'select') {
      return (
        <Select style={{ width: 200 }}>
          {field.option?.map((option, optionIndex) => (
            <Select.Option key={optionIndex} value={option.value}>
              {option.title}
            </Select.Option>
          ))}
        </Select>
      );
    } else if (field.type === 'datepicker') {
      return (
        <DatePicker
          placeholder=""
          suffixIcon={<img src={datePickerItem} alt="" />}
          style={{ width: 200 }}
        />
      );
    } else {
      return (
        <Input
          disabled={field.fieldName === "companyName"}
          onBlur={() => {
            const customValue =
              otherProfileForm.getFieldValue(field.fieldName);
            otherProfileForm.setFieldsValue({
              [field.fieldName]: customValue.trim(),
            });
          }}
        />
      )
    }
  }

  function renderFieldValue(field) {
    switch (field.fieldName) {
    case 'companyName': {
      return data.company?.name;
    }
    case 'birthday': {
      return formatDateTime(data[field.fieldName], DATE_FORMAT);
    }
    case 'systemNotificationSetting': {
      return NOTIFY_SETTING_TYPE[data[field.fieldName]];
    }
    default:
      return data[field.fieldName];
    }
  }

  function renderFieldRule(field) {
    switch (field.fieldName) {
    case 'jituName': {
      return [
        { required: true, message: '名前は必須項目です。' },
        { whitespace: true, message: '名前は必須項目です。' },
        () => ({
          validator(_, value) {
            if ((value || '').trim().length > 50) {
              return Promise.reject('名前が長すぎます。（最大は50桁です）');
            }
            return Promise.resolve();
          },
        }),
      ];
    }
    case 'kanaName':
    case 'department':
    case 'jobType':
    case 'jobTitle': {
      return [
        () => ({
          validator(_, value) {
            if ((value || '').trim().length > 50) {
              return Promise.reject(`${field.title}が長すぎます。（最大は50桁です）`);
            }
            return Promise.resolve();
          },
        }),
      ];
    }
    default:
      return [];
    }
  }

  function renderProfileView() {
    if (profileInfo.load) {
      return fieldData.map((field, fieldIndex) => (
        <Style.ProfileContent key={`loading-overview-profile-${fieldIndex}`}>
          <Space>
            <TextTitleInfo lg w6>
              {field.title}
            </TextTitleInfo>
            <>
              <SkeletonDesktop
                active
                paragraph={false}
                title={{ width: randomLoadingWidth(250, 350) }}
                height={16}
              />
              <SkeletonMobile
                active
                paragraph={false}
                title={{ width: randomLoadingWidth(70, 150) }}
                height={16}
              />
            </>
          </Space>
        </Style.ProfileContent>
      ));
    }
    return (
      <>
        {fieldData.map((field, fieldIndex) => {
          if (['department', 'jobTitle', 'jobType', 'birthday'].includes(field.fieldName)
            && !data.customDisplayField?.[field.fieldName]) {
            return null;
          }
          return (
            <Style.ProfileContent key={`overview-profile-${fieldIndex}`}>
              <Space>
                <TextTitleInfo lg w6 isPreWrap>
                  {field.title}
                </TextTitleInfo>
                <TextInfoUser lg isPreWrap>
                  {renderFieldValue(field)}
                </TextInfoUser>
              </Space>
            </Style.ProfileContent>
          );
        })}
        {type === 'company' && customUserInfos?.map((field, fieldIndex) => {
          const { isDisplay } = data.companyCustomUserInfos[fieldIndex];
          if (!isDisplay) return null;
          return (
            <Style.ProfileContent key={`custom-profile-${fieldIndex}`}>
              <Space>
                <TextTitleInfo lg w6>{field.fieldName}</TextTitleInfo>
                <TextInfoUser lg isPreWrap>{field.fieldValue}</TextInfoUser>
              </Space>
            </Style.ProfileContent>
          );
        })}
      </>
    );
  }

  function renderProfileEdit() {
    return (
      <Style.EditProfileContent>
        {fieldData.map((field, fieldIndex) => {
          if (keys(data.customDisplayField).indexOf(field.fieldName) !== -1
            && !data.customDisplayField?.[field.fieldName]) {
            return null;
          }
          return (
            <Form.Item
              key={`overview-profile-${fieldIndex}`}
              label={(
                <Style.TextField lg w6>
                  {field.title}
                </Style.TextField>
              )}
              name={field.fieldName}
              labelAlign="left"
              colon={false}
              rules={renderFieldRule(field)}
            >
              {renderFieldType(field, fieldIndex)}
            </Form.Item>
          );
        })}
        {type === 'company' && (
          <Form.List name="customUserInfos" >
            {(fields) => (
              <>
                {fields.map((field, fieldIndex) => {
                  const { fieldName, isRequired, isDisplay } = data.companyCustomUserInfos[field.fieldKey];
                  if (!isDisplay) return null;
                  return (
                    <div key={`${field.key}-${fieldIndex}`}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'id']}
                        fieldKey={[field.fieldKey, 'id'] as any}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, 'companyCustomUserInfoId']}
                        fieldKey={[field.fieldKey, 'companyCustomUserInfoId'] as any}
                        style={{ display: 'none' }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label={(
                          <Text lg w6>
                            {fieldName}
                          </Text>
                        )}
                        name={[field.name, 'fieldValue']}
                        fieldKey={[field.fieldKey, 'fieldValue'] as any}
                        labelAlign="left"
                        colon={false}
                        rules={[
                          {
                            ...(isRequired && {
                              required: true,
                              whitespace: true,
                              message: `${fieldName}は必須項目です。`,
                            }),
                          },
                          () => ({
                            validator(_, value) {
                              if ((value || '').trim().length > 50) {
                                return Promise.reject(`${fieldName}が長すぎます。（最大は50桁です）`);
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input
                          onBlur={() => {
                            const customUserInfosValue =
                              otherProfileForm.getFieldValue('customUserInfos');
                            const formValueFieldTrim =
                            customUserInfosValue[fieldIndex].fieldValue.trim();
                            customUserInfosValue[fieldIndex] = {
                              ...customUserInfosValue[fieldIndex],
                              fieldValue: formValueFieldTrim,
                            };
                            otherProfileForm.setFieldsValue({
                              customUserInfos: customUserInfosValue,
                            });
                          }}
                        />
                      </Form.Item>
                    </div>
                  );
                })}
              </>
            )}
          </Form.List>
        )}
      </Style.EditProfileContent>
    );
  }

  return (
    <Form
      form={otherProfileForm}
      name={type === 'user' ? 'userProfileForm' : 'companyProfileForm'}
      initialValues={type === 'user'
        ? userProfileInitialValues
        : companyProfileInitialValues
      }
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.ModifyProfile>
        {isEditForm
          ? (
            <Space align="center" size="middle">
              <Style.ButtonCancel
                type="primary"
                htmlType="button"
                className="btn-secondary"
                onClick={() => {
                  setIsEditForm(false);
                  otherProfileForm.resetFields();
                }}
              >
                キャンセル
              </Style.ButtonCancel>
              <Button type="primary" htmlType="submit">保存する</Button>
            </Space>
          )
          : <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              if (!avatarReviewVisible) setIsEditForm(true);
            }}
          >
            修正する
          </Button>
        }
      </Style.ModifyProfile>
      <Style.ProfileWrapper>
        <Style.HeaderWrapper>
          <TextInfoUser white lg w6>{title}</TextInfoUser>
        </Style.HeaderWrapper>
        {isEditForm ? renderProfileEdit() : renderProfileView()}
      </Style.ProfileWrapper>
    </Form>
  );
};

export default OtherProfile;
