// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class LicenseServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  // License Action
  createLicense(params) {
    return this.restClient.post('/admin_manage/courses_companies', params);
  }
  getLicenseList(params) {
    return this.restClient.get('/admin_manage/courses_companies', params);
  }

  getLicenseDetail(params) {
    return this.restClient.get(`/admin_manage/courses_companies/${params.id}`);
  }

  updateLicense(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/courses_companies/${id}`, requestData);
  }
}
