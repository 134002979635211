// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const UNIT_ACTION = {
  GET_LIST: 'ADMIN_SERVICE/UNIT_ACTION/GET_LIST',
  CLEAR_LIST: 'ADMIN_SERVICE/UNIT_ACTION/CLEAR_LIST',
  GET_DETAIL: 'ADMIN_SERVICE/UNIT_ACTION/GET_DETAIL',
  CLEAR_UNIT_DETAIL: 'ADMIN_SERVICE/UNIT_ACTION/CLEAR_UNIT_DETAIL',
  UPDATE: 'ADMIN_SERVICE/UNIT_ACTION/UPDATE',
  CREATE: 'ADMIN_SERVICE/UNIT_ACTION/CREATE',
  DELETE: 'ADMIN_SERVICE/UNIT_ACTION/DELETE',
  GET_VERSIONS: 'ADMIN_SERVICE/UNIT_ACTION/GET_VERSIONS',
  CLEAR_VERSIONS: 'ADMIN_SERVICE/UNIT_ACTION/CLEAR_VERSIONS',
  SELECT_VERSION: 'ADMIN_SERVICE/UNIT_ACTION/SELECT_VERSION',
  GET_ITEMS: 'ADMIN_SERVICE/UNIT_ACTION/GET_ITEMS',
  SELECT_ITEM: 'ADMIN_SERVICE/UNIT_ACTION/SELECT_ITEM',
  CREATE_VERSION: 'ADMIN_SERVICE/UNIT_ACTION/CREATE_VERSION',
  GET_COURSE_CONTENT_UNITS: 'ADMIN_SERVICE/UNIT_ACTION/GET_COURSE_CONTENT_UNITS',
  GET_OVERVIEW_UNIT_DETAIL: 'ADMIN_SERVICE/UNIT_ACTION/GET_OVERVIEW_UNIT_DETAIL',
};
