//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { isNumber, isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';
import { FilterSidebar } from 'src/components/layouts/Sidebar';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { ServicePath } from 'src/constants/routerConstants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';
import { DATE_FORMAT } from 'src/constants/common';

import {
  getCompanyList as getCompanyListAction,
} from 'src/redux/actions';

interface ICompanyListPageProps extends StateProps, DispatchProps {
  setParamsFilter(params): void;
  getCompanyList(params): void;
  companyList: {
    data: {
      id: number;
      name: string;
      contractName: string;
      registerDate: string;
      usersCount: number;
      coursesCount: number;
      licensesCount: number;
      contractEndDate: string;
      contractRemainingDays: number;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: object[];
  };
}

const CompanyListPage: React.FC<ICompanyListPageProps> = ({
  getCompanyList,
  companyList,
}) => {
  const filterParamsUrl = JSON.parse(history.location.state?.filterParams || '{}');
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const { data, meta } = companyList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  const contractThirtyDay = 30;

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CompanyList);
    setParamsFilter(filterParamsUrl || {})
    setSortCondition(sortConditionUrl || {})
    getCompanyList({ page: 1, ...pageUrl, items: pageUrl?.limit, ...filterParamsUrl, sort: sortConditionUrl });
    window.addEventListener("beforeunload", function () {
      history.replace()
    })
  }, []);

  useEffect(() => {
    history.replace({
      state: {
        filterParams: JSON.stringify(filterParams),
        sortCondition,
        page: meta.pageInfo,
        isSort: true,
      }
    })
  }, [sortCondition, filterParams, meta.pageInfo])

  const columns = [
    {
      title:
        <SortableHeader
          type="contractName"
          title="契約名"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyList}
          setAction={setSortCondition}
        />,
      dataIndex: 'contractName',
      render: (_, record) => <TooltipParagraph isPreWrap rows={2}>{record.contractName}</TooltipParagraph>,
      width: 250,
    },
    {
      title:
        <SortableHeader
          type="name"
          title="企業名"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyList}
          setAction={setSortCondition}
        />,
      dataIndex: 'name',
      render: (_, record) => <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>,
      width: 250,
    },
    {
      title:
        <SortableHeader
          type="registerDate"
          title="契約日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyList}
          setAction={setSortCondition}
        />,
      dataIndex: 'registerDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="usersCount"
          title="登録ユーザー"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyList}
          setAction={setSortCondition}
        />,
      dataIndex: 'usersCount',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="coursesCount"
          title="コース数"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyList}
          setAction={setSortCondition}
        />,
      dataIndex: 'coursesCount',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="licensesCount"
          title="ライセンス数"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyList}
          setAction={setSortCondition}
        />,
      dataIndex: 'licensesCount',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="contractEndDate"
          title="コース終了"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getCompanyList}
          setAction={setSortCondition}
        />,
      dataIndex: 'contractEndDate',
      render: (_, record) => {
        if (isNumber(record.contractRemainingDays)
          && record.contractRemainingDays >= 0
          && record.contractRemainingDays <= contractThirtyDay
        ) {
          return (
            <Text style={{ color: '#D25109' }}>
              {(`${formatDateTime(record.contractEndDate, DATE_FORMAT)}[残り${(record.contractRemainingDays)}]`)}
            </Text>
          );
        }
        return (
          <Text>
            {formatDateTime(record.contractEndDate, DATE_FORMAT)}
          </Text>
        );
      },
      width: 200,
    },
  ];

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: 'コース終了日',
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: '契約名',
    },
  ];

  const tableData = data.map((item) => ({
    ...item,
    key: item.id,
    registerDate: formatDateTime(item.registerDate, DATE_FORMAT),
  }));

  function redirectAddCompanyAccount() {
    return history.push({
      pathname: '/admin-service/manager-account',
      state: { key: '5' }, // activated add company account tab in manager account screen
    });
  }

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getCompanyList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
          filterParams={filterParams}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>企業一覧</Text>
          <Button type="primary" onClick={() => redirectAddCompanyAccount()} >
            企業新規登録
          </Button>
        </TopContent>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          scroll={{ x: 1300, y: 506 }}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {
              history.push({
                pathname: ServicePath.CompanyDetail,
                state: {
                  companyContractId: record.id,
                }, // select company contract in company detail screen
              });
            },
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={companyList.load}
        />
        {!isEmpty(companyList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getCompanyList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { companyList } = state.companyReducer;
  return {
    companyList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyList: (params) => dispatch(getCompanyListAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyListPage);
