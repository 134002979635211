//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Space,
  Button,
  Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import {
  formatPhoneNumber,
  renderLinkInText,
  getFilterOption,
} from 'src/utils/common';
import { formatDateTime } from 'src/utils/dateTime';

import { MainWrapper } from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';

import { DATE_FORMAT } from 'src/constants/common';
import { PHONE_NUMBER_REGEX, POSTAL_CODE_REGEX } from 'src/constants/regexValidate';

import * as Style from './styles';
import history from 'src/utils/history';

interface ICompanyInformationProps {
  getCompanyDetail(params): void;
  updateCompanyInfos(params): void;
  companyIdSelected: number | undefined;
  companyDetail: {
    data: {
      id: number
      name: string;
      status: string;
      addressBuilding: string;
      addressCity: string;
      adminCompany: {
        id: number;
        jituName: string;
        department: string;
        phoneNumber: string;
        email: string;
      };
      contractName: string;
      departmentInCharge: string;
      fax: string;
      phoneNumber: string;
      postalCode: string;
      province: {
        id: number,
        name: string;
      };
      registerDate: string;
      sfa: string;
      customDisplayField: {
        department: boolean;
      };
    };
  };
  provinceList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  activeTabKey: string
}

const CompanyInformation: React.FC<ICompanyInformationProps> = ({
  getCompanyDetail,
  updateCompanyInfos,
  companyIdSelected,
  companyDetail,
  provinceList,
  activeTabKey
}) => {
  const urlSelectCompanyId = history.location.state?.companyContractId;
  const [isEditForm, setIsEditForm] = useState<boolean>(false);

  const [companyInfoForm] = Form.useForm();

  const companyInfoInitialValues = {
    id: companyDetail.data.id,
    contractName: companyDetail.data.contractName,
    name: companyDetail.data.name,
    departmentInCharge: companyDetail.data.departmentInCharge,
    postalCode: companyDetail.data.postalCode,
    provinceId: companyDetail.data.province?.id,
    addressCity: companyDetail.data.addressCity,
    addressBuilding: companyDetail.data.addressBuilding,
    phoneNumber: companyDetail.data.phoneNumber,
    fax: companyDetail.data.fax,
    registerDate: formatDateTime(companyDetail.data.registerDate, DATE_FORMAT),
    sfa: companyDetail.data.sfa,
  };

  useEffect(() => {
    if (companyIdSelected) {
      getCompanyDetail({ id: urlSelectCompanyId || companyIdSelected });
    }
  }, [companyIdSelected]);

  
  useEffect(() => {
    history.replace({
      state: {
        activeTabKey,
        urlSelectCompanyId: companyIdSelected
      }
    })
  }, [activeTabKey])

  useEffect(() => {
    if (companyDetail.data.id) {
      companyInfoForm.resetFields();
    }
  }, [companyDetail.data]);

  function handleSubmitForm(values) {
    if (!companyIdSelected) return null;
    const newValues = {
      contractName: values.contractName.trim(),
      name: values.name.trim(),
      postalCode: values.postalCode?.trim(),
      departmentInCharge: values.departmentInCharge.trim(),
      provinceId: values?.provinceId,
      addressCity: values.addressCity?.trim(),
      addressBuilding: values.addressBuilding?.trim(),
      phoneNumber: values.phoneNumber?.trim(),
      fax: values.fax ? values.fax.trim() : '',
      sfa: values.sfa ? values.sfa.trim() : '',
    };
    updateCompanyInfos({
      id: companyIdSelected,
      requestData: newValues,
      requestAction: {
        setIsEditForm,
        setCompanyInfoFields: (params) => companyInfoForm.setFields(params),
      },
    });
  }

  function onFinishFailed({ errorFields }) {
    companyInfoForm.scrollToField(errorFields[0].name);
    window.scrollBy(0, 40);
  }

  function renderProvinceOptions() {
    if (isEmpty(provinceList.data)) return null;
    return provinceList.data.map((province) => (
      <Select.Option key={`province-${province.id}`} value={province.id}>{province.name}</Select.Option>
    ));
  }

  function renderCompanyAddress() {
    if (isEmpty(companyDetail.data)) return null;
    let companyAddress = '';
    if (companyDetail.data.province?.name) {
      companyAddress = companyAddress + companyDetail.data.province?.name;
    }
    if (companyDetail.data.addressCity) {
      companyAddress = companyAddress + companyDetail.data.addressCity;
    }
    if (companyDetail.data.addressBuilding) {
      companyAddress = companyAddress + companyDetail.data.addressBuilding;
    }
    return companyAddress;
  }

  function renderCompanyView() {
    return (
      <>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>契約ID</Text>
          <Text headerText lg>{companyDetail.data.id}</Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>契約名</Text>
          <Text headerText lg>{companyDetail.data.contractName}</Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>企業名</Text>
          <Text headerText lg>{companyDetail.data.name}</Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>担当部署</Text>
          <Text headerText lg>{companyDetail.data.departmentInCharge}</Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>郵便番号</Text>
          <Text headerText lg>{companyDetail.data.postalCode}</Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>住所</Text>
          <Text headerText lg>
            {renderCompanyAddress()}
          </Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>電話番号</Text>
          <Text headerText lg>
            {formatPhoneNumber(companyDetail.data.phoneNumber)}
          </Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>ファックス番号</Text>
          <Text headerText lg>
            {formatPhoneNumber(companyDetail.data.fax)}
          </Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>契約日</Text>
          <Text headerText lg>
            {formatDateTime(companyDetail.data.registerDate, DATE_FORMAT)}
          </Text>
        </Style.CompanyInformationItem>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 180 }}>SFA</Text>
          <Text
            headerText
            lg
            dangerouslySetInnerHTML={{
              __html: renderLinkInText(companyDetail.data.sfa),
            }}
          />
        </Style.CompanyInformationItem>
      </>
    );
  }

  function renderCompanyEdit() {
    return (
      <Style.EditCompanyContent>
        <Form.Item
          label={(<Text lg w6>契約ID</Text>)}
          name="id"
          labelAlign="left"
          colon={false}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>契約名</Text>)}
          name="contractName"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: '契約名は必須項目です。' },
            { whitespace: true, message: '契約名は必須項目です。' },
            { max: 50, message: '契約名が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>企業名</Text>)}
          name="name"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: '企業名は必須項目です。' },
            { whitespace: true, message: '企業名は必須項目です。' },
            { max: 50, message: '企業名が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>担当部署</Text>)}
          name="departmentInCharge"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, whitespace: true, message: '担当部署は必須項目です。' },
            { max: 50, message: '担当部署が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>郵便番号</Text>)}
          name="postalCode"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 10, message: '郵便番号が長すぎます。（最大は10桁です）' },
            {
              pattern: new RegExp(POSTAL_CODE_REGEX),
              message: '郵便番号のフォーマットが正しくありません。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>都道府県</Text>)}
          name="provinceId"
          labelAlign="left"
          colon={false}
        >
          <Select
            allowClear
            showSearch
            suffixIcon={<CaretDownOutlined />}
            optionFilterProp="children"
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            filterOption={getFilterOption}
            style={{ width: 200 }}
          >
            {renderProvinceOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>市町村・番地</Text>)}
          name="addressCity"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 50, message: '市町村・番地が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>建物名・部屋番号等</Text>)}
          name="addressBuilding"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 50, message: '建物名・部屋番号等が長すぎます。（最大は50桁です）' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>電話番号</Text>)}
          name="phoneNumber"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 20, message: '電話番号が長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PHONE_NUMBER_REGEX),
              message: '電話番号の形式が正しくありません。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>ファックス番号</Text>)}
          name="fax"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 20, message: 'ファックス番号が長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PHONE_NUMBER_REGEX),
              message: 'ファックス番号の形式が正しくありません。',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>契約日</Text>)}
          name="registerDate"
          labelAlign="left"
          colon={false}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={(<Text lg w6>SFA</Text>)}
          name="sfa"
          labelAlign="left"
          colon={false}
          rules={[
            { max: 255, message: 'SFAリンクが長すぎます。（最大は255桁です）' },
            { type: 'url', message: '入力したリンクのフォーマットは正しくありません。' },
          ]}
        >
          <Input />
        </Form.Item>
      </Style.EditCompanyContent>
    );
  }

  return (
    <Form
      form={companyInfoForm}
      name="companyInfoForm"
      initialValues={companyInfoInitialValues}
      onFinish={(values) => handleSubmitForm(values)}
      onFinishFailed={onFinishFailed}
    >
      <Style.ModifyCompanyInfo>
        {isEditForm
          ? (
            <Space align="center" size="middle">
              <Button
                type="primary"
                htmlType="button"
                className="btn-secondary"
                onClick={() => {
                  setIsEditForm(false);
                  companyInfoForm.resetFields();
                }}
              >
                キャンセル
              </Button>
              <Button type="primary" htmlType="submit">保存する</Button>
            </Space>
          )
          : <Button
            type="primary"
            htmlType="button"
            onClick={() => {
              if (companyIdSelected) setIsEditForm(true);
            }}
            disabled={!companyIdSelected}
          >
            修正する
          </Button>
        }
      </Style.ModifyCompanyInfo>
      <MainWrapper title="企業情報">
        {isEditForm
          ? renderCompanyEdit()
          : renderCompanyView()
        }
      </MainWrapper>
      <MainWrapper title="企業管理者" style={{ marginTop: 40 }}>
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 150 }}>担当者名</Text>
          <Text headerText lg>{companyDetail.data.adminCompany?.jituName}</Text>
        </Style.CompanyInformationItem>
        {companyDetail.data.customDisplayField?.department && (
          <Style.CompanyInformationItem>
            <Text lg w6 style={{ width: 150 }}>所属部署</Text>
            <Text headerText lg>{companyDetail.data.adminCompany?.department}</Text>
          </Style.CompanyInformationItem>
        )}
        <Style.CompanyInformationItem>
          <Text lg w6 style={{ width: 150 }}>担当者Email</Text>
          <Text headerText lg>{companyDetail.data.adminCompany?.email}</Text>
        </Style.CompanyInformationItem>
      </MainWrapper>
    </Form>
  );
};

export default CompanyInformation;
