// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import CreateTest from './Create';
import EditTest from './Edit';

interface IModifyTestPageProps {
  match: {
    params: {
      id?: string;
    };
  };
}

const ModifyTestPage: React.FC<IModifyTestPageProps> = ({
  match,
}) => {
  const testId = match.params.id;
  function renderModifyTestContent() {
    if (testId) return <EditTest testId={testId} />;
    return <CreateTest />;
  }
  return (
    <MainContainer isTabs backgroundHeight={130}>
      <MainContent fullView>
        {renderModifyTestContent()}
      </MainContent>
    </MainContainer>
  );
};

export default ModifyTestPage;
