// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class StudentAccountServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getConfirmationStatus(params) {
    return this.restClient.get('/confirmation_statuses', params);
  }

  confirmAccount(params) {
    return this.restClient.post('/confirmations', params);
  }
}
