// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from "styled-components";
import { Table } from "antd";

export const AttendanceTableWrapper = styled.div`
  p:not(th p), td {
    color: ${(props) => props.theme.colors.headerText} !important;
  }
`

export const TableCustom = styled(Table)`
  margin-top: 32px;

  .ant-tag {
    font-size: 11px !important;
  }
`;

export const TagWrapper = styled.div`
  width: 60px;
  text-align: center;
`;

export const StudentName = styled.div`
  position: relative;
`;

export const StudentStatus = styled.span`
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  color: ${(props) => props.theme.colors.error};
`;
