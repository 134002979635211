// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const TipOverviewAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 40px;

  & button {
    width: 100px;
  }
`;
