//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { Space, Button } from 'antd';

import { randomLoadingWidth } from 'src/utils/common';

import ChangeRoleModal from '../ChangeRoleModal';
import ChangeEmailModal from '../ChangeEmailModal';
import ChangePasswordModal from '../ChangePasswordModal';
import ChangeEmailPasswordModal from '../ChangeEmailPasswordModal';

import { ACCOUNT_ROLES } from 'src/constants/common';
import { ACCOUNT_PROFILE_FIELDS } from '../../constants';

import * as Style from './styles';
import { TextInfoUser, TextTitleInfo } from '../../styles';
import history from 'src/utils/history';
import { SkeletonDesktop, SkeletonMobile } from '../../styles'

interface IAccountProfileProps {
  clearUpdateEmailErrors(): void;
  clearUpdatePasswordErrors(): void;
  clearUpdateRoleErrors(): void;
  clearCompanyUpdateRoleErrors(): void;
  showModal(params): void;
  role: string;
  profileInfo: {
    data: {
      id: number;
      department: string;
      email: string;
      jituName: string;
    },
    load: boolean;
  };
  avatarReviewVisible: boolean;
  activeTabKey: string
}

const AccountProfile: React.FC<IAccountProfileProps> = ({
  clearUpdateEmailErrors,
  clearUpdatePasswordErrors,
  clearUpdateRoleErrors,
  clearCompanyUpdateRoleErrors,
  showModal,
  role,
  profileInfo,
  avatarReviewVisible,
  activeTabKey
}) => {

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey
      }
    })
  }, [activeTabKey])

  function showChangeRoleModal() {
    if (role === 'admin_service') {
      clearUpdateRoleErrors();
    } else {
      clearCompanyUpdateRoleErrors();
    }
    const modalProps = {
      width: 560,
      modalData: {
        title: 'アカウント種別変更',
        profileInfo: profileInfo.data,
        editorRole: role,
      },
    };
    return showModal(<ChangeRoleModal {...modalProps} />);
  }

  function showChangeEmailModal() {
    clearUpdateEmailErrors();
    const modalProps = {
      width: 560,
      modalData: {
        title: 'メールアドレス変更',
        email: profileInfo.data.email,
      },
    };
    return showModal(<ChangeEmailModal {...modalProps} />);
  }

  function showChangePasswordModal() {
    clearUpdatePasswordErrors();
    const modalProps = {
      width: 560,
      modalData: {
        title: 'パスワード変更',
      },
    };
    return showModal(<ChangePasswordModal {...modalProps} />);
  }

  function renderProfileInfo(field, fieldIndex) {
    if (profileInfo.load) {
      return (
        <>
          <SkeletonDesktop
            active
            paragraph={false}
            title={{ width: randomLoadingWidth(250, 350) }}
            height={16}
          />
          <SkeletonMobile
            active
            paragraph={false}
            title={{ width: randomLoadingWidth(70, 150) }}
            height={16}
          />
        </>
      );
    }
    if (fieldIndex === 3) {
      return (
        <Space size={2}>
          {[...Array(8)].map((_, index) => <Style.PasswordHidden key={`password-hidden-${index}`} />)}
        </Space>
      );
    } else {
      return (
        <TextInfoUser lg>
          {fieldIndex === 1
            ? (profileInfo.data[field.fieldName] || []).map((roleType) => ACCOUNT_ROLES[roleType]).toString()
            : profileInfo.data[field.fieldName]
          }
        </TextInfoUser>
      );
    }
  }

  function renderProfileContent() {
    return ACCOUNT_PROFILE_FIELDS.map((field, fieldIndex) => (
      <Style.ProfileContent key={`overview-profile-${fieldIndex}`}>
        <Space>
          <TextTitleInfo lg w6>
            {field.title}
          </TextTitleInfo>
          {renderProfileInfo(field, fieldIndex)}
        </Space>
        <Style.ButtonWrapper>
        {(role !== 'student' && fieldIndex === 1) && (
          <Button
            type="primary"
            onClick={() => {
              showChangeRoleModal();
            }}
            disabled={profileInfo.load}
          >
            変更する
          </Button>
        )}
        {fieldIndex === 2 && (
          <Button
            type="primary"
            onClick={() => {
              if (!avatarReviewVisible) showChangeEmailModal();
            }}
            disabled={profileInfo.load}
          >
            変更する
          </Button>
        )}
        {fieldIndex === 3 && (
          <Button
            type="primary"
            onClick={() => {
              if (!avatarReviewVisible) showChangePasswordModal();
            }}
            disabled={profileInfo.load}
          >
            変更する
          </Button>
        )}
        </Style.ButtonWrapper>
      </Style.ProfileContent>
    ));
  }

  function showChangeEmailPasswordModal() {
    clearUpdatePasswordErrors();
    const modalProps = {
      width: 560,
      modalData: {
        title: 'アカウント情報変更',
        email: profileInfo.data.email,
      },
    };
    return showModal(<ChangeEmailPasswordModal {...modalProps} />);
  }

  return (
    <>
      <Style.ModifyProfile>
      <Button
        type="primary"
        htmlType="button"
        onClick={() => {
          if (!avatarReviewVisible) showChangeEmailPasswordModal();
        }}
      >
        変更する
      </Button>
      </Style.ModifyProfile>
      <Style.ProfileWrapper>
        <Style.HeaderWrapper>
          <TextInfoUser white lg w6>アカウント情報</TextInfoUser>
        </Style.HeaderWrapper>
        {renderProfileContent()}
      </Style.ProfileWrapper>
    </>
  );
};

export default AccountProfile;
