// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Col } from 'antd';

export const FormCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const TextOverviewGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 4px;
`;

export const TextOverviewInput = styled.div`
  width: calc(100% - 240px);
`;

export const TextOverviewAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 24px;
  height: 100%;

  & button {
    width: 100px;
  }
`;

export const FieldInput = styled.input`
  display: none !important;
`;

export const FileInputField = styled.div`
  display: flex;
  align-items: center;
`;
