// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const COMPANY_ACTION = {
  GET_LIST: 'ADMIN_SERVICE/COMPANY_ACTION/GET_LIST',
  GET_DETAIL: 'ADMIN_SERVICE/COMPANY_ACTION/GET_DETAIL',
  GET_COMPANY_CONTRACTS: 'ADMIN_SERVICE/COMPANY_ACTION/GET_COMPANY_CONTRACTS',
  SELECT_COMPANY_STATUS: 'ADMIN_SERVICE/COMPANY_ACTION/SELECT_COMPANY_STATUS',
  GET_COMPANY_STUDENTS: 'ADMIN_SERVICE/COMPANY_ACTION/GET_COMPANY_STUDENTS',
  GET_COMPANY_COURSES: 'ADMIN_SERVICE/COMPANY_ACTION/GET_COMPANY_COURSES',
  CLEAR_COMPANY_DATA: 'ADMIN_SERVICE/COMPANY_ACTION/CLEAR_COMPANY_DATA',
  GET_COMPANY_CUSTOM_INFOS: 'ADMIN_SERVICE/COMPANY_ACTION/GET_COMPANY_CUSTOM_INFOS',
  UPDATE_COMPANY_CUSTOM_INFOS: 'ADMIN_SERVICE/COMPANY_ACTION/UPDATE_COMPANY_CUSTOM_INFOS',
  DELETE_COMPANY_CUSTOM_INFOS: 'ADMIN_SERVICE/COMPANY_ACTION/DELETE_COMPANY_CUSTOM_INFOS',
  UPDATE_COMPANY_INFOS: 'ADMIN_SERVICE/COMPANY_ACTION/UPDATE_COMPANY_INFOS',
  BAN_COMPANY_STUDENT: 'ADMIN_SERVICE/COMPANY_ACTION/BAN_COMPANY_STUDENT',
};
