// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CompanyAccountServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getConfirmationStatus(params) {
    return this.restClient.get('/confirmation_statuses', params);
  }

  confirmAccount(params) {
    return this.restClient.post('/confirmations', params);
  }

  confirmAdminCompany(params) {
    return this.restClient.post('/company_confirmations', params);
  }

  getStudents(params) {
    return this.restClient.get('/company_manage/register_course/users', params);
  }

  addStudentsToCourse(params) {
    const { requestData, courseId } = params;
    return this.restClient.post(`/company_manage/courses/${courseId}/courses_users`, requestData);
  }

  inviteStudents(params) {
    return this.restClient.post('/company_manage/students_invitations', params);
  }

  inviteAdminCompany(params) {
    return this.restClient.post('/company_manage/admin_companies_invitations', params);
  }

  getInvitationList(params) {
    return this.restClient.get('/company_manage/registration/users', params);
  }

  resendInvitation(params) {
    return this.restClient.post('/company_manage/resend_invitations', params);
  }

  cancelInvitation(params) {
    return this.restClient.post('/company_manage/cancel_invitations', params);
  }

  addStudentsToCourseByCsv(params) {
    const { requestData, courseId } = params;
    return this.restClient.post(`/company_manage/courses/${courseId}/import_users_by_file`, requestData, {}, 'formData');
  }

  bulkResendInvitations(params) {
    return this.restClient.post('/company_manage/resend_invitations/bulk_resend', params);
  }

  bulkCancelInvitations(params) {
    return this.restClient.post('/company_manage/cancel_invitations/bulk_cancel', params);
  }
}
