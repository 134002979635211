// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import AdminServiceTop from './AdminServiceTop';
import CourseList from './CourseList';
import CourseDetail from './CourseDetail';
import CourseView from './CourseView';
import ModifyCourse from './ModifyCourse';
import ModifyChapterFAQ from './ModifyChapterFAQ';
import UnitList from './UnitList';
import ModifyUnit from './ModifyUnit';
import TestList from './TestList';
import ModifyTest from './ModifyTest';
import QuestionList from './QuestionList';
import QuestionDetail from './QuestionDetail';
import VideoList from './VideoList';
import TipList from './TipList';
import ModifyTip from './ModifyTip';
import ManagerAccount from './ManagerAccount';
import LicenseGrant from './LicenseGrant';
import LicenseList from './LicenseList';
import ModifyLicense from './ModifyLicense';
import CompanyDetail from './CompanyDetail';
import CompanyList from './CompanyList';
import SurveyList from './SurveyList';
import ModifySurvey from './ModifySurvey';
import FAQList from './FAQList';
import CreateFAQ from './CreateFAQ';
import EditFAQ from './EditFAQ';
import UploadAnswerList from './UploadAnswerList';
import MasterSetting from './MasterSetting';
import ProfileDetail from './ProfileDetail';
import SurveyDetail from './SurveyDetail';
import TestOverview from './TestOverview';
import TestingForm from './TestingForm';
import ConfirmServiceAccount from './ConfirmServiceAccount';
import PreviewQuestion from './PreviewQuestion';

export const ServicePage = {
  AdminServiceTop,
  CourseList,
  CourseDetail,
  CourseView,
  ModifyCourse,
  ModifyChapterFAQ,
  UnitList,
  ModifyUnit,
  TestList,
  ModifyTest,
  QuestionList,
  QuestionDetail,
  VideoList,
  TipList,
  ModifyTip,
  ManagerAccount,
  LicenseGrant,
  CompanyList,
  CompanyDetail,
  SurveyList,
  ModifySurvey,
  FAQList,
  CreateFAQ,
  EditFAQ,
  UploadAnswerList,
  MasterSetting,
  ProfileDetail,
  SurveyDetail,
  TestOverview,
  TestingForm,
  ConfirmServiceAccount,
  PreviewQuestion,
  LicenseList,
  ModifyLicense,
};
