//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Space,
  Progress,
  Tooltip,
  Button,
} from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { DATE_FORMAT, COURSE_STATUS, COURSE_ACTUAL_STATUS } from 'src/constants/common';
import * as Style from './styles';
import Switch from 'src/components/switch';

interface IJoinedCoursesProps extends DispatchProps {
  userId: any,
  getListCourseCompany(params): void;
  updateCourseManager(params): void;
  listCourseCompany: {
    data: {
      id: number;
      course: {
        id: number;
        name: string;
        courseCategory: {
          id: number;
          name: string;
        }
      },
      courseActualStatus: string;
      startDate: string;
      endDate: string;
      manageStatus: string
    }[],
    meta: {
      pageInfo: {
        page: number;
        limit: number;
        count: number;
      }
    }
    load: boolean
  }
}
const CourseManager: React.FC<IJoinedCoursesProps> = ({
  userId,
  getListCourseCompany,
  listCourseCompany,
  updateCourseManager
}) => {

    useEffect(() => {
      getListCourseCompany({
        userId: userId,
        requestData: {
          page: 1
        }
      })
    }, [])

  const { data, meta } = listCourseCompany;
  const [sortCondition, setSortCondition] = useState<object>({});

  function handleSwitch(id) {
    return updateCourseManager({ courseId: id, userId: userId })
  }

  const tableData = data.map((courseItem) => ({
    ...courseItem,
    key: courseItem.id,
    id: courseItem.id,
    name: courseItem.course.courseCategory.name,
    versionName: courseItem.course?.name,
    courseActualStatus: courseItem.courseActualStatus,
    endDate: courseItem.endDate ? moment(courseItem.endDate).format(DATE_FORMAT) : "-",
    startDate: courseItem.startDate ? moment(courseItem.startDate).format(DATE_FORMAT) : "-",
    statusManager: courseItem.manageStatus,
  }));

  const columns = [
    {
      title:
        <SortableHeader
          type="courseName"
          title="コース"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getListCourseCompany}
          setAction={setSortCondition}
          dataParam={{
            userId: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
        />,
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 400,
    },
    {
      title:
        <SortableHeader
          type="versionName"
          title="バージョン"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getListCourseCompany}
          setAction={setSortCondition}
          dataParam={{
            userId: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
        />,
      dataIndex: 'versionName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.versionName}</TooltipParagraph>
      ),
      width: 250,
    },
    {
      title:
        <SortableHeader
          type="courseActualStatus"
          title="ステータス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getListCourseCompany}
          setAction={setSortCondition}
          dataParam={{
            userId: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
        />,
      dataIndex: 'courseActualStatus',
      render: (_, record) => (
        <Text>{COURSE_STATUS[record.courseActualStatus]}</Text>
      ),
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="startDate"
          title="開始日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getListCourseCompany}
          setAction={setSortCondition}
          dataParam={{
            userId: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
        />,
      dataIndex: 'startDate',
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="endDate"
          title="終了日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getListCourseCompany}
          setAction={setSortCondition}
          dataParam={{
            userId: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
        />,
      dataIndex: 'endDate',
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="manageStatus"
          title="管理状態"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getListCourseCompany}
          setAction={setSortCondition}
          dataParam={{
            userId: userId,
            requestData: {
              page: meta.pageInfo?.page,
            },
          }}
        />,
      dataIndex: 'statusManager',
      width: 130,
      render: (_, record) => <Switch status={record.statusManager === "enabled"} onSwitch={() => {
        handleSwitch(record.id)
      }} />
    },
    {
      width: 230,
    },
  ];

  return (
    <>
      <div>
        <Style.CourseManagerContainer>
          <Table
            pagination={false}
            scroll={{ x: 1500, y: 506 }}
            columns={columns}
            dataSource={tableData}
            locale={{ emptyText: "現在管理可能なコースがありません。" }}
            loading={listCourseCompany.load}
          />
        </Style.CourseManagerContainer>
        {!isEmpty(listCourseCompany.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getListCourseCompany}
            sortCondition={sortCondition}
            dataParam={{
              userId: userId,
              requestData: {
                page: meta.pageInfo?.page,
              },
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => ({
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseManager);
