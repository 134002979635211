//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';

interface IChartDotProps {
  cx?: number;
  cy?: number;
  dotColor: string;
}

const ChartDot: React.FC<IChartDotProps> = ({
  cx,
  cy,
  dotColor,
}) => (
  <circle cx={cx} cy={cy} r={3} stroke={dotColor} strokeWidth={3} fill={dotColor} />
);

export default ChartDot;
