// # gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const ProgressNotificationOverview = styled.div``;

export const ProgressNotificationContainer = styled.div`
  margin: 24px 40px;
`;

export const ProgressNotificationSchedule = styled.div``;

export const TwoDots = styled.span`
  margin: 0 25px;
  font-size: 18px;
  font-weight: bold;
`
