// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  AUTH_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../constant';

const initialState = {
  tokenInfo: {},
  resetPasswordStatus: false,
  isFailGetResetPassword: false,
  errors: [],
  errorsForgot: [],
  errorsReset: [],
};

export type AuthReducer = Readonly<typeof initialState>;

export default (state: AuthReducer = initialState, action: any): AuthReducer => {
  switch (action.type) {
  case SUCCESS(AUTH_ACTION.LOGIN): {
    const { tokenInfo } = action.payload;
    return {
      ...state,
      tokenInfo,
      errors: [],
    };
  }
  case FAILURE(AUTH_ACTION.LOGIN): {
    const { errors } = action;
    return {
      ...state,
      errors,
    };
  }

  case SUCCESS(AUTH_ACTION.LOGOUT): {
    return {
      ...state,
      tokenInfo: {},
      errors: [],
    };
  }
  case FAILURE(AUTH_ACTION.LOGOUT): {
    const { errors } = action;
    return {
      ...state,
      errors,
    };
  }

  case REQUEST(AUTH_ACTION.GET_RESET_PASSWORD_STATUS): {
    return {
      ...state,
      resetPasswordStatus: false,
    };
  }
  case SUCCESS(AUTH_ACTION.GET_RESET_PASSWORD_STATUS): {
    return {
      ...state,
      resetPasswordStatus: true,
    };
  }
  case FAILURE(AUTH_ACTION.GET_RESET_PASSWORD_STATUS): {
    return {
      ...state,
      resetPasswordStatus: false,
      isFailGetResetPassword: true
    };
  }

  case REQUEST(AUTH_ACTION.CLEAR_FLAG_GET_RESET_ERROR): {
    return {
      ...state,
      isFailGetResetPassword: false
    };
    }

  case REQUEST(AUTH_ACTION.CLEAR_ERROR): {
    return {
      ...state,
      errorsForgot: [],
      errorsReset: [],
      errors: [],
    };
  }

  case FAILURE(AUTH_ACTION.RESET_PASSWORD): {
    const { errors } = action;
    return {
      ...state,
      errorsReset: errors,
    };
  }

  case FAILURE(AUTH_ACTION.FORGOT_PASSWORD): {
    const { errors } = action;
    return {
      ...state,
      errorsForgot: errors,
    };
  }

  case SUCCESS(AUTH_ACTION.FORGOT_PASSWORD): {
    return {
      ...state,
      errorsForgot: [],
    };
  }

  default:
    return state;
  }
};
