// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  testList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  testDetail: {
    data: {},
    meta: {},
    errors: [],
  },
  questionList: {
    data: [] as {
      id: number,
    }[],
    meta: {},
    errors: [],
  },
  questionDetail: {
    data: [] as object[],
    meta: {},
    errors: [],
  },
  testQuestions: {
    data: {} as {
      unitVersionId: number;
      questions: object[];
      test: object;
    },
    meta: {},
    load: false,
    errors: [],
  },
};

export type TestReducer = Readonly<typeof initialState>;

export default (state: TestReducer = initialState, action: any): TestReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_LIST): {
    return {
      ...state,
      testList: {
        ...state.testList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testList: {
        data: data.tests,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      testList: {
        ...state.testList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testDetail: {
        data: data.test,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      testDetail: {
        ...state.testDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.UPDATE_TEST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testDetail: {
        data: data.test,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.UPDATE_TEST): {
    const { errors } = action;
    return {
      ...state,
      testDetail: {
        ...state.testDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_QUESTIONS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      questionList: {
        data: data.questions,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_QUESTIONS): {
    const { errors } = action;
    return {
      ...state,
      questionList: {
        ...state.questionList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.MOVE_QUESTION): {
    const { meta } = action.payload;
    const newQuestionList = state.questionList.data;
    const dragQuestion = newQuestionList[meta.dragIndex];
    newQuestionList.splice(meta.dragIndex, 1);
    newQuestionList.splice(meta.hoverIndex, 0, dragQuestion);
    return {
      ...state,
      questionList: {
        data: newQuestionList,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.MOVE_QUESTION): {
    const { errors } = action;
    return {
      ...state,
      questionList: {
        ...state.questionList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.GET_QUESTION_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      questionDetail: {
        data: data.question,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.GET_QUESTION_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      questionDetail: {
        ...state.questionDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION): {
    const { data, meta } = action.payload;
    return {
      ...state,
      questionList: {
        data: [...state.questionList.data, data.question],
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION): {
    const { errors } = action;
    return {
      ...state,
      questionList: {
        ...state.questionList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.UPDATE_QUESTION): {
    const { data, meta } = action.payload;
    const newQuestionList = state.questionList.data;
    const updatedQuestionIndex = newQuestionList.findIndex((question) => question.id === data.question.id);
    newQuestionList.splice(updatedQuestionIndex, 1, data.question);
    return {
      ...state,
      questionList: {
        data: newQuestionList,
        meta,
        errors: [],
      },
      questionDetail: {
        data: data.question,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.UPDATE_QUESTION): {
    const { errors } = action;
    return {
      ...state,
      questionDetail: {
        ...state.questionDetail,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION): {
    const { questionId } = action.payload;
    const newQuestionList = state.questionList.data;
    const removedQuestionIndex = newQuestionList.findIndex((question) => question.id === questionId);
    newQuestionList.splice(removedQuestionIndex, 1);
    return {
      ...state,
      questionList: {
        data: newQuestionList,
        meta: {},
        errors: [],
      },
      questionDetail: {
        data: [],
        meta: {},
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION): {
    const { errors } = action;
    return {
      ...state,
      questionList: {
        ...state.questionList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.TEST_ACTION.JOIN_OVERVIEW_TEST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testQuestions: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.TEST_ACTION.JOIN_OVERVIEW_TEST): {
    const { errors } = action;
    return {
      ...state,
      testQuestions: {
        ...state.testQuestions,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.TEST_ACTION.CLEAR_TEST_DATA): {
    return {
      ...initialState,
    };
  }

  default:
    return state;
  }
};
