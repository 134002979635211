// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Col,
  Space,
  Button,
  Select,
  Table,
  Input,
  DatePicker,
  Checkbox,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { find } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import _ from 'lodash'

import history from 'src/utils/history';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import {
  MainContainer,
  MainContent,
} from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';

import { COURSE_DURATION_TITLE, DATE_FORMAT } from 'src/constants/common';
import { CompanyPath } from 'src/constants/routerConstants';
import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import {
  getCourses as getCoursesAction,
  getStudents as getStudentsAction,
  clearStudents as clearStudentsAction,
  companyAddStudentsToCourse as companyAddStudentsToCourseAction,
  getCompanyContractDetail as getCompanyContractDetailAction,
  showModal as showModalAction,
  companyAddStudentsToCourseByCsv as companyAddStudentsToCourseByCsvAction,
  closeModal as closeModalAction
} from 'src/redux/actions';

import datePickerItem from 'src/assets/images/common/datepicker-icon.png';

import * as Style from './styles';
import AddCsvModal from 'src/components/Modal/components/AddCsvModal';
import { handleShowMessageOnSettingLicense } from 'src/utils/common';

interface ILicenseGrantPageProps extends StateProps, DispatchProps {
  getCourses(): void;
  getStudents(params): void;
  clearStudents(): void;
  companyAddStudentsToCourse(params): void;
  getCompanyContractDetail(params): void;
  showModal(params): void;
  companyAddStudentsToCourseByCsv(params): void;
  closeModal(): void;
  companyDetail: {
    data: {
      id: number,
      contractName: string,
    },
    meta: {};
    load: boolean;
  };
  courses: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
      coursesCompany: {
        courseDurations: string[];
        licensesCount: number;
        usedLicensesCount: number;
        startDate: string;
        endDate: string;
        remainingDays: number;
        isProcessing: boolean;
        recommendedEndDate: string;
        recommendedStartDate: string;
        recommendedCompletionDay: number;
      }
    }[],
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
        next: number;
      };
    },
    load: boolean;
    errors: object[];
  };
  students: {
    data: {
      studentList: {
        id: number;
        jituName: string;
        department: string;
        email: string;
      }[];
      customDisplayField: {
        department: boolean;
      };
    },
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    },
    load: boolean;
    errors: {
      message: string;
    }[];
  };
  dataAddCsv: {
    statusAddCsv: string,
    courseIdSelected: any,
    error: {
      message: string;
    },
  }
}

const LicenseGrantPage: React.FC<ILicenseGrantPageProps> = ({
  getCourses,
  getStudents,
  clearStudents,
  companyAddStudentsToCourse,
  getCompanyContractDetail,
  courses,
  students,
  showModal,
  companyDetail,
  companyAddStudentsToCourseByCsv,
  dataAddCsv,
  closeModal
}) => {
  const [courseSelected, setCourseSelected] = useState<any>(undefined);
  const [durationSelected, setDurationSelected] = useState<any>(undefined);
  const [studentsSelected, setStudentsSelected] = useState<any>([]);
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});
  const [limitTimeSelected, setLimitTimeSelected] = useState<any>({});
  const [limitTimeError, setLimitTimeError] = useState<string>('');
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const [isShowErrorMessage, setIsShowErrorMessage] = useState<boolean>(false)

  const startDateFormat = courseSelected && moment(courseSelected.coursesCompany.startDate).format('YYYY/MM/DD');
  const endDateFormat = courseSelected && moment(courseSelected.coursesCompany.endDate).format('YYYY/MM/DD');
  const durationOptions = courseSelected
    ? courseSelected.coursesCompany.courseDurations.map((durationType) => ({
      label: COURSE_DURATION_TITLE[durationType],
      value: durationType,
    }))
    : [];

  const urlSelectCourseId = history.location.state?.courseId;

  function disabledDate(current) {
    return (current < moment(courseSelected.coursesCompany.startDate)
      || current > moment(courseSelected.coursesCompany.endDate));
  }

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.LicenseGrant);

    if (isEmpty(companyDetail.data)) getCompanyContractDetail({});
    if (isEmpty(courses.data)) getCourses();

    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    if (durationSelected === "other") {
      setIsShowErrorMessage(
        handleShowMessageOnSettingLicense(
          courseSelected?.coursesCompany.recommendedStartDate,
          courseSelected?.coursesCompany.recommendedEndDate,
          limitTimeSelected,
          courseSelected?.coursesCompany.recommendedCompletionDay
        )
      );
    } else if (!isEmpty(durationSelected)) {
      const courseDurationOptions = Object.keys(COURSE_DURATION_TITLE);
      const courseDurationIndex = courseDurationOptions.findIndex((item) => item === durationSelected);
      const isWeek = courseDurationOptions[courseDurationIndex].includes("week");
      const userEndDate = moment().startOf("day")
        .add(
          isWeek ? courseDurationIndex + 1 : courseDurationIndex - 2,
          isWeek ? "w" : "M"
        )
        .subtract(1,'days');
      setIsShowErrorMessage(
        handleShowMessageOnSettingLicense(
          courseSelected?.coursesCompany.recommendedStartDate,
          courseSelected?.coursesCompany.recommendedEndDate,
          {
            startDate: moment().startOf("day"),
            endDate:
              userEndDate > moment(courseSelected?.coursesCompany.endDate)
                ? moment(courseSelected?.coursesCompany.endDate)
                : userEndDate,
          },
          courseSelected?.coursesCompany.recommendedCompletionDay
        )
      );
    }
  }, [durationSelected, limitTimeSelected]);

  useEffect(() => {
    if (dataAddCsv.statusAddCsv === "success") {
      setCourseSelected(find(courses.data, { id: parseFloat(dataAddCsv.courseIdSelected) }));
    }
  }, [courses.data])

  useEffect(() => {
    dataAddCsv.statusAddCsv === "success" && handleAddCsvSuccess();
    dataAddCsv.statusAddCsv === "error" && handleAddCsvFail();
  }, [dataAddCsv])

  useEffect(() => {
    if (urlSelectCourseId && !isEmpty(courses.data) && !courseSelected) {
      setCourseSelected(find(courses.data, { id: parseFloat(urlSelectCourseId) }));
    }
  }, [urlSelectCourseId, courses.data]);

  useEffect(() => {
    if (courseSelected) {
      getStudents({
        requestData: {
          courseId: courseSelected.id,
          items: students.meta.pageInfo?.limit || 10,
          page: 1,
        },
      });
    } else {
      clearStudents();
    }

    if(!isEmpty(courses.data)){
      const index = courses.data.findIndex((item) => item.id === courseSelected?.id);
      setIsDisable(courses.data[index]?.coursesCompany.isProcessing)
    }
  }, [courseSelected]);

  useEffect(() => {
    const check = checkArrayInArray([...tableDataKeys], [...studentsSelected]);
    const checkItem = checkArrayInArray([...tableDataKeys], [...studentsSelected], true,);
    setCheckAll(
      check && !isEmpty(students.data.studentList) ? true : false,
    );
    setIndeterminate(check ? false : checkItem)
  }, [students]);


  const columns = [
    {
      title: 'ユーザーID',
      dataIndex: 'id',
      width: 120,
    },
    {
      title: 'ユーザー名',
      dataIndex: 'jituName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.jituName}
        </TooltipParagraph>
      ),
      width: 300,
    },
    ...students.data.customDisplayField.department
      ? [{
        title: '部署',
        dataIndex: 'department',
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.department}
          </TooltipParagraph>
        ),
        width: 200,
      }]
      : [],
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      width: 300,
    },
  ];

  const tableData = students.data.studentList.map((row) => ({
    key: row.id,
    id: row.id,
    jituName: row.jituName,
    email: row.email,
    ...students.data.customDisplayField.department && { department: row.department },
  }));

  const tableDataKeys = students.data.studentList.map((row) => row.id);
  function onCheckAllChange(e) {
    if (
      courseSelected.coursesCompany.licensesCount -
      courseSelected.coursesCompany.usedLicensesCount -
      studentsSelected.length < tableDataKeys.length && e.target.checked
    ) {
      return null;
    } else {
      setCheckAll(e.target.checked);
      setIndeterminate(false);
      return setStudentsSelected(
        e.target.checked
          ? _.uniq([...studentsSelected, ...tableDataKeys])
          : _.difference(studentsSelected, tableDataKeys),
      );
    }
  }

  function checkArrayInArray(
    arr1: any[],
    arr2: any[],
    isItem: boolean = false,
  ) {
    let count = 0;
    arr1.forEach((arrItem1) => {
      arr2.forEach((arrItem2, index2) => {
        if (arrItem1 === arrItem2) {
          arr2.splice(index2, 1);
          count++;
        }
      });
    });
    if (isItem) {
      return count > 0;
    }
    return count === arr1.length ? true : false;
  }

  function handleShowModalAddCsv() {
    const modalProps = {
      isOk: true,
      isClose: true,
      modalData: {
        title: 'CSVファイルのアップロード',
        okText: '一括登録する',
        closeText: 'キャンセル',
        contractName: companyDetail.data && companyDetail.data.contractName,
        courseName: courseSelected.courseCategory.name
      },
      requestData: {
        ...!isEmpty(durationSelected) && { durationType: durationSelected },
        ...(limitTimeSelected.startDate && durationSelected === 'other') &&
        { startDate: moment(limitTimeSelected.startDate).format(DATE_FORMAT) },
        ...(limitTimeSelected.endDate && durationSelected === 'other') &&
        { endDate: moment(limitTimeSelected.endDate).format(DATE_FORMAT) },
      },
      courseId: courseSelected?.id,
      modalAction: {
        AddStudentsToCourseByCsv: (params) => companyAddStudentsToCourseByCsv(params)
      },
    };
    return showModal(<AddCsvModal {...modalProps} />);
  }

  function handleAddCsvSuccess() {
    const modalProps = {
      isOk: true,
      modalData: {
        title: 'CSVファイルのアップロード',
        okText: '完了',
        contractName: companyDetail.data && companyDetail.data.contractName,
        courseName: courseSelected.courseCategory.name,
        watting: true
      },
    }
    showModal(<AddCsvModal {...modalProps} />)
  };

  function handleAddCsvFail() {
    const modalProps = {
      isOk: true,
      isClose: true,
      modalData: {
        title: 'CSVファイルのアップロード',
        okText: '一括登録する',
        closeText: 'キャンセル',
        contractName: companyDetail.data && companyDetail.data.contractName,
        courseName: courseSelected.courseCategory.name,
        errorText: `${dataAddCsv.error[0]?.message}`,
      },
      requestData: {
        ...!isEmpty(durationSelected) && { durationType: durationSelected },
        ...(limitTimeSelected.startDate && durationSelected === 'other') &&
        { startDate: moment(limitTimeSelected.startDate).format(DATE_FORMAT) },
        ...(limitTimeSelected.endDate && durationSelected === 'other') &&
        { endDate: moment(limitTimeSelected.endDate).format(DATE_FORMAT) },
      },
      courseId: courseSelected?.id,
      modalAction: {
        AddStudentsToCourseByCsv: (params) => companyAddStudentsToCourseByCsv(params)
      },
    }
    showModal(<AddCsvModal {...modalProps} />)
  }

  function handleSelectCourse(courseId) {
    setCourseSelected(find(courses.data, { id: courseId }));
    setDurationSelected(undefined);
    setStudentsSelected([]);
    setParamsFilter({});
    setIsShowErrorMessage(false)
  }

  function handleAddStudentToCourse() {
    if (!courseSelected || isEmpty(studentsSelected)) return null;
    companyAddStudentsToCourse({
      courseId: courseSelected.id,
      requestData: {
        userIds: studentsSelected,
        ...!isEmpty(durationSelected) && { durationType: durationSelected },
        ...(limitTimeSelected.startDate && durationSelected === 'other') &&
        { startDate: moment(limitTimeSelected.startDate).format(DATE_FORMAT) },
        ...(limitTimeSelected.endDate && durationSelected === 'other') &&
        { endDate: moment(limitTimeSelected.endDate).format(DATE_FORMAT) },
      },
    });
    const newCourseSelected = courseSelected;
    newCourseSelected.coursesCompany.usedLicensesCount = newCourseSelected.coursesCompany.usedLicensesCount
      + studentsSelected.length;
    setCourseSelected(newCourseSelected);
    setStudentsSelected([]);
  }

  function renderCoursesOptions() {
    if (isEmpty(courses.data)) return null;
    return courses.data.map((course) => (
      <Select.Option key={`course-${course.id}`} value={course.id}>
        {`${course.courseCategory.name} - バージョン : ${course.name}`}
      </Select.Option>
    ));
  }

  const dataFilterFields = [
    {
      type: 'textInput',
      fieldParams: 'name',
      title: 'ユーザー名',
    },
    {
      type: 'textInput',
      fieldParams: 'email',
      title: 'メールアドレス',
    },
  ];

  return (
    <MainContainer backgroundHeight={290}>
      <MainContent fullView>
        <Style.LicenseOverviewContainer>
          <Text headerText xxxl w6>ライセンス付与</Text>
          <Row gutter={32} style={{ marginTop: 16 }}>
            <Col span={12}>
              <Row>
                <Style.LicenseCol span={4}>
                  <Text>契約名</Text>
                </Style.LicenseCol>
                <Col span={20}>
                  <Style.LicenseSelect>
                    <Input
                      value={companyDetail.data && companyDetail.data.contractName}
                      disabled
                      style={{ width: '100%' }}
                    />
                  </Style.LicenseSelect>
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }}>
                <Style.LicenseCol span={4}>
                  <Text>コース名</Text>
                </Style.LicenseCol>
                <Col span={20}>
                  <Style.LicenseSelect>
                    <Select
                      allowClear
                      showSearch
                      suffixIcon={<CaretDownOutlined />}
                      filterOption={(input: any, option: any) => {
                        const optionTitle = option.children;
                        const filterValue = (input || '').trim();
                        return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                      }}
                      placeholder="選択してください"
                      notFoundContent="データが存在しません。"
                      loading={courses.load}
                      onChange={(value) => handleSelectCourse(value)}
                      value={courseSelected && courseSelected.id}
                      style={{ width: '100%' }}
                      dropdownClassName="course_version_select"
                    >
                      {renderCoursesOptions()}
                    </Select>
                  </Style.LicenseSelect>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Style.LicenseOverviewContent>
                <Space align="center">
                  <Text xs secondaryText w6>受講開始日</Text>
                  <Text headerText w6>
                    {courseSelected && courseSelected.coursesCompany.startDate ? startDateFormat : 'ー'}
                  </Text>
                </Space>
              </Style.LicenseOverviewContent>
              <Style.LicenseOverviewContent>
                <Space align="center">
                  <Text xs secondaryText w6>受講終了日</Text>
                  <Text headerText w6>
                    {courseSelected && courseSelected.coursesCompany.endDate ? `${endDateFormat}${courseSelected.coursesCompany.remainingDays >= 0 ? `[残り${courseSelected.coursesCompany.remainingDays}日]` : ''
                      }` : 'ー'}
                  </Text>
                </Space>
              </Style.LicenseOverviewContent>
            </Col>
          </Row>
          <Row gutter={32} style={{ marginTop: 16 }}>
            <Col span={7}>
              <Row>
                <Style.LicenseCol span={7}>
                  <Text>ライセンス期間</Text>
                </Style.LicenseCol>
                <Col span={17}>
                  <Style.LicenseSelect style={{ marginLeft: 2 }}>
                    <Select
                      allowClear
                      suffixIcon={<CaretDownOutlined />}
                      placeholder="選択してください"
                      notFoundContent="データが存在しません。"
                      onChange={(value) => {
                        setDurationSelected(value)
                        if (value === 'other') {
                          setLimitTimeSelected({
                            startDate: moment(courseSelected.coursesCompany.startDate, 'YYYY-MM-DD'),
                            endDate: moment(courseSelected.coursesCompany.endDate, 'YYYY-MM-DD'),
                          });
                        } else {
                          setIsShowErrorMessage(false)
                          setLimitTimeSelected({});
                          setLimitTimeError('');
                        }
                      }}
                      options={[...durationOptions, { label: '開始・終了日で設定', value: 'other' }]}
                      value={durationSelected}
                      disabled={!courseSelected}
                      style={{ width: 200 }}
                    />
                  </Style.LicenseSelect>
                </Col>
              </Row>
            </Col>
            {durationSelected === 'other' ? (
              <>
                <Col span={5}>
                  <Row>
                    <Style.LicenseCol span={6}>
                      <Text>開始日</Text>
                    </Style.LicenseCol>
                    <Col span={18}>
                      <Style.DatePickerContainer>
                        <DatePicker
                          placeholder=""
                          format="YYYY/MM/DD"
                          {...limitTimeError !== '' && { style: { borderColor: '#D25109' } }}
                          suffixIcon={<img src={datePickerItem} alt="" />}
                          value={limitTimeSelected.startDate}
                          disabledDate={disabledDate}
                          onChange={(value) => {
                            setLimitTimeSelected({ ...limitTimeSelected, startDate: value });
                            if (!value || !limitTimeSelected.endDate) {
                              return setLimitTimeError('開始日と終了日は必須項目です。 ');
                            }
                            if (value > limitTimeSelected.endDate.endOf("day")) {
                              return setLimitTimeError('開始日は終了日より前の日付を入力してください。');
                            }
                            return setLimitTimeError('');
                          }}
                        />
                      </Style.DatePickerContainer>
                    </Col>
                  </Row>
                </Col>
                <Col span={5}>
                  <Row>
                    <Style.LicenseCol span={6}>
                      <Text>終了日</Text>
                    </Style.LicenseCol>
                    <Col span={18}>
                      <Style.DatePickerContainer>
                        <DatePicker
                          placeholder=""
                          bordered
                          {...limitTimeError !== '' && { style: { borderColor: '#D25109' } }}
                          format="YYYY/MM/DD"
                          suffixIcon={<img src={datePickerItem} alt="" />}
                          value={limitTimeSelected.endDate}
                          disabledDate={disabledDate}
                          onChange={(value) => {
                            setLimitTimeSelected({ ...limitTimeSelected, endDate: value });
                            if (!value || !limitTimeSelected.startDate) {
                              return setLimitTimeError('開始日と終了日は必須項目です。');
                            }
                            if (value < limitTimeSelected.startDate.startOf('day')) {
                              return setLimitTimeError('開始日は終了日より前の日付を入力してください。');
                            }
                            return setLimitTimeError('');
                          }}
                        />
                      </Style.DatePickerContainer>
                    </Col>
                  </Row>
                </Col>
                <Style.LicenseCol span={7} >
                  <Text error>{limitTimeError}</Text>
                </Style.LicenseCol>
              </>
             )
             : isShowErrorMessage && 
               <Text color="red" style={{margin: "-5px 0 0 -48px"}}>
                 ※完了推奨期間がライセンス期間外のため、この状態では完了推奨日は表示されません。
                 <br/>
                 設定を再度確認してください。
               </Text>
            }
          </Row>
          <Row gutter={32} style={{ marginTop: 16 }}>
            <Col span={7}>
              <Space align="center" size="large">
                <Style.HeaderBlock>
                  <Text headerText w6 margin="0 5px 0 0" style={{ fontSize: 14 }}>
                    ライセンス総数:
                  </Text>
                  <Text style={{ fontSize: 20 }}>
                    {courseSelected ? courseSelected.coursesCompany.licensesCount
                      : "ー"
                    }
                  </Text>
                </Style.HeaderBlock>
                <Style.HeaderBlock>
                  <Text headerText w6 margin="0 5px 0 0" style={{ fontSize: 14 }}>
                    登録済み:
                  </Text>
                  <Text style={{ fontSize: 20 }}>
                    {courseSelected
                      ? courseSelected.coursesCompany.usedLicensesCount
                      : "ー"
                    }
                  </Text>
                </Style.HeaderBlock>
              </Space>
            </Col>
            <Col span={15} >
              {isShowErrorMessage && durationSelected === 'other' &&
                <Text color="red">
                  ※完了推奨期間がライセンス期間外のため、この状態では完了推奨日は表示されません。設定を再度確認してください。
                </Text>}
            </Col>
          </Row>
        </Style.LicenseOverviewContainer>
        <Style.LicenseDataContainer>
          <Style.AddCsvFileContainer>
            <Text headerText xxxl w6>
              一括登録
            </Text>
            <Text style={{ marginTop: 8 }}>
                メールアドレスを記載したCSVファイルをアップロードすることで、ライセンスをまとめて付与することができます。
            </Text>
            <Style.AddCsvFileContent>
              <Button
                type="primary"
                disabled={isEmpty(courseSelected) ||
                  courseSelected.coursesCompany.licensesCount -
                  courseSelected.coursesCompany.usedLicensesCount -
                  studentsSelected.length === 0 || isDisable}
                onClick={() => { handleShowModalAddCsv() }}
              >
                CSVアップロード
              </Button>
            </Style.AddCsvFileContent>
          </Style.AddCsvFileContainer>

          <Style.LicenseDataTableHeader>
            <div style={{ width: 316 }}>
              <Text headerText w6 xxxl>
                個別設定
              </Text>
              <Text style={{ marginTop: 8 }}>
                個別にライセンスを管理することができます。
              </Text>
            </div>
            <div style={{ width: "calc(100% - 316px)" }}>
              <Space align="center" size="large" style={{ marginTop: 16 }}>
                <Style.HeaderBlock >
                  <Text w6 headerText margin="0 5px 0 0" style={{ fontSize: 14 }}>
                    選択数:
                  </Text>
                  <Text style={{ fontSize: 20 }}>
                    {courseSelected ? studentsSelected.length : "ー"}
                  </Text>
                </Style.HeaderBlock>
                <Style.HeaderBlock>
                  <Text w6 headerText margin="0 5px 0 0" style={{ fontSize: 14 }}>
                    残ライセンス数:
                  </Text>
                  <Text style={{ fontSize: 20 }}>
                    {courseSelected
                      ? courseSelected.coursesCompany.licensesCount -
                      courseSelected.coursesCompany.usedLicensesCount -
                      studentsSelected.length
                      : "ー"
                    }
                  </Text>
                </Style.HeaderBlock>
              </Space>
            </div>
          </Style.LicenseDataTableHeader>
          <Style.LicenseDataTable>
            <Style.UserListSidebar>
              <FilterSidebar
                data={dataFilterFields}
                actionFilter={getStudents}
                setParamsFilter={setParamsFilter}
                setSortCondition={setSortCondition}
                dataParam={{
                  requestData: {
                    courseId: courseSelected?.id,
                    page: students.meta.pageInfo?.page,
                    items: students.meta.pageInfo?.limit,
                  },
                }}
                filterParams={filterParams}
                disabled={isEmpty(courseSelected)}
                pageSize={students.meta.pageInfo?.limit}
              />
            </Style.UserListSidebar>
            <div style={{ width: 'calc(100% - 316px)' }}>
              <Table
                rowSelection={{
                  type: 'checkbox',
                  columnTitle: (
                    <div>
                      <Text w6 color="white">選択</Text>
                      <Checkbox
                        disabled={isEmpty(students.data.studentList)}
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                      />
                    </div>
                  ),
                  columnWidth: 100,
                  selectedRowKeys: [...studentsSelected],
                  onChange: (keys) => {
                    const check = checkArrayInArray([...tableDataKeys], [...keys],);
                    const checkItem = checkArrayInArray([...tableDataKeys], [...keys], true,);
                    if (
                      courseSelected.coursesCompany.licensesCount
                      - courseSelected.coursesCompany.usedLicensesCount
                      - keys.length < 0
                    ) {
                      return null;
                    }
                    else {
                      setIndeterminate(!check && checkItem);
                      setCheckAll(check);
                      setStudentsSelected([...keys]);
                    }
                  },
                }}
                loading={students.load}
                pagination={false}
                scroll={{
                  x: students.data.customDisplayField.department
                    ? 1020
                    : 820,
                  y: 506,
                }}
                columns={columns}
                dataSource={tableData}
                locale={{ emptyText: 'データが存在しません。' }}
              />
              {!isEmpty(students.data.studentList) && (
                <PaginationLayout
                  pageInfo={students.meta.pageInfo}
                  onChangePage={getStudents}
                  filterParams={filterParams}
                  dataParam={courseSelected
                    ? {
                      requestData: {
                        courseId: courseSelected.id,
                        page: 1,
                      },
                    }
                    : {}
                  }
                />
              )}
              <Style.LicenseGroupAction>
                <Space size="middle">
                  <Button
                    type="primary"
                    className="btn-secondary"
                    onClick={() => history.push(CompanyPath.AdminCompanyTop)}
                  >
                    キャンセル
                  </Button>
                  <Button
                    type="primary"
                    disabled={isEmpty(studentsSelected) || limitTimeError !== ''}
                    onClick={() => handleAddStudentToCourse()}
                  >
                    保存する
                  </Button>
                </Space>
              </Style.LicenseGroupAction>
            </div>
          </Style.LicenseDataTable>
        </Style.LicenseDataContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { courses } = state.companyCourseReducer;
  const { students, dataAddCsv } = state.companyAccountReducer;
  const { companyDetail } = state.companyContractReducer;
  return {
    courses,
    students,
    companyDetail,
    dataAddCsv
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCourses: () => dispatch(getCoursesAction()),
  getStudents: (params) => dispatch(getStudentsAction(params)),
  clearStudents: () => dispatch(clearStudentsAction()),
  companyAddStudentsToCourse: (params) => dispatch(companyAddStudentsToCourseAction(params)),
  getCompanyContractDetail: (params) => dispatch(getCompanyContractDetailAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  companyAddStudentsToCourseByCsv: (params) => dispatch(companyAddStudentsToCourseByCsvAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(LicenseGrantPage);
