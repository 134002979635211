//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import history from 'src/utils/history';

import {
  getStudentUnitDetail as getStudentUnitDetailAction,
  getStudentCourseDetail as getStudentCourseDetailAction,
  finishedSurvey as finishedSurveyAction,
} from 'src/redux/actions';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, StudentTitle } from 'src/constants/pageTitle';

import * as Style from './styles';

interface ISurveyDetailPageProps extends StateProps, DispatchProps {
  getStudentUnitDetail(params): void;
  getStudentCourseDetail(params): void;
  finishedSurvey(params): void;
  unitDetail: {
    data: {
      id: number;
      item: {
        name: string;
        description: number;
        url: string;
      };
      unit: {
        name: string;
      }
    };
    load: boolean;
  };
  courseTopDetail: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      };
    },
    load: boolean;
  };
}

const SurveyDetailPage: React.FC<ISurveyDetailPageProps> = ({
  unitDetail,
  courseTopDetail,
  getStudentUnitDetail,
  getStudentCourseDetail,
  finishedSurvey,
}) => {
  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[2];
  const unitVersionId = pathnameSplit[4];

  useEffect(() => {
    document.title = PAGE_TITLE(StudentTitle.SurveyOverview);
  }, []);

  useEffect(() => {
    getStudentUnitDetail({
      courseId,
      unitVersionId,
    });
    getStudentCourseDetail({ id: courseId });
  }, [courseId, unitVersionId]);

  return (
    <MainContainer isStudent>
      <MainContent fullView>
        {courseTopDetail.load
          ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
          : <Text white w6 xxl>{courseTopDetail.data.courseCategory?.name}</Text>
        }
        <Style.SurveyDetailContainer>
          <Style.SurveyDetailTitle>
            {courseTopDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text truncate white lg w6>{unitDetail.data.unit.name}</Text>
            }
          </Style.SurveyDetailTitle>
          <Style.SurveyDetailDescription>
            {courseTopDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : (
                <Text multiLine>
                  {unitDetail.data.item.description}
                </Text>
              )
            }
          </Style.SurveyDetailDescription>
          <Style.SurveyDetailFooter>
            <Button
              type="primary"
              onClick={() => {
                finishedSurvey({ courseId, unitVersionId });
                window.open(unitDetail.data.item.url, '_blank');
              }}
              disabled={courseTopDetail.load || unitDetail.load}
            >
              回答する
            </Button>
          </Style.SurveyDetailFooter>
        </Style.SurveyDetailContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { unitDetail } = state.studentUnitReducer;
  const { courseTopDetail } = state.studentCourseReducer;
  const { testQuestions } = state.studentTestReducer;
  return {
    unitDetail,
    courseTopDetail,
    testQuestions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  finishedSurvey: (params) => dispatch(finishedSurveyAction(params)),
  getStudentUnitDetail: (params) => dispatch(getStudentUnitDetailAction(params)),
  getStudentCourseDetail: (params) => dispatch(getStudentCourseDetailAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetailPage);
