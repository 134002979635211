// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CompanyStatisticServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getStatisticCourses() {
    return this.restClient.get('/company_manage/statistics/courses');
  }

  getStatisticStudents() {
    return this.restClient.get('/company_manage/statistics/students');
  }

  getCurrentWeek() {
    return this.restClient.get('/company_manage/statistics/current_week_datas');
  }

  getWeekChart(params) {
    return this.restClient.get('/company_manage/statistics/learning_time_by_wday_charts', params);
  }

  getDayChart(params) {
    return this.restClient.get('/company_manage/statistics/learning_time_charts', params);
  }
}
