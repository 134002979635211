// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const OverviewChart = styled.div`
  position: relative;
  padding: 24px 24px 0 24px;
  height: 500px;
  background-color: white;
`;

export const ChartDatePicker = styled.div`
  border-bottom: 1px solid #999999;
  ${(props) => props.invalidDate
    && css`
      border: 1px solid ${props.theme.colors.error}
    `}
`;

export const LabelTooltip = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #ccc;
  
  p {
    font-size: 14;
    font-weight: 500;
    color: #D29C09 !important;
  }
`;

export const OverallStudent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputChartField = styled.div`
  display: flex;
  margin-bottom: 8px;
`;
