// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const ACCOUNT_ACTION = {
  GET_CONFIRMATION_STATUS: 'ADMIN_COMPANY/ACCOUNT_ACTION/GET_CONFIRMATION_STATUS',
  CONFIRM: 'ADMIN_COMPANY/ACCOUNT_ACTION/CONFIRM',
  CONFIRM_COMPANY: 'ADMIN_COMPANY/ACCOUNT_ACTION/CONFIRM_COMPANY',
  GET_STUDENTS: 'ADMIN_COMPANY/ACCOUNT_ACTION/GET_STUDENTS',
  CLEAR_STUDENTS: 'ADMIN_COMPANY/ACCOUNT_ACTION/CLEAR_STUDENTS',
  ADD_STUDENTS_TO_COURSE: 'ADMIN_COMPANY/ACCOUNT_ACTION/ADD_STUDENTS_TO_COURSE',
  INVITE_STUDENTS: 'ADMIN_COMPANY/ACCOUNT_ACTION/INVITE_STUDENTS',
  INVITE_ADMIN_COMPANY: 'ADMIN_COMPANY/ACCOUNT_ACTION/INVITE_ADMIN_COMPANY',
  GET_INVITATION_LIST: 'ADMIN_COMPANY/ACCOUNT_ACTION/GET_INVITATION_LIST',
  RESEND_INVITATION: 'ADMIN_COMPANY/ACCOUNT_ACTION/RESEND_INVITATION',
  CANCEL_INVITATION: 'ADMIN_COMPANY/ACCOUNT_ACTION/CANCEL_INVITATION',
  ADD_STUDENTS_TO_COURSE_BY_CSV: "ADMIN_COMPANY/ACCOUNT_ACTION/ADD_STUDENTS_TO_COURSE_BY_CSV",
  BULK_RESEND_INVITATIONS: 'ADMIN_COMPANY/ACCOUNT_ACTION/BULK_RESEND_INVITATIONS',
  BULK_CANCEL_INVITATIONS: 'ADMIN_COMPANY/ACCOUNT_ACTION/BULK_CANCEL_INVITATIONS',
};
