// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Collapse, Radio, Row, Spin, Tag } from 'antd';
import { isArray, isNumber, isEmpty } from 'lodash/lang';
import MathJax from 'react-mathjax-preview';
import { maxBy } from 'lodash/math';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';
import {
  closeModal as closeModalAction,
  getFileDownloadURL as getFileDownloadURLAction,
  getStudentFileDownloadURL as getStudentFileDownloadURLAction,
  getDetailsQuestion as getDetailsQuestionAction
} from 'src/redux/actions';

import * as Style from './styles';
import { MainWrapper } from 'src/components/layouts/ContentLayout';
import closeIcon from 'src/assets/images/common/close-icon.svg'

interface ITestResultModalProps extends StateProps, DispatchProps {
  closeModal(): void;
  getFileDownloadURL(params): void;
  getStudentFileDownloadURL(params): void;
  getDetailsQuestion(params): void;
  modalData: {
    title: string;
    testType?: string;
    isReview: boolean;
    testExplanationFile: {
      id: number;
      explanationFile: {
        id: number;
        url: string;
      };
    };
    testResults: {
      id: number;
      answerInput: number | null;
      answerOptionIndex: number | null;
      score: number;
      answerFile: {
        url: string;
        filename: string;
      };
      isRight: boolean;
      question: {
        name: string;
        rightInput: number | null;
        rightOptionIndex: number | null;
        score: number;
        questionType: string;
        isScored: boolean;
        id: any;
      };
    }[];
    testScore: {
      score: number;
      testScorePercentage: number;
      isPass: boolean;
      completionStatus: string;
    };
    displayResults: string;
  };
  unitDetail: {
    data: {
      item: {
        testType: string;
      };
    };
  };
  dataTestResult:{
    data:{
      name: string;
      questionDetail:{
        id: number,
        score: number,
        isScored: boolean,
        name: string,
        questionType: string,
        content: string,
        questionOptions: [],
        image: {
          filename: string,
          url: string
        }
      },
    }[],
    meta:{},
    loadQuestionDetail: boolean
  }
}

const TestResultModal: React.FC<ITestResultModalProps> = ({
  closeModal,
  getFileDownloadURL,
  getStudentFileDownloadURL,
  modalData,
  unitDetail,
  getDetailsQuestion,
  dataTestResult
}) => {
  const [collapsesActivated, setCollapsesActivated] = useState<any>([])
  const [indexCurrent, setIndexCurrent] = useState<number>(-1)

  let testNotScoredYet = false;
  if (isArray(modalData.testResults)) {
    modalData.testResults.forEach((item) => {
      if (
        ((item.question.questionType === 'upload_file_type' && (item.answerInput || item.answerFile?.url))
        && item.question.isScored
        && !isNumber(item.score))
      ) {
        testNotScoredYet = true;
      }
    });
  }

  function renderTagResult(record, isColor) {
    if (record.questionType === 'upload_file_type') {
      if (!record.isScored || record.answerFile) return isColor ? 'orange' : '？';
      if (record.score === 0 || !record.answerFile) return isColor ? 'red' : <img src={closeIcon}/>;
      return isColor ? 'green' : '〇';
    } else if (record.isRight) {
      return isColor ? 'green' : '〇';
    }
    return isColor ? 'red' : <img src={closeIcon}/>;
  }

  const tableData = isArray(modalData.testResults)
    ? modalData.testResults.map((item, index) => ({
      key: item.id,
      id: item.id,
      idQuestion: item.question.id,
      isRight: (item.question.questionType === 'upload_file_type' && item.question.isScored)
        ? item.score > 0
        : item.isRight,
      questionIndex: `${index + 1}問目`,
      name: item.question.name,
      ...item.answerInput
        ? { studentAnswer: item.answerInput }
        : { studentAnswer: item.answerFile?.filename || item.answerOptionIndex },
      ...item.question.rightInput
        ? { rightAnswer: item.question.rightInput }
        : { rightAnswer: item.question.rightOptionIndex },
      score: item.isRight === false ? 0 : item.score,
      questionType: item.question.questionType,
      isScored: item.question.isScored,
      answerFile: item.answerFile
    }))
    : [];

  function renderScoreTitle() {
    if ((modalData.testScore.completionStatus === 'waiting' || testNotScoredYet)
      && modalData.testScore.completionStatus !== 'done') {
      return <Text xxxl w6 style={{ marginTop: 15 }}>採点待ち</Text>;
    }
    return (
      <Style.TestResultDetail>
        <Style.TestResultScore>
          <Style.TextTitle xxxl w6>得点</Style.TextTitle>
          <Style.TextScore
            w6
            style={{ fontSize: '56px', lineHeight: '64px', transform: 'translateY(4px)' }}
          >
            {modalData.testScore.score}
          </Style.TextScore>
          <Style.TextTitle xxxl w6>{`点（${modalData.testScore.testScorePercentage}%）`}</Style.TextTitle>
        </Style.TestResultScore>
        {(unitDetail.data.item.testType === 'finish_test' || modalData.testType === 'finish_test') && (
          <Style.TestResultStatus
            color={modalData.testScore.isPass ? 'green' : 'red'}
          >
            {modalData.testScore.isPass ? '合格' : '不合格'}
          </Style.TestResultStatus>
        )}
      </Style.TestResultDetail>
    );
  }

  function renderQuestionContent(question) {
    return (
      <>
        {question?.image && (
          <Style.QuestionImageContainer>
            <Style.QuestionImageContent src={question?.image.url} alt="" />
          </Style.QuestionImageContainer>
        )}
        <Style.QuestionContent>
          {question?.content && (
            <MathJax math={question.content} />
          )}
        </Style.QuestionContent>
      </>
    );
  }

  
  function renderQuestionContentMobile(question) {
    return (
      <>
        {question?.image && (
          <Style.QuestionImageContainer>
            <Style.QuestionImageContent src={question?.image.url} alt="" />
          </Style.QuestionImageContainer>
        )}
        <Style.QuestionContent>
          {question?.content && (
            <MathJax math={question.content} />
          )}
        </Style.QuestionContent>
      </>
    );
  }

  function renderOptionRadio(questionOptions, rightAnswer) {
    const maxLengthAnswer = maxBy(questionOptions, (answer) => answer.content.length);
    let answerWidth = '';
    const isMediumContent = maxLengthAnswer.content.length > 10 && maxLengthAnswer.content.length <= 30;
    const isLongContent = maxLengthAnswer.content.length > 30;
    if (questionOptions.length === 2 && !isLongContent) {
      answerWidth = 'calc(50% - 16px)';
    } else if ([3, 5, 6].includes(questionOptions.length) && !isLongContent) {
      answerWidth = 'calc(100% / 3 - 16px)';
    } else if (maxLengthAnswer.content.length <= 10) {
      answerWidth = 'calc(25% - 16px)';
    } else if (isMediumContent) {
      answerWidth = 'calc(50% - 16px)';
    } else {
      answerWidth = '100%';
    }
    return questionOptions.map((option, optionIndex) => (
      <Style.AnswerOptionWrapper
        isRight={rightAnswer === optionIndex + 1}
        key={`option-${option.id}-${optionIndex}`}
        style={{ width: answerWidth }}
      >
        {modalData.displayResults === "details" &&
          rightAnswer === optionIndex + 1 && <Style.CheckOutlinedMobile />}
        <Radio.Button
          className="answer-radio-button"
          value={optionIndex + 1}
        >
          {
            <Style.AnswerOption isRight={rightAnswer === optionIndex + 1}>
              {`${optionIndex + 1}. ${option.content}`}
              {modalData.displayResults === "details" &&
                rightAnswer === optionIndex + 1 && (
                  <Style.CheckOutlinedDesktop />
                )}
            </Style.AnswerOption>
          }
        </Radio.Button>
      </Style.AnswerOptionWrapper>
    ));
  }

  function renderAnswerContent(question,record) {
    switch (question?.questionType) {
    case 'option_type':
      return (
        <Radio.Group
          className="answer-radio-group"
          buttonStyle="solid"
          value={record?.studentAnswer}
          style={{ padding: "16px" }}
        >
          {renderOptionRadio(question?.questionOptions, record?.rightAnswer)}
        </Radio.Group>
      );
    case 'input_type':
      return (
          <Style.InputTypeBlock>
            <div>
              <div style={{width: "20%"}} >
                <Style.TextTitleAnswer w6>回答 : </Style.TextTitleAnswer>
              </div>
              <div style={{width: "90%"}}>
                <Text>{record.studentAnswer}</Text>
              </div>
            </div>
            {
             modalData.displayResults === "details" && (
              <>
                <br/>
                <div>
                  <div style={{width: "20%"}} >
                    <Style.TextTitleAnswer w6>正解 : </Style.TextTitleAnswer>
                  </div>
                  <div style={{width: "80%"}}>
                    <Text>{record.rightAnswer}</Text>
                  </div>
                </div>
              </>
            )
          }
          </Style.InputTypeBlock>

      );
    case 'upload_file_type':
      return(
        <>
          {record.studentAnswer &&
            <Style.UploadFileType>
              <Text truncate center >
                {record.studentAnswer}
              </Text>
            </Style.UploadFileType>}
          <Style.UploadFileTypeMobile>
              <div style={{width: "20%"}} >
                <Text margin={"0 8px 0 0"}>回答 : </Text>
              </div>
              <div style={{width: "80%"}}>
                <Text>{record.studentAnswer}</Text>
              </div>
          </Style.UploadFileTypeMobile>
        </>
      )
    default:
      return null;
    }
  }
  
  const handleChangeActivedKey = (values) => {
    setCollapsesActivated([...values])
  }

  function renderTestList() {
    return (
      <Collapse
        onChange={(values) => handleChangeActivedKey(values)}
        defaultActiveKey={[]}
        style={{ backgroundColor: '#FFF' }}
        activeKey={collapsesActivated}
        expandIcon={() => null}
        expandIconPosition="right"
      >
       {renderTestCollapse()}
      </Collapse>
    );
  }

  const renderTestCollapse = () => {
    if(isEmpty(tableData)) return null
    return tableData.map((record, index) => {
      return (
        <Collapse.Panel
          key={`course-${index}`}
          style={{ borderTop: '1px solid #FFFFFF', minHeight: 50 }}
          header={
            <Style.CollapseHeader
              onClick={ () => {
                setIndexCurrent(index);
                !dataTestResult.data[index].questionDetail && getDetailsQuestion({ id: record.idQuestion, index: index })
            }}>
              <Style.CollapseItemName style={{ width: '7%' }}>
                <Style.TagResultCustom
                  color={renderTagResult(record, true) as string}
                >
                  {renderTagResult(record, false)}
                </Style.TagResultCustom>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '8%', paddingLeft: 16 }}>
                <Text w6>{record.questionIndex}</Text>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '25%', paddingLeft: 16 }}>
                <TooltipParagraph 
                  rows={1} 
                  overlayStyle={{ maxHeight: 380, overflow: 'scroll' }} 
                  placement={"right"} 
                  autoAdjustOverflow={false} 
                >
                  {record.name}
                </TooltipParagraph>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '20%', paddingLeft: 16 }}>
                  <TooltipParagraph 
                    rows={1} 
                    overlayStyle={{ maxHeight: 380, overflow: 'scroll' }} 
                    placement={"right"} 
                    autoAdjustOverflow={false}
                  >
                    {record.studentAnswer ? record.studentAnswer.toString().replace(/(?:\r\n|\r|\n)/g, ' ') : '未回答'}
                  </TooltipParagraph>
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '25%', paddingLeft: 16 }}>
                {
                  record.questionType !== 'upload_file_type' ? 
                    <TooltipParagraph 
                      rows={1} 
                      overlayStyle={{ maxHeight: 380, overflow: 'scroll' }} 
                      placement={"right"} 
                      autoAdjustOverflow={false}
                    >
                      {record.rightAnswer ? record.rightAnswer.toString().replace(/(?:\r\n|\r|\n)/g, ' ') : 'ー'}
                    </TooltipParagraph> : 'ー'
                } 
              </Style.CollapseItemName >
              <Style.CollapseItemName style={{ width: '10%', paddingLeft: 16 }}>
                {
                !record.isScored ? '採点なし' :
                record.questionType === 'upload_file_type' 
                && modalData.testScore.completionStatus !== 'done' && record.answerFile ?
                  '未採点' : record.score
                }
              </Style.CollapseItemName>
              <Style.CollapseItemName style={{ width: '5%', paddingLeft: 16 }}>
                {collapsesActivated.includes(`course-${index}`) ? <MinusOutlined /> : <PlusOutlined />}
              </Style.CollapseItemName>
              {/* mobile */}
              <Style.CollapseItemNameMobile style={{ width: '8%' }}>
                <Style.TagResultCustom
                  color={renderTagResult(record, true) as string}
                >
                  {renderTagResult(record, false)}
                </Style.TagResultCustom>
              </Style.CollapseItemNameMobile>
              <Style.CollapseItemNameMobile style={{ width: '17%', paddingLeft: 8 }}>
                <Text w6>{record.questionIndex}</Text>
              </Style.CollapseItemNameMobile>
              <Style.CollapseItemNameMobile style={{ width: '20%', paddingLeft: 8 }}>
                <TooltipParagraph 
                  rows={1} 
                  overlayStyle={{ maxHeight: 380, overflow: 'scroll' }} 
                  placement={"right"} 
                  autoAdjustOverflow={false} 
                >
                  {record.name}
                </TooltipParagraph>
              </Style.CollapseItemNameMobile>
              <Style.CollapseItemNameMobile style={{ width: '20%', paddingLeft: 8 }}>
                  <TooltipParagraph 
                    rows={1} 
                    overlayStyle={{ maxHeight: 380, overflow: 'scroll' }} 
                    placement={"right"} 
                    autoAdjustOverflow={false}
                  >
                    {record.studentAnswer ? record.studentAnswer.toString().replace(/(?:\r\n|\r|\n)/g, ' ') : '未回答'}
                  </TooltipParagraph>
              </Style.CollapseItemNameMobile>
              <Style.CollapseItemNameMobile style={{ width: '22%', paddingLeft: 8 }}>
                {
                  record.questionType !== 'upload_file_type' ? 
                    <TooltipParagraph 
                      rows={1} 
                      overlayStyle={{ maxHeight: 380, overflow: 'scroll' }} 
                      placement={"right"} 
                      autoAdjustOverflow={false}
                    >
                      {record.rightAnswer ? record.rightAnswer.toString().replace(/(?:\r\n|\r|\n)/g, ' ') : 'ー'}
                    </TooltipParagraph> : 'ー'
                } 
              </Style.CollapseItemNameMobile >
              <Style.CollapseItemNameMobile style={{ width: '13%', paddingLeft: 8 }}>
                {
                !record.isScored ? '採点なし' :
                record.questionType === 'upload_file_type' 
                && modalData.testScore.completionStatus !== 'done' && record.answerFile ?
                  '未採点' : record.score
                }
              </Style.CollapseItemNameMobile>
            </Style.CollapseHeader>
          }
        >
          {
            index === indexCurrent && dataTestResult.loadQuestionDetail === true ? 
              <Spin style={{ width: '100%', margin: '10px auto' }} />
              :  
              <>
                <Style.TestContainer>
                  <Style.TestQuestion>
                    <Text w6 margin={"0 32px 0 0"}>
                      問{index + 1}
                    </Text>
                    {renderQuestionContent(
                      dataTestResult?.data[index]?.questionDetail
                    )}
                  </Style.TestQuestion>
                  <Text w6 margin={"16px 0 0 0"}>
                    解答
                  </Text>
                  <Style.TestAnswer>
                    {renderAnswerContent(
                      dataTestResult?.data[index]?.questionDetail,
                      record
                    )}
                  </Style.TestAnswer>
                </Style.TestContainer>
                <Style.TestContainerMobile>
                  <Style.TestQuestion>
                    {renderQuestionContentMobile(
                      dataTestResult?.data[index]?.questionDetail
                    )}
                  </Style.TestQuestion>
                  <Style.TestAnswer>
                    {renderAnswerContent(
                      dataTestResult?.data[index]?.questionDetail,
                      record
                    )}
                  </Style.TestAnswer>
                </Style.TestContainerMobile>
              </>
          }
      </Collapse.Panel>
  )})
  }

  return (
    <Style.TestResultContainer>
      <Style.TestResultOverview>
        {renderScoreTitle()}
        {modalData.testExplanationFile && modalData.testExplanationFile.explanationFile && (
          <Style.DownloadExplanation>
            <Style.ButtonDownloadTestMobile
              type="link"
              onClick={() => modalData.isReview
                ? getFileDownloadURL({ id: modalData.testExplanationFile?.id })
                : getStudentFileDownloadURL({ id: modalData.testExplanationFile?.id })
              }
            >
              解説ダウンロード
            </Style.ButtonDownloadTestMobile>
            <Style.ButtonDownloadTestDesktop
              type="primary"
              onClick={() => modalData.isReview
                ? getFileDownloadURL({ id: modalData.testExplanationFile?.id })
                : getStudentFileDownloadURL({ id: modalData.testExplanationFile?.id })
              }
            >
              解説ダウンロード
            </Style.ButtonDownloadTestDesktop>
          </Style.DownloadExplanation>
        )}
      </Style.TestResultOverview>
      {isArray(modalData.testResults) && (
        <MainWrapper
          title={
          <>
            <Style.CollapseItemName style={{ width: '7%' }}>
              <Style.TitleHeaderTable white w6>正誤</Style.TitleHeaderTable>
            </Style.CollapseItemName>
            <Style.CollapseItemName style={{ width: '8%', paddingLeft: 16  }}>
              <Style.TitleHeaderTable white w6>問目</Style.TitleHeaderTable>
            </Style.CollapseItemName>
            <Style.CollapseItemName style={{ width: '25%', paddingLeft: 16  }}>
              <Style.TitleHeaderTable white w6>問名</Style.TitleHeaderTable>
            </Style.CollapseItemName>
            <Style.CollapseItemName style={{ width: '20%', paddingLeft: 16  }}>
              <Style.TitleHeaderTable white w6>選択</Style.TitleHeaderTable>
            </Style.CollapseItemName>
            <Style.CollapseItemName style={{ width: '25%', paddingLeft: 16  }}>
              <Style.TitleHeaderTable white w6>正解</Style.TitleHeaderTable>
            </Style.CollapseItemName>
            <Style.CollapseItemName style={{ width: '10%', paddingLeft: 16  }}>
              <Style.TitleHeaderTable white w6>得点</Style.TitleHeaderTable>
            </Style.CollapseItemName>
            <Style.CollapseItemName style={{ width: '5%', paddingLeft: 16  }}>
              <Style.TitleHeaderTable white w6>詳細</Style.TitleHeaderTable>
            </Style.CollapseItemName>
            {/* mobile */}
            <Style.CollapseItemNameMobile style={{ width: '8%' }}>
              <Style.TitleHeaderTable white w6>正誤</Style.TitleHeaderTable>
            </Style.CollapseItemNameMobile>
            <Style.CollapseItemNameMobile style={{ width: '17%', paddingLeft: 8  }}>
              <Style.TitleHeaderTable white w6>問目</Style.TitleHeaderTable>
            </Style.CollapseItemNameMobile>
            <Style.CollapseItemNameMobile style={{ width: '20%', paddingLeft: 8  }}>
              <Style.TitleHeaderTable white w6>問名</Style.TitleHeaderTable>
            </Style.CollapseItemNameMobile>
            <Style.CollapseItemNameMobile style={{ width: '20%', paddingLeft: 8  }}>
              <Style.TitleHeaderTable white w6>選択</Style.TitleHeaderTable>
            </Style.CollapseItemNameMobile>
            <Style.CollapseItemNameMobile style={{ width: '22%', paddingLeft: 8  }}>
              <Style.TitleHeaderTable white w6>正解</Style.TitleHeaderTable>
            </Style.CollapseItemNameMobile>
            <Style.CollapseItemNameMobile style={{ width: '13%', paddingLeft: 8  }}>
              <Style.TitleHeaderTable white w6>得点</Style.TitleHeaderTable>
            </Style.CollapseItemNameMobile>
          </>
          }
          isStudent
          style={{maxHeight: "500px", overflowY: "scroll"}}
        >
          {isEmpty(tableData) ? 
            <Style.EmptyData>
              <Text>データが存在しません。</Text>
            </Style.EmptyData> 
            : renderTestList()
          }        
        </MainWrapper>
      )}
      <Style.TestResultFooter>
        <Button type="primary" className="btn-secondary" onClick={() => closeModal()}>
          閉じる
        </Button>
      </Style.TestResultFooter>
    </Style.TestResultContainer>
  );
};

const mapStateToProps = (state) => {
  const { unitDetail } = state.studentUnitReducer;
  const { dataTestResult } = state.studentTestReducer
  return {
    unitDetail,
    dataTestResult
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
  getFileDownloadURL: (params) => dispatch(getFileDownloadURLAction(params)),
  getStudentFileDownloadURL: (params) => dispatch(getStudentFileDownloadURLAction(params)),
  getDetailsQuestion: (params) => dispatch(getDetailsQuestionAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestResultModal);
