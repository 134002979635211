// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const ConfirmStudentContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;

  @media only screen and (max-width: 428px) {
    .ant-form label {
      font-size: 16px;
      font-weight: 700;
    }

    .ant-form-item-label {
      padding: 0;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }

    .ant-form-item-explain {
      font-size: 14px !important;
    }
  }
`;

export const ConfirmStudentContent = styled.div`
  padding: 24px;
`;

export const ConfirmStudentTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.headerText};
  color: white;

  @media only screen and (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const GroupAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    width: 100px;
  }

  ${(props) => props.hasSubText
    && css`
      justify-content: space-between;
    `}
  
  @media only screen and (max-width: 428px) {
    justify-content: center;
  }
`;

export const Link = styled.a`
  font-size: 14px;
  color: #0DA2FE;

  &:hover {
    color: #0DA2FE;
    opacity: 0.75;
  }
`;

export const GroupCheckbox = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 428px) {
    margin-top: 8px;
    align-items: flex-start;
  }
`;


export const GroupTextCheckbox = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 428px) {
    display: block;
  }
`;

