// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { findIndex } from 'lodash/array';
import { isNumber, isEmpty } from 'lodash/lang';
import { Button, Table } from 'antd';
import ReactTooltip from 'react-tooltip';

import { TEST_TYPE } from 'src/constants/common';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import { Text } from 'src/components/styles';
import { LoginStorage } from 'src/utils/localStorge';

import history from 'src/utils/history';

import * as Style from './styles';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

interface ITestScoreStudentsProps {
  getTestScoreStudents(params): void;
  showModal(params): void;
  closeModal(): void;
  downloadCsvTestStudent(params): void;
  setSortCondition(params): void
  companySelectedId: number;
  courseId: string | undefined;
  testScoreStudents: {
    data: {
      coursesUnitVersions: {
        id: number;
        itemType: string;
        unit: {
          name: string;
        };
      }[];
      usersScore: object[];
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  isStudentsFinishedTests: boolean;
  sortCondition: any;
}

const TestScoreStudents: React.FC<ITestScoreStudentsProps> = ({
  getTestScoreStudents,
  closeModal,
  showModal,
  downloadCsvTestStudent,
  companySelectedId,
  courseId,
  testScoreStudents,
  isStudentsFinishedTests,
  sortCondition,
  setSortCondition
}) => {

  const { data, meta } = testScoreStudents;
  const userStorage = LoginStorage.getData();

  const tableData = data.usersScore.map((row: any) => ({
    ...row,
    key: row.id,
    id: row.id,
  }));

  const coursesUnitVersionColumns = data.coursesUnitVersions.map((columnData) => ({
    title:
      <SortableHeader
        type={`coursesUnitVersion${columnData.id}`}
        title={
          <div>
            <TooltipParagraph isPreWrap rows={2} style={{ width: 110, color: 'white' }}>
              {columnData.unit.name}
            </TooltipParagraph>
            {columnData && <Text white center>{TEST_TYPE[columnData.itemType]}</Text>}
          </div>
        }
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        dataParam={{ id: courseId, requestData: { companyId: companySelectedId } }}
        actionSort={getTestScoreStudents}
        setAction={setSortCondition}
      />,
    dataIndex: `coursesUnitVersion${columnData.id}`,
    render: (_, record) => {
      const {
        highestRealScore,
        highestTestScore,
        isOnlyWaitingTest,
      } = record[`coursesUnitVersion${columnData.id}`];
      if (isOnlyWaitingTest) {
        return <Text>採点待ち</Text>
      } else {
        return (
          <Text>
            {isNumber(highestRealScore) && isNumber(highestTestScore)
              ? `${highestRealScore}点 (${highestTestScore}%)`
              : 'ー'
            }
          </Text>
        );
      };
    },
    width: 150,
  }));

  const tableColumns = [
    {
      title:
        <SortableHeader
          type="jituName"
          title="名前"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{ id: courseId, requestData: { companyId: companySelectedId } }}
          actionSort={getTestScoreStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'jituName',
      fixed: 'left' as any,
      render: (_, record) => (
        <Style.AccountName>
          <TooltipParagraph isPreWrap rows={2} {...(record.status === 'banned' && { style: { marginTop: 14 } } )}>
            {record.jituName}
          </TooltipParagraph>
          {record.status === 'banned' && (
            <Style.AccountStatus>停止</Style.AccountStatus>
          )}
        </Style.AccountName>
      ),
      width: 300,
    },
    {
      title:
        <SortableHeader
          type="allTest"
          title="全テスト"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{ id: courseId, requestData: { companyId: companySelectedId } }}
          actionSort={getTestScoreStudents}
          setAction={setSortCondition}
        />,
      dataIndex: 'allTest',
      render: (_, record) => (
        <Text>
          {isNumber(record.allTest)
            ? `${record.allTest}%`
            : 'ー'
          }
        </Text>
      ),
      width: 150,
    },
    ...findIndex(data.coursesUnitVersions, { itemType: 'pre_test' }) !== -1
      ? [{
        title:
          <SortableHeader
            type="preTest"
            title="事前テスト"
            page={meta.pageInfo?.page}
            pageSize={meta.pageInfo?.limit}
            sortCondition={sortCondition}
            dataParam={{ id: courseId, requestData: { companyId: companySelectedId } }}
            actionSort={getTestScoreStudents}
            setAction={setSortCondition}
          />,
        dataIndex: 'preTest',
        render: (_, record) => (
          <Text>
            {isNumber(record.preTest)
              ? `${record.preTest}%`
              : 'ー'
            }
          </Text>
        ),
        width: 150,
      }]
      : [],
    ...findIndex(data.coursesUnitVersions, { itemType: 'verify_test' }) !== -1
      ? [{
        title:
          <SortableHeader
            type="verifyTest"
            title="確認テスト"
            page={meta.pageInfo?.page}
            pageSize={meta.pageInfo?.limit}
            sortCondition={sortCondition}
            dataParam={{ id: courseId, requestData: { companyId: companySelectedId } }}
            actionSort={getTestScoreStudents}
            setAction={setSortCondition}
          />,
        dataIndex: 'verifyTest',
        render: (_, record) => (
          <Text>
            {isNumber(record.verifyTest)
              ? `${record.verifyTest}%`
              : 'ー'
            }
          </Text>
        ),
        width: 150,
      }]
      : [],
    ...findIndex(data.coursesUnitVersions, { itemType: 'finish_test' }) !== -1
      ? [{
        title:
          <SortableHeader
            type="finishTest"
            title="修了テスト"
            page={meta.pageInfo?.page}
            pageSize={meta.pageInfo?.limit}
            sortCondition={sortCondition}
            dataParam={{ id: courseId, requestData: { companyId: companySelectedId } }}
            actionSort={getTestScoreStudents}
            setAction={setSortCondition}
          />,
        dataIndex: 'finishTest',
        render: (_, record) => (
          <Text>
            {isNumber(record.finishTest)
              ? `${record.finishTest}%`
              : 'ー'
            }
          </Text>
        ),
        width: 150,
      }]
      : [],
    ...coursesUnitVersionColumns,
  ];

  const scrollPoint = 450
    + (findIndex(data.coursesUnitVersions, { itemType: 'pre_test' }) !== -1 ? 150 : 0)
    + (findIndex(data.coursesUnitVersions, { itemType: 'verify_test' }) !== -1 ? 150 : 0)
    + (findIndex(data.coursesUnitVersions, { itemType: 'finish_test' }) !== -1 ? 150 : 0)
    + data.coursesUnitVersions.length * 150;

  const handleShowModalConfirmExportCsv = () => {
    const modalProps = {
      modalData: {
        title: "CSV出力",
        message: "ユーザー毎のテスト受講履歴をCSVで出力しますか？",
        okText: "出力する",
        cancelText: "キャンセル"
      },
      modalAction: {
        confirm: () =>
          downloadCsvTestStudent({
            companyId: companySelectedId,
            courseId: courseId,
          }),
      },
      isConfirm: true,
      spaceEvenly: true,
      isButtonLoading: true,
      styleContent: {
        paddingTop: "60px"
      }
    };
    showModal(<ConfirmModal {...modalProps} />);
  };

  return (
    <>
      <Style.ButtonExportCsvWrapper>
        <Button
          size="small"
          type="primary"
          onClick={handleShowModalConfirmExportCsv}
          disabled={!isStudentsFinishedTests}
        >
          CSV出力
        </Button>
        <Style.QuestionCircleOutlinedCustom data-tip data-for="questionTip"/>
        <ReactTooltip id="questionTip" place="top" effect="solid" backgroundColor="#0D122D">
          完了したデータのみを出力します。
          <br/>
          セッションエラーなどで回答が完了しなかったデータを出力しません
        </ReactTooltip>
      </Style.ButtonExportCsvWrapper>
      <Table
        className="table-cursor-pointer"
        pagination={false}
        scroll={{ x: scrollPoint, y: 506 }}
        columns={tableColumns}
        dataSource={tableData}
        onRow={(record) => ({
          onClick: () => {
            const urlProfile = userStorage.user.id === record.id ? 'my-profile' : `profile/${record.id}`;
            history.push(`/admin-service/${urlProfile}`);
          }
        })}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={testScoreStudents.load}
      />
      {!isEmpty(data.usersScore) && (
        <PaginationLayout
          pageInfo={meta.pageInfo}
          onChangePage={getTestScoreStudents}
          sortCondition={sortCondition}
          dataParam={{ id: courseId, requestData: { companyId: companySelectedId } }}
        />
      )}
    </>
  );
};

export default TestScoreStudents;
