// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const OverviewCardContainer = styled.div`
  background-color: white;
`;

export const OverviewCardTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 132px;
`;

export const OverviewCardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 84px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const OverviewChart = styled.div`
  position: relative;
  padding: 16px;
  height: 216px;
`;

export const ChartTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const YAxisTitle = styled.div`
  position: absolute;
`;

export const ChartDatePicker = styled.div`
  border-bottom: 1px solid #999999;
  ${(props) => props.invalidDate
    && css`
      border: 1px solid ${props.theme.colors.error}
    `}
`;

export const StudentTopWapper = styled.div`
  @media only screen and (max-width: 428px) {
    display: none;
  }
`
