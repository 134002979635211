// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Form } from 'antd';

export const TabWrapperContent = styled.div`
  padding: 24px;
  width: 100%;
`;

export const DynamicInputField = styled.div`
  display: flex;
`;

export const FormItemLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  width: 150px;
`;

export const GroupAction = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    width: 100px;
  }
`;

export const FormWrapper = styled(Form)`
  & label {
    width: 150px;
  }
`;
