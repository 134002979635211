// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';
import moment from 'moment';

export function getTestList(params) {
  const newParams = {
    page: params.page || '',
    nameCont: (params.name || '').trim(),
    categoryIdEq: params.category || '',
    updatedAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    updatedAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getTestDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function createTest(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_TEST),
    payload: params,
  };
}

export function updateTest(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.UPDATE_TEST),
    payload: params,
  };
}

export function getQuestions(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_QUESTIONS),
    payload: params,
  };
}

export function moveQuestion(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.MOVE_QUESTION),
    payload: params,
  };
}

export function getQuestionDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_QUESTION_DETAIL),
    payload: params,
  };
}

export function createQuestion(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.CREATE_QUESTION),
    payload: params,
  };
}

export function updateQuestion(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.UPDATE_QUESTION),
    payload: params,
  };
}

export function deleteQuestion(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.DELETE_QUESTION),
    payload: params,
  };
}

export function getFileDownloadURL(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.GET_FILE_DOWNLOAD_URL),
    payload: params,
  };
}

export function joinOverviewTest(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.JOIN_OVERVIEW_TEST),
    payload: params,
  };
}

export function clearTestData() {
  return { type: REQUEST(ADMIN_SERVICE.TEST_ACTION.CLEAR_TEST_DATA) };
}

export function copyTest(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.COPY_TEST),
    payload: params
  };
}

export function deleteTest(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TEST_ACTION.DELETE_TEST),
    payload: params
  };
}
