// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Checkbox,
  Row,
  Col,
  Space,
  DatePicker,
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash/lang';

import { getSearchParams } from 'src/utils/common';

import {
  MainContainer,
  MainContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles';
import { DATE_FORMAT } from 'src/constants/common';
import { PASSWORD_REGEX } from 'src/constants/regexValidate';
import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import {
  getCompanyConfirmationStatus as getCompanyConfirmationStatusAction,
  companyConfirmAccount as companyConfirmAccountAction,
} from 'src/redux/actions';

import datePickerItem from 'src/assets/images/common/datepicker-icon.png';

import * as Style from './styles';

interface IConfirmCompanyAccountPageProps extends StateProps, DispatchProps {
  getCompanyConfirmationStatus(params): void;
  companyConfirmAccount(params): void;
  confirmationStatus: {
    data: {
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      company: {
        id: number;
        customDisplayField: {
          department: boolean;
          jobType: boolean;
          jobTitle: boolean;
          birthday: boolean;
        };
      };
    };
  };
}

const ConfirmCompanyAccountPage: React.FC<IConfirmCompanyAccountPageProps> = ({
  getCompanyConfirmationStatus,
  companyConfirmAccount,
  confirmationStatus,
}) => {
  const [confirmAdminCompanyForm] = Form.useForm();

  const customUserInfos = confirmationStatus.data.companyCustomUserInfos?.map((item) => ({
    companyCustomUserInfoId: item.id,
    fieldValue: '',
  }));

  const confirmCompanyAccountInitialValues = {
    customUserInfos,
  };

  const email = getSearchParams('email');
  const confirmationToken = getSearchParams('confirmation_token');

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.ConfirmAdminCompany);

    getCompanyConfirmationStatus({
      email,
      confirmationToken,
    });
  }, []);

  useEffect(() => {
    if (confirmationStatus.data.company?.id) {
      confirmAdminCompanyForm.resetFields();
    }
  }, [confirmationStatus.data]);

  function handleSubmitConfirmForm(values) {
    const requestCustomInfos = values.customUserInfos
      ? values.customUserInfos.map((customInfo) => ({
        companyCustomUserInfoId: customInfo.companyCustomUserInfoId,
        fieldValue: (customInfo.fieldValue || '').trim(),
      }))
      : [];
    const requestData = {
      user: {
        email,
        token: confirmationToken,
        password: values.password,
        role: 'admin_company',
        jituName: values.jituName.trim(),
        department: (values.department || '').trim(),
        jobTitle: (values.jobTitle || '').trim(),
        jobType: (values.jobType || '').trim(),
        ...(!isEmpty(values.birthday) && { birthday: moment(values.birthday).format(DATE_FORMAT) }),
        customUserInfos: requestCustomInfos,
      },
    };
    companyConfirmAccount(requestData);
  }

  function renderCustomInfos() {
    return (
      <Form.List name="customUserInfos" >
        {(fields) => (
          <>
            {fields.map((field, fieldIndex) => {
              const {
                fieldName,
                isRequired,
                isDisplay,
              } = confirmationStatus.data.companyCustomUserInfos[field.fieldKey];
              return (
                <Space key={`${field.key}-${fieldIndex}`} style={{ display: 'block' }}>
                  <Form.Item
                    {...field}
                    name={[field.name, 'companyCustomUserInfoId']}
                    fieldKey={[field.fieldKey, 'companyCustomUserInfoId'] as any}
                    style={{ display: 'none' }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    {...field}
                    label={fieldName}
                    name={[field.name, 'fieldValue']}
                    fieldKey={[field.fieldKey, 'fieldValue'] as any}
                    labelAlign="left"
                    colon={false}
                    rules={[
                      { ...isRequired && { required: true, whitespace: true, message: `${fieldName}は必須項目です。` } },
                      { max: 50, message: `${fieldName}が長すぎます。（最大は50桁です）` },
                    ]}
                    {...!isDisplay && { style: { display: 'none' } }}
                  >
                    <Input />
                  </Form.Item>
                </Space>
              );
            })}
          </>
        )}
      </Form.List>
    );
  }

  return (
    <MainContainer>
      <MainContent formView>
        <MainWrapper title="新規登録" style={{ marginTop: 24 }}>
          <Style.CompanyConfirmContainer>
          <Form
            form={confirmAdminCompanyForm}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            name="userConfirmForm"
            initialValues={confirmCompanyAccountInitialValues}
            onFinish={(values) => handleSubmitConfirmForm(values)}
            scrollToFirstError={true}
          >
            <Style.FormRow>
              <Style.FormCol span={6}>
                <Text>登録アドレス</Text>
              </Style.FormCol>
              <Style.FormCol span={18}>
                <Input value={email ? email : ''} disabled />
              </Style.FormCol>
            </Style.FormRow>
            <Form.Item
              label="名前"
              name="jituName"
              labelAlign="left"
              colon={false}
              rules={[
                { required: true, whitespace: true, message: '名前は必須項目です。' },
                { max: 50, message: '名前が長すぎます。（最大は50桁です）' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="部署"
              name="department"
              labelAlign="left"
              colon={false}
              rules={[
                { max: 50, message: '部署が長すぎます。（最大は50桁です）' },
              ]}
              {...!confirmationStatus.data.company?.customDisplayField.department &&
                { style: { display: 'none' } }
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="役職"
              name="jobTitle"
              labelAlign="left"
              colon={false}
              rules={[
                { max: 50, message: '役職が長すぎます。（最大は50桁です）' },
              ]}
              {...!confirmationStatus.data.company?.customDisplayField.jobType &&
                { style: { display: 'none' } }
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="職種"
              name="jobType"
              labelAlign="left"
              colon={false}
              rules={[
                { max: 50, message: '職種が長すぎます。（最大は50桁です）' },
              ]}
              {...!confirmationStatus.data.company?.customDisplayField.jobTitle &&
                { style: { display: 'none' } }
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="生年月日"
              name="birthday"
              labelAlign="left"
              colon={false}
              {...!confirmationStatus.data.company?.customDisplayField.birthday &&
                { style: { display: 'none' } }
              }
            >
              <DatePicker
                placeholder=""
                suffixIcon={<img src={datePickerItem} alt="" />}
                style={{ width: 150 }}
              />
            </Form.Item>
            {renderCustomInfos()}
            <Form.Item
              label="パスワード"
              name="password"
              labelAlign="left"
              colon={false}
              validateFirst
              rules={[
                { required: true, message: 'パスワードは必須項目です。' },
                { min: 8, message: 'パスワードは8文字以上で設定して下さい。' },
                { max: 20, message: 'パスワードが長すぎます。（最大は20桁です）' },
                {
                  pattern: new RegExp(PASSWORD_REGEX),
                  message: 'パスワードは大文字、小文字、数字、記号から3つ以上の組み合わせで入力してください。'
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="パスワード確認用"
              name="confirmPassword"
              dependencies={['password']}
              labelAlign="left"
              colon={false}
              validateFirst
              rules={[
                { required: true, message: 'パスワード確認用は必須項目です。' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject('新しいパスワードと確認用のパスワードが一致しません。ご確認ください。');
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="new-password" />
            </Form.Item>
            <Row>
              <Col span={6} />
              <Col span={18}>
                <Form.Item
                  name="agreeTerm"
                  wrapperCol={{ span: 24 }}
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) => value
                        ? Promise.resolve()
                        : Promise.reject('ご利用には利用規約とプライバシーポリシーへ同意が必要です。'),
                    },
                  ]}
                >
                  <Style.GroupCheckbox>
                    <Checkbox />
                    <Style.GroupCheckbox style={{ marginLeft: 6 }}>
                      アカウントを作成すると、
                      <Style.Link
                        href="https://skillup-next.co.jp/lms-scai-terms-of-use/"
                        target="_blank"
                      >
                        利用規約
                      </Style.Link>
                      、および
                      <Style.Link
                        href="https://skillup-next.co.jp/privacy/"
                        target="_blank"
                      >
                        プライバシーポリシー
                      </Style.Link>
                      に同意したことになります。
                    </Style.GroupCheckbox>
                  </Style.GroupCheckbox>
                </Form.Item>
              </Col>
            </Row>
            <Style.GroupAction>
              <Button
                type="primary"
                htmlType="submit"
              >
                登録する
              </Button>
            </Style.GroupAction>
          </Form>
          </Style.CompanyConfirmContainer>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { confirmationStatus } = state.companyAccountReducer;
  return {
    confirmationStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyConfirmationStatus: (params) => dispatch(getCompanyConfirmationStatusAction(params)),
  companyConfirmAccount: (params) => dispatch(companyConfirmAccountAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmCompanyAccountPage);
