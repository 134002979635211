// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
`;

export const VideoContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const VideoContent = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const VideoTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const VideoPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  width: 100%;
`;

export const VideoPlayerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
`;

export const VideoSidebarContent = styled.div`
  min-width: 430px;
  max-width: 430px;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  z-index: 1;
`;

export const VideoMainContent = styled.div`
  margin: 0 auto;
  padding: 16px;
  max-width: calc(100% - 430px);
  width: 100%;
  z-index: 1;
`;
