// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const OverviewContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -12px 40px;
`;

export const OverviewContent = styled.div`
  padding: 0 12px;
  width: calc(100% / 5);
`;

export const OverviewCardContainer = styled.div`
  background-color: white;
`;

export const OverviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 126px;
`;
