// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';

interface IHealthCheckPageProps {}

const HealthCheckPage: React.FC<IHealthCheckPageProps> = () => (
  <></>
);

export default HealthCheckPage;
