// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Button,
  Table,
  Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { DndProvider, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { onScrollLoadMore } from 'src/utils/common';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import DragableBodyRow from 'src/components/tables/DragableBodyRow';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getCourseFAQs as getCourseFAQsAction,
  createChapterFAQ as createChapterFAQAction,
  getChapterFAQs as getChapterFAQsAction,
  deleteChapterFAQ as deleteChapterFAQAction,
  moveChapterFAQ as moveChapterFAQAction,
  showModal as showModalAction,
  closeModal as closeModalAction,

} from 'src/redux/actions';

import * as Style from './styles';

interface IModifyChapterFAQPageProps extends StateProps, DispatchProps {
  createChapterFAQ(params): void;
  getCourseFAQs(params): void;
  getChapterFAQs(params): void;
  deleteChapterFAQ(params): void;
  moveChapterFAQ(params): void;
  showModal(params): void;
  closeModal(): void;
  isDisableRequest: boolean;
  faqList: {
    data: {
      id: number,
      name: string,
    }[],
    meta: {
      pageInfo: {
        next: number;
      };
    };
    load: boolean;
  };
  chapterFAQs: {
    data: {
      id: number,
      name: string,
    }[],
    meta: any;
    load: boolean;
  };
}

const ModifyChapterFAQPage: React.FC<IModifyChapterFAQPageProps> = ({
  createChapterFAQ,
  getCourseFAQs,
  getChapterFAQs,
  deleteChapterFAQ,
  moveChapterFAQ,
  showModal,
  closeModal,
  faqList,
  chapterFAQs,
  isDisableRequest
}) => {
  const chapterId = history.location.state?.chapterId;
  const chapterName = history.location.state?.chapterName;
  const [FAQIdSelected, setFAQIdSelected] = useState<number | undefined>();
  const [faqForm] = Form.useForm();

  const RNDContext = createDndContext(HTML5Backend);
  const dragDropManagerRef = useRef(RNDContext);

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.ModifyChapterFAQ);

    getCourseFAQs({ page: 1 });
    getChapterFAQs({ page: 1, id: chapterId });
    return () => {
      closeModal()
    }
  }, []);

  function handleDeleteChapterFAQ(id) {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'このFAQを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deleteChapterFAQ({ chapterId, id }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }
  function handleSearchFAQ(value) {
    getCourseFAQs({ nameCont: value, page: 1 });
  }

  function handleLoadMoreFAQ(e) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    return onScrollLoadMore(
      {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
      !!faqList.meta.pageInfo.next,
      () => getCourseFAQs({
        page: faqList.meta.pageInfo.next,
        more: true,
      }),
    );
  }

  function handleSubmitForm() {
    const requestData = {
      chaptersFaq: {
        faqID: FAQIdSelected,
      },
    };
    return createChapterFAQ({ requestData, id: chapterId });
  }

  const moveRow = (dragIndex, hoverIndex) => {
    moveChapterFAQ({
      chapterId,
      requestData: {
        position: hoverIndex,
        faqId: chapterFAQs.data[dragIndex].id,
      },
      dragIndex,
      hoverIndex,
    });
  };

  function renderFAQOptions() {
    if (isEmpty(faqList.data)) return null;
    return faqList.data.map((faq) => (
      <Select.Option key={`faq-${faq.id}`} value={faq.id}>
        <TooltipParagraph isPreWrap rows={2}>
          {faq.name}
        </TooltipParagraph>
      </Select.Option>
    ));
  }

  const columns = [
    {
      title: '項目名',
      dataIndex: 'name',
      width: 450,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Style.TableAction>
          <Button
            size="small"
            danger
            ghost
            style={{ marginRight: 16 }}
            onClick={() => handleDeleteChapterFAQ(record.id)}
          >
            &nbsp;削除&nbsp;
          </Button>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() => history.push(`/admin-service/faq/${record.id}/edit`)}
          >
            &nbsp;編集&nbsp;
          </Button>
        </Style.TableAction>
      ),
    },
  ];

  const tableData = chapterFAQs.data.map((item) => ({
    key: item.id,
    id: item.id,
    name: item.name,
  }));

  return (
    <MainContainer>
      <MainContent fullView>
        <Form
          form={faqForm}
          name="modifyFAQForm"
          onFinish={() => handleSubmitForm()}
        >
          <Style.ChapterFAQTitle>
            <Text xxxl w6>{`${chapterName}にFAQを追加`}</Text>
          </Style.ChapterFAQTitle>
          <Style.ChapterFAQFilter>
            <Select
              allowClear
              showSearch
              suffixIcon={<CaretDownOutlined />}
              filterOption={false}
              placeholder="選択してください"
              notFoundContent="データが存在しません。"
              className="transparent-input"
              value={FAQIdSelected}
              onSearch={(value) => handleSearchFAQ(value)}
              onChange={(value) => setFAQIdSelected(value)}
              onPopupScroll={(e) => handleLoadMoreFAQ(e)}
              style={{ width: 400 }}
            >
              {renderFAQOptions()}
            </Select>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!FAQIdSelected || isDisableRequest}
              style={{ marginLeft: 24 }}
            >
              追加する
            </Button>
          </Style.ChapterFAQFilter>
        </Form>
        <Style.TabWrapperContent>
          <DndProvider manager={dragDropManagerRef.current.dragDropManager as any}>
            <Table
              id="FAQ-drap-drop"
              pagination={false}
              columns={columns}
              dataSource={[...tableData]}
              locale={{ emptyText: 'データが存在しません。' }}
              {...!isEmpty(chapterFAQs.data) && { components: { body: { row: DragableBodyRow } } }}
              onRow={(record: any, index: any) => ({
                index,
                moveRow,
                key: record.id,
                id: record.id,
              })}
              loading={chapterFAQs.load}
            />
          </DndProvider>
          {!isEmpty(chapterFAQs.data) && (
            <PaginationLayout
              pageInfo={chapterFAQs.meta.pageInfo}
              onChangePage={getChapterFAQs}
              filterParams={{}}
              sortCondition={{}}
            />
          )}
        </Style.TabWrapperContent>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { chapterFAQs, faqList } = state.courseReducer;
  const { isDisableRequest } = state.disableRequestReducer;

  return {
    faqList,
    chapterFAQs,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCourseFAQs: (params) => dispatch(getCourseFAQsAction(params)),
  createChapterFAQ: (params) => dispatch(createChapterFAQAction(params)),
  getChapterFAQs: (params) => dispatch(getChapterFAQsAction(params)),
  deleteChapterFAQ: (params) => dispatch(deleteChapterFAQAction(params)),
  moveChapterFAQ: (params) => dispatch(moveChapterFAQAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyChapterFAQPage);
