//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { isEmpty } from 'lodash/lang';

import { handleNavigateUnit } from '../../util';

import {
  getOverviewGroupDetail as getOverviewGroupDetailAction,
  setCollapseData as setCollapseDataAction,
} from 'src/redux/actions';

import { Text, SkeletonLoading } from 'src/components/styles';

import prevUnitIcon from 'src/assets/images/videoPlayer/prev-video-icon.svg';
import nextUnitIcon from 'src/assets/images/videoPlayer/next-video-icon.svg';
import prevUnitDisabledIcon from 'src/assets/images/videoPlayer/prev-video-disabled-icon.svg';
import nextUnitDisabledIcon from 'src/assets/images/videoPlayer/next-video-disabled-icon.svg';

import * as Style from './styles';

interface ISurveyOverviewProps extends StateProps, DispatchProps {
  getOverviewGroupDetail(params): void;
  setCollapseData(params): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  overviewCourseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
  overviewUnitDetail: {
    data: {
      id: number;
      unit: {
        name: string;
      };
      item: {
        description: string;
        url: string;
      };
    };
    meta: {
      back: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
      next: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
    };
    load: boolean;
  };
}

const SurveyOverview: React.FC<ISurveyOverviewProps> = ({
  getOverviewGroupDetail,
  setCollapseData,
  collapseData,
  overviewCourseDetail,
  overviewUnitDetail,
}) =>
  (
    <>
      {overviewCourseDetail.load
        ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
        : <Text white w6 xxl>{overviewCourseDetail.data.courseCategory?.name}</Text>
      }
      <Style.SurveyDetailContainer>
        <Style.SurveyDetailContent>
          <Style.SurveyDetailTitle>
            {overviewUnitDetail.load || overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text truncate white lg w6>{overviewUnitDetail.data.unit.name}</Text>
            }
          </Style.SurveyDetailTitle>
          <Style.SurveyDescription>
            {overviewUnitDetail.load || overviewUnitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : (
                <Text multiLine>
                  {overviewUnitDetail.data.item.description}
                </Text>
              )
            }
          </Style.SurveyDescription>
          <Style.SurveyFooter>
            <Button
              type="link"
              style={{ padding: 0 }}
              disabled={isEmpty(overviewUnitDetail.meta.back)}
              onClick={() => handleNavigateUnit({
                action: 'back',
                getStudentGroupDetail: getOverviewGroupDetail,
                setCollapseData,
                courseDetail: overviewCourseDetail,
                unitDetail: overviewUnitDetail,
                collapseData,
              })}
            >
              <img
                src={overviewUnitDetail.meta.back ? prevUnitIcon : prevUnitDisabledIcon}
                style={{ marginRight: 4 }}
                alt=""
              />
              戻る
            </Button>
            <Button
              type="primary"
              onClick={() => {
                window.open(overviewUnitDetail.data.item.url, '_blank');
              }}
              disabled={overviewCourseDetail.load || overviewUnitDetail.load}
            >
              回答する
            </Button>
            <Button
              type="link"
              style={{ padding: 0 }}
              disabled={isEmpty(overviewUnitDetail.meta.next)}
              onClick={() => handleNavigateUnit({
                action: 'next',
                getStudentGroupDetail: getOverviewGroupDetail,
                setCollapseData,
                courseDetail: overviewCourseDetail,
                unitDetail: overviewUnitDetail,
                collapseData,
              })}
            >
              次へ
              <img
                src={overviewUnitDetail.meta.next ? nextUnitIcon : nextUnitDisabledIcon}
                style={{ marginLeft: 4 }}
                alt=""
              />
            </Button>
          </Style.SurveyFooter>
        </Style.SurveyDetailContent>
      </Style.SurveyDetailContainer>
    </>
  );

const mapStateToProps = (state) => {
  const { collapseData } = state.commonReducer;
  const { overviewCourseDetail } = state.courseReducer;
  const { overviewUnitDetail } = state.unitReducer;
  return {
    collapseData,
    overviewCourseDetail,
    overviewUnitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOverviewGroupDetail: (params) => dispatch(getOverviewGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setCollapseDataAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOverview);
