//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { sumBy } from 'lodash/math';
import { isNumber, isEqual, isEmpty, omit } from "lodash";

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import CourseOverview from './components/CourseOverview';
import SurveyAndTest from './components/SurveyAndTest';
import CourseContent from './components/CourseContent';
import AttendanceStatus from './components/AttendanceStatus';
import AttendanceChart from './components/AttendanceChart';
import StudentList from './components/StudentList';
import TestScore from './components/TestScore';
import ProgressNotification from "./components/ProgressNotification"

import { convertSecondTime } from 'src/utils/dateTime';
import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import {
  getCompanyCourseDetail as getCompanyCourseDetailAction,
  getCompanyGroupDetail as getCompanyGroupDetailAction,
  getCompanyAttendanceStatus as getCompanyAttendanceStatusAction,
  getCompanyCourseChart as getCompanyCourseChartAction,
  getCompanyTestScoreOverview as getCompanyTestScoreOverviewAction,
  getCompanyTestScoreChart as getCompanyTestScoreChartAction,
  setProgressNotification as setProgressNotificationAction,
  getCompanyRecommendDate as getCompanyRecommendDateAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
  clearDataLearning as clearDataLearningAction,
  getCompanyProgressRateChart as getCompanyProgressRateChartAction,
  getCompanyUserProgressCourse as getCompanyUserProgressCourseAction
} from 'src/redux/actions';
import * as Style from './styles';
import history from 'src/utils/history';
import AttendanceTable from './components/AttendanceTable';

interface ICourseDetailPageProps extends StateProps, DispatchProps {
  getCompanyCourseDetail(params: any): void;
  getCompanyGroupDetail(params: any): void;
  getCompanyAttendanceStatus(params: any): void;
  getCompanyCourseChart(params: any): void;
  getCompanyTestScoreOverview(params): void;
  getCompanyTestScoreChart(params: any): void;
  setProgressNotification(params: any): void;
  getCompanyRecommendDate(params: any): void;
  showModal(params: any): void;
  getCompanyProgressRateChart(params: string): void;
  getCompanyUserProgressCourse(params: any): void
  closeModal(): void;
  clearDataLearning(): void;
  match: {
    params: {
      id?: string;
    };
  };
  courseDetail: {
    data: {
      id: number;
      actualStatus: string;
      name: string;
      courseCategory: {
        name: string;
      };
      overview: string;
      startDate: string;
      remainingDays: number;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      recommendedStartDate: string;
      recommendedEndDate: string;
      remainingRecommendedDays: number
      recommendedCompletionDay: number;
      isDisplayLogo: boolean,
      logo:{
        logoName: string,
        logoUrl: string,
      },
      emailHoursSchedule: number | null;
      emailMinutesSchedule: number | null;
      emailWeeklySchedule: []
      excludeFinishedUserSchedule: boolean;
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      contentOverview: {
        videosTotalDuration: number;
      };
      surveys: {
        id: number;
        item: {
          surveyType: string;
        };
        unit: {
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        item: {
          testType: string;
          questionsCount: number;
        };
        unit: {
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      groups: {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          totalUnits: number;
          totalVideosTime: number;
        }[];
      }[];
      versionName: string;
    };
    load: boolean;
  };
  attendanceStatus: {
    data: {
      usersBehindSchedule: {
        usersBehindSchedulePercentage: number;
        total: number;
      };
      usersOnSchedule: {
        usersOnSchedulePercentage: number
        total: number;
      };
      coursesConsumptionPercentage: number;
      avgLearningTime: number;
      joinTestsPercentage: number;
      passedFinishTest: {
        passedFinishTestsPercentage: number;
        total: number;
      };
    };
    load: boolean;
  };
  attendanceChart: {
    data: {
      chartData: {
        [key: string]: number;
      };
      targetProgressGapLine?: number
    }
    load: boolean;
  };
  recommendDates : {
    data: {
      groupRecommendedDate: {
        groupId: number;
        endDate: string;
        isDelayed: boolean;
      }[]
    },
  };
  userProgress: {
    data: {
      id: number;
      jituName: string;
      email: string;
      totalLearningTime: number;
      coursesPercentage: number;
      progressGap: number;
      lastLearningDate: string;
      joinedTestsRate: number;
      finishTestStatus: number;
    }[];
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
  };
}

const CourseDetailPage: React.FC<ICourseDetailPageProps> = ({
  getCompanyCourseDetail,
  getCompanyGroupDetail,
  getCompanyAttendanceStatus,
  getCompanyCourseChart,
  getCompanyProgressRateChart,
  getCompanyUserProgressCourse,
  setProgressNotification,
  closeModal,
  showModal,
  clearDataLearning,
  match,
  courseDetail,
  attendanceStatus,
  attendanceChart,
  recommendDates,
  getCompanyRecommendDate,
  userProgress
}) => {
  const sortConditionUrl = history.location.state?.sortCondition;
  const progressRateSelectUrl = history.location.state?.progressRateSelect;
  const pageUrl = history.location.state?.page;

  const activeTabKeyUrl = history.location.state?.activeTabKey;
  const [tabIndexSelected, setTabIndexSelected] = useState<string>(activeTabKeyUrl || '1');
  const [backgroundHeight, setBackgroundHeight] = useState<number>(200);
  const [sortConditionAttendance, setSortConditionAttendance] = useState<object>({});
  const [progressRateSelect, setProgressRateSelect] = useState<number | null>(null)
  const [activeIndexBar, setActiveIndexBar] = useState(-1); 
  const [pageUserProgress, setPageUserProgress] = useState({});

  const courseOverviewElement = useRef(null);
  const courseId = match.params.id;

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.CourseDetail);
    if(courseId){
      getCompanyRecommendDate({ courseId: courseId})
    }

    if (activeTabKeyUrl) {
      setTabIndexSelected(activeTabKeyUrl)
    }

    if (tabIndexSelected === '1') {
      setSortConditionAttendance(sortConditionUrl || {});
      setProgressRateSelect(isNumber(progressRateSelectUrl) ? progressRateSelectUrl : null)
    }

    window.addEventListener("beforeunload", function () {
      history.replace()
    })

    return (() => {
      clearDataLearning();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    const chartData = attendanceChart.data.chartData;

    if (tabIndexSelected === '1' && !isEmpty(chartData)) {
      let requestData = {
        ...pageUrl,
        items: pageUrl?.limit,
        sort: sortConditionAttendance
      };

      if (isNumber(progressRateSelectUrl)) {
        if (chartData[progressRateSelectUrl] > 0) {
          requestData = {
            ...requestData,
            progressRate: progressRateSelectUrl
          }
        } else {
          requestData = {};
          setActiveIndexBar(-1);
          setPageUserProgress({});
          setProgressRateSelect(null);
          setSortConditionAttendance({});
        }
      }

      getCompanyUserProgressCourse({
        courseId,
        requestData,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendanceChart.data.chartData]);

  useEffect(() => {
    if (tabIndexSelected === '1') {
      setPageUserProgress(userProgress.meta.pageInfo);
    }
  }, [userProgress.meta.pageInfo]);

  useEffect(() => {
    if (tabIndexSelected === '1' && !isEmpty(attendanceChart.data) && !attendanceChart.load && !userProgress.load) {
      const pageUser = omit(pageUserProgress, "count");
      const state = {
        activeTabKey: tabIndexSelected,
        sortCondition: sortConditionAttendance,
        page: {
          ...pageUser,
          to: pageUser?.to >= 10 ? pageUser?.to : 10,
        },
        isSort: true,
        progressRateSelect,
        activeIndexBar
      }

      if (isEqual(state, history.location.state)) return;

      const pageInfo = omit(userProgress.meta.pageInfo, "count");

      history.replace({
        state: {
          activeTabKey: tabIndexSelected,
          sortCondition: sortConditionAttendance,
          page: {
            ...pageInfo,
            to: pageInfo?.to >= 10 ? pageInfo?.to : 10,
          },
          isSort: true, 
          progressRateSelect,
          activeIndexBar
        }
      });
    }
  }, [activeIndexBar, sortConditionAttendance, tabIndexSelected, pageUserProgress, progressRateSelect]);


  useEffect(() => {
    if (courseId) {
      switch (tabIndexSelected) {
      case '5': {
        if (parseFloat(courseId) !== courseDetail.data.id) {
          getCompanyCourseDetail({ id: courseId });
        }
        break;
      }
      case '1': {
        getCompanyAttendanceStatus({ id: courseId });
        getCompanyProgressRateChart(courseId);
        if (parseFloat(courseId) !== courseDetail.data.id) {
          getCompanyCourseDetail({ id: courseId });
        }
        break;
      }
      default: break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, tabIndexSelected]);

  useEffect(() => {
    const courseOverviewHeight = (courseOverviewElement.current as any)?.offsetHeight;
    setBackgroundHeight(200 + (courseOverviewHeight - 118));
  }, [courseDetail]);

  function handleClickCard(progressRate){
    getCompanyUserProgressCourse({ courseId, requestData: {
      progressRate,
    }});
    setProgressRateSelect(progressRate)
    setSortConditionAttendance({})
  }

  return (
    <MainContainer isTabs backgroundHeight={backgroundHeight}>
      <MainContent fullView>
        <CourseOverview courseDetail={courseDetail} courseOverviewElement={courseOverviewElement} />
        <Tabs
          activeKey={tabIndexSelected}
          type="card"
          onChange={(activeKey) => {
            clearDataLearning();
            history.replace({
              state: {
                activeTabKey: activeKey,
                sortCondition: {},
                page: {},
              }
            });
            setTabIndexSelected(activeKey);
            setProgressRateSelect(null);
            setSortConditionAttendance({});
            setPageUserProgress({});
          }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>学習状況</Text>}
            key="1"
          >
            <AttendanceStatus attendanceStatus={attendanceStatus} />
            <AttendanceChart
              attendanceChart={attendanceChart}
              handleClickCard={handleClickCard}
              tabIndexSelected={tabIndexSelected}
              activeIndexBar={activeIndexBar} 
              setActiveIndexBar={setActiveIndexBar}
            />
            <AttendanceTable
              userProgress={userProgress}
              getCompanyUserProgressCourse={getCompanyUserProgressCourse}
              sortConditionAttendance={sortConditionAttendance}
              setSortConditionAttendance={setSortConditionAttendance}
              courseId={courseId}
              progressRateSelect={progressRateSelect}
              isLoading={attendanceChart.load || userProgress.load}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>ユーザー一覧</Text>}
            key="2"
          >
            <StudentList
              courseId={courseId}
              tabIndexSelected={tabIndexSelected}
            />
          </Tabs.TabPane>
          {courseDetail.data.tests.length > 0 && (
            <Tabs.TabPane
              tab={<Text xl headerText w6>テスト</Text>}
              key="3"
            >
              <TestScore
                courseId={courseId}
                tabIndexSelected={tabIndexSelected}
              />
            </Tabs.TabPane>
          )}
          {(courseDetail.data.actualStatus === 'opening' || courseDetail.data.actualStatus === 'coming') && (
            <Tabs.TabPane
              tab={<Text xl headerText w6>進捗通知設定 </Text>}
              key="4"
            >
              <ProgressNotification
                courseDetail={courseDetail}
                setProgressNotification={setProgressNotification}
                closeModal={closeModal}
                showModal={showModal}
              />
            </Tabs.TabPane>
          )}
          <Tabs.TabPane
            tab={<Text xl headerText w6>内容</Text>}
            key="5"
          >
            <div style={{ marginTop: 24 }}>
              <SurveyAndTest courseDetail={courseDetail} activeTabKey={tabIndexSelected} />
              <CourseContent
                courseDetail={courseDetail}
                getCompanyGroupDetail={getCompanyGroupDetail}
                recommendDates={recommendDates}
                />
              <Style.OverallInfoField>
                <div style={{ width: '35%' }} />
                {courseDetail.load
                  ? (
                    <div style={{ marginRight: 50 }}>
                      <SkeletonLoading
                        active
                        paragraph={false}
                        title={{ width: 32 }}
                        height={16}
                      />
                    </div>
                  )
                  : <div style={{ width: '10%' }}><Text secondaryText lg w6 margin="0 50px 0 0">合計</Text></div>
                }
                <div style={{ width: '15%' }}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={16} />
                    : <Text lg>{`${sumBy(courseDetail.data.groups, (group) => group.totalUnits)}レッスン`}</Text>
                  }
                </div>
                <div style={{ width: '15%' }}>
                  {courseDetail.load
                    ? <SkeletonLoading active paragraph={false} title={{ width: 70 }} height={16} />
                    : (
                      <Text lg>
                        {convertSecondTime(
                          sumBy(courseDetail.data.groups, (group) => group.totalVideosTime),
                          'HH:mm:ss',
                        )}
                      </Text>
                    )
                  }
                </div>
                <div style={{ width: '25%' }} />
              </Style.OverallInfoField>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const {
    courseDetail,
    attendanceStatus,
    attendanceChart,
    testScoreOverview,
    testScoreChart,
    recommendDates,
    userProgress
  } = state.companyCourseReducer;
  return {
    courseDetail,
    attendanceStatus,
    attendanceChart,
    testScoreOverview,
    testScoreChart,
    recommendDates,
    userProgress
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyCourseDetail: (params) => dispatch(getCompanyCourseDetailAction(params)),
  getCompanyGroupDetail: (params) => dispatch(getCompanyGroupDetailAction(params)),
  getCompanyAttendanceStatus: (params) => dispatch(getCompanyAttendanceStatusAction(params)),
  getCompanyCourseChart: (params) => dispatch(getCompanyCourseChartAction(params)),
  getCompanyTestScoreOverview: (params) => dispatch(getCompanyTestScoreOverviewAction(params)),
  getCompanyTestScoreChart: (params) => dispatch(getCompanyTestScoreChartAction(params)),
  setProgressNotification: (params) => dispatch(setProgressNotificationAction(params)),
  getCompanyRecommendDate: (params) => dispatch(getCompanyRecommendDateAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  clearDataLearning: () => dispatch(clearDataLearningAction()),
  getCompanyProgressRateChart: (params) => dispatch(getCompanyProgressRateChartAction(params)),
  getCompanyUserProgressCourse: (params) => dispatch(getCompanyUserProgressCourseAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetailPage);
