// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Text } from 'src/components/styles';

export const StatusUploadContainer = styled.div`
  padding: 24px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & .ant-table .ant-tag {
      padding: 3px 8px 0px 6px;
    }
  }
`;

export const StatusUploadFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;

  & > button {
    width: 120px;

    @media (max-width: 428px) {
      font-weight: 700;
    }
  }
`;

export const StatusUploadContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Message = styled(Text)`
  margin: 12px 0;
  font-size: 16px;
`;

