// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  SUCCESS,
  FAILURE,
  REQUEST,
} from 'src/redux/constant';

const initialState = {
  surveyList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  surveyDetail: {
    data: {
      name: '',
      teachers: [],
      tags: [],
      lastUpdater: {},
    },
    meta: {},
    load: false,
    errors: [],
  },
};

export type SurveyReducer = Readonly<typeof initialState>;

export default (state: SurveyReducer = initialState, action: any): SurveyReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.SURVEY_ACTION.GET_LIST): {
    return {
      ...state,
      surveyList: {
        ...state.surveyList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      surveyList: {
        data: data.surveys,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.SURVEY_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      surveyList: {
        ...state.surveyList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      surveyDetail: {
        data: data.survey,
        meta,
        load: false,
        errors: [],
      },
    };
  }

  case FAILURE(ADMIN_SERVICE.SURVEY_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      surveyDetail: {
        ...state.surveyDetail,
        errors,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.UPDATE_SURVEY): {
    const { data, meta } = action.payload;
    return {
      ...state,
      surveyDetail: {
        data: data.survey,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.SURVEY_ACTION.UPDATE_SURVEY): {
    const { errors } = action;
    return {
      ...state,
      surveyDetail: {
        ...state.surveyDetail,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
