// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';

export const ForgotPasswordContainer = styled.div`
  padding: 24px;
`;

export const GroupAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > button {
    width: 100px;
  }
  ${(props) => props.hasSubText
    && css`
      justify-content: space-between;
    `}
`;

export const Link = styled.a`
  max-height: 16px;
  font-size: 15px;
  color: #0DA2FE;
  &:hover {
    color: #0DA2FE;
    opacity: 0.75;
  }
  ${(props) => props.sm
    && css`
      font-size: 12px;
    `}
`;

export const TextError = styled(Text)`
  margin-left: 210px;
  margin-bottom: 5px;
  @media only screen and (max-width: 576px) {
    margin: 0;
  }
`
