// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const QuestionListContainer = styled.div`
  height: 550px;
  background-color: white;
`;

export const QuestionListContent = styled.div`
  height: 500px;
  overflow-y: auto;

  & #FAQ-drap-drop .ant-table-cell {
    padding: 0 12px;
  }

  & #FAQ-drap-drop .ant-table-selection-column {
    padding: 0 !important;
    width: 5px;
    min-width: 5px;
  }

  & #FAQ-drap-drop .ant-table-row-selected td {
    background-color: #fff8e7;
  }

  & #FAQ-drap-drop .ant-table-row-selected td:first-child {
    background-color: #d29c09;
  }
`;

export const QuestionTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const AddQuestionButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`;

export const TableAction = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    width: 76px;
  }
`;
