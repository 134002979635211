// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from "styled-components";
import { Spin, Tag } from "antd";
import { Text } from 'src/components/styles';

export const OverviewCardContainer = styled.div`
  background-color: white;
`;

export const TagNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const OverviewContainer = styled.div`
  position: relative;
  z-index: 2;
  background-color: white;
  margin-top: 16px;
  padding: 8px 16px 16px 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  cursor: pointer;
`;

export const OverviewCardTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 132px;
`;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 155px;
  margin-bottom: ${(props) => props.marginBottom || '6px'};
`;

export const CustomTextRecommendSetting = styled(Text)`
  height: 22px;
  font-family: 'Noto Sans';
  color: #D25109;
  font-size: 11px;
`;

export const TagTextCustom = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const OverviewCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 12px 13px 12px;
`;

export const TagCustom = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor || props.theme.colors.primary20};
  border: 1px solid ${(props) => props.borderColor || props.theme.colors.primary};
  border-radius: 4px;
  font-weight: 700;
  height: ${(props) => props.height || '25px'};
  width: ${(props) => props.width || '120px'};
`;

export const OverviewCardBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 84px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const OverviewChart = styled.div`
  position: relative;
  padding: 16px;
  height: 216px;
`;

export const ChartTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ChartDatePicker = styled.div`
  border-bottom: 1px solid #999999;
  ${(props) => props.invalidDate
    && css`
      border: 1px solid ${props.theme.colors.error}
    `}
`;

export const TopOverviewWrapper = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 32px;
  padding-left: 10px;

  ::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }
`;

export const CardWrapper = styled.div`
  display: inline-block;
  overflow: hidden;
  width: 180px;
  height: 230px;
  border-top: 4px solid #000000;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  margin-right: 40px;
`;

export const LoadingWrapper = styled.div`
  width: 50px;
  height: 230px;
  display: inline-block;
  position: relative;
`;

export const SpinCustom = styled(Spin)`
  position: absolute;
  top: 50%;
`;

export const OverviewDescription = styled.div`
  position: relative;
  z-index: 1;
  background: #fbfbfb;
  height: 230px;
  padding: 32px 40px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

export const OverviewDescriptionItem = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 16px 0;
`;
