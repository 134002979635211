// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { Text } from 'src/components/styles';

import UnitDetailContents from './UnitDetailContents';

import * as Style from './styles';

interface IChapterDetailContentsProps {
  type: string;
  groupId: number;
  chapters: {
    id: number;
    name: string;
    unitVersions: {
      unitVersionId: number;
      name: string;
      type: string;
    }[];
  }[];
}

const ChapterDetailContents: React.FC<IChapterDetailContentsProps> = ({
  type,
  groupId,
  chapters,
}) => (
  <Droppable droppableId={type} type={'droppableChapter'}>
    {(provided, snapshot) => (
      <Style.ChapterDroppableBackground
        isDraggingOver={snapshot.isDraggingOver}
        ref={provided.innerRef}
      >
        {chapters.length > 0 ? chapters.map((chapter, index) => (
          <Draggable key={chapter.id} draggableId={`chapter-${chapter.id}`} index={index}>
            {(chapterProvided, chapterSnapshot) => (
              <Style.ChapterContainer
                isLastChild={index + 1 === chapters.length}
                isDragging={chapterSnapshot.isDragging}
                ref={chapterProvided.innerRef}
                {...chapterProvided.draggableProps}
                {...chapterProvided.dragHandleProps}
                style={{ ...chapterProvided.draggableProps.style }}
              >
                <Style.ChapterContents key={chapter.id}>
                  <Text w6>{chapter.name}</Text>
                  <UnitDetailContents
                    units={chapter.unitVersions}
                    type={`group-${groupId}-chapter-${chapter.id}`}
                  />
                </Style.ChapterContents>
              </Style.ChapterContainer>
            )}
          </Draggable>
        )) : <Text w6 center>データが存在しません。</Text>}
        {provided.placeholder}
      </Style.ChapterDroppableBackground>
    )}
  </Droppable>
);

export default ChapterDetailContents;
