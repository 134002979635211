// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

const initialState = {
  currentWeek: {
    data: {
      companies: {},
      students: {},
      usedLicenses: {},
      learningTime: {},
      joinedInTests: {},
    },
    meta: {},
    load: false,
    errors: [],
  },
  statisticCourses: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  recentCourses: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
};

export type ServiceStatisticReducer = Readonly<typeof initialState>;

export default (state: ServiceStatisticReducer = initialState, action: any): ServiceStatisticReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_CURRENT_WEEK): {
    return {
      ...state,
      currentWeek: {
        ...state.currentWeek,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.STATISTIC_ACTION.GET_CURRENT_WEEK): {
    const { data, meta } = action.payload;
    return {
      ...state,
      currentWeek: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.STATISTIC_ACTION.GET_CURRENT_WEEK): {
    const { errors } = action;
    return {
      ...state,
      currentWeek: {
        ...state.currentWeek,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_COURSES): {
    return {
      ...state,
      statisticCourses: {
        ...state.statisticCourses,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.STATISTIC_ACTION.GET_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      statisticCourses: {
        data: data.coursesCompanies,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.STATISTIC_ACTION.GET_COURSES): {
    const { errors } = action;
    return {
      ...state,
      statisticCourses: {
        ...state.statisticCourses,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_RECENT_COURSES): {
    return {
      ...state,
      recentCourses: {
        ...state.recentCourses,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.STATISTIC_ACTION.GET_RECENT_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      recentCourses: {
        data: data.coursesCompanies,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.STATISTIC_ACTION.GET_RECENT_COURSES): {
    const { errors } = action;
    return {
      ...state,
      recentCourses: {
        ...state.recentCourses,
        load: false,
        errors,
      },
    };
  }

  default:
    return state;
  }
};
