// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Text } from 'src/components/styles';

export const OverviewContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -12px 16px;

  p, td {
    color: ${(props) => props.theme.colors.headerText} !important;
  }
`;

export const OverviewContent = styled.div`
  padding: 0 12px;
  width: calc(100% / 5);
`;

export const OverviewCardContainer = styled.div`
  background-color: white;
`;

export const OverviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 126px;
  padding-top: 16px;
`;

export const TextTitle = styled(Text)`
  font-size: 25px;
  line-height: 35px;
  margin: 8px 0 8px
`;
