// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class CompanyProfileServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getProfileDetail(params) {
    return this.restClient.get(`/company_manage/users/${params.id}`);
  }

  updateUserRole(params) {
    return this.restClient.put('/company_manage/users_roles', params);
  }

  getJoinedCourses(params) {
    const { id, requestData } = params;
    return this.restClient.get(`/company_manage/users/${id}/courses`, requestData);
  }

  getListTestStudent(params) {
    const { user_id, requestData } = params;
    return this.restClient.get(`/company_manage/users/${user_id}/tests_users`, requestData)
  }
  
  resetHistoryTest(params) {
    const { requestData } = params;
    return this.restClient.post(`/company_manage/reset_test_histories`, requestData,)
  }

  unlockUser(id) {
    return this.restClient.patch(`company_manage/users/${id}/unlock`)
  }
}

