// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';

import {
  MODAL_ACTION,
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

import { showAlertNotice } from 'src/utils/alert';
import { planServices } from 'src/services';

function* getPlansSaga() {
  try {
    const result = yield planServices.getPlanList();
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PLAN_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.PLAN_ACTION.GET_LIST), errors: e.errors });
  }
}

function* createPlanSaga(action) {
  try {
    yield planServices.createPlan(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PLAN_ACTION.CREATE_PLAN),
    });
    yield put({
      type: REQUEST(ADMIN_SERVICE.PLAN_ACTION.GET_LIST),
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.PLAN_ACTION.GET_LIST), errors: e.errors });
  }
}

function* updatePlanSaga(action) {
  try {
    yield planServices.updatePlan(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN),
      payload: {
        data: {
          id: action.payload.id,
          name: action.payload.requestData.plan.name,
          description: action.payload.requestData.plan.description,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN), errors: e.errors });
  }
}

function* deletePlanSaga(action) {
  try {
    yield planServices.deletePlan(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に削除されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN), errors: e.errors });
  }
}


export default function* planSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.PLAN_ACTION.GET_LIST), getPlansSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PLAN_ACTION.CREATE_PLAN), createPlanSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN), updatePlanSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN), deletePlanSaga),
  ]);
}
