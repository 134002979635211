// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from "styled-components";

export const AddCsvModalContainer = styled.div`
  width: 560px;
  margin: 16px auto;
  border: 2px dotted #CCCCCC;
  display: flex;
  flex-direction: column;
  align-items: center;
  -moz-window-dragging:unset;
  -webkit-user-drag: none;
  -ms-window-dragging:unset;
  -ms-user-drag: none;
  -moz-user-select: none !important;
  -webkit-user-select: none!important;
  -khtml-user-select: none!important;
  -moz-user-select: none!important;
  -ms-user-select: none!important;
  user-select: none!important;

  &>img{
  margin-top:${(props) => props.isDone ? "42px" : "56px"};
  -moz-window-dragging:unset;
  -webkit-user-drag: none;
  -ms-window-dragging:unset;
  -ms-user-drag: none;
  -moz-user-select: none !important;
  -webkit-user-select: none!important;
  -khtml-user-select: none!important;
  -moz-user-select: none!important;
  -ms-user-select: none!important;
  user-select: none!important;
  }
  border-color: ${(props) => props.isDrag && "blue"};
  border: ${(props) => props.isDone && "none"};
  height: ${(props) => props.isDone ? "180px" : "270px"};
`;

export const AddCsvModalFooter = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 24px;
  margin-top: 16px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    justify-content: space-around;
    padding: 24px 48px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & button span {
      padding-top: 5px;
    }
  }
`;

export const AddCsvModalHeader = styled.div`
  display: flex;
  margin-left: 32px;
  margin-top: ${(props) => props.marginTop || 0}; ;
`;

export const ErrorContent = styled.div`
  margin-top: 16px;
  display:flex;
`
