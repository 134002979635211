// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from '../../constant';

export function getProfileDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL),
    payload: params,
  };
}

export function updateUserRole(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE),
    payload: params,
  };
}

export function clearUpdateRoleErrors() {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.CLEAR_UPDATE_ROLE_ERRORS),
  };
}

export function updateUserEmail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL),
    payload: params,
  };
}

export function clearUpdateEmailUserErrors() {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.CLEAR_UPDATE_EMAIL_USER_ERRORS),
  };
}

export function updateUserProfileInfo(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO),
    payload: params,
  };
}

export function getJoinedCourses(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_JOINED_COURSES),
    payload: params,
  };
}

export function updateCourseDuration(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSES_DURATION),
    payload: params,
  };
}

export function getTestStudent(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT),
    payload: params,
  };
}

export function resetHistoryTest(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST),
    payload: params,
  };
}

export function getListCourseCompany(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_COURSE_COMPANY),
    payload: params,
  };
}

export function updateCourseManager(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER),
    payload: params,
  };
}

export function unlockUserAdminService(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UNLOCK_USER),
    payload: params,
  };
}
