// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';

import { MainContainer, SidebarContent, MainContent } from 'src/components/layouts/ContentLayout';
import { CourseSidebar } from 'src/components/layouts/Sidebar';

import VideoPlayer from './components/VideoPlayer';
import TestExplain from './components/TestExplain';
import TestingContent from './components/TestingContent';
import SurveyOverview from './components/SurveyOverview';
import TipOverview from './components/TipOverview';

import {
  getOverviewUnitDetail as getOverviewUnitDetailAction,
  getOverviewGroupDetail as getOverviewGroupDetailAction,
  setCollapseData as setStudentCollapseDataAction,
  selectQuestion as selectQuestionAction,
  clearSidebarCourse as clearSidebarCourseAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import { ServicePath } from 'src/constants/routerConstants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

interface ICourseViewPageProps extends StateProps, DispatchProps {
  getOverviewUnitDetail(params): void;
  getOverviewGroupDetail(params): void;
  setCollapseData(params): void;
  selectQuestion(params): void;
  clearSidebarCourse(): void;
  showModal(params): void;
  closeModal(): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  questionIdSelected: number | string;
  overviewCourseDetail: {
    data: {
      id: number,
      name: string,
      studentProgressPercentage?: number,
      groups: {
        id: number;
        name: string;
        chapters: {
          id: string;
          faqs: {
            id: number;
            name: string;
            faqContents: {
              question: string;
              answer: string;
            }[];
          }[];
        }[];
        totalUnits: string;
        totalVideosTime: string;
      }[];
    },
  };
  testQuestions: {
    data: {
      unitVersionId: number;
      questions: {
        id: number;
        isScored: boolean;
        name: string;
        content: string;
        questionType: string;
        questionOptions: {
          id: number;
          content: string;
        }[];
      }[];
    };
  };
}

const CourseViewPage: React.FC<ICourseViewPageProps> = ({
  getOverviewUnitDetail,
  getOverviewGroupDetail,
  setCollapseData,
  selectQuestion,
  clearSidebarCourse,
  showModal,
  closeModal,
  collapseData,
  questionIdSelected,
  overviewCourseDetail,
  testQuestions,
}) => {
  const [isShowSidebar, setIsShowSidebar] = useState<boolean>(true);

  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[3];
  const unitVersionId = pathnameSplit[5];
  const groupId = getSearchParams('group');
  const chapterId = getSearchParams('chapter');

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CourseView);

    getOverviewGroupDetail({
      requestData: {
        groupId,
      },
    });
    return () => {
      clearSidebarCourse();
      closeModal()
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(overviewCourseDetail.data.groups)) {
      getOverviewUnitDetail({
        courseId,
        unitVersionId,
        groupId,
        chapterId,
      });
    }
  }, [courseId, unitVersionId, groupId, chapterId, overviewCourseDetail.data.groups]);

  return (
    <MainContainer isStudent>
      <SidebarContent isCollapse={!isShowSidebar}>
        <CourseSidebar
          isReview
          isShowSidebar={isShowSidebar}
          setIsShowSidebar={setIsShowSidebar}
          getGroupDetail={getOverviewGroupDetail}
          setCollapseData={setCollapseData}
          selectQuestion={selectQuestion}
          showModal={showModal}
          closeModal={closeModal}
          collapseData={collapseData}
          questionIdSelected={questionIdSelected}
          courseDetail={overviewCourseDetail}
          testQuestions={testQuestions}
          currentUnitId={unitVersionId}
        />
      </SidebarContent>
      <MainContent sidebarView={!isShowSidebar}>
        <Switch>
          <Route exact path={ServicePath.VideoPlayer} component={VideoPlayer} />
          <Route exact path={ServicePath.TestExplain} component={TestExplain} />
          <Route exact path={ServicePath.TestingContent} component={TestingContent} />
          <Route exact path={ServicePath.SurveyOverview} component={SurveyOverview} />
          <Route exact path={ServicePath.TipOverview} component={TipOverview} />
        </Switch>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { collapseData, questionIdSelected } = state.commonReducer;
  const { overviewCourseDetail } = state.courseReducer;
  const { testQuestions } = state.testReducer;
  return {
    collapseData,
    questionIdSelected,
    overviewCourseDetail,
    testQuestions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getOverviewUnitDetail: (params) => dispatch(getOverviewUnitDetailAction(params)),
  getOverviewGroupDetail: (params) => dispatch(getOverviewGroupDetailAction(params)),
  setCollapseData: (params) => dispatch(setStudentCollapseDataAction(params)),
  selectQuestion: (params) => dispatch(selectQuestionAction(params)),
  clearSidebarCourse: () => dispatch(clearSidebarCourseAction()),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseViewPage);
