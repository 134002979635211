//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Checkbox,
  Space,
  Spin,
} from 'antd';
import { PlusOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import { MainContainer, MainContent, MainWrapper } from 'src/components/layouts/ContentLayout';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import { Text } from 'src/components/styles';

import {
  getCompanyContractDetail as getCompanyContractDetailAction,
  getCompanyContractCustomInfos as getCompanyContractCustomInfosAction,
  updateCompanyContractCustomInfos as updateCompanyContractCustomInfosAction,
  deleteCompanyContractCustomInfos as deleteCompanyContractCustomInfosAction,
  showModal as showModalAction,
  closeModal as closeModalAction
} from 'src/redux/actions';

import { DEFAULT_INFO_FIELDS } from './constants';
import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import * as Style from './styles';

interface IMasterSettingPageProps extends StateProps, DispatchProps {
  getCompanyContractDetail(params): void;
  getCompanyContractCustomInfos(): void;
  updateCompanyContractCustomInfos(params): void;
  deleteCompanyContractCustomInfos(params): void;
  showModal(params): void;
  closeModal(): void;
  companyDetail: {
    data: {
      id: number;
      contractName: string;
    };
  };
  companyCustomInfos: {
    data: {
      id: number;
      isDisplay: boolean;
      isRequired: boolean;
      fieldName: string;
    }[];
    load: boolean;
  };
  companyCustomDisplay: {
    data: {
      department: boolean;
      jobTitle: boolean;
      jobType: boolean;
      birthday: boolean;
    };
    load: boolean;
  };
}

const MasterSettingPage: React.FC<IMasterSettingPageProps> = ({
  getCompanyContractDetail,
  getCompanyContractCustomInfos,
  updateCompanyContractCustomInfos,
  deleteCompanyContractCustomInfos,
  showModal,
  closeModal,
  companyDetail,
  companyCustomInfos,
  companyCustomDisplay,
}) => {
  const [isEditForm, setIsEditForm] = useState<boolean>(false);
  const [customDisplayField, setCustomDisplayField] = useState<object>({});
  const [customInfosForm] = Form.useForm();

  const infosInitialFormValues = {
    customInfos: [
      ...companyCustomInfos.data,
    ],
  };

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.MasterSetting);

    if (!companyDetail.data.id) {
      getCompanyContractDetail({});
    }
    getCompanyContractCustomInfos();

    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    customInfosForm.resetFields();
  }, [companyCustomInfos.data]);

  useEffect(() => {
    setCustomDisplayField(companyCustomDisplay.data);
  }, [companyCustomDisplay.data]);

  function handleSubmitForm(values) {
    let customRequestData = [] as any;
    values.customInfos.map((customInfo) => {
      if (customInfo.id) {
        customRequestData = [
          ...customRequestData,
          {
            id: customInfo.id,
            isDisplay: customInfo.isDisplay,
            isRequired: customInfo.isRequired,
          },
        ];
      } else {
        customRequestData = [
          ...customRequestData,
          {
            isDisplay: customInfo.isDisplay,
            isRequired: customInfo.isRequired,
            fieldName: customInfo.fieldName.trim(),
          },
        ];
      }
      return null;
    });
    updateCompanyContractCustomInfos({
      companyCustomUserInfos: customRequestData,
      customDisplayField,
    });
    return setIsEditForm(false);
  }

  function handleChangeDisplayDefaultField(e) {
    const { name, checked } = e.target;
    setCustomDisplayField({
      ...customDisplayField,
      [name]: checked,
    });
  }

  function handleCheckboxCustomField(e, index, type) {
    if (type === 'display' ? !e.target.checked : e.target.checked) {
      const customInfosFieldValue = customInfosForm.getFieldValue('customInfos');
      customInfosFieldValue[index] = type === 'display'
        ? {
          ...customInfosForm.getFieldValue('customInfos')[index],
          isRequired: false,
        }
        : {
          ...customInfosForm.getFieldValue('customInfos')[index],
          isDisplay: true,
        };
      customInfosForm.setFields([
        {
          name: ['customInfos'],
          value: [
            ...customInfosFieldValue,
          ],
        },
      ]);
    }
  }

  function handleDeleteField({ fieldIndex, removeField }) {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: '削除してもよろしいですか。削除した場合、アカウントの該当情報が削除されます。',
      },
      modalAction: {
        confirm: () => {
          if (companyCustomInfos.data[fieldIndex]?.id) {
            deleteCompanyContractCustomInfos({
              id: companyCustomInfos.data[fieldIndex].id,
            });
          }
          removeField();
        },
      },
    };
    if (companyCustomInfos.data[fieldIndex]?.id) {
      return showModal(<ConfirmModal {...modalProps} />);
    } else {
      return removeField();
    }
  }

  function renderDefaultField() {
    const newDefaultInfoField = DEFAULT_INFO_FIELDS.map((field) => ({
      isDisplay:
        ['department', 'jobTitle', 'jobType', 'birthday'].includes(field.fieldKey)
        && !isEmpty(companyCustomDisplay.data)
          ? companyCustomDisplay.data[field.fieldKey]
          : field.isDisplay,
      isRequired: field.isRequired,
      fieldName: field.fieldName,
      fieldKey: field.fieldKey,
    }));
    if (isEditForm) {
      return newDefaultInfoField.map((field, fieldIndex) => (
        <Style.TabWrapperContent key={`edit-default-field-${fieldIndex}`} isEdit>
          <Checkbox
            name={field.fieldKey}
            defaultChecked={field.isDisplay}
            disabled={!['department', 'jobTitle', 'jobType', 'birthday'].includes(field.fieldKey)}
            onChange={(e) => handleChangeDisplayDefaultField(e)}
            style={{ marginBottom: 24, minWidth: 80 }}
          />
          <Checkbox
            checked={field.isRequired}
            disabled
            style={{ margin: '0 0 24px', minWidth: 80 }}
          />
          <Input value={field.fieldName} disabled style={{ marginBottom: 24 }} />
        </Style.TabWrapperContent>
      ));
    }
    return newDefaultInfoField.map((field, fieldIndex) => (
      <Style.TabWrapperContent key={`default-field-${fieldIndex}`}>
        <Text lg style={{ width: 80 }}>
          {field.isDisplay ? '表示' : '非表示'}
        </Text>
        <Text lg style={{ width: 80 }}>
          {field.isRequired ? '必須' : '任意'}
        </Text>
        <Text lg>{field.fieldName}</Text>
      </Style.TabWrapperContent>
    ));
  }

  function renderCustomField() {
    if (isEditForm) {
      return (
        <>
          <Form.List name="customInfos" >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, fieldIndex) => (
                  <Style.TabWrapperContent key={`${field.key}-${fieldIndex}`} isEdit>
                    <Form.Item
                      {...field}
                      name={[field.name, 'id']}
                      fieldKey={[field.fieldKey, 'id'] as any}
                      style={{ display: 'none' }}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'isDisplay']}
                      fieldKey={[field.fieldKey, 'isDisplay'] as any}
                      shouldUpdate={(prevValues, currentValues) => !prevValues.isDisplay && currentValues.isRequired}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) => handleCheckboxCustomField(e, fieldIndex, 'display')}
                        style={{ width: 80 }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'isRequired']}
                      fieldKey={[field.fieldKey, 'isRequired'] as any}
                      valuePropName="checked"
                    >
                      <Checkbox
                        onChange={(e) => handleCheckboxCustomField(e, fieldIndex, 'required')}
                        style={{ width: 80 }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'fieldName']}
                      fieldKey={[field.fieldKey, 'fieldName'] as any}
                      rules={[
                        { required: true, message: 'この項目は必須項目です。' },
                        { whitespace: true, message: 'この項目は必須項目です。' },
                        { max: 10, message: '10桁以下で入力してください。' },
                      ]}
                      style={{ width: '100%' }}
                    >
                      <Input disabled={!!companyCustomInfos.data[fieldIndex]?.id} />
                    </Form.Item>
                    <Style.FieldRemoveAction>
                      <Button
                        type="link"
                        ghost
                        onClick={() => handleDeleteField({
                          fieldIndex,
                          removeField: () => remove(field.name),
                        })}
                        icon={<CloseCircleOutlined style={{ fontSize: 20, color: '#d25109' }} />}
                      />
                    </Style.FieldRemoveAction>
                  </Style.TabWrapperContent>
                ))}
                {fields.length < 5 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add({ isRequired: false, fieldName: '' })}
                      style={{ margin: '6px 20px 0', width: 'calc(100% - 40px)' }}
                    >
                      <PlusOutlined /> フィールド追加
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
        </>
      );
    }
    return companyCustomInfos.data.map((info, infoIndex) => (
      <Style.TabWrapperContent key={`info-${infoIndex}`}>
        <Text lg style={{ width: 80 }}>
          {info.isDisplay ? '表示' : '非表示'}
        </Text>
        <Text lg style={{ width: 80 }}>
          {info.isRequired ? '必須' : '任意'}
        </Text>
        <Text lg>{info.fieldName}</Text>
      </Style.TabWrapperContent>
    ));
  }

  return (
    <MainContainer>
      <MainContent fullView>
        <Style.MasterSettingTitle>
          <Text xxxl w6>マスタ管理</Text>
        </Style.MasterSettingTitle>
        <Form
          form={customInfosForm}
          name="customInfosForm"
          initialValues={infosInitialFormValues}
          onFinish={(values) => handleSubmitForm(values)}
        >
          <Style.ProfileSettingTop>
            <Text headerText xxxl w6 style={{ margin: '8px 0', minHeight: 32 }}>
              {companyDetail.data.contractName}
            </Text>
            <Space>
              {isEditForm
                ? (
                  <Space align="center" size="middle">
                    <Button
                      type="primary"
                      htmlType="button"
                      className="btn-secondary"
                      onClick={() => {
                        setIsEditForm(false);
                        customInfosForm.resetFields();
                      }}
                    >
                      キャンセル
                    </Button>
                    <Button type="primary" htmlType="submit">保存する</Button>
                  </Space>
                )
                : <Button
                  type="primary"
                  htmlType="button"
                  onClick={() => setIsEditForm(true)}
                >
                  修正する
                </Button>
              }
            </Space>
          </Style.ProfileSettingTop>
          <Spin spinning={companyCustomInfos.load || companyCustomDisplay.load}>
            <MainWrapper
              title={
                <>
                  <Text white lg style={{ width: 80, marginLeft: 4 }}>表示</Text>
                  <Text white lg style={{ width: 80 }}>必須</Text>
                  <Text white lg>項目</Text>
                </>
              }
              style={{ marginTop: 16 }}
            >
              <Style.ProfileSettingContent isEdit={isEditForm}>
                {renderDefaultField()}
                {renderCustomField()}
              </Style.ProfileSettingContent>
            </MainWrapper>
          </Spin>
        </Form>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const {
    companyDetail,
    companyContracts,
    companyCustomInfos,
    companyCustomDisplay,
  } = state.companyContractReducer;
  return {
    companyDetail,
    companyContracts,
    companyCustomInfos,
    companyCustomDisplay,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractDetail: (params) => dispatch(getCompanyContractDetailAction(params)),
  getCompanyContractCustomInfos: () => dispatch(getCompanyContractCustomInfosAction()),
  updateCompanyContractCustomInfos: (params) => dispatch(updateCompanyContractCustomInfosAction(params)),
  deleteCompanyContractCustomInfos: (params) => dispatch(deleteCompanyContractCustomInfosAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MasterSettingPage);
