// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { ReactNode } from 'react';
import { Space } from 'antd';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import { Text } from 'src/components/styles';

interface ISortableHeaderProps {
  type: string;
  title: string | ReactNode;
  page: number;
  pageSize?: number;
  sortCondition: object;
  filterParams?: object;
  dataParam?: any;
  actionSort(params): void;
  setAction(params): void;
}

const SortableHeader: React.FC<ISortableHeaderProps> = ({
  type,
  title,
  page,
  sortCondition,
  filterParams,
  dataParam,
  pageSize,
  actionSort,
  setAction,
}) => {
  function setSortParams() {
    switch (sortCondition[type]) {
    case 'asc': {
      setAction({ [type] : 'desc' });
      return { [type] : 'desc' };
    }
    case 'desc': {
      setAction({});
      return {};
    }
    default: {
      setAction({ [type] : 'asc' });
      return { [type] : 'asc' };
    }
    }
  }

  function handleSort() {
    const sortRequest = !isEmpty(setSortParams())
      ? { ...filterParams, sort: setSortParams() }
      : (!isEmpty(filterParams) ? filterParams : {});
    if (!isEmpty(dataParam)) {
      const newDataParam = {
        ...dataParam,
        requestData: {
          ...dataParam.requestData,
          ...sortRequest,
          page,
          items: pageSize,
        },
      };
      actionSort({ ...newDataParam });
    } else {
      actionSort({
        ...sortRequest,
        page: page || 1,
        items: pageSize,
      });
    }
  }

  return (
    <div
      style={{ width: '100%', cursor: 'pointer' }}
      onClick={() => handleSort()}
    >
      <Space align="center">
        {typeof title === 'string'
          ? (
            <Text white>
              {title}
            </Text>
          )
          : title
        }
        {isEmpty(sortCondition[type]) && (
          <Space direction="vertical" size={-12}>
            <CaretUpOutlined />
            <CaretDownOutlined />
          </Space>
        )}
        {sortCondition[type] === 'asc' && <CaretUpOutlined />}
        {sortCondition[type] === 'desc' && <CaretDownOutlined />}
      </Space>
    </div>
  );
};

export default SortableHeader;
