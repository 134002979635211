// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  MY_PROFILE_ACTION,
  ADMIN_SERVICE,
  ADMIN_COMPANY,
  REQUEST,
  SUCCESS,
  FAILURE,
  MODAL_ACTION,
} from '../constant';
import { findIndex } from 'lodash/array';

interface listCourseCompanyProps{
  id: number;
  course: {
    id: number;
    name: string;
    courseCategory: {
      id: number;
      name: string;
    }
  },
  actualStatus: string;
  startDate: string;
  endDate: string;
  manageStatus: string
}

const initialState = {
  profileInfo: {
    data: {
      avatarUrl: '',
    },
    meta: {},
    load: false,
    errors: [],
  },
  courseList: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
  changeEmail: {
    errors: [],
  },
  changePassword: {
    errors: [],
  },
  changePasswordAndEmail: {
    errors: {},
  },
  listTestsStudent: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  isResetSucess: false,
  listCourseCompany: {
    data: [] as listCourseCompanyProps[],
    meta: {},
    load: false,
    errors: [],
  }
};

export type MyProfileReducer = Readonly<typeof initialState>;

export default (state: MyProfileReducer = initialState, action: any): MyProfileReducer => {
  switch (action.type) {
    case REQUEST(MY_PROFILE_ACTION.GET_PROFILE_INFO): {
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          load: true,
        },
      };
    }
    case SUCCESS(MY_PROFILE_ACTION.GET_PROFILE_INFO): {
      const { data, meta } = action.payload;
      return {
        ...state,
        profileInfo: {
          data: data.user,
          meta,
          load: false,
          errors: [],
        },
      };
    }
    case FAILURE(MY_PROFILE_ACTION.GET_PROFILE_INFO): {
      const { errors } = action;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          load: false,
          errors,
        },
      };
    }

    case REQUEST(MY_PROFILE_ACTION.GET_MY_JOINED_COURSES): {
      return {
        ...state,
        courseList: {
          ...state.courseList,
          load: true,
        },
      };
    }
    case SUCCESS(MY_PROFILE_ACTION.GET_MY_JOINED_COURSES): {
      const { data, meta } = action.payload;
      return {
        ...state,
        courseList: {
          data: data.courses,
          meta,
          load: false,
          errors: [],
        },
      };
    }
    case FAILURE(MY_PROFILE_ACTION.GET_MY_JOINED_COURSES): {
      const { errors } = action;
      return {
        ...state,
        courseList: {
          ...state.courseList,
          load: false,
          errors,
        },
      };
    }

    case SUCCESS(MY_PROFILE_ACTION.UPDATE_MY_COURSES_DURATION): {
      const { data } = action.payload;
      const newCourseList = state.courseList.data;
      const courseIndex = findIndex(state.courseList.data, { id: data.id });
      const newCourseData = {
        ...state.courseList.data[courseIndex],
        ...data,
      };
      newCourseList.splice(courseIndex, 1, newCourseData);
      return {
        ...state,
        courseList: {
          ...state.courseList,
          data: newCourseList,
        },
      };
    }
    case FAILURE(MY_PROFILE_ACTION.UPDATE_MY_COURSES_DURATION): {
      const { errors } = action;
      return {
        ...state,
        courseList: {
          ...state.courseList,
          load: false,
          errors,
        },
      };
    }

    case SUCCESS(MY_PROFILE_ACTION.UPDATE_USER_INFO): {
      const { data } = action.payload;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          data: {
            ...state.profileInfo.data,
            ...data,
          },
        },
      };
    }
    case FAILURE(MY_PROFILE_ACTION.UPDATE_USER_INFO): {
      const { errors } = action;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          errors,
        },
      };
    }

    case SUCCESS(MY_PROFILE_ACTION.UPDATE_CUSTOM_USER_INFO): {
      const { data } = action.payload;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          data: {
            ...state.profileInfo.data,
            ...data,
          },
        },
      };
    }
    case FAILURE(MY_PROFILE_ACTION.UPDATE_CUSTOM_USER_INFO): {
      const { errors } = action;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          errors,
        },
      };
    }

    case SUCCESS(MY_PROFILE_ACTION.UPDATE_EMAIL): {
      const { data } = action.payload;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          data: {
            ...state.profileInfo.data,
            ...data,
          },
        },
      };
    }
    case FAILURE(MY_PROFILE_ACTION.UPDATE_EMAIL): {
      const { errors } = action;
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          errors,
        },
      };
    }
    case REQUEST(MY_PROFILE_ACTION.CLEAR_UPDATE_EMAIL_ERRORS): {
      return {
        ...state,
        changeEmail: {
          ...state.changeEmail,
          errors: [],
        },
      };
    }

    case FAILURE(MY_PROFILE_ACTION.UPDATE_PASSWORD): {
      const { errors } = action;
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          errors,
        },
      };
    }
    
    case FAILURE(MY_PROFILE_ACTION.UPDATE_PASSWORD_AND_EMAIL): {
      const { errors } = action;
      return {
        ...state,
        changePasswordAndEmail: {
          ...state.changePasswordAndEmail,
          errors,
        },
      };
    }

    case REQUEST(MY_PROFILE_ACTION.RESET_ERROR_CHANGE_MAIL_PASSWORD): {
      return {
        ...state,
        changePasswordAndEmail: {
          errors: {}
        },
      };
    }

    case REQUEST(MY_PROFILE_ACTION.CLEAR_UPDATE_PASSWORD_ERRORS): {
      return {
        ...state,
        changePassword: {
          ...state.changePassword,
          errors: [],
        },
      };
    }

    case SUCCESS(MY_PROFILE_ACTION.UPDATE_AVATAR): {
      const { data } = action.payload;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          data: {
            ...state.profileInfo.data,
            avatarUrl: data,
          },
        },
      };
    }
    case FAILURE(MY_PROFILE_ACTION.UPDATE_AVATAR): {
      const { errors } = action;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          errors,
        },
      };
    }

    case SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE): {
      const { data } = action.payload;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          data: {
            ...state.profileInfo.data,
            ...data,
          },
        },
      };
    }
    case SUCCESS(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE): {
      const { data } = action.payload;
      return {
        ...state,
        profileInfo: {
          ...state.profileInfo,
          data: {
            ...state.profileInfo.data,
            ...data,
          },
        },
      };
    }

    case REQUEST(MY_PROFILE_ACTION.GET_TEST_STUDENT): {
      return {
        ...state,
        listTestsStudent: {
          ...state.listTestsStudent,
          load: true
        }
      }
    }
    case SUCCESS(MY_PROFILE_ACTION.GET_TEST_STUDENT): {
      const { data, meta } = action.payload;
      return {
        ...state,
        listTestsStudent: {
          ...state.listTestsStudent,
          load: false,
          data: data.testsUsers,
          meta
        }
      }
    }
    case FAILURE(MY_PROFILE_ACTION.GET_TEST_STUDENT): {
      const { errors } = action;
      return {
        ...state,
        listTestsStudent: {
          ...state.listTestsStudent,
          errors,
          load: false
        }
      }
    }

    case SUCCESS(MY_PROFILE_ACTION.RESET_MY_TEST_HISTORY): {
      return {
        ...state,
        isResetSucess: true,
      }
    }
    case REQUEST(MODAL_ACTION.CLOSE): {
      return {
        ...state,
        isResetSucess: false,
      }
    }

    case REQUEST(MY_PROFILE_ACTION.GET_COURSE_COMPANY): {
      return {
        ...state,
        listCourseCompany: {
          ...state.listCourseCompany,
          load: true
        }
      }
    }
    case SUCCESS(MY_PROFILE_ACTION.GET_COURSE_COMPANY): {
      const { data, meta } = action.payload;
      return {
        ...state,
        listCourseCompany: {
          ...state.listCourseCompany,
          load: false,
          data: data.coursesCompanies,
          meta
        }
      }
    }
    case FAILURE(MY_PROFILE_ACTION.GET_COURSE_COMPANY): {
      const { errors } = action;
      return {
        ...state,
        listCourseCompany: {
          ...state.listCourseCompany,
          errors,
          load: false
        }
      }
    }
  
    case SUCCESS(MY_PROFILE_ACTION.UPDATE_COURSE_MANAGER): {
      const index = state.listCourseCompany.data.findIndex(( item ) => item.id === action.payload)
      const newDataCourse ={
        ...state.listCourseCompany.data[index],
        manageStatus:state.listCourseCompany.data[index].manageStatus === "enabled" ? "disabled" : "enabled"
      };
      const newListCourse = state.listCourseCompany.data;
      newListCourse.splice(index, 1, newDataCourse)
      return {
        ...state,
        listCourseCompany:{
          ...state.listCourseCompany,
          data: [...newListCourse]
        }
      };
    }
    default:
      return state;
  }
};
