// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';

import { Text } from 'src/components/styles';
import { renderItemTypeIcon } from 'src/utils/common';

import * as Style from './styles';

interface IUnitDetailContentsProps {
  type: string;
  units: {
    unitVersionId: number;
    name: string;
    type: string;
  }[];
}

const UnitDetailContents: React.FC<IUnitDetailContentsProps> = ({
  type,
  units,
}) => (
  <Droppable droppableId={type} type={'droppableUnit'}>
    {(provided, snapshot) => (
      <Style.UnitDroppableBackground
        ref={provided.innerRef}
        isDraggingOver={snapshot.isDraggingOver}
      >
        {units.length > 0 ? units?.map((unit, index) => (
          <Draggable key={unit.unitVersionId} draggableId={`unit-${unit.unitVersionId}`} index={index}>
            {(unitProvided, unitSnapshot) => (
              <Style.UnitContainer
                isLastChild={index + 1 === units.length}
                isDragging={unitSnapshot.isDragging}
                ref={unitProvided.innerRef}
                {...unitProvided.draggableProps}
                {...unitProvided.dragHandleProps}
                style={{ ...unitProvided.draggableProps.style }}
              >
                  <Style.ItemTypeIcon>
                    <img src={renderItemTypeIcon(unit.type)} alt="" />
                  </Style.ItemTypeIcon>
                  <Text sm w6>{unit.name}</Text>
              </Style.UnitContainer>
            )}
          </Draggable>
        )) : <Text w6 center>データが存在しません。</Text>}
        {provided.placeholder}
      </Style.UnitDroppableBackground>
    )}
  </Droppable>
);

export default UnitDetailContents;
