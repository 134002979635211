// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import moment from 'moment';

import { SessionStorage } from 'src/utils/localStorge';

import { STUDENT, SUCCESS, FAILURE, REQUEST } from '../../constant';

import { studentVideoServices } from 'src/services';

function* setWatchedTimeSaga(action) {
  const { requestData } = action.payload;
  const sessionData = SessionStorage.getData();
  if (moment(sessionData?.lastActionTime).add(1, 'minute') <= moment()) {
    SessionStorage.setData({
      mouseX: sessionData.mouseX,
      mouseY: sessionData.mouseY,
      lastActionTime: moment(),
    });
  }
  try {
    const result = yield studentVideoServices.saveWatchedTime(action.payload);
    yield put({
      type: SUCCESS(STUDENT.VIDEO_ACTION.SAVE_WATCHED_TIME),
      payload: result,
    });
    if (result.meta.isDone) {
      yield put({
        type: SUCCESS(STUDENT.COURSE_ACTION.UPDATE_COURSE_PROGRESS),
        payload: {
          meta: result.meta,
          data: {
            unitVersionId: requestData.unitVersionId,
            groupId: requestData.groupId,
            chapterId: requestData.chapterId,
          },
        },
      });
    }
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.VIDEO_ACTION.SAVE_WATCHED_TIME), errors: e.errors });
  }
}

export default function* studentVideoSaga() {
  yield all([
    takeLatest(REQUEST(STUDENT.VIDEO_ACTION.SAVE_WATCHED_TIME), setWatchedTimeSaga),
  ]);
}
