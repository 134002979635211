// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useRef } from 'react';
import { DndProvider, createDndContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, Table } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import { MainWrapper } from 'src/components/layouts/ContentLayout';
import DragableBodyRow from 'src/components/tables/DragableBodyRow';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { QUESTION_TYPE } from 'src/constants/common';

import * as Style from './styles';

interface IQuestionListProps {
  setQuestionIdSelected(params: number | string): void;
  setQuestionSelectedIndex(params): void;
  setAnswerType(params): void;
  moveQuestion(params): void;
  testId: string;
  questionIdSelected: number | string;
  hasBeenUsed: boolean;
  questionForm: {
    resetFields(): void;
  };
  questionList: {
    data: {
      id: number;
      name: string;
      questionType: string;
      score: number;
    }[];
  };
}

const QuestionList: React.FC<IQuestionListProps> = ({
  setQuestionIdSelected,
  setQuestionSelectedIndex,
  setAnswerType,
  moveQuestion,
  testId,
  questionIdSelected,
  hasBeenUsed,
  questionForm,
  questionList,
}) => {
  const RNDContext = createDndContext(HTML5Backend);
  const dragDropManagerRef = useRef(RNDContext);

  const columns = [
    {
      dataIndex: 'index',
      key: 'index',
      render: (_, record) => <Text>{`${record.index}問目`}</Text>,
      width: 80,
    },
    {
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 160,
    },
    {
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => <Text>{QUESTION_TYPE[record.type]}</Text>,
      width: 110,
    },
    {
      dataIndex: 'score',
      key: 'score',
      width: 90,
    },
  ];

  const tableData = questionList.data.map((item, index) => ({
    key: item.id,
    id: item.id,
    index: index + 1,
    name: item.name,
    type: item.questionType,
    score: item.score ? item.score : '採点なし',
  }));

  const moveRow = (dragIndex, hoverIndex) => {
    moveQuestion({
      testId,
      questionId: questionList.data[dragIndex].id,
      questionIndex: dragIndex,
      requestData: {
        position: hoverIndex,
      },
    });
  };

  function countTotalScore() {
    return questionList.data.reduce((prev, current) => (prev + current.score), 0);
  }

  return (
    <MainWrapper title="テスト名">
      <Style.QuestionListContainer>
        <Style.QuestionTotal>
          <Text lg>{`合計点数: ${countTotalScore()}点`}</Text>
          <Button
            type="link"
            size="small"
            icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
            onClick={() => {
              questionForm.resetFields();
              setAnswerType('option_type');
              setQuestionIdSelected('create');
              setQuestionSelectedIndex(questionList.data.length + 1);
            }}
            disabled={hasBeenUsed}
            style={{ padding: 0 }}
          >
            新規追加
          </Button>
        </Style.QuestionTotal>
        <Style.QuestionListContent>
          <DndProvider manager={dragDropManagerRef.current.dragDropManager as any}>
            <Table
              id="FAQ-drap-drop"
              showHeader={false}
              pagination={false}
              columns={columns}
              dataSource={[...tableData]}
              locale={{ emptyText: 'データが存在しません。' }}
              rowSelection={{
                selectedRowKeys: [questionIdSelected],
                renderCell: () => null,
                columnWidth: '0px',
              }}
              components={{
                body: {
                  row: DragableBodyRow,
                },
              }}
              onRow={(record: any, index: any) => ({
                index,
                moveRow,
                key: record.id,
                id: record.id,
                onClick: () => {
                  setQuestionIdSelected(record.id);
                  setQuestionSelectedIndex(index + 1);
                },
              })}
            />
          </DndProvider>
        </Style.QuestionListContent>
      </Style.QuestionListContainer>
    </MainWrapper>
  );
};

export default QuestionList;
