// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

import { Text } from 'src/components/styles';

export const CoursesTableContainer = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  max-width: 1400px;
  overflow: scroll;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: auto;
  }
  .table-student-top-mobile {
      display: none;
  }

  @media only screen and (max-width: 428px) {
    overflow: unset;
    .table-student-top-desktop {
      display: none;
    }
    .table-student-top-mobile {
      display: block;

      th, td {
        padding: 8px;
      }

      th {
        text-align: center;
      }

      td:nth-child(2),
      td:nth-child(3) {
        text-align: center;
      }

      td:last-child {
        font-size: 12px !important;
      }
    }
    .ant-progress-text {
      color: ${(props) => props.theme.colors.primaryText} !important;
      font-size: 12px;
    }
  }
`;

export const TextHeader = styled(Text)`
  margin: 40px 0 16px;
  
  @media only screen and (max-width: 428px) {
    margin: 16px !important;
    font-size: 20px !important;
  }
`
