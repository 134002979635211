// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty, isNumber } from 'lodash/lang';

import history from 'src/utils/history';
import { formatDateTime, convertSecondTime } from 'src/utils/dateTime';
import { getFilterOption } from 'src/utils/common';

import {
  MainContainer,
  MainContent,
  TopContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles/Texts';

import CreateVersionModal from './components/CreateVersionModal';
import SelectVersionModal from './components/SelectVersionModal';

import {
  getUnitDetail as getUnitDetailAction,
  getTagList as getTagListAction,
  getTeacherList as getTeacherListAction,
  getCategoryList as getCategoryListAction,
  selectItemVersion as selectItemVersionAction,
  updateUnit as updateUnitAction,
  createUnit as createUnitAction,
  deleteUnit as deleteUnitAction,
  clearUnitDetail as clearUnitDetailAction,
  closeModal as closeModalAction,
  showModal as showModalAction,
} from 'src/redux/actions';
import { DATE_TIME_FORMAT, UNIT_TYPE, DATE_FORMAT } from 'src/constants/common';
import { ServicePath } from 'src/constants/routerConstants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import * as Style from './styles';

interface IModifyUnitPageProps extends StateProps, DispatchProps {
  getUnitDetail(params): void;
  getTagList(): void;
  getTeacherList(): void;
  getCategoryList(): void;
  selectItemVersion(params): void;
  updateUnit(params): void;
  createUnit(params): void;
  deleteUnit(params): void;
  clearUnitDetail(): void;
  closeModal(): void;
  showModal(params: any): void;
  isDisableRequest: boolean;
  match: {
    params: {
      id?: string;
    };
  };
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  teacherList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  unitDetail: {
    data: {
      id: number,
      name: string,
      hasBeenUsed: boolean;
      description: string,
      status: string,
      updatedAt: string,
      category: {
        id: number,
        name: string,
      },
      lastUpdater: {
        id: number,
        jituName: string,
      }
      teachers: {
        id: number,
        name: string,
      }[],
      tags: {
        id: number,
        name: string,
      }[],
      currentVersion: string,
      teacher: {
        id: number,
        name: string,
      },
      item: {
        id: number,
        name: string,
        type: string,
        category: any,
        duration: string,
        teacherName: string,
        updatedAt: string,
        smallThumbnailUrl: string,
        questionsCount: string,
      },
    },
    meta: object,
    errors: {
      message: string;
    }[];
  };
}

const ModifyUnitPage: React.FC<IModifyUnitPageProps> = ({
  getUnitDetail,
  getTagList,
  getTeacherList,
  getCategoryList,
  selectItemVersion,
  updateUnit,
  createUnit,
  deleteUnit,
  clearUnitDetail,
  showModal,
  match,
  unitDetail,
  tagList,
  teacherList,
  categoryList,
  closeModal,
  isDisableRequest
}) => {
  const [unitType, setUnitType] = useState<any>(undefined);
  const [itemId, setItemId] = useState<number>(NaN);
  const [unitForm] = Form.useForm();

  const { data } = unitDetail;
  const unitId = match.params.id;
  const unitInitialFormValues = {
    name: data.name,
    description: data.description,
    status: data.status,
    categoryId: data.category?.id,
    teacherIds: data.teachers.map((teacher) => teacher.id),
    tagIds: data.tags.map((tag) => tag.id),
    currentVersion: data.currentVersion,
    unitType: unitId ? UNIT_TYPE[data.item.type] : unitType,
  };

  useEffect(() => {
    if (unitId) {
      document.title = PAGE_TITLE(ServiceTitle.UnitDetail);

      if (parseFloat(unitId) !== data.id) {
        getUnitDetail({ id: unitId });
      }
    } else {
      document.title = PAGE_TITLE(ServiceTitle.CreateUnit);
    }
    getTagList();
    getTeacherList();
    getCategoryList();

    return () => {
      clearUnitDetail();
      unitForm.resetFields();
    };
  }, [unitId]);

  useEffect(() => {
    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    if (data.id) {
      unitForm.resetFields();
      setUnitType(data.item.type);
    }
  }, [data.id]);

  useEffect(() => {
    if (!unitId) {
      unitForm.setFields([{ name: 'currentVersion', value: itemId ? '1' : '' }]);
    }
  }, [itemId]);

  useEffect(() => {
    if (data.item.id) setItemId(data.item.id);
  }, [data.item]);

  function handleChangeUnitType(value) {
    setUnitType(value);
    selectItemVersion({ item: {} });
    setItemId(NaN);
    if (value) {
      handleCreateVersionModal({ unitType: value });
    }
  }

  function handleCreateVersionModal(params) {
    const unitTypeData = params.unitType;
    const modalProps = {
      width: 800,
      modalData: {
        title: '新規バージョン追加',
        unitType: unitTypeData,
        unitId: parseFloat(unitId || ''),
      },
    };
    return showModal(<CreateVersionModal {...modalProps} />);
  }

  function handleSelectVersionModal() {
    const modalProps = {
      modalData: {
        title: 'バージョン変更',
        headerAction: (
          <Button
            type="primary"
            onClick={() => handleCreateVersionModal({ unitType })}
          >
            新規追加
          </Button>
        ),
        currentVersion: data.currentVersion,
        unitId: parseFloat(unitId || ''),
        unitType,
      },
    };
    return showModal(<SelectVersionModal {...modalProps} />);
  }

  function handleSubmitForm(values) {
    const unitRequestData = {
      name: values.name.trim(),
      ...(!isEmpty(values.description) && { description: (values.description || '').trim() }),
      status: values.status,
      teacherIds: isEmpty(values.teacherIds) ? [] : values.teacherIds,
      tagIds: isEmpty(values.tagIds) ? [] : values.tagIds,
      ...(isNumber(values.categoryId) && { categoryId: values.categoryId }),
    };
    if (unitId) {
      const requestUpdateData = {
        unit: {
          ...unitRequestData,
          version: data.currentVersion,
        },
      };
      updateUnit({ requestData: requestUpdateData, id: unitId });
    } else {
      const requestCreateData = {
        unit: {
          ...unitRequestData,
          itemType: unitType,
          itemId,
        },
      };
      createUnit(requestCreateData);
    }
  }

  function handleDeleteUnit() {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'このレッスンを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deleteUnit({ id: unitId }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function renderTagsOptions() {
    if (isEmpty(tagList.data)) return null;
    return tagList.data.map((tag) => (
      <Select.Option key={`tag-${tag.id}`} value={tag.id}>{tag.name}</Select.Option>
    ));
  }

  function renderTeachersOptions() {
    if (isEmpty(teacherList.data)) return null;
    return teacherList.data.map((teacher) => (
      <Select.Option key={`teacher-${teacher.id}`} value={teacher.id}>{teacher.name}</Select.Option>
    ));
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  function renderSpecifyField() {
    switch (unitType) {
    case 'video':
      return (
        <>
          <Style.FormRow>
            <Style.FormCol span={3}>
              <Text>講師</Text>
            </Style.FormCol>
            <Style.FormCol span={21}>
              <Input value={data.item ? data.item.teacherName : ''} disabled style={{ width: 350 }} />
            </Style.FormCol>
          </Style.FormRow>
          <Style.FormRow>
            <Style.FormCol span={3}>
              <Text>動画時間</Text>
            </Style.FormCol>
            <Style.FormCol span={21}>
              <Input
                value={data.item ? convertSecondTime(data.item.duration, 'HH:mm:ss') : ''}
                disabled
                style={{ width: 350 }}
              />
            </Style.FormCol>
          </Style.FormRow>
          <Style.FormRow>
            <Style.FormCol span={3}>
              <Text>更新日</Text>
            </Style.FormCol>
            <Style.FormCol span={21}>
              <Input
                value={data.item ? formatDateTime(data.item.updatedAt, DATE_FORMAT) as string : ''}
                disabled
                style={{ width: 350 }}
              />
            </Style.FormCol>
          </Style.FormRow>
          <Style.FormRow>
            <Style.FormCol span={3}>
              <Text>サムネイル</Text>
            </Style.FormCol>
            <Style.FormCol span={21}>
              <img width="195px" height="110px" src={data.item ? data.item.smallThumbnailUrl : ''} alt="" />
            </Style.FormCol>
          </Style.FormRow>
        </>
      );
    case 'test':
      return (
        <>
          <Style.FormRow>
            <Style.FormCol span={3}>
              <Text>問題数</Text>
            </Style.FormCol>
            <Style.FormCol span={21}>
              <Input value={data.item ? data.item.questionsCount : ''} disabled style={{ width: 350 }} />
            </Style.FormCol>
          </Style.FormRow>
          <Style.FormRow>
            <Style.FormCol span={3}>
              <Text>更新日</Text>
            </Style.FormCol>
            <Style.FormCol span={21}>
              <Input
                value={data.item ? formatDateTime(data.item.updatedAt, DATE_FORMAT) as string : ''}
                disabled
                style={{ width: 350 }}
              />
            </Style.FormCol>
          </Style.FormRow>
        </>
      );
    case 'tip':
      return (
        <Style.FormRow>
          <Style.FormCol span={3}>
            <Text>更新日</Text>
          </Style.FormCol>
          <Style.FormCol span={21}>
            <Input
              value={data.item ? formatDateTime(data.item.updatedAt, DATE_FORMAT) as string : ''}
              disabled
              style={{ width: 350 }}
            />
          </Style.FormCol>
        </Style.FormRow>
      );
    default:
      return null;
    }
  }

  return (
    <MainContainer>
      <MainContent fullView>
        <TopContent>
          {unitId
            ? <Text w6 xxxl>レッスン修正</Text>
            : <Text w6 xxxl>レッスン新規作成</Text>
          }
        </TopContent>
        <MainWrapper title="レッスン概要">
          <Form
            form={unitForm}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            {...unitId
              ? { initialValues: unitInitialFormValues }
              : { initialValues: { status: 'opening' } }
            }
            name="modifyUnitForm"
            onFinish={(values) => handleSubmitForm(values)}
            scrollToFirstError={true}
          >
            <Style.ModifyUnitContainer>
              <Form.Item
                label="レッスン名"
                name="name"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'レッスン名は必須項目です。' },
                  { whitespace: true, message: 'レッスン名は必須項目です。' },
                  { max: 50, message: 'レッスン名が長すぎます。（最大は50桁です）' },
                ]}
              >
                <Input style={{ width: 350 }} />
              </Form.Item>
              <Form.Item
                label="レッスン概要"
                name="description"
                labelAlign="left"
                colon={false}
                rules={[{ max: 300, message: 'レッスン概要が長すぎます。（最大は300桁です）'  }]}
              >
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 8 }} />
              </Form.Item>
              <Form.Item
                label="ステータス"
                name="status"
                labelAlign="left"
                colon={false}
              >
                <Select
                  allowClear
                  suffixIcon={<CaretDownOutlined />}
                  placeholder="選択してください"
                  disabled={isEmpty(unitId)}
                  style={{ width: 350 }}
                >
                  <Select.Option value="opening">オープン</Select.Option>
                  <Select.Option value="closed">中止</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="講座タイプ"
                name="categoryId"
                labelAlign="left"
                colon={false}
                rules={[{ required: true, message: '講座タイプは必須項目です。' }]}
              >
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="選択してください"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                  style={{ width: 350 }}
                >
                  {renderCategoryOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="担当講師"
                name="teacherIds"
                labelAlign="left"
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                >
                  {renderTeachersOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tags"
                name="tagIds"
                labelAlign="left"
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                >
                  {renderTagsOptions()}
                </Select>
              </Form.Item>
            </Style.ModifyUnitContainer>
            <Style.ModifyUnitContainer>
              <Text lg w6 style={{ marginBottom: 16 }}>コンテンツ</Text>
              <Form.Item
                label="バージョン"
                name="currentVersion"
                labelAlign="left"
                colon={false}
                rules={[{ required: true, message: 'バージョンは必須項目です。' }]}
                style={{ marginBottom: unitId && 0 }}
              >
                <Style.InputAction>
                  <Input
                    disabled
                    value={unitId
                      ? data.currentVersion
                      : (itemId ? 1 : '')
                    }
                    style={{ width: 350 }}
                  />
                  {unitId && (
                    <Button
                      type="primary"
                      ghost
                      onClick={() => handleSelectVersionModal()}
                      style={{ marginLeft: 12 }}
                    >
                      バージョン変更
                    </Button>
                  )}
                </Style.InputAction>
                {unitId && <Text style={{ margin: '2px 0' }}>バージョンを変更するとコンテンツ全体に反映されます。</Text>}
              </Form.Item>
              <Form.Item
                label="タイプ"
                name="unitType"
                labelAlign="left"
                colon={false}
                rules={[{ required: true, message: 'タイプは必須項目です。' }]}
              >
                {unitId
                  ? (
                      <Input
                        value={data && data.item ? UNIT_TYPE[data.item.type] : ''}
                        disabled
                        style={{ width: 350 }}
                      />
                    )
                  : (
                    <Select
                      allowClear
                      suffixIcon={<CaretDownOutlined />}
                      placeholder="選択してください"
                      value={unitType}
                      onChange={(value) => handleChangeUnitType(value)}
                      style={{ width: 350 }}
                    >
                      <Select.Option value="video">動画</Select.Option>
                      <Select.Option value="test">テスト</Select.Option>
                      <Select.Option value="tip">Tips</Select.Option>
                      <Select.Option value="survey">アンケート</Select.Option>
                    </Select>
                  )
                }
              </Form.Item>
              <Style.FormRow>
                <Style.FormCol span={3}>
                  <Text>レッスン名</Text>
                </Style.FormCol>
                <Style.FormCol span={21}>
                  <Style.InputAction>
                    <Input
                      value={data.item ? data.item.name : ''}
                      disabled
                      readOnly
                      style={{ width: 350 }}
                    />
                    {!unitId && (
                      <Button
                        type="primary"
                        ghost
                        onClick={() => handleCreateVersionModal({ unitType })}
                        disabled={isEmpty(unitType)}
                        style={{ marginLeft: 12 }}
                      >
                        コンテンツ変更
                      </Button>
                    )}
                  </Style.InputAction>
                </Style.FormCol>
              </Style.FormRow>
              <Style.FormRow>
                <Style.FormCol span={3}>
                  <Text>講座タイプ</Text>
                </Style.FormCol>
                <Style.FormCol span={21}>
                  <Input
                    value={data.item && data.item.category?.name
                      ? data.item.category.name
                      : (data.item.category ? data.item.category : '')
                    }
                    disabled
                    style={{ width: 350 }}
                  />
                </Style.FormCol>
              </Style.FormRow>
              {renderSpecifyField()}
              <Row>
                <Col span={3} />
                <Col span={21}>
                  {!isEmpty(unitDetail.errors) && (
                    <Text error>{unitDetail.errors[0].message}</Text>
                  )}
                </Col>
              </Row>
            </Style.ModifyUnitContainer>
            <Style.ModifyUnitAction>
              <Row style={{ width: '100%' }}>
                <Col span={3} />
                <Col span={21}>
                  <Style.SubmitActionContent hasSubText={!!unitId}>
                    {unitId && (
                      <Space align="center" size={32}>
                        <Space align="center" size="large">
                          <Text xs subText w6>更新者</Text>
                          <TooltipParagraph
                            style={{ maxWidth: 300, color: '#333333', fontWeight: '600' }}
                          >
                            {data.lastUpdater.jituName}
                          </TooltipParagraph>
                        </Space>
                        <Space align="center" size="large">
                          <Text xs subText w6>更新日</Text>
                          <Text headerText w6>{formatDateTime(data.updatedAt, DATE_TIME_FORMAT)}</Text>
                        </Space>
                      </Space>
                    )}
                    <Space align="center" size="middle">
                    {unitId && (
                      <Button
                        type="primary"
                        danger
                        ghost
                        htmlType="button"
                        disabled={unitDetail.data.hasBeenUsed}
                        onClick={() => handleDeleteUnit()}
                      >
                        &nbsp;削除&nbsp;
                      </Button>
                    )}
                      <Button
                        type="primary"
                        className="btn-secondary"
                        onClick={() => history.push(ServicePath.UnitList)}
                      >
                        キャンセル
                      </Button>
                      <Button type="primary" htmlType="submit" disabled={isDisableRequest}>保存する</Button>
                    </Space>
                  </Style.SubmitActionContent>
                </Col>
              </Row>
            </Style.ModifyUnitAction>
          </Form>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { unitDetail } = state.unitReducer;
  const { tagList } = state.tagReducer;
  const { teacherList } = state.teacherReducer;
  const { categoryList } = state.categoryReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    unitDetail,
    tagList,
    teacherList,
    categoryList,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUnitDetail: (params) => dispatch(getUnitDetailAction(params)),
  getTagList: () => dispatch(getTagListAction()),
  getTeacherList: () => dispatch(getTeacherListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  selectItemVersion: (params) => dispatch(selectItemVersionAction(params)),
  updateUnit: (params) => dispatch(updateUnitAction(params)),
  createUnit: (params) => dispatch(createUnitAction(params)),
  deleteUnit: (params) => dispatch(deleteUnitAction(params)),
  clearUnitDetail: () => dispatch(clearUnitDetailAction()),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyUnitPage);
