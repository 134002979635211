// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class TestServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getTestList(params) {
    return this.restClient.get('/admin_manage/tests', params);
  }

  getTestDetail(params) {
    return this.restClient.get(`/admin_manage/tests/${params.id}`);
  }

  createTest(params) {
    return this.restClient.post('/admin_manage/tests', params, {}, 'formData');
  }

  updateTest(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/tests/${id}`, requestData, {}, 'formData');
  }

  deleteQuestion(params) {
    const { testId, questionId } = params;
    return this.restClient.delete(`/admin_manage/tests/${testId}/questions/${questionId}`);
  }

  getQuestions(params) {
    return this.restClient.get(`/admin_manage/tests/${params.id}/questions`);
  }

  moveQuestion(params) {
    const { requestData, testId, questionId } = params;
    return this.restClient.patch(`/admin_manage/tests/${testId}/questions/${questionId}/position`, requestData);
  }

  getQuestionDetail(params) {
    const { testId, questionId } = params;
    return this.restClient.get(`/admin_manage/tests/${testId}/questions/${questionId}`);
  }

  createQuestion(params) {
    const { testId, questionData } = params;
    return this.restClient.post(`/admin_manage/tests/${testId}/questions`, questionData, {}, 'formData');
  }

  updateQuestion(params) {
    const { testId, questionId, questionData } = params;
    return this.restClient.put(`/admin_manage/tests/${testId}/questions/${questionId}`, questionData, {}, 'formData');
  }

  getFileDownloadURL(params) {
    return this.restClient.get(`/admin_manage/tests/${params.id}/download_explanation`);
  }

  joinOverviewTest(params) {
    return this.restClient.get('/admin_manage/take_course/join_tests', params);
  }

  copyTest(params) {
    return this.restClient.post(`admin_manage/tests/${params.id}/copy`, {}, {}, 'formData');
  }

  deleteTest(params) {
    return this.restClient.delete(`admin_manage/tests/${params.id}`);
  }
}
