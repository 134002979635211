// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { Form, Button } from 'antd';
import styled from 'styled-components';
import { Text } from 'src/components/styles';

export const FieldContent = styled.div`
  padding: 24px;

  @media (max-width: 428px) {
    .ant-form-item-label > label {
      height: 12px !important;
    }
  }
`;

export const FormWrapper = styled(Form)`
  display: none;

  @media (max-width: 428px) {
    display: block;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-top: 1px solid ${(props) => props.theme.colors.primaryBorder};
`;

export const ButtonCancel = styled(Button)`
  background-color: #CCCCCC !important;
`

export const TextTitle = styled(Text)`
  @media (max-width: 428px) {
    font-weight: 700;
  }
`
