// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import { Space, Button } from 'antd';
import { isEmpty } from 'lodash/lang';

import { Text } from 'src/components/styles/Texts';

import {
  closeModal as closeModalAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModalFooterProps extends DispatchProps, StateToProps {
  onSubmit(): void;
  onClose(): void;
  closeModal(): void;
  okText: string;
  cancelText: string;
  messageText: string;
  error: string;
  isConfirm: boolean;
  isCloseAction: boolean;
  isForm: boolean;
  isDisableRequest: boolean;
  spaceEvenly?: boolean;
  isButtonLoading?: boolean;
  isSpaceCenter?: boolean;
}

const ModalFooter: React.FC<IModalFooterProps> = ({
  onSubmit,
  onClose,
  closeModal,
  okText,
  cancelText,
  messageText,
  error,
  isConfirm,
  isCloseAction,
  isForm,
  isDisableRequest,
  spaceEvenly,
  isButtonLoading,
  isSpaceCenter
}) => (
  <Style.FooterModal hasSubText={!!error || !!messageText} spaceEvenly={spaceEvenly} isSpaceCenter={isSpaceCenter}>
    {(!!error || !!messageText) && (
      <div>
        {!!messageText && (
          <Text>{messageText}</Text>
        )}
        {!isEmpty(error) && (
          <Text error>{error}</Text>
        )}
      </div>
    )}
    <Style.SpaceCustom align="center" size="middle">
      <Button
        type="primary"
        htmlType="button"
        className="btn-secondary"
        onClick={() => {
          isCloseAction ? onClose() : closeModal();
        }}
        disabled={isDisableRequest}
      >
        {cancelText}
      </Button>
      {isConfirm && (
        <Button
          type="primary"
          className="btn-primary"
          htmlType={isForm ? 'submit' : 'button'}
          onClick={() => !isForm && onSubmit()}
          disabled={!isButtonLoading && isDisableRequest}
          loading={isButtonLoading && isDisableRequest}
        >
          {okText}
        </Button>
      )}
    </Style.SpaceCustom>
  </Style.FooterModal>
);

const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModalFooter);
