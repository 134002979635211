// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H

export const PAGE_TITLE = (title) => `${title} | SkillUpAI LMS`;

export const LoginTitle = 'ログイン';
export const ForgotPasswordTitle = 'パスワード忘れ';
export const ResetPasswordTitle = 'パスワード再設定';
export const NotFoundTitle = '404未検出';
export const ServerErrorTitle = 'サーバーエラー';
export const MyProfileTitle = 'マイプロフィール';

// Admin Service
export const ServiceTitle = {
  AdminServiceTop: 'サービス管理者TOP',
  CourseList: 'コース一覧',
  CourseDetail: 'コース詳細内容',
  CreateCourse: 'コース新規作成',
  EditCourse: 'コース修正',
  CourseView: 'コースのプレビュー',
  SurveyOverview: 'アンケートのプレビュー',
  TestOverview: 'テスト概要のプレビュー',
  TestingForm: 'テスト受講のプレビュー',
  ModifyChapterFAQ: 'チャプターにFAQ追加',
  UnitList: 'レッスン一覧',
  CreateUnit: 'レッスン新規作成',
  UnitDetail: 'レッスン修正',
  TestList: 'テスト一覧',
  CreateTest: 'テスト新規作成',
  EditTest: 'テスト修正',
  QuestionList: '質問一覧',
  QuestionDetail: '質問詳細',
  VideoList: '動画一覧',
  TipList: 'Tips一覧',
  EditTip: 'Tips修正',
  CreateTip: 'Tips新規作成',
  ManagerAccount: 'アカウント管理',
  LicenseGrant: 'ライセンス管理',
  LicenseList: 'ライセンス一覧',
  EditLicense: 'ライセンス修正',
  CreateLicense: 'ライセンス設定',
  CompanyList: '企業一覧',
  CompanyDetail: '企業詳細_基本情報',
  SurveyList: 'アンケート一覧',
  CreateSurvey: 'アンケート新規作成',
  EditSurvey: 'アンケート修正',
  FAQList: 'FAQ一覧',
  CreateFAQ: 'FAQ新規作成',
  EditFAQ: 'FAQ修正',
  UploadAnswerList: 'テスト採点',
  MasterSetting: '設定',
  ProfileDetail: 'プロフィール詳細',
  PreviewQuestion: '質問プレビュー',
  ConfirmServiceAccount: 'サービス管理者アカウント登録',
  NotificationList: '通知一覧',
};

// Admin Company
export const CompanyTitle = {
  AdminCompanyTop: '企業管理者TOP',
  CourseDetail: 'コース詳細内容',
  CompanyDetail: '企業詳細_基本情報',
  MasterSetting: '設定',
  ManagerAccount: 'アカウント管理',
  LicenseGrant: 'ライセンス管理',
  ProfileDetail: 'プロフィール詳細',
  ConfirmCompany: '企業アカウント登録',
  ConfirmAdminCompany: '企業管理者情報',
};

// Student
export const StudentTitle = {
  StudentTop: '受講者TOP',
  TestOverview: 'テスト概要',
  TestingForm: 'テスト受講',
  SurveyOverview: 'アンケート確認',
  CourseView: 'コース受講',
  CourseDetail: 'コース詳細内容',
  ConfirmStudent: 'ユーザーアカウント登録',
};

export const CheckVideoTitle = '動画確認';
export const HealthCheckTitle = 'ヘルスチェック';
