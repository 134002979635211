// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Space,
} from 'antd';
import Slider from 'react-slick';
import { isNumber } from 'lodash/lang';

import NoData from 'src/components/layouts/NoData';

import { Text, SkeletonLoading } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import learningCourseIcon from 'src/assets/images/statistic/learning-course-icon.svg';
import nextCourseHistory from 'src/assets/images/statistic/next-course-history.svg';
import prevCourseHistory from 'src/assets/images/statistic/prev-course-history.svg';
import nextCourseHistoryDisabled from 'src/assets/images/statistic/next-course-history-disabled.svg';
import prevCourseHistoryDisabled from 'src/assets/images/statistic/prev-course-history-disabled.svg';
import docFileIcon from 'src/assets/images/common/doc-file-icon.svg';
import tipFileIcon from 'src/assets/images/common/tip-file-icon.svg';
import surveyFileIcon from 'src/assets/images/common/survey-file-icon.svg';

import * as Style from './styles';

interface IHistoryCoursesProps {
  getStudentLearningHistories(params): void;
  learningHistories: {
    data: {
      course: {
        id: number;
        name: string;
        groupId: number;
        chapterId: number;
      };
      unitVersion: {
        id: string;
        unit: {
          name: string;
        };
        item: {
          type: string;
          thumbnailUrl: string;
        };
      };
    }[];
    meta: {
      pageInfo: {
        next: number;
      };
    };
    load: boolean;
  };
}

const HistoryCourses: React.FC<IHistoryCoursesProps> = ({
  getStudentLearningHistories,
  learningHistories,
}) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [slideLength, setSlideLength] = useState<number>(3);
  const courseHistoryRef = useRef<any>(null);

  useEffect(() => {
    setSlideLength(window.innerWidth < 428 ? 2 : 3)
    window.addEventListener('resize', () => {
      setSlideLength(window.innerWidth < 428 ? 2 : 3)
    });
    return () => window.removeEventListener('resize', () => {});
  }, [])

  const sliderSettings = {
    infinite: false,
    slidesToShow: slideLength,
    speed: 500,
    initialSlide: 0,
    dots: false,
    arrows: false,
    touchMove: false,
    beforeChange: (_, next) => setActiveSlideIndex(next),
  };

  function handleNextCourseHistory() {
    if ((activeSlideIndex === (learningHistories.data.length - 5)) && isNumber(learningHistories.meta.pageInfo.next)) {
      getStudentLearningHistories({
        page: learningHistories.meta.pageInfo.next,
        more: true,
      });
    }
    courseHistoryRef.current.slickNext();
  }

  function handlePrevCourseHistory() {
    courseHistoryRef.current.slickPrev();
  }

  function renderHistoryImage(unitVersion) {
    switch (unitVersion.item.type) {
    case 'video':
      return unitVersion.item.thumbnailUrl;
    case 'test':
      return docFileIcon;
    case 'tip':
      return tipFileIcon;
    case 'survey':
      return surveyFileIcon;
    default:
      return undefined;
    }
  }

  function renderCourseHistoriesContainer() {
    if (learningHistories.load) {
      return (
        <Row style={{ margin: '0 -12px' }}>
          {[...Array(slideLength)].map((historyCard, historyCardIndex) => (
            <Col span={24/slideLength} key={`history-card-${historyCardIndex}`}>
              {renderLoadingCourseHistories(historyCard, historyCardIndex)}
            </Col>
          ))}
        </Row>
      );
    }
    if (learningHistories.data.length === 0) {
      return(
        <NoData message="データが存在しません。" />
      );
    }
    if (learningHistories.data.length <= slideLength) {
      return (
        <Row style={{ margin: '0 -12px' }}>
          {learningHistories.data.map((historyCard, historyCardIndex) => (
            <Style.HistoryCardWrapper key={`history-card-${historyCardIndex}`}>
              {renderCourseHistoriesCard(historyCard, historyCardIndex)}
            </Style.HistoryCardWrapper>
          ))}
        </Row>
      );
    } else {
      return (
        <Slider
          {...sliderSettings}
          className="history-course-slide"
          ref={courseHistoryRef}
        >
          {learningHistories.data.map((historyCard, historyCardIndex) => (
            renderCourseHistoriesCard(historyCard, historyCardIndex)
          ))}
        </Slider>
      );
    }
  }

  function renderLoadingCourseHistories(_, historyCardIndex) {
    return (
      <Style.HistoryCardContainer key={`loading-history-card-${historyCardIndex}`}>
        <Style.HistoryCardContent>
          <Style.LoadingHistoryCardImage />
          <Style.SkeletonHistoryCardInfo>
            <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
            <SkeletonLoading
              active
              paragraph={{ rows: 2 }}
              title={false}
              height={16}
              margin="4px 0 0 0"
            />
          </Style.SkeletonHistoryCardInfo>

          <Style.SkeletonHistoryCardInfoMobile>
            <SkeletonLoading active paragraph={false} title={{ width: '70%' }} height={12} />
            <SkeletonLoading
              active
              paragraph={{ rows: 2 }}
              title={false}
              height={16}
              margin="4px 0 0 0"
            />
          </Style.SkeletonHistoryCardInfoMobile>
        </Style.HistoryCardContent>
      </Style.HistoryCardContainer>
    );
  }

  function renderCourseHistoriesCard(historyCard, historyCardIndex) {
    return (
      <Style.HistoryCardContainer key={`history-card-${historyCardIndex}`}>
        <Style.HistoryCardContent
          onClick={() => window.open(
            `/learn-course/${historyCard.course.id}/${historyCard.unitVersion.item.type}/${historyCard.unitVersion.id}?group=${historyCard.course.groupId}&chapter=${historyCard.course.chapterId}`,
            '_blank',
          )}
        >
          <Style.HistoryCardImage
            isThumbnailUrl={historyCard.unitVersion.item.type === 'video'}
            image={renderHistoryImage(historyCard.unitVersion)}
            isTip={historyCard.unitVersion.item.type === 'tip'}
          />
          <Style.HistoryCardInfo>
            <Style.HistoryCardInfoName align="center">
              <img src={learningCourseIcon} alt="" />
              <Style.HistoryCourseName w6>
                <TooltipParagraph isPreWrap rows={2}>
                  {historyCard.course.name}
                </TooltipParagraph>
              </Style.HistoryCourseName>
            </Style.HistoryCardInfoName>
            <Style.HistoryUnitName>
              <TooltipParagraph isPreWrap rows={1}>
                {historyCard.unitVersion.unit.name}
              </TooltipParagraph>
            </Style.HistoryUnitName>
          </Style.HistoryCardInfo>
        </Style.HistoryCardContent>
      </Style.HistoryCardContainer>
    );
  }

  return (
    <Style.HistoryWrapper>
      <Style.HistoryCourseTitle>
        <Style.TextHeader xxxl>受講履歴</Style.TextHeader>
        {learningHistories.data.length > slideLength && (
          <Space align="center" size={24}>
            <Button
              type="link"
              icon={activeSlideIndex === 0 || learningHistories.data.length <= slideLength
                ? <img src={prevCourseHistoryDisabled} alt="" />
                : <img src={prevCourseHistory} alt="" />
              }
              disabled={activeSlideIndex === 0}
              onClick={() => handlePrevCourseHistory()}
              style={{ padding: 0 }}
            />
            <Button
              type="link"
              icon={activeSlideIndex === (learningHistories.data.length - slideLength) || learningHistories.data.length <= slideLength
                ? <img src={nextCourseHistoryDisabled} alt="" />
                : <img src={nextCourseHistory} alt="" />
              }
              disabled={activeSlideIndex === (learningHistories.data.length - slideLength)}
              onClick={() => handleNextCourseHistory()}
              style={{ padding: 0 }}
            />
          </Space>
        )}
      </Style.HistoryCourseTitle>
      {renderCourseHistoriesContainer()}
    </Style.HistoryWrapper>
  );
};

export default HistoryCourses;
