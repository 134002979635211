// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from "react";
import { Button } from "antd";
import { connect } from "react-redux";

import { closeModal as closeModalAction } from "src/redux/actions";
import { Text } from "src/components/styles";

import * as Style from "./styles";

interface IShowErrorModalProps extends DispatchProps {
  modalData: {
    title: string;
    message: string;
  };
  isConfirm: boolean;
  closeModal: () => void;
}

const ShowErrorModal: React.FC<IShowErrorModalProps> = ({
  modalData,
  closeModal,
}) => (
  <>
    <Style.ShowErrorModalContainer>
      <Text error w6 lg>
        {modalData.message}
      </Text>
    </Style.ShowErrorModalContainer>
    <Style.ButtonWrapper>
      <Button
        type="primary"
        className="btn-secondary"
        onClick={() => closeModal()}
      >
        閉じる
      </Button>
    </Style.ButtonWrapper>
  </>
);

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(ShowErrorModal);
