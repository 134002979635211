// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { STATISTIC_ACTION } from './statistic.constant';
import { COURSE_ACTION } from './course.constant';
import { UNIT_ACTION } from './unit.constant';
import { TEST_ACTION } from './test.constant';
import { VIDEO_ACTION } from './video.constant';
import { ACCOUNT_ACTION } from './account.constant';
import { SURVEY_ACTION } from './survey.constant';

export const STUDENT = {
  STATISTIC_ACTION,
  COURSE_ACTION,
  UNIT_ACTION,
  TEST_ACTION,
  VIDEO_ACTION,
  ACCOUNT_ACTION,
  SURVEY_ACTION,
};
