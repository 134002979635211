// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty, isNumber } from 'lodash/lang';

import history from 'src/utils/history';
import { formatDateTime } from 'src/utils/dateTime';
import { getFilterOption } from 'src/utils/common';

import {
  MainContainer,
  MainContent,
  TopContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles/Texts';

import { DATE_TIME_FORMAT } from 'src/constants/common';
import { URL_REGEX } from 'src/constants/regexValidate';
import { ServicePath } from 'src/constants/routerConstants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getSurveyDetail as getSurveyDetailAction,
  getTagList as getTagListAction,
  getCategoryList as getCategoryListAction,
  createSurvey as createSurveyAction,
  updateSurvey as updateSurveyAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModifySurveyPageProps {
  getSurveyDetail(params): void;
  getCategoryList(): void;
  getTagList(): void;
  createSurvey(params): void;
  updateSurvey(params): void;
  isDisableRequest: boolean;
  match: {
    params: {
      id?: string;
    };
  };
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  surveyDetail: {
    data: {
      id: number,
      addedToCourse: boolean,
      name: string,
      description: string,
      surveyType: string,
      updatedAt: string,
      url: string,
      category: {
        id: number,
        name: string,
      },
      lastUpdater: {
        id: number,
        jituName: string,
      }
      tags: {
        id: number,
        name: string,
      }[],
    },
    meta: object,
    errors: {
      message: string;
    }[];
  };
}

const ModifySurveyPage: React.FC<IModifySurveyPageProps> = ({
  getSurveyDetail,
  getTagList,
  getCategoryList,
  createSurvey,
  updateSurvey,
  match,
  categoryList,
  surveyDetail,
  tagList,
  isDisableRequest
}) => {
  const { data } = surveyDetail;
  const surveyId = match.params.id;
  const [surveyForm] = Form.useForm();

  useEffect(() => {
    if (surveyId) {
      document.title = PAGE_TITLE(ServiceTitle.EditSurvey);

      if (parseFloat(surveyId) !== data.id) {
        getSurveyDetail({ id: surveyId });
      }
    } else {
      document.title = PAGE_TITLE(ServiceTitle.CreateSurvey);
    }
    getTagList();
    getCategoryList();

    return () => {
      surveyForm.resetFields();
    };
  }, [surveyId]);

  useEffect(() => {
    if (data.id) {
      surveyForm.resetFields();
    }
  }, [data]);

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  function renderTagsOptions() {
    if (isEmpty(tagList.data)) return null;
    return tagList.data.map((tag) => (
      <Select.Option key={`tag-${tag.id}`} value={tag.id}>{tag.name}</Select.Option>
    ));
  }

  const surveyInitialFormValues = {
    name: data.name,
    description: data.description,
    surveyType: data.surveyType,
    url: data.url,
    categoryId: data.category?.id,
    tagIds: data.tags.map((tag) => tag.id),
  };

  function handleSubmitForm(values) {
    const requestData = {
      survey: {
        name: values.name.trim(),
        url: values.url.trim(),
        surveyType: values.surveyType,
        tagIds: isEmpty(values.tagIds) ? [] : values.tagIds,
        ...(values.description && { description: values.description?.trim() }),
        ...(isNumber(values.categoryId) && { categoryId: values.categoryId }),
      },
    };
    if (surveyId) {
      updateSurvey({ requestData, id: surveyId });
    } else {
      createSurvey(requestData);
    }
  }

  return (
    <MainContainer>
      <MainContent fullView>
        <TopContent>
          {surveyId
            ? <Text w6 xxxl>アンケート修正</Text>
            : <Text w6 xxxl>アンケート新規作成</Text>
          }
        </TopContent>
        <MainWrapper title="アンケート内容">
          <Form
            form={surveyForm}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            {...surveyId && { initialValues: surveyInitialFormValues }}
            name="modifySurveyForm"
            onFinish={(values) => handleSubmitForm(values)}
            scrollToFirstError={true}
          >
            <Style.ModifySurveyContainer>
              <Form.Item
                label="アンケート名"
                name="name"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'アンケート名は必須項目です。' },
                  { whitespace: true, message: 'アンケート名は必須項目です。' },
                  { max: 50, message: 'アンケート名が長すぎます。（最大は50桁です）' },
                ]}
              >
                <Input style={{ width: 350 }} />
              </Form.Item>
              <Form.Item
                label="アンケート概要"
                name="description"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'テスト概要は必須項目です。' },
                  { whitespace: true, message: 'テスト概要は必須項目です。' },
                  { max: 250, message: 'テスト概要が長すぎます。（最大は250桁です）' },
                ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  className="transparent-input"
                />
              </Form.Item>
              <Form.Item
                label="講座タイプ"
                name="categoryId"
                labelAlign="left"
                colon={false}
              >
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="選択してください"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                  style={{ width: 350 }}
                >
                  {renderCategoryOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="アンケート種別"
                name="surveyType"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'アンケート種別は必須項目です。' },
                ]}
              >
                <Select
                  allowClear
                  suffixIcon={<CaretDownOutlined />}
                  placeholder="選択してください"
                  className="transparent-input"
                  style={{ width: 350 }}
                  disabled={surveyDetail?.data?.addedToCourse}
                >
                  <Select.Option value="pre_survey">事前アンケート</Select.Option>
                  <Select.Option value="confirm_survey">確認アンケート</Select.Option>
                  <Select.Option value="finish_survey">修了アンケート</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Tags"
                name="tagIds"
                labelAlign="left"
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                >
                  {renderTagsOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="アンケートURL"
                name="url"
                labelAlign="left"
                colon={false}
                validateTrigger={['onChange', 'onBlur']}
                rules={[
                  { required: true, message: 'アンケートURLは必須項目です。' },
                  { whitespace: true, message: 'アンケートURLは必須項目です' },
                  { pattern: new RegExp(URL_REGEX), message: '入力したリンクのフォーマットは正しくありません。' },
                  { max: 255, message: 'アンケートURLが長すぎます。（最大は255桁です）' },
                ]}
              >
                <Input />
              </Form.Item>
            </Style.ModifySurveyContainer>
            <Style.ModifySurveyAction>
              <Row style={{ width: '100%' }}>
                <Col span={3} />
                <Col span={21}>
                  <Style.SubmitActionContent hasSubText={!!surveyId}>
                    {surveyId && (
                      <Space align="center" size={32}>
                        <Space align="center" size="large">
                          <Text xs subText w6>更新者</Text>
                          <TooltipParagraph
                            style={{ maxWidth: 300, color: '#333333', fontWeight: '600' }}
                          >
                            {data.lastUpdater.jituName}
                          </TooltipParagraph>
                        </Space>
                        <Space align="center" size="large">
                          <Text xs subText w6>更新日</Text>
                          <Text headerText w6>{formatDateTime(data.updatedAt, DATE_TIME_FORMAT)}</Text>
                        </Space>
                      </Space>
                    )}
                    <Space align="center" size="middle">
                      <Button
                        type="primary"
                        htmlType="button"
                        className="btn-secondary"
                        onClick={() => history.push(ServicePath.SurveyList)}
                      >
                        キャンセル
                      </Button>
                      <Button type="primary" htmlType="submit" disabled={isDisableRequest}>保存する</Button>
                    </Space>
                  </Style.SubmitActionContent>
                </Col>
              </Row>
            </Style.ModifySurveyAction>
          </Form>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  const { surveyDetail } = state.surveyReducer;
  const { isDisableRequest } = state.disableRequestReducer;

  return {
    tagList,
    categoryList,
    surveyDetail,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSurveyDetail: (params) => dispatch(getSurveyDetailAction(params)),
  getTagList: () => dispatch(getTagListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  createSurvey: (params) => dispatch(createSurveyAction(params)),
  updateSurvey: (params) => dispatch(updateSurveyAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifySurveyPage);

