// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const ManagerStatusContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ManagerStatusSidebar = styled.div`
  margin-right: 16px;
  min-width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

export const ManageStatusContent = styled.div`
  margin: 0 auto;
  max-width: calc(100% - 316px);
  width: 100%;
`;

export const TableAction = styled.div`
  & > button {
    width: 100px;
  }
`;

export const RegistrationStatusAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & button span {
      padding-top: 5px;
    }
  }
`;

export const HeaderBlock = styled.div`
display: flex;
`;
