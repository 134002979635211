//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import {
  Button,
  Table,
  Space,
  Progress,
} from 'antd';
import { isEmpty, isNumber } from 'lodash';

import history from 'src/utils/history';
import { convertSecondTime } from 'src/utils/dateTime';
import { formatDateTime } from 'src/utils/dateTime';
import { showAlertNotice } from 'src/utils/alert';

import { PaginationLayout } from 'src/components/layouts/Pagination';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';
import { ServicePath } from 'src/constants/routerConstants';

import DragDropCourseModal from '../DragDropModal';

import * as Style from './styles';

interface ICompanyCoursesProps {
  getCompanyCourses(params): void;
  showModal(params): void;
  companyIdSelected: any;
  activeTabKey: string;
  companyCourses: {
    data: {
      id: number;
      plan: {
        id: number;
        name: string;
      };
      courseCategory: {
        name: string;
      };
      name: string;
      totalLearningTime: number;
      usedLicensesPass: number;
      companyProgressPercentage: number;
      licensesCount: number;
      remainingLicensesCount: number;
      startDate: string;
      endDate: string;
      remainingDays: number;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const CompanyCourses: React.FC<ICompanyCoursesProps> = ({
  getCompanyCourses,
  showModal,
  companyIdSelected,
  companyCourses,
  activeTabKey,
}) => {
  const { data, meta } = companyCourses;
  const pageUrl = history.location.state?.page;
  
  useEffect(() => {
    if (companyIdSelected && activeTabKey === '2') {
      getCompanyCourses({
        id: companyIdSelected,
        requestData: {
          page: 1,
          ...pageUrl,
          items: pageUrl?.limit
        },
      });
    }
  }, [companyIdSelected, activeTabKey]);

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey,
        page: meta.pageInfo,
        companyContractId: companyIdSelected
      }
    })
  }, [meta.pageInfo, companyIdSelected, activeTabKey])

  function redirectLicenseGrant() {
    return history.push({
      pathname: ServicePath.LicenseGrant,
      state: { companyContractId: companyIdSelected }, // select company contract in license grant screen
    });
  }

  const tableData = data.map((row: any) => ({
    key: row.id,
    id: row.id,
    name: row.courseCategory.name,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'HH:mm:ss'),
    usedLicensesPass: row.usedLicensesPass,
    companyProgressPercentage: row.companyProgressPercentage,
    licensesCount: `${row.licensesCount - row.remainingLicensesCount}(残り${row.remainingLicensesCount})`,
    startDate: row.startDate ? formatDateTime(row.startDate, DATE_FORMAT) : 'ー',
    endDate: row.endDate
      ? `${formatDateTime(row.endDate, DATE_FORMAT)}${row.remainingDays > 0 ? `[残り${row.remainingDays}日]` : ''}`
      : 'ー',
  }));

  const tableColumns = [
    {
      title: 'コース名',
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      width: 400,
    },
    {
      title: '学習時間',
      dataIndex: 'totalLearningTime',
      width: 120,
    },
    {
      title: '合格者数',
      dataIndex: 'usedLicensesPass',
      render: (_, record) => (
        <Text>{isNumber(record.usedLicensesPass) ? record.usedLicensesPass : 'ー'}</Text>
      ),
      width: 100,
    },
    {
      title: '進歩率',
      dataIndex: 'companyProgressPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.companyProgressPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.companyProgressPercentage} />
        </Space>
      ),
      width: 180,
    },
    {
      title: '受講者数',
      dataIndex: 'licensesCount',
      width: 160,
    },
    {
      title: '受講開始日',
      dataIndex: 'startDate',
      width: 120,
    },
    {
      title: '受講終了日',
      dataIndex: 'endDate',
      width: 200,
    },
  ];

  const handleDragDrop = () => {
    const modalProps = {
     modalData: {
       title: "順番の入れ替え",
     },
     data,
     companyId: companyIdSelected,
     closeModalAction: () => getCompanyCourses({
      id: companyIdSelected,
      requestData: {
        page: meta.pageInfo?.page,
        items: meta.pageInfo?.limit
      },
    }),
    }
    return showModal(<DragDropCourseModal {...modalProps} />)
  }

  return (
    <>
      <Style.CompanyCoursesTitle>
        <Text xxxl>契約コース一覧</Text>
        <div>
          <Button
            type="primary"
            disabled={isEmpty(data)}
            onClick={handleDragDrop}
            style={{ marginRight: 10 }}
          >
            順番の入れ替え
          </Button>
          <Button type="primary" onClick={() => redirectLicenseGrant()}>
          ライセンス付与
          </Button>
        </div>
      </Style.CompanyCoursesTitle>
      <Table
        className="table-cursor-pointer"
        pagination={false}
        scroll={{ x: 1280, y: 506 }}
        columns={tableColumns}
        dataSource={tableData}
        onRow={(record) => ({
          onClick: () => {
            history.push({
              pathname: `/admin-service/course/${record.id}`,
              state: { companyContractId: companyIdSelected },
            });
          },
        })}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={companyCourses.load}
      />
      {!isEmpty(companyCourses.data) && (
        <PaginationLayout
          pageInfo={meta.pageInfo}
          onChangePage={getCompanyCourses}
          pageSizeOptions={['10', '20', '50', '1000']}
          dataParam={{
            id: companyIdSelected,
            requestData: {
              page: 1,
            },
          }}
        />
      )}
    </>
  );
};

export default CompanyCourses;
