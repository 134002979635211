// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import {
  Button,
  Input ,
  Select,
  Form,
  DatePicker,
  InputNumber,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

import { onScrollLoadMore } from 'src/utils/common';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';

import { FORMAT_NUMBER_REGEX, CHECK_NUMBER_REGEX } from 'src/constants/regexValidate';

import datePickerItem from 'src/assets/images/common/datepicker-icon.png';

import * as Style from './styles';

interface IFilterSidebarProps {
  data: {
    type: string;
    title: string;
    fieldParams?: string;
    searchFilter?(params): void;
    options?: {
      id: string | number,
      name: string,
    }[];
    meta?: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    },
    getDependentData?(params): void;
    dependent?: string;
  }[];
  dataParam?: any;
  pageSize?: number;
  filterParams?: any;
  disabled?: boolean;
  actionFilter(params): void;
  setParamsFilter(params): void;
  setSortCondition(params): void;
}

const FilterSidebar: React.FC<IFilterSidebarProps> = ({
  data,
  dataParam,
  pageSize,
  filterParams,
  disabled,
  actionFilter,
  setParamsFilter,
  setSortCondition,
}) => {
  const [searchFilterKey, setSearchFilterKey] = useState<string>('');
  const [filterForm] = Form.useForm();

  useEffect(() => {
    if (filterParams) {
      data.forEach((filterItem) => {
        filterForm.setFields([{
          name: filterItem.fieldParams as string,
          value: filterParams[filterItem.fieldParams as string],
        }]);
      });
      filterForm.setFieldsValue({
        ...(filterParams as object),
        startDate: filterParams.startDate ? moment(filterParams.startDate) : filterParams.startDate,
        endDate: filterParams.endDate ? moment(filterParams.endDate) : filterParams.endDate,
        lastLearningDateGteq: filterParams.lastLearningDateGteq ? moment(filterParams.lastLearningDateGteq) : filterParams.lastLearningDateGteq,
        lastLearningDateLteq: filterParams.lastLearningDateLteq ? moment(filterParams.lastLearningDateLteq) : filterParams.lastLearningDateLteq,
      })
    }
  }, [filterParams]);

  function handleLoadMoreFilter(e, filterItem) {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    return onScrollLoadMore(
      {
        scrollTop,
        scrollHeight,
        clientHeight,
      },
      !!filterItem.meta.pageInfo.next,
      () => filterItem.searchFilter({
        ...filterItem.dependent && {
          [filterItem.dependent]: filterForm.getFieldValue(filterItem.dependent),
        },
        nameCont: searchFilterKey,
        page: filterItem.meta.pageInfo.next,
        more: true,
      }),
    );
  }

  function handleSubmitFilter(values) {
    if (!isEmpty(dataParam)) {
      const newDataParam = {
        ...dataParam,
        requestData: {
          ...dataParam.requestData,
          ...values,
          page: 1,
          items: pageSize,
        },
      };
      actionFilter({ ...newDataParam });
    } else {
      actionFilter({ ...values, page: 1, items: pageSize });
    }
    setParamsFilter(values);
    setSortCondition({});
  }

  function renderTagSelected({ label, onClose }) {
    return (
      <Style.TagSelected
        color="#D29C09"
        closable={true}
        onClose={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        {label}
      </Style.TagSelected>
    );
  }

   function handleAutoTrimInput(value, filterItem) {
     filterForm.setFieldsValue({
       [filterItem.fieldParams as string]: value.trim(),
     });
   }

  function renderFilterField() {
    return data.map((filterItem, filterIndex) => {
      if (filterItem.type === 'datepicker') {
        return (
          <div key={`filter-item-${filterIndex}`}>
            <Text margin="0 0 6px 0" sm>{filterItem.title}</Text>
            <Style.DatePickerWrapper>
              <Form.Item name="startDate">
                <DatePicker placeholder="" suffixIcon={<img src={datePickerItem} alt="" />} disabled={disabled} />
              </Form.Item>
              <Style.DistanceMiddle>~</Style.DistanceMiddle>
              <Form.Item name="endDate">
                <DatePicker placeholder="" suffixIcon={<img src={datePickerItem} alt="" />} disabled={disabled} />
              </Form.Item>
            </Style.DatePickerWrapper>
          </div>
        );
      } else if (filterItem.type === 'select' && filterItem.options) {
        return (
          <div key={`filter-item-${filterIndex}`}>
            <Text margin="0 0 6px 0" sm>{filterItem.title}</Text>
            <Form.Item name={filterItem.fieldParams}>
              <Select
                allowClear
                showSearch
                suffixIcon={<CaretDownOutlined />}
                {...!filterItem.searchFilter && { optionFilterProp: 'children' }}
                placeholder="選択してください"
                notFoundContent="データが存在しません。"
                disabled={disabled}
                filterOption={(input: any, option: any) => {
                  const optionTitle = option.children.props.children;
                  const filterValue = (input || '').trim();
                  return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                }}
                {...!!filterItem.getDependentData && {
                  onChange: (value) => {
                    if (
                      !!filterItem.getDependentData
                      && filterItem.fieldParams
                      && filterItem.dependent
                    ) {
                      filterForm.setFieldsValue({
                        [filterItem.dependent]: undefined,
                      });
                      filterItem.getDependentData({
                        [filterItem.fieldParams]: value,
                        nameCont: searchFilterKey,
                        page: 1,
                      });
                    }
                  },
                }}
                {...!!filterItem.searchFilter && {
                  filterOption: false,
                  onSearch: (searchKey) => {
                    if (!!filterItem.searchFilter && searchKey) {
                      setSearchFilterKey(searchKey.trim());
                      filterItem.searchFilter({
                        ...filterItem.dependent && {
                          [filterItem.dependent]: filterForm.getFieldValue(filterItem.dependent),
                        },
                        nameCont: searchKey.trim(),
                        page: 1,
                      });
                    }
                  },
                  onChange: (value) => {
                    if (!!filterItem.searchFilter && !value) {
                      filterItem.searchFilter({
                        ...filterItem.dependent && {
                          [filterItem.dependent]: filterForm.getFieldValue(filterItem.dependent),
                        },
                        nameCont: '',
                        page: 1,
                      });
                    }
                    setSearchFilterKey('');
                  },
                  onBlur: () => {
                    if (
                      !!filterItem.searchFilter
                      && !filterForm.getFieldValue(filterItem.fieldParams as string)
                    ) {
                      filterItem.searchFilter({
                        ...filterItem.dependent && {
                          [filterItem.dependent]: filterForm.getFieldValue(filterItem.dependent),
                        },
                        nameCont: '',
                        page: 1,
                      });
                    }
                    setSearchFilterKey('');
                  },
                  onPopupScroll: (e) => handleLoadMoreFilter(e, filterItem),
                }}
              >
                {filterItem.options.map(
                  (option) => <Select.Option key={option.id} value={option.id}>
                    <TooltipParagraph style={{ lineHeight: 'unset' }}>{option.name}</TooltipParagraph>
                  </Select.Option>)
                }
              </Select>
            </Form.Item>
          </div>
        );
      } else if (filterItem.type === 'multiSelect' && filterItem.options) {
        return (
          <div key={`filter-item-${filterIndex}`}>
            <Text margin="0 0 6px 0" sm>{filterItem.title}</Text>
            <Form.Item name={filterItem.fieldParams}>
              <Select
                mode="multiple"
                allowClear
                showArrow
                suffixIcon={<CaretDownOutlined />}
                {...!filterItem.searchFilter && { optionFilterProp: 'children' }}
                placeholder="選択してください"
                notFoundContent="データが存在しません。"
                disabled={disabled}
                tagRender={(props) => renderTagSelected(props)}
                filterOption={(input: any, option: any) => {
                  const optionTitle = option.children.props.children;
                  const filterValue = (input || '').trim();
                  return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                }}
                {...!!filterItem.getDependentData && {
                  onChange: (value) => {
                    if (
                      !!filterItem.getDependentData
                      && filterItem.fieldParams
                      && filterItem.dependent
                    ) {
                      filterForm.setFieldsValue({
                        [filterItem.dependent]: undefined,
                      });
                      filterItem.getDependentData({
                        [filterItem.fieldParams]: value,
                        nameCont: searchFilterKey,
                        page: 1,
                      });
                    }
                  },
                }}
                {...!!filterItem.searchFilter && {
                  filterOption: false,
                  onSearch: (searchKey) => {
                    if (!!filterItem.searchFilter && searchKey) {
                      setSearchFilterKey(searchKey.trim());
                      filterItem.searchFilter({
                        ...filterItem.dependent && {
                          [filterItem.dependent]: filterForm.getFieldValue(filterItem.dependent),
                        },
                        nameCont: searchKey.trim(),
                        page: 1,
                      });
                    }
                  },
                  onChange: (value) => {
                    if (!!filterItem.searchFilter && !value) {
                      filterItem.searchFilter({
                        ...filterItem.dependent && {
                          [filterItem.dependent]: filterForm.getFieldValue(filterItem.dependent),
                        },
                        nameCont: '',
                        page: 1,
                      });
                    }
                    setSearchFilterKey('');
                  },
                  onBlur: () => {
                    if (
                      !!filterItem.searchFilter
                      && !filterForm.getFieldValue(filterItem.fieldParams as string)
                    ) {
                      filterItem.searchFilter({
                        ...filterItem.dependent && {
                          [filterItem.dependent]: filterForm.getFieldValue(filterItem.dependent),
                        },
                        nameCont: '',
                        page: 1,
                      });
                    }
                    setSearchFilterKey('');
                  },
                  onPopupScroll: (e) => handleLoadMoreFilter(e, filterItem),
                }}
              >
                {filterItem.options.map(
                  (option) => <Select.Option key={option.id} value={option.id}>
                    <TooltipParagraph style={{ lineHeight: 'unset' }}>{option.name}</TooltipParagraph>
                  </Select.Option>)
                }
              </Select>
            </Form.Item>
          </div>
        );
      }
      else if (filterItem.type === 'inputNumber') {
        return (
          <div key={`filter-item-${filterIndex}`}>
            <Text margin="0 0 6px 0" sm>{filterItem.title}</Text>
            <Style.InputNumberWrapper >
            <Form.Item name="numberGteq">
              <InputNumber
                formatter={(value) => `${value}`.replace(FORMAT_NUMBER_REGEX, ',')}
                parser={(value) => `${value}`.replace(CHECK_NUMBER_REGEX, '')}
                disabled={disabled}
              />
            </Form.Item>
            <Style.DistanceMiddle>~</Style.DistanceMiddle>
            <Form.Item name="numberLteq">
              <InputNumber
                formatter={(value) => `${value}`.replace(FORMAT_NUMBER_REGEX, ',')}
                parser={(value) => `${value}`.replace(CHECK_NUMBER_REGEX, '')}
                disabled={disabled}
              />
            </Form.Item>
            </Style.InputNumberWrapper>
          </div>
        );
      } else {
        return (
          <div key={`filter-item-${filterIndex}`}>
            <Text margin="0 0 6px 0" sm>{filterItem.title}</Text>
            <Form.Item name={filterItem.fieldParams}>
              <Input disabled={disabled} onBlur={(event) => {
                const { value } = event.target;
                handleAutoTrimInput(value, filterItem); 
              }} onKeyPress={(event) => {
                if (event.key === "Enter") {
                  const { value } = event.target as HTMLInputElement;
                  handleAutoTrimInput(value, filterItem); 
                }   
              }} />
            </Form.Item>
          </div>
        );
      }
    });
  }

  return (
    <>
      <Style.SidebarContainer>
        <Text margin="0 0 10px 0" lg w6>フィルター</Text>
        <Form
          form={filterForm}
          name="filter"
          onFinish={(values) => handleSubmitFilter(values)}
          onFinishFailed={() => null}
        >
          {renderFilterField()}
          <Style.FilterSubmitButton>
            <Button type="primary" htmlType="submit" style={{ minWidth: 100 }} disabled={disabled}>絞り込む</Button>
          </Style.FilterSubmitButton>
        </Form>
      </Style.SidebarContainer>
    </>
  );
};

export { FilterSidebar };
