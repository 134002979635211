// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const CompanyDetailOverview = styled.div`
  margin: 16px 0 10px;
  height: 118px;

  & .ant-select-selector {
    padding-left: 0 !important;
    background-color: unset !important;
  }
  & .ant-select-disabled {
    background-color: ${(props) => props.theme.colors.secondaryBorder} !important;
  }
  & .ant-select-selection-item {
    color: #333333;
    font-size: 24px;
    font-weight: 600;
  }
  & .ant-select-selection-placeholder {
    font-size: 24px;
  }
  & .ant-select-selection-search{
    left: 0 !important;
  }
  & .ant-select-selection-search-input {
    font-size: 24px;
    font-weight: 600;
  }
`;

export const CompanyStatus = styled.div`
  display: flex;
  align-items: center;
  height: 38px;
`;
