// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const TestScoreChart = styled.div`
  position: relative;
  padding: 24px 24px 0 24px;
  height: 410px;
  background-color: white;
`;

export const InputChartField = styled.div`
  display: flex;
  justify-content: space-between;
`;
