// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';

export const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 56px;
  color: white;
  background-color: ${(props) => props.theme.colors.adminBackground};

  ${(props) => props.isStudent
    && css`
      background-color: #212121;
    `}
`;

export const FooterContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 0;
  margin-right: 32px;
  max-width: 1400px;
  width: 100%;

  @media (max-width: 428px) {
    justify-content: center;
    margin-right: 0;
  }
`;

export const FooterItem = styled.div`
  padding: 0 32px;
  border-left: 1px solid ${(props) => props.theme.colors.userBackground};
  cursor: pointer;

  &:hover {
    opacity: 0.75
  }

  &:first-child {
    border-left: none;
  }
`;

export const CopyrightContent = styled.div`
  position: absolute;
  right: 0;

  @media (max-width: 428px) {
    position: static;
  }
`;

export const FooterLinks = styled.div`
  display: flex;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const TextFooter = styled(Text)`
  display: flex;

  & > span {
      margin-right: 5px;
      font-size: 21px;
    }

  @media (max-width: 428px) {
    font-size: 14px;
  }
`;
