// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from "styled-components";

export const StudentTableContainer = styled.div`
  margin-top: 40px;
`;

export const StudentName = styled.div`
  position: relative;
`;

export const StudentStatus = styled.span`
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  color: ${(props) => props.theme.colors.error};
`;

export const ButtonExportCsvWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
`;
