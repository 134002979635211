// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';

export function getPlanList() {
  return {
    type: REQUEST(ADMIN_SERVICE.PLAN_ACTION.GET_LIST),
  };
}

export function createPlan(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.PLAN_ACTION.CREATE_PLAN),
    payload,
  };
}


export function updatePlan(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN),
    payload: params,
  };
}

export function deletePlan(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN),
    payload: params,
  };
}
