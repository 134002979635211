// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import {
  Row,
  Col,
  Space,
  Form,
  Input,
  Select,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import { formatDateTime } from 'src/utils/dateTime';
import { getFilterOption } from 'src/utils/common';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';

import { DATE_TIME_FORMAT } from 'src/constants/common';

interface ITipOverviewProps {
  tipId?: string;
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  tipDetail?: {
    data: {
      id: number;
      name: string;
      content: string;
      category: {
        id: number;
        name: string;
      };
      tags: {
        id: number;
        name: string;
      }[];
      lastUpdater: {
        jituName: string;
      };
      creator: {
        jituName: string;
      }
      updatedAt: string;
    };
  };
}

const TipOverview: React.FC<ITipOverviewProps> = ({
  tipId,
  tagList,
  categoryList,
  tipDetail,
}) => {
  function renderTagsOptions() {
    if (isEmpty(tagList.data)) return null;
    return tagList.data.map((tag) => (
      <Select.Option key={`tag-${tag.id}`} value={tag.id}>{tag.name}</Select.Option>
    ));
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  return (
    <>
      <Space align="center" size={32}>
        <Text headerText xxxl w6>{`${ tipId ? 'Tips修正' : 'Tips新規作成'}`}</Text>
        {tipId && (
          <Space align="center" size={24}>
            <Space align="center" size={24}>
              <Text secondaryText xs w6>更新者</Text>
              <TooltipParagraph
                style={{ maxWidth: 300, color: '#333333', fontWeight: '600' }}
              >
                {tipDetail?.data.lastUpdater?.jituName}
              </TooltipParagraph>
            </Space>
            <Space align="center" size={24}>
              <Text secondaryText xs w6>更新日</Text>
              <Text headerText w6>
                {formatDateTime(tipDetail?.data.updatedAt, DATE_TIME_FORMAT)}
              </Text>
            </Space>
          </Space>
        )}
      </Space>
      <Row gutter={30}>
        <Col span={12}>
          <Form.Item
            label="Tips名"
            name="name"
            labelAlign="left"
            labelCol={{ span: 4 }}
            colon={false}
            rules={[
              { required: true, message: 'Tips名は必須項目です。' },
              { whitespace: true, message: 'Tips名は必須項目です。' },
              { max: 50, message: 'Tips名が長すぎます。（最大は50桁です）' },
            ]}
            style={{ marginTop: 24 }}
          >
            <Input className="transparent-input" />
          </Form.Item>
          <Form.Item
            name="tagIds"
            label="Tags"
            labelAlign="left"
            labelCol={{ span: 4 }}
            colon={false}
          >
            <Select
              mode="multiple"
              optionFilterProp="children"
              notFoundContent="データが存在しません。"
              className="transparent-input"
              filterOption={getFilterOption}
            >
              {renderTagsOptions()}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="categoryId"
            label="講座タイプ"
            labelAlign="left"
            labelCol={{ span: 4 }}
            colon={false}
            style={{ marginTop: 24 }}
          >
            <Select
              allowClear
              showSearch
              suffixIcon={<CaretDownOutlined />}
              optionFilterProp="children"
              placeholder="選択してください"
              notFoundContent="データが存在しません。"
              className="transparent-input"
              filterOption={getFilterOption}
              style={{ width: '40%' }}
            >
              {renderCategoryOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            name="creator"
            label="作成者"
            labelAlign="left"
            labelCol={{ span: 4 }}
            colon={false}
          >
            <Input style={{ color: '#343A40' }} disabled />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default TipOverview;
