// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_COMPANY, REQUEST } from '../../constant';

export function getCompanyContractDetail(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function getCompanyContractStudents(params) {
  const newParams = {
    jituNameCont: (params.jituNameCont || '').trim(),
    jobTypeCont: (params.jobTypeCont || '').trim(),
    jobTitleCont: (params.jobTitleCont || '').trim(),
    departmentCont: (params.departmentCont || '').trim(),
    emailCont: (params.email || '').trim(),
    page: params.page,
    sort: params.sort,
    items: params?.items,
    roles: params.roles || ''
  };

  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_STUDENTS),
    payload: newParams,
  };
}

export function getCompanyContractCourses(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES),
    payload: params,
  };
}

export function getCompanyContractCustomInfos() {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS),
  };
}

export function updateCompanyContractCustomInfos(payload) {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS),
    payload,
  };
}

export function deleteCompanyContractCustomInfos(payload) {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS),
    payload,
  };
}

export function updateCompanyContractInfos(payload) {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_INFOS),
    payload,
  };
}

export function getCompanyCoursesStatus(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES_STATUS),
    payload: params,
  };
}

export function clearCompanyCoursesStatus() {
  return {
    type: REQUEST(ADMIN_COMPANY.COMPANY_ACTION.CLEAR_COMPANY_COURSES_STATUS),
  };
}
