// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { NOTIFICATION_ACTION, REQUEST } from '../constant';

export function getNotificationUnread() {
  return {
    type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD),
  };
}

export function getNotificationList(params) {
  return {
    type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_LIST),
    payload: params,
  };
}

export function getDropdownNotifications(payload) {
  return {
    type: REQUEST(NOTIFICATION_ACTION.GET_DROPDOWN_NOTIFICATIONS),
    payload,
  };
}
export function markNotificationsAsRead() {
  return { type: REQUEST(NOTIFICATION_ACTION.MARK_NOTIFICATIONS_AS_READ) };
}

export function checkNotificationAsRead(payload){
  return {
    type: REQUEST(NOTIFICATION_ACTION.CHECK_NOTIFICATION_AS_READ),
    payload,
  }
}
