// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import FormItem from 'antd/lib/form/FormItem';

export const QuestionDetailContent = styled.div`
  padding: 20px 20px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const ImageInputField = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

export const FieldInput = styled.input`
  display: none !important;
`;

export const QuestionContent = styled.div`
  background: #f5f5f5;
  margin: 6px 0 -38px;
  height: 254px;
`;

export const EditorErrors = styled.div`
  height: 25px;
`;

export const FormItemCustom = styled(FormItem)`
    margin-top: 38px;
    margin-bottom: 0;
    
  .ant-form-item-control-input {
    min-height: 0;
  }
`;
