// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class StudentTestServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  joinTest(params) {
    const { requestData, courseId } = params;
    return this.restClient.post(`/student/courses/${courseId}/join_tests`, requestData);
  }

  submitTestAnswers(params) {
    const { requestData, courseId } = params;
    return this.restClient.post(`/student/courses/${courseId}/users_answers`, requestData);
  }

  getFileDownloadURL(params) {
    return this.restClient.get(`/student/tests/${params.id}/download_explanation`);
  }

  getTestResults(params) {
    const { requestData, courseId } = params;
    return this.restClient.get(`/student/courses/${courseId}/tests_results`, requestData);
  }

  uploadFileAnswer(params) {
    const { courseId, requestData } = params;
    return this.restClient.post(`/student/courses/${courseId}/upload_answer_files`, requestData, {}, 'formData');
  }

  getDetailsQuestion(id) {
    return this.restClient.get(`student/questions/${id} `);
  }
}
