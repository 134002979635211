// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  InputNumber,
  Space,
} from 'antd';
import { isNumber } from 'lodash/lang';

import ModalFooter from 'src/components/Modal/components/ModalFooter';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { Text } from 'src/components/styles/Texts';

import {
  scoreUploadAnswer as scoreUploadAnswerAction,
} from 'src/redux/actions';

import docFileIcon from 'src/assets/images/common/doc-file-icon.svg';

import * as Style from './styles';

interface IUploadScoringModalPageProps extends DispatchProps {
  scoreUploadAnswer(params): void;
  modalData: {
    company: {
      id: number;
      contractName: string;
    };
    course: {
      id: number;
      courseName: string;
      courseCategory: {
        name: string;
      };
    };
    test: {
      id: number;
      testName: string;
    };
    question: {
      id: number;
      questionName: string;
      questionScore: number;
    };
    user: {
      id: number;
      jituName: string;
    };
    answerFile: {
      url: string;
    };
    usersAnswerId: number;
    score: number | null;
  };
}

const UploadScoringModal: React.FC<IUploadScoringModalPageProps> = ({
  scoreUploadAnswer,
  modalData,
}) => {

  const [uploadScoringForm] = Form.useForm();

  const uploadScoringInitialFormValues = {
    score: isNumber(modalData.score) ? modalData.score : undefined,
  };

  function handleSubmitForm(values) {
    scoreUploadAnswer({
      usersAnswer: {
        usersAnswerId: modalData.usersAnswerId,
        score: values.score,
      },
      isNotification: true
    });
  }

  return (
    <Form
      form={uploadScoringForm}
      name="uploadScoringForm"
      initialValues={uploadScoringInitialFormValues}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.UploadScoringContainer>
        <Style.UploadScoringInfo>
          <Text lg w6>契約名</Text>
          <TooltipParagraph isPreWrap rows={2}>
            {modalData.company.contractName}
          </TooltipParagraph>
        </Style.UploadScoringInfo>
        <Style.UploadScoringInfo>
          <Text lg w6>コース名</Text>
          <TooltipParagraph isPreWrap rows={2}>
            {modalData.course.courseCategory.name}
          </TooltipParagraph>
        </Style.UploadScoringInfo>
        <Style.UploadScoringInfo>
          <Text lg w6>テスト名</Text>
          <TooltipParagraph isPreWrap rows={2}>
            {modalData.test.testName}
          </TooltipParagraph>
        </Style.UploadScoringInfo>
        <Style.UploadScoringInfo>
          <Text lg w6>問名</Text>
          <TooltipParagraph isPreWrap rows={2}>
            {modalData.question.questionName}
          </TooltipParagraph>
        </Style.UploadScoringInfo>
        <Style.UploadScoringContent>
          <div>
            <Space>
              <TooltipParagraph
                style={{ fontSize: '22px', lineHeight: '30px', fontWeight: '600' }}
              >
                {modalData.user.jituName}
              </TooltipParagraph>
              <Text xxl w6>様</Text>
            </Space>
            <Form.Item>
              <Space>
                <Form.Item
                  name="score"
                  validateFirst
                  rules={[
                    { required: true, message: '点数は必須項目です。' },
                    () => ({
                      validator(_, value) {
                        if (value > modalData.question.questionScore) {
                          return Promise.reject(`点数は${modalData.question.questionScore}以下で入力してください。`);
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  noStyle
                  style={{ display: 'flex' }}
                >
                  <InputNumber
                    formatter={(value: any) => `${value}`.replace(/\D/g, '')}
                    min={0}
                    max={modalData.question.questionScore}
                    style={{ width: 104 }}
                  />
                </Form.Item>
                <Text lg w6 style={{ margin: '8px' }}>
                  {`/ ${modalData.question.questionScore}点`}
                </Text>
              </Space>
            </Form.Item>
          </div>
          <div>
            <Text>回答ダウンロード</Text>
            <Style.DownloadFileButton
              onClick={() => window.open(modalData.answerFile?.url, '_blank')}
            >
              <img src={docFileIcon} width="48" height="48" alt="" />
            </Style.DownloadFileButton>
          </div>
        </Style.UploadScoringContent>
      </Style.UploadScoringContainer>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  scoreUploadAnswer: (params) => dispatch(scoreUploadAnswerAction(params)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(UploadScoringModal);
