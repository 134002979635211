// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Tag } from 'antd';

export const TestResultContainer = styled.div`
  padding: 24px;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & .ant-table .ant-tag {
      padding: 3px 8px 0px 6px;
    }
  }
`;

export const TestResultOverview = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

export const TestResultDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TestResultScore = styled.div`
  display: flex;
  align-items: baseline;
`;

export const TestResultStatus = styled(Tag)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  height: 24px;
  font-size: 14px;
  width: 64px;
`;

export const DownloadExplanation = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const TestResultFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;
