// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { Button } from "antd";
import React from "react";
import doneIcon from "src/assets/images/modalImage/done-action.svg";
import * as Style from "./styles";
import { closeModal as closeModalAction } from "src/redux/actions";
import { connect } from "react-redux";
import { Text } from "src/components/styles";

interface IConfirmResetModalProps extends DispatchProps, StateToProps {
  closeModal: () => void;
  isOk?: boolean;
  isClose?: boolean;
  isDone?: boolean;
  isDoingUnlimitedTest?: boolean;
  modalData: {
    title: string;
    okeText: string;
    closeText?: string;
  };
  modalAction: {
    confirm: () => void;
  };
  isDisableRequest: boolean,
}

const ConfirmResetModal: React.FC<IConfirmResetModalProps> = ({
  modalData,
  modalAction,
  isClose,
  isOk,
  isDone,
  closeModal,
  isDisableRequest,
  isDoingUnlimitedTest,
}) => (
  <>
    <Style.ConfirmResetModalContainer>
      {isDoingUnlimitedTest && (
        <div>
          <Text w6>受講者が受験中の場合テストリセットはできません。</Text>
          <Text margin={"0 0 12px 0"} w6>受講者が受験中でないことを確認してからリセットをしてください。</Text>
        </div>
      )}
      {!isDoingUnlimitedTest && isDone && (
        <>
          <img src={doneIcon} height="83px" width="83px" alt="done-icon" />
          <Text margin={"32px 0 0 0"} w6>
            テスト受講履歴がリセットされました
          </Text>
        </>
      )}
      {!isDoingUnlimitedTest && !isDone && (
        <div>
          <Text w6>テスト受講履歴のリセットは取り消しができません。</Text>
          <Text margin={"0 0 12px 0"} w6>このテストをリセットしてもよろしいですか？</Text>
        </div>
      )}
    </Style.ConfirmResetModalContainer>
    <Style.ConfirmResetFooter>
      {isClose && (
        <Button
          onClick={() => {
            closeModal();
          }}
          type="primary"
          {...isDone || { className: "btn-secondary" }}
        >
          {modalData.closeText}
        </Button>
      )}

      {isOk && (
        <Button
          onClick={() => {
            modalAction.confirm();
          }}
          type="primary"
          disabled={isDisableRequest}
        >
          {modalData.okeText}
        </Button>
      )}
    </Style.ConfirmResetFooter>
  </>
);

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
});

const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

type StateToProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmResetModal);
