// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const AccountListContainer  = styled.div`
  display: flex;
  width: 100%;
`;

export const AccountListSidebar = styled.div`
  margin-right: 16px;
  min-width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

export const  AccountListContent = styled.div`
  margin: 0 auto;
  max-width: calc(100% - 316px);
  width: 100%;
`;

export const AccountListTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 8px 0 16px;
`;

export const TableAction = styled.div`
  & > button {
    width: 100px;
  }
`;

export const BanAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const AccountName = styled.div`
  position: relative;
  cursor: pointer;
`;

export const AccountStatus = styled.span`
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  color: ${(props) => props.theme.colors.error};
`;
