// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import AdminCompanyTop from './AdminCompanyTop';
import CourseDetail from './CourseDetail';
import CompanyDetail from './CompanyDetail';
import MasterSetting from './MasterSetting';
import ManagerAccount from './ManagerAccount';
import LicenseGrant from './LicenseGrant';
import ProfileDetail from './ProfileDetail';
import ConfirmAdminCompany from './ConfirmAdminCompany';
import ConfirmCompanyAccount from './ConfirmCompanyAccount';

export const CompanyPage = {
  AdminCompanyTop,
  CourseDetail,
  CompanyDetail,
  MasterSetting,
  ManagerAccount,
  LicenseGrant,
  ProfileDetail,
  ConfirmAdminCompany,
  ConfirmCompanyAccount,
};
