// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { closeModal as closeModalAction } from 'src/redux/actions';

import * as Style from './styles';

interface IModalContainerProps extends StateProps, DispatchProps {
  closeModal(): void;
  isShowModal: boolean;
  children: {
    props: {
      isConfirm: boolean;
      modalData: object;
      width: number;
    };
  };
}

const ModalContainer: React.FC<IModalContainerProps> = ({
  closeModal,
  isShowModal,
  children,
}) => {
  if (isShowModal) {
    const {
      isConfirm,
      isStudent,
      showCloseIcon,
      toggleClickOutside,
      modalData,
      width,
    } = children.props;
    return (
      <Modal
        key={`modal-${JSON.stringify(Math.random())}`}
        className={isStudent ? 'student-modal' : ''}
        closable={!!showCloseIcon}
        closeIcon={showCloseIcon && <CloseOutlined style={{ color: 'white' }} />}
        visible={isShowModal}
        width={isConfirm
          ? 420
          : (width ? width : 680)
        }
        title={modalData.headerAction
          ? (
            <Style.HeaderModal>
              <Style.TitleModal white lg w6>{modalData.title}</Style.TitleModal>
              {modalData.headerAction}
            </Style.HeaderModal>
          )
          : <Style.TitleModal white lg w6>{modalData.title}</Style.TitleModal>
        }
        footer={false}
        style={{ top: 40 }}
        {...!toggleClickOutside && { maskClosable: false }}
        {...(toggleClickOutside || showCloseIcon) && { onCancel: () => closeModal() }}
      >
        {children}
      </Modal>
    );
  }
  return null;
};

const mapStateToProps = (state) => {
  const { isShowModal, children } = state.modalReducer;
  return { isShowModal, children };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
