// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';

export const TopSidebar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 0 22px;
  height: 50px;

  @media only screen and (max-width: 428px) {
    .ant-progress-text {
      color: ${(props) => props.theme.colors.primaryText} !important;
      font-size: 12px;
    }
  }
`;

export const MenuButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  cursor: pointer;

  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const CollapseContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  width: 0;
  max-height: calc(100vh - 106px);
  overflow: auto;

  ${(props) => props.isShowSidebar
    && css`
      width: 100%;
    `}

  @media only screen and (max-width: 428px) {
    .ant-collapse-arrow {
      display: none !important;
    }

    .ant-collapse-header {
      height: unset;
    }
  }
`;

export const CollapseHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Fix onClick call API collapse header */
  margin: 0 -40px 0 -16px;
  padding: 0 40px 0 16px;
  width: calc(100% + 56px);
  height: 50px;

  @media only screen and (max-width: 428px) {
    padding: 16px;
    margin: -12px -40px -12px -16px;
    min-height: 50px;
    height: unset;
  }
`;

export const CollapseIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  display: flex;
  transform: translate(0, -50%);

  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const UnitItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 12px 0 16px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
  cursor: pointer;

  ${(props) => props.active
    && css`
      background-color: #FFF8E7;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 50px;
        background-color: ${props.theme.colors.primary};
      }
    `}
`;

export const QuestionItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 12px 0 70px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
  cursor: pointer;

  @media only screen and (max-width: 428px) {
    min-height: 50px;
    height: auto;
    padding: 12px 12px 12px 70px;
  }

  ${(props) => props.active
    && css`
      background-color: #FFF8E7;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 50px;
        background-color: ${props.theme.colors.primary};
      }
    `}
`;

export const UnitTitle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;

  ${(props) => props.unitType === 'video'
    && css`
      margin-right: 84px;
    `}

  ${(props) => props.unitType === 'test'
    && css`
      margin-right: 54px;
    `}

  & > img {
    min-width: 24px;
  }

  @media only screen and (max-width: 428px) {
    font-weight: 700 !important;
  }
`;

export const NoUnitItemData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};
`;

export const NoChapterData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: #FAFAFA;
`;

export const HeaderWrapper = styled.div`
  display: none;

  @media only screen and (max-width: 428px) {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 50px;
    background-color: #C08D01;
    color: white;
  }
`;

export const TotalUnitsAndTimeLabel = styled(Text)`
  @media only screen and (max-width: 428px) {
    font-size: 12px !important;
  }
`

export const CellTable = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 16px;

  &:nth-child(2) {
    justify-content: flex-end;
  }
`

export const CellTableHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 16px;

  &:nth-child(2) {
    justify-content: flex-end;
  }

  p {
    font-size: 13px;
  }
`
