// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_COMPANY, REQUEST } from '../../constant';
import moment from 'moment';

export function getCompanyConfirmationStatus(payload) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS),
    payload,
  };
}

export function companyConfirmAccount(payload) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM),
    payload,
  };
}

export function confirmAdminCompany(payload) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CONFIRM_COMPANY),
    payload,
  };
}

export function getStudents(params) {
  const newParams = {
    emailCont: (params.requestData?.email || '').trim(),
    jituNameCont: (params.requestData?.name || '').trim(),
    page: params.requestData.page || '',
    items: params?.requestData.items,
    courseId: params.requestData.courseId,
  };
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_STUDENTS),
    payload: newParams,
  };
}

export function clearStudents() {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CLEAR_STUDENTS),
  };
}

export function companyAddStudentsToCourse(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE),
    payload: params,
  };
}

export function companyInviteStudents(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_STUDENTS),
    payload: params,
  };
}

export function companyInviteAdminCompany(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY),
    payload: params,
  };
}

export function getCompanyInvitationList(params) {
  const newParams = {
    confirmationSentAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    confirmationSentAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    roleEq: params.type || '',
    emailCont: (params.email || '').trim(),
    page: params.page || '',
    sort: params.sort,
    items: params?.items,
    actualRegisterStatus: params?.actualRegisterStatus,
  };
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.GET_INVITATION_LIST),
    payload: newParams,
  };
}

export function companyResendInvitations(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.RESEND_INVITATION),
    payload: params,
  };
}

export function companyCancelInvitations(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.CANCEL_INVITATION),
    payload: params,
  };
}

export function companyAddStudentsToCourseByCsv(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV),
    payload: params,
  };
}

export function companyBulkResendInvitations(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS),
    payload: params,
  };
}

export function companyBulkCancelInvitations(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS),
    payload: params,
  };
}
