//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Select,
  Tabs,
  Space,
} from 'antd';
import { isEmpty } from 'lodash/lang';
import { CaretDownOutlined } from '@ant-design/icons';

import history from 'src/utils/history';

import { getFilterOption } from 'src/utils/common';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import CompanyInformation from './components/CompanyInformation';
import CompanyCourses from './components/CompanyCourses';
import CompanyStudents from './components/CompanyStudents';
import SelectCompanyStatusModal from './components/SelectCompanyStatusModal';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { COMPANY_STATUS } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getCompanyContractList as getCompanyContractListAction,
  getCompanyDetail as getCompanyDetailAction,
  getCompanyStudents as getCompanyStudentsAction,
  getCompanyCourses as getCompanyCoursesAction,
  getProvinceList as getProvinceListAction,
  getCourseDetail as getCourseDetailAction,
  updateCompanyInfos as updateCompanyInfosAction,
  clearCompanyData as clearCompanyDataAction,
  banCompanyStudent as banCompanyStudentAction,
  showModal as showModalAction,
  closeModal as closeModalAction
} from 'src/redux/actions';

import * as Style from './styles';

interface ICompanyDetailPageProps extends StateProps, DispatchProps {
  getCompanyContractList(): void;
  getCompanyDetail(params): void;
  getCompanyStudents(params): void;
  getCompanyCourses(params): void;
  getProvinceList(): void;
  getCourseDetail(params): void;
  updateCompanyInfos(params): void;
  clearCompanyData(): void;
  showModal(params: any): void;
  banCompanyStudent(params): void;
  closeModal(): void;
  companyContracts: {
    data: {
      id: number;
      contractName: string;
    }[];
  };
  companyDetail: {
    data: {
      id: number
      name: string;
      status: string;
      addressBuilding: string;
      addressCity: string;
      adminCompany: {
        id: number;
        jituName: string;
        department: string;
        phoneNumber: string;
        email: string;
      };
      contractName: string;
      departmentInCharge: string;
      fax: string;
      phoneNumber: string;
      postalCode: string;
      province: {
        id: number,
        name: string;
      };
      registerDate: string;
      licensesCount: number;
      remainingLicensesCount: number;
      sfa: string;
      customDisplayField: {
        department: boolean;
      };
    };
  };
  companyCourses: {
    data: {
      id: number;
      plan: {
        id: number;
        name: string;
      };
      courseCategory: {
        name: string;
      };
      name: string;
      totalLearningTime: number;
      usedLicensesPass: number;
      companyProgressPercentage: number;
      licensesCount: number;
      remainingLicensesCount: number;
      startDate: string;
      endDate: string;
      remainingDays: number;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  companyStudents: {
    data: {
      studentList: {
        id: number;
        jituName: string;
        numberOfCourses: number;
        status: string;
        courses: {
          courseCategory: {
            name: string;
          };
          name: string;
        }[];
        department: string;
        jobType: string;
        jobTitle: string;
        email: string,
        roles: string[],
        customUserInfos: {
          companyCustomUserInfoId: number;
          fieldValue: string;
        }[];
        lastLearningDate: number;
        totalLearningTime: number;
        entireCoursesHighestTestScore: number;
        entireCoursesPercentage: number;
      }[];
      companyCustomInfos: {
        id: number;
        fieldName: string;
        isDisplay: boolean;
      }[];
      customDisplayField: {
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  provinceList: {
    data: {
      id: number;
      name: string;
    }[];
  };
}

const CompanyDetailPage: React.FC<ICompanyDetailPageProps> = ({
  getCompanyContractList,
  getCompanyDetail,
  getCompanyStudents,
  getCompanyCourses,
  getProvinceList,
  getCourseDetail,
  updateCompanyInfos,
  clearCompanyData,
  showModal,
  banCompanyStudent,
  companyContracts,
  companyDetail,
  companyCourses,
  companyStudents,
  provinceList,
  closeModal
}) => {
  const activeTabKeyUrl = history.location.state?.activeTabKey;
  const urlSelectCompanyId = history.location.state?.companyContractId;
  const [companyIdSelected, setCompanyIdSelected] = useState<number | undefined>(urlSelectCompanyId);
  const [activeTabKey, setActiveTabKey] = useState<string>(activeTabKeyUrl || '1');
  
  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.CompanyDetail);

    getCompanyContractList();
    getProvinceList();
    setActiveTabKey(activeTabKeyUrl || '1')
    setCompanyIdSelected(urlSelectCompanyId)

    window.addEventListener("beforeunload", function () {
      history.replace({
        state: {
          activeTabKey: 1,
          companyContractId: companyIdSelected
        }}
      )
    })

    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    if (urlSelectCompanyId && !isEmpty(companyContracts.data) && !companyIdSelected) {
      setCompanyIdSelected(parseFloat(urlSelectCompanyId));
    }
  }, [urlSelectCompanyId, companyContracts.data, companyIdSelected]);

  useEffect(() => {
    if (!companyIdSelected) {
      clearCompanyData();
    }
  }, [companyIdSelected]);

  function handleSelectCompanyStatusModal() {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: 'ステータス変更',
        companyId: companyIdSelected,
        companyStatus: companyDetail.data.status,
      },
    };
    return showModal(<SelectCompanyStatusModal {...modalProps} />);
  }

  function renderCompanyOptions() {
    if (isEmpty(companyContracts.data)) return null;
    return companyContracts.data.map((company) => (
      <Select.Option key={`company-${company.id}`} value={company.id}>
        <TooltipParagraph
          style={{
            lineHeight: 'unset',
            fontSize: 24,
          }}
        >
          {company.contractName}
        </TooltipParagraph>
      </Select.Option>
    ));
  }
  return (
    <MainContainer isTabs>
      <MainContent fullView>
        <Style.CompanyDetailOverview>
          <Select
            bordered={false}
            showSearch
            suffixIcon={<CaretDownOutlined />}
            optionFilterProp="children"
            placeholder="選択してください"
            notFoundContent="データが存在しません。"
            value={companyIdSelected}
            onChange={(value) => {
              history.replace({
                state: {
                  companyContractId: value,
                  activeTabKey
                }
              })
              setCompanyIdSelected(value)
            }}
            style={{ marginBottom: 8, width: 450 }}
            dropdownClassName="container-dropdown-select"
            filterOption={(input: any, option: any) => {
              const optionTitle = option.children.props.children;
              const filterValue = (input || '').trim();
              return optionTitle.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
            }}
          >
            {renderCompanyOptions()}
          </Select>
          <Style.CompanyStatus>
            <Space align="center" size="middle">
              <Text secondaryText xs w6>ステータス</Text>
              {!isEmpty(companyDetail.data)
                ? (
                  <>
                    <Text headerText w6>{COMPANY_STATUS[companyDetail.data.status]}</Text>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => handleSelectCompanyStatusModal()}
                    >
                      ステータス変更
                    </Button>
                  </>
                )
                : <Text headerText w6>ー</Text>
              }
            </Space>
          </Style.CompanyStatus>
        </Style.CompanyDetailOverview>
        <Tabs
          defaultActiveKey={activeTabKey}
          type="card"
          style={{ marginBottom: 60 }}
          onChange={(activeKey) => {
            history.replace({
              state: {
                activeTabKey: activeKey,
                urlSelectCompanyId: companyIdSelected
              }
            })
            setActiveTabKey(activeKey)
          }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>基本情報</Text>}
            key="1"
          >
            <CompanyInformation
              companyIdSelected={companyIdSelected}
              companyDetail={companyDetail}
              provinceList={provinceList}
              getCompanyDetail={getCompanyDetail}
              updateCompanyInfos={updateCompanyInfos}
              activeTabKey={activeTabKey}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>コース</Text>}
            key="2"
          >
            <CompanyCourses
              companyIdSelected={companyIdSelected}
              companyCourses={companyCourses}
              getCompanyCourses={getCompanyCourses}
              activeTabKey={activeTabKey}
              showModal={showModal}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>ユーザー</Text>}
            key="3"
          >
            <CompanyStudents
              companyIdSelected={companyIdSelected}
              companyDetail={companyDetail}
              companyStudents={companyStudents}
              getCompanyStudents={getCompanyStudents}
              getCourseDetail={getCourseDetail}
              showModal={showModal}
              banCompanyStudent={banCompanyStudent}
              activeTabKey={activeTabKey}
            />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const {
    companyContracts,
    companyDetail,
    companyStudents,
    companyCourses,
  } = state.companyReducer;
  const { provinceList } = state.masterDataReducer;
  return {
    companyContracts,
    companyDetail,
    companyStudents,
    companyCourses,
    provinceList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  getCompanyDetail: (params) => dispatch(getCompanyDetailAction(params)),
  getCompanyStudents: (params) => dispatch(getCompanyStudentsAction(params)),
  getCompanyCourses: (params) => dispatch(getCompanyCoursesAction(params)),
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
  getProvinceList: () => dispatch(getProvinceListAction()),
  updateCompanyInfos: (params) => dispatch(updateCompanyInfosAction(params)),
  clearCompanyData: () => dispatch(clearCompanyDataAction()),
  showModal: (params) => dispatch(showModalAction(params)),
  banCompanyStudent: (params) => dispatch(banCompanyStudentAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailPage);
