// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Button } from 'antd';
import { Text } from 'src/components/styles';

export const CourseItemsContainer = styled.div`
  margin-bottom: 40px;
`;

export const CollapseHeader = styled.div`
  display: flex;
  width: 100%;
`;

export const CollapseItemContainer = styled.div`
  display: flex;
  background-color: white;
  height: 50px;
  padding: 0 40px 0 16px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const CollapseItemContent = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify || 'flex-start'};
  align-items: center;
  width: ${(props) => props.width || 'auto'};

  & > button {
    min-width: 76px;
  }
`;

export const NoHistoryData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: #FAFAFA;
`;

export const SurveyList = styled.div`
  display: block;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const SurveyListMobile = styled.div`
  display: none;

  @media (max-width: 428px) {
    display: block;
  }
`;

export const TextHeader = styled(Text)`
  @media (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
  }
`;

export const ButtonSurvey = styled(Button)`
  height: unset;
  margin: 0 16px 16px 0;
  font-size: 16px;
  font-weight: 700;
  white-space: unset;
  text-align: left;

  & > span {
    display: initial;
  }
`;
