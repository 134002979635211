// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Tag } from 'antd'

export const QuestionDetailContainer = styled.div`
  margin-top: 24px;
`;

export const QuestionDetailContent = styled.div`
  margin-right: 16px;
  width: calc(100% - 300px);
  min-width: 898px;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  overflow: hidden;
`;

export const QuestionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const QuestionContent = styled.div`
  padding: 16px;
  background-color: white;
  overflow: hidden;

  & p {
    word-break: break-word;
  }
  
  & img{
    max-width: 100%;
  }
`;

export const QuestionStatus = styled(Tag)`
  display: flex;
  justify-content: center;
  width: 70px;
`;

export const AnswerContainer = styled.div`
  margin-top: 16px;
  width: calc(100% - 300px);
  min-width: 898px;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
`;

export const AnswerTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const AnswerContent = styled.div`
  padding: 16px;
`;

export const AnswerAction = styled.div`
  display: flex;
  justify-content: ${(props) => props.isFirstQuestion ? 'flex-end' : 'space-between'};
  margin-top: 16px;

  & > button {
    width: 100px;
  }
`;

export const ConfirmAnswerAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;

  & > button {
    width: 100px;
  }
`;

export const QuestionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const QuestionImageContent = styled.img`
  max-width: 100%;
  max-height: 500px;
`;

export const AnswerOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${(props) => props.isRight
    && css`
      justify-content: space-between;
    `}
`;

export const FieldInput = styled.input`
  display: none !important;
`;

export const FileInputField = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
`;

export const UploadField = styled.div`
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  border: 1px dashed ${(props) => props.isDrag ? '#2196f3' : 'gray'};
  justify-content: space-between;
`;

export const GroupAction = styled.div`
  display: flex;
`;
