// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_COMPANY,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';
import { findIndex } from 'lodash/array';

const initialState = {
  companyDetail: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
  companyStudents: {
    data: {
      studentList: [],
      companyCustomInfos: [],
      customDisplayField: {},
    },
    meta: {},
    load: false,
    errors: [],
  },
  companyCourses: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  companyCoursesStatus: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  companyCustomInfos: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
  companyCustomDisplay: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
};

export type CompanyContractReducer = Readonly<typeof initialState>;

export default (state: CompanyContractReducer = initialState, action: any): CompanyContractReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL): {
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyDetail: {
        data: data.company,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_STUDENTS): {
    return {
      ...state,
      companyStudents: {
        ...state.companyStudents,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_STUDENTS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyStudents: {
        data: {
          studentList: data.users,
          companyCustomInfos: data.companyCustomUserInfos,
          customDisplayField: data.company.customDisplayField,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_STUDENTS): {
    const { errors } = action;
    return {
      ...state,
      companyStudents: {
        ...state.companyStudents,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES): {
    return {
      ...state,
      companyCourses: {
        ...state.companyCourses,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyCourses: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES): {
    const { errors } = action;
    return {
      ...state,
      companyCourses: {
        ...state.companyCourses,
        load: false,
        errors,
      },
    };
  }
  case REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES_STATUS): {
    return {
      ...state,
      companyCoursesStatus: {
        ...state.companyCoursesStatus,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES_STATUS): {
    const { data, meta } = action.payload
    return {
      ...state,
      companyCoursesStatus: {
        data: [...state.companyCoursesStatus.data, ...data.courses] as [],
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES_STATUS): {
    const { errors } = action;
    return {
      ...state,
      companyCoursesStatus: {
        ...state.companyCourses,
        load: false,
        errors,
      },
    };
  }
  case REQUEST(ADMIN_COMPANY.COMPANY_ACTION.CLEAR_COMPANY_COURSES_STATUS): {
    return {
      ...state,
      companyCoursesStatus: {
        ...state.companyCourses,
        data: [],
        meta: {}
      },
    };
  }
  case REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS): {
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: true,
      },
      companyCustomDisplay: {
        ...state.companyCustomDisplay,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyCustomInfos: {
        data: data.companyCustomUserInfos,
        meta,
        load: false,
        errors: [],
      },
      companyCustomDisplay: {
        data: data.company.customDisplayField,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS): {
    const { errors } = action;
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: false,
        errors,
      },
      companyCustomDisplay: {
        ...state.companyCustomDisplay,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      companyCustomInfos: {
        data: data.companyCustomUserInfos,
        meta,
        load: false,
        errors: [],
      },
      companyCustomDisplay: {
        data: data.company.customDisplayField,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS): {
    const { errors } = action;
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: false,
        errors,
      },
      companyCustomDisplay: {
        ...state.companyCustomDisplay,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS): {
    const { data } = action.payload;
    const newCompanyCustomInfos = state.companyCustomInfos.data;
    const customInfoIndex = findIndex(newCompanyCustomInfos, { id: data.id });
    newCompanyCustomInfos.splice(customInfoIndex, 1);
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        data: newCompanyCustomInfos,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_COMPANY.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS): {
    const { errors } = action;
    return {
      ...state,
      companyCustomInfos: {
        ...state.companyCustomInfos,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_INFOS): {
    const { data } = action.payload;
    return {
      ...state,
      companyDetail: {
        ...state.companyDetail,
        data: data.company,
      },
    };
  }
  default:
    return state;
  }
};
