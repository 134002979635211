// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import { ADMIN_SERVICE, SUCCESS, FAILURE, REQUEST } from '../../constant';

import { serviceStatisticServices  } from 'src/services';


function* getCurrentWeekSaga() {
  try {
    const result = yield serviceStatisticServices.getCurrentWeek();
    yield put({
      type: SUCCESS(ADMIN_SERVICE.STATISTIC_ACTION.GET_CURRENT_WEEK),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.STATISTIC_ACTION.GET_CURRENT_WEEK), errors: e.errors });
  }
}
function* getStatisticCoursesSaga() {
  try {
    const result = yield serviceStatisticServices.getStatisticCourses();
    yield put({
      type: SUCCESS(ADMIN_SERVICE.STATISTIC_ACTION.GET_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.STATISTIC_ACTION.GET_COURSES), errors: e.errors });
  }
}
function* getRecentCoursesSaga() {
  try {
    const result = yield serviceStatisticServices.getRecentCourses();
    yield put({
      type: SUCCESS(ADMIN_SERVICE.STATISTIC_ACTION.GET_RECENT_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.STATISTIC_ACTION.GET_RECENT_COURSES), errors: e.errors });
  }
}

export default function* serviceStatisticSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_CURRENT_WEEK), getCurrentWeekSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_COURSES), getStatisticCoursesSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.STATISTIC_ACTION.GET_RECENT_COURSES), getRecentCoursesSaga),
  ]);
}
