// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';
import moment from 'moment';

export function getCompanyList(params) {
  const newParams = {
    contractEndDateGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    contractEndDateLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    contractNameCont: (params.name || '').trim(),
    page: params?.page,
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getCompanyDetail(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_DETAIL),
    payload,
  };
}

export function getCompanyContractList() {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CONTRACTS),
  };
}

export function selectCompanyStatus(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.SELECT_COMPANY_STATUS),
    payload,
  };
}

export function getCompanyStudents(params) {
  const newParams = {
    ...params,
    requestData: {
      jituNameCont: (params.requestData.jituNameCont || '').trim(),
      jobTypeCont: (params.requestData.jobTypeCont || '').trim(),
      jobTitleCont: (params.requestData.jobTitleCont || '').trim(),
      departmentCont: (params.requestData.departmentCont || '').trim(),
      emailCont: (params.requestData.email || '').trim(),
      page: params.requestData.page,
      sort: params.requestData.sort,
      items: params.requestData?.items,
      roles: params.requestData.roles || '',
    },
  };
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_STUDENTS),
    payload: newParams,
  };
}

export function getCompanyCourses(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_COURSES),
    payload,
  };
}

export function clearCompanyData() {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.CLEAR_COMPANY_DATA),
  };
}

export function getCompanyCustomInfos(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS),
    payload,
  };
}

export function updateCompanyCustomInfos(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS),
    payload,
  };
}

export function deleteCompanyCustomInfos(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS),
    payload,
  };
}

export function updateCompanyInfos(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.UPDATE_COMPANY_INFOS),
    payload,
  };
}

export function banCompanyStudent(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.COMPANY_ACTION.BAN_COMPANY_STUDENT),
    payload,
  };
}
