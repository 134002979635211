// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';

import {
  STUDENT,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

import { studentUnitServices } from 'src/services';
import { showAlertNotice } from 'src/utils/alert';

function* getUnitDetailSaga(action) {
  try {
    const result = yield studentUnitServices.getUnitDetail(action.payload);
    yield put({
      type: SUCCESS(STUDENT.UNIT_ACTION.GET_DETAIL),
      payload: result,
    });
    if (result.data.unitVersion.item.type === 'tip') {
      yield put({
        type: SUCCESS(STUDENT.COURSE_ACTION.UPDATE_COURSE_PROGRESS),
        payload: {
          meta: result.meta,
          data: action.payload,
        },
      });
    }
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(STUDENT.UNIT_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

export default function* studentUnitSaga() {
  yield all([
    takeLatest(REQUEST(STUDENT.UNIT_ACTION.GET_DETAIL), getUnitDetailSaga),
  ]);
}
