// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const NotFoundType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  height: 184px;
`;

export const NotFoundTitle = styled.div`
  margin-bottom: 46px;
  border-bottom: 1px solid ${(props) => props.theme.colors.primaryBorder};
`;

export const NotFoundContent = styled.div`
  max-width: 646px;
  margin: 60px auto 0;
`;

export const Link = styled.a`
  max-height: 18px;
  font-size: 18px;
  color: #0DA2FE;

  &:hover {
    color: #0DA2FE;
    opacity: 0.75;
  }
`;
