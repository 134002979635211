// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
const AUTH_KEY = 'LMS-authData';
const VIDEO_PLAYER_KEY = 'LMS-videoSetting';
const SESSION_KEY = 'LMS-session';
const QUESTION_KEY = 'LMS-question';
const SERVER_TIME_KEY = "LMS-serverTime"

export const LoginStorage = {
  getData() {
    const localStorageData = JSON.parse(localStorage.getItem(AUTH_KEY) || 'null');
    const sessionStorageData = JSON.parse(sessionStorage.getItem(AUTH_KEY) || 'null');
    const storageData = localStorageData || sessionStorageData;
    if (!storageData) return null;
    return storageData;
  },
  setData(res: any, remember?: any) {
    // [Update Late] remember login
    localStorage.setItem(AUTH_KEY, JSON.stringify(res));
  },
  clearData() {
    localStorage.removeItem(AUTH_KEY);
    sessionStorage.removeItem(AUTH_KEY);
  },
};

export const PlayerStorage = {
  getData() {
    const localStorageData = JSON.parse(localStorage.getItem(VIDEO_PLAYER_KEY) || 'null');
    return localStorageData;
  },
  setData(res: any) {
    localStorage.setItem(VIDEO_PLAYER_KEY, JSON.stringify(res));
  },
  clearData() {
    localStorage.removeItem(VIDEO_PLAYER_KEY);
  },
};

export const SessionStorage = {
  getData() {
    const localStorageData = JSON.parse(localStorage.getItem(SESSION_KEY) || 'null');
    return localStorageData;
  },
  setData(res: any) {
    localStorage.setItem(SESSION_KEY, JSON.stringify(res));
  },
  clearData() {
    localStorage.removeItem(SESSION_KEY);
  },
};

export const QuestionStorage = {
  getData() {
    const localStorageData = JSON.parse(localStorage.getItem(QUESTION_KEY) || 'null');
    return localStorageData;
  },
  setData(res: any) {
    localStorage.setItem(QUESTION_KEY, JSON.stringify(res));
  },
  clearData() {
    localStorage.removeItem(QUESTION_KEY);
  },
};

export const ServerTimeStorage = {
  getData() {
    const localStorageData = JSON.parse(localStorage.getItem(SERVER_TIME_KEY) || 'null');
    return localStorageData;
  },
  setData(res: any) {
    localStorage.setItem(SERVER_TIME_KEY, JSON.stringify(res));
  },
  clearData() {
    localStorage.removeItem(SERVER_TIME_KEY);
  },
};
