// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  MainContainer,
  MainContent,
} from 'src/components/layouts/ContentLayout';

import TopOverview from './components/TopOverview';
import CourseList from './components/CourseList';
import StudentList from './components/StudentList';

import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import {
  getCurrentTime as getCurrentTimeAction,
  getCompanyStatisticCourses as getCompanyStatisticCoursesAction,
  getCompanyStatisticStudents as getCompanyStatisticStudentsAction,
  getCompanyCourseDetail as getCompanyCourseDetailAction,
  getCompanyCoursesStatus as getCompanyCoursesStatusAction,
  clearCompanyCoursesStatus as clearCompanyCoursesStatusAction
} from 'src/redux/actions';

interface IAdminCompanyTopPageProps extends StateProps, DispatchProps {
  getCurrentTime(): void;
  getCompanyStatisticCourses(): void;
  getCompanyStatisticStudents(): void;
  getCompanyCourseDetail(params): void;
  getCompanyCoursesStatus(params): void;
  clearCompanyCoursesStatus(): void;
  currentTime: {
    data: string;
  };
  statisticCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      };
      totalLearningTime: number;
      usedLicensesPass: number;
      companyProgressPercentage: number;
      progressGap: number;
      usedLicensesCount: number;
      remainingLicensesCount: number;
      remainingDays: number;
      startDate: string;
      endDate: string;
    }[];
    load: boolean;
  };
  statisticStudents: {
    data: {
      studentList: {
        id: number;
        jituName: string;
        company: {
          contractName: string;
        };
        department: string;
        jobType: string;
        jobTitle: string;
        customUserInfos: {
          companyCustomUserInfoId: number;
          fieldValue: string;
        }[];
        numberOfCourses: number;
        courses: {
          courseCategory: {
            name: string;
          };
          name: string;
        }[];
        lastLearningDate: string;
        registerDate: string
        totalLearningTime: number;
        entireCoursesHighestTestScore: string;
      }[];
      companyCustomInfos: {
        id: number;
        fieldName: string;
        isDisplay: boolean;
      }[];
      customDisplayField: {
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    load: boolean;
  };
  companyCoursesStatus: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        id: number;
        name: string;
      };
      recommendedSetting: boolean;
      periodStatus: number;
      behindSchedulePercentage: string;
      onSchedulePercentage: string;
      remainingDays: number;
    }[],
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
        next: number;
      };
    };
    load: boolean;
  };
}

const AdminCompanyTopPage: React.FC<IAdminCompanyTopPageProps> = ({
  getCurrentTime,
  getCompanyStatisticCourses,
  getCompanyStatisticStudents,
  getCompanyCourseDetail,
  getCompanyCoursesStatus,
  clearCompanyCoursesStatus,
  currentTime,
  statisticCourses,
  statisticStudents,
  companyCoursesStatus,
}) => {
  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.AdminCompanyTop);

    getCurrentTime();
    getCompanyStatisticCourses();
    getCompanyStatisticStudents();
    getCompanyCoursesStatus({});

    return () => {
      clearCompanyCoursesStatus();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer>
      <MainContent fullView>
        <TopOverview
          currentTime={currentTime}
          companyCoursesStatus={companyCoursesStatus}
          getCompanyCoursesStatus={getCompanyCoursesStatus}
        />
        <CourseList
          statisticCourses={statisticCourses}
          getCompanyCourseDetail={getCompanyCourseDetail}
        />
        <StudentList statisticStudents={statisticStudents} />
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { currentTime } = state.masterDataReducer;
  const {
    statisticCourses,
    statisticStudents,
  } = state.companyStatisticReducer;
  const { companyCoursesStatus } = state.companyContractReducer;
  return {
    currentTime,
    statisticCourses,
    statisticStudents,
    companyCoursesStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCurrentTime: () => dispatch(getCurrentTimeAction()),
  getCompanyStatisticCourses: () => dispatch(getCompanyStatisticCoursesAction()),
  getCompanyStatisticStudents: () => dispatch(getCompanyStatisticStudentsAction()),
  getCompanyCourseDetail: (params) => dispatch(getCompanyCourseDetailAction(params)),
  getCompanyCoursesStatus: (params) => dispatch(getCompanyCoursesStatusAction(params)),
  clearCompanyCoursesStatus: () => dispatch(clearCompanyCoursesStatusAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminCompanyTopPage);
