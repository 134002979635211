// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import moveLeftIcon from 'src/assets/images/videoPlayer/move-left-icon.svg';
import moveRightIcon from 'src/assets/images/videoPlayer/move-right-icon.svg';
import { Button } from 'antd';
import { Text } from 'src/components/styles';

export const VideoDetailContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

export const VideoDetailContent = styled.div`
  margin-right: 16px;
  width: 65%;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const VideoDetailTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const VideoPlayerContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  width: 100%;
`;

export const VideoPlayerContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
`;

export const VideoDescription = styled.div`
  padding: 24px;
`;

export const VideoTeacher = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  height: 36px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 40px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

export const VideoActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export const FAQContent = styled.div`
  margin: 24px 0 48px 0;
  height: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  display: none;
  overflow: hidden;
  order: 3;

  @media only screen and (max-width: 428px) {
    display: block;

    .custom-question-collapse > .ant-collapse-header {
      height: auto !important;
      min-height: 50px;
    }
  }
`;

export const FAQTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
  padding: 0 16px;

  img {
    cursor: pointer;
    width: 24px;
  }
`;

export const MoveLeftIcon = styled.div`
  width: 20px;
  height: 24px;
  background: url(${moveLeftIcon});
  background-repeat: no-repeat;
  background-size: 20px;
`;

export const MoveRightIcon = styled.div`
  width: 20px;
  height: 24px;
  background: url(${moveRightIcon});
  background-repeat: no-repeat;
  background-size: 20px;
`;

export const CollapseContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const AnswerContainer = styled.div`
  padding: 16px;
  background-color: white;
`;

export const AnswerContent = styled.div`
  margin-top: 16px;
  padding: 16px;
  background-color: #FFFCF2;
`;

export const ShowMoreAction = styled.div`
  margin-top: 16px;
  padding: 0 16px;
`;

export const FAQFormContainer = styled.div`
  padding: 16px;
`;

export const FAQFormTitle = styled.div`
  display: flex;
  align-content: center;
  margin-bottom: 8px;
`;

export const FAQSubmitButton = styled.div`
  display: flex;
  justify-content: flex-end;
  & > button {
    width: 100px;
  }
`;

export const ButtonBack = styled(Button)`
  @media only screen and (max-width: 428px) {
    font-size: 16px;
    font-weight: 700;
    width: 100px;
    height: 44px;
    border: 1px solid ${(props) => props.theme.colors.primary};

    &:hover,
    &:active,
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.primary};
      color: #dca40b;
      opacity: 1;
    }

    &:active {
      opacity: 0.5;
      border: none;
    }
  }
`

export const CourseNameTitle = styled(Text)`
  @media only screen and (max-width: 428px) {
    padding: 16px;
    font-size: 20px;
    font-weight: 700 !important;
  }
`
