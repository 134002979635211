// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Table, Tooltip } from 'antd';
import moment from 'moment';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import history from 'src/utils/history';

import { DATE_FORMAT } from 'src/constants/common';

import * as Style from './styles';

interface IRecentCoursesProps {
  recentCourses: {
    data: {
      course: {
        id: number;
        name: string;
        courseCategory: {
          name: string;
        };
      };
      licensesCount: number;
      usedLicensesCount: number;
      remainingLicensesCount: number;
      company: {
        id: number;
        contractName: string;
      }
      startDate: string;
      endDate: string;
    }[];
    load: boolean;
  };
}

const RecentCourses: React.FC<IRecentCoursesProps> = ({
  recentCourses,
}) => {
  const tableData = recentCourses.data.map((row: any) => ({
    key: row.course.id,
    id: row.course.id,
    courseName: row.course.courseCategory.name,
    contractName: row.company?.contractName,
    licensesCount: `${row.usedLicensesCount}(残り${row.remainingLicensesCount})`,
    startDate: moment(row.startDate).format(DATE_FORMAT),
    endDate: moment(row.endDate).format(DATE_FORMAT),
    companyContractId: row.company?.id,
  }));

  const columns = [
    {
      title: '契約名',
      dataIndex: 'contractName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.contractName}
        </TooltipParagraph>
      ),
      width: 400,
    },
    {
      title: 'コース名',
      dataIndex: 'courseName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.courseName}
        </TooltipParagraph>
      ),
      width: 400,
    },
    {
      title: '受講者数',
      dataIndex: 'licensesCount',
      width: 150,
    },
    {
      title: 'コース開始',
      dataIndex: 'startDate',
      width: 150,
    },
    {
      title: 'コース終了',
      dataIndex: 'endDate',
      width: 150,
    },
  ];

  return (
    <>
      <Text xxxl style={{ margin: '40px 0 16px' }}>新規契約コース</Text>
      <Style.RecentCoursesContainer>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {
              history.push({
                pathname: `/admin-service/course/${record.id}`,
                state: { companyContractId: record.companyContractId },
              });
            },
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={recentCourses.load}
        />
      </Style.RecentCoursesContainer>
    </>
  );
};

export default RecentCourses;
