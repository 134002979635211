//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Space,
  Progress,
  Tooltip,
  Button,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { showAlertNotice } from 'src/utils/alert';
import history from 'src/utils/history';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import {
  getMyJoinedCourses as getMyJoinedCoursesAction,
  getStudentCourseDetail as getStudentCourseDetailAction,
  getCompanyCourseDetail as getCompanyCourseDetailAction,
} from 'src/redux/actions';

import { convertSecondTime } from 'src/utils/dateTime';
import { DATE_FORMAT, COURSE_STATUS, COURSE_ACTUAL_STATUS } from 'src/constants/common';
import { handleStopPropagation } from 'src/utils/common';

import ModifyCourseDurationModal from 'src/components/Modal/components/ModifyCourseDurationModal';

import * as Style from './styles';

interface IJoinedCoursesProps extends StateProps, DispatchProps {
  getMyJoinedCourses(params): void;
  getStudentCourseDetail(params): void;
  getCompanyCourseDetail(params): void;
  showModal(params): void;
  role: string;
  activeTabKey: string;
  courseList: {
    data: {
      id: number;
      courseCategory: {
        name: string,
      },
      name: string;
      totalLearningDays: number;
      totalLearningTime: number;
      studentProgressPercentage: number;
      testInfo: {
        usersRank: number;
        totalUsers: number;
        highestTestScore: number;
      };
      remainingDays: number;
      courseActualStatus: string;
      courseStartDate: string;
      startDate: string;
      endDate: string;
      lastLearningDate: string
      actualStatus: string;
      recommendedEndDate: string;
      recommendedStartDate: string;
      manageCourseStatus: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: string;
  };
  profileInfo: {
    data: {
      id: number;
      company: {
        id: number;
        name: string;
      };
    };
  };
}

const JoinedCourses: React.FC<IJoinedCoursesProps> = ({
  getMyJoinedCourses,
  getStudentCourseDetail,
  getCompanyCourseDetail,
  showModal,
  role,
  courseList,
  profileInfo,
  activeTabKey,
}) => {
  const { data, meta } = courseList;
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    if (sortConditionUrl) {
      setSortCondition(sortConditionUrl)
    }
  }, []);

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey,
        sortCondition,
        page: meta.pageInfo,
        isSort: true,
      }
    })
  }, [activeTabKey, sortCondition, meta.pageInfo])

  useEffect(() => {
    setSortCondition(sortConditionUrl)
  }, [activeTabKey])

  useEffect(() => {
    if (activeTabKey === '2') {
      getMyJoinedCourses({ page: 1,...pageUrl, items: pageUrl?.limit, sort: sortConditionUrl });
    }
  }, [activeTabKey]);

  function handleClickCourse(record) {
    switch (role) {
    case 'student': {
      if (record.actualStatus === 'coming') {
        showAlertNotice({ type: 'success', message: `このコースは${record.startDate}から受講可能です。` });
      } else if (record.actualStatus === 'opening') {
        getStudentCourseDetail({
          id: record.id,
          redirect: `/course/${record.key}`,
        });
      } else {
        showAlertNotice({ type: 'warning', message: 'コースは受講期限が過ぎているか、コースが終了しています。' });
      }
      break;
    }
    case 'admin_company': {
      getCompanyCourseDetail({
        id: record.id,
        redirect: `/admin/course/${record.id}`,
      });
      break;
    }
    default: {
      history.push({
        pathname: `/admin-service/course/${record.key}`,
        state: { companyContractId: profileInfo.data.company.id },
      });
    }}
  }

  const tableData = data.map((courseItem) => ({
    ...courseItem,
    key: courseItem.id,
    id: courseItem.id,
    name: courseItem.courseCategory.name,
    totalLearningDays: `${courseItem.totalLearningDays}日`,
    totalLearningTime: convertSecondTime(courseItem.totalLearningTime, 'H時間m分'),
    studentProgressPercentage: courseItem.studentProgressPercentage,
    testInfo: courseItem.testInfo,
    startDate: moment(courseItem.startDate).format(DATE_FORMAT),
    endDate: moment(courseItem.endDate).format(DATE_FORMAT),
    actualStatus: courseItem.actualStatus,
    disable: courseItem.manageCourseStatus !== "enabled",
    lastLearningDate: courseItem.lastLearningDate && moment(courseItem.lastLearningDate).format(DATE_FORMAT),
  }));

  function CustomRow({index, ...props}) {
    if(index !== undefined && index !== null  && tableData[index].disable){
      return (
        <Tooltip title="管理対象外のコースです" placement="bottom" arrowPointAtCenter={false}>
          <tr {...props}/>
        </Tooltip>
      );
    }
    return <tr {...props}/>
  }

  function handleModifyCourseDuration(e, record) {
    handleStopPropagation(e);
    const modalProps = {
      width: 560,
      modalData: {
        title: 'ライセンス期間の変更',
        fromPage: 'myProfile',
        course: {
          userId: profileInfo.data.id,
          companyId: profileInfo.data.company.id,
          courseId: record.id,
          startDate: record.startDate,
          endDate: record.endDate,
          courseStartDate: record.courseStartDate,
          courseEndDate: record.courseEndDate,
          recommendedEndDate: record.recommendedEndDate,
          recommendedStartDate: record.recommendedStartDate,
          recommendedCompletionDay: record.recommendedCompletionDay
        },
      },
    };
    return showModal(<ModifyCourseDurationModal {...modalProps} />);
  }

  const columns = [
    {
      title:
        <SortableHeader
          type="name"
          title="コース"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 400,
    },
    {
      title:
        <SortableHeader
          type="actualStatus"
          title="ステータス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'actualStatus',
      render: (_, record) => (
        <Text>{COURSE_STATUS[record.actualStatus]}</Text>
      ),
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="totalLearningDays"
          title="学習日数"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningDays',
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningTime',
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="studentProgressPercentage"
          title="進歩率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'studentProgressPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.studentProgressPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.studentProgressPercentage} />
        </Space>
      ),
      width: 180,
    },
    {
      title:
        <SortableHeader
          type="highestTestScore"
          title={
            <div>
              <Text white style={{ marginLeft: '2px' }}>テスト正答率</Text>
              <Text white style={{ marginLeft: '-10px' }}>（全テスト平均）</Text>
            </div>
          }
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'testInfo' as any,
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text>
            {(isEmpty(record.testInfo) || record.testInfo.highestTestScore === null)
              ? 'ー'
              : `${record.testInfo.highestTestScore}%[${record.testInfo.usersRank}位/${record.testInfo.totalUsers}人]`
            }
          </Text>
        </Space>
      ),
      width: 170,
    },
    {
      title: (
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "lastLearningDate",
      render: (_, record) => (
        <Text>{record.lastLearningDate || ""}</Text>
      ),
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="startDate"
          title="開始日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'startDate',
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="endDate"
          title="終了日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          actionSort={getMyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'endDate',
      render: (_, record) => {
        if (role === 'admin_service') {
          return (
            <Style.CourseDurationAction
              onClick={(e) =>  handleStopPropagation(e) }
            >
              <Text>
                {`${moment(record.endDate).format(DATE_FORMAT)}[残り${record.remainingDays}日]`}
              </Text>
              <Tooltip
                placement="right"
                title={['opening', 'coming'].includes(record.courseActualStatus)
                  ? null
                  : COURSE_ACTUAL_STATUS[record.courseActualStatus]}
              >
                <Button
                  type="link"
                  icon={<EditOutlined style={{ color: '#3333' }} />}
                  disabled={['finished', 'closed'].includes(record.courseActualStatus)}
                  onClick={(e) => handleModifyCourseDuration(e, record)}
                />
              </Tooltip>
            </Style.CourseDurationAction>
          );
        }
        return <Text>{`${moment(record.endDate).format(DATE_FORMAT)}[残り${record.remainingDays}日]`}</Text>;
      },
      width: 230,
    },
  ];

  return (
    < >
      <div>
        <Style.JoinedCoursesContainer>
          <Table
            className={role === 'student' ? 'table-header-student table-cursor-pointer' : 'table-cursor-pointer'}
            pagination={false}
            scroll={{ x: 1500, y: 506 }}
            columns={columns}
            dataSource={tableData}
            onRow={(record,index) => ({
              onClick: () => record.disable || handleClickCourse(record),
              index
            })}
            locale={{ emptyText: '現在受講可能なコースはありません。' }}
            loading={courseList.load}
            components={{
              body: {
                row: CustomRow
              }
            }}
            rowClassName={(record)=>record.disable? "row-disable" :" "}
          />
        </Style.JoinedCoursesContainer>
        {!isEmpty(courseList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getMyJoinedCourses}
            sortCondition={sortCondition}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { courseList } = state.myProfileReducer;
  return {
    courseList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getMyJoinedCourses: (params) => dispatch(getMyJoinedCoursesAction(params)),
  getStudentCourseDetail: (params) => dispatch(getStudentCourseDetailAction(params)),
  getCompanyCourseDetail: (params) => dispatch(getCompanyCourseDetailAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JoinedCourses);
