// # gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Space, Select, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { CaretDownOutlined } from '@ant-design/icons';
import { isEmpty, isFinite, padStart } from 'lodash';

import { MainWrapper } from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';

import * as Style from './styles';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

interface IProgressNotificationProps {
  updateProgressNotificationSetting(params): void;
  showModal(params): void;
  closeModal(): void;
  courseId: string | undefined;
  companySelectedId: number;
  emailWeeklySchedule: [];
  emailHoursSchedule: number;
  emailMinutesSchedule: number;
  excludeFinishedUserSchedule: boolean;
}

const ProgressNotification: React.FC<IProgressNotificationProps> = ({
  updateProgressNotificationSetting,
  showModal,
  closeModal,
  courseId,
  companySelectedId,
  emailWeeklySchedule,
  emailHoursSchedule,
  emailMinutesSchedule,
  excludeFinishedUserSchedule
}) => {
  const [daysSelected, setDaysSelected] = useState<number[]>([])
  const [hourSelected, setHourSelected] = useState<number | undefined>(undefined)
  const [minuteSelected, setMinuteSelected] = useState<number | undefined>(undefined)
  const [finishedUserScheduleSelected, setFinishedUserScheduleSelected] = useState<boolean>(false);
  
  const daysOfWeek = [
    { title: '月', id: 1 },
    { title: '火', id: 2 },
    { title: '水', id: 3 },
    { title: '木', id: 4 },
    { title: '金', id: 5 },
    { title: '土', id: 6 },
    { title: '日', id: 0 }
  ]

  useEffect(() => {
    setDaysSelected(emailWeeklySchedule || [])
    setHourSelected(emailHoursSchedule !== null ? emailHoursSchedule : undefined)
    setMinuteSelected(emailMinutesSchedule !== null ? emailMinutesSchedule : undefined)
    setFinishedUserScheduleSelected(excludeFinishedUserSchedule)
  }, [emailWeeklySchedule, emailHoursSchedule, emailMinutesSchedule, excludeFinishedUserSchedule])

  function renderDaysCheckbox() {
    return daysOfWeek.map(day =>
      <Button
        key={`progress-notify-day-${day.id}`}
        type={(!isEmpty(daysSelected) && daysSelected.includes(day.id)) ? 'primary' : 'default'}
        onClick={() => (!isEmpty(daysSelected) && daysSelected.includes(day.id)) ?
          setDaysSelected([...daysSelected].filter(item => item !== day.id)) :
          setDaysSelected([...daysSelected, day.id])
        }
      >
        {day.title}
      </Button>
    )
  }

  function renderHourOptions() {
    return [...Array(24)].map((_, itemOptionIndex) => {
      const itemValue = itemOptionIndex
      return (
        <Select.Option key={`progress-notify-hour-${itemOptionIndex}`} value={itemValue}>
          {itemValue}
        </Select.Option>
      );
    });
  }

  function renderMinuteOptions() {
    return [...Array(4)].map((_, itemOptionIndex) => {
      const itemValue = itemOptionIndex * 15
      return (
        <Select.Option key={`progress-notify-minute-${itemOptionIndex}`} value={itemValue}>
          {padStart(itemValue, 2, '0')}
        </Select.Option>
      );
    });
  }

  const handleClearSetting = () => {
    const modalProps = {
      modalData: {
        title: "通知停止確認",
        message: "通知を停止しますが、よろしいですか。",
        okText: "はい",
        cancelText: "いいえ"
      },
      modalAction: {
        confirm: () => {
          const requestData = {
            companyId: companySelectedId,
            emailWeeklySchedule: [],
            emailHoursSchedule: null,
            emailMinutesSchedule: null,
            excludeFinishedUserSchedule: false
          }
          updateProgressNotificationSetting({ id: courseId, requestData })
          closeModal()
        },
      },
      isConfirm: true,
      spaceEvenly: true
    };
    showModal(<ConfirmModal {...modalProps} />);
  };


  const isDisableButtonSubmit = isEmpty(daysSelected) || !isFinite(hourSelected) || !isFinite(minuteSelected)
  const isDisableButtonCancel = isEmpty(emailWeeklySchedule)
  return (
    <div style={{ marginTop: '40px' }}>
      <MainWrapper 
        title={<Text white lg w6>通知条件設定</Text>}
      >
        <Style.ProgressNotificationContainer>
          <Style.ProgressNotificationOverview>
            <Text style={{ lineHeight: '32px' }}>概要</Text>
            <Text>進捗通知設定をすることで、当コースの担当をしている企業管理者および受講者に進捗状況をメールにて通知することができます。</Text>
            <Text>※通知頻度を高く設定すると、迷惑メールと認識される場合があるため、週1回の設定をお勧めします。</Text>
          </Style.ProgressNotificationOverview>
          <Style.ProgressNotificationSchedule>
            <Row style={{ marginTop: '40px' }}>
              <Col span={3}>
                <Text>曜日</Text>
              </Col>
              <Col span={21}>
                <Row>
                  <Space size='middle'>
                    {renderDaysCheckbox()}
                  </Space>
                </Row>
                <Row style={{ marginTop: '8px' }}>
                  <Text>※複数選択可</Text>
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: '40px 0' }}>
              <Col span={3}>
                <Text>時間</Text>
              </Col>
              <Col span={21}>
                <Space align="start">
                  <Select
                    allowClear
                    suffixIcon={<CaretDownOutlined />}
                    placeholder='時'
                    style={{ width: 150 }}
                    value={isFinite(hourSelected) ? hourSelected : undefined}
                    onChange={(value) => setHourSelected(+value)}
                  >
                    {renderHourOptions()}
                  </Select>
                  <Style.TwoDots style={{margin: '0px 25px'}}>:</Style.TwoDots>
                  <Select
                    allowClear
                    suffixIcon={<CaretDownOutlined />}
                    placeholder='分'
                    style={{ width: 150 }}
                    value={isFinite(minuteSelected) ? minuteSelected : undefined}
                    onChange={(value) => setMinuteSelected(+value)}
                  >
                    {renderMinuteOptions()}
                  </Select>
                </Space>
              </Col>
            </Row>
            <Row style={{ margin: '40px 0' }}>
              <Col span={3}>
                <Text>完了者除外</Text>
              </Col>
              <Col span={21}>
                <Space align="center">
                  <Checkbox
                    checked={finishedUserScheduleSelected}
                    onChange={(e: CheckboxChangeEvent) => {
                      setFinishedUserScheduleSelected(e.target?.checked)
                    }}
                  />
                  進捗率100％の人を通知から除外する
                </Space>
              </Col>
            </Row>
            <Row style={{ margin: '40px 0' }}>
              <Col span={21} offset={3}>
                <Button 
                  type='primary'
                  disabled={isDisableButtonSubmit}
                  onClick={() => {
                    const requestData = {
                      companyId: companySelectedId,
                      emailWeeklySchedule: daysSelected,
                      emailHoursSchedule: isFinite(hourSelected) ?  hourSelected : null,
                      emailMinutesSchedule: isFinite(minuteSelected) ? minuteSelected : null,
                      excludeFinishedUserSchedule: finishedUserScheduleSelected
                    }
                    updateProgressNotificationSetting({ id: courseId, requestData })
                  }}
                  style={{ marginRight: 30 }}
                >
                  設定する
                </Button>
                <Button 
                  type='primary'
                  disabled={isDisableButtonCancel}
                  onClick={handleClearSetting}
                >
                  通知停止
                </Button>
              </Col>
            </Row>
          </Style.ProgressNotificationSchedule>
        </Style.ProgressNotificationContainer>
      </MainWrapper>
    </div>
  );
};

export default ProgressNotification;
