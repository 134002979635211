// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';
import moment from 'moment';

export function getVideoList(params) {
  const newParams = {
    page: params.page,
    nameCont: (params.name || '').trim(),
    categoryCont: (params.category || '').trim(),
    updatedAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    updatedAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.VIDEO_ACTION.GET_LIST),
    payload: newParams,
  };
}
