// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { put, takeLatest, all } from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';

import history from 'src/utils/history';

import {
  ADMIN_SERVICE,
  MODAL_ACTION,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';
import { ServicePath } from 'src/constants/routerConstants';
import AddCsvModal from "src/components/Modal/components/AddCsvModal"

import { accountServices } from 'src/services';

function* getConfirmationStatusSaga(action) {
  try {
    const result = yield accountServices.getConfirmationStatus(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS),
      payload: result,
    });
  } catch (e) {
    yield history.push('/admin-service/login');
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS), errors: e.errors });
  }
}

function* inviteCompanySaga(action) {
  try {
    const result = yield accountServices.inviteCompany(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: 'アカウントの招待が成功しました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY), errors: e.errors });
  }
}

function* inviteStudentsSaga(action) {
  try {
    const result = yield accountServices.inviteStudents({ ...action.payload });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS),
      payload: result.data,
    });
    yield showAlertNotice({ type: 'success', message: 'アカウントの招待が成功しました。' });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* inviteServicesSaga(action) {
  try {
    const result = yield accountServices.inviteServices({ ...action.payload });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES),
      payload: result.data,
    });
    yield showAlertNotice({ type: 'success', message: 'アカウントの招待が成功しました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES), errors: e.errors });
  }
}

function* inviteAdminCompanySaga(action) {
  try {
    const result = yield accountServices.inviteAdminCompany(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: 'アカウントの招待が成功しました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY), errors: e.errors });
  }
}

function* getInvitationListSaga(action) {
  try {
    const result = yield accountServices.getInvitationList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.GET_INVITATION_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_INVITATION_LIST), errors: e.errors });
  }
}

function* getStudentsByCompanySaga(action) {
  try {
    const result = yield accountServices.getStudentsByCompany(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY), errors: e.errors });
  }
}

function* getAccountListSaga(action) {
  try {
    const result = yield accountServices.getAccountList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.GET_ACCOUNT_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.GET_ACCOUNT_LIST), errors: e.errors });
  }
}

function* resendInvitationSaga(action) {
  try {
    const result = yield accountServices.resendInvitation(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.RESEND_INVITATION),
      payload: {
        ...result.data.user,
        email: action.payload.user.email,
      },
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.RESEND_INVITATION), errors: e.errors });
  }
}

function* cancelInvitationSaga(action) {
  try {
    yield accountServices.cancelInvitation(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.CANCEL_INVITATION),
      payload: action.payload,
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.CANCEL_INVITATION), errors: e.errors });
  }
}

function* addStudentsToCourseSaga(action) {
  try {
    const { courseId, requestData } = action.payload;
    yield accountServices.addStudentsToCourse(action.payload);
    const result = yield accountServices.getStudentsByCompany({
      companyId: requestData.companyId,
      requestData: {
        courseId,
        page: 1,
      },
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: 'ユーザのライセンス登録が成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE), errors: e.errors });
  }
}

function* serviceConfirmAccountSaga(action) {
  try {
    yield accountServices.serviceConfirmAccount(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.CONFIRM_ACCOUNT),
    });
    yield localStorage.clear();
    yield history.push(ServicePath.Login);
    yield showAlertNotice({ type: 'success', message: 'アカウントの登録が成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.CONFIRM_ACCOUNT), errors: e.errors });
  }
}

function* banAccountSaga(action) {
  try {
    yield accountServices.banAccount(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.BAN_ACCOUNT),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: 'ユーザを非アクティブ化することに成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.BAN_ACCOUNT), errors: e.errors });
  }
}

function* addStudentsToCourseByCsvSaga(action) {
  try {
    const { courseId, requestData } = action.payload;
    const result = yield accountServices.addStudentsToCourseByCsv(action.payload);
    yield put({
      type: REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY),
      payload: {
        companyId: requestData.getAll("company_id"),
        requestData: {
          courseId,
          page: 1,
        },
      }
    })
    yield put({
      type: REQUEST(ADMIN_SERVICE.COURSE_ACTION.GET_COURSES_BY_COMPANY),
      payload: {
        id: requestData.getAll("company_id"),
      },
    })
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV),
      payload: { numberSuccessEmails: result.data.numberSuccessEmails, courseId,numberInviteEmails: result.data.numberInvitedEmails },
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });

  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });

  }
}

function* bulkResendInvitationsSaga(action) {
  try {
    yield accountServices.bulkResendInvitations(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS),
      payload: action.payload
    });
    yield showAlertNotice({ type: 'success', message: '一括再送を実行中です' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS), errors: e.errors });
  }
}

function* bulkCancelInvitationsSaga(action) {
  try {
    yield accountServices.bulkCancelInvitations(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS),
      payload: action.payload,
    });
    yield showAlertNotice({ type: 'success', message: '一括キャンセルを実行中です' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS), errors: e.errors });
  }
}

export default function* accountSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS), getConfirmationStatusSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_COMPANY), inviteCompanySaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_STUDENTS), inviteStudentsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_SERVICES), inviteServicesSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.INVITE_ADMIN_COMPANY), inviteAdminCompanySaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_INVITATION_LIST), getInvitationListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_STUDENTS_BY_COMPANY), getStudentsByCompanySaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.GET_ACCOUNT_LIST), getAccountListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.RESEND_INVITATION), resendInvitationSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.CANCEL_INVITATION), cancelInvitationSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE), addStudentsToCourseSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.CONFIRM_ACCOUNT), serviceConfirmAccountSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BAN_ACCOUNT), banAccountSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.ADD_STUDENTS_TO_COURSE_BY_CSV), addStudentsToCourseByCsvSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_RESEND_INVITATIONS), bulkResendInvitationsSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.ACCOUNT_ACTION.BULK_CANCEL_INVITATIONS), bulkCancelInvitationsSaga),
  ]);
}
