//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import history from 'src/utils/history';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import ProfileSetting from './components/ProfileSetting';
import TeacherList from './components/TeacherList';
import TagList from './components/TagList';
import CategoryList from './components/CategoryList';
import PlansList from './components/PlansList';
import {
  closeModal as closeModalAction,
} from 'src/redux/actions';
import { Text } from 'src/components/styles';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import * as Style from './styles';

interface IMasterSettingPageProps extends StateProps, DispatchProps {
  closeModal(): void;
}

const MasterSettingPage: React.FC<IMasterSettingPageProps> = ({ closeModal }) => {
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const urlActiveKey = history.location.state?.key;

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.MasterSetting);
    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    if (urlActiveKey) setActiveTabKey(urlActiveKey);
  }, [urlActiveKey]);

  return (
    <MainContainer isTabs>
      <MainContent fullView>
        <Style.MasterSettingTitle>
          <Text xxxl w6>マスタ管理</Text>
        </Style.MasterSettingTitle>
        <Tabs
          activeKey={activeTabKey}
          type="card"
          onChange={(activeKey) => setActiveTabKey(activeKey)}
          style={{ marginBottom: 60 }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>講座タイプ</Text>}
            key="1"
          >
            <CategoryList />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>Tags</Text>}
            key="2"
          >
            <TagList />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>プラン</Text>}
            key="3"
          >
            <PlansList />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>講師</Text>}
            key="4"
          >
            <TeacherList />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>プロフィール項目</Text>}
            key="5"
          >
            <ProfileSetting />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(MasterSettingPage);
