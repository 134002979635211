//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Radio,
  Button,
  Input,
  List,
  Spin,
  Skeleton,
} from 'antd';
import Countdown from 'react-countdown';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { maxBy } from 'lodash/math';
import MathJax from 'react-mathjax-preview';
import { useDropzone } from 'react-dropzone';

import history from 'src/utils/history';
import {
  ALLOWED_TYPE,
  FILE_SIZE,
} from 'src/constants/common';
import { getSearchParams } from 'src/utils/common';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import { Text } from 'src/components/styles';

import {
  selectQuestion as selectQuestionAction,
  submitTestAnswers as submitTestAnswersAction,
  uploadFileAnswer as uploadFileAnswerAction,
  showModal as showModalAction,
} from 'src/redux/actions';

import dateFileIcon from 'src/assets/images/common/date-file-icon.svg';

import * as Style from './styles';
import { CourseNameTitle } from "../../styles"

// Fix Unmount remove event beforeunload
const useUnload = (useUnloadFunc) => {
  const useUnloadRef = useRef(useUnloadFunc);

  useEffect(() => {
    const onUnload = useUnloadRef.current;

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, [useUnloadRef]);
};

interface ITestingContentPageProps extends StateProps, DispatchProps {
  selectQuestion(params): void;
  submitTestAnswers(params): void;
  uploadFileAnswer(params): void;
  showModal(params): void;
  questionIdSelected: number | string;
  isMobileDevice: boolean;
  courseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
  };
  unitDetail: {
    data: {
      id: number;
      item: {
        limitTime: number;
        name: string;
        description: number;
        questionsCount: number;
        testType: string;
        type: string;
      };
    };
  };
  testQuestions: {
    data: {
      unitVersionId: number;
      testsUserId: number;
      questions: {
        id: number;
        name: string;
        isScored: boolean;
        image: {
          url: string;
        };
        content: string;
        questionType: string;
        questionOptions: {
          id: number;
          content: string;
        }[];
      }[];
    };
    filesUploadStatus: {};
  };
}

const TestingContentPage: React.FC<ITestingContentPageProps> = ({
  selectQuestion,
  submitTestAnswers,
  showModal,
  uploadFileAnswer,
  questionIdSelected,
  courseDetail,
  unitDetail,
  testQuestions,
  isMobileDevice,
}) => {
  const [countdownTime, setCountdownTime] = useState<any>(Date.now());
  const [completionTime, setCompletionTime] = useState<number>(0);
  const [answerForm, setAnswerForm] = useState<{
    questionId: number;
    answer: number | string | undefined;
    questionType: string;
    isScored: boolean;
  }[]>([]);
  const [networkIsOnline, setNetworkIsOnline] = useState<boolean>(navigator.onLine);

  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[2];
  const unitVersionId = pathnameSplit[4];
  const groupId = getSearchParams('group') || '';
  const chapterId = getSearchParams('chapter') || '';

  const questionData = testQuestions.data.questions;
  const questionIndex = questionIdSelected !== 'confirm'
    ? findIndex(questionData, { id: questionIdSelected })
    : -2; // confirm answer

  const [fileName, setFileName] = useState<number | string | undefined>('');
  const [fileError, setFileError] = useState<string>('');
  const [fileResult, setFileResult] = useState<any>();
  const [filesUploadStatus, setFilesUploadStatus] = useState<any>({});
  const fileFormData = new FormData();

  const onDrop = (file) => {
    setFileResult(file[0]);
    setFileName(file[0].name);
    const fileType = file[0].name.slice(file[0].name.lastIndexOf('.') + 1);
    if (!ALLOWED_TYPE.includes(fileType)) {
      setFileError('ファイルの形式が正しくありません。選択可能ファイルはpdf、word、powerpoint、ipynb、pyです。');
    } else if (file[0].size > FILE_SIZE) {
      setFileError('ファイルサイズが大きすぎます。(最大サイズ：30MB）');
    } else {
      setFileError('');
    }
    const reader = new FileReader();

    reader.readAsDataURL(file[0]);
  };

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
  } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (!networkIsOnline) alert('ネットワーク接続が切れました。もう一度お試しください。');
  }, [networkIsOnline]);

  useEffect(() => {
    if (testQuestions.data) {
      setFilesUploadStatus(testQuestions.filesUploadStatus);
    }
  }, [testQuestions.filesUploadStatus]);

  useEffect(() => {
    if (unitDetail.data.id && unitDetail.data.item.limitTime > 0) {
      setCountdownTime(Date.now() + (unitDetail.data.item.limitTime * 1000));
    }
  }, [unitDetail.data.id]);

  useEffect(() => {
    if (!networkIsOnline) alert('ネットワーク接続が切れました。もう一度お試しください。');
    if (questionIdSelected !== 'confirm') {
      answerForm.forEach((file) => {
        if (file.questionId === questionIdSelected) setFileName(file.answer);
      });
    }
    setFileError('');
    setNetworkIsOnline(navigator.onLine);
  }, [questionIdSelected]);


  useEffect(() => {
    if (isEmpty(testQuestions.data?.questions)) {
      history.push({
        pathname: `/learn-course/${courseId}/test/${unitVersionId}`,
        search: `?group=${groupId}&chapter=${chapterId}`,
      });
    } else {
      const newAnswerForm = questionData.map((question) => ({
        questionId: question.id,
        answer: undefined,
        questionType: question.questionType,
        isScored: question.isScored,
      }));
      setAnswerForm([...newAnswerForm]);
    }
  }, [testQuestions.data?.questions]);

  useUnload((e) => {
    e.preventDefault();
    e.returnValue = '';
  });

  function handleChangeAnswer(e, id, isUpload?: boolean) {
    const newAnswerForm = answerForm;
    const answerIndex = findIndex(answerForm, { questionId: id });
    newAnswerForm.splice(answerIndex, 1, {
      ...answerForm[answerIndex],
      questionId: id,
      answer: isUpload ? e : e.target.value,
    });
    setAnswerForm([...newAnswerForm]);
  }

  function handleMoveQuestion(isNext) {
    if (!isNext && questionIndex !== 0) {
      selectQuestion(questionData[questionIndex - 1].id);
    } else if (questionIndex + 1 < questionData.length) {
      selectQuestion(questionData[questionIndex + 1].id);
    } else {
      selectQuestion('confirm');
    }
  }

  function handleConfirmSubmitAnswer() {
    setNetworkIsOnline(navigator.onLine);
    const modalProps = {
      isConfirm: true,
      modalData: {
        message: 'このテストを提出しますか？',
        title: 'テスト提出確認',
      },
      modalAction: {
        confirm: () => handleSubmitAnswer(),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function handleSubmitAnswer() {
    const newAnswerForm = answerForm.map((answerFormItem) => ({
      questionId: answerFormItem.questionId,
      answer: typeof answerFormItem.answer === 'string'
        ? answerFormItem.answer.trim()
        : answerFormItem.answer,
    }));
    return submitTestAnswers({
      courseId,
      requestData: {
        usersAnswer: {
          unitVersionId,
          groupId,
          chapterId,
          completionTime,
          questions: newAnswerForm,
          testsUserId: testQuestions.data.testsUserId,
        },
      },
    });
  }

  function renderQuestionContent() {
    if (questionIdSelected !== 'confirm') {
      return (
        <>
          {questionData[questionIndex].image && (
            <Style.QuestionImageContainer>
              <Style.QuestionImageContent src={questionData[questionIndex].image.url} alt="" />
            </Style.QuestionImageContainer>
          )}
          {questionData[questionIndex].content && (
            <Style.QuestionContent>
              <MathJax math={questionData[questionIndex].content} />
            </Style.QuestionContent>
          )}
        </>
      );
    } else {
      return (
        <Style.QuestionContent>
          <List
            bordered
            dataSource={questionData}
            renderItem={(questionItem, questionItemIndex) => (
              <List.Item
                style={{ cursor: 'pointer' }}
                onClick={() => selectQuestion(questionItem.id)}
              >
                <Space align="center" size="middle">
                  {!!answerForm[questionItemIndex].answer
                    ? <Style.QuestionStatus color="green">解答済み</Style.QuestionStatus>
                    : <Style.QuestionStatus color="gold">未解答</Style.QuestionStatus>
                  }
                  <Style.QuestionIndex headerText w6 style={{ width: 60 }}>
                    {`${questionItemIndex + 1}問目`}
                  </Style.QuestionIndex>
                  <Text>{questionItem.name}</Text>
                </Space>
                {filesUploadStatus[questionItem.id] &&
                  <Space style={{ width: '150px' }}>
                    <Text lg w6>アップロード中</Text>
                    <Spin />
                  </Space>
                }
              </List.Item>
            )}
          />
          <Style.ConfirmAnswerAction>
            <Space align="center" size="middle">
              <Style.AnswerNumber xs subText w6>解答済み：</Style.AnswerNumber>
              <Style.AnswerNumber headerText w6>
                {`${answerForm?.filter((studentAnswer) => !!studentAnswer.answer).length}/${questionData?.length}問`}
              </Style.AnswerNumber>
            </Space>
            <Button
              type="primary"
              onClick={() => handleConfirmSubmitAnswer()}
              disabled={Object.values(filesUploadStatus).includes(true)}
            >
              提出する
            </Button>
          </Style.ConfirmAnswerAction>
        </Style.QuestionContent>
      );
    }
  }

  function renderOptionRadio(questionOptions) {
    const maxLengthAnswer = maxBy(questionOptions, (answer) => answer.content.length);
    let answerWidth = '';
    const isMediumContent = maxLengthAnswer.content.length > 10 && maxLengthAnswer.content.length <= 30;
    const isLongContent = maxLengthAnswer.content.length > 30;
    if (questionOptions.length === 2 && !isLongContent) {
      answerWidth = 'calc(50% - 16px)';
    } else if ([3, 5, 6].includes(questionOptions.length) && !isLongContent) {
      answerWidth = 'calc(100% / 3 - 16px)';
    } else if (maxLengthAnswer.content.length <= 10) {
      answerWidth = 'calc(25% - 16px)';
    } else if (isMediumContent) {
      answerWidth = 'calc(50% - 16px)';
    } else {
      answerWidth = '100%';
    }
    return questionOptions.map((option, optionIndex) => (
      <Radio.Button
        key={`option-${option.id}-${optionIndex}`}
        className="answer-radio-button"
        value={option.id}
        style={{ width: answerWidth }}
      >
        {`${optionIndex + 1}. ${option.content}`}
      </Radio.Button>
    ));
  }

  function renderAnswerContent() {
    const answerIndex = findIndex(answerForm, { questionId: questionData[questionIndex].id });
    switch (questionData[questionIndex].questionType) {
    case 'option_type':
      return (
        <Radio.Group
          className="answer-radio-group"
          buttonStyle="solid"
          {...answerIndex !== -1 && { value: answerForm[answerIndex].answer }}
          onChange={(e) => handleChangeAnswer(e, questionData[questionIndex].id)}
        >
          {renderOptionRadio(questionData[questionIndex].questionOptions)}
        </Radio.Group>
      );
    case 'input_type':
      return (
        <Input.TextArea
          placeholder="解答を入力してください"
          value={answerIndex !== -1 ? answerForm[answerIndex].answer : ''}
          onChange={(e) => handleChangeAnswer(e, questionData[questionIndex].id)}
          style={{ height: 200 }}
        />
      );
    case 'upload_file_type':
      return renderDragFileArea();
    default:
      return null;
    }
  }

  function handleUploadFile() {
    if (!isEmpty(fileError)) return null;
    fileFormData.append('users_answer[group_id]', groupId);
    fileFormData.append('users_answer[chapter_id]', chapterId);
    fileFormData.append('users_answer[unit_version_id]', unitVersionId);
    fileFormData.append('users_answer[question_id]', questionIdSelected.toString());
    fileFormData.append('users_answer[tests_user_id]', testQuestions.data.testsUserId.toString());
    if (fileResult) fileFormData.append('users_answer[answer_file]', fileResult, fileResult?.name);
    uploadFileAnswer({
      requestData: fileFormData,
      courseId,
      questionId: questionIdSelected,
      isMobileDevice,
      callback: () => handleChangeAnswer(acceptedFiles[0].name, questionData[questionIndex].id, true)
    });
  }

  function renderUploadingIcon() {
    if (filesUploadStatus[questionIdSelected]) {
      return (
        <>
          <Style.FileName
            truncate
            center
            lg
            w6
            style={{ width: 400, lineHeight: '24px' }}
          >
            {fileName}
          </Style.FileName>
          <Space>
            <Text lg w6>アップロード中</Text>
            <Spin />
          </Space>
        </>
      );
    }
    return (
      <Style.FileName
        truncate
        center
        lg
        w6
        style={{ width: 400, lineHeight: `${fileError ? '24px' : '54px'}` }}
      >
        {fileName}
      </Style.FileName>
    );
  }

  function renderDragFileArea() {
    return (
      <Style.UploadField
        key={questionData[questionIndex].id}
        {...getRootProps()}
        isDrag={isDragActive}
      >
        <input {...getInputProps()} />
        {!fileName
          ? (
            <Style.Placeholder>
              <Text lg w6 subText>ここにファイルをドラッグ</Text>
              <Text margin="0 0 10px" lg w6 subText>または</Text>
            </Style.Placeholder>
          )
          : renderUploadingIcon()
        }
        {fileError && (
          <Text error margin="0 0 10px">{fileError}</Text>
        )}
        <Style.GroupAction>
          <Button
            size="middle"
            type="primary"
            className="btn-select-file-desktop"
            onClick={open}
            ghost
          >
            {!!fileName ? 'ファイルを再選択' : 'パソコンからファイルを選択'}
          </Button>
          <Button
            size="middle"
            type="primary"
            className="btn-select-file-mobile"
            onClick={open}
            ghost
          >
            {!!fileName ? 'ファイルを再選択' : 'ファイルを選択'}
          </Button>
          <Button
            size="middle"
            type="primary"
            className="btn-primary"
            onClick={() => {
              handleUploadFile();
            }}
            style={{ marginLeft: 10 }}
            disabled={!!fileError || !fileName}
          >
            ファイルを提出
          </Button>
          {!fileName && <Style.PlacehoderUploadMobile>ファイル形式 pdf・xlsx</Style.PlacehoderUploadMobile>}
        </Style.GroupAction>
      </Style.UploadField>
    );
  }

  function renderAnswerTitle() {
    switch (questionData[questionIndex].questionType) {
    case 'option_type':
      return '解答を選択';
    case 'input_type':
      return '解答を入力';
    case 'upload_file_type':
      return '解答ファイルをアップロード';
    default:
      return null;
    }
  }

  if (questionIndex === -1) return null;
  return (
    <>
      <CourseNameTitle white w6 xxl>{courseDetail.data.courseCategory?.name}</CourseNameTitle>
      <Style.QuestionDetailContainer>
        <Style.QuestionDetailContent>
          <Style.QuestionTitle>
            <Style.QuestionName truncate white lg w6 style={{ width: 'calc(100% - 250px)' }}>
              {questionIdSelected !== 'confirm'
                ? `${questionIndex + 1}問目 ${questionData[questionIndex].name}`
                : '解答確認'
              }
            </Style.QuestionName>
            <Style.QuestionNumber white lg w6 style={{ width: 'calc(100% - 250px)' }}>
              {questionIdSelected !== 'confirm'
                ? `${questionIndex + 1}問目`
                : '解答確認'
              }
            </Style.QuestionNumber>
            <Space align="center" size="middle">
              <Style.LimitTime white lg w5>テスト制限時間</Style.LimitTime>
              <Space align="center" size={4}>
                <img src={dateFileIcon} height="24" width="auto" alt="" className="icon-clock" />
                {unitDetail.data.item.limitTime > 0
                  ? <Countdown
                    date={countdownTime}
                    onTick={(props) => {
                      const testingTime = unitDetail.data.item.limitTime - (props.total / 1000);
                      setCountdownTime(Date.now() + props.total);
                      setCompletionTime(testingTime);
                    }}
                    onComplete={() => handleSubmitAnswer()}
                    renderer={(props) => (
                      <Style.Time white lg>
                        {`${props.formatted.hours}:${props.formatted.minutes}:${props.formatted.seconds}`}
                      </Style.Time>
                    )}
                  />
                  : <Style.Time white lg>無制限</Style.Time>
                }
              </Space>
            </Space>
            {questionIdSelected !== "confirm" && (
              <Style.QuestionNameMobile white lg w6>
                {questionData[questionIndex].name}
              </Style.QuestionNameMobile>
            )}
          </Style.QuestionTitle>
          {renderQuestionContent()}
        </Style.QuestionDetailContent>
        {questionIdSelected !== 'confirm' && (
          <Style.AnswerContainer>
            {questionData[questionIndex].questionType !== 'option_type' && (
              <Style.AnswerTitle>
                <Text white lg w5>{renderAnswerTitle()}</Text>
              </Style.AnswerTitle>
            )}
            <Style.AnswerContent>
              {renderAnswerContent()}
              {questionData[questionIndex].questionType === 'upload_file_type'
              && <Style.BtnUploadMobile>
                  <Button
                    size="middle"
                    type="primary"
                    onClick={() => {
                      handleUploadFile();
                    }}
                    style={{ marginLeft: 10 }}
                    disabled={!!fileError || !fileName}
                  >
                    ファイルを提出
                  </Button>
              </Style.BtnUploadMobile>
              }
              <Style.AnswerAction
                isFirstQuestion={questionIndex === 0}
                isLastQuestion={questionIndex === questionData.length - 1}
              >
                {questionIndex !== 0 && (
                  <Button
                    type="primary"
                    className="btn-previous"
                    onClick={() => handleMoveQuestion(false)}
                  >
                    戻る
                  </Button>
                )}
                <Button type="primary" className="btn-next" onClick={() => handleMoveQuestion(true)}>
                  {questionIndex === questionData.length - 1 ? '解答する' : '次へ'}
                </Button>
              </Style.AnswerAction>
            </Style.AnswerContent>
          </Style.AnswerContainer>
        )}
      </Style.QuestionDetailContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { questionIdSelected } = state.commonReducer;
  const { courseDetail } = state.studentCourseReducer;
  const { unitDetail } = state.studentUnitReducer;
  const { testQuestions } = state.studentTestReducer;
  return {
    questionIdSelected,
    courseDetail,
    unitDetail,
    testQuestions,
  };
};

const mapDispatchToProps = (dispatch) => ({
  selectQuestion: (params) => dispatch(selectQuestionAction(params)),
  submitTestAnswers: (params) => dispatch(submitTestAnswersAction(params)),
  uploadFileAnswer: (params) => dispatch(uploadFileAnswerAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestingContentPage);
