// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { put, takeLatest, all } from 'redux-saga/effects';

import {
  MODAL_ACTION,
  ADMIN_SERVICE,
  NOTIFICATION_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

import UploadScoringModal from 'src/components/Modal/components/UploadScoringModal';

import { TEST_TYPE } from 'src/constants/common';

import { uploadAnswerServices } from 'src/services';
import { showAlertNotice } from 'src/utils/alert';
import ShowErrorModal from 'src/components/Modal/components/ShowErrorModal';


function* getUploadAnswerListSaga(action) {
  try {
    const result = yield uploadAnswerServices.getUploadAnswerList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getUploadAnswerDetailSaga(action) {
  try {
    const { requestData } = action.payload;
    const result = yield uploadAnswerServices.getUploadAnswerDetail(action.payload);
    const { usersAnswer } = result.data;
    const modalProps = {
      width: 500,
      modalData: {
        title: `${TEST_TYPE[usersAnswer.test.testType]}採点`,
        company: usersAnswer.company,
        course: usersAnswer.course,
        test: usersAnswer.test,
        question:  usersAnswer.question,
        user: usersAnswer.user,
        answerFile: usersAnswer.answerFile,
        usersAnswerId: usersAnswer.id,
        score: usersAnswer.score,
      },
    };
    yield put({
      type: REQUEST(MODAL_ACTION.SHOW),
      payload: (<UploadScoringModal {...modalProps} />),
    });
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_DETAIL),
      payload: {
        notificationId: requestData.markNotificationAsReadId,
        ...result,
      },
    });
    yield put({ type: REQUEST(NOTIFICATION_ACTION.GET_NOTIFICATION_UNREAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_DETAIL), errors: e.errors });
    const { isNotification } = action.payload.requestData;
    if(isNotification){
      const modalProps = {
        isConfirm: true,
        modalData: {
          message: e.errors[0].message,
          title: 'データが存在しません',
        },
      };
      yield put({
        type: REQUEST(MODAL_ACTION.SHOW),
        payload: (<ShowErrorModal {...modalProps} />),
      });
    }
  }
}

function* scoreUploadAnswerSaga(action) {
  try {
    const { usersAnswer } = action.payload;
    const result = yield uploadAnswerServices.scoreUploadAnswer(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.SCORE_UPLOAD_ANSWER),
      payload: {
        data: {
          usersAnswer: {
            ...result.data.usersAnswer,
            score: usersAnswer.score,
          },
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '採点が成功しました。' });
  } catch (e) {
    const { isNotification } = action.payload;
    if(isNotification){
      const modalProps = {
        isConfirm: true,
        modalData: {
          message: e.errors[0].message,
          title: 'データが存在しません',
        },
      };
      yield put({
        type: REQUEST(MODAL_ACTION.SHOW),
        payload: (<ShowErrorModal {...modalProps} />),
      });
    }else{
      yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    }
    yield put({ type: FAILURE(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.SCORE_UPLOAD_ANSWER), errors: e.errors });
  }
}

export default function* uploadAnswerSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_LIST), getUploadAnswerListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.GET_DETAIL), getUploadAnswerDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.UPLOAD_ANSWER_ACTION.SCORE_UPLOAD_ANSWER), scoreUploadAnswerSaga),
  ]);
}
