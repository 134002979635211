// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Tag } from 'antd';

export const FieldContent = styled.div`
  padding: 24px;
`;

export const TagSelected = styled(Tag)`
  display: flex;
  align-items: center;
  padding: 0 12px 0 16px;
  height: 24px;
  border-radius: ${(props) => props.theme.radius.circle};
`;
