// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';

import {
  ADMIN_SERVICE,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

import history from 'src/utils/history';
import { licenseServices } from 'src/services';
import { showAlertNotice } from 'src/utils/alert';

import { ServicePath } from 'src/constants/routerConstants';

// License Action

function* getLicenseListSaga(action) {
  try {
    const result = yield licenseServices.getLicenseList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.LICENSE_ACTION.GET_LIST), errors: e.errors });
  }
}

function* createLicenseSaga(action) {
  try {
    const result = yield licenseServices.createLicense(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.CREATE_LICENSE),
      payload: result,
    });
    yield history.push(ServicePath.LicenseList);
    yield showAlertNotice({ type: 'success', message: 'ライセンスの設定が成功しました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.LICENSE_ACTION.CREATE_LICENSE), errors: e.errors });
  }
}

function* getLicenseDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield licenseServices.getLicenseDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.GET_DETAIL),
      payload: result,
    });
    if (redirect) history.push(redirect);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.LICENSE_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* updateLicenseSaga(action) {
  try {
    const result = yield licenseServices.updateLicense(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.LICENSE_ACTION.UPDATE_LICENSE),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.LICENSE_ACTION.UPDATE_LICENSE), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

export default function* licenseSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.LICENSE_ACTION.CREATE_LICENSE), createLicenseSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.LICENSE_ACTION.GET_LIST), getLicenseListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.LICENSE_ACTION.GET_DETAIL), getLicenseDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.LICENSE_ACTION.UPDATE_LICENSE), updateLicenseSaga),
  ]);
}
