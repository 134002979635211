// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { Collapse, Spin } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import { Text } from 'src/components/styles/Texts';

import { SURVEY_TYPE, TEST_TYPE } from 'src/constants/common';

import * as Style from './styles';
import history from 'src/utils/history';

interface ISurveyAndTestProps {
  courseDetail: {
    data: {
      surveys: {
        id: number;
        item: {
          surveyType: string;
        };
        unit: {
          name: string;
          category: {
            name: string;
          };
        };
      }[];
      tests: {
        id: number;
        item: {
          testType: string;
          questionsCount: number;
        };
        unit: {
          name: string;
          category: {
            name: string;
          };
        };
      }[];
    };
    load: boolean;
  };
  activeTabKey: string
}

const SurveyAndTest: React.FC<ISurveyAndTestProps> = ({
  courseDetail,
  activeTabKey
}) => {

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey
      }
    })
  }, [activeTabKey])

  function renderSurveyContainer() {
    if (!courseDetail.load && isEmpty(courseDetail.data.surveys)) return null;
    return (
      <Style.CourseItemsContainer>
        <Text xxxl style={{ marginBottom: 16 }}>アンケート</Text>
        <Spin spinning={courseDetail.load}>
          <Collapse
            bordered={false}
            defaultActiveKey={isEmpty(courseDetail.data.surveys) ? ['noData'] : []}
            expandIconPosition="right"
            expandIcon={
              ({ isActive }) => isActive ? <MinusOutlined style={{ color: 'white' }} /> : <PlusOutlined style={{ color: 'white' }} />
            }
            style={{ backgroundColor: '#0D122D', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <Collapse.Panel
              key={!isEmpty(courseDetail.data.surveys) ? 'surveys' : 'noData'}
              header={
                <Style.CollapseHeader>
                  <div style={{ width: '20%' }}>
                    <Text white lg w6>アンケート種別</Text>
                  </div>
                  <div style={{ width: '70%' }}>
                    <Text white w6>アンケート名</Text>
                  </div>
                </Style.CollapseHeader>
              }
            >
              {renderSurveyItems()}
            </Collapse.Panel>
          </Collapse>
        </Spin>
      </Style.CourseItemsContainer>
    );
  }

  function renderSurveyItems() {
    return courseDetail.data.surveys.map((survey, unitIndex) => (
      <Style.CollapseItemContainer key={`survey-${survey.id}`}>
        <Style.CollapseItemContent width="20%">
          <Text lg w6>
            {SURVEY_TYPE[survey.item.surveyType]}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="70%">
          <Text
            lg
            truncate
            isPreWrap
            style={{ width: 'calc(100% - 24px)' }}
          >
            {survey.unit.name}
          </Text>
        </Style.CollapseItemContent>
      </Style.CollapseItemContainer>
    ));
  }

  function renderTestContainer() {
    if (!courseDetail.load && isEmpty(courseDetail.data.tests)) return null;
    return (
      <Style.CourseItemsContainer>
        <Text xxxl style={{ marginBottom: 16 }}>テスト</Text>
        <Spin spinning={courseDetail.load}>
          <Collapse
            bordered={false}
            defaultActiveKey={isEmpty(courseDetail.data.tests) ? ['noData'] : []}
            expandIconPosition="right"
            expandIcon={
              ({ isActive }) => isActive ? <MinusOutlined style={{ color: 'white' }} /> : <PlusOutlined style={{ color: 'white' }} />
            }
            style={{ backgroundColor: '#0D122D', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <Collapse.Panel
              key={!isEmpty(courseDetail.data.tests) ? 'tests' : 'noData'}
              header={
                <Style.CollapseHeader>
                  <div style={{ width: '20%' }}>
                    <Text white lg w6>テスト種別</Text>
                  </div>
                  <div style={{ width: '55%' }}>
                    <Text white lg w6>レッスン名</Text>
                  </div>
                  <div style={{ width: '15%' }}>
                    <Text white lg w6>問題数</Text>
                  </div>
                </Style.CollapseHeader>
              }
            >
              {renderTestItems()}
            </Collapse.Panel>
          </Collapse>
        </Spin>
      </Style.CourseItemsContainer>
    );
  }

  function renderTestItems() {
    return courseDetail.data.tests.map((test, unitIndex) => (
      <Style.CollapseItemContainer key={`test-${test.id}`}>
        <Style.CollapseItemContent width="20%">
          <Text lg w6>
            {TEST_TYPE[test.item.testType]}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="55%">
          <Text
            lg
            style={{ width: 'calc(100% - 24px)' }}
          >
            {test.unit.name}
          </Text>
        </Style.CollapseItemContent>
        <Style.CollapseItemContent width="15%">
          <Text lg truncate>
            {`${test.item.questionsCount}問`}
          </Text>
        </Style.CollapseItemContent>
      </Style.CollapseItemContainer>
    ));
  }

  return (
    <>
      {renderSurveyContainer()}
      {renderTestContainer()}
    </>
  );
};

export default SurveyAndTest;
