// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Divider, Button, Row, Col } from 'antd';

import history from 'src/utils/history';

import { ServicePath } from 'src/constants/routerConstants';

import * as Style from './styles';

interface IHeaderMenuItemProps {
  setIsShowMenuDropdown(params): void;
  headerMenuIndex: number;
}

const HeaderMenuItem: React.FC<IHeaderMenuItemProps> = ({
  setIsShowMenuDropdown,
  headerMenuIndex,
}) => {
  function handleRedirectLink(path) {
    setIsShowMenuDropdown(-1);
    return history.push(path);
  }

  switch (headerMenuIndex) {
  case 0: { // Course
    return (
      <Style.HeaderItemMenu style={{ width: 300 }}>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          コース
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CourseList)}>
              コース一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CreateCourse)}>
              コース新規作成
            </Button>
          </Col>
        </Row>
      </Style.HeaderItemMenu>
    );
  }
  case 1: { // Unit
    return (
      <Style.HeaderItemMenu style={{ width: 350 }}>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          レッスン
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.UnitList)}>
              レッスン一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CreateUnit)}>
              レッスン新規作成
            </Button>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          動画
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.VideoList)}>
              動画一覧
            </Button>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          テスト
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.TestList)}>
              テスト一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CreateTest)}>
              テスト新規作成
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.UploadAnswerList)}>
              テスト採点
            </Button>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          アンケート
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.SurveyList)}>
              アンケート一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CreateSurvey)}>
              アンケート新規作成
            </Button>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          Tips
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.TipList)}>
              Tips一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CreateTip)}>
              Tips新規作成
            </Button>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          FAQ
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.FAQList)}>
              FAQ一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CreateFAQ)}>
              FAQ新規作成
            </Button>
          </Col>
        </Row>
      </Style.HeaderItemMenu>
    );
  }
  case 2: { // Account Manager
    return (
      <Style.HeaderItemMenu style={{ width: 350 }}>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          一覧
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CompanyList)}>
              企業一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.ManagerAccount,
                state: { key: '7' },
              })}
            >
              ユーザー一覧
            </Button>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          アカウント登録
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.ManagerAccount,
                state: { key: '1' },
              })}
            >
              受講者登録
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.ManagerAccount,
                state: { key: '3' },
              })}
            >
              企業管理者登録
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.ManagerAccount,
                state: { key: '4' },
              })}
            >
              サービス管理者登録
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.ManagerAccount,
                state: { key: '5' },
              })}
            >
              企業登録
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.ManagerAccount,
                state: { key: '6' },
              })}
            >
              登録状況
            </Button>
          </Col>
        </Row>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          ライセンス
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.LicenseList)}>
              ライセンス一覧
            </Button>
          </Col>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.CreateLicense)}>
              新規ライセンス設定
            </Button>
          </Col>
        </Row>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={12}>
            <Button type="link" onClick={() => handleRedirectLink(ServicePath.LicenseGrant)}>
              ライセンス付与
            </Button>
          </Col>
        </Row>
      </Style.HeaderItemMenu>
    );
  }
  case 3: { // Master Setting
    return (
      <Style.HeaderItemMenu style={{ width: 350 }}>
        <Divider
          orientation="left"
          style={{ margin: 0, fontWeight: 600 }}
        >
          設定
        </Divider>
        <Row gutter={8} style={{ margin: '0 8px' }}>
          <Col span={10}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.MasterSetting,
                state: { key: '1' },
              })}
            >
              グループ設定
            </Button>
          </Col>
          <Col span={14}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.MasterSetting,
                state: { key: '2' },
              })}
            >
              タグ設定
            </Button>
          </Col>
          <Col span={10}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.MasterSetting,
                state: { key: '3' },
              })}
            >
              プラン設定
            </Button>
          </Col>
          <Col span={10}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.MasterSetting,
                state: { key: '4' },
              })}
            >
              講師設定
            </Button>
          </Col>
          <Col span={14}>
            <Button
              type="link"
              onClick={() => handleRedirectLink({
                pathname: ServicePath.MasterSetting,
                state: { key: '5' },
              })}
            >
              プロフィールマスタ設定
            </Button>
          </Col>
        </Row>
      </Style.HeaderItemMenu>
    );
  }
  default:
    return <Style.HeaderItemMenu />;
  }
};

export default HeaderMenuItem;
