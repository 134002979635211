// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Col } from 'antd';

export const CourseOverviewContainer = styled.div`
  margin: 16px 0 10px;
  min-height: 150px;

  p, td   {
    color: ${(props) => props.theme.colors.headerText} !important;
  }
`;

export const OverviewCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const LogoCourse = styled.div`
  width: 78px;
  height: 78px;
  margin-right: 10px;

  img {
    width: 78px;
    height: 78px;
    object-fit: scale-down;
  }
`;

export const CourseNameBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RecommendDateBlock = styled.div`
  position: absolute;
  left: -30px;

  width: 324px;
  height: 100%;
`;

export const RecommendDateWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #cccccc;
  border-radius: 4px;
`;
