//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { isNumber } from 'lodash/lang';
import { isEmpty } from 'lodash';
import { Progress, Space } from 'antd';

import { Text, SkeletonLoading } from 'src/components/styles';

import * as Style from './styles';
import { convertSecondTime, formatDateTime } from 'src/utils/dateTime';
import { DATE_FORMAT } from 'src/constants/common';
import { randomLoadingWidth } from 'src/utils/common';

interface ICourseOverviewMobileProps {
  courseTopDetail: {
    data: {
      name: string;
      overview: string;
      startDate: string;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      recommendedStartDate: string;
      recommendedEndDate: string;
      remainingDays: number;
      courseCategory: {
        name: string;
      };
      isDisplayLogo: boolean;
      logo:{
        logoName: string;
        logoUrl: string;
      };
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      totalVideosDuration: number;
      testInfo?: {
        highestTestScore: number;
        usersRank: number;
        totalUsers: number;
      };
      tests: any;
    };
    load: boolean;
  };
  courseTopDetailStatus: {
    data: {
      avgLearningTime: number;
      avgLearningDays: number;
      studentProgressPercentage: number | null;
      avgTestScorePercentage: {
        finishTest: number | null | string;
        preTest: number | null | string;
        verifyTest: number | null | string;
      },
      latestUnitVersion: {
        unitVersion: {
          id: number;
          item: {
            type: string;
            thumbnailUrl: string;
          };
          unit: {
            name: string;
          }
        };
        course: {
          chapterId: number;
          groupId: number;
          id: number;
          name: string;
        }
      }
    };
    load: boolean;
  };
  backgroundHeight?: number;
}

const CourseOverviewMobile: React.FC<ICourseOverviewMobileProps> = ({
  courseTopDetail,
  courseTopDetailStatus,
}) => {
  const {
    startDate,
    endDate,
    remainingDays,
    totalVideosDuration,
    testInfo,
    tests,
  } = courseTopDetail.data;
  const {
    avgLearningTime,
    avgLearningDays,
    studentProgressPercentage,
  } = courseTopDetailStatus.data;

  return <Style.CourseOverviewContainer>
  <Style.CourseInfo>
    {courseTopDetailStatus.load
    ? (<Space size={36}>
      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
      <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
    </Space>)
    : (<Space>
      <Style.TextLabel>
        学習日数
      </Style.TextLabel>
      <Text md w4 isPreWrap>
        {isNumber(avgLearningDays)
          ? `${avgLearningDays}日`
          : 'ー'
        }
      </Text>
    </Space>)}
  </Style.CourseInfo>
  <Style.CourseInfo>
  {courseTopDetailStatus.load
    ? (<Space size={36}>
      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
      <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
    </Space>)
    : (<Space>
      <Style.TextLabel>
        学習時間
      </Style.TextLabel>
      <Text md w4 isPreWrap>
        {avgLearningTime > 0
          ? `${convertSecondTime(avgLearningTime, 'H時間m分')}`
          : '0時間0分'
        }
      </Text>
    </Space>)}
  </Style.CourseInfo>
  <Style.CourseInfo>
  {courseTopDetailStatus.load
    ? (
        <Space size={36}>
          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
          <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
        </Space>
      )
    : (
      <Space>
        <Style.TextLabel>
          動画時間
        </Style.TextLabel>
        <Text md w4 isPreWrap>
          {totalVideosDuration > 0
            ? `${convertSecondTime(totalVideosDuration, 'H時間m分')}`
            : '0時間0分'
          }
        </Text>
      </Space>
    )}
  </Style.CourseInfo>
  <Style.CourseInfo>
  {courseTopDetailStatus.load
    ? (
        <Space size={36}>
          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
          <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
        </Space>
      )
    : (
      <Space>
        <Style.TextLabel>
          進捗率
        </Style.TextLabel>
        <Text md w4 isPreWrap>
          {isNumber(studentProgressPercentage)
            ? `${studentProgressPercentage}%`
            : 'ー'
          }
        </Text>
        <Progress showInfo={false} strokeWidth={12} percent={studentProgressPercentage || 0} />
      </Space>
    )}
  </Style.CourseInfo>
  {!isEmpty(tests) ?
    <Style.CourseInfo>
    {courseTopDetailStatus.load
      ? (<Space size={36}>
        <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
        <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
      </Space>)
      : (<Space>
        <Style.TextLabel>
          {`テスト正答率
          （全テスト平均）`}
        </Style.TextLabel>
        <Text md w4 isPreWrap>
          {isEmpty(testInfo) || testInfo?.highestTestScore === null
            ? 'ー'
            : `${testInfo?.highestTestScore}%[${testInfo?.usersRank}位/${testInfo?.totalUsers}人]`
          }
        </Text>
      </Space>)}
    </Style.CourseInfo>
    : null
  }
  <Style.CourseInfo>
  {courseTopDetail.load
    ? (<Space size={36}>
      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
      <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
    </Space>)
    : (<Space>
      <Style.TextLabel>
        開始日
      </Style.TextLabel>
      <Text md w4>
        {startDate ? formatDateTime(startDate, DATE_FORMAT) : 'ー'}
      </Text>
    </Space>)}
  </Style.CourseInfo>
  <Style.CourseInfo>
  {courseTopDetail.load
    ? (<Space size={36}>
      <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
      <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
    </Space>)
    : (<Space>
      <Style.TextLabel>
        終了日
      </Style.TextLabel>
      <Space>
        <Text md>
          {endDate ? formatDateTime(endDate, DATE_FORMAT) : 'ー'}
        </Text>
        {endDate && (
          <Text md >
            [残り{remainingDays > 0 ? remainingDays : 0}日]
          </Text>
        )}
      </Space>
    </Space>)}
  </Style.CourseInfo>
  {(courseTopDetail.data.recommendedStartDate && courseTopDetail.data.recommendedEndDate)
    ? <Style.CourseInfo>
      {courseTopDetail.load
        ? (<Space size={36}>
          <SkeletonLoading active paragraph={false} title={{ width: 120 }} height={12} />
          <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 150) }} />
        </Space>)
        : (<Space>
          <Style.TextLabel>
            完了推奨期間
          </Style.TextLabel>
          <Text md w4>
            {`${formatDateTime(courseTopDetail.data.recommendedStartDate, DATE_FORMAT)} ~ ${formatDateTime(courseTopDetail.data.recommendedEndDate, DATE_FORMAT)}`}
          </Text>
        </Space>)}
      </Style.CourseInfo>
    : null
  }
</Style.CourseOverviewContainer>
}

export default CourseOverviewMobile;
