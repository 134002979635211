//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  Tooltip,
  Button,
  Progress,
  Space,
} from 'antd';
import { isEmpty, isNumber } from 'lodash/lang';

import { formatDateTime } from 'src/utils/dateTime';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import history from 'src/utils/history';

import { FilterSidebar } from 'src/components/layouts/Sidebar';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import { handleStopPropagation } from 'src/utils/common';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles';

import {
  getCompanyContractList as getCompanyContractListAction,
  getAccountList as getAccountListAction,
  banAccount as banAccountAction,
  showModal as showModalAction,
  closeModal as closeModalAction,

} from 'src/redux/actions';

import { ServicePath } from 'src/constants/routerConstants';

import { DATE_FORMAT, ACCOUNT_ROLES } from 'src/constants/common';
import { convertSecondTime } from 'src/utils/dateTime';
import { LoginStorage } from 'src/utils/localStorge';

import * as Style from './styles';

interface IRegistrationStatusProps extends StateProps, DispatchProps {
  getCompanyContractList(): void;
  getAccountList(params): void;
  showModal(params): void;
  banAccount(params): void;
  closeModal(): void;
  activeTabKey: string;
  companyContracts: {
    data: {
      id: number,
      contractName: string,
    }[],
  };
  accountList: {
    data: {
      id: number;
      jituName: string;
      email: string,
      lastLearningDate: string;
      numberOfCourses: number;
      department: string;
      status: string;
      jobType: string;
      jobTitle: string;
      totalLearningTime: number;
      entireCoursesHighestTestScore: string;
      entireCoursesPercentage: number;
      roles: string[],
      courses: {
        courseCategory: {
          name: string;
        };
        name: string;
      }[];
      company: {
        id: number;
        contractName: string;
      }[];
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: object[];
  };
}

const RegistrationAccountList: React.FC<IRegistrationStatusProps> = ({
  getCompanyContractList,
  getAccountList,
  showModal,
  banAccount,
  companyContracts,
  accountList,
  activeTabKey,
  closeModal
}) => {
  const filterParamsUrl = JSON.parse(history.location.state?.filterParams || '{}');
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const { data, meta } = accountList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});
  const userStorage = LoginStorage.getData();

  useEffect(() => {
    setParamsFilter(filterParamsUrl || {})
    setSortCondition(sortConditionUrl || {})
    if (activeTabKey === '7') {
      getCompanyContractList();
      getAccountList({ page: 1,...pageUrl, items: pageUrl?.limit, ...filterParamsUrl, sort: sortConditionUrl });
    }
    return () => {
      closeModal()
    }
  }, [activeTabKey])

  useEffect(() => {
    history.replace({
      state: {
        key: activeTabKey,
        filterParams: JSON.stringify(filterParams),
        sortCondition,
        page: meta.pageInfo,
        isSort: true,
      }
    })
  }, [sortCondition, filterParams, meta.pageInfo])

  function renderCoursesOfUser(coursesNames) {
    if (isEmpty(coursesNames)) return null;
    return coursesNames.map((name, courseIndex) => (
      <Text white key={`course-name-${courseIndex}`}>・{name}</Text>
    ));
  }

  function handleConfirmBanAccount(e, userId) {
    handleStopPropagation(e);
    const modalProps = {
      isConfirm: true,
      modalData: {
        message: 'このユーザーを非アクティブしてもよろしいですか。',
        title: 'ユーザの非アクティブ化',
      },
      modalAction: {
        confirm: () => banAccount({ id: userId }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '最終学習日',
    },
    {
      type: 'select',
      fieldParams: 'companyId',
      title: '契約名',
      options: companyContracts.data.map((company) => ({ id: company.id, name: company.contractName })),
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: '名前',
    },
    {
      type: 'multiSelect',
      title: '権限',
      fieldParams: 'roles',
      options: [
        { id: 'student', name: '受講者' },
        { id: 'admin_company', name: '企業管理者' },
        { id: 'admin_service', name: 'サービス管理者' },
      ],
    },
    {
      type: 'textInput',
      fieldParams: 'email',
      title: 'メールアドレス',
    },
    {
      type: 'inputNumber',
      title: 'コース数',
    },
  ];

  function getListCourseName(courses) {
    if (isEmpty(courses)) return [];
    return courses.map((course) => `${course.courseCategory.name}`);
  }

  const tableData = data.map((user: any) => {
    const newRoles = !isEmpty(user.roles)
      ? (user.roles || []).map((roleType) => ACCOUNT_ROLES[roleType]).toString()
      : user.roles;
    return {
      ...user,
      key: user.id,
      id: user.id,
      contractName: user.company.contractName,
      roles: newRoles,
      entireCoursesPercentage: user.entireCoursesPercentage || 0,
      entireCoursesHighestTestScore: user.entireCoursesHighestTestScore,
      lastLearningDate: formatDateTime(user.lastLearningDate, DATE_FORMAT),
      numberOfCourses: user.numberOfCourses,
      totalLearningTime: convertSecondTime(user.totalLearningTime, 'HH:mm:ss'),
      status: user.status,
      coursesNames: getListCourseName(user.courses),
    };
  });


  const columns = [
    {
      title:
        <SortableHeader
          type="jituName"
          title="名前"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      dataIndex: 'jituName',
      render: (_, record) => (
        <Style.AccountName>
          <TooltipParagraph isPreWrap rows={2} {...(record.status === 'banned' && { style: { marginTop: 14 } } )}>
            {record.jituName}
          </TooltipParagraph>
          {record.status === 'banned' && (
            <Style.AccountStatus>停止</Style.AccountStatus>
          )}
        </Style.AccountName>
      ),
      width: 350,
    },
    {
      title:
        <SortableHeader
          type="email"
          title="メールアドレス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      dataIndex: 'email',
      width: 350,
    },
    {
      title:
        <SortableHeader
          type="contractName"
          title="契約名"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      dataIndex: 'contractName',
      render: (_, record) => <TooltipParagraph isPreWrap rows={2}>{record.contractName}</TooltipParagraph>,
      width: 300,
    },
    {
      title: 'アカウント権限',
      dataIndex: 'roles',
      width: 250,
    },
    {
      title:
        <SortableHeader
          type="numberOfCourses"
          title="コース数"
          page={meta.pageInfo?.page}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      dataIndex: 'numberOfCourses',
      render: (_, record) => (
        <Tooltip
        placement="right"
        title={renderCoursesOfUser(record.coursesNames)}
        overlayStyle={{ maxHeight: 600, overflow: 'scroll'}}
        >
          <Text>{record.numberOfCourses}</Text>
        </Tooltip>
      ),
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      dataIndex: 'lastLearningDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="highestTestScore"
          title={
            <div>
              <Text white style={{ marginLeft: '2px' }}>テスト正答率</Text>
              <Text white style={{ marginLeft: '-10px' }}>（全テスト平均）</Text>
            </div>
          }
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>
            {isNumber(record.entireCoursesHighestTestScore) ? `${record.entireCoursesHighestTestScore}%` : 'ー'}
          </Text>
        </Space>
      ),
      dataIndex: 'entireCoursesHighestTestScore',
      width: 180,
    },
    {
      title:
        <SortableHeader
          type="coursesPercentage"
          title="進捗率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getAccountList}
          setAction={setSortCondition}
        />,
      dataIndex: 'entireCoursesPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.entireCoursesPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.entireCoursesPercentage} />
        </Space>
      ),
      width: 150,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Style.BanAction>
          <Button
            size="small"
            type="primary"
            danger
            ghost
            onClick={(e) => handleConfirmBanAccount(e, record.id)}
            disabled={record.status === 'banned' || userStorage.user.id === record.id}
          >
            非アクティブ
          </Button>
        </Style.BanAction>
      ),
      width: 150,
    },
  ];

  return (
    <>
      <Style.AccountListTitle>
        <Button
          type="primary"
          onClick={() => history.push({
            pathname: ServicePath.LicenseGrant,
          })}
        >
          ライセンス付与
        </Button>
      </Style.AccountListTitle>
      <Style.AccountListContainer>
        <Style.AccountListSidebar>
          <FilterSidebar
            data={dataFilterFields}
            actionFilter={getAccountList}
            setParamsFilter={setParamsFilter}
            setSortCondition={setSortCondition}
            pageSize={meta.pageInfo?.limit}
            filterParams={filterParams}
          />
        </Style.AccountListSidebar>
        <Style.AccountListContent>
          <Table
            className="table-cursor-pointer"
            pagination={false}
            scroll={{ x: 2080, y: 506 }}
            columns={columns}
            dataSource={tableData}
            onRow={(record) => ({
              onClick: () => {
                const urlProfile = userStorage.user.id === record.id ? 'my-profile' : `profile/${record.id}`;
                history.push(`/admin-service/${urlProfile}`);
              },
            })}
            locale={{ emptyText: 'データが存在しません。' }}
            loading={accountList.load}
          />
          {!isEmpty(accountList.data) && (
            <PaginationLayout
              pageInfo={meta.pageInfo}
              onChangePage={getAccountList}
              filterParams={filterParams}
              sortCondition={sortCondition}
            />
          )}
        </Style.AccountListContent>
      </Style.AccountListContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { companyContracts } = state.companyReducer;
  const { accountList } = state.accountReducer;
  return {
    companyContracts,
    accountList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  getAccountList: (params) => dispatch(getAccountListAction(params)),
  banAccount: (params) => dispatch(banAccountAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RegistrationAccountList);
