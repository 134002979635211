// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';

import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  MODAL_ACTION,
  FAILURE } from '../../constant';

import { questionServices } from 'src/services';

function* getQuestionListSaga(action) {
  try {
    const result = yield questionServices.getQuestionList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.QUESTION_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.QUESTION_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getCourseQuestionDetailSaga(action) {
  try {
    const result = yield questionServices.getCourseQuestionDetail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.QUESTION_ACTION.GET_DETAIL),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.QUESTION_ACTION.GET_DETAIL), errors: e.errors });
  }
}

function* selectQuestionStatusSaga(action) {
  try {
    const result = yield questionServices.selectQuestionStatus(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.QUESTION_ACTION.SELECT_QUESTION_STATUS),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.QUESTION_ACTION.SELECT_QUESTION_STATUS), errors: e.errors });
  }
}

export default function* questionSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.QUESTION_ACTION.GET_LIST), getQuestionListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.QUESTION_ACTION.GET_DETAIL), getCourseQuestionDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.QUESTION_ACTION.SELECT_QUESTION_STATUS), selectQuestionStatusSaga),
  ]);
}
