// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { STUDENT, REQUEST } from '../../constant';

export function getStudentConfirmationStatus(payload) {
  return {
    type: REQUEST(STUDENT.ACCOUNT_ACTION.GET_CONFIRMATION_STATUS),
    payload,
  };
}

export function studentConfirmAccount(payload) {
  return {
    type: REQUEST(STUDENT.ACCOUNT_ACTION.CONFIRM),
    payload,
  };
}
