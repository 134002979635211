//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { renderLinkInText } from 'src/utils/common';
import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';
import { FilterSidebar } from 'src/components/layouts/Sidebar';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import {
  getCategoryList as getCategoryListAction,
  getSurveyList as getSurveyListAction,
  getSurveyDetail as getSurveyDetailAction,
} from 'src/redux/actions';

import { ServicePath } from 'src/constants/routerConstants';
import { DATE_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

interface ISurveyListPageProps extends StateProps, DispatchProps {
  getCategoryList(): void;
  getSurveyList(params): void;
  getSurveyDetail(params): void;
  surveyList: {
    data: {
      id: number,
      name: string
      updatedAt: string;
      surveyType: string;
      url: string;
      category: {
        id: number,
        name: string,
      }[],
      tags: {
        id: number,
        name: string,
      }[],
    }[],
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[],
  };
}

const SurveyListPage: React.FC<ISurveyListPageProps> = ({
  getCategoryList,
  getSurveyList,
  getSurveyDetail,
  surveyList,
  categoryList,
}) => {
  const { meta } = surveyList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.SurveyList);

    getCategoryList();
    getSurveyList({ page: 1 });
  }, []);

  const tableData = surveyList.data.map((row: any) => {
    const newTags = !isEmpty(row.tags)
      ? row.tags.map((tag) => tag.name)
      : [];
    return {
      ...row,
      key: row.id,
      id: row.id,
      category: row.category?.name,
      tags: newTags.toString(),
      updatedAt: formatDateTime(row.updatedAt, DATE_FORMAT),
    };
  });

  const columns = [
    {
      title: <SortableHeader
        type="name"
        title="アンケート名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getSurveyList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <div
          onClick={() => getSurveyDetail({
            id: record.id,
            redirect: `/admin-service/survey/${record.id}/edit`,
          })}
          style={{ cursor: 'pointer' }}
        >
          <TooltipParagraph isPreWrap rows={2}>
            {record.name}
          </TooltipParagraph>
        </div>
      ),
      dataIndex: 'name',
      width: 300,
    },
    {
      title: <SortableHeader
        type="category"
        title="講座タイプ"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getSurveyList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <div
          onClick={() => getSurveyDetail({
            id: record.id,
            redirect: `/admin-service/survey/${record.id}/edit`,
          })}
          style={{ cursor: 'pointer' }}
        >
          <TooltipParagraph isPreWrap rows={2}>
            {record.category}
          </TooltipParagraph>
        </div>
      ),
      dataIndex: 'category',
      width: 130,
    },
    {
      title: 'Tags',
      render: (_, record) => (
        <div
          onClick={() => getSurveyDetail({
            id: record.id,
            redirect: `/admin-service/survey/${record.id}/edit`,
          })}
          style={{ cursor: 'pointer' }}
        >
          <TooltipParagraph isPreWrap rows={2}>
            {record.tags}
          </TooltipParagraph>
        </div>
      ),
      dataIndex: 'tags',
      width: 130,
    },
    {
      title: <SortableHeader
        type="url"
        title="アンケートURL"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getSurveyList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <Text
          headerText
          lg
          truncate
          dangerouslySetInnerHTML={{
            __html: renderLinkInText(record.url),
          }}
        />
      ),
      ellipsis: true,
      width: 300,
    },
    {
      title: <SortableHeader
        type="updatedAt"
        title="更新日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getSurveyList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <div
          onClick={() => getSurveyDetail({
            id: record.id,
            redirect: `/admin-service/survey/${record.id}/edit`,
          })}
          style={{ cursor: 'pointer' }}
        >
          <TooltipParagraph isPreWrap rows={2}>
            {record.updatedAt}
          </TooltipParagraph>
        </div>
      ),
      dataIndex: 'updatedAt',
      width: 130,
    },
  ];

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '更新日',
    },
    {
      type: 'select',
      title: '講座タイプ',
      fieldParams: 'category',
      options: categoryList.data,
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: 'アンケート名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getSurveyList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>アンケート一覧</Text>
          <Button type="primary" onClick={() => history.push(ServicePath.CreateSurvey)}>
            新規作成
          </Button>
        </TopContent>
        <Table
          pagination={false}
          columns={columns}
          scroll={{ x: 990, y: 506 }}
          dataSource={tableData}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={surveyList.load}
        />
        {!isEmpty(surveyList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getSurveyList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { categoryList } = state.categoryReducer;
  const { surveyList } = state.surveyReducer;
  return {
    categoryList,
    surveyList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCategoryList: () => dispatch(getCategoryListAction()),
  getSurveyList: (params) => dispatch(getSurveyListAction(params)),
  getSurveyDetail: (params) => dispatch(getSurveyDetailAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyListPage);
