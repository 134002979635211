// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';

import ModalFooter from 'src/components/Modal/components/ModalFooter';
import { Text } from 'src/components/styles';

import * as Style from './styles';

interface IConfirmModalProps {
  isConfirm: boolean;
  spaceEvenly?: boolean
  styleContent?: object
  isButtonLoading?: boolean
  modalData: {
    title: string;
    message: string;
    messageTwo?: string;
    okText?: string;
    cancelText?: string;
  };
  modalAction: {
    confirm(): void;
  };
  width?: string;
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  modalData,
  modalAction,
  spaceEvenly,
  styleContent,
  isButtonLoading,
  width,
}) => (
  <>
    <Style.ConfirmModalContainer style={styleContent}>
      <Style.Message w6>{modalData.message}</Style.Message>
      <Text w6>{modalData.messageTwo}</Text>
    </Style.ConfirmModalContainer>
    <ModalFooter
      onSubmit={() => modalAction.confirm()}
      okText={modalData.okText || "はい"}
      cancelText={modalData.cancelText || "いいえ"}
      isConfirm
      spaceEvenly={spaceEvenly}
      isButtonLoading={isButtonLoading}
      width={width}
    />
  </>
);

export default ConfirmModal;
