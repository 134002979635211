// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
// tslint:disable: max-line-length
import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import DefaultLayout from './components/layouts/DefaultLayout';
import LoginLayout from './components/layouts/LoginLayout';
import RegisterLayout from './components/layouts/RegisterLayout';
import ResetPasswordLayout from './components/layouts/ResetPasswordLayout';
import ErrorLayout from './components/layouts/ErrorLayout';
import CheckVideoLayout from './components/layouts/CheckVideoLayout';
import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  MyProfilePage,
  NotFoundPage,
  ServerErrorPage,
  CheckVideoPage,
  NotificationPage,

  ServicePage,
  CompanyPage,
  StudentPage,

  HealthCheckPage,
} from './pages';
import {
  ResetPasswordPath,
  NotFoundPath,
  ServerErrorPath,
  CheckVideoPath,

  ServicePath,
  CompanyPath,
  StudentPath,

  HealthCheckPath,
} from './constants/routerConstants';
import history from './utils/history';
import NotificationList from './pages/NotificationList';

const Routes = () => (
  <Router history={history}>
    <Switch>
      <ResetPasswordLayout exact path={ResetPasswordPath} component={ResetPasswordPage} />
      <ErrorLayout exact path={NotFoundPath} component={NotFoundPage} />
      <ErrorLayout exact path={ServerErrorPath} component={ServerErrorPage} />

      <LoginLayout exact role="admin_service" path={ServicePath.Login} component={LoginPage} />
      <ResetPasswordLayout exact role="admin_service" path={ServicePath.ForgotPassword} component={ForgotPasswordPage} />
      <DefaultLayout exact role="admin_service" path={ServicePath.AdminServiceTop} component={ServicePage.AdminServiceTop} />
      <DefaultLayout exact role="admin_service" path={ServicePath.VideoList} component={ServicePage.VideoList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.TestList} component={ServicePage.TestList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CreateTest} component={ServicePage.ModifyTest} />
      <DefaultLayout exact role="admin_service" path={ServicePath.EditTest} component={ServicePage.ModifyTest} />
      <DefaultLayout exact role="admin_service" path={ServicePath.UnitList} component={ServicePage.UnitList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CreateUnit} component={ServicePage.ModifyUnit} />
      <DefaultLayout exact role="admin_service" path={ServicePath.UnitDetail} component={ServicePage.ModifyUnit} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CreateCourse} component={ServicePage.ModifyCourse} />
      <DefaultLayout exact role="admin_service" path={ServicePath.EditCourse} component={ServicePage.ModifyCourse} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CourseList} component={ServicePage.CourseList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CourseDetail} component={ServicePage.CourseDetail} />
      <DefaultLayout exact role="admin_service" path={ServicePath.ModifyChapterFAQ} component={ServicePage.ModifyChapterFAQ} />
      <DefaultLayout exact role="admin_service" path={ServicePath.ManagerAccount} component={ServicePage.ManagerAccount} />
      <DefaultLayout exact role="admin_service" path={ServicePath.LicenseGrant} component={ServicePage.LicenseGrant} />
      <DefaultLayout exact role="admin_service" path={ServicePath.LicenseList} component={ServicePage.LicenseList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CreateLicense} component={ServicePage.ModifyLicense} />
      <DefaultLayout exact role="admin_service" path={ServicePath.EditLicense} component={ServicePage.ModifyLicense} />
      <DefaultLayout exact role="admin_service" path={ServicePath.QuestionList} component={ServicePage.QuestionList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.QuestionDetail} component={ServicePage.QuestionDetail} />
      <DefaultLayout exact role="admin_service" path={ServicePath.TipList} component={ServicePage.TipList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CreateTip} component={ServicePage.ModifyTip} />
      <DefaultLayout exact role="admin_service" path={ServicePath.EditTip} component={ServicePage.ModifyTip} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CompanyDetail} component={ServicePage.CompanyDetail} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CompanyList} component={ServicePage.CompanyList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.SurveyList} component={ServicePage.SurveyList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CreateSurvey} component={ServicePage.ModifySurvey} />
      <DefaultLayout exact role="admin_service" path={ServicePath.EditSurvey} component={ServicePage.ModifySurvey} />
      <DefaultLayout exact role="admin_service" path={ServicePath.PreSurveyDetail} component={ServicePage.SurveyDetail} />
      <DefaultLayout exact role="admin_service" path={ServicePath.FinalSurveyDetail} component={ServicePage.SurveyDetail} />
      <DefaultLayout exact role="admin_service" path={ServicePath.ConfirmTestOverview} component={ServicePage.TestOverview} />
      <DefaultLayout exact role="admin_service" path={ServicePath.ConfirmTestingForm} component={ServicePage.TestingForm} />
      <DefaultLayout exact role="admin_service" path={ServicePath.FinalTestOverview} component={ServicePage.TestOverview} />
      <DefaultLayout exact role="admin_service" path={ServicePath.FinalTestingForm} component={ServicePage.TestingForm} />
      <DefaultLayout exact role="admin_service" path={ServicePath.PreTestOverview} component={ServicePage.TestOverview} />
      <DefaultLayout exact role="admin_service" path={ServicePath.PreTestingForm} component={ServicePage.TestingForm} />
      <DefaultLayout exact role="admin_service" path={ServicePath.FAQList} component={ServicePage.FAQList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.CreateFAQ} component={ServicePage.CreateFAQ} />
      <DefaultLayout exact role="admin_service" path={ServicePath.EditFAQ} component={ServicePage.EditFAQ} />
      <DefaultLayout exact role="admin_service" path={ServicePath.UploadAnswerList} component={ServicePage.UploadAnswerList} />
      <DefaultLayout exact role="admin_service" path={ServicePath.MasterSetting} component={ServicePage.MasterSetting} />
      <DefaultLayout exact role="admin_service" path={ServicePath.ProfileDetail} component={ServicePage.ProfileDetail} />
      <DefaultLayout exact role="admin_service" path={ServicePath.PreviewQuestion} component={ServicePage.PreviewQuestion} />
      <DefaultLayout exact role="admin_service" path={ServicePath.NotificationList} component={NotificationPage} />
      <DefaultLayout exact role="admin_service" path={ServicePath.ServiceProfile} component={MyProfilePage} />
      <DefaultLayout role="admin_service" path={ServicePath.CourseView} component={ServicePage.CourseView} />
      <RegisterLayout exact role="admin_service" path={ServicePath.ConfirmServiceAccount} component={ServicePage.ConfirmServiceAccount} />

      <LoginLayout exact role="admin_company" path={CompanyPath.Login} component={LoginPage} />
      <ResetPasswordLayout exact role="admin_company" path={CompanyPath.ForgotPassword} component={ForgotPasswordPage} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.AdminCompanyTop} component={CompanyPage.AdminCompanyTop} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.CourseDetail} component={CompanyPage.CourseDetail} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.CompanyDetail} component={CompanyPage.CompanyDetail} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.MasterSetting} component={CompanyPage.MasterSetting} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.CompanyProfile} component={MyProfilePage} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.ManagerAccount} component={CompanyPage.ManagerAccount} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.LicenseGrant} component={CompanyPage.LicenseGrant} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.ProfileDetail} component={CompanyPage.ProfileDetail} />
      <RegisterLayout exact role="admin_company" path={CompanyPath.ConfirmAdminCompany} component={CompanyPage.ConfirmAdminCompany} />
      <RegisterLayout exact role="admin_company" path={CompanyPath.ConfirmCompanyAccount} component={CompanyPage.ConfirmCompanyAccount} />
      <DefaultLayout exact role="admin_company" path={CompanyPath.NotificationList} component={NotificationList} />

      <LoginLayout exact role="student" path={StudentPath.Login} component={LoginPage} />
      <ResetPasswordLayout exact role="student" path={StudentPath.ForgotPassword} component={ForgotPasswordPage} />
      <DefaultLayout exact role="student" path={StudentPath.StudentTop} component={StudentPage.StudentTop} />
      <DefaultLayout exact role="student" path={StudentPath.ConfirmTestOverview} component={StudentPage.TestOverview} />
      <DefaultLayout exact role="student" path={StudentPath.ConfirmTestingForm} component={StudentPage.TestingForm} />
      <DefaultLayout exact role="student" path={StudentPath.FinalTestOverview} component={StudentPage.TestOverview} />
      <DefaultLayout exact role="student" path={StudentPath.FinalTestingForm} component={StudentPage.TestingForm} />
      <DefaultLayout exact role="student" path={StudentPath.PreTestOverview} component={StudentPage.TestOverview} />
      <DefaultLayout exact role="student" path={StudentPath.PreTestingForm} component={StudentPage.TestingForm} />
      <DefaultLayout exact role="student" path={StudentPath.PreSurveyDetail} component={StudentPage.SurveyDetail} />
      <DefaultLayout exact role="student" path={StudentPath.FinalSurveyDetail} component={StudentPage.SurveyDetail} />
      <DefaultLayout role="student" path={StudentPath.CourseView} component={StudentPage.CourseView} />
      <DefaultLayout exact role="student" path={StudentPath.CourseDetail} component={StudentPage.CourseDetail} />
      <DefaultLayout exact role="student" path={StudentPath.StudentProfile} component={MyProfilePage} />
      <DefaultLayout exact role="student" path={StudentPath.NotificationList} component={NotificationPage} />
      <RegisterLayout exact role="student" path={StudentPath.ConfirmStudent} component={StudentPage.ConfirmStudent} />

      <CheckVideoLayout exact path={CheckVideoPath} component={CheckVideoPage} />
      <Route exact path={HealthCheckPath} component={HealthCheckPage} />
      <ErrorLayout component={NotFoundPage} />
    </Switch>
  </Router>
);

export default Routes;
