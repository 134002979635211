// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ACCOUNT_ACTION } from './account.constant';
import { CATEGORY_ACTION } from './category.constant';
import { COMPANY_ACTION } from './company.constant';
import { COURSE_ACTION } from './course.constant';
import { FAQ_ACTION } from './faq.constant';
import { PLAN_ACTION } from './plan.constant';
import { PROFILE_ACTION } from './profile.constant';
import { QUESTION_ACTION } from './question.constant';
import { STATISTIC_ACTION } from './statistic.constant';
import { SURVEY_ACTION } from './survey.constant';
import { TAG_ACTION } from './tag.constant';
import { TEACHER_ACTION } from './teacher.constant';
import { TEST_ACTION } from './test.constant';
import { TIP_ACTION } from './tip.constant';
import { UNIT_ACTION } from './unit.constant';
import { UPLOAD_ANSWER_ACTION } from './uploadAnswer.constant';
import { VIDEO_ACTION } from './video.constant';
import { LICENSE_ACTION } from './license.constant';

export const ADMIN_SERVICE = {
  ACCOUNT_ACTION,
  CATEGORY_ACTION,
  COMPANY_ACTION,
  COURSE_ACTION,
  FAQ_ACTION,
  PLAN_ACTION,
  PROFILE_ACTION,
  QUESTION_ACTION,
  STATISTIC_ACTION,
  SURVEY_ACTION,
  TAG_ACTION,
  TEACHER_ACTION,
  TEST_ACTION,
  TIP_ACTION,
  UNIT_ACTION,
  UPLOAD_ANSWER_ACTION,
  VIDEO_ACTION,
  LICENSE_ACTION,
};
