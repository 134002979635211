// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class AccountServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getConfirmationStatus(params) {
    return this.restClient.get('/confirmation_statuses', params);
  }

  inviteCompany(params) {
    return this.restClient.post('/admin_manage/companies', params);
  }

  inviteStudents(params) {
    return this.restClient.post('/admin_manage/students_invitations', params);
  }

  inviteServices(params) {
    return this.restClient.post('/admin_manage/admin_services_invitations', params);
  }

  inviteAdminCompany(params) {
    return this.restClient.post('/admin_manage/admin_companies_invitations', params);
  }

  getInvitationList(params) {
    return this.restClient.get('/admin_manage/registration/users', params);
  }

  getStudentsByCompany(params) {
    const { requestData, companyId } = params;
    return this.restClient.get(`/admin_manage/companies/${companyId}/register_course/users`, requestData);
  }

  resendInvitation(params) {
    return this.restClient.post('/admin_manage/resend_invitations', params);
  }

  cancelInvitation(params) {
    return this.restClient.post('/admin_manage/cancel_invitations', params);
  }

  addStudentsToCourse(params) {
    const { requestData, courseId } = params;
    return this.restClient.post(`/admin_manage/courses/${courseId}/courses_users`, requestData);
  }

  getAccountList(params) {
    return this.restClient.get('/admin_manage/users', params);
  }

  serviceConfirmAccount(params) {
    return this.restClient.post('/confirmations', params);
  }

  banAccount(params) {
    const { id } = params;
    return this.restClient.patch(`/admin_manage/users/${id}/ban`);
  }

  addStudentsToCourseByCsv(params) {
    const { requestData, courseId } = params;
    return this.restClient.post(`/admin_manage/courses/${courseId}/import_users_by_file`, requestData, {}, 'formData');
  }

  bulkResendInvitations(params) {
    return this.restClient.post('/admin_manage/resend_invitations/bulk_resend', params);
  }

  bulkCancelInvitations(params) {
    return this.restClient.post('/admin_manage/cancel_invitations/bulk_cancel', params);
  }
}
