// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { QuestionCircleOutlined } from "@ant-design/icons";

export const AccountName = styled.div`
  position: relative;
  cursor: pointer;
`;

export const AccountStatus = styled.span`
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  color: ${(props) => props.theme.colors.error};
`;

export const ButtonExportCsvWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 15px 0;
`;

export const QuestionCircleOutlinedCustom = styled(QuestionCircleOutlined)`
  font-size: 20px;
  margin: 5px;
`;
