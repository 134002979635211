// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const NotificationContainer = styled.div`
  margin: 30px auto 0;
  max-width: 1000px;
  width: 100%;
`;

export const NotificationContent = styled.div`
  margin-bottom: 16px;
  padding: 16px 16px 24px;
  border-radius: ${(props) => props.theme.radius.md};
  background-color: ${(props) => props.theme.colors.primaryBackground};
  cursor: pointer;

  &:hover {
    background-color: #fff8e7;
  }

  ${(props) => props.unread
    && css`
      background-color: white;
      box-shadow: ${props.theme.shadow.md};
    `
  }
`;

export const NotificationWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const NotificationTime = styled.div`
  position: absolute;
  bottom: -16px;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;

export const NotificationAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 16px 0 8px;
`;

export const ProfileAvatar = styled.div`
  min-width: 40px;
  height: 40px;

  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;
