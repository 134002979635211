// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { merge } from 'lodash/object';
import {
  STUDENT,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  courseDetail: {
    data: {
      studentProgressPercentage: 0,
      groups: [] as {
        id: number;
        name: string;
        chapters: {
          id: number;
          name: string;
          unitVersions: {
            id: string;
            isDone: boolean;
            item: object;
            unit: object;
          }[];
        }[];
      }[],
    },
    meta: {},
    load: true,
    errors: [] as object[],
  },
  courseTopDetail: {
    data: {
      plan: {},
      company: {},
      teachers: [],
      tags: [],
      category: {},
      surveys: [] as {
        id: number;
        item: object;
        unit: object;
        version: string;
      }[],
      tests: [] as {
        id: number;
        item: object;
        unit: object;
        version: string;
      }[],
      groups: [] as {
        id: number;
        name: string;
        totalUnits: number;
        totalVideosTime: number;
        chapters: {
          id: number;
          name: string;
          totalUnits: number;
          totalVideosTime: number;
          unitVersions: {
            id: string;
            item: object;
            unit: object;
          }[];
        }[];
      }[],
      contentOverview: {},
      lastUpdater: {},
      updatedAt: '',
    },
    meta: {},
    load: false,
    errors: [] as object[],
  },
  courseTopDetailStatus: {
    data: {},
    meta: {},
    load: false,
    errors: [],
  },
  recommendDates : {
    data: {
      groupRecommendedDate: []
    },
  }
};

export type StudentCourseReducer = Readonly<typeof initialState>;

export default (state: StudentCourseReducer = initialState, action: any): StudentCourseReducer => {
  switch (action.type) {
  case SUCCESS(STUDENT.COURSE_ACTION.GET_GROUP): {
    const { data, meta } = action.payload;
    let newGroups = [] as any;
    if (isEmpty(state.courseDetail.data.groups)) {
      newGroups = data.groups;
    } else {
      newGroups = state.courseDetail.data.groups;
    }
    const groupIndex = findIndex(newGroups, { id: data.group.id });
    newGroups.splice(groupIndex, 1, data.group);
    return {
      ...state,
      courseDetail: {
        data: {
          ...state.courseDetail.data,
          ...data.course,
          groups: newGroups,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.COURSE_ACTION.GET_GROUP): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case SUCCESS(STUDENT.COURSE_ACTION.UPDATE_COURSE_PROGRESS): {
    const { data, meta } = action.payload;
    const newGroups = state.courseDetail.data.groups;
    const groupIndex = findIndex(newGroups, { id: parseFloat(data.groupId) });
    const newChapters = newGroups[groupIndex]?.chapters;
    const chapterIndex = findIndex(
      newChapters,
      { id: parseFloat(data.chapterId) },
    );
    const newUnitVersions = newChapters[chapterIndex]?.unitVersions;
    const unitVersionIndex = findIndex(
      newUnitVersions,
      { id: parseFloat(data.unitVersionId) },
    );
    newGroups[groupIndex].chapters[chapterIndex].unitVersions[unitVersionIndex].isDone = meta.isDone;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        data: {
          ...state.courseDetail.data,
          groups: newGroups,
          studentProgressPercentage: meta.studentProgressPercentage,
        },
      },
    };
  }

  case FAILURE(STUDENT.COURSE_ACTION.UPDATE_COURSE_PROGRESS): {
    const { errors } = action;
    return {
      ...state,
      courseDetail: {
        ...state.courseDetail,
        errors,
      },
    };
  }

  case REQUEST(STUDENT.COURSE_ACTION.GET_DETAIL): {
    return {
      ...state,
      courseTopDetail: {
        ...state.courseTopDetail,
        load: true,
      },
    };
  }
  case SUCCESS(STUDENT.COURSE_ACTION.GET_DETAIL): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseTopDetail: {
        data: data.course,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.COURSE_ACTION.GET_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      courseTopDetail: {
        ...state.courseTopDetail,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(STUDENT.COURSE_ACTION.GET_TOP_GROUP_DETAIL): {
    const { data, meta } = action.payload;
    const newGroups = state.courseTopDetail.data.groups;
    const groupSelectedData = state.courseTopDetail.data.groups[meta.groupIndex];
    merge(groupSelectedData, { chapters: data.group.chapters });
    newGroups.splice(meta.groupIndex, 1, groupSelectedData);
    return {
      ...state,
      courseTopDetail: {
        data: {
          ...state.courseTopDetail.data,
          groups: newGroups,
        },
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.COURSE_ACTION.GET_TOP_GROUP_DETAIL): {
    const { errors } = action;
    return {
      ...state,
      courseTopDetail: {
        ...state.courseTopDetail,
        errors,
      },
    };
  }

  case REQUEST(STUDENT.COURSE_ACTION.GET_DETAIL_STATUS): {
    return {
      ...state,
      courseTopDetailStatus: {
        ...state.courseTopDetailStatus,
        load: true,
      },
    };
  }
  case SUCCESS(STUDENT.COURSE_ACTION.GET_DETAIL_STATUS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      courseTopDetailStatus: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.COURSE_ACTION.GET_DETAIL_STATUS): {
    const { errors } = action;
    return {
      ...state,
      courseTopDetailStatus: {
        ...state.courseTopDetailStatus,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(STUDENT.COURSE_ACTION.CLEAR_SIDEBAR_COURSE): {
    return {
      ...state,
      courseDetail: {
        data: {
          studentProgressPercentage: 0,
          groups: [],
        },
        load: false,
        meta: {},
        errors: [],
      },
    };
  }

  case REQUEST(STUDENT.COURSE_ACTION.GET_RECOMMEND_DATE): {
    return {...state}
  }
  case SUCCESS(STUDENT.COURSE_ACTION.GET_RECOMMEND_DATE): {
    const { data } = action.payload;
    return {
      ...state,
      recommendDates: {
        ...state.recommendDates,
        data: data
      }
    };
  }

  default:
    return state;
  }
};
