// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const TestOverviewContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  border-radius: ${(props) => props.theme.radius.sm};
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  overflow: hidden;
`;

export const TestOverviewTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const  TestOverviewDescription = styled.div`
  padding: 16px;
  background-color: white;
`;

export const TestOverviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;
