// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import moment from 'moment';
import 'moment-duration-format';

interface Duration extends moment.Duration {
  format(template?: string, precision?: number, settings?: DurationSettings): string;
}

interface DurationSettings {
  forceLength?: boolean;
  precision?: number;
  template?: string;
  trunc?: boolean;
  trim?: boolean | 'left' | 'right' | 'both';
}

export function formatDateTime(dateTime, formatType) {
  if (!dateTime) return null;
  return moment(dateTime).format(formatType);
}

export function convertSecondTime(seconds, convertType) {
  const duration = moment.duration(seconds, 'seconds') as Duration;
  return duration.format(convertType, 0, {
    trunc: true,
    trim: false,
  });
}

export const convertServerTime = (serverTime) => {
  // server time have format : 2022-05-19 20:58:49 +0900 
  // delete +0900 
  if (!serverTime) return moment().format("YYYYMMDD_HHmmss");
  let myArray = serverTime.split("");
  const index = myArray.findIndex(item => item === "+")
  myArray.splice(index,5)
  return formatDateTime((myArray || [])?.join(""), "YYYYMMDD_HHmmss")
}
