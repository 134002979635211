// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState } from 'react';
import { Space, Tag } from 'antd';
import { isNull, isEmpty } from 'lodash/lang';

import { Text, SkeletonLoading } from 'src/components/styles';

import * as Style from './styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import theme from 'src/constants/theme';
import { COURSE_PERIOD } from 'src/constants/common';
import history from 'src/utils/history';

interface ITopOverviewProps {
  currentTime: {
    data: string;
  };
  companyCoursesStatus: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        id: number;
        name: string;
      };
      recommendedSetting: boolean;
      periodStatus: number;
      behindSchedulePercentage: string;
      onSchedulePercentage: string;
      remainingDays: number;
    }[],
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
        next: number;
      };
    };
    load: boolean;
  }
  getCompanyCoursesStatus(currentPage): void
}

const TopOverview: React.FC<ITopOverviewProps> = ({
  companyCoursesStatus,
  getCompanyCoursesStatus,
}) => {
  const { data, load, meta } = companyCoursesStatus;

  function handleScrollCard({ target }) {
    if (target.clientWidth + target.scrollLeft >= target.scrollWidth - 50 && !load && meta?.pageInfo?.next) {
      getCompanyCoursesStatus({page: meta?.pageInfo?.page + 1});
    }
  }

  const remainingDays = (status: number, day: number) => {
    switch (status) {
      case COURSE_PERIOD.inRecommendedPeriod.status: {
        return (<Text xs w7 headerText>完了推奨日まで あと{day}日</Text>);
      }
      case COURSE_PERIOD.inCoursePeriod.status: {
        return (<Text xs w7 headerText>受講期限まで：あと{day}日</Text>);
      }
      case COURSE_PERIOD.unstartedCourse.status: {
        return (<Text xs w7 headerText>受講開始まで：あと{day}日</Text>);
      }
      case COURSE_PERIOD.endedCourse.status: {
        return (<Text xs w7 headerText></Text>);
      }
    }
  }

  const renderCoursePeriod = (status: number, width?: string, height?: string, fontSize?: string) => {
    let backgroundColor = "";
    let borderColor = "";
    let title = "";
    let maxWidth = width || "125px";
    let maxHeight = height || "25px";
    const fSize = fontSize || "12px";

    switch (status) {
      case COURSE_PERIOD.inRecommendedPeriod.status: {
        backgroundColor = theme.colors.primary20;
        borderColor = theme.colors.primary;
        title = COURSE_PERIOD.inRecommendedPeriod.title;
        break;
      }
      case COURSE_PERIOD.inCoursePeriod.status: {
        backgroundColor = theme.colors.primary15;
        borderColor = theme.colors.primary15Border;
        title = COURSE_PERIOD.inCoursePeriod.title;
        break;
      }
      case COURSE_PERIOD.endedCourse.status: {
        backgroundColor = theme.colors.secondaryBackground45;
        borderColor = theme.colors.secondaryBorder45;
        title = COURSE_PERIOD.endedCourse.title;
        break;
      }
      case COURSE_PERIOD.unstartedCourse.status: {
        backgroundColor = theme.colors.white;
        borderColor = theme.colors.secondaryBorder63;
        title = COURSE_PERIOD.unstartedCourse.title;
        break;
      }
      default:
        break;
    }

    return (
      <Style.TagCustom width={maxWidth} height={maxHeight}
        backgroundColor={backgroundColor} borderColor={borderColor}>
        <Text headerText style={{ fontSize:  fSize }}>{title}</Text>
      </Style.TagCustom>
    );
  }

  return (
    <>
      <Style.OverviewContainer>
        <Text headerText xxxl style={{ margin: '24px 0 16px' }}>
          コース状況
        </Text>
        <Style.TopOverviewWrapper onScroll={handleScrollCard}>
          {
            (!load && isEmpty(data)) 
              ? 
            <Style.TagNoData>
              <Text>データが存在しません。</Text>
            </Style.TagNoData>
            : 
            (
              data.map((item) => (
                <Style.CardWrapper key={item}>
                  <Style.OverviewCardContainer onClick={() => history.push({ pathname: `/admin/course/${item?.id}` })}>
                    <Style.OverviewCardContent>
                      <Style.TagItem marginBottom={'0px'}>
                        <Text>
                          <TooltipParagraph style={{fontSize: theme.fontSize.xs, color: theme.colors.headerText}}>{ item?.courseCategory?.name }</TooltipParagraph>
                        </Text>
                      </Style.TagItem>
                      <Style.TagItem>
                        <Text>
                          <TooltipParagraph style={{fontSize: '11px', color: theme.colors.headerText}}>{ item?.name }</TooltipParagraph>
                        </Text>
                      </Style.TagItem>
                      <Style.TagItem>
                        { renderCoursePeriod(item?.periodStatus, "100px", "20px", theme.fontSize.xxs) }
                      </Style.TagItem>
                      <Style.TagItem>
                        <Style.CustomTextRecommendSetting>
                          { !item?.recommendedSetting && item?.periodStatus !== COURSE_PERIOD.endedCourse.status && "▲完了推奨期間未設定" }
                        </Style.CustomTextRecommendSetting>
                      </Style.TagItem>
                      <Style.TagItem marginBottom={'9px'}>
                        <Text xs headerText>遅延ユーザー { item?.recommendedSetting }</Text>
                        <Style.TagTextCustom>
                          <Text w7 center headerText fontSize={theme.fontSize.xxxl}>{ isNull(item?.behindSchedulePercentage) ? "-" : `${item?.behindSchedulePercentage}%`}</Text>
                        </Style.TagTextCustom>
                      </Style.TagItem>
                      <Style.TagItem marginBottom={'16px'}>
                        <Text xs headerText style={{ marginRigth:  9}}>完了ユーザー</Text>
                        <Style.TagTextCustom>
                          <Text w7 center headerText fontSize={theme.fontSize.xxxl}>{ isNull(item?.onSchedulePercentage) ? "-" : `${item?.onSchedulePercentage}%`}</Text>
                        </Style.TagTextCustom>
                      </Style.TagItem>
                      <Space>
                        { remainingDays(item?.periodStatus, item?.remainingDays) }
                      </Space>
                    </Style.OverviewCardContent>
                  </Style.OverviewCardContainer>
                </Style.CardWrapper>
              ))
            )
          }
          {load && !isEmpty(data) && <Style.LoadingWrapper> <Style.SpinCustom /> </Style.LoadingWrapper>}
          {
            load && isEmpty(data) && [...Array(10)].map(item => (
              <Style.CardWrapper>
                <Style.OverviewCardContainer>
                  <Style.OverviewCardContent>
                    <Style.TagItem marginBottom={'0px'}>
                      <SkeletonLoading active paragraph={false} title={{ width: 155 }} />
                    </Style.TagItem>
                    <Style.TagItem>
                      <Text>
                        <SkeletonLoading active paragraph={false} title={{ width: 155 }} />
                      </Text>
                    </Style.TagItem>
                    <Style.TagItem>
                      <SkeletonLoading active paragraph={false} title={{ width: 155 }} />
                    </Style.TagItem>
                    <Style.TagItem marginBottom={'9px'}>
                      <SkeletonLoading active paragraph={false} title={{ width: 155 }} />
                    </Style.TagItem>
                    <Style.TagItem marginBottom={'16px'}>
                      <Style.TagTextCustom>
                        <SkeletonLoading active paragraph={false} title={{ width: 155 }} />
                      </Style.TagTextCustom>
                    </Style.TagItem>
                    <Space>
                      <SkeletonLoading active paragraph={false} title={{ width: 155 }} />
                    </Space> 
                  </Style.OverviewCardContent>
                </Style.OverviewCardContainer>
              </Style.CardWrapper>
            ))
          }
        </Style.TopOverviewWrapper>
      </Style.OverviewContainer>
      <Style.OverviewDescription>
        <Style.OverviewDescriptionItem>
          <Space style={{ marginRight: 16 }}>{ renderCoursePeriod(COURSE_PERIOD.inRecommendedPeriod.status) }</Space>
          <Text headerText xs>
            ：設定された完了推奨期間中であることを表します。完了推奨期間を設定・変更したい場合はスキルアップAIまでご連絡ください。
          </Text>
        </Style.OverviewDescriptionItem>
        <Style.OverviewDescriptionItem>
          <Space style={{ marginRight: 16 }}>{ renderCoursePeriod(COURSE_PERIOD.inCoursePeriod.status) }</Space>
          <Text headerText xs>
            ：完了推奨期間外だが、受講可能期間内であることを表します。
          </Text>
        </Style.OverviewDescriptionItem>
        <Style.OverviewDescriptionItem>
          <Space style={{ marginRight: 16 }}>{ renderCoursePeriod(COURSE_PERIOD.endedCourse.status) }</Space>
          <Text headerText xs>
            ：受講可能期間が終了したことを表します。
          </Text>
        </Style.OverviewDescriptionItem>
        <Style.OverviewDescriptionItem>
          <Space style={{ marginRight: 16 }}>{ renderCoursePeriod(COURSE_PERIOD.unstartedCourse.status) }</Space>
          <Text headerText xs>
            ：受講可能期間の開始前であることを表します。
          </Text>
        </Style.OverviewDescriptionItem>
      </Style.OverviewDescription>
    </>
  );
};

export default TopOverview;
