// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';

import {
  MODAL_ACTION,
  ADMIN_COMPANY,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

import { companyContractServices } from 'src/services';
import history from 'src/utils/history';

function* getCompanyDetailSaga(action) {
  try {
    const { redirect } = action.payload;
    const result = yield companyContractServices.getCompanyDetail();
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL),
      payload: result,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (redirect) yield history.push(redirect);
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getCompanyStudentsSaga(action) {
  try {
    const result = yield companyContractServices.getCompanyStudents(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_STUDENTS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_STUDENTS), errors: e.errors });
  }
}

function* getCompanyCoursesSaga(action) {
  try {
    const result = yield companyContractServices.getCompanyCourses(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES), errors: e.errors });
  }
}

function* getCompanyCoursesStatusSaga(action) {
  try {
    const result = yield companyContractServices.getCompanyCoursesStatus(action.payload);
    
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES_STATUS),
      payload: result,
    });
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES_STATUS),
      errors: e.errors,
    });
  }
}

function* getCompanyCustomInfosSaga() {
  try {
    const result = yield companyContractServices.getCompanyCustomInfos();
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS), errors: e.errors });
  }
}

function* updateCompanyCustomInfosSaga(action) {
  try {
    const result = yield companyContractServices.updateCompanyCustomInfos(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS), errors: e.errors });
  }
}

function* deleteCompanyCustomInfosSaga(action) {
  try {
    yield companyContractServices.deleteCompanyCustomInfos(action.payload);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS),
      payload: {
        data: {
          id: action.payload.id,
        },
      },
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS), errors: e.errors });
  }
}

function* updateCompanyInfosSaga(action) {
  const { requestData, requestAction } = action.payload;
  try {
    const result = yield companyContractServices.updateCompanyInfos(requestData);
    yield put({
      type: SUCCESS(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_INFOS),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
    yield requestAction.setIsEditForm(false);
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_INFOS), errors: e.errors });
  }
}

export default function* companyContractSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_DETAIL), getCompanyDetailSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_STUDENTS), getCompanyStudentsSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES), getCompanyCoursesSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_COURSES_STATUS), getCompanyCoursesStatusSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.GET_COMPANY_CUSTOM_INFOS), getCompanyCustomInfosSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_CUSTOM_INFOS), updateCompanyCustomInfosSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.DELETE_COMPANY_CUSTOM_INFOS), deleteCompanyCustomInfosSaga),
    takeLatest(REQUEST(ADMIN_COMPANY.COMPANY_ACTION.UPDATE_COMPANY_INFOS), updateCompanyInfosSaga),
  ]);
}
