//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';

import history from 'src/utils/history';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import CompanyInformation from './components/CompanyInformation';
import CompanyCourses from './components/CompanyCourses';
import CompanyStudents from './components/CompanyStudents';

import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';

import {
  getCompanyContractDetail as getCompanyContractDetailAction,
  getCompanyContractStudents as getCompanyContractStudentsAction,
  getCompanyContractCourses as getCompanyContractCoursesAction,
  updateCompanyContractInfos as updateCompanyContractInfosAction,
  getCompanyCourseDetail as getCompanyCourseDetailAction,
  getProfileInfo as getProfileInfoAction,
  getCompanyProfileDetail as getCompanyProfileDetailAction,
  getProvinceList as getProvinceListAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface ICompanyDetailPageProps extends StateProps, DispatchProps {
  getCompanyContractDetail(params): void;
  getCompanyContractStudents(params): void;
  getCompanyContractCourses(params): void;
  updateCompanyContractInfos(params): void;
  getCompanyCourseDetail(params): void;
  getProfileInfo(params): void;
  getCompanyProfileDetail(params): void;
  getProvinceList(): void;
  companyDetail: {
    data: {
      id: number;
      name: string;
      status: string;
      addressBuilding: string;
      addressCity: string;
      adminCompany: {
        id: number;
        jituName: string;
        department: string;
        phoneNumber: string;
        email: string;
      };
      contractName: string;
      departmentInCharge: string;
      fax: string;
      phoneNumber: string;
      postalCode: string;
      province: {
        id: number;
        name: string;
      };
      registerDate: string;
      licensesCount: number;
      remainingLicensesCount: number;
      sfa: string;
      customDisplayField: {
        department: boolean;
      };
    };
    load: boolean;
  };
  companyStudents: {
    data: {
      studentList: {
        id: number;
        jituName: string;
        email: string,
        numberOfCourses: number;
        courses: {
          courseCategory: {
            name: string;
          };
          name: string;
        }[];
        department: string;
        jobType: string;
        jobTitle: string;
        roles: string[],
        customUserInfos: {
          companyCustomUserInfoId: number;
          fieldValue: string;
        }[];
        lastLearningDate: number;
        totalLearningTime: number;
        entireCoursesHighestTestScore: number;
        entireCoursesPercentage: number;
      }[];
      companyCustomInfos: {
        id: number;
        fieldName: string;
        isDisplay: boolean;
      }[];
      customDisplayField: {
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  companyCourses: {
    data: {
      id: number;
      plan: {
        id: number;
        name: string;
      };
      name: string;
      courseCategory: {
        name: string;
      }
      totalLearningTime: number;
      usedLicensesPass: number;
      companyProgressPercentage: number;
      licensesCount: number;
      remainingLicensesCount: number;
      startDate: string;
      endDate: string;
      remainingDays: number;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  provinceList: {
    data: {
      id: number;
      name: string;
    }[];
  };
}

const CompanyDetailPage: React.FC<ICompanyDetailPageProps> = ({
  getCompanyContractDetail,
  getCompanyContractStudents,
  getCompanyContractCourses,
  updateCompanyContractInfos,
  getCompanyCourseDetail,
  getProfileInfo,
  getCompanyProfileDetail,
  getProvinceList,
  companyDetail,
  companyStudents,
  companyCourses,
  provinceList,
}) => {
  const [activeTabKey, setActiveTabKey] = useState<string>('1');
  const urlActiveKey = history.location.state?.key;

  useEffect(() => {
    if (urlActiveKey) setActiveTabKey(urlActiveKey);
  }, [urlActiveKey]);

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.CompanyDetail);

    if (!companyDetail.data.id) {
      getCompanyContractDetail({});
    }
    getProvinceList();
  }, []);

  return (
    <MainContainer isTabs>
      <MainContent fullView>
        <Style.CompanyDetailOverview>
          {companyDetail.load
            ? (
              <SkeletonLoading
                active
                paragraph={false}
                title={{ width: 300 }}
                height={24}
                margin="8px 0"
              />
            )
            : (
              <Text headerText xxxl w6 style={{ margin: '8px 0' }}>
                {companyDetail.data.contractName}
              </Text>
            )
          }
        </Style.CompanyDetailOverview>
        <Tabs
          activeKey={activeTabKey}
          type="card"
          onChange={(activeKey) => setActiveTabKey(activeKey)}
          style={{ marginBottom: 60 }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>基本情報</Text>}
            key="1"
          >
            <CompanyInformation
              companyDetail={companyDetail}
              provinceList={provinceList}
              updateCompanyContractInfos={updateCompanyContractInfos}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>コース</Text>}
            key="2"
          >
            <CompanyCourses
              companyDetail={companyDetail}
              companyCourses={companyCourses}
              getCompanyContractCourses={getCompanyContractCourses}
              getCompanyCourseDetail={getCompanyCourseDetail}
              activeTabKey={activeTabKey}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>ユーザー</Text>}
            key="3"
          >
            <CompanyStudents
              companyDetail={companyDetail}
              companyStudents={companyStudents}
              getCompanyContractStudents={getCompanyContractStudents}
              getCompanyProfileDetail={getCompanyProfileDetail}
              getProfileInfo={getProfileInfo}
              activeTabKey={activeTabKey}
            />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const {
    companyDetail,
    companyStudents,
    companyCourses,
  } = state.companyContractReducer;
  const { provinceList } = state.masterDataReducer;
  return {
    companyDetail,
    companyStudents,
    companyCourses,
    provinceList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractDetail: (params) => dispatch(getCompanyContractDetailAction(params)),
  getCompanyContractStudents: (params) => dispatch(getCompanyContractStudentsAction(params)),
  getCompanyContractCourses: (params) => dispatch(getCompanyContractCoursesAction(params)),
  updateCompanyContractInfos: (params) => dispatch(updateCompanyContractInfosAction(params)),
  getCompanyCourseDetail: (params) => dispatch(getCompanyCourseDetailAction(params)),
  getProfileInfo: (params) => dispatch(getProfileInfoAction(params)),
  getCompanyProfileDetail: (params) => dispatch(getCompanyProfileDetailAction(params)),
  getProvinceList: () => dispatch(getProvinceListAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailPage);
