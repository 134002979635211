// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Button,
  Form,
} from 'antd';
import { isEmpty, isNumber } from 'lodash/lang';

import { LoginStorage } from 'src/utils/localStorge';
import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import history from 'src/utils/history';
import { ServicePath } from 'src/constants/routerConstants';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import TipOverview from './components/TipOverview';
import TipContent from './components/TipContent';

import * as Style from './styles';

import {
  getTipDetail as getTipDetailAction,
  getTagList as getTagListAction,
  getCategoryList as getCategoryListAction,
  updateTip as updateTipAction,
  createTip as createTipAction,
} from 'src/redux/actions';

interface IModifyTipProps extends StateProps, DispatchProps {
  getTipDetail(params): void;
  getTagList(): void;
  getCategoryList(): void;
  updateTip(params): void;
  createTip(params): void;
  isDisableRequest: boolean;
  match: {
    params: {
      id?: string;
    };
  };
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  tipDetail: {
    data: {
      id: number;
      name: string;
      content: string;
      category: {
        id: number;
        name: string;
      };
      tags: {
        id: number;
        name: string;
      }[];
      lastUpdater: {
        jituName: string;
      };
      creator: {
        jituName: string;
      }
      updatedAt: string;
    };
  };
}

const ModifyTip: React.FC<IModifyTipProps> = ({
  getTipDetail,
  getTagList,
  getCategoryList,
  updateTip,
  createTip,
  match,
  tagList,
  categoryList,
  tipDetail,
  isDisableRequest
}) => {
  const userStorage = LoginStorage.getData();
  const tipId = match.params.id;
  const [tipForm] = Form.useForm();

  const tipInitialFormValues = tipId
    ? {
      name: tipDetail?.data.name,
      content: tipDetail?.data.content,
      creator: tipDetail?.data.creator?.jituName,
      categoryId: tipDetail?.data.category?.id,
      tagIds: tipDetail?.data.tags?.map((tag) => tag.id),
    }
    : { creator: userStorage?.user?.jituName };

  useEffect(() => {
    if (tipDetail?.data.id) {
      tipForm.resetFields();
    }
  }, [tipDetail?.data.id]);

  useEffect(() => {
    if (tipId) {
      document.title = PAGE_TITLE(ServiceTitle.EditTip);

      if (parseFloat(tipId) !== tipDetail.data.id) {
        getTipDetail({ id: tipId });
      }
    } else {
      document.title = PAGE_TITLE(ServiceTitle.CreateTip);
    }
    getTagList();
    getCategoryList();

    return () => {
      tipForm.resetFields();
    };
  }, [tipId]);

  function handleSubmitForm(values) {
    const requestData = {
      tip: {
        name: values.name.trim(),
        ...(values.tagIds && { tagIds: values.tagIds }),
        ...(isNumber(values.categoryId) && { categoryId: values.categoryId }),
        content: values.content.trim(),
      },
    };

    if (tipId && updateTip) {
      updateTip({ requestData, id: tipId });
    } else if (createTip) {
      createTip(requestData);
    }
  }

  return (
    <MainContainer>
      <MainContent fullView>
        <Form
          form={tipForm}
          initialValues={tipInitialFormValues}
          name="modifyTipForm"
          onFinish={(values) => handleSubmitForm(values)}
          scrollToFirstError={true}
        >
          <TipOverview
            tipId={tipId}
            tagList={tagList}
            categoryList={categoryList}
            tipDetail={tipDetail}
          />
          <TipContent
            {...(tipId && !isEmpty(tipDetail?.data.content)) && { content: tipDetail?.data.content }}
            tipForm={tipForm}
          />
          <Style.TipOverviewAction>
            <Space align="center" size="middle">
              <Button
                type="primary"
                htmlType="button"
                className="btn-secondary"
                onClick={() => history.push(ServicePath.TipList)}
              >
                キャンセル
              </Button>
              <Button htmlType="submit" type="primary" disabled={isDisableRequest} >
                保存する
              </Button>
            </Space>
          </Style.TipOverviewAction>
        </Form>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { tipDetail } = state.tipReducer;
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    tipDetail,
    tagList,
    categoryList,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTipDetail: (params) => dispatch(getTipDetailAction(params)),
  getTagList: () => dispatch(getTagListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  updateTip: (params) => dispatch(updateTipAction(params)),
  createTip: (params) => dispatch(createTipAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyTip);
