// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Space } from 'antd';

import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  MainContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles';

import { DATE_FORMAT, QUESTION_STATUS } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import SelectQuestionStatusModal from './components/SelectQuestionStatusModal';

import {
  getCourseQuestionDetail as getCourseQuestionDetailAction,
  closeModal as closeModalAction,
  showModal as showModalAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IQuestionDetailPageProps extends StateProps, DispatchProps {
  showModal(params: any): void;
  closeModal(): void;
  getCourseQuestionDetail(params): void;
  match: {
    params: {
      id?: string;
    };
  };
  questionDetail: {
    data: {
      id: number;
      name: string;
      createdAt: number;
      status: string;
      updatedAt: number;
      content: string;
      daysFromCreated: number;
      category: {
        id: number;
        name: string;
      },
      company: {
        id: number;
        contractName: string;
      },
      course: {
        id: number;
        name: string;
      },
      unit: {
        id: number;
        name: string;
      },
      user: {
        id: number;
        jituName: string;
      },
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    errors: string;
  };
}

const QuestionDetailPage: React.FC<IQuestionDetailPageProps> = ({
  showModal,
  closeModal,
  getCourseQuestionDetail,
  match,
  questionDetail,
}) => {
  const questionId = match.params.id;
  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.QuestionDetail);

    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    getCourseQuestionDetail({ id: questionId });
  }, [questionId]);

  function handleSelectQuestionStatusModal() {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: 'ステータス変更',
        questionId: parseFloat(questionId || ''),
        companyStatus: questionDetail.data.status,
      },
    };
    return showModal(<SelectQuestionStatusModal {...modalProps} />);
  }

  return (
    <MainContainer>
      <MainContent fullView>
        <Style.QuestionDetailTitle>
          <Text headerText xxxl w6>質問詳細</Text>
        </Style.QuestionDetailTitle>
        <Style.QuestionDetailWrapper>
          <Text secondaryText xs w6 style={{ width: 100 }}>質問日</Text>
          <Space>
            <Text headerText w6>
              {questionDetail.data.createdAt
                ? `${formatDateTime(questionDetail.data.createdAt, DATE_FORMAT)}`
                : 'ー'}
            </Text>
            <Text headerText w6>
              {questionDetail.data.createdAt
                ? `${formatDateTime(questionDetail.data.createdAt, 'HH:mm')}`
                : 'ー'}
            </Text>
            <Text headerText w6>
              {`[${questionDetail.data.daysFromCreated}日経過]`}
            </Text>
          </Space>
        </Style.QuestionDetailWrapper>
        <Style.QuestionDetailWrapper>
          <Text secondaryText xs w6 style={{ width: 100 }}>ステータス</Text>
          <Text headerText w6 >
           {QUESTION_STATUS[questionDetail.data.status]}
          </Text>
          <Button
            size="small"
            type="primary"
            style={{ marginLeft: 10 }}
            onClick={() => handleSelectQuestionStatusModal()}
          >
            ステータス変更
          </Button>
        </Style.QuestionDetailWrapper>
        <Style.QuestionDetailWrapper>
          <Text xs secondaryText w6 style={{ width: 100 }}>法人名</Text>
          <Text headerText w6>
            {questionDetail.data.company ? questionDetail.data.company.contractName : 'ー'}
          </Text>
        </Style.QuestionDetailWrapper>
        <Style.QuestionDetailWrapper>
          <Text xs secondaryText w6 style={{ width: 100 }}>質問者</Text>
          <Text headerText w6>
            {questionDetail.data.user ? questionDetail.data.user.jituName : 'ー'}
          </Text>
        </Style.QuestionDetailWrapper>
        <MainWrapper title="質問内容" style={{ marginTop: 70 }}>
          <Style.QuestionDetailContent>
            <Input.TextArea
              disabled
              value={questionDetail.data.content ? questionDetail.data.content : ''}
              autoSize={{ minRows: 10, maxRows: 10 }}
            />
          </Style.QuestionDetailContent>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { questionDetail } = state.questionReducer;
  return {
    questionDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCourseQuestionDetail: (params) => dispatch(getCourseQuestionDetailAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  showModal: (params) => dispatch(showModalAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(QuestionDetailPage);
