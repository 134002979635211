// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  STUDENT,
  SUCCESS,
  FAILURE,
  REQUEST,
} from 'src/redux/constant';
import { merge } from 'lodash/object';
export const initialState = {
  testQuestions: {
    data: {} as {
      unitVersionId: number;
      questions: object[];
    },
    meta: {},
    load: false,
    filesUploadStatus: {},
    errors: [],
  },
  dataTestResult:{
    data: [] as {
      name: string;
      questionDetail:{
        id: number,
        score: number,
        is_scored: boolean,
        name: string,
        question_type: string,
        content: string,
        question_options: [],
        image: {
          filename: string,
          url: string
        }
      }[],
    }[],
    meta:{},
    loadQuestionDetail: false
  },
  isDisableRequest: false
};

export type StudentTestReducer = Readonly<typeof initialState>;

export default (state: StudentTestReducer = initialState, action: any): StudentTestReducer => {
  switch (action.type) {
  case SUCCESS(STUDENT.TEST_ACTION.JOIN_TEST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testQuestions: {
        data,
        meta,
        load: false,
        errors: [],
        filesUploadStatus: {},
      },
    };
  }
  case FAILURE(STUDENT.TEST_ACTION.JOIN_TEST): {
    const { errors } = action;
    return {
      ...state,
      testQuestions: {
        ...state.testQuestions,
        load: false,
        filesUploadStatus: {},
        errors,
      },
    };
  }

  case SUCCESS(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      testQuestions: {
        data: {} as {
          unitVersionId: number;
          questions: object[];
        },
        meta: {},
        load: false,
        errors: [],
        filesUploadStatus: {},
      },
      isDisableRequest: false,
      dataTestResult : {
        data : data.testResults,
        meta,
        loadQuestionDetail: false
      }
    };
  }
  case FAILURE(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS): {
    const { errors } = action;
    return {
      ...state,
      testQuestions: {
        ...state.testQuestions,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(STUDENT.TEST_ACTION.UPLOAD_FILE_ANSWER): {
    const { questionId } =  action.payload;
    return {
      ...state,
      testQuestions: {
        ...state.testQuestions,
        filesUploadStatus: {
          ...state.testQuestions.filesUploadStatus,
          [questionId]: true,
        },
      },
    };
  }
  case SUCCESS(STUDENT.TEST_ACTION.UPLOAD_FILE_ANSWER): {
    const { questionId } =  action.payload;
    return {
      ...state,
      testQuestions: {
        ...state.testQuestions,
        filesUploadStatus: {
          ...state.testQuestions.filesUploadStatus,
          [questionId]: false,
        },
      },
    };
  }
  case FAILURE(STUDENT.TEST_ACTION.UPLOAD_FILE_ANSWER): {
    const { questionId } =  action.payload;
    return {
      ...state,
      testQuestions: {
        ...state.testQuestions,
        filesUploadStatus: {
          ...state.testQuestions.filesUploadStatus,
          [questionId]: false,
        },
      },
    };
  }

  case SUCCESS(STUDENT.TEST_ACTION.GET_DETAILS_QUESTION): {
    const { dataQuestion , indexQuestion } = action.payload
    const newTest = state.dataTestResult.data;
    const dataQuestionDetail = state.dataTestResult.data[indexQuestion];

    merge(dataQuestionDetail, { questionDetail: dataQuestion.question});
    newTest.splice(indexQuestion, 1, dataQuestionDetail);
    return {
      ...state,
      dataTestResult: {
        ...state.dataTestResult,
        data: newTest,
        loadQuestionDetail: false
      }
    }
  }

  case SUCCESS(STUDENT.TEST_ACTION.GET_TEST_RESULTS): {
    const { data, meta } = action.payload;
    return {
      ...state,
      dataTestResult : {
        data: data.testResults,
        meta: meta,
        loadQuestionDetail: false
      }
    }
  }

  case FAILURE(STUDENT.TEST_ACTION.GET_DETAILS_QUESTION): {
    return {
      ...state,
      dataTestResult : {
        ...state.dataTestResult,
        loadQuestionDetail: false
      }
    }
  }

  case REQUEST(STUDENT.TEST_ACTION.GET_DETAILS_QUESTION): {
    return {
      ...state,
      dataTestResult : {
        ...state.dataTestResult,
        loadQuestionDetail: true
      }
    }
  }

  default:
    return state;
  }
};
