// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Col } from 'antd';

export const LicenseOverviewContainer = styled.div`
  margin-top: 16px;
  height: 212px;
`;

export const LicenseCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const LicenseOverviewContent = styled.div`
  margin-bottom: 8px;
`;

export const LicenseDataContainer = styled.div`
  margin-top: 64px;
`;

export const LicenseDataTable = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const LicenseGroupAction = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  & button {
    width: 100px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & button span {
      padding-top: 5px;
    }
  }

  ${(props) => props.hasSubText
    && css`
      justify-content: space-between;
    `}
`;

export const LicenseSelect = styled.div`
  & .ant-select-selector, .ant-input {
    background-color: unset !important;
  }
  & .ant-select-disabled, .ant-input-disabled {
    background-color: ${(props) => props.theme.colors.secondaryBorder} !important;
  }
  & .ant-input-disabled {
    border: 1px solid ${(props) => props.theme.colors.primaryBorder} !important;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & .ant-select-selection-placeholder {
      margin-top: 3px;
    }
    & .ant-select-selection-search{
      margin-top: 3px;
    }
    & .ant-select-selection-item .ant-typography-ellipsis{
      margin-top: 10px;
    }
  } 
`;

export const UserListSidebar = styled.div`
  margin-right: 16px;
  min-width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

export const DatePickerContainer = styled.div`
  & .ant-picker{
    width: 100%;
    background-color: unset !important;
  }
  & .ant-picker-clear {
    background-color: unset !important;
  }
`;


export const AddCsvFileContainer = styled.div`
  margin-top: 48px;
`;

export const AddCsvFileContent = styled.div`
  margin-top: 16px;

  button {
    margin-right: 16px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    & button span {
      padding-top: 5px;
    }
  }
`;

export const LicenseDataTableHeader = styled.div`
  display: flex;
  margin-top: 32px;
  align-items: flex-end;
  padding-bottom: 8px;
`;

export const HeaderBlock = styled.div`
display: flex;
`;
