// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Route } from 'react-router-dom';

import HeaderLayout from '../Header';
import FooterLayout from '../Footer';

import * as Style from './styles';

const RegisterLayout = ({ component: Component, role, ...rest }) => (
  <Route
    {...rest}
    render={(matchProps) => (
      <Style.RegisterLayoutContainer>
        <HeaderLayout isRegister currentPath={matchProps.match.path} role={role} />
        <Style.MainLayout>
          <Component {...matchProps} />
        </Style.MainLayout>
        <FooterLayout isRegister role={role} />
      </Style.RegisterLayoutContainer>
    )}
  />
);

export default RegisterLayout;
