// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from "styled-components";
import { Space } from 'antd';

export const FooterModal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  border-top: 1px solid #c4c4c4;
  justify-content: space-between;
  ${(props) =>
    props.hasSubText &&
    css`
      justify-content: space-between;
    `}
  ${({ spaceEvenly }) =>
    spaceEvenly &&
    css`
      border: none;
      .ant-space {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }
    `}

    ${({ isSpaceCenter }) =>
      isSpaceCenter &&
      css`
        border: none;
        .ant-space {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      `}


  @media (max-width: 428px) {
    justify-content: center;
    font-weight: 700;
    padding: 24px 32px;
  }

  & button {
    min-width: 100px;
    @media (max-width: 428px) {
      font-weight: 700;
    }
  }
`;

export const SpaceCustom = styled(Space)`
  @media (max-width: 428px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
`
