// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';
import moment from 'moment';

export function getUnitList(params) {
  const newParams = {
    updatedAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    updatedAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    itemType: params.type || '',
    categoryIdEq: params.category || '',
    nameCont: (params.unit || '').trim(),
    page: params.page || '',
    sort: params.sort,
    items: params?.items,
  };
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function clearUnitList() {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.CLEAR_LIST),
  };
}

export function getUnitDetail(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_DETAIL),
    payload,
  };
}

export function clearUnitDetail() {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.CLEAR_UNIT_DETAIL),
  };
}

export function updateUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.UPDATE),
    payload,
  };
}

export function createUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.CREATE),
    payload,
  };
}

export function deleteUnit(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.DELETE),
    payload,
  };
}

export function getVersionList(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_VERSIONS),
    payload: params,
  };
}

export function clearVersionList() {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.CLEAR_VERSIONS),
  };
}

export function selectUnitVersion(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.SELECT_VERSION),
    payload,
  };
}

export function getUnitItems(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_ITEMS),
    payload,
  };
}

export function selectItemVersion(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.SELECT_ITEM),
    payload,
  };
}

export function createUnitVersion(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.CREATE_VERSION),
    payload,
  };
}

export function getCourseContentUnits(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_COURSE_CONTENT_UNITS),
    payload: params,
  };
}

export function getOverviewUnitDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.UNIT_ACTION.GET_OVERVIEW_UNIT_DETAIL),
    payload: params,
  };
}
