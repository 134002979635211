// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  // Input,
  Collapse,
  // Form,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import Countdown from 'react-countdown';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';
import { getSearchParams } from 'src/utils/common';
import { PlayerStorage } from 'src/utils/localStorge';

import { handleNavigateUnit } from '../../util';

import NoData from 'src/components/layouts/NoData';
import { Text, SkeletonLoading } from 'src/components/styles';

import {
  getStudentGroupDetail as getStudentGroupDetailAction,
  saveWatchedTime as saveWatchedTimeAction,
  setCollapseData as setStudentCollapseDataAction,
  createStudentQuestion as createStudentQuestionAction,
} from 'src/redux/actions';

import prevVideoIcon from 'src/assets/images/videoPlayer/prev-video-icon.svg';
import nextVideoIcon from 'src/assets/images/videoPlayer/next-video-icon.svg';
import prevVideoDisabledIcon from 'src/assets/images/videoPlayer/prev-video-disabled-icon.svg';
import nextVideoDisabledIcon from 'src/assets/images/videoPlayer/next-video-disabled-icon.svg';
import chevronDownIcon from 'src/assets/images/videoPlayer/chevron-down-icon.svg';
// import showMoreIcon from 'src/assets/images/videoPlayer/show-more-icon.svg';
// import questionFormIcon from 'src/assets/images/videoPlayer/question-form-icon.svg';

import * as Style from './styles';
import { ButtonBack, CourseNameTitle } from "../../styles"
import { isNumber } from 'util';

declare global {
  interface Window { LMSVideoPlayer: any; }
}
interface IVideoPlayerProps extends StateProps, DispatchProps {
  getStudentGroupDetail(params): void;
  saveWatchedTime(params): void;
  createStudentQuestion(params): void;
  setCollapseData(params): void;
  collapseData: {
    key: string;
    chapters: {
      key: string;
    }[];
  }[];
  courseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
      groups: {
        id: number;
        name: string;
        chapters: {
          faqs: {
            id: number;
            name: string;
            faqContents: {
              question: string;
              answer: string;
            }[];
          }[];
        }[];
      }[];
    };
    load: boolean;
  };
  unitDetail: {
    data: {
      id: number;
      unit: {
        name: string;
        description: string;
        teachers: {
          id: number;
          name: string;
        }[];
      };
      item: {
        type: string;
        duration: number;
        teacherName: string;
        vmsObfuscatedMid: string;
        vmsMid: number;
      };
    };
    meta: {
      back: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
      next: {
        chapterId: number;
        courseId: number;
        groupId: number;
        unitVersionId: number;
        type: string;
      };
    };
    load: boolean;
  };
}

const VideoPlayer: React.FC<IVideoPlayerProps> = ({
  getStudentGroupDetail,
  saveWatchedTime,
  setCollapseData,
  // createStudentQuestion,
  collapseData,
  courseDetail,
  unitDetail,
}) => {
  const [countdownTime, setCountdownTime] = useState<any>(Date.now());
  const [watchedTimeValue, setWatchedTimeValue] = useState<any>(NaN);
  const [requestCountDown, setRequestCountDown] = useState<number>(10);
  const [networkIsOnline, setNetworkIsOnline] = useState<boolean>(navigator.onLine);
  // const [isShowQuestionForm, setIsShowQuestionForm] = useState(true);

  const videoContentRef = useRef<any>(null);
  const refCountDown = useRef<any>();
  const faqContentRef = useRef<any>(null);

  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[2];
  const unitId = pathnameSplit[4];
  const groupId = getSearchParams('group');
  const chapterId = getSearchParams('chapter');
  const videoSetting = PlayerStorage.getData() || {};

  const teacherList = !isEmpty(unitDetail.data.unit.teachers)
    ? unitDetail.data.unit.teachers.map((teacher) => teacher.name)
    : [];

  useEffect(() => {
    if (!networkIsOnline) alert('ネットワーク接続が切れました。もう一度お試しください。');
  }, [networkIsOnline]);

  useEffect(() => {
    if (unitDetail.data.item.vmsMid && unitDetail.data.item.duration > 0) {
      setCountdownTime(Date.now() + (unitDetail.data.item.duration * 1000) + 5000); // 5s next video
      setWatchedTimeValue(NaN);
      setRequestCountDown(10);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitDetail.data.item.vmsMid]);

  useEffect(() => {
    if (unitDetail.data?.id === Number(unitId) && unitDetail.data?.item?.vmsObfuscatedMid) {
      const videoFunction = `(function initPlayer() {
        var player = jstream_t3.PlayerFactoryIF.create({
          b: "${process.env.REACT_APP_BASE_PLAYER_CONFIG}",
          c: "${process.env.REACT_APP_CONTRACT_ID}",
          m: "${unitDetail.data.item.vmsObfuscatedMid}",
          t: "0.001",
          s: {
            wp: "${window.innerWidth ? window.innerWidth : 1920}",
            hp: "${window.innerWidth ? window.innerWidth * 9 / 16 : 1080}",
            il: "off",
            pr: "0.5,0.7,1.2,1.5,2.0",
            sn: "",
            tg: "off",
            ip: "on",
            rp: "on",
            rs: "on",
            skb: "10",
            dq: "3",
            mdq: "3",
            ti: "off",
            wm: "off",
          },
        }, "videoPlayer");
        window.LMSVideoPlayer = player;
      })();`;

      const videoScript = document.createElement('script');
      videoScript.innerHTML = videoFunction;
      videoScript.type = 'application/javascript';

      document.body.appendChild(videoScript);

      return function cleanVideoScript() {
        if (unitDetail.data.item.type === 'video') {
          document.body.removeChild(videoScript);
          PlayerStorage.setData({
            quality: window.LMSVideoPlayer?.accessor?.getQuality(),
            playRate: window.LMSVideoPlayer?.accessor?.getPlaybackRate(),
          });
        }
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitDetail.data?.id, unitDetail.data?.item?.vmsObfuscatedMid]);

  useEffect(() => {
    if (window.LMSVideoPlayer?.accessor?.isLanding) {
      window.LMSVideoPlayer?.accessor?.setPlaybackRate(videoSetting?.playRate || 1);
      window.LMSVideoPlayer?.accessor?.changeQuality(videoSetting?.quality || 3);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.LMSVideoPlayer?.accessor?.isLanding]);

  useEffect(() => {
    const state = window.LMSVideoPlayer?.accessor?.state;
    const preState = window.LMSVideoPlayer?.accessor?.preState;
   
    if (Number(unitId) === unitDetail.data?.id && (state === 'playing' || (state === 'paused' && preState === 'playing'))) {
      const currentTime = Math.floor(window.LMSVideoPlayer?.accessor?.getCurrentTime());
      const actuallyWatchedTime = unitDetail.data.item.duration - currentTime > 10
          ? currentTime
          : unitDetail.data.item.duration;
      saveWatchedTime({
        courseId,
        videoDuration: unitDetail.data.item.duration,
        requestData: {
          groupId,
          chapterId,
          unitVersionId: unitDetail.data.id,
          watchedTime: actuallyWatchedTime,
          isPlay: state === 'playing'
        },
      });
      setRequestCountDown(10);
      setWatchedTimeValue(currentTime);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.LMSVideoPlayer?.accessor?.state]);

  function onTickWatchedTime() {
    const currentTime = Math.floor(window.LMSVideoPlayer?.accessor?.getCurrentTime()) || 0;
    if (isNumber(currentTime)) {
      setRequestCountDown(requestCountDown - 1);
      setCountdownTime(Date.now() + (unitDetail.data.item.duration - currentTime) * 1000);
    }
    if (requestCountDown === 0) {
      if (networkIsOnline !== navigator.onLine) setNetworkIsOnline(navigator.onLine);
      setWatchedTimeValue(currentTime);
      setRequestCountDown(10);
      if (
        currentTime !== watchedTimeValue &&
        navigator.onLine &&
        window.LMSVideoPlayer?.accessor?.state === 'playing'
      ) {
        const actuallyWatchedTime = unitDetail.data.item.duration - currentTime > 10
          ? currentTime
          : unitDetail.data.item.duration;
        saveWatchedTime({
          courseId,
          videoDuration: unitDetail.data.item.duration,
          requestData: {
            groupId,
            chapterId,
            unitVersionId: unitDetail.data.id,
            watchedTime: actuallyWatchedTime,
          },
        });
      }
    }
  }

  let mousePosition;
  const minSize = 300;

  document.addEventListener('mouseup', () => {
    document.removeEventListener('mousemove', handleResize, false);
  }, false);

  function handleMouseDown(e) {
    mousePosition = e.x;
    document.addEventListener('mousemove', handleResize, false);
  }

  function handleResize(e) {
    const dx = mousePosition - e.x;
    mousePosition = e.x;

    const videoContentElement = videoContentRef.current;
    const faqContentElement = faqContentRef.current;

    const videoWidth = videoContentRef.current.offsetWidth;
    const faqWidth = faqContentRef.current.offsetWidth;

    if (videoWidth >= minSize && faqWidth >= minSize) {
      videoContentElement.style.width = `calc(100% - ${faqWidth + dx + 16}px)`;
      // 16 is margin-right of videoPlayer component
      faqContentElement.style.width = faqWidth + dx + 'px';
    }
    if (videoWidth < minSize) videoContentElement.style.width = minSize - dx + 'px';
    if (faqWidth < minSize) faqContentElement.style.width = minSize + dx + 'px';
  }

  // function handleSubmitForm(values) {
  //   const requestData = {
  //     courseQuestion: {
  //       content: values.faqQuestion,
  //       unitVersionId: unitDetail.data.id,
  //     },
  //   };
  //   createStudentQuestion({ requestData, id: courseId });
  // }

  function renderFAQContents(faqContents) {
    return faqContents.map((faqContent, faqContentIndex) => (
      <Style.AnswerContainer key={`faq-content-${faqContentIndex}`}>
        <Text multiLine>{faqContent.question}</Text>
        <Style.AnswerContent>
          <Text multiLine w6>{faqContent.answer}</Text>
        </Style.AnswerContent>
      </Style.AnswerContainer>
    ));
  }

  function renderFAQCollapse() {
    const groupActiveIndex = findIndex(courseDetail.data.groups, { id: parseFloat(groupId as string) });
    const chapterActiveIndex = groupActiveIndex !== -1
      ? findIndex(courseDetail.data.groups[groupActiveIndex].chapters, { id: parseFloat(chapterId as string) })
      : -1;
    const faqList = chapterActiveIndex !== -1
      ? courseDetail.data.groups[groupActiveIndex].chapters[chapterActiveIndex].faqs
      : [];
    if (isEmpty(faqList)) return (
      <NoData
        message="ー"
        style={{
          justifyContent: 'flex-start',
          paddingLeft: 20,
        }}
      />
    );
    return faqList.map((faqCollapse, faqCollapseIndex) => (
      <Collapse.Panel
        key={`faq-${faqCollapseIndex}`}
        header={
          <Text white w6>{faqCollapse.name}</Text>
        }
        className="custom-question-collapse"
      >
        {renderFAQContents(faqCollapse.faqContents)}
      </Collapse.Panel>
    ));
  }

  const executeScrollToFAQ = () => {
    const element = document.getElementsByClassName('faq-collapse-mobile');
    if (element[0]) {
      element[0].scrollIntoView()
    }
  };
  return (
    <>
      {unitDetail.data.item.vmsMid && (
        <div style={{ display: 'none' }}>
          <Countdown
            ref={refCountDown}
            key={unitDetail.data.item.vmsMid}
            date={countdownTime}
            onTick={() => onTickWatchedTime()}
            onComplete={() => {
              const currentTime = Math.floor(window.LMSVideoPlayer?.accessor?.getCurrentTime()) || 0;
              if (isNumber(currentTime) && unitDetail.data.item.duration - currentTime > 1) {
                setCountdownTime(Date.now() + (unitDetail.data.item.duration - currentTime) * 1000);
                refCountDown.current.start();
              } else {
                handleNavigateUnit({
                  action: 'next',
                  getStudentGroupDetail,
                  setCollapseData,
                  courseDetail,
                  unitDetail,
                  collapseData,
                });
                saveWatchedTime({
                  courseId,
                  videoDuration: unitDetail.data.item.duration,
                  requestData: {
                    groupId,
                    chapterId,
                    unitVersionId: unitDetail.data.id,
                    watchedTime: unitDetail.data.item.duration,
                  },
                });
              }
            }}
          />
        </div>
      )}
      {courseDetail.load
        ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
        : <CourseNameTitle white w6 xxl>{courseDetail.data.courseCategory?.name}</CourseNameTitle>
      }
      <Style.VideoDetailContainer>
        <Style.VideoDetailContent ref={videoContentRef}>
          <Style.VideoDetailTitle>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text white lg w7 truncate>{unitDetail.data.unit.name}</Text>
            }
            <Style.ButtonResize
              type="link"
              icon={<Style.MoveLeftIcon />}
              onMouseDown={(e) => handleMouseDown(e)}
              style={{ cursor: 'w-resize', width: '30px' }}
            />
          </Style.VideoDetailTitle>
          <Style.VideoPlayerContainer>
            <Style.VideoPlayerContent load={unitDetail.load}>
              <div key={unitDetail.data.item.vmsMid} id="videoPlayer" />
            </Style.VideoPlayerContent>
          </Style.VideoPlayerContainer>
          <Style.VideoDescription>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 2 }} title={false} />
              : <Text sm>{unitDetail.data.unit.description}</Text>
            }
            <Style.VideoTeacher>
              <Text xs style={{ marginRight: 8 }}>講師</Text>
              {courseDetail.load || unitDetail.load
                ? (
                  <div>
                    <SkeletonLoading active paragraph={false} title={{ width: 100 }} />
                  </div>
                )
                : <Text w6>{teacherList.length > 0 ? teacherList.toString() : 'ー'}</Text>
              }
            </Style.VideoTeacher>
            <Style.VideoActionContainer>
              <ButtonBack
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(unitDetail.meta.back)}
                onClick={() => 
                  handleNavigateUnit({
                    action: 'back',
                    getStudentGroupDetail,
                    setCollapseData,
                    courseDetail,
                    unitDetail,
                    collapseData,
                  })
                }
              >
                <img
                  src={unitDetail.meta.back ? prevVideoIcon : prevVideoDisabledIcon}
                  style={{ marginRight: 10 }}
                  alt=""
                />
                戻る
              </ButtonBack>
              <Style.ButtonFAQ
                type="link"
                style={{ padding: 0 }}
                onClick={executeScrollToFAQ}
                className='faq-collapse-header-mobile'
              >
                <img
                  src={chevronDownIcon}
                  style={{ marginRight: 4, width: 20 }}
                  alt=""
                />
                FAQ
              </Style.ButtonFAQ>
              <ButtonBack
                type="link"
                style={{ padding: 0 }}
                disabled={isEmpty(unitDetail.meta.next)}
                onClick={() => 
                  handleNavigateUnit({
                    action: 'next',
                    getStudentGroupDetail,
                    setCollapseData,
                    courseDetail,
                    unitDetail,
                    collapseData,
                  })
                }
              >
                次へ
                <img
                  src={unitDetail.meta.next ? nextVideoIcon : nextVideoDisabledIcon}
                  style={{ marginLeft: 10 }}
                  alt=""
                />
              </ButtonBack>
            </Style.VideoActionContainer>
          </Style.VideoDescription>
        </Style.VideoDetailContent>
        <Style.FAQContent ref={faqContentRef}>
          <Style.FAQTitle>
            <Style.ButtonResize
              type="link"
              icon={<Style.MoveRightIcon />}
              onMouseDown={(e) => handleMouseDown(e)}
              style={{ display: 'flex', justifyContent: 'flex-end', cursor: 'w-resize', width: '30px' }}
            />
            <Text white lg w6 style={{ marginLeft: 20 }}>FAQ</Text>
          </Style.FAQTitle>
          <Style.CollapseContainer>
            <Collapse
              key={`FAQs-${chapterId}`}
              className="faq-collapse"
              bordered={false}
              defaultActiveKey={[]}
              expandIcon={
                ({ isActive }) => isActive
                  ? <MinusOutlined style={{ color: 'white' }} />
                  : <PlusOutlined style={{ color: 'white' }} />
              }
              expandIconPosition="right"
            >
              {renderFAQCollapse()}
            </Collapse>
          </Style.CollapseContainer>
          {/* <Style.ShowMoreAction>
            <Button
              type="primary"
              ghost
              block
              onClick={() => setIsShowQuestionForm(!isShowQuestionForm)}
            >
              質問フォーム
              <img src={showMoreIcon} height="24" width="auto" alt="" />
            </Button>
          </Style.ShowMoreAction>
          {isShowQuestionForm && (
            <Style.QuestionFormContainer>
              <Form
                name="questionForm"
                onFinish={(values) => handleSubmitForm(values)}
              >
                <Style.QuestionFormTitle>
                  <img src={questionFormIcon} height="24" width="auto" alt="" />
                  <Text lg style={{ marginLeft: 4 }}>質問する</Text>
                </Style.QuestionFormTitle>
                <Form.Item
                  name="faqQuestion"
                  rules={[{ required: true, message: '質問するは必須項目です。' }]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 4, maxRows: 8 }}
                    placeholder="質問の内容をここに入力してください。"
                  />
                </Form.Item>
                <Style.QuestionSubmitButton>
                  <Button type="primary" htmlType="submit">&nbsp;送信&nbsp;</Button>
                </Style.QuestionSubmitButton>
              </Form>
            </Style.QuestionFormContainer>
          )} */}
        </Style.FAQContent>
      </Style.VideoDetailContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  const { collapseData } = state.commonReducer;
  const { courseDetail } = state.studentCourseReducer;
  const { unitDetail } = state.studentUnitReducer;
  return {
    collapseData,
    courseDetail,
    unitDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentGroupDetail: (params) => dispatch(getStudentGroupDetailAction(params)),
  saveWatchedTime: (params) => dispatch(saveWatchedTimeAction(params)),
  setCollapseData: (params) => dispatch(setStudentCollapseDataAction(params)),
  createStudentQuestion: (params) => dispatch(createStudentQuestionAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
