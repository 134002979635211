// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { isEmpty } from 'lodash';

import history from 'src/utils/history';
import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';
import SortableHeader from 'src/components/tables/SortableHeader';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getFAQList as getFAQListAction,
  getFAQDetail as getFAQDetailAction,
  getCategoryList as getCategoryListAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IFaqListPageProps extends StateProps, DispatchProps {
  getFAQList(params): void;
  getFAQDetail(params): void;
  getTagList(): void;
  getCategoryList(): void;
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  faqList: {
    data: {
      id: number;
      name: string;
      updatedAt: string;
      category: {
        id: number;
        name: string;
      },
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const FAQListPage: React.FC<IFaqListPageProps> = ({
  getFAQList,
  getFAQDetail,
  getCategoryList,
  categoryList,
  faqList,
}) => {
  const { data, meta } = faqList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.FAQList);

    getFAQList({ page: 1 });
    getCategoryList();
  }, []);

  const columns = [
    {
      title: <SortableHeader
        type="name"
        title="項目名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getFAQList}
        setAction={setSortCondition}
      />,
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      width: 300,
    },
    {
      title: <SortableHeader
        type="category"
        title="講座タイプ"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getFAQList}
        setAction={setSortCondition}
      />,
      dataIndex: 'category',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.category}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title: <SortableHeader
        type="updatedAt"
        title="更新日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getFAQList}
        setAction={setSortCondition}
      />,
      dataIndex: 'updatedAt',
      width: 130,
    },
  ];

  const tableData = data.map((item) => ({
    key: item.id,
    id: item.id,
    name: item.name,
    category: (item.category || {}).name,
    updatedAt: formatDateTime(item.updatedAt, DATE_FORMAT),
  }));

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '更新日',
    },
    {
      type: 'select',
      fieldParams: 'category',
      options: categoryList.data,
      title: '講座タイプ',
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: '項目名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getFAQList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>FAQ一覧</Text>
          <Button type="primary" onClick={() => history.push('/admin-service/faq/new')}>
            新規作成
          </Button>
        </TopContent>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          scroll={{ y: 506 }}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {getFAQDetail({
              id: record.id,
              redirect: `/admin-service/faq/${record.id}/edit`,
            })} 
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={faqList.load}
        />
        {!isEmpty(faqList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getFAQList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { faqList } = state.faqReducer;
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  return {
    faqList,
    tagList,
    categoryList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFAQList: (params) => dispatch(getFAQListAction(params)),
  getFAQDetail: (params) => dispatch(getFAQDetailAction(params)),
  getCategoryList: () => dispatch(getCategoryListAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(FAQListPage);
