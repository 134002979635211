// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const ErrorLayoutContainer = styled.div`
  min-height: 100vh;
`;

export const MainLayout = styled.div`
  min-height: calc(100vh - 112px);
  background-color: white;
`;

export const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 56px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: white;
  color: white;
  z-index: 98;
`;

export const BrandContent = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
