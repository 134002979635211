// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  videoList: {
    data: [],
    meta: {},
    load: false,
    errors: [],
  },
};

export type VideoReducer = Readonly<typeof initialState>;

export default (state: VideoReducer = initialState, action: any): VideoReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.VIDEO_ACTION.GET_LIST): {
    return {
      ...state,
      videoList: {
        ...state.videoList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.VIDEO_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      videoList: {
        data: data.videos,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.VIDEO_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      videoList: {
        ...state.videoList,
        load: false,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
