// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from '../../constant';
import moment from 'moment';

export function getFAQList(params) {
  const newParams = {
    updatedAtGteq: params.startDate ? moment(params.startDate).format('YYYY/MM/DD') : '',
    updatedAtLteq: params.endDate ? moment(params.endDate).format('YYYY/MM/DD') : '',
    categoryIdEq: params?.category || '',
    nameCont: (params.name || '').trim(),
    page: params?.page,
    sort: params.sort,
    items: params?.items,
  };

  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.GET_LIST),
    payload: newParams,
  };
}

export function getFAQDetail(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function createFAQ(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.CREATE_FAQ),
    payload: params,
  };
}

export function updateFAQ(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ),
    payload: params,
  };
}

export function addFAQContent(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.ADD_FAQ_CONTENT),
    payload: params,
  };
}

export function updateFAQContent(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.UPDATE_FAQ_CONTENT),
    payload: params,
  };
}

export function deleteFAQContent(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.DELETE_FAQ_CONTENT),
    payload: params,
  };
}

export function clearFAQErrors() {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.CLEAR_FAQ_ERRORS),
  };
}

export function clearFAQContentErrors() {
  return {
    type: REQUEST(ADMIN_SERVICE.FAQ_ACTION.CLEAR_FAQ_CONTENT_ERRORS),
  };
}
