// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { findIndex } from 'lodash/array';
import {
  ADMIN_SERVICE,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';

const initialState = {
  planList: {
    data: [] as any,
    meta: {},
    load: false,
    errors: [],
  },
};

export type PlanReducer = Readonly<typeof initialState>;

export default (state: PlanReducer = initialState, action: any): PlanReducer => {
  switch (action.type) {
  case REQUEST(ADMIN_SERVICE.PLAN_ACTION.GET_LIST): {
    return {
      ...state,
      planList: {
        ...state.planList,
        load: true,
      },
    };
  }
  case SUCCESS(ADMIN_SERVICE.PLAN_ACTION.GET_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      planList: {
        data: data.plans,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PLAN_ACTION.GET_LIST): {
    const { errors } = action;
    return {
      ...state,
      planList: {
        ...state.planList,
        load: false,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN): {
    const { data } = action.payload;
    const newPlanList = state.planList.data;
    const updatePlanList = findIndex(state.planList.data, { id: data.id });
    newPlanList.splice(updatePlanList, 1, data );
    return {
      ...state,
      planList: {
        ...state.planList,
        data: newPlanList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PLAN_ACTION.UPDATE_PLAN): {
    const { errors } = action;
    return {
      ...state,
      planList: {
        ...state.planList,
        errors,
      },
    };
  }

  case SUCCESS(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN): {
    const { data } = action.payload;
    const newPlanList = state.planList.data;
    const  remotePlanList = findIndex(state.planList.data, { id: data.id });
    newPlanList.splice(remotePlanList, 1);
    return {
      ...state,
      planList: {
        ...state.planList,
        data: newPlanList,
        errors: [],
      },
    };
  }
  case FAILURE(ADMIN_SERVICE.PLAN_ACTION.DELETE_PLAN): {
    const { errors } = action;
    return {
      ...state,
      planList: {
        ...state.planList,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
