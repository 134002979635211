// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';

import { MainContainer, MainContent, TopContent } from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles/Texts';

import CreateCourse from './Create';
import EditCourse from './Edit';

interface IModifyCoursePageProps {
  match: {
    params: {
      id?: string;
    };
  };
}

const ModifyCoursePage: React.FC<IModifyCoursePageProps> = ({
  match,
}) => {
  const courseId = match.params.id;
  function renderModifyCourseContent() {
    if (courseId) return <EditCourse courseId={courseId} />;
    return <CreateCourse />;
  }
  return (
    <MainContainer>
      <MainContent fullView>
        <TopContent>
          {courseId
            ? <Text w6 xxxl>コース修正</Text>
            : <Text w6 xxxl>コース新規作成</Text>
          }
        </TopContent>
        {renderModifyCourseContent()}
      </MainContent>
    </MainContainer>
  );
};

export default ModifyCoursePage;
