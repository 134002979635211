// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ACCOUNT_ACTION } from './account.constant';
import { STATISTIC_ACTION } from './statistic.constant';
import { COURSE_ACTION } from './course.constant';
import { COMPANY_ACTION } from './company.constant';
import { PROFILE_ACTION } from './profile.constant';

export const ADMIN_COMPANY = {
  ACCOUNT_ACTION,
  STATISTIC_ACTION,
  COURSE_ACTION,
  COMPANY_ACTION,
  PROFILE_ACTION,
};
