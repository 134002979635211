// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Col } from 'antd';

export const CourseContentContainer = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

export const CollapseHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* Fix onClick call API collapse header */
  margin: 0 -40px 0 -16px;
  padding: 0 40px 0 16px;
  width: calc(100% + 56px);
  height: 50px;

  & .collapse-header-button {
    display: none;
  }

  &:hover .collapse-header-button {
    display: block;
  }
`;

export const CollapseIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 16px;
  display: flex;
  transform: translate(0, -50%);
`;

export const GroupCollapseContent = styled.div`
  padding: 30px;
  background-color: white;
`;

export const ChapterCollapseContent = styled.div`
  position: relative;
  padding: 32px 0 32px 32px;
  background-color: white;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 12px;
    height: 100%;
    border-left: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  }
`;

export const UnitItemContainer = styled.div`
  position: relative;
  margin-top: 32px;

  &:first-child {
    margin-top: 0;
  }
`;

export const UnitItemContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 50px;
  border: 1px solid ${(props) => props.theme.colors.secondaryBorder};
  background-color: ${(props) => props.theme.colors.tertiaryBackground};

  ${(props) => props.active
    && css`
      border: 2px solid ${props.theme.colors.primary};
      background-color: #FFF8E7;
    `}
`;

export const UnitCol = styled(Col)`
  display: flex;
  align-items: center;
`;

export const UnitAction = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > button {
    min-width: 76px;
  }
`;

export const ItemTypeIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 26px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const ChapterCol = styled.div`
  display: flex;
  align-items: center;
`;

export const ChapterAction = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > button {
    min-width: 76px;
    ${(props) => props.isDisabled
      && css`
        color: white !important;
      `}
  }
`;

export const AddUnitButton = styled.div`
  position: absolute;
  display: none;
  transform: translate(50%, 0);

  & > button {
    font-size: ${(props) => props.theme.fontSize.md};
  }

  ${(props) => props.position === 'top'
    && css`
      top: -32px;
      right: 50%;
    `}

  ${(props) => props.position === 'bottom'
    && css`
      bottom: -34px;
      right: 50%;
    `}

  ${(props) => props.show
    && css`
      display: block;
    `}
`;

export const CourseItemsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
`;
