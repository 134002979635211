// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { LoginStorage } from 'src/utils/localStorge';

import HeaderLayout from '../Header';
import FooterLayout from '../Footer';

import { redirectOnAccessSpecificPage } from 'src/utils/common';

import * as Style from './styles';
import { ResetPasswordPath } from 'src/constants/routerConstants';

interface IResetPasswordLayout {
  exact: boolean;
  role?: string;
  path: string;
  component: any;
}

const ResetPasswordLayout: React.FC<IResetPasswordLayout> = ({
  exact,
  role,
  path,
  component: Component,
}) => {
  const userStorage = LoginStorage.getData();
  if (!isEmpty(userStorage) && !path.includes(ResetPasswordPath)) {
    redirectOnAccessSpecificPage();
    return null;
  }
  if (path.includes(ResetPasswordPath)) {
    LoginStorage.clearData()
  }
  return (
    <Route
      exact={exact}
      path={path}
      render={(matchProps) => (
        <Style.ResetPasswordContainer>
          <HeaderLayout isRegister currentPath={matchProps.match.path} />
          <Style.MainLayout>
            <Component {...matchProps} role={role} />
          </Style.MainLayout>
          <FooterLayout isRegister />
        </Style.ResetPasswordContainer>
      )}
    />
  );
};

export default ResetPasswordLayout;
