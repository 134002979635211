// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { put, takeLatest, all } from 'redux-saga/effects';

import {
  ADMIN_SERVICE,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

import history from 'src/utils/history';
import { tipServices } from 'src/services';
import { showAlertNotice } from 'src/utils/alert';

import { ServicePath } from 'src/constants/routerConstants';

function* getTipListSaga(action) {
  try {
    const result = yield tipServices.getTipList(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TIP_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TIP_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getTipDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield tipServices.getTipDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TIP_ACTION.GET_DETAIL),
      payload: result,
    });
    if (redirect) history.push(redirect);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TIP_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* createTipSaga(action) {
  try {
    const result = yield tipServices.createTip(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TIP_ACTION.CREATE_TIP),
      payload: result,
    });
    yield history.push(ServicePath.TipList);
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TIP_ACTION.CREATE_TIP), errors: e.errors });
  }
}

function* updateTipSaga(action) {
  try {
    const result = yield tipServices.updateTip(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.TIP_ACTION.UPDATE_TIP),
      payload: result,
    });
    yield history.push(ServicePath.TipList);
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.TIP_ACTION.UPDATE_TIP), errors: e.errors });
  }
}

export default function* tipSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.TIP_ACTION.GET_LIST), getTipListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TIP_ACTION.GET_DETAIL), getTipDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TIP_ACTION.CREATE_TIP), createTipSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.TIP_ACTION.UPDATE_TIP), updateTipSaga),
  ]);
}
