// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useMemo } from 'react';
import { isNil } from "lodash";
import {
  Spin,
} from 'antd';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label,
  BarChart,
  Bar,
  Cell,
  ReferenceLine,
} from "recharts";

import { CardWrapper } from 'src/components/layouts/ContentLayout';

import { Text } from 'src/components/styles';

import * as Style from './styles';
import { SCORE_CHART_TICKS } from 'src/constants/common';

interface IAttendanceChartProps {
  handleClickCard(params: any): void;
  setActiveIndexBar(params: number): void;
  activeIndexBar: number;
  attendanceChart: {
    data: {
      chartData: {
        [key: string]: number;
      };
      targetProgressGapLine?: number
    }
    load: boolean;
  };
}

interface TooltipProps {
  active?: boolean;
  payload: {
    value: number
  }[];
}

const AttendanceChart: React.FC<IAttendanceChartProps> = ({
  attendanceChart,
  activeIndexBar,
  handleClickCard,
  setActiveIndexBar
}) => {
  const distanceBarChart = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => Math.floor(i * 100 / 11));
  }, []);

  const formattedChartData = () =>
    Object.entries(attendanceChart?.data?.chartData || []).map(([_, value], index) => ({
      progressPercentage: distanceBarChart[index],
      人数: value,
    })
  );

  const totalUser = () =>
    (formattedChartData() || []).reduce((preValue, currentValue) => preValue + currentValue['人数'], 0);

  function ContentTooltip({ active, payload }: TooltipProps) {
    if (active && !isNil(payload)) {
      const userCount = payload[0].value;
      const percent = totalUser() === 0 ? 0 : Math.floor(userCount / totalUser() * 100);

      if (userCount === 0) return <></>;
  
      return (
        <Style.LabelTooltip>
          <Text>人数: {userCount}人</Text>
          <Text>割合: {percent}％</Text>
        </Style.LabelTooltip>
      );
    }
  
    return <></>;
  };

  function handleClickBar(_, index, event) {
    event.stopPropagation();
    if (index === activeIndexBar) {
      handleClickCard(null)
      return setActiveIndexBar(-1);
    }
    handleClickCard(SCORE_CHART_TICKS[index])
    return setActiveIndexBar(index);
  }

  function CustomizedAxisTick(props) {
    const { x, y, index } = props;
    const tickValue = SCORE_CHART_TICKS[index] as number;  
    const tickFormat = [0, 100].includes(tickValue) ? `${tickValue}%` : `${tickValue}%~`;
  
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={12} dx={16} textAnchor="end" fill="#666">
          {tickFormat}
        </text>
      </g>
    );
  }
    
  function convertProgressGap(progressGap) {
    if (isNil(progressGap)) return null;

    switch (progressGap) {
      case 0:
        return 0;
      case 1:
        return 9;
      default:
        if (progressGap >= 90 && progressGap <= 100) {
          return progressGap;
        }
        return progressGap / 10 * 9 + 9;
    }
  }

  function ReferenceLabel(props) {
    const { fill, textAnchor, viewBox, dy, dx } = props;
    const x = viewBox.width + viewBox.x - 70;
    const y = viewBox.y - 9;
    return (
      <text
        x={x}
        y={y}
        dy={dy}
        dx={dx}
        fill={fill}
        fontSize={14}
        textAnchor={textAnchor}
      >
        進捗ギャップ0ライン
      </text>
    );
  }

  function renderChart() {
    return (
      <Spin spinning={attendanceChart.load}>
        <Text margin="24px 0 0 0" xs w5>人数（人）</Text>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart
            data={formattedChartData()}
            margin={{ top: 20, right: 50, bottom: 16, left: 16 }}
            onClick={() => {
              setActiveIndexBar(-1);
              handleClickCard([])
            }}
          >
            <CartesianGrid vertical={false} stroke="#eeeeee" />
            <XAxis
              dataKey="progressPercentage"
              type="number"
              ticks={distanceBarChart}
              tick={<CustomizedAxisTick />}
              padding={{ right: 19, left: 30 }}
            >
              <Label offset={-10} position="insideBottomRight">
                進捗率（％）
              </Label>
            </XAxis>
            <YAxis
              dataKey="人数"
              type="number"
              domain={[0, (dataMax) => (dataMax < 4 ? 4 : dataMax)]}
              tickMargin={8}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              cursor={{ strokeDasharray: "3 3" }}
              content={({ active, payload }) => (
                <ContentTooltip active={active} payload={payload} />
              )}
            />
            <Bar
              dataKey="人数"
              barSize={40}
              fill="#D29C09"
              unit="人"
              onClick={handleClickBar}
            >
              {(formattedChartData() || []).map((entry, index) => (
                <Cell
                  cursor="pointer"
                  fill={index === activeIndexBar ? '#D29C09' : '#D9D9D9'}
                  key={`cell-${index}`}
                />
              ))}
            </Bar>
            <ReferenceLine
              x={convertProgressGap(attendanceChart.data.targetProgressGapLine)}
              stroke="#0DA2FE"
              label={<ReferenceLabel />}
              strokeWidth={2}
            />
          </BarChart>
        </ResponsiveContainer>
      </Spin>
    );
  }

  return (
    <>
      <Text xxl w7 headerText style={{ margin: "40px 0 16px" }}>
        進捗率
      </Text>
      <CardWrapper>
        <Style.OverviewChart>
          <Text margin="0 0 6px 0" w5>受講人数：{totalUser()}人</Text>

          {renderChart()}
        </Style.OverviewChart>
      </CardWrapper>
    </>
  );
};

export default AttendanceChart;
