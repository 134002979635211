// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import StudentTop from './StudentTop';
import TestOverview from './TestOverview';
import TestingForm from './TestingForm';
import CourseView from './CourseView';
import CourseDetail from './CourseDetail';
import SurveyDetail from './SurveyDetail';
import ConfirmStudent from './ConfirmStudent';

export const StudentPage = {
  StudentTop,
  TestOverview,
  TestingForm,
  CourseView,
  CourseDetail,
  SurveyDetail,
  ConfirmStudent,
};
