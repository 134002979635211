// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Tooltip,
  Popover,
  Checkbox,
  Space,
  Spin,
} from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { findIndex } from 'lodash/array';
import { isNumber, isEmpty } from 'lodash/lang';
import CKEditor from 'ckeditor4-react';
import imageCompression from 'browser-image-compression';

import { FILE_IMAGE_SIZE } from 'src/constants/common';
import { Text, RequiredSymbol } from 'src/components/styles';

import * as Style from './styles';

interface IModifyQuestionProps {
  setFileImage(params): void;
  setImageError(params): void;
  setPreviewImageUrl(params): void;
  removeImage(): void;
  setImageURL(params): void;
  hasBeenUsed: boolean;
  questionCount: number;
  fileImage: any;
  questionList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  questionForm: any;
  questionDetail: any;
  questionIdSelected: number | string;
  imageURL: string;
  editorErrors: string;
  setEditorErrors(params): void
}

const ModifyQuestion: React.FC<IModifyQuestionProps> = ({
  setFileImage,
  setImageError,
  setPreviewImageUrl,
  removeImage,
  setImageURL,
  setEditorErrors,
  editorErrors,
  questionCount,
  hasBeenUsed,
  fileImage,
  questionList,
  questionForm,
  questionIdSelected,
  questionDetail,
  imageURL,
}) => {
  const uploadFileButton = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [isValidQuestionContent, setIsValidQuestionContent] = useState<boolean>(true);

  const contentConfig = {
    extraPlugins: 'justify, font, colorbutton, ckeditor_wiris',
    toolbar: [
      [ 'Styles', 'FontSize'],
      ['Bold', 'Italic', 'Underline', 'Strike'],
      ['ckeditor_wiris_formulaEditor'],
      ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
      ['Link', 'Unlink'],
      ['Image', 'Table'],
      ['Maximize'],
    ],
    removeButtons: '',
    removePlugins: 'elementspath, resize',
    language: 'ja',
    allowedContent : true,
    height: '210px',
  };

  function handleUploadClick() {
    uploadFileButton.current.click();
  }

  async function handleImageUpload(e) {
    setIsUploadingFile(true);
    const imageFile = e.target.files[0];
    if (imageFile) {
      setFileImage(imageFile);
      if (!imageFile?.type.includes('image')) {
        questionForm.setFieldsValue({ image: 'wrong_format' });
        setImageError('wrong_format');
      } else if (imageFile.size > FILE_IMAGE_SIZE) {
        questionForm.setFieldsValue({ image: 'too_large' });
        setImageError('too_large');
      } else {
        setImageError('');
      }
      const reader = new FileReader();

      const options = {
        maxSizeMB: 3,
      };
      const previewImageFile = imageFile?.type.includes('image') ?
        await imageCompression(imageFile, options) : imageFile;
      const previewReader = new FileReader();
      previewReader.onloadend = () => {
        setPreviewImageUrl(previewReader.result);
        setIsUploadingFile(false);
      };
      previewReader.readAsDataURL(previewImageFile);

      reader.onloadend = () => {
        setImageURL(reader.result);
        questionForm.setFieldsValue({ isDeleteImage: false });
      };
      reader.readAsDataURL(imageFile);
    } else setIsUploadingFile(false);
  }

  return (
    <Style.QuestionDetailContent>
      <Text lg><RequiredSymbol />問名</Text>
      <Form.Item
        name="name"
        labelAlign="left"
        colon={false}
        style={{ marginTop: 6 }}
        validateFirst
        rules={[
          { required: true, message: '問名は必須項目です。' },
          { whitespace: true, message: '問名は必須項目です。' },
          { max: 50, message: '問名が長すぎます。（最大は50桁です）' },
          () => ({
            validator(_, value) {
              if (questionList.data.findIndex((questionItem, questionIndex) => {
                const questionIndexSelected = findIndex(questionList.data, { id: questionIdSelected });
                return questionItem.name === ( value || '').trim() && questionIndexSelected !== questionIndex;
              }) !== -1) {
                return Promise.reject('入力された問名がすでに存在しております。');
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Input
          className="transparent-input"
          disabled={hasBeenUsed && questionIdSelected === 'create'}
        />
      </Form.Item>
      <Text lg>説明画像</Text>
      <Form.Item
        name="image"
        labelAlign="left"
        colon={false}
        validateFirst
        rules={[
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value && !getFieldValue('content') && !fileImage) {
                questionForm.setFields([ { name: 'content', errors: ['画像選択または問題文は必須項目です。'] } ]);
                return Promise.reject('画像選択または問題文は必須項目です。');
              } else if (value === 'wrong_format') {
                return Promise.reject('png、jpg、jpeg、tiff、gif等の画像ファイルをアップロードしてください。');
              } else if (value === 'too_large') {
                return Promise.reject('ファイルサイズが大きすぎます。(最大サイズ：5MB）');
              }
              questionForm.setFields([ { name: 'content', errors: [] } ]);
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Style.ImageInputField>
          <Text margin={fileImage ? '0 6px 0 0' : '0'} truncate width="50%" lg>
            <Tooltip title={fileImage ? fileImage.name : ''}>
              <span>{fileImage ? fileImage.name : ''}</span>
            </Tooltip>
          </Text>
          <Style.FieldInput
            accept="image/*"
            id="uploadFileImageField"
            type="file"
            ref={uploadFileButton}
            onChange={(e) => handleImageUpload(e)}
          />
          {imageURL && (
            <>
              <Text width="50%" truncate>
                {fileImage ? '' : questionDetail.data.image?.filename}
              </Text>
              <Popover
                placement="topLeft"
                content={<img src={imageURL} width="auto" style={{ maxHeight: '500px' }} alt="" />}
              >
                <EyeOutlined
                  style={{ color: '#D29C09', marginRight: '10px' }}
                />
              </Popover>
              <DeleteOutlined
                style={{ color: '#D25109', marginRight: '10px' }}
                onClick={() => {
                  removeImage();
                  questionForm.setFieldsValue({ isDeleteImage: true });
                }}
              />
            </>
          )}
          <Button
            size="middle"
            ghost
            type="primary"
            onClick={() => handleUploadClick()}
            disabled={hasBeenUsed && questionIdSelected === 'create'}
          >
            画像選択
          </Button>
          {isUploadingFile &&
            <Space style={{ marginLeft: '20px' }}>
              <Text lg w6>アップロード中</Text>
              <Spin />
            </Space>
          }
        </Style.ImageInputField>
      </Form.Item>
      <Text lg>問題文</Text>
        <Style.QuestionContent>
          {hasBeenUsed !== undefined
            && (
              <CKEditor
                key={`total-${questionCount}-questions`}
                onBeforeLoad={
                  (CKEDITOR) => {
                    CKEDITOR.plugins.addExternal(
                      'ckeditor_wiris',
                      '/mathtype-ckeditor4/',
                      'plugin.js',
                    );
                    CKEDITOR.addCss('.cke_editable img { max-width: 100% !important; height: auto !important; }');
                  }
                }
                id="question-content"
                {...(!isEmpty(questionDetail?.data.content)
                  && isNumber(questionIdSelected)
                  && { data: questionDetail?.data.content })
                }
                onChange={(event) => {
                  questionForm.setFieldsValue({ content: event.editor.getData() });
                  if (!isEmpty(event.editor.getData())) {
                    questionForm.setFields([ { name: 'image', errors: [] } ]);
                  }
                  const element = document.createElement('DIV');
                  element.innerHTML = event.editor.getData();
                  const textData = element.textContent || element.innerText || '';
                  if (textData.replace(/(?:\r\n|\r|\n|\s|\u200B)/g, '').length > 2000 && !isUploadingFile) {
                    setIsValidQuestionContent(false)
                    return setEditorErrors('問題文が長すぎます。（最大は2000文字です）');
                  }
                  setIsValidQuestionContent(true)
                  return setEditorErrors('');
                }}
                config={contentConfig}
                readOnly={hasBeenUsed && questionIdSelected === 'create'}
                limit={200}
              />
            )
          }
        </Style.QuestionContent>
        <Style.FormItemCustom
          name="content"
          labelAlign="left"
          colon={false}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value && !getFieldValue('image')) {
                  questionForm.setFields([ { name: 'image', errors: ['画像選択または問題文は必須項目です。'] } ]);
                  return setEditorErrors('画像選択または問題文は必須項目です。');
                }
                else if(isValidQuestionContent){
                  setEditorErrors('')
                  questionForm.setFields([ { name: 'image', errors: [] } ]);
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <Input style={{ display: 'none' }} />
        </Style.FormItemCustom>
        <Style.EditorErrors>
          <Text fontSize={"13px"} color = "#D25109">{editorErrors}</Text>
        </Style.EditorErrors>
      <Form.Item
        name="isDeleteImage"
        style={{ display: 'none' }}
      >
        <Checkbox />
      </Form.Item>
    </Style.QuestionDetailContent>
  );
};

export default ModifyQuestion;
