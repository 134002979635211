// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_COMPANY, REQUEST } from '../../constant';

export function getCompanyCourseDetail(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_DETAIL),
    payload: params,
  };
}

export function getCompanyGroupDetail(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_GROUP),
    payload: params,
  };
}

export function getCompanyAttendanceStatus(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_STATUS),
    payload: params,
  };
}

export function getCompanyCourseStudents(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_STUDENT_LIST),
    payload: params,
  };
}

export function getCompanyCourseChart(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_ATTENDANCE_CHART),
    payload: params,
  };
}

export function getCompanyTestScoreChart(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_CHART),
    payload: params,
  };
}

export function getCourses() {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_COURSES),
  };
}

export function getCompanyTestScoreOverview(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_OVERVIEW),
    payload: params,
  };
}

export function getCompanyTestScoreStudents(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_TEST_SCORE_STUDENTS),
    payload: params,
  };
}

export function downloadCsvStudyStudentByAdminCompany(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_STUDY_STUDENT),
    payload: params,
  };
}

export function setProgressNotification(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.SET_PROGRESS_NOTIFICATION),
    payload: params,
  };
}

export function getCompanyRecommendDate(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_RECOMMEND_DATE),
    payload: params,
  }
}

export function downloadCsvTestStudentByAdminCompany(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.DOWNLOAD_CSV_TESTS),
    payload: params,
  };
}

export function getCompanyProgressRateChart(id) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_PROGRESS_RATE_CHART),
    payload: id,
  };
}

export function getCompanyUserProgressCourse(id) {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.GET_USER_PROGRESS_COURSE),
    payload: id,
  };
}

export function clearDataLearning() {
  return {
    type: REQUEST(ADMIN_COMPANY.COURSE_ACTION.CLEAR_LEARNING_TAB),
  };
}
