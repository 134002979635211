// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  STUDENT,
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../constant';

const initialState = {
  statisticCourses: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  currentWeek: {
    data: {
      learningDates: {},
      learningTime: {},
      doneUnits: {},
      learningProgress: {},
    },
    meta: {},
    load: false,
    errors: [],
  },
  learningHistories: {
    data: [] as object[],
    meta: {},
    load: false,
    errors: [],
  },
  learningTimeChart: {
    data: [],
    meta: {
      maxLearningTime: 0,
    },
    load: false,
    errors: [],
  },
};

export type StudentStatisticReducer = Readonly<typeof initialState>;

export default (state: StudentStatisticReducer = initialState, action: any): StudentStatisticReducer => {
  switch (action.type) {
  case REQUEST(STUDENT.STATISTIC_ACTION.GET_STATISTIC_COURSES): {
    return {
      ...state,
      statisticCourses: {
        ...state.statisticCourses,
        load: true,
      },
    };
  }
  case SUCCESS(STUDENT.STATISTIC_ACTION.GET_STATISTIC_COURSES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      statisticCourses: {
        data: data.courses,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.STATISTIC_ACTION.GET_STATISTIC_COURSES): {
    const { errors } = action;
    return {
      ...state,
      statisticCourses: {
        ...state.statisticCourses,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(STUDENT.STATISTIC_ACTION.GET_CURRENT_WEEK): {
    return {
      ...state,
      currentWeek: {
        ...state.currentWeek,
        load: true,
      },
    };
  }
  case SUCCESS(STUDENT.STATISTIC_ACTION.GET_CURRENT_WEEK): {
    const { data, meta } = action.payload;
    return {
      ...state,
      currentWeek: {
        data,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.STATISTIC_ACTION.GET_CURRENT_WEEK): {
    const { errors } = action;
    return {
      ...state,
      currentWeek: {
        ...state.currentWeek,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(STUDENT.STATISTIC_ACTION.GET_LEARNING_HISTORIES): {
    return {
      ...state,
      learningHistories: {
        ...state.learningHistories,
        load: true,
      },
    };
  }
  case SUCCESS(STUDENT.STATISTIC_ACTION.GET_LEARNING_HISTORIES): {
    const { data, meta, more } = action.payload;
    if (more) {
      return {
        ...state,
        learningHistories: {
          data: [
            ...state.learningHistories.data,
            ...data.histories,
          ],
          meta,
          load: false,
          errors: [],
        },
      };
    }
    return {
      ...state,
      learningHistories: {
        data: data.histories,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.STATISTIC_ACTION.GET_LEARNING_HISTORIES): {
    const { errors } = action;
    return {
      ...state,
      learningHistories: {
        ...state.learningHistories,
        load: false,
        errors,
      },
    };
  }

  case REQUEST(STUDENT.STATISTIC_ACTION.GET_LEARNING_TIMES): {
    return {
      ...state,
      learningTimeChart: {
        ...state.learningTimeChart,
        load: true,
      },
    };
  }
  case SUCCESS(STUDENT.STATISTIC_ACTION.GET_LEARNING_TIMES): {
    const { data, meta } = action.payload;
    return {
      ...state,
      learningTimeChart: {
        data: data.chartData,
        meta,
        load: false,
        errors: [],
      },
    };
  }
  case FAILURE(STUDENT.STATISTIC_ACTION.GET_LEARNING_TIMES): {
    const { errors } = action;
    return {
      ...state,
      learningTimeChart: {
        ...state.learningTimeChart,
        load: false,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
