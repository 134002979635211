// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { LoginStorage, SessionStorage } from 'src/utils/localStorge';

import {
  ServicePath,
  CompanyPath,
  StudentPath,
  NotFoundPath,
} from 'src/constants/routerConstants';

import {
  logout as logoutAction,
  getNotificationUnread as getNotificationUnreadAction,
} from 'src/redux/actions';

import HeaderLayout from '../Header';
import FooterLayout from '../Footer';

import * as Style from './styles';
declare global {
  interface Window {
    mouseX: any;
    mouseY: any;
  }
}
interface IDefaultLayoutProps extends DispatchProps {
  logout(params): void;
  getNotificationUnread(): void;
  component: any;
  role: string;
  path: string;
}

const DefaultLayout: React.FC<IDefaultLayoutProps> = ({
  logout,
  getNotificationUnread,
  component: Component,
  role,
  path,
  ...rest
}) => {
  const userStorage = LoginStorage.getData();
  useEffect(() => {
    const session = SessionStorage.getData();
    if (userStorage && session && moment(session?.lastActionTime).add(1, 'hour') <= moment()) {
      logout({ role });
    }

    const interval = setInterval(() => {
      const sessionData = SessionStorage.getData();
      if (isEmpty(sessionData) || sessionData.mouseX !== window.mouseX || sessionData.mouseY !== window.mouseY) {
        SessionStorage.setData({
          mouseX: window.mouseX,
          mouseY: window.mouseY,
          lastActionTime: moment(),
        });
      } else if (moment(sessionData.lastActionTime).add(1, 'hour') <= moment()) {
        logout({ role });
      }
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    getNotificationUnread();
  }, [path]);

  if (isEmpty(userStorage)) {
    if (role === 'admin_service') {
      return <Redirect to={ServicePath.Login} />;
    } else if (role === 'admin_company') {
      return <Redirect to={CompanyPath.Login} />;
    } else {
      return <Redirect to={StudentPath.Login} />;
    }
  } else if (userStorage.tokenInfo.role !== role) {
    if ([
      ServicePath.AdminServiceTop,
      CompanyPath.AdminCompanyTop,
      StudentPath.StudentTop,
    ].includes(path)) {
      if (userStorage.tokenInfo.role === 'admin_service') {
        return <Redirect to={ServicePath.AdminServiceTop} />;
      } else if (userStorage.tokenInfo.role === 'admin_company') {
        return <Redirect to={CompanyPath.AdminCompanyTop} />;
      } else {
        return <Redirect to={StudentPath.StudentTop} />;
      }
    } else {
      return <Redirect to={NotFoundPath} />;
    }
  }
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Style.DefaultLayoutContainer
          onMouseMove={(event) => {
            window.mouseX = event.clientX;
            window.mouseY = event.clientY;
          }}
          onTouchStart={() => {
            const sessionData = SessionStorage.getData();
            if (moment(sessionData.lastActionTime).add(1, 'minute') <= moment()) {
              SessionStorage.setData({
                mouseX: 0,
                mouseY: 0,
                lastActionTime: moment(),
              });
            }
          }}
        >
          <HeaderLayout currentPath={matchProps.match.path} role={role} />
          <Style.MainLayout>
            <Component {...matchProps} role={role} />
          </Style.MainLayout>
          <FooterLayout role={role} />
        </Style.DefaultLayoutContainer>
      )}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  logout: (params) => dispatch(logoutAction(params)),
  getNotificationUnread: () => dispatch(getNotificationUnreadAction()),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(DefaultLayout);
