// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import 'moment/locale/ja';
import {
  Space,
  Avatar,
  Spin,
  Button,
} from 'antd';
import { isEmpty } from 'lodash';
import { UserOutlined } from '@ant-design/icons';
import warningImg from "src/assets/images/modalImage/warning.svg"
import history from 'src/utils/history';

import NoData from 'src/components/layouts/NoData';

import {
  getDropdownNotifications as getDropdownNotificationsAction,
  markNotificationsAsRead as markNotificationsAsReadAction,
  getUploadAnswerDetail as getUploadAnswerDetailAction,
  getTestResults as getTestResultsAction,
  getCurrentTime as getCurrentTimeAction,
  showModal as showModalAction
} from 'src/redux/actions';

import { Text } from 'src/components/styles';

import * as Style from './styles';
import AddCsvModal from 'src/components/Modal/components/AddCsvModal';

interface INotificationDropdownProps extends DispatchProps {
  setIsShowNotiDropdown(params): void;
  getDropdownNotifications(params): void;
  markNotificationsAsRead(): void;
  getUploadAnswerDetail(params): void;
  getTestResults(params): void;
  getCurrentTime(params): void;
  showModal(params): void;
  isStudent: boolean;
  isCompany: boolean;
  dropdownNotifications: {
    data: {
      id: number;
      action: string;
      sentAt: string;
      isRead: string;
      sender: {
        id: number;
        jituName: string;
        kanaName: string;
        avatarUrl: string;
      };
      course: {
        id: number;
        name: string;
        courseCategory: {
          id: number,
          name: string
        }
      };
      item: {
        id: number;
        type: string;
      };
      unit: {
        id: number;
        name: string;
      }
      company: {
        id: number,
        contractName: string,
        name: string
      }
      optionalInformation: {
        numberEmailInvited: number;
        numberEmailSuccess: number;
        message: string;
        success?: boolean;
      };
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        page: number;
        next: number;
      };
    };
    load: boolean;
    errors: object[];
  };
}

const NotificationDropdown: React.FC<INotificationDropdownProps> = ({
  setIsShowNotiDropdown,
  getDropdownNotifications,
  markNotificationsAsRead,
  getUploadAnswerDetail,
  getTestResults,
  isStudent,
  dropdownNotifications,
  getCurrentTime,
  showModal,
  isCompany
}) => {
  moment.locale('ja');
  const { data, meta } = dropdownNotifications;

  function renderNotificationItem() {
    if (isEmpty(data) && !dropdownNotifications.load) {
      return <NoData message="データが存在しません。" />;
    }
    return data.map((notificationItem, notificationItemIndex) => {
      switch (notificationItem.action) {
      case 'uploaded_answer_file': {
        return (
          <Style.NotificationItem
            key={`notification-${notificationItem.id}-${notificationItemIndex}`}
            unread={!notificationItem.isRead}
            onClick={() => {
              getUploadAnswerDetail({
                userAnswerId: notificationItem.item.id,
                requestData: {
                  markNotificationAsReadId: notificationItem.id,
                  isNotification: true
                },
              });
              setIsShowNotiDropdown(false);
            }}
          >
            <Space size={12}>
              {notificationItem?.sender?.avatarUrl
                ? <Style.ProfileAvatar image={notificationItem?.sender?.avatarUrl} />
                : <Avatar size={40}><UserOutlined /></Avatar>
              }
              <div>
                <Text w6={!notificationItem.isRead} truncateMultiLine={2}>
                  {`${notificationItem?.sender?.jituName}が${notificationItem?.unit?.name}の回答をアップロードしました`}
                </Text>
                <Text subText xs w6={!notificationItem.isRead}>
                  {moment(notificationItem.sentAt).fromNow()}
                </Text>
              </div>
            </Space>
          </Style.NotificationItem>
        );
      }
      case 'scored_answer_file': {
        return (
          <Style.NotificationItem
            key={`notification-${notificationItem.id}-${notificationItemIndex}`}
            unread={!notificationItem.isRead}
            onClick={() => {
              getTestResults({
                courseId: notificationItem.course.id,
                requestData: {
                  testsUserId: notificationItem.item.id,
                  markNotificationAsReadId: notificationItem.id,
                  isNotification: true,
                },
              });
              setIsShowNotiDropdown(false);
            }}
          >
            <div>
              <Text w6={!notificationItem.isRead} truncateMultiLine={2}>
                {`${notificationItem?.unit?.name}の採点が完了しました`}
              </Text>
              <Text subText xs w6={!notificationItem.isRead}>
                {moment(notificationItem.sentAt).fromNow()}
              </Text>
            </div>
          </Style.NotificationItem>
        );
      }
      case "imported_users_file": {
        return (
          <Style.NotificationItem
            key={`notification-${notificationItem.id}-${notificationItemIndex}`}
            unread={!notificationItem.isRead}
            onClick={() => {
              getCurrentTime({
                requestData: {
                  markNotificationAsReadId: notificationItem.id,
                }
              })
              setIsShowNotiDropdown(false)
              const modalDataSuccess = {
                isOk: true,
                modalData: {
                  title: 'CSVファイルのアップロード',
                  okText: '完了',
                  contractName: notificationItem.company?.contractName,
                  courseName: notificationItem.course?.courseCategory.name,
                  responeDataSuccess: {
                    numberSuccess: notificationItem.optionalInformation?.numberEmailSuccess,
                    numberInviteEmails: notificationItem.optionalInformation?.numberEmailInvited,
                  }
                },
              };

              const modalDataError = {
                isOk: true,
                modalData: {
                  title: 'CSVファイルのアップロード',
                  okText: '完了',
                  contractName: notificationItem.company?.contractName,
                  courseName: notificationItem.course?.courseCategory.name,
                  errorServer: 'インポート中にエラーが発生しました。再度インポートをしてください。'
                },
              }

              if(notificationItem.optionalInformation.message ){
                return showModal(<AddCsvModal {...modalDataError}/>)
              }
              return showModal(<AddCsvModal {...modalDataSuccess}/>)
              }
            }
          >
             <Space size={12}>
              {notificationItem?.sender?.avatarUrl
                ? <Style.ProfileAvatar image={notificationItem?.sender?.avatarUrl} />
                : <Avatar size={40}><UserOutlined /></Avatar>
              }
              <div>
                {
                  notificationItem.optionalInformation.message ? 
                    <>
                      <img src={warningImg} alt="warningImg" draggable={false} />
                      <Text w6={!notificationItem.isRead} truncateMultiLine={2} color="red">
                        {notificationItem.optionalInformation.message}
                      </Text>
                    </>  
                    :(
                      <>
                        <Text w6={!notificationItem.isRead} truncateMultiLine={2} >
                          {notificationItem.optionalInformation.numberEmailSuccess}件のライセンス付与、
                        </Text>
                        <Text w6={!notificationItem.isRead} truncateMultiLine={2}>
                          {notificationItem.optionalInformation.numberEmailInvited}件のユーザー登録を行いました。
                        </Text>
                      </>
                    )
                }
                <Text subText xs w6={!notificationItem.isRead}>
                  {moment(notificationItem.sentAt).fromNow()}
                </Text>
              </div>
            </Space>
          </Style.NotificationItem>)
      }
      case "bulk_resend_invitation":
      case "bulk_cancel_invitation": {
        return (
          <Style.NotificationItem
            key={`notification-${notificationItem.id}`}
            unread={!notificationItem.isRead}
            onClick={() => {
              if (!notificationItem.isRead) {
                getCurrentTime({
                  requestData: {
                    markNotificationAsReadId: notificationItem.id,
                  },
                });
              }
            }}
          >
            <Space size={12}>
              {notificationItem?.sender?.avatarUrl ? (
                <Style.ProfileAvatar
                  image={notificationItem?.sender?.avatarUrl}
                />
              ) : (
                <Avatar size={40}>
                  <UserOutlined />
                </Avatar>
              )}
              <div>
                {notificationItem.optionalInformation?.success ? (
                  <Text w6={!notificationItem.isRead} truncateMultiLine={2}>
                    {notificationItem.optionalInformation.message}
                  </Text>
                ) : (
                  <Space>
                    <img src={warningImg} alt="warningImg" draggable={false} />
                    <Text
                      w6={!notificationItem.isRead}
                      truncateMultiLine={2}
                      color="red"
                    >
                      {notificationItem.optionalInformation.message}
                    </Text>
                  </Space>
                )}
                <Text subText xs w6={!notificationItem.isRead}>
                  {moment(notificationItem.sentAt).fromNow()}
                </Text>
              </div>
            </Space>
          </Style.NotificationItem>
        );
      }
      default: {
        return null;
      }
      }
    });
  }

  return (
    <Style.NotificationDropdownContainer emptyNotify={isEmpty(data)}>
      <Style.NotificationHeader>
        <Text w6 lg>通知</Text>
        {!isEmpty(data) && (
          <Button
            type="link"
            onClick={() => markNotificationsAsRead()}
            style={{ padding: '7px 0' }}
          >
            すべて既読にする
          </Button>
        )}
      </Style.NotificationHeader>
      <Spin spinning={dropdownNotifications.load}>
        <Style.NotificationDropdownContent>
          <InfiniteScroll
            pageStart={0}
            hasMore={!!meta.pageInfo?.next && !dropdownNotifications.load}
            useWindow={false}
            loadMore={() => {
              if (!!meta.pageInfo?.next && !dropdownNotifications.load) {
                getDropdownNotifications({
                  lastSentId: data[data.length - 1].id,
                  more: true,
                });
              }
            }}
          >
            {renderNotificationItem()}
          </InfiniteScroll>
        </Style.NotificationDropdownContent>
      </Spin>
      {!isEmpty(data) && (
        <Style.NotificationShowMore>
          <Button
            type="link"
            onClick={() => {
              history.push(`${isStudent ? '' : isCompany ? '/admin' : '/admin-service'}/notifications`);
              setIsShowNotiDropdown(false);
            }}
          >
            通知一覧へ
          </Button>
        </Style.NotificationShowMore>
      )}
    </Style.NotificationDropdownContainer>
  );
};


const mapStateToProps = (state) => {
  const { dropdownNotifications } = state.notificationReducer;
  return { dropdownNotifications };
};

const mapDispatchToProps = (dispatch) => ({
  getDropdownNotifications: (params) => dispatch(getDropdownNotificationsAction(params)),
  markNotificationsAsRead: () => dispatch(markNotificationsAsReadAction()),
  getUploadAnswerDetail: (params) => dispatch(getUploadAnswerDetailAction(params)),
  getTestResults: (params) => dispatch(getTestResultsAction(params)),
  getCurrentTime: (params) => dispatch(getCurrentTimeAction(params)),
  showModal: (params) => dispatch(showModalAction(params))
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdown);
