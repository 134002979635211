// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import { QUESTION_STATUS_OPTION } from 'src/constants/common';
import {
  selectQuestionStatus as selectQuestionStatusAction,
} from 'src/redux/actions';

interface ISelectQuestionStatusModalProps extends DispatchProps {
  selectQuestionStatus(params): void;
  modalData: {
    questionId: number;
    companyStatus: string;
  };
}
const SelectQuestionStatusModal: React.FC<ISelectQuestionStatusModalProps> = ({
  modalData,
  selectQuestionStatus,
}) => {
  const [questionStatusSelected, setQuestionStatusSelected] = useState<any>([modalData.companyStatus]);

  function handleSubmitModal() {
    return selectQuestionStatus({
      requestData: {
        courseQuestion: {
          status: questionStatusSelected[0],
        },
      },
      id: modalData.questionId,
    });
  }

  const columns = [
    {
      dataIndex: 'name',
    },
  ];

  const tableData =  QUESTION_STATUS_OPTION.map((row: any) => ({
    key: row.id,
    id: row.id,
    name: row.name,
  }));

  return (
    <>
      <Table
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [...questionStatusSelected],
          onChange: (key) => {
            setQuestionStatusSelected([...key]);
          },
        }}
        columns={columns}
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />
      <ModalFooter
        onSubmit={() => handleSubmitModal()}
        okText="選択する"
        cancelText="キャンセル"
        isConfirm
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectQuestionStatus: (params) => dispatch(selectQuestionStatusAction(params)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(SelectQuestionStatusModal);
