// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Table,
} from 'antd';

import {
  getTagList as getTagListAction,
  deleteTag as deleteTagAction,
  showModal as showModalAction,
} from 'src/redux/actions';

import ModifyTagsModal from '../ModifyTagsModal';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import * as Style from './styles';

interface ICategoryListPageProps extends StateProps, DispatchProps {
  showModal(params): void;
  getTagList(): void;
  deleteTag(params): void;
  tagList: {
    data: {
      id: number;
      name: string;
      description: string;
    }[];
    load: boolean;
  };
}

const CategoryListPage: React.FC<ICategoryListPageProps> = ({
  getTagList,
  deleteTag,
  showModal,
  tagList,
}) => {
  useEffect(() => {
    getTagList();
  }, []);

  function handleModifyTags(record, actionType) {
    const modalProps = {
      modalData: {
        title: actionType === 'add' ? 'タグ新規作成' : 'タグ修正',
        tag: {
          id: record?.id,
          name: record?.name,
          description: record?.description,
        },
      },
    };
    return showModal(<ModifyTagsModal {...modalProps} />);
  }

  function handleDeleteTags(id) {
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'このタグを削除してもよろしいですか？',
      },
      modalAction: {
        confirm: () => deleteTag({ id }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  const tableData = tagList.data.map((row) => ({
    key: row.id,
    id: row.id,
    name: row.name,
    description: row.description,
  }));

  const columns = [
    {
      title: 'タグ名',
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 250,
    },
    {
      title: 'タグ説明',
      dataIndex: 'description',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.description}</TooltipParagraph>
      ),
      width: 500,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Style.TableAction>
          <Button
            size="small"
            danger
            ghost
            style={{ marginRight: 16 }}
            onClick={() => handleDeleteTags(record.id)}
          >
            &nbsp;削除&nbsp;
          </Button>
          <Button
            size="small"
            type="primary"
            ghost
            onClick={() => handleModifyTags(record, 'edit')}
          >
            &nbsp;編集&nbsp;
          </Button>
        </Style.TableAction>
      ),
      width: 100,
    },
  ];

  return (
    <>
      <Style.TagAction>
        <Button
          type="primary"
          onClick={() => handleModifyTags(null, 'add' )}
        >
          新規作成
        </Button>
      </Style.TagAction>
      <Table
        pagination={false}
        columns={columns}
        dataSource={tableData}
        locale={{ emptyText: 'データが存在しません。' }}
        loading={tagList.load}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { tagList } = state.tagReducer;
  return {
    tagList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  getTagList: () => dispatch(getTagListAction()),
  deleteTag: (params) => dispatch(deleteTagAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CategoryListPage);
