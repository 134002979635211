// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';
import isEmpty from 'lodash/isEmpty';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import { updateUserEmail as updateUserEmailAction } from 'src/redux/actions';

import * as Style from './styles';

interface IChangeEmailModalProps extends StateProps, DispatchProps {
  updateUserEmail(params): void;
  modalData: {
    id: string;
    email: string;
  };
  changeEmail: {
    errors: {
      message: string;
    }[];
  };
}

const ChangeEmailModal: React.FC<IChangeEmailModalProps> = ({
  updateUserEmail,
  modalData,
  changeEmail,
}) => {
  const [changeEmailForm] = Form.useForm();

  useEffect(() => {
    if (!isEmpty(changeEmail.errors)) {
      changeEmailForm.setFields([
        {
          name: 'email',
          errors: [changeEmail.errors[0].message],
        },
      ]);
    }
  }, [changeEmail.errors]);

  function handleSubmitForm(values) {
    updateUserEmail({
      id: modalData.id, requestData: {
        user: values
    } });
  }

  return (
    <Form
      form={changeEmailForm}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      name="updateUserEmailForm"
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.FieldContent>
        <Form.Item
          label="新しいメールアドレス"
          name="email"
          labelAlign="left"
          colon={false}
          normalize={(value) => value.trim()}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            { required: true, whitespace: true, message: '新しいメールアドレスは必須項目です。' },
            { type: 'email', message: '新しいメールアドレスのフォーマットが正しくありません。' },
            { max: 50, message: '新しいメールアドレスが長すぎます。（最大は50桁です）' },
            {
              validator: (_, value) => value !== modalData.email
                ? Promise.resolve()
                : Promise.reject('現在のメールアドレスとは別のメールアドレスを入力してください。'),
            },
          ]}
        >
          <Input placeholder={modalData.email} />
        </Form.Item>
      </Style.FieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { changeEmail } = state.profileReducer;
  
  return {
    changeEmail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserEmail: (params) => dispatch(updateUserEmailAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmailModal);
