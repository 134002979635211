// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from './restClient';

export default class AuthServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  login(params) {
    return this.restClient.post('/login', params);
  }

  logout() {
    return this.restClient.delete('/logout');
  }

  forgotPassword(params) {
    return this.restClient.post('/reset_passwords', params);
  }

  resetPassword(params) {
    return this.restClient.put('/reset_passwords', params);
  }

  getResetPasswordStatus(params) {
    return this.restClient.get('/reset_passwords', params);
  }
}
