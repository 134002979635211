// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const TableAction = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const TypeFieldContent = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
`;

export const TypeFieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 16px;
`;

export const SearchFieldContent = styled.div`
  display: flex;
  align-items: center;
`;

export const UnitItemHeaderRow = styled.div`
  display: flex;
  align-items: center;
  padding-right: 17px; /* Scroller width */
  height: 50px;
  background-color: #666666;
`;

export const UnitItemHeaderCol = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: ${(props) => props.width || 'auto'};
`;

export const UnitItemListContainer = styled.div`
  height: 300px;
  overflow-y: auto;
`;

export const UnitItemListRow = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #F0F0F0;
  background-color: white;

  &:hover {
    background-color: #FAFAFA;
  }

  ${(props) => props.active
    && css`
      background-color: #E6F9FF;
    `}
`;

export const UnitItemListCol = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: ${(props) => props.width || 'auto'};
`;

export const RadioCustom = styled.label`
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  & > span {
    position: relative;
    display: block;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.colors.primaryBorder};
    border-radius: ${(props) => props.theme.radius.circle};
    background-color: white;
    transition: all 0.3s;
  }

  &:hover input ~ span {
    border: 1px solid #0DA2FE;
  }

  & > input:checked ~ span {
    border: 1px solid #0DA2FE;
  }

  & > span:after {
    content: "";
    position: absolute;
    display: none;
  }

  & > input:checked ~ span:after {
    display: block;
  }

  & > span:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: ${(props) => props.theme.radius.circle};
    background-color: #0DA2FE;
  }
`;
