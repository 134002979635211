// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';

import {
  STUDENT,
  COMMON_ACTION,
  LOADING_ACTION,
  SUCCESS,
  FAILURE,
  REQUEST,
} from '../../constant';

import { studentCourseServices } from 'src/services';
import history from 'src/utils/history';

function* getGroupDetailSaga(action) {
  try {
    const { requestData, meta } = action.payload;
    const result = yield studentCourseServices.getGroupDetail(requestData);
    yield put({
      type: SUCCESS(STUDENT.COURSE_ACTION.GET_GROUP),
      payload: result,
    });
    if (meta.type) {
      const newCollapseData = meta.collapseData;
      if (meta.type === 'next') {
        newCollapseData.splice(meta.groupActiveIndex + 1, 0, {
          key: `group-${meta.groupDataIndex + 1}`,
          chapters: [
            {
              key: `group-${meta.groupDataIndex + 1}-chapter-0`,
            },
          ],
        });
      } else {
        const chaptersLength = result.data.group.chapters.length;
        newCollapseData.splice(meta.groupActiveIndex - 1, 0, {
          key: `group-${meta.groupDataIndex - 1}`,
          chapters: [
            {
              key: `group-${meta.groupDataIndex - 1}-chapter-${chaptersLength - 1}`,
            },
          ],
        });
      }
      yield put({
        type: REQUEST(COMMON_ACTION.SET_COLLAPSE_DATA),
        payload: newCollapseData,
      });
    }
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.COURSE_ACTION.GET_GROUP), errors: e.errors });
  }
}

function* getCourseDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield studentCourseServices.getCourseDetail(payload);
    yield put({
      type: SUCCESS(STUDENT.COURSE_ACTION.GET_DETAIL),
      payload: result,
    });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
    if (redirect) {
      yield history.push(redirect);
    }
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.COURSE_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* getStudentTopGroupDetailSaga(action) {
  try {
    const result = yield studentCourseServices.getGroupDetail(action.payload);
    yield put({
      type: SUCCESS(STUDENT.COURSE_ACTION.GET_TOP_GROUP_DETAIL),
      payload: {
        data: result.data,
        meta: {
          ...result.meta,
          groupIndex: action.payload.groupIndex,
        },
      },
    });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.COURSE_ACTION.GET_TOP_GROUP_DETAIL), errors: e.errors });
  }
}

function* createQuestionSaga(action) {
  try {
    const result = yield studentCourseServices.createQuestion(action.payload);
    yield put({
      type: SUCCESS(STUDENT.COURSE_ACTION.CREATE_QUESTION),
      payload: result,
    });
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.COURSE_ACTION.CREATE_QUESTION), errors: e.errors });
  }
}

function* getStudentCourseDetailStatusSaga(action) {
  try {
    const result = yield studentCourseServices.getStudentCourseDetailStatus(action.payload);
    yield put({
      type: SUCCESS(STUDENT.COURSE_ACTION.GET_DETAIL_STATUS),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.COURSE_ACTION.GET_DETAIL_STATUS), errors: e.errors });
  }
}


function* getStudentRecommendDateSaga(action) {
  try {
    const result = yield studentCourseServices.getRecommendDate(action.payload);
    yield put({
      type: SUCCESS(STUDENT.COURSE_ACTION.GET_RECOMMEND_DATE),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(STUDENT.COURSE_ACTION.GET_RECOMMEND_DATE), errors: e.errors });
  }
}

export default function* studentCourseSaga() {
  yield all([
    takeLatest(REQUEST(STUDENT.COURSE_ACTION.GET_GROUP), getGroupDetailSaga),
    takeLatest(REQUEST(STUDENT.COURSE_ACTION.GET_DETAIL), getCourseDetailSaga),
    takeLatest(REQUEST(STUDENT.COURSE_ACTION.GET_TOP_GROUP_DETAIL), getStudentTopGroupDetailSaga),
    takeLatest(REQUEST(STUDENT.COURSE_ACTION.CREATE_QUESTION), createQuestionSaga),
    takeLatest(REQUEST(STUDENT.COURSE_ACTION.GET_DETAIL_STATUS), getStudentCourseDetailStatusSaga),
    takeLatest(REQUEST(STUDENT.COURSE_ACTION.GET_RECOMMEND_DATE), getStudentRecommendDateSaga),
  ]);
}
