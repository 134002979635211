// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const MY_PROFILE_ACTION = {
  GET_PROFILE_INFO: 'PROFILE_ACTION/GET_PROFILE_INFO',
  GET_MY_JOINED_COURSES: 'PROFILE_ACTION/GET_MY_JOINED_COURSES',
  UPDATE_MY_COURSES_DURATION: 'PROFILE_ACTION/UPDATE_MY_COURSES_DURATION',
  UPDATE_USER_INFO: 'PROFILE_ACTION/UPDATE_USER_INFO',
  UPDATE_CUSTOM_USER_INFO: 'PROFILE_ACTION/UPDATE_CUSTOM_USER_INFO',
  UPDATE_AVATAR: 'PROFILE_ACTION/UPDATE_AVATAR',
  UPDATE_EMAIL: 'PROFILE_ACTION/UPDATE_EMAIL',
  UPDATE_PASSWORD: 'PROFILE_ACTION/UPDATE_PASSWORD',
  UPDATE_PASSWORD_AND_EMAIL: 'PROFILE_ACTION/UPDATE_PASSWORD_AND_EMAIL',
  CLEAR_UPDATE_EMAIL_ERRORS: 'PROFILE_ACTION/CLEAR_UPDATE_EMAIL_ERRORS',
  CLEAR_UPDATE_PASSWORD_ERRORS: 'PROFILE_ACTION/CLEAR_UPDATE_PASSWORD_ERRORS',
  GET_TEST_STUDENT: 'PROFILE_ACTION/GET_TEST_STUDENT',
  RESET_MY_TEST_HISTORY: 'PROFILE_ACTION/RESET_MY_TEST_HISTORY',
  GET_COURSE_COMPANY: 'PROFILE_ACTION/GET_COURSE_COMPANY',
  UPDATE_COURSE_MANAGER: 'PROFILE_ACTION/UPDATE_COURSE_MANAGER',
  RESET_ERROR_CHANGE_MAIL_PASSWORD: 'RESET_ERROR_CHANGE_MAIL_PASSWORD'
};
