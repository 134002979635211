// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Space,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash/lang';

import history from 'src/utils/history';

import {
  login as loginAction,
  closeModal as closeModalAction,
  clearFlagGetResetError as clearFlagGetResetErrorAction,
  clearError as clearErrorAction,
} from 'src/redux/actions';

import { Text } from 'src/components/styles';

import {
  ServicePath,
  CompanyPath,
  StudentPath,
} from 'src/constants/routerConstants';
import { LOGIN_TITLE } from 'src/constants/common';
import { PAGE_TITLE, LoginTitle } from 'src/constants/pageTitle';

import brandLogo from 'src/assets/images/login/brand-logo.svg';
import brandName from 'src/assets/images/login/brand-name.svg';
import serviceBrandLogo from 'src/assets/images/common/brand-logo.svg';

import * as Style from './styles';

interface ILoginPageProps extends StateProps, DispatchProps {
  login(params): void;
  closeModal(): void;
  clearFlagGetResetError(): void;
  clearError(): void;
  role: string;
  errors: {
    message: string;
    code?: number;
  }[];
  isFailGetResetPassword: boolean
}

const LoginPage: React.FC<ILoginPageProps> = ({
  login,
  closeModal,
  clearFlagGetResetError,
  clearError,
  role,
  errors,
  isFailGetResetPassword
}) => {
  useEffect(() => {
    document.title = PAGE_TITLE(LoginTitle);
    if(!isFailGetResetPassword) {
      closeModal(); // Fix when logout another browser tab, modal still exists
    }
    clearFlagGetResetError()

    return () => {
      clearError()
    }
  }, []);

  const [loginForm] = Form.useForm();

  function redirectForgotPassword() {
    if (role === 'admin_service') {
      return history.push(ServicePath.ForgotPassword);
    } else if (role === 'admin_company') {
      return history.push(CompanyPath.ForgotPassword);
    } else {
      return history.push(StudentPath.ForgotPassword);
    }
  }

  function validFullSpacePW(_, value) {
    if (value && value.trim() === "") {
      return Promise.reject("パスワードにスペースは使用できません。");
    }
    return Promise.resolve();
  }

  function handleBlurPassword(e, loginForm) {
    !isEmpty(errors) && clearError();
    const value = e.target?.value;

    if (value && value.trim() === '') {
      loginForm.setFieldsValue({
        password: "",
      });
    }
  }

  function renderFooterLinks() {
    if (role !== 'admin_service') {
      return (
        <Style.FooterLinks>
          <Style.CompanyLinks>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/company/', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                会社概要
              </Style.ItemLink>
            </Style.FooterItem>
            <Text white margin="0 10px"> | </Text>
            <Style.FooterItem
              onClick={() => window.open('https://share.hsforms.com/118qwvfYSTH6mTxLq8hFoJg2mtef', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                LMSに関するお問い合わせ
              </Style.ItemLink>
            </Style.FooterItem>
            <Text white margin="0 10px"> | </Text>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/lms-scai-terms-of-use/', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                利用規約
              </Style.ItemLink>
            </Style.FooterItem>
          </Style.CompanyLinks>
          <Style.PolicyLink>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/privacy/', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                プライバシーポリシー
              </Style.ItemLink>
            </Style.FooterItem>
            <Text white margin="0 10px"> | </Text>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/security-policy/', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                情報セキュリティーに関する方針
              </Style.ItemLink>
            </Style.FooterItem>
          </Style.PolicyLink>
        </Style.FooterLinks>
      );
    }
    return null;
  }

  function renderFooterLinksMobile() {
    if (role !== 'admin_service') {
      return (
        <Style.FooterLinksMobile>
          <Style.CompanyLinks>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/company/', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                会社概要
              </Style.ItemLink>
            </Style.FooterItem>
            <Text white margin="0 10px"> | </Text>
            <Style.FooterItem
              onClick={() => window.open('https://share.hsforms.com/118qwvfYSTH6mTxLq8hFoJg2mtef', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                LMSに関するお問い合わせ
              </Style.ItemLink>
            </Style.FooterItem>
          </Style.CompanyLinks>
          <Style.CompanyLinks>
          <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/lms-scai-terms-of-use/', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                利用規約
              </Style.ItemLink>
            </Style.FooterItem>
            <Text white margin="0 10px"> | </Text>
            <Style.FooterItem
              onClick={() => window.open('https://skillup-next.co.jp/privacy/', '_blank')}
            >
              <Style.ItemLink
                white
                pointer
              >
                プライバシーポリシー
              </Style.ItemLink>
            </Style.FooterItem>
          </Style.CompanyLinks>
          <Style.CompanyLinks>
            <Style.FooterItem onClick={() => window.open('https://skillup-next.co.jp/security-policy/', '_blank')}>
              <Style.ItemLink
                white
                pointer
              >
                情報セキュリティーに関する方針
              </Style.ItemLink>
            </Style.FooterItem>
          </Style.CompanyLinks>
        </Style.FooterLinksMobile>
      );
    }
    return null;
  }

  return (
    <Style.LoginContainer>
      <Style.LoginBackground isAdminService={role === 'admin_service'}>
        <img src={role === 'admin_service' ? serviceBrandLogo : brandLogo} alt="" className="logo" />
        <img src={brandName} alt="" className="brand" />
        {renderFooterLinks()}
      </Style.LoginBackground>
      <Style.LoginContent>
        <Form
          form={loginForm}
          name="loginForm"
          onFinish={(values) => login({
            user: {
              email: values.email.trim(),
              password: values.password,
              role,
            },
          })}
        >
          <Style.LoginForm>
            <Style.LoginField>
              <Style.TitleLogin
                xl
                w6
                headerText
                style={{ marginBottom: isEmpty(errors) ? 18 : 0 }}
              >
                {LOGIN_TITLE[role]}
              </Style.TitleLogin>
              {!isEmpty(errors) &&
                (errors[0].code === 1101 ? (
                  <Text error style={{ marginBottom: 2 }}>
                    ログインパスワードを連続で間違えたためアカウントがロックされました。解除の申請をする場合は
                    <Style.Link
                      href="https://share.hsforms.com/118qwvfYSTH6mTxLq8hFoJg2mtef"
                      target="_blank"
                    >
                      こちら
                    </Style.Link>
                    からお問い合わせください。
                  </Text>
                ) : (
                  <Text error style={{ marginBottom: 2 }}>
                    {errors[0].message}
                  </Text>
                ))}
              <Text margin="0 0 0 5px">メールアドレス</Text>
              <Form.Item
                name="email"
                normalize={(value) => value.trim()}
                validateFirst
                rules={[
                  { required: true, message: 'メールアドレスは必須項目です。' },
                  { whitespace: true, message: 'メールアドレスは必須項目です。' },
                  { type: 'email', message: '	メールアドレスのフォーマットが正しくありません。' },
                ]}
              >
                <Style.InputEmail
                  placeholder="メールアドレス"
                  onBlur={() => !isEmpty(errors) && clearError()}
                />
              </Form.Item>
              <Text margin="0 0 0 5px">パスワード</Text>
              <Form.Item name="password"
                rules={[
                  { required: true, message: 'パスワードは必須項目です。' },
                  { validator: (_, value) => validFullSpacePW(_, value) },
                ]}>
                <Style.InputPassWord
                  placeholder="パスワード"
                  visibilityToggle={false}
                  onBlur={(e) => handleBlurPassword(e, loginForm)}
                />
              </Form.Item>
              <Style.ButtonLogin
                type="primary"
                block
                htmlType="submit"
              >
                ログインする
              </Style.ButtonLogin>
              <div>
                <Space align="center">
                  <RightOutlined style={{ color: '#D29C09' }} />
                  <Style.Link sm onClick={() => redirectForgotPassword()}>
                    パスワードを忘れた方はこちら
                  </Style.Link>
                </Space>
              </div>
              {role === 'student' && (
                <div>
                  <Space align="center">
                    <RightOutlined style={{ color: '#D29C09' }} />
                    <Style.Link sm onClick={() => history.push(CompanyPath.Login)}>
                      管理者のログインはこちら
                    </Style.Link>
                  </Space>
                </div>
              )}
              {role === 'admin_company' && (
                <div>
                  <Space align="center">
                    <RightOutlined style={{ color: '#D29C09' }} />
                    <Style.Link sm onClick={() => history.push(StudentPath.Login)}>
                      受講者のログインはこちら
                    </Style.Link>
                  </Space>
                </div>
              )}
            </Style.LoginField>
          </Style.LoginForm>
        </Form>
        <Style.CopyrightContent>
          {renderFooterLinksMobile()}
          <Text subText className="copyright" ><span>©</span>SKILLUP NeXt, Ltd.</Text>
        </Style.CopyrightContent>
      </Style.LoginContent>
    </Style.LoginContainer>
  );
};

const mapStateToProps = (state) => ({
  errors: state.authReducer.errors,
  isFailGetResetPassword: state.authReducer.isFailGetResetPassword,
});

const mapDispatchToProps = (dispatch) => ({
  login: (params) => dispatch(loginAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  clearFlagGetResetError: () => dispatch(clearFlagGetResetErrorAction()),
  clearError: () => dispatch(clearErrorAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
