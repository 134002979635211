// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const OverallInfoField = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding: 0 40px 0 16px;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const CourseInfo = styled.div`
  display: none;

  @media (max-width: 428px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 50px;
    border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

    &:last-child {
      border-bottom: none;
    }
  }
`;
