// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { isEmpty } from 'lodash';

import { formatDateTime, convertSecondTime } from 'src/utils/dateTime';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import SortableHeader from 'src/components/tables/SortableHeader';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import { getVideoList as getVideoListAction } from 'src/redux/actions';

interface IVideoListPageProps extends StateProps, DispatchProps {
  getVideoList(params): void;
  videoList: {
    data: {
      id: number;
      duration: number;
      updatedAt: string;
      tags: {
        name: string;
      }[];
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: string;
  };
}

const VideoListPage: React.FC<IVideoListPageProps> = ({ getVideoList, videoList }) => {
  const { data, meta } = videoList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<any>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.VideoList);

    getVideoList({ page: 1 });
  }, []);

  const columns = [
    {
      title: <SortableHeader
        type="name"
        title="動画名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getVideoList}
        setAction={setSortCondition}
      />,
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.name}
        </TooltipParagraph>
      ),
      width: 350,
    },
    {
      title: <SortableHeader
        type="category"
        title="講座タイプ"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getVideoList}
        setAction={setSortCondition}
      />,
      dataIndex: 'category',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.category}
        </TooltipParagraph>
      ),
      width: 150,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.tags}
        </TooltipParagraph>
      ),
      width: 150,
    },
    {
      title: <SortableHeader
        type="duration"
        title="動画時間"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getVideoList}
        setAction={setSortCondition}
      />,
      dataIndex: 'duration',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.duration}
        </TooltipParagraph>
      ),
      width: 150,
    },
    {
      title: <SortableHeader
        type="teacherName"
        title="講師"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getVideoList}
        setAction={setSortCondition}
      />,
      dataIndex: 'teacherName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.teacherName}
        </TooltipParagraph>
      ),
      width: 150,
    },
    {
      title: <SortableHeader
        type="updatedAt"
        title="更新日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getVideoList}
        setAction={setSortCondition}
      />,
      dataIndex: 'updatedAt',
      width: 150,
    },
  ];

  const tableData = data.map((item) => ({
    ...item,
    tags: item.tags.map((tag) => tag.name).join(', '),
    key: item.id,
    duration: convertSecondTime(item.duration, 'HH:mm:ss'),
    updatedAt: formatDateTime(item.updatedAt, DATE_FORMAT),
  }));

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '更新日',
    },
    {
      type: 'textInput',
      fieldParams: 'category',
      title: '講座タイプ',
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: '動画名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getVideoList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>動画一覧</Text>
        </TopContent>
        <Table
          pagination={false}
          scroll={{ x: 1100, y: 506 }}
          columns={columns}
          dataSource={tableData}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={videoList.load}
        />
        {!isEmpty(videoList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getVideoList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => ({
  videoList: state.videoReducer.videoList,
});

const mapDispatchToProps = (dispatch) => ({
  getVideoList: (params) => dispatch(getVideoListAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(VideoListPage);
