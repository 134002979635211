// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/ja';
import { connect } from 'react-redux';
import { Button, Space, Avatar } from 'antd';
import { isEmpty } from 'lodash/lang';
import { UserOutlined, ReloadOutlined } from '@ant-design/icons';
import warningImg from "src/assets/images/modalImage/warning.svg"

import {
  MainContainer,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import NoData from 'src/components/layouts/NoData';
import { PaginationLayout } from 'src/components/layouts/Pagination';

import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getNotificationList as getNotificationListAction,
  markNotificationsAsRead as markNotificationsAsReadAction,
  getUploadAnswerDetail as getUploadAnswerDetailAction,
  getTestResults as getTestResultsAction,
  getCurrentTime as getCurrentTimeAction,
  showModal as showModalAction
} from 'src/redux/actions';

import * as Style from './styles';
import AddCsvModal from 'src/components/Modal/components/AddCsvModal';

interface INotificationListPageProps extends StateProps, DispatchProps {
  getNotificationList(params): void;
  markNotificationsAsRead(): void;
  getUploadAnswerDetail(params): void;
  getTestResults(params): void;
  getCurrentTime(params): void;
  showModal(params): void;
  role: string;
  notificationList: {
    data: {
      id: number;
      sender: {
        id: number;
        jituName: string;
        kanaName: string;
        avatarUrl: string;
      };
      course: {
        id: number;
        name: string;
        courseCategory: {
          name: string;
        };
      };
      item: {
        id: number;
        type: string;
      };
      unit: {
        id: number;
        name: string;
      };
      sentAt: string;
      isRead: boolean;
      action: string;
      company:{
        id: number,
        contractName: string,
        name: string
      }
      optionalInformation: {
        numberEmailInvited: number,
        numberEmailSuccess: number,
        message: string,
        success?: boolean
      }
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: [];
  };
}

const NotificationListPage: React.FC<INotificationListPageProps> = ({
  getNotificationList,
  markNotificationsAsRead,
  getUploadAnswerDetail,
  getTestResults,
  role,
  notificationList,
  getCurrentTime,
  showModal
}) => {
  moment.locale('ja');
  const isStudent = role === 'student';
  const { data, meta } = notificationList;

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.NotificationList);

    getNotificationList({ page: 1, items: 10 });
  }, []);

  function renderNotificationItems() {
    if (notificationList.load) {
      return [...Array(10)].map((_, index) => (
        <Style.NotificationContent unread key={index}>
          <Style.NotificationWrapper>
            <SkeletonLoading avatar active title={false} paragraph={{ rows: 2 }} />
            <Style.NotificationTime>
              <Space>
                <SkeletonLoading active title={{ width: 100 }} paragraph={false} />
              </Space>
            </Style.NotificationTime>
          </Style.NotificationWrapper>
        </Style.NotificationContent>
      ));
    }

    if (isEmpty(data)) return <NoData message="データが存在しません。" />;

    return data.map((notificationItem) => {
      switch (notificationItem.action) {
      case 'uploaded_answer_file': {
        return (
          <Style.NotificationContent
            key={`notification-item-${notificationItem.id}`}
            unread={!notificationItem.isRead}
            onClick={() => getUploadAnswerDetail({
              userAnswerId: notificationItem.item.id,
              requestData: {
                isNotification: true,
                markNotificationAsReadId: notificationItem.id,
              },
            })}
          >
            <Style.NotificationWrapper isRead={notificationItem.isRead}>
              {notificationItem?.sender?.avatarUrl
                ? <Style.ProfileAvatar image={notificationItem?.sender?.avatarUrl} />
                : <Avatar size={40}><UserOutlined /></Avatar>
              }
              <Text w6={!notificationItem.isRead} style={{ marginLeft: 10 }}>
                {`${notificationItem?.sender?.jituName}が${notificationItem?.unit?.name}の回答をアップロードしました`}
              </Text>
              <Style.NotificationTime>
                <Text subText xs w6={!notificationItem.isRead}>
                  {moment(notificationItem.sentAt).fromNow()}
                </Text>
              </Style.NotificationTime>
            </Style.NotificationWrapper>
          </Style.NotificationContent>
        );
      }
      case 'scored_answer_file': {
        return (
          <Style.NotificationContent
            unread={!notificationItem.isRead}
            key={notificationItem.id}
            onClick={() => getTestResults({
              courseId: notificationItem.course.id,
              requestData: {
                isNotification: true,
                testsUserId: notificationItem.item.id,
                markNotificationAsReadId: notificationItem.id,
              },
            })}
          >
            <Style.NotificationWrapper>
              <Text w6={!notificationItem.isRead} style={{ marginLeft: 10 }}>
                {`${notificationItem?.unit?.name}の採点が完了しました`}
              </Text>
              <Style.NotificationTime>
                <Text subText xs w6={!notificationItem.isRead}>
                  {moment(notificationItem.sentAt).fromNow()}
                </Text>
              </Style.NotificationTime>
            </Style.NotificationWrapper>
          </Style.NotificationContent>
        );
      }
      case "imported_users_file": {
        return (
          <Style.NotificationContent
            key={`notification-${notificationItem.id}`}
            unread={!notificationItem.isRead}
            onClick={() => {
              getCurrentTime({
                requestData: {
                  markNotificationAsReadId: notificationItem.id,
                }
              })
              const modalDataSuccess = {
                isOk: true,
                modalData: {
                  title: 'CSVファイルのアップロード',
                  okText: '完了',
                  contractName: notificationItem.company.contractName,
                  courseName: notificationItem.course.courseCategory.name,
                  responeDataSuccess: {
                    numberSuccess: notificationItem.optionalInformation.numberEmailSuccess,
                    numberInviteEmails: notificationItem.optionalInformation.numberEmailInvited,
                  }
                },
              }
              const modalDataError = {
                isOk: true,
                modalData: {
                  title: 'CSVファイルのアップロード',
                  okText: '完了',
                  contractName: notificationItem.company?.contractName,
                  courseName: notificationItem.course?.courseCategory.name,
                  errorServer: 'インポート中にエラーが発生しました。再度インポートをしてください。'
                },
              }

              if(notificationItem.optionalInformation.message ){
                return showModal(<AddCsvModal {...modalDataError}/>)
              }
              return showModal(<AddCsvModal {...modalDataSuccess}/>)
              }
            }
          >
             <Space size={12}>
              {notificationItem?.sender?.avatarUrl
                ? <Style.ProfileAvatar image={notificationItem?.sender?.avatarUrl} />
                : <Avatar size={40}><UserOutlined /></Avatar>
              }
              <div>
                  {notificationItem.optionalInformation.message ? 
                    <>
                      <img src={warningImg} alt="warningImg" draggable={false} />
                      <Text w6={!notificationItem.isRead} truncateMultiLine={2} color="red">
                        {notificationItem.optionalInformation.message}
                      </Text>
                    </>  
                    : (
                      <>
                        <Text w6={!notificationItem.isRead} truncateMultiLine={2} >
                          {notificationItem.optionalInformation.numberEmailSuccess}件のライセンス付与、
                        </Text>
                        <Text w6={!notificationItem.isRead} truncateMultiLine={2}>
                          {notificationItem.optionalInformation.numberEmailInvited}件のユーザー登録を行いました。
                        </Text>
                      </>
                    )}
              </div>
            </Space>
          </Style.NotificationContent>)
      }
      case "bulk_resend_invitation":
      case "bulk_cancel_invitation": {
        return (
          <Style.NotificationContent
            key={`notification-${notificationItem.id}`}
            unread={!notificationItem.isRead}
            onClick={() => {
              if (!notificationItem.isRead) {
                getCurrentTime({
                  requestData: {
                    markNotificationAsReadId: notificationItem.id,
                  },
                });
              }
            }}
          >
            <Style.NotificationWrapper isRead={notificationItem.isRead}>
              {notificationItem?.sender?.avatarUrl ? (
                <Style.ProfileAvatar
                  image={notificationItem?.sender?.avatarUrl}
                />
              ) : (
                <Avatar size={40}>
                  <UserOutlined />
                </Avatar>
              )}
              {notificationItem.optionalInformation?.success ? (
                <Text w6={!notificationItem.isRead} truncateMultiLine={2} style={{ marginLeft: 10 }}>
                  {notificationItem.optionalInformation.message}
                </Text>
              ) : (
                <Space style={{ marginLeft: 10 }}>
                  <img src={warningImg} alt="warningImg" draggable={false} />
                  <Text
                    w6={!notificationItem.isRead}
                    truncateMultiLine={2}
                    color="red"
                  >
                    {notificationItem.optionalInformation.message}
                  </Text>
                </Space>
              )}
              <Style.NotificationTime>
                <Text subText xs w6={!notificationItem.isRead}>
                  {moment(notificationItem.sentAt).fromNow()}
                </Text>
              </Style.NotificationTime>
            </Style.NotificationWrapper>
          </Style.NotificationContent>
        );
      }
      default: {
        return null;
      }
      }
    });
  }

  return (
    <MainContainer backgroundHeight={100} isStudent={isStudent}>
      <MainContent fullView>
        <TopContent height="50px">
          <Text w6 xxxl>通知一覧</Text>
        </TopContent>
        <Style.NotificationContainer>
          <Style.NotificationAction>
            {notificationList.load && (
              <Space style={{ margin: '7px 0' }} >
                <SkeletonLoading active title={{ width: 80 }} paragraph={false} />
              </Space>
            )}
            {!isEmpty(data) && !notificationList.load && (
              <>
                <Button
                  type="link"
                  onClick={() => getNotificationList({ page: 1, items: 10 })}
                  style={{ padding: '7px 0', marginRight: 24 }}
                >
                  <ReloadOutlined />
                  リロード
                </Button>
                <Button
                  type="link"
                  onClick={() => markNotificationsAsRead()}
                  style={{ padding: '7px 0' }}
                >
                  すべて既読にする
                </Button>
              </>
            )}
          </Style.NotificationAction>
          {renderNotificationItems()}
          {!isEmpty(data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getNotificationList}
          />
        )}
        </Style.NotificationContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { notificationList } = state.notificationReducer;
  return {
    notificationList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getNotificationList: (params) => dispatch(getNotificationListAction(params)),
  markNotificationsAsRead: () => dispatch(markNotificationsAsReadAction()),
  getUploadAnswerDetail: (params) => dispatch(getUploadAnswerDetailAction(params)),
  getTestResults: (params) => dispatch(getTestResultsAction(params)),
  getCurrentTime: (params) => dispatch(getCurrentTimeAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(NotificationListPage);
