// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const AnswerContainer = styled.div`
  width: 100%;
`;

export const AnswerTypeContent = styled.div`
  padding: 24px 20px 0;
  background-color: #FFF8E7;
`;

export const AnswerMainContent = styled.div`
  padding: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.secondaryBorder};
`;

export const AnswerOptionWrapper = styled.div`
  display: flex;

  & .ant-form-item-label {
    min-width: 100px;
  }
`;

export const AddAnswerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
`;
