// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_COMPANY, REQUEST } from "../../constant";

export function getCompanyProfileDetail(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_PROFILE_DETAIL),
    payload: params,
  };
}

export function companyUpdateUserRole(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.UPDATE_USER_ROLE),
    payload: params,
  };
}

export function clearCompanyUpdateRoleErrors() {
  return {
    type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.CLEAR_UPDATE_ROLE_ERRORS),
  };
}

export function getCompanyJoinedCourses(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_JOINED_COURSES),
    payload: params,
  };
}

export function getTestStudentCompany(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.GET_TEST_STUDENT),
    payload: params,
  };
}

export function resetHistoryTestCompany(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.RESET_TEST_COMPANY),
    payload: params,
  };
}

export function unlockUserCompany(params) {
  return {
    type: REQUEST(ADMIN_COMPANY.PROFILE_ACTION.UNLOCK_USER),
    payload: params,
  };
}
