// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import moment from 'moment';

import {
  MASTER_DATA_ACTION,
  SUCCESS,
  FAILURE,
} from '../constant';

const initialState = {
  careerList: {
    data: [],
    meta: {},
    errors: [],
  },
  provinceList: {
    data: [],
    meta: {},
    errors: [],
  },
  currentTime: {
    data: moment(),
    errors: [],
  },
  checkVideo: {
    data: [],
    meta: {},
    errors: [],
  },
  currentUser: {
    data: [],
    meta: {},
    errors: [],
  },
};

export type MasterDataReducer = Readonly<typeof initialState>;

export default (state: MasterDataReducer = initialState, action: any): MasterDataReducer => {
  switch (action.type) {
  case SUCCESS(MASTER_DATA_ACTION.GET_CAREER_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      careerList: {
        data: data.careers,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(MASTER_DATA_ACTION.GET_CAREER_LIST): {
    const { errors } = action;
    return {
      ...state,
      careerList: {
        ...state.careerList,
        errors,
      },
    };
  }

  case SUCCESS(MASTER_DATA_ACTION.GET_PROVINCE_LIST): {
    const { data, meta } = action.payload;
    return {
      ...state,
      provinceList: {
        data: data.provinces,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(MASTER_DATA_ACTION.GET_PROVINCE_LIST): {
    const { errors } = action;
    return {
      ...state,
      provinceList: {
        ...state.provinceList,
        errors,
      },
    };
  }

  case SUCCESS(MASTER_DATA_ACTION.GET_CURRENT_TIME): {
    const { data } = action.payload;
    return {
      ...state,
      currentTime: {
        data: data.currentTime,
        errors: [],
      },
    };
  }
  case FAILURE(MASTER_DATA_ACTION.GET_CURRENT_TIME): {
    const { errors } = action;
    return {
      ...state,
      currentTime: {
        ...state.currentTime,
        errors,
      },
    };
  }

  case SUCCESS(MASTER_DATA_ACTION.GET_CHECK_VIDEO): {
    const { data, meta } = action.payload;
    return {
      ...state,
      checkVideo: {
        data: data.video,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(MASTER_DATA_ACTION.GET_CHECK_VIDEO): {
    const { errors } = action;
    return {
      ...state,
      checkVideo: {
        ...state.checkVideo,
        errors,
      },
    };
  }

  case SUCCESS(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO): {
    const { data, meta } = action.payload;
    return {
      ...state,
      currentUser: {
        data: data.user,
        meta,
        errors: [],
      },
    };
  }
  case FAILURE(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO): {
    const { errors } = action;
    return {
      ...state,
      currentUser: {
        ...state.currentUser,
        errors,
      },
    };
  }
  default:
    return state;
  }
};
