// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const CompanyInformationItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

  &:last-child {
    border-bottom: none;
  }
`;

export const ModifyCompanyInfo = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

export const EditCompanyContent = styled.div`
  padding: 16px 20px;

  & label {
    min-width: 180px;
  }
`;
