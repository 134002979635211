// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const AUTH_ACTION = {
  LOGIN: 'AUTH_ACTION/LOGIN',
  LOGOUT: 'AUTH_ACTION/LOGOUT',
  FORGOT_PASSWORD: 'AUTH_ACTION/FORGOT_PASSWORD',
  RESET_PASSWORD: 'AUTH_ACTION/RESET_PASSWORD',
  GET_RESET_PASSWORD_STATUS: 'AUTH_ACTION/GET_RESET_PASSWORD_STATUS',
  CLEAR_FLAG_GET_RESET_ERROR: 'AUTH_ACTION/CLEAR_FLAG_GET_RESET_ERROR',
  CLEAR_ERROR: 'AUTH_ACTION/CLEAR_ERROR',
};
