//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

import history from 'src/utils/history';

import {
  getUnitDetail as getUnitDetailAction,
  getCourseDetail as getCourseDetailAction,
} from 'src/redux/actions';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text, SkeletonLoading } from 'src/components/styles';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import * as Style from './styles';

interface ISurveyDetailPageProps extends StateProps, DispatchProps {
  getUnitDetail(params): void;
  getCourseDetail(params): void;
  unitDetail: {
    data: {
      id: number;
      item: {
        name: string;
        description: number;
        url: string;
      };
      name: string;
    };
    load: boolean;
  };
  courseDetail: {
    data: {
      id: number;
      name: string;
      courseCategory: {
        name: string;
      };
    };
    load: boolean;
  };
}

const SurveyDetailPage: React.FC<ISurveyDetailPageProps> = ({
  unitDetail,
  courseDetail,
  getUnitDetail,
  getCourseDetail,
}) => {
  const pathnameSplit = history.location.pathname.split('/');
  const courseId = pathnameSplit[3];
  const unitVersionId = pathnameSplit[5];

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.SurveyOverview);
  }, []);

  useEffect(() => {
    getUnitDetail({ id: unitVersionId });
    getCourseDetail({ id: courseId });
  }, [courseId, unitVersionId]);

  return (
    <MainContainer isStudent>
      <MainContent fullView>
        {courseDetail.load
          ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={22} />
          : <Text white w6 xxl>{courseDetail.data.courseCategory?.name}</Text>
        }
        <Style.SurveyDetailContainer>
          <Style.SurveyDetailTitle>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={16} />
              : <Text truncate white lg w6>{unitDetail.data?.name}</Text>
            }
          </Style.SurveyDetailTitle>
          <Style.SurveyDetailDescription>
            {courseDetail.load || unitDetail.load
              ? <SkeletonLoading active paragraph={{ rows: 3 }} title={false} />
              : (
                <Text multiLine>
                  {unitDetail.data.item?.description}
                </Text>
              )
            }
          </Style.SurveyDetailDescription>
          <Style.SurveyDetailFooter>
            <Button
              type="primary"
              onClick={() => window.open(unitDetail.data.item?.url, '_blank')}
              disabled={courseDetail.load || unitDetail.load}
            >
              回答する
            </Button>
          </Style.SurveyDetailFooter>
        </Style.SurveyDetailContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { unitDetail } = state.unitReducer;
  const { courseDetail } = state.courseReducer;
  return {
    unitDetail,
    courseDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUnitDetail: (params) => dispatch(getUnitDetailAction(params)),
  getCourseDetail: (params) => dispatch(getCourseDetailAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SurveyDetailPage);
