//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useRef, useState } from 'react';
import { Space } from 'antd';
import { isEmpty } from 'lodash/lang';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import { DATE_FORMAT } from 'src/constants/common';
import { randomLoadingWidth } from 'src/utils/common';
import { convertSecondTime, formatDateTime } from 'src/utils/dateTime';

import { Text, SkeletonLoading } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import * as Style from './styles';

interface ICourseOverviewProps {
  courseOverviewElement: any;
  courseTopDetail: {
    data: {
      name: string;
      overview: string;
      startDate: string;
      endDate: string;
      licensesCount: number;
      usedLicensesCount: number;
      recommendedStartDate: string;
      recommendedEndDate: string;
      remainingDays: number;
      courseCategory: {
        name: string;
      };
      isDisplayLogo: boolean;
      logo:{
        logoName: string;
        logoUrl: string;
      };
      category: {
        name: string;
      };
      teachers: {
        name: string;
      }[];
      totalVideosDuration: number;
    };
    load: boolean;
  };
}

const CourseOverview: React.FC<ICourseOverviewProps> = ({
  courseOverviewElement,
  courseTopDetail,
}) => {
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [isOverflow, setIsOverflow] = useState<boolean>(false);
  const elementRef = useRef<HTMLElement>(null);
  const teacherList = !isEmpty(courseTopDetail.data.teachers)
    ? courseTopDetail.data.teachers.map((teacher) => teacher.name)
    : [];

  useEffect(() => {
    if (elementRef.current) {
      setIsOverflow(
        elementRef.current.scrollHeight > elementRef.current.clientHeight ||
          elementRef.current.scrollWidth > elementRef.current.clientWidth
      );
    }
  },[courseTopDetail.data.overview])

  return (
    <Style.CourseOverviewContainer ref={courseOverviewElement}>
      {courseTopDetail.load
        ? (
          <Style.LoadingCourseTitle>
            <SkeletonLoading active paragraph={false} title={{ width: 300 }} height={24} />
            <SkeletonLoading
              active
              paragraph={{ rows: 3 }}
              title={false}
              height={16}
              margin="8px 0 0 0"
            />
          </Style.LoadingCourseTitle>
        )
        : (
          <Style.CourseNameContainer>
            <Style.CourseNameBlock>
              {courseTopDetail?.data.isDisplayLogo && (
                <Style.LogoCourse src={courseTopDetail?.data?.logo?.logoUrl} />
                )}
            <Style.CourseName headerText xxxl white w6 >
              {courseTopDetail.data.courseCategory?.name}
            </Style.CourseName>
          </Style.CourseNameBlock>
            <Style.CourseDesc headerText lg white isShowMore={isShowMore} ref={elementRef}>
                {courseTopDetail.data.overview}
            </Style.CourseDesc>
            {isOverflow ? (isShowMore
              ? <UpOutlined style={{ float: 'right', color: 'white' }} onClick={() => setIsShowMore(!isShowMore)} />
              : <DownOutlined style={{ float: 'right', color: 'white' }} onClick={() => setIsShowMore(!isShowMore)} />
            ) : null}
          </Style.CourseNameContainer>
        )
      }
      <Style.CourseOviewsBlock>
        <Style.CourseOverviewContent>
          {courseTopDetail.load
            ? (
              <Space size={36}>
                <SkeletonLoading active paragraph={false} title={{ width: 64 }} height={12} />
                <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 200) }} />
              </Space>
            )
            : (
              <>
                <Text xs white w6>動画時間</Text>
                <Text headerText white w6>
                  {courseTopDetail.data.totalVideosDuration > 0
                    ? convertSecondTime(courseTopDetail.data.totalVideosDuration, 'H時間m分')
                    : 'ー'
                  }
                </Text>
              </>
            )
          }
        </Style.CourseOverviewContent>
        <Style.CourseOverviewContent>
          {courseTopDetail.load
            ? (
              <Space size={36}>
                <SkeletonLoading active paragraph={false} title={{ width: 64 }} height={12} />
                <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 200) }} />
              </Space>
            )
            : (
              <>
                <Text xs white w6>担当講師</Text>
                <TooltipParagraph
                  style={{ color: 'white', fontWeight: 600 }}
                >
                  {teacherList.length > 0 ? teacherList.toString() : 'ー'}
                </TooltipParagraph>
              </>
            )
          }
        </Style.CourseOverviewContent>
        <Style.CourseOverviewContent>
          {courseTopDetail.load
            ? (
              <Space size={36}>
                <SkeletonLoading active paragraph={false} title={{ width: 64 }} height={12} />
                <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 200) }} />
              </Space>
            )
            : (
              <>
                <Text xs white w6>カテゴリー</Text>
                <TooltipParagraph style={{ fontWeight: 600, color: 'white' }}>
                  {!isEmpty(courseTopDetail.data.category) ? courseTopDetail.data.category.name : 'ー'}
                </TooltipParagraph>
              </>
            )
          }
        </Style.CourseOverviewContent>
        <Style.CourseOverviewContent>
          {courseTopDetail.load
            ? (
              <Space size={36}>
                <SkeletonLoading active paragraph={false} title={{ width: 64 }} height={12} />
                <SkeletonLoading active paragraph={false} title={{ width: randomLoadingWidth(100, 200) }} />
              </Space>
            )
            : (
              <>
                <Text xs white w6>受講期限</Text>
                <Space>
                  <Text headerText white w6>
                    {courseTopDetail.data.endDate ? formatDateTime(courseTopDetail.data.endDate, DATE_FORMAT) : 'ー'}
                  </Text>
                  {courseTopDetail.data.endDate && (
                    <Text xs headerText white w6>
                      [ 残り{courseTopDetail.data.remainingDays > 0 ? courseTopDetail.data.remainingDays : 0}日 ]
                    </Text>
                  )}
                </Space>
              </>
            )
          }
        </Style.CourseOverviewContent>
        {(courseTopDetail.data.recommendedStartDate && courseTopDetail.data.recommendedEndDate) && (
          <Style.CourseOverviewContent>
            <Text xs white w6>完了推奨期間</Text>
            <div>
              <Text headerText white w6>
              {`${formatDateTime(courseTopDetail.data.recommendedStartDate, DATE_FORMAT)} ~ ${formatDateTime(courseTopDetail.data.recommendedEndDate, DATE_FORMAT)}`}
              </Text>
            </div>
          </Style.CourseOverviewContent>
        )}
      </Style.CourseOviewsBlock>
    </Style.CourseOverviewContainer>
  );
};

export default CourseOverview;
