// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class TipServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getTipList(params) {
    return this.restClient.get('/admin_manage/tips', params);
  }

  getTipDetail(params) {
    return this.restClient.get(`/admin_manage/tips/${params.id}`);
  }

  createTip(params) {
    return this.restClient.post('/admin_manage/tips', params);
  }

  updateTip(params) {
    const { requestData, id } = params;
    return this.restClient.put(`/admin_manage/tips/${id}`, requestData);
  }
}
