// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { MASTER_DATA_ACTION, REQUEST } from '../constant';

export function getCareerList() {
  return {
    type: REQUEST(MASTER_DATA_ACTION.GET_CAREER_LIST),
  };
}

export function getProvinceList() {
  return {
    type: REQUEST(MASTER_DATA_ACTION.GET_PROVINCE_LIST),
  };
}

export function getCurrentTime(params = null) {
  return {
    type: REQUEST(MASTER_DATA_ACTION.GET_CURRENT_TIME),
    payload: params
  };
}

export function getCheckVideo(params) {
  return {
    type: REQUEST(MASTER_DATA_ACTION.GET_CHECK_VIDEO),
    payload: params,
  };
}

export function getCurrentUserInfo() {
  return {
    type: REQUEST(MASTER_DATA_ACTION.GET_CURRENT_USER_INFO),
  };
}
