// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Space } from 'antd';
import { isEmpty } from 'lodash';
import { isNumber } from 'lodash/lang';

import { formatDateTime } from 'src/utils/dateTime';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';
import SortableHeader from 'src/components/tables/SortableHeader';

import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import UploadScoringModal from 'src/components/Modal/components/UploadScoringModal';

import {
  DATE_FORMAT,
  TEST_TYPE,
  TEST_TYPE_OPTION,
  UPLOAD_ANSWER_STATUS,
} from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getUploadAnswerList as getUploadAnswerListAction,
  getCompanyContractList as getCompanyContractListAction,
  getMasterDataCourse as getMasterDataCourseAction,
  showModal as showModalAction,
  closeModal as closeModalAction,
} from 'src/redux/actions';

import docFileIcon from 'src/assets/images/common/doc-file-icon.svg';

import * as Style from './styles';

interface IUploadAnswerListPageProps extends StateProps, DispatchProps {
  getUploadAnswerList(params): void;
  getCompanyContractList(): void;
  getMasterDataCourse(params): void;
  showModal(params): void;
  closeModal(): void;
  companyContracts: {
    data: {
      id: number,
      contractName: string,
    }[],
  };
  uploadAnswerList: {
    data: {
      id: number;
      status: string;
      score: number;
      updatedAt: string;
      scoredAt: string;
      createdAt: string;
      answerFile: {
        filename: string;
        url: string;
      };
      user: {
        id: number;
        jituName: string;
      };
      course: {
        id: number;
        courseName: string;
        courseCategory: {
          name: string;
        };
      };
      company: {
        id: number;
        contractName: string;
      };
      question: {
        id: number;
        questionName: string;
      };
      test: {
        id: number;
        testName: string;
        testType: string;
      };
      unitVersionAble: {
        id: number;
        type: string;
      };
    }[],
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: object[];
  };
  masterDataCourses: {
    data: {
      id: number;
      courseCategory: {
        name: string;
      };
      name: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    errors: object[];
  };
}

const UploadAnswerListPage: React.FC<IUploadAnswerListPageProps> = ({
  getUploadAnswerList,
  getCompanyContractList,
  getMasterDataCourse,
  showModal,
  closeModal,
  companyContracts,
  uploadAnswerList,
  masterDataCourses,
}) => {
  const { data, meta } = uploadAnswerList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.UploadAnswerList);

    getUploadAnswerList({ page: 1 });
    getMasterDataCourse({ page: 1 });
    getCompanyContractList();

    return () => {
      closeModal()
    }
  }, []);

  function handleScoreUploadAnswer(record) {
    const modalProps = {
      width: 500,
      modalData: {
        title: `${TEST_TYPE[record.test.testType]}採点`,
        company: record.company,
        course: record.course,
        test: record.test,
        question:  record.question,
        user: record.user,
        answerFile: record.answerFile,
        usersAnswerId: record.id,
        score: record.score,
      },
    };
    return showModal(<UploadScoringModal {...modalProps} />);
  }

  const columns = [
    {
      title: <SortableHeader
        type="status"
        title="ステータス"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUploadAnswerList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <Style.AnswerStatus isScored={record.status === 'scored'}>
          {UPLOAD_ANSWER_STATUS[record.status]}
        </Style.AnswerStatus>
      ),
      dataIndex: 'status',
      width: 130,
    },
    {
      title: <SortableHeader
        type="testType"
        title="テスト種別"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUploadAnswerList}
        setAction={setSortCondition}
      />,
      dataIndex: 'testType',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.testType}
        </TooltipParagraph>
      ),
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="contractName"
          title="契約名"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getUploadAnswerList}
          setAction={setSortCondition}
        />,
      dataIndex: 'contractName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.company.contractName}
        </TooltipParagraph>
      ),
      width: 180,
    },
    {
      title:
        <SortableHeader
          type="courseName"
          title="コース名"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getUploadAnswerList}
          setAction={setSortCondition}
        />,
      dataIndex: 'courseName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.course.courseCategory.name}
        </TooltipParagraph>
      ),
      width: 180,
    },
    {
      title: <SortableHeader
        type="testName"
        title="テスト名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUploadAnswerList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.testName}
        </TooltipParagraph>
      ),
      dataIndex: 'testName',
      width: 180,
    },
    {
      title: <SortableHeader
        type="jituName"
        title="受講者名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUploadAnswerList}
        setAction={setSortCondition}
      />,
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.jituName}
        </TooltipParagraph>
      ),
      dataIndex: 'jituName',
      width: 180,
    },
    {
      title: <SortableHeader
        type="score"
        title="点数"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUploadAnswerList}
        setAction={setSortCondition}
      />,
      dataIndex: 'score',
      render: (_, record) => <Text>{isNumber(record.score) ? record.score : 'ー'}</Text>,
      width: 100,
    },
    {
      title: <SortableHeader
        type="createdAt"
        title="提出日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUploadAnswerList}
        setAction={setSortCondition}
      />,
      dataIndex: 'createdAt',
      width: 130,
    },
    {
      title: <SortableHeader
        type="scoredAt"
        title="採点日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getUploadAnswerList}
        setAction={setSortCondition}
      />,
      dataIndex: 'scoredAt',
      width: 130,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Style.AnswerAction>
          <Space align="center">
            <Style.DownloadFileButton
              onClick={() => window.open(record.answerFile?.url, '_blank')}
            >
              <img src={docFileIcon} alt="" />
            </Style.DownloadFileButton>
            <Button
              size="small"
              type="primary"
              ghost
              onClick={() => handleScoreUploadAnswer(record)}
            >
              &nbsp;採点&nbsp;
            </Button>
          </Space>
        </Style.AnswerAction>
      ),
      width: 100,
    },
  ];
  const tableData = data.map((item) => ({
    ...item,
    key: item.id,
    id: item.id,
    testType: TEST_TYPE[item.test.testType],
    contractName: item.company.contractName,
    courseName: item.course.courseName,
    testName: item.test.testName,
    questionName: item.question.questionName,
    jituName: item.user.jituName,
    score: item.score,
    createdAt: formatDateTime(item.createdAt, DATE_FORMAT),
    scoredAt: item.scoredAt ? formatDateTime(item.scoredAt, DATE_FORMAT) : 'ー',
  }));

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '提出日',
    },
    {
      type: 'select',
      title: 'ステータス',
      fieldParams: 'status',
      options: [
        { id: 'scored', name: '採点済' },
        { id: 'unscored', name: '未採点' },
      ],
    },
    {
      type: 'select',
      fieldParams: 'testType',
      options: TEST_TYPE_OPTION,
      title: 'テスト種別',
    },
    {
      type: 'select',
      title: '契約名',
      fieldParams: 'companyId',
      options: companyContracts.data.map((company) => ({ id: company.id, name: company.contractName })),
    },
    {
      type: 'select',
      title: 'コース名',
      fieldParams: 'courseId',
      searchFilter: getMasterDataCourse,
      options: masterDataCourses.data.map((course) => ({ id: course.id, name: `${course.courseCategory.name} (${course.name})` })),
      meta: masterDataCourses.meta,
    },
    {
      type: 'textInput',
      fieldParams: 'jituNameCont',
      title: '受講者名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getUploadAnswerList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>テスト採点</Text>
        </TopContent>
        <Table
          pagination={false}
          columns={columns}
          scroll={{ x: 1440, y: 506 }}
          dataSource={tableData}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={uploadAnswerList.load}
        />
        {!isEmpty(uploadAnswerList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getUploadAnswerList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};


const mapStateToProps = (state) => {
  const { companyContracts } = state.companyReducer;
  const { uploadAnswerList } = state.uploadAnswerReducer;
  const { masterDataCourses } = state.courseReducer;
  return {
    companyContracts,
    uploadAnswerList,
    masterDataCourses,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getUploadAnswerList: (params) => dispatch(getUploadAnswerListAction(params)),
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  getMasterDataCourse: (params) => dispatch(getMasterDataCourseAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(UploadAnswerListPage);
