// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Select } from 'antd';
import { isEmpty } from 'lodash/lang';

import { LoginStorage } from 'src/utils/localStorge';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import { updateUserRole as updateUserRoleAction } from 'src/redux/actions';

import * as Style from './styles';

interface IChangeRoleModalProps extends StateProps, DispatchProps {
  updateUserRole(params): void;
  modalData: {
    profileDetail: {
      id: number;
      roles: string[];
    };
    editorRole: string;
  };
  changeRole: {
    errors: {
      message: string;
    }[];
  };
}

const ChangeRoleModal: React.FC<IChangeRoleModalProps> = ({
  updateUserRole,
  modalData,
  changeRole,
}) => {
  const userStorage = LoginStorage.getData();
  const loggedUser = userStorage?.user;
  const [changeRoleForm] = Form.useForm();

  const rolesInitialFormValues = {
    roles: modalData.profileDetail.roles,
  };

  useEffect(() => {
    if (!isEmpty(changeRole.errors)) {
      changeRoleForm.setFields([
        {
          name: 'roles',
          errors: [changeRole.errors[0].message],
        },
      ]);
    }
  }, [changeRole.errors]);

  function handleSubmitForm(values) {
    updateUserRole({
      userId: modalData.profileDetail.id,
      usersRole: {
        roles: values.roles,
      },
    });
  }

  function renderTagSelected({ label, value, onClose }) {
    return (
      <Style.TagSelected
        color="#D29C09"
        closable={!(value === modalData.editorRole && loggedUser.id === modalData.profileDetail.id)}
        onClose={(e) => {
          e.preventDefault();
          onClose();
        }}
      >
        {label}
      </Style.TagSelected>
    );
  }

  return (
    <Form
      form={changeRoleForm}
      initialValues={rolesInitialFormValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 18 }}
      name="updateUserRoleForm"
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.FieldContent>
        <Form.Item
          label="アカウント種別"
          name="roles"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: 'アカウント種別は必須項目です。' },
          ]}
        >
          <Select
            style={{ overflowX: "hidden" }}
            mode="multiple"
            optionFilterProp="children"
            notFoundContent="データが存在しません。"
            tagRender={(props) => renderTagSelected(props)}
          >
            <Select.Option value="student">受講者</Select.Option>
            <Select.Option value="admin_company">企業管理者</Select.Option>
            <Select.Option
              value="admin_service"
              disabled={loggedUser.id === modalData.profileDetail.id}
            >
              サービス管理者
            </Select.Option>
          </Select>
        </Form.Item>
      </Style.FieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
  const { changeRole } = state.profileReducer;
  return {
    changeRole,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserRole: (params) => dispatch(updateUserRoleAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ChangeRoleModal);
