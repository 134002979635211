// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import _ from 'lodash';
import moment from 'moment';
import { isEmpty } from 'lodash/lang';
import json2csv from "json2csv"

import {
  ServicePath,
  CompanyPath,
  StudentPath,
} from 'src/constants/routerConstants';

import { URL_REGEX } from 'src/constants/regexValidate';

import docFileIcon from 'src/assets/images/common/doc-file-icon.svg';
import videoFileIcon from 'src/assets/images/common/video-file-icon.svg';
import tipFileIcon from 'src/assets/images/common/tip-file-icon.svg';
import surveyFileIcon from 'src/assets/images/common/survey-file-icon.svg';

import history from 'src/utils/history';

export const toCamelCase = (object) => {
  let camelCaseObject = _.cloneDeep(object);

  if (_.isArray(camelCaseObject)) {
    return _.map(camelCaseObject, toCamelCase);
  }
  if (!_.isObject(camelCaseObject)) {
    return camelCaseObject;
  }
  camelCaseObject = _.mapKeys(camelCaseObject, (value, key) => _.camelCase(key));

  // Recursively apply throughout object
  return _.mapValues(camelCaseObject, (value) => {
    if (_.isPlainObject(value)) {
      return toCamelCase(value);
    } if (_.isArray(value)) {
      return _.map(value, toCamelCase);
    }
    return value;
  });
};

export const toSnakeCase = (object) => {
  let snakeCaseObject = _.cloneDeep(object);

  if (_.isArray(snakeCaseObject)) {
    return _.map(snakeCaseObject, toSnakeCase);
  }
  if (!_.isObject(snakeCaseObject)) {
    return snakeCaseObject;
  }
  snakeCaseObject = _.mapKeys(snakeCaseObject, (value, key) => _.snakeCase(key));

  // Recursively apply throughout object
  return _.mapValues(snakeCaseObject, (value) => {
    if (_.isPlainObject(value)) {
      return toSnakeCase(value);
    } if (_.isArray(value)) {
      return _.map(value, toSnakeCase);
    }
    return value;
  });
};

export const getSearchParams = (param) => {
  const url = window.location.href;
  // eslint-disable-next-line no-useless-escape
  param = param.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2]);
};

export const formatPhoneNumber = (phoneNumber) => {
  const phoneNumberMatch = phoneNumber?.length === 11
    ? phoneNumber?.match(/^(\d{3})(\d{4})(\d{4})$/)
    : phoneNumber?.match(/^(\d{2})(\d{4})(\d{4})$/);
  if (phoneNumberMatch) {
    return phoneNumberMatch[1] + '-' + phoneNumberMatch[2] + '-' + phoneNumberMatch[3];
  }
  return phoneNumber;
};

export function renderLinkInText(urlParam) {
  if (!urlParam) return null;
  return urlParam.replace(URL_REGEX, (url) => {
    const hyperLink = url;
    if (hyperLink.match('^https?://')) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    }
  });
}

export const onScrollLoadMore = (scrollPoint, canLoadMore, getData) => {
  const { scrollTop, scrollHeight, clientHeight } = scrollPoint;
  if (scrollTop === scrollHeight - clientHeight && canLoadMore) {
    return getData();
  }
  return null;
};

export function getFilterOption(input, option) {
  const optionChildren = option.children;
  const filterValue = (input || '').trim();
  return optionChildren.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
}

export function randomLoadingWidth(minWidth, maxWidth) {
  return Math.floor(Math.random() * (maxWidth - minWidth + 1) ) + minWidth;
}

export function renderItemTypeIcon(unitType) {
  switch (unitType) {
  case 'video':
    return videoFileIcon;
  case 'test':
    return docFileIcon;
  case 'survey':
    return surveyFileIcon;
  case 'tip':
    return tipFileIcon;
  default:
    return undefined;
  }
}

export function handleStopPropagation(e) {
  const event = e || window.event;
  event.cancelBubble = true;
  if (event.stopPropagation) event.stopPropagation();
}

export function redirectOnAccessSpecificPage() {
  let redirectPath = '';
  if (window.location.pathname.includes('admin-service/login')) {
    redirectPath = ServicePath.Login;
  } else if (window.location.pathname.includes('admin/login')) {
    redirectPath = CompanyPath.Login;
  } else if (window.location.href.includes(`${window.location.origin}/login`)) {
    redirectPath = StudentPath.Login;
  }

  switch (true) {
  case window.location.pathname.includes('admin-service/forgot-password'): {
    localStorage.clear();
    history.push(ServicePath.ForgotPassword);
    break;
  }
  case window.location.pathname.includes('admin/forgot-password'): {
    localStorage.clear();
    history.push(CompanyPath.ForgotPassword);
    break;
  }
  case window.location.href.includes(`${window.location.origin}/forgot-password`): {
    localStorage.clear();
    history.push(StudentPath.ForgotPassword);
    break;
  }
  default: {
    if (!_.isEmpty(redirectPath)) {
      localStorage.clear();
      history.push(redirectPath);
    }
    break;
  }}
}

export function handleShowMessageOnSettingLicense(recommendedStartDate, recommendedEndDate, limitTimeSelected , recommendedCompletionDay){
  const recommendStart = moment(recommendedStartDate, 'YYYY-MM-DD');
  const recommendEnd = moment(recommendedEndDate, 'YYYY-MM-DD');
  const { endDate, startDate } = limitTimeSelected;

  if(recommendedCompletionDay){
    if(recommendedCompletionDay > endDate.diff(startDate, "days") + 1){
      return true
    }
  }

  if (
    !isEmpty(limitTimeSelected) &&
    limitTimeSelected.endDate &&
    limitTimeSelected.startDate
  ) {
    if (recommendEnd &&
      endDate.diff(startDate, "days") < recommendEnd.diff(recommendStart, "days")) {
      return true;
    }
    if (endDate < recommendStart || (endDate === recommendStart && startDate < endDate)) {
      return true;
    }
    if (startDate <= recommendStart && endDate < recommendEnd) {
      return true;
    }
  }
  return false;
};

export function downloadCsvFromArray(data: any, name: string) {
  if (!_.isEmpty(data)) {
    const BOM = '\uFEFF';
    const csv = json2csv.parse(data)
    const myBlob = new Blob([BOM + csv], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(myBlob);
    const link = document.createElement("a")
    link.href = url;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }
}

export function isAndroidDevice() {
  const navigate = navigator.userAgent.toLowerCase();
  return navigate.indexOf('android') > -1;
}
