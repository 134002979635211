// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TestScoreStatus from './components/TestScoreStatus';
import TestScoreChart from './components/TestScoreChart';
import TestScoreStudents from './components/TestScoreStudents';

import {
  getTestScoreOverview as getTestScoreOverviewAction,
  getTestScoreChart as getTestScoreChartAction,
  getTestScoreStudents as getTestScoreStudentsAction,
  closeModal as closeModalAction,
  showModal as showModalAction,
  downloadCsvTestStudent as downloadCsvTestStudentAction
} from 'src/redux/actions';

import * as Style from './styles';
import history from 'src/utils/history';

interface ITestScoreProps extends StateProps, DispatchProps {
  getTestScoreOverview(params): void;
  getTestScoreChart(params): void;
  getTestScoreStudents(params): void;
  showModal(params): void;
  closeModal(): void;
  downloadCsvTestStudent(params): void;
  courseId: string | undefined;
  tabIndexSelected: string;
  companySelectedId: number;
  testScoreOverview: {
    data: {
      overview: {
        avgScoreOfAllTest: number;
        avgScoreOfFinishTest: number;
        avgScoreOfPreTest: number;
        avgScoreOfVerifyTest: number;
        studentsJoinedTestsCount: number;
        isStudentsFinishedTests: boolean;
      };
    };
    load: boolean;
  };
  testScoreChart: {
    data: object[];
    load: boolean;
  };
  testScoreStudents: {
    data: {
      coursesUnitVersions: {
        id: number;
        itemType: string;
        unit: {
          name: string;
        };
      }[];
      usersScore: object[];
    };
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
}

const TestScore: React.FC<ITestScoreProps> = ({
  getTestScoreOverview,
  getTestScoreChart,
  getTestScoreStudents,
  showModal,
  closeModal,
  downloadCsvTestStudent,
  companySelectedId,
  courseId,
  testScoreOverview,
  testScoreChart,
  testScoreStudents,
  tabIndexSelected,
}) => {
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    setSortCondition(sortConditionUrl || {})
  }, [tabIndexSelected, companySelectedId])

  useEffect(() => {
    if (courseId && tabIndexSelected === '3') {
      getTestScoreOverview({ id: courseId, requestData: { companyId: companySelectedId } });
      getTestScoreChart({ id: courseId, requestData: { testType: 'all_test', companyId: companySelectedId } });
      getTestScoreStudents({
        id: courseId,
        requestData: {
          page: 1,
          ...pageUrl,
          companyId: companySelectedId,
          items: pageUrl?.limit,
          sort: sortConditionUrl
        }
      });
    }
  }, [courseId, tabIndexSelected, companySelectedId]);

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey: tabIndexSelected,
        sortCondition,
        page: testScoreStudents.meta.pageInfo,
        companyContractId: companySelectedId,
        isSort: true,
      }
    })
  }, [sortCondition, testScoreStudents.meta.pageInfo])

  return (
    <>
      <TestScoreStatus testScoreOverview={testScoreOverview} />
      <TestScoreChart
        getTestScoreChart={getTestScoreChart}
        companySelectedId={companySelectedId}
        courseId={courseId}
        testScoreChart={testScoreChart}
      />
      <TestScoreStudents
        getTestScoreStudents={getTestScoreStudents}
        companySelectedId={companySelectedId}
        courseId={courseId}
        testScoreStudents={testScoreStudents}
        isStudentsFinishedTests={testScoreOverview?.data?.overview?.isStudentsFinishedTests}
        showModal={showModal}
        closeModal={closeModal}
        downloadCsvTestStudent={downloadCsvTestStudent}
        sortCondition={sortCondition}
        setSortCondition={setSortCondition}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    testScoreOverview,
    testScoreChart,
    testScoreStudents,
  } = state.courseReducer;
  return {
    testScoreOverview,
    testScoreChart,
    testScoreStudents,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTestScoreOverview: (params) => dispatch(getTestScoreOverviewAction(params)),
  getTestScoreChart: (params) => dispatch(getTestScoreChartAction(params)),
  getTestScoreStudents: (params) => dispatch(getTestScoreStudentsAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  downloadCsvTestStudent: (params) => dispatch(downloadCsvTestStudentAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestScore);
