// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Space,
  Form,
  Input,
  Select,
  Button,
  InputNumber,
  Tooltip,
  Checkbox,
  Spin,
} from 'antd';
import { DeleteOutlined, CaretDownOutlined } from '@ant-design/icons';

import { snakeCase } from 'lodash/string';
import { isEmpty, isNumber } from 'lodash/lang';

import history from 'src/utils/history';
import { getFilterOption } from 'src/utils/common';

import { Text } from 'src/components/styles';

import { ServicePath } from 'src/constants/routerConstants';
import { ALLOWED_TYPE, FILE_SIZE } from 'src/constants/common';

import {
  showModal as showModalAction,
  closeModal as closeModalAction
} from 'src/redux/actions'
import { connect } from 'react-redux';

import * as Style from './styles';
import ConfirmDeleteTest from '../ConfirmDeleteTest';
import { showAlertNotice } from 'src/utils/alert';

interface ITestOverviewProps extends DispatchProps, StateToProps{
  createTest?(params): void;
  updateTest?(params): void;
  showModal(params): void;
  closeModal(): void;
  isDisableRequest: boolean;
  testId?: string;
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  testDetail?: {
    data: {
      id: number;
      addedToCourse: boolean;
      name: string;
      description: string;
      testType: string;
      passScorePercentage: number;
      limitTime: number;
      limitAttempt: number;
      displayResults: boolean;
      explanationFile: {
        filename: string;
        url: string;
      };
      isAddedToLesson: boolean;
      category: {
        id: number;
        name: string;
      };
      tags: {
        id: number;
        name: string;
      }[];
      lastUpdater: {
        jituName: string;
      };
      updatedAt: string;
    };
  };
}

const TestOverview: React.FC<ITestOverviewProps> = ({
  createTest,
  updateTest,
  testId,
  tagList,
  categoryList,
  testDetail,
  showModal,
  closeModal,
  isDisableRequest
}) => {
  const [isFinishTest, setIsFinishTest] = useState<boolean>(false);
  const [fileResult, setFileResult] = useState<any>(null);
  const [fileName, setFileName] = useState<string>('');
  const [isUploadingFile, setIsUploadingFile] = useState<boolean>(false);
  const [testForm] = Form.useForm();
  const [fileError, setFileError] = useState<string>('');
  const testFormData = new FormData();

  const uploadFileButton = useRef() as React.MutableRefObject<HTMLInputElement>;
  const testInitialFormValues = {
    name: testDetail?.data.name,
    description: testDetail?.data.description,
    testType: testDetail?.data.testType,
    passScorePercentage: testDetail?.data.passScorePercentage,
    ...(testDetail?.data.testType === 'finish_test' && { passScorePercentage: testDetail?.data.passScorePercentage }),
    limitTime: testDetail?.data.limitTime,
    limitAttempt: testDetail?.data.limitAttempt,
    categoryId: testDetail?.data.category?.id,
    tagIds: testDetail?.data.tags?.map((tag) => tag.id),
    displayResults: testDetail?.data.displayResults?.toString() || 'hidden',
  };
  useEffect(() => {
    return () => {
      closeModal()
    }
  }, []);

  useEffect(() => {
    if (testDetail?.data.id) {
      testForm.resetFields();
      setFileName(testDetail?.data?.explanationFile?.filename);
    }
  }, [testDetail?.data.id]);

  useEffect(() => {
    setIsFinishTest(testDetail?.data.testType === 'finish_test');
  }, [testDetail?.data.testType]);

  function createFormData(values) {
    if (!isEmpty(fileError)) testForm.setFieldsValue({ explanationFile: fileError });
    testForm.validateFields();
    // eslint-disable-next-line array-callback-return
    Object.keys(values).map((key) => {
      if (isEmpty(values[key]) && !isNumber(values[key]) && key !== 'isDeleteExplanationFile') {
        return null;
      }
      if (key === 'name' || key === 'description') {
        testFormData.append(`test[${snakeCase(key)}]`, values[key].trim() || '');
      } else if (key !== 'explanationFile') {
        const dataValue = values[key] || isNumber(values[key]) ? values[key] : '';
        if (key === 'tagIds') {
          testFormData.append(`test[${snakeCase(key)}]`, JSON.stringify(dataValue));
        } else {
          testFormData.append(`test[${snakeCase(key)}]`, dataValue);
        }
      }
    });
    if (fileResult && !fileError) testFormData.append('test[explanation_file]', fileResult, fileResult?.name);
  }

  function renderTagsOptions() {
    if (isEmpty(tagList.data)) return null;
    return tagList.data.map((tag) => (
      <Select.Option key={`tag-${tag.id}`} value={tag.id}>{tag.name}</Select.Option>
    ));
  }

  function renderTestTimeOptions() {
    return [...Array(25)].map((_, itemOptionIndex) => {
      const itemValue = itemOptionIndex * 5;
      return (
        <Select.Option key={`test-type-${itemOptionIndex}`} value={itemValue * 60}>
          {itemValue === 0 ? '時間無制限' : `${itemValue}分`}
        </Select.Option>
      );
    });
  }

  function renderTestLimitOptions() {
    return [...Array(11)].map((_, itemOptionIndex) => (
      <Select.Option key={`test-limit-${itemOptionIndex}`} value={itemOptionIndex}>
        {itemOptionIndex === 0 ? 'テスト回数無制限' : `${itemOptionIndex}回`}
      </Select.Option>
    ));
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  function handleUploadClick() {
    uploadFileButton.current.click();
  }

  function removeFile() {
    setFileResult(null);
    setFileName('');
    testForm.setFieldsValue({ explanationFile: '' });
    setFileError('');
    (document.getElementById('uploadFileField') as HTMLInputElement).value = '';
  }

  function handleFileUpload(e) {
    setIsUploadingFile(true);
    const resultFile = e.target.files[0];
    if (resultFile) {
      setFileResult(resultFile);
      setFileName(resultFile.name);
      const fileType = resultFile.name.slice(resultFile.name.lastIndexOf('.') + 1);
      // setFileImage(resultFile);
      if (!ALLOWED_TYPE.includes(fileType)) {
        testForm.setFieldsValue({ explanationFile: 'wrong_format' });
        setFileError('wrong_format');
      } else if (resultFile.size > FILE_SIZE) {
        testForm.setFieldsValue({ explanationFile: 'too_large' });
        setFileError('too_large');
      } else {
        setFileError('');
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        setIsUploadingFile(false);
        testForm.setFieldsValue({ isDeleteExplanationFile: false });
      };
      reader.readAsDataURL(resultFile);
    } else {
      setIsUploadingFile(false);
    }
  }

  return (
    <Form
      form={testForm}
      {...testId && { initialValues: testInitialFormValues }}
      name="modifyTestForm"
      onFinish={(values) => {
        createFormData(values);
        if (!fileError) {
          if (testId && updateTest) {
            updateTest({ requestData: testFormData, id: testId });
          } else if (createTest) {
            createTest(testFormData);
          }
        }
      }}
      onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
      style={{ marginTop: 8 }}
      scrollToFirstError={true}
    >
      <Row gutter={30}>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="テスト名"
                name="name"
                labelCol={{ span: 24 }}
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'テスト名は必須項目です。' },
                  { whitespace: true, message: 'テスト名は必須項目です。' },
                  { max: 50, message: 'テスト名が長すぎます。（最大は50桁です）' },
                ]}
              >
                <Input className="transparent-input" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="tagIds"
                label="Tags"
                labelAlign="left"
                labelCol={{ span: 24 }}
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  className="transparent-input"
                  filterOption={getFilterOption}
                >
                  {renderTagsOptions()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="テスト概要"
                name="description"
                labelAlign="left"
                labelCol={{ span: 24 }}
                colon={false}
                rules={[
                  { required: true, message: 'テスト概要は必須項目です。' },
                  { whitespace: true, message: 'テスト概要は必須項目です。' },
                  { max: 250, message: 'テスト概要が長すぎます。（最大は250桁です）' },
                ]}
              >
                <Input.TextArea
                  autoSize={{ minRows: 6, maxRows: 6 }}
                  className="transparent-input"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="testType"
                label="テスト種別"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: 'テスト種別は必須項目です。' },
                ]}
              >
                <Select
                  allowClear
                  suffixIcon={<CaretDownOutlined />}
                  placeholder="選択してください"
                  className="transparent-input"
                  onChange={(value) => setIsFinishTest(value === 'finish_test')}
                  disabled={testDetail?.data?.addedToCourse}
                >
                  <Select.Option value="pre_test">事前テスト</Select.Option>
                  <Select.Option value="verify_test">確認テスト</Select.Option>
                  <Select.Option value="finish_test">修了テスト</Select.Option>
                  <Select.Option value="confirm_test">知識テスト</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            {isFinishTest && (
              <Col span={12}>
                <Form.Item
                  name="passScorePercentage"
                  label="合格ライン"
                  labelCol={{ span: 24 }}
                  initialValue={80} // Bug show warning, fixed on antd 4.3.0
                  rules={[
                    { required: true, message: '合格ラインは必須項目です。' },
                    {
                      validator: (_, value) =>
                        value !== 0 ? Promise.resolve() : Promise.reject(new Error('正答率は0以降で入力してください。')),
                    }
                  ]}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    formatter={(value: any) => `${`${value}`.replace(/\D/g, '')}%`}
                    parser={(value: any) => value.replace('%', '')}
                    className="transparent-input"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item
                name="limitTime"
                label="制限時間"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: '制限時間は必須項目です。' },
                ]}
              >
                <Select
                  allowClear
                  suffixIcon={<CaretDownOutlined />}
                  placeholder="選択してください"
                  className="transparent-input"
                >
                  {renderTestTimeOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="limitAttempt"
                label="受講制限"
                labelCol={{ span: 24 }}
                rules={[
                  { required: true, message: '受講制限は必須項目です。' },
                ]}
              >
                <Select
                  allowClear
                  suffixIcon={<CaretDownOutlined />}
                  placeholder="選択してください"
                  className="transparent-input"
                >
                  {renderTestLimitOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="categoryId"
                label="講座タイプ"
                labelCol={{ span: 24 }}
              >
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="選択してください"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                  className="transparent-input"
                >
                  {renderCategoryOptions()}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="explanationFile"
            labelAlign="left"
            label="解説ファイル"
            labelCol={{ span: 24 }}
            colon={false}
            validateFirst
            rules={[
              () => ({
                validator(_, value) {
                  if (value === 'wrong_format') {
                    return Promise.reject('ファイルの形式が正しくありません。選択可能ファイルはpdf、word、powerpoint、ipynb、pyです。');
                  } else if (value === 'too_large') {
                    return Promise.reject('ファイルサイズが大きすぎます。(最大サイズ：30MB）');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Style.FileInputField>
              <Text margin={fileName ? '0 6px 0 0' : '0'} truncate width="50%" lg>
                <Tooltip title={fileName ? fileName : ''}>
                  <span>{fileName ? fileName : ''}</span>
                </Tooltip>
              </Text>
              <Style.FieldInput
                accept={ALLOWED_TYPE}
                id="uploadFileField"
                type="file"
                ref={uploadFileButton}
                onChange={(e) => handleFileUpload(e)}
              />
              {fileName && (
                <>
                  <Text width="50%" truncate>
                    {fileName ? '' : testDetail?.data.explanationFile?.filename}
                  </Text>
                  <DeleteOutlined
                    style={{ color: '#D25109', marginRight: '10px' }}
                    onClick={() => {
                      removeFile();
                      testForm.setFieldsValue({ isDeleteExplanationFile: true });
                    }}
                  />
                </>
              )}
              <Form.Item
                name="isDeleteExplanationFile"
                style={{ display: 'none' }}
              >
                <Checkbox />
              </Form.Item>
              <Button
                size="middle"
                ghost
                type="primary"
                onClick={() => handleUploadClick()}
              >
                ファイル選択
              </Button>
              {isUploadingFile &&
                <Space style={{ marginLeft: '20px' }}>
                  <Text lg w6>アップロード中</Text>
                  <Spin />
                </Space>
              }
            </Style.FileInputField>
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="正誤・解答表示"
                labelCol={{ span: 24 }}
                name="displayResults"
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  className="transparent-input"
                  defaultValue={['hidden']}
                >
                  <Select.Option value="details">正誤・解答表示</Select.Option>
                  <Select.Option value="show">正誤のみ表示</Select.Option>
                  <Select.Option value="hidden">非表示</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Style.TextOverviewAction>
                <Space align="center" size="middle">
                  {
                    testId && <Button
                      disabled={testDetail?.data.isAddedToLesson}
                      type="primary"
                      htmlType="button"
                      ghost
                      danger
                      onClick={() => {
                        const modalData = {
                          modalData: {
                            title: "テストの削除",
                          },
                          testId: testId
                        }
                        showModal(<ConfirmDeleteTest {...modalData} />)
                      }}
                    >
                      削除する
                    </Button>
                  }
                  <Button
                    type="primary"
                    htmlType="button"
                    className="btn-secondary"
                    onClick={() => history.push(ServicePath.TestList)}
                  >
                    キャンセル
                  </Button>
                  <Button htmlType="submit" type="primary" disabled={isDisableRequest}>
                    保存する
                  </Button>
                </Space>
              </Style.TextOverviewAction>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};


const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

const mapStateToProps = (state) => {
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    isDisableRequest
  };
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type StateToProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestOverview);