// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { Button } from 'antd';
import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  width: 100%;
  margin-top: ${(props) => props.marginTop || 0};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.adminBackground};
`;

export const ProfileContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

  &:last-child {
    border-bottom: none;
  }
`;

export const EditProfileContent = styled.div`
  padding: 16px 20px;

  & label {
    min-width: 150px;
    width: 150px;
  }

  @media only screen and (max-width: 428px) {
    padding: 0;
    & label {
      min-width: 124px;
      width: unset;
    }

    .ant-form-item {
      flex-direction: row !important;
      align-items: center;
      border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};
      padding: 5px 20px;
      margin: 0 !important;
    }
    
    .ant-form-item-label {
      flex: 0 0 124px !important;
    }

    .ant-form-item-control {
      flex: 1 !important;
    }
    .ant-select, .ant-picker {
      width: 100% !important;
    }
  }
`;

export const ButtonCancel = styled(Button)`
  background-color: #CCCCCC !important;
`

export const TextField = styled(Text)`
  @media only screen and (max-width: 428px) {
    font-size: 14px !important;
    min-width: 124px;
    width: 124px;
    font-weight: 700;
  }
`

export const ModifyProfile = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 16px;
`;
