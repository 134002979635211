// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from '../restClient';

export default class StudentStatisticServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }

  getStatisticCourses() {
    return this.restClient.get('/student/statistics/courses');
  }

  getCurrentWeek() {
    return this.restClient.get('/student/statistics/current_week_datas');
  }

  getLearningHistories(params) {
    return this.restClient.get('/student/statistics/learning_histories', params);
  }

  getLearningTimes(params) {
    return this.restClient.get('/student/statistics/learning_time_charts', params);
  }
}
