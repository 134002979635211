// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import { isEmpty } from 'lodash/lang';

import { PASSWORD_REGEX } from 'src/constants/regexValidate';

import {
  closeModal as closeModalAction,
  updateProfilePasswordAndEmail as updateProfilePasswordAndEmailAction,
  resetErrorMessageChangeEmailPassword as resetErrorMessageChangeEmailPasswordAction
} from 'src/redux/actions';

import * as Style from './styles';

interface IChangeEmailPasswordModalProps extends StateProps, DispatchProps {
  updateProfilePasswordAndEmail(params): void;
  closeModal(): void;
  resetErrorMessageChangeEmailPassword(): void;
  changePasswordAndEmail: {
    errors: [{
      message: string,
      field: string
    }];
  };
  modalData: {
    email: string;
  };
  isDisableRequest;
}

const ChangeEmailPasswordModal: React.FC<IChangeEmailPasswordModalProps> = ({
  closeModal,
  updateProfilePasswordAndEmail,
  resetErrorMessageChangeEmailPassword,
  changePasswordAndEmail,
  modalData,
  isDisableRequest,
}) => {
  const [changePasswordAndMailForm] = Form.useForm();
  const [isDisableForm, setIsDisableForm] = useState(true);
  
  useEffect(() => {
    if (!isEmpty(changePasswordAndEmail.errors)) {
      changePasswordAndEmail.errors.forEach((item) => {
        changePasswordAndMailForm.setFields([
          {
            name: item.field || "currentPassword",
            errors: [item.message as string],
          },
        ])    
      })}
    setIsDisableForm(
      isDisableRequest ||
        ((!changePasswordAndMailForm.getFieldValue("email") ||
        (changePasswordAndMailForm.getFieldValue("email") === modalData.email) &&
          !changePasswordAndMailForm.getFieldValue("currentPassword") &&
          !changePasswordAndMailForm.getFieldValue("newPassword") &&
          !changePasswordAndMailForm.getFieldValue("confirmNewPassword")))
    );
  }, [changePasswordAndEmail.errors]);

  function handleSubmitForm(values) {
    updateProfilePasswordAndEmail({
      user: {
        ...values,
      },
    });
  }

  function handleChangeForm() {
    setIsDisableForm(
      isDisableRequest ||
        ((!changePasswordAndMailForm.getFieldValue("email") ||
        (changePasswordAndMailForm.getFieldValue("email") === modalData.email) &&
          !changePasswordAndMailForm.getFieldValue("currentPassword") &&
          !changePasswordAndMailForm.getFieldValue("newPassword") &&
          !changePasswordAndMailForm.getFieldValue("confirmNewPassword")))
    );
  }
  return (
    <Style.FormWrapper
      form={changePasswordAndMailForm}
      labelCol={{ span: 9 }}
      wrapperCol={{ span: 15 }}
      name="updateProfilePasswordForm"
      onFinish={handleSubmitForm}
      onFieldsChange={handleChangeForm}
      initialValues={{ email: modalData.email }}
    >
      <Style.FieldContent>
        <Form.Item
          label={<Style.TextTitle lg>メールアドレス</Style.TextTitle>}
          name="email"
          labelAlign="left"
          colon={false}
          normalize={(value) => value.trim()}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            {
              required: true,
              whitespace: true,
              message: "新しいメールアドレスは必須項目です。",
            },
            {
              type: "email",
              message: "新しいメールアドレスのフォーマットが正しくありません。",
            },
            {
              max: 50,
              message: "新しいメールアドレスが長すぎます。（最大は50桁です）",
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Style.TextTitle lg style={{ marginBottom: 8 }}>
          パスワード
        </Style.TextTitle>
        <Form.Item
          label={<Style.TextTitle>現在のパスワード</Style.TextTitle>}
          name="currentPassword"
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          dependencies={['confirmNewPassword', 'newPassword']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  (getFieldValue("newPassword") ||
                    getFieldValue("confirmNewPassword")) &&
                  !value
                ) {
                  return Promise.reject("現在のパスワードは必須項目です。");
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={<Style.TextTitle>新しいパスワード</Style.TextTitle>}
          name="newPassword"
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          dependencies={['confirmNewPassword', 'currentPassword']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  (getFieldValue("currentPassword") ||
                    getFieldValue("confirmNewPassword")) &&
                  !value
                ) {
                  return Promise.reject("新しいパスワードは必須項目です。");
                }
                if (value) {
                  if (value.length < 8)
                    return Promise.reject(
                      "新しいパスワードは8文字以上で設定して下さい。"
                    );
                  if (value.length > 20)
                    return Promise.reject(
                      "新しいパスワードが長すぎます。（最大は20桁です）"
                    );
                  if (!PASSWORD_REGEX.test(value))
                    return Promise.reject(
                      "新しいパスワードは大文字、小文字、数字、記号から3つ以上の組み合わせで入力してください。"
                    );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={<Style.TextTitle>新しいパスワード（確認用）</Style.TextTitle>}
          name="confirmNewPassword"
          dependencies={['newPassword', 'currentPassword']}
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  ((!getFieldValue("newPassword") && !getFieldValue("currentPassword")) && !value) ||
                  getFieldValue("newPassword") === value
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "新しいパスワードと確認用のパスワードが一致しません。ご確認ください。"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Style.FieldContent>
        <Style.ActionWrapper>
          <Style.ButtonCancel
            type="primary"
            htmlType="button"
            className="btn-secondary"
            onClick={() => {
              closeModal();
              resetErrorMessageChangeEmailPassword();
            }}
            disabled={isDisableRequest}
          >
            キャンセル
          </Style.ButtonCancel>
          <Button type="primary" htmlType="submit" disabled={isDisableForm}>
            変更する
          </Button>
        </Style.ActionWrapper>
    </Style.FormWrapper>
  );
};

const mapStateToProps = (state) => {
  const { changePasswordAndEmail } = state.myProfileReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    changePasswordAndEmail,
    isDisableRequest,
  };
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeModalAction()),
  updateProfilePasswordAndEmail: (params) => dispatch(updateProfilePasswordAndEmailAction(params)),
  resetErrorMessageChangeEmailPassword: () => dispatch(resetErrorMessageChangeEmailPasswordAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeEmailPasswordModal);
