// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';
import { Space } from 'antd';

export const HistoryCourseTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 4px;
`;

export const HistoryCardContainer = styled.div`
  padding: 12px;
`;

export const HistoryCardContent = styled.div`
  position: relative;
  border-top: 5px solid #C08D01;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  overflow: hidden;
  cursor: pointer;
`;

export const HistoryCardImage = styled.div`
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-size: ${(props) => props.isThumbnailUrl ? 'cover' : '100px'};
  background-position: center;
  background-color: ${(props) => props.isThumbnailUrl ? 'unset' : '#F0F0F0'};

  @media only screen and (max-width: 428px) {
    background-size: ${(props) => props.isThumbnailUrl ? 'cover' : '70px'};
    
    ${(props) => props.isTip
      && css`
            background-size: 40px;
      `}
    }
`;

export const HistoryCardInfo = styled.div`
  height: 111px;
  padding: 20px;

  @media only screen and (max-width: 428px) {
    height: 90px;
  }
`;

export const SkeletonHistoryCardInfo = styled.div`
  height: 111px;
  padding: 20px;

  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const SkeletonHistoryCardInfoMobile = styled.div`
  display: none;

  @media only screen and (max-width: 428px) {
    display: block;
    height: 95px;
    padding: 20px;
  }
`;

export const LoadingHistoryCardImage = styled.div`
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: #f2f2f2;
`;

export const HistoryCardWrapper = styled.div`
  width: calc(100% / 3);

  @media only screen and (max-width: 428px) {
    width: 50%;
  }
`

export const HistoryWrapper = styled.div`
  @media only screen and (max-width: 428px) {
    padding: 16px;
  }
`

export const HistoryUnitName = styled.div`
  @media only screen and (max-width: 428px) {
    .ant-typography {
      font-size: 12px;
    }
  }
`

export const HistoryCourseName = styled(Text)`
  font-size: 14px;
  @media only screen and (max-width: 428px) {
    .ant-typography {
      font-size: 10px;
      line-height: 14px;
      font-weight: 800;
    }
  }
`

export const TextHeader = styled(Text)`
  @media only screen and (max-width: 428px) {
    font-size: 20px !important;
  }
`

export const HistoryCardInfoName = styled(Space)`
  @media only screen and (max-width: 428px) {
    align-items: flex-start !important;
  }
`
