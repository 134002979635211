// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const ProfileWrapper = styled.div`
  margin-top: 16px;
  width: 100%;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: white;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 40px 0 16px;
  height: 50px;
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const ProfileContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

  &:last-child {
    border-bottom: none;
  }

  & button {
    padding: 0 7px;
    height: 24px;
    font-size: 14px;
    line-height: 20px;
  }

  @media only screen and (max-width: 428px) {
    padding: 15px;
  }
`;

export const PasswordHidden = styled.div`
  width: 12px;
  height: 12px;
  border-radius: ${(props) => props.theme.radius.circle};
  background-color: ${(props) => props.theme.colors.userBackground};
`;

export const ButtonWrapper = styled.div`
  @media only screen and (max-width: 428px) {
    display: none;
  }
`;

export const ModifyProfile = styled.div`
  display: none;

  @media only screen and (max-width: 428px) {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0 16px;
  }
`;
