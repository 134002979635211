// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const CompanyStudentAction = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;
`;

export const CompanyStudentContainer  = styled.div`
  display: flex;
  padding-bottom: 60px;
  width: 100%;
`;

export const CompanyStudentSidebar = styled.div`
  margin-right: 16px;
  min-width: 300px;
  height: 100%;
  background-color: white;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
`;

export const CompanyStudentContent = styled.div`
  margin: 0 auto;
  max-width: calc(100% - 316px);
  width: 100%;
`;

export const CompanyStudentName = styled.div`
  position: relative;
  cursor: pointer;
`;

export const CompanyStudentStatus = styled.span`
  position: absolute;
  top: -11px;
  left: 0;
  font-size: 12px;
  font-weight: ${(props) => props.theme.fontWeight.w6};
  color: ${(props) => props.theme.colors.error};
`;

export const TableAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;
