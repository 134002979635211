//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, Tooltip, Tabs } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';
import { Text, SkeletonLoading } from 'src/components/styles';

import AccountProfile from './components/AccountProfile';
import OtherProfile from './components/OtherProfile';
import JoinedCourses from './components/JoinedCourses';
import ConfirmModal from 'src/components/Modal/components/ConfirmModal';

import {
  getCompanyProfileDetail as getCompanyProfileDetailAction,
  clearCompanyUpdateRoleErrors as clearCompanyUpdateRoleErrorsAction,
  getCompanyJoinedCourses as getCompanyJoinedCoursesAction,
  getCompanyCourseDetail as getCompanyCourseDetailAction,
  showModal as showModalAction,
  getTestStudentCompany as getTestStudentCompanyAction,
  resetHistoryTestCompany as resetHistoryTestCompanyAction,
  closeModal as closeModalAction,
  unlockUserCompany as unlockUserCompanyAction
} from 'src/redux/actions';

import { USER_PROFILE_FIELDS, COMPANY_PROFILE_FIELDS } from './constants';
import { PAGE_TITLE, CompanyTitle } from 'src/constants/pageTitle';
import { STATUS_USER } from 'src/constants/common'

import * as Style from './styles';
import TestListStudent from './components/TestListStudent';
import history from 'src/utils/history';

interface IProfileDetailPageProps extends StateProps, DispatchProps {
  getCompanyProfileDetail(params): void;
  clearCompanyUpdateRoleErrors(): void;
  getCompanyJoinedCourses(params): void;
  showModal(params): void;
  getTestStudentCompany(params): void;
  resetHistoryTestCompany(params): void;
  closeModal(): void;
  unlockUserCompanyAction(params): void;
  match: {
    params: {
      id: string;
    };
  };
  role: string;
  profileDetail: {
    data: {
      id: number;
      avatarUrl: string;
      company: {
        name: string;
      };
      department: string;
      email: string;
      jituName: string;
      roles: string[];
      kanaName: string;
      birthday: string;
      gender: string;
      jobTitle: string;
      jobType: string;
      systemNotificationSetting: string;
      status: string;
      companyCustomUserInfos: {
        id: number;
        fieldName: string;
        isRequired: boolean;
        isDisplay: boolean;
      }[];
      customUserInfos: {
        id: number;
        companyCustomUserInfoId: number;
        fieldValue: string;
      }[];
      customDisplayField: {
        birthday: boolean;
        department: boolean;
        jobTitle: boolean;
        jobType: boolean;
      };
    },
    load: boolean;
  };
  joinedCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      };
      totalLearningDays: number;
      totalLearningTime: number;
      studentProgressPercentage: number;
      testInfo: {
        usersRank: number;
        totalUsers: number;
        highestTestScore: number;
      };
      remainingDays: number;
      startDate: string;
      endDate: string;
      lastLearningDate: string
      actualStatus: string;
      manageCourseStatus: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  listTestsStudent: {
    data: {
      id: number;
      name: string;
      test: {
        id: number,
        name: string,
        testType: string
      };
      category: {
        id: number;
        name: string;
      };
      course: {
        id: number;
        name: string
      }
      isDoingUnlimitedTest: boolean;
      usedAttemptsCount: number;
      testScore: number;
      testScorePercentage: number;
      lastTestingDate: string;
      isWaitingTest: boolean;
      unit: {
        id: number,
        name: string,
      };
      unitVersionAble: {
        id: number,
        type: string
      },
      manageTestStatus: string
    }[]
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
  },
  isResetSucess: boolean
}

const ProfileDetailPage: React.FC<IProfileDetailPageProps> = ({
  getCompanyProfileDetail,
  clearCompanyUpdateRoleErrors,
  getCompanyJoinedCourses,
  getCompanyCourseDetail,
  getTestStudentCompany,
  showModal,
  unlockUserCompany,
  closeModal,
  match,
  role,
  profileDetail,
  joinedCourses,
  listTestsStudent,
  resetHistoryTestCompany,
  isResetSucess
}) => {
  const activeTabKeyUrl = history.location.state?.activeTabKey;
  
  const [activeTabKey, setActiveTabKey] = useState<string>(activeTabKeyUrl || '1');
  const userId = match.params.id;

  useEffect(() => {
    document.title = PAGE_TITLE(CompanyTitle.ProfileDetail);

    if (parseFloat(userId) !== profileDetail.data.id) {
      getCompanyProfileDetail({ id: userId });
    }

    if (activeTabKeyUrl) {
      setActiveTabKey(activeTabKeyUrl)
    }

    window.addEventListener("beforeunload", function () {
      history.replace()
    })

    return () => {
      closeModal()
    }
  }, []);

  function renderProfileAvatar() {
    if (profileDetail.load) {
      return (
        <SkeletonLoading.Avatar active size={120} />
      );
    }
    if (profileDetail.data.avatarUrl) {
      return (
        <Style.ProfileAvatar image={profileDetail.data.avatarUrl} />
      );
    } else {
      return (
        <Avatar
          size={120}
          icon={<UserOutlined style={{ fontSize: 56 }} />}
          style={{ marginRight: 20, minWidth: 120 }}
        />
      );
    }
  }

  const handleShowModalConfirmUnblockUser = () => {
    const modalProps = {
      modalData: {
        title: "ロック解除確認",
        message: "アカウントのロックを解除して良いですか？",
      },
      modalAction: {
        confirm: () => {
          unlockUserCompany(userId)
        },
      },
      isConfirm: true,
      spaceEvenly: true,
      isButtonLoading: true,
      styleContent: {
        paddingTop: "60px"
      }
    };
    showModal(<ConfirmModal {...modalProps} />);
  };

  return (
    <MainContainer backgroundHeight={250} isTabs>
      <MainContent fullView>
        <Style.ProfileInfoContainer>
          <Text w6 xxl style={{ marginBottom: 16 }}>
            プロフィール
          </Text>
          <Style.ProfileInfoContent>
            {renderProfileAvatar()}
            <div style={{ width: 'calc(100% - 140px)' }}>
              {profileDetail.load
                ? (
                  <>
                    <SkeletonLoading active paragraph={false} title={{ width: 150 }} height={22} />
                    <SkeletonLoading active paragraph={false} title={{ width: 50 }} height={16} />
                  </>
                )
                : (
                  <>
                    {profileDetail.data.status === STATUS_USER.BLOCK && (
                      <Text color="red" xl>アカウントロック</Text>
                    )}
                    <Tooltip title={profileDetail.data.jituName} placement="top">
                      <Text xxxl w6 truncate style={{ fontSize: 22 }}>
                        {profileDetail.data.jituName}
                      </Text>
                    </Tooltip>
                    <Text lg>{profileDetail.data.id}</Text>
                    {profileDetail.data.status === STATUS_USER.BLOCK && (
                      <Style.ButtonUnblock
                        type="primary"
                        onClick={handleShowModalConfirmUnblockUser}
                      >
                        ロック解除
                      </Style.ButtonUnblock>
                  )}
                  </>
                )
              }
            </div>
          </Style.ProfileInfoContent>
        </Style.ProfileInfoContainer>
        <Tabs
          activeKey={activeTabKey}
          type="card"
          onChange={(activeKey) => {
            history.replace({
              state: {
                activeTabKey: activeKey,
                sortCondition: {},
              }
            })
            setActiveTabKey(activeKey)
          }}
          style={{ marginBottom: 60 }}
        >
          <Tabs.TabPane
            tab={<Text xl headerText w6>基本情報</Text>}
            key="1"
          >
            <AccountProfile
              role={role}
              profileDetail={profileDetail}
              clearCompanyUpdateRoleErrors={clearCompanyUpdateRoleErrors}
              showModal={showModal}
              activeTabKey={activeTabKey}
            />
            <OtherProfile
              type="user"
              title="ユーザー情報"
              fieldData={USER_PROFILE_FIELDS}
              profileDetail={profileDetail}
            />
            <OtherProfile
              type="company"
              title="所属情報"
              fieldData={COMPANY_PROFILE_FIELDS}
              profileDetail={profileDetail}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>受講コース</Text>}
            key="2"
          >
            {profileDetail.data.roles?.includes('student')
              ? (
                <JoinedCourses
                  getCompanyJoinedCourses={getCompanyJoinedCourses}
                  getCompanyCourseDetail={getCompanyCourseDetail}
                  userId={userId}
                  joinedCourses={joinedCourses}
                  activeTabKey={activeTabKey}
                />
              )
              : <Text lg w6 center margin="16px 0">現在受講可能なコースはありません。</Text>
            }
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<Text xl headerText w6>受講テスト</Text>}
            key="3"
          >
            <TestListStudent
              getTestStudentCompany={getTestStudentCompany}
              listTestsStudent={listTestsStudent}
              userId={userId}
              resetTestStudent={resetHistoryTestCompany}
              isResetSucess={isResetSucess}
              activeKey={activeTabKey}
            />
          </Tabs.TabPane>
        </Tabs>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { profileDetail, joinedCourses, listTestsStudent, isResetSucess } = state.companyProfileReducer;
  return {
    profileDetail,
    joinedCourses,
    listTestsStudent,
    isResetSucess
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyProfileDetail: (params) => dispatch(getCompanyProfileDetailAction(params)),
  clearCompanyUpdateRoleErrors: () => dispatch(clearCompanyUpdateRoleErrorsAction()),
  getCompanyJoinedCourses: (params) => dispatch(getCompanyJoinedCoursesAction(params)),
  getCompanyCourseDetail: (params) => dispatch(getCompanyCourseDetailAction(params)),
  showModal: (params) => dispatch(showModalAction(params)),
  getTestStudentCompany: (params) => dispatch(getTestStudentCompanyAction(params)),
  resetHistoryTestCompany: (params) => dispatch(resetHistoryTestCompanyAction(params)),
  closeModal: () => dispatch(closeModalAction()),
  unlockUserCompany: (params) => dispatch(unlockUserCompanyAction(params))
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailPage);
