// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Table } from 'antd';
import { isEmpty } from 'lodash';

import { formatDateTime } from 'src/utils/dateTime';
import history from 'src/utils/history';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { FilterSidebar } from 'src/components/layouts/Sidebar';
import SortableHeader from 'src/components/tables/SortableHeader';

import { QUESTION_STATUS_OPTION, QUESTION_STATUS } from 'src/constants/common';
import { Text } from 'src/components/styles';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getQuestionList as getQuestionListAction,
  getCategoryList as getCategoryListAction,
  getCompanyContractList as getCompanyContractListAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IQuestionListPageProps extends StateProps, DispatchProps {
  getQuestionList(params): void;
  getCategoryList(): void;
  getCompanyContractList(): void;
  categoryList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  companyContracts: {
    data: {
      id: number,
      contractName: string,
    }[],
  };
  questionList: {
    data: {
      id: number;
      name: string;
      createdAt: string;
      status: string;
      updatedAt: string;
      content: string;
      category: {
        id: number;
        name: string;
      },
      company: {
        id: number;
        contractName: string;
      },
      course: {
        id: number;
        name: string;
      },
      unit: {
        id: number;
        name: string;
      },
      user: {
        id: number;
        jituName: string;
      },
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: object[];
  };
}

const QuestionList: React.FC<IQuestionListPageProps> = ({
  getQuestionList,
  getCategoryList,
  getCompanyContractList,
  questionList,
  categoryList,
  companyContracts,
}) => {
  const { data, meta } = questionList;
  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.QuestionList);

    getQuestionList({ page: 1 });
    getCategoryList();
    getCompanyContractList();
  }, []);

  const columns = [
    {
      title: <SortableHeader
        type="content"
        title="質問"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'content',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.content}</TooltipParagraph>
      ),
      width: 300,
    },
    {
      title: <SortableHeader
        type="createdAt"
        title="質問日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'createdAt',
      width: 200,
    },
    {
      title: <SortableHeader
        type="status"
        title="ステータス"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'status',
      width: 200,
    },
    {
      title: <SortableHeader
        type="category"
        title="講座タイプ"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'category',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.category}
        </TooltipParagraph>
      ),
      width: 200,
    },
    {
      title: <SortableHeader
        type="courseName"
        title="コース名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'courseName',
      width: 200,
    },
    {
      title: <SortableHeader
        type="unitName"
        title="レッスン名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'unitName',
      width: 200,
    },
    {
      title: <SortableHeader
        type="companyId"
        title="契約名"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'companyId',
      width: 200,
    },
    {
      title: <SortableHeader
        type="username"
        title="質問者"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'username',
      width: 200,
    },
    {
      title: <SortableHeader
        type="updatedAt"
        title="更新日"
        page={meta.pageInfo?.page}
        pageSize={meta.pageInfo?.limit}
        sortCondition={sortCondition}
        filterParams={filterParams}
        actionSort={getQuestionList}
        setAction={setSortCondition}
      />,
      dataIndex: 'updatedAt',
      width: 200,
    },
    {
      title: '',
      dataIndex: 'actionGroup',
      key: 'actionGroup',
      render: (_, record) => (
        <Style.TableAction>
          <Button
            size="small"
            type="primary"
            onClick={() => history.push(`/admin-service/question/${record.key}`)}
          >
            &nbsp;詳細&nbsp;
          </Button>
        </Style.TableAction>
      ),
    },
  ];

  const tableData = data.map((row: any) => ({
    key: row.id,
    content: row.content,
    status: QUESTION_STATUS[row.status],
    category: row.category?.name,
    companyId: row.company?.contractName,
    courseName: row.course?.name,
    unitName: row.unit?.name,
    username: row.user?.jituName,
    updatedAt: formatDateTime(row.updatedAt, DATE_FORMAT),
    createdAt: formatDateTime(row.createdAt, DATE_FORMAT),
  }));

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '質問日',
    },
    {
      type: 'select',
      fieldParams: 'status',
      options: QUESTION_STATUS_OPTION,
      title: 'ステータス',
    },
    {
      type: 'select',
      fieldParams: 'category',
      options: categoryList.data,
      title: '講座タイプ',
    },
    {
      type: 'select',
      title: '契約名',
      fieldParams: 'company',
      options: companyContracts.data.map((company) => ({ id: company.id, name: company.contractName })),
    },
    {
      type: 'textInput',
      fieldParams: 'name',
      title: '質問名',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getQuestionList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>質問一覧</Text>
        </TopContent>
        <Table
          pagination={false}
          scroll={{ x: 2000, y: 506 }}
          columns={columns}
          dataSource={tableData}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={questionList.load}
        />
        {!isEmpty(questionList.data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getQuestionList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { companyContracts } = state.companyReducer;
  const { questionList } = state.questionReducer;
  const { categoryList } = state.categoryReducer;
  return {
    questionList,
    categoryList,
    companyContracts,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getQuestionList: (params) => dispatch(getQuestionListAction(params)),
  getCategoryList: () => dispatch(getCategoryListAction()),
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(QuestionList);
