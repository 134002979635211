// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
export const FAQ_ACTION = {
  GET_LIST: 'ADMIN_SERVICE/FAQ_ACTION/GET_LIST',
  CREATE_FAQ: 'ADMIN_SERVICE/FAQ_ACTION/CREATE_FAQ',
  UPDATE_FAQ: 'ADMIN_SERVICE/FAQ_ACTION/UPDATE_FAQ',
  GET_DETAIL: 'ADMIN_SERVICE/FAQ_ACTION/GET_DETAIL',
  ADD_FAQ_CONTENT: 'ADMIN_SERVICE/FAQ_ACTION/ADD_FAQ_CONTENT',
  UPDATE_FAQ_CONTENT: 'ADMIN_SERVICE/FAQ_ACTION/UPDATE_FAQ_CONTENT',
  DELETE_FAQ_CONTENT: 'ADMIN_SERVICE/FAQ_ACTION/DELETE_FAQ_CONTENT',
  CLEAR_FAQ_ERRORS: 'ADMIN_SERVICE/FAQ_ACTION/CLEAR_FAQ_ERRORS',
  CLEAR_FAQ_CONTENT_ERRORS: 'ADMIN_SERVICE/FAQ_ACTION/CLEAR_FAQ_CONTENT_ERRORS',
};
