// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { STUDENT, REQUEST } from '../../constant';

export function joinStudentTest(params) {
  return {
    type: REQUEST(STUDENT.TEST_ACTION.JOIN_TEST),
    payload: params,
  };
}

export function submitTestAnswers(params) {
  return {
    type: REQUEST(STUDENT.TEST_ACTION.SUBMIT_TEST_ANSWERS),
    payload: params,
  };
}

export function getStudentFileDownloadURL(params) {
  return {
    type: REQUEST(STUDENT.TEST_ACTION.GET_FILE_DOWNLOAD_URL),
    payload: params,
  };
}

export function getTestResults(params) {
  return {
    type: REQUEST(STUDENT.TEST_ACTION.GET_TEST_RESULTS),
    payload: params,
  };
}

export function uploadFileAnswer(params) {
  return {
    type: REQUEST(STUDENT.TEST_ACTION.UPLOAD_FILE_ANSWER),
    payload: params,
  };
}

export function getDetailsQuestion(params) {
  return {
    type: REQUEST(STUDENT.TEST_ACTION.GET_DETAILS_QUESTION),
    payload: params,
  };
}
