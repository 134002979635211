// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  STUDENT,
  SUCCESS,
  FAILURE,
} from '../../constant';

const initialState = {};

export type StudentVideoReducer = Readonly<typeof initialState>;

export default (state: StudentVideoReducer = initialState, action: any): StudentVideoReducer => {
  switch (action.type) {
  default:
    return state;
  }
};
