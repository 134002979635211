// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { MainContainer, MainContent } from 'src/components/layouts/ContentLayout';

import {
  getCurrentTime as getCurrentTimeAction,
  getServiceCurrentWeek as getServiceCurrentWeekAction,
  getServiceStatisticCourses as getServiceStatisticCoursesAction,
  getServiceRecentCourses as getServiceRecentCoursesAction,
} from 'src/redux/actions';

import TopOverview from './components/TopOverview';
import CourseList from './components/CourseList';
import RecentCourses from './components/RecentCourses';

import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

interface IAdminServiceTopPageProps extends StateProps, DispatchProps {
  getCurrentTime(): void;
  getServiceCurrentWeek(): void;
  getServiceStatisticCourses(): void;
  getServiceRecentCourses(): void;
  currentTime: {
    data: string;
  };
  currentWeek: {
    data: {
      companies: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      students: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      usedLicenses: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      learningTime: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
      joinedInTests: {
        currentWeek: number;
        total: number;
        lastWeek: number;
      };
    };
    load: boolean;
  };
  statisticCourses: {
    data: {
      course: {
        id: number;
        name: string;
        courseCategory: {
          name: string;
        };
      };
      licensesCount: number;
      usedLicensesCount: number;
      remainingLicensesCount: number;
      company: {
        id: number;
        contractName: string;
      }
      startDate: string;
      endDate: string;
    }[];
    load: boolean;
  };
  recentCourses: {
    data: {
      course: {
        id: number;
        name: string;
        courseCategory: {
          name: string;
        };
      };
      licensesCount: number;
      usedLicensesCount: number;
      remainingLicensesCount: number;
      company: {
        id: number;
        contractName: string;
      }
      startDate: string;
      endDate: string;
    }[];
    load: boolean;
  };
}

const AdminServiceTopPage: React.FC<IAdminServiceTopPageProps> = ({
  getCurrentTime,
  getServiceCurrentWeek,
  getServiceStatisticCourses,
  getServiceRecentCourses,
  currentTime,
  currentWeek,
  statisticCourses,
  recentCourses,
}) => {
  const startOfWeek = moment(currentTime.data).startOf('isoWeek');
  const endOfWeek = moment(currentTime.data).endOf('isoWeek');

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.AdminServiceTop);

    getCurrentTime();
    getServiceCurrentWeek();
    getServiceStatisticCourses();
    getServiceRecentCourses();
  }, []);

  return (
    <MainContainer>
      <MainContent fullView>
        <TopOverview
          currentWeek={currentWeek}
          startOfWeek={startOfWeek}
          endOfWeek={endOfWeek}
        />
        <CourseList statisticCourses={statisticCourses} />
        <RecentCourses recentCourses={recentCourses} />
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { currentTime } = state.masterDataReducer;
  const {
    currentWeek,
    statisticCourses,
    recentCourses,
  } = state.serviceStatisticReducer;
  return {
    currentWeek,
    statisticCourses,
    recentCourses,
    currentTime,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCurrentTime: () => dispatch(getCurrentTimeAction()),
  getServiceCurrentWeek: () => dispatch(getServiceCurrentWeekAction()),
  getServiceStatisticCourses: () => dispatch(getServiceStatisticCoursesAction()),
  getServiceRecentCourses: () => dispatch(getServiceRecentCoursesAction()),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminServiceTopPage);
