// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Button, Space, Avatar } from 'antd';

import { Text, SkeletonLoading } from 'src/components/styles';

export const ProfileInfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ProfileInfoContent = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media only screen and (max-width: 428px) {
    flex-direction: column;
    margin: 0 auto;
    justify-content: center;

    .ant-skeleton-avatar {
      display: block;
      margin: 0 auto;
      width: 70px !important;
      height: 70px !important;
    }
  }
`;

export const ProfileAvatar = styled.div`
  margin-right: 20px;
  min-width: 120px;
  height: 120px;

  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 428px) {
    min-width: 64px;
    width: 64px;
    height: 64px;
    margin-right: 0px;
  }
`;

export const PreviewAvatar = styled.div`
  margin: 20px;
  min-width: 240px;
  height: 240px;

  border-radius: ${(props) => props.theme.radius.circle};
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media only screen and (max-width: 428px) {
    min-width: 120px;
    height: 120px;
  }
`;

export const FieldInput = styled.input`
  display: none !important;
`;

export const PreviewAvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatar {
    margin: 20px;
    min-width: 240px;
  }

  @media only screen and (max-width: 428px) {
    .avatar {
      margin: 20px;
      min-width: 120px;
    }
  }
`;

export const ProfileInfoContentBottom = styled.div`
  width: calc(100% - 140px);

  @media only screen and (max-width: 428px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
  }
`

export const TextName = styled(Text)`
  font-size: 22px;

  @media only screen and (max-width: 428px) {
    max-width: 250px;
    font-size: 16px !important;
  }
`

export const TextId = styled(Text)`
  @media only screen and (max-width: 428px) {
    max-width: 150px;
    font-size: 14px !important;
  }
`

export const ProfileTabWrapper = styled.div`
  @media only screen and (max-width: 428px) {
    .ant-tabs-bar {
      display: none;
    }
  }
`

export const TextTitleInfo = styled(Text)`
  min-width: 150px;
  @media only screen and (max-width: 428px) {
    font-size: 14px !important;
    min-width: 124px;
    width: 124px;
    font-weight: 700;
  }
`

export const TextInfoUser = styled(Text)`
  @media only screen and (max-width: 428px) {
    font-size: 14px !important;
  }
`

export const ButtonAvatar = styled(Button)`
  @media only screen and (max-width: 428px) {
    height: 32px;
    padding: 2.4px 7px;
    font-size: 10px;
    border-radius: 4px;
    line-height: 30px;
  }
`

export const ButtonAvatarWrapper = styled(Space)`
  @media only screen and (max-width: 428px) {
    .ant-space-item:not(:last-child) {
      margin-right: 10px !important;
    }
  }
`

export const SkeletonDesktop = styled(SkeletonLoading)`
  @media only screen and (max-width: 428px) {
    display: none;
  }
`

export const SkeletonMobile = styled(SkeletonLoading)`
  display: none;
  @media only screen and (max-width: 428px) {
    display: block;
  }
`

export const AvatarDefault = styled(Avatar)`
  margin-right: 20px;
  min-width: 120px;
  @media only screen and (max-width: 428px) {
    display: none;
  }
`

export const AvatarDefaultMobile = styled(Avatar)`
  display: none;
  @media only screen and (max-width: 428px) {
    display: block;
  }
`

export const TitlePage = styled(Text)`
  margin-bottom: 16px;
  @media only screen and (max-width: 428px) {
    font-size: 20px;
  }
`

export const ButtonUploadAvatar = styled(Button)`
  margin-top: 10px;
  @media only screen and (max-width: 428px) {
    font-size: 14px !important;
  }
`
