// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';
import { Space } from 'antd';
import { Text } from 'src/components/styles';

export const OverviewContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const GroupCardStatus = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 450px);

  @media (max-width: 428px) {
    display: none;
  }
`;

export const OverviewContent = styled.div`
  width: calc(100% /3 - 16px);
  margin: 0 0 16px 16px;

  &:last-child {
    margin-right: 0;
  }
`;

export const OverviewCardContainer = styled.div`
  background-color: white;
`;

export const OverviewCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 160px;
`;

export const HistoryCardContainer = styled.div`
  width: 450px;
`;

export const HistoryCardContent = styled.div`
  position: relative;
  border-top: 5px solid #C08D01;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  overflow: hidden;
  cursor: pointer;
`;

export const HistoryCardContentDesktop = styled.div`
  position: relative;
  border-top: 5px solid #C08D01;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const HistoryCardContentMobile = styled.div`
  position: relative;
  border-top: 5px solid #C08D01;
  border-radius: ${(props) => props.theme.radius.sm};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
  background-color: white;
  overflow: hidden;
  cursor: pointer;
  display: none;

  @media (max-width: 428px) {
    display: block;
  }
`;

export const HistoryCardImage = styled.div`
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-image: url(${(props) => `"${props.image}"`});
  background-repeat: no-repeat;
  background-size: ${(props) => props.isThumbnailUrl ? 'cover' : '100px'};
  background-position: center;
  background-color: ${(props) => props.isThumbnailUrl ? 'unset' : '#F0F0F0'};;
`;

export const HistoryCardInfo = styled.div`
  padding: 20px;
  height: 86px;

  @media (max-width: 428px) {
    height: unset;
  }
`;

export const LoadingHistoryCardImage = styled.div`
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: #f2f2f2;
`;

export const LoadingStatusCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextHeaderDesktop = styled(Text)`
  display: block;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const TextHeaderMobile = styled(Text)`
  display: none;

  @media (max-width: 428px) {
    display: block;
    font-weight: 700;
  }
`;

export const ContentHistoryCard = styled(Space)`
  @media (max-width: 428px) {
    display: none;
  }
`;

export const TextNameHistory = styled(Text)`
  @media (max-width: 428px) {
    font-size: 12px;
    white-space: pre-line;
  }
`;
