// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
} from 'antd';
import isEmpty from 'lodash/isEmpty'

import { getSearchParams } from 'src/utils/common';

import {
  MainContainer,
  MainContent,
  MainWrapper,
} from 'src/components/layouts/ContentLayout';

import { PASSWORD_REGEX } from 'src/constants/regexValidate';
import { PAGE_TITLE, ResetPasswordTitle } from 'src/constants/pageTitle';

import {
  getResetPasswordStatus as getResetPasswordStatusAction,
  resetPassword as  resetPasswordAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IResetPasswordPageProps extends StateProps, DispatchProps {
  getResetPasswordStatus(params): void;
  resetPassword(params): void;
  resetPasswordStatus: boolean;
  errors: {
    code?: number,
    message: string
  }[]
}

const ResetPasswordPage: React.FC<IResetPasswordPageProps> = ({
  getResetPasswordStatus,
  resetPassword,
  resetPasswordStatus,
  errors
}) => {
  const [resetPasswordForm] = Form.useForm();
  const token = getSearchParams('token');
  const role = getSearchParams('role');

  useEffect(() => {
    document.title = PAGE_TITLE(ResetPasswordTitle);

    getResetPasswordStatus({
      token,
      ...role && { role },
    });
  }, []);

  function handleSubmitForm(values) {
    resetPassword({
      token,
      newPassword: values.password,
      ...role && { role },
    });
  }

  function renderResetPasswordForm() {
    return (
      <Form
        form={resetPasswordForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        name="resetPasswordForm"
        onFinish={(values) => handleSubmitForm(values)}
      >
        {!isEmpty(errors) && errors[0]?.code === 1314 && (
          <Style.TextError sm error>
            アカウントがロックされています。解除の申請をする場合は
            <Style.Link
              href="https://share.hsforms.com/118qwvfYSTH6mTxLq8hFoJg2mtef"
              target="_blank"
            >
              こちら
            </Style.Link>
            からお問い合わせください。
          </Style.TextError>
        )}
        <Form.Item
          label="新しいパスワード"
          name="password"
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            { required: true, message: '新しいパスワードは必須項目です。' },
            { min: 8, message: '新しいパスワードは8文字以上で設定して下さい。' },
            { max: 20, message: '新しいパスワードが長すぎます。（最大は20桁です）' },
            {
              pattern: new RegExp(PASSWORD_REGEX),
              message: '新しいパスワードは大文字、小文字、数字、記号から3つ以上の組み合わせで入力してください。',
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="新しいパスワード（確認用）"
          name="confirmPassword"
          dependencies={['password']}
          labelAlign="left"
          colon={false}
          validateTrigger={['onBlur']}
          validateFirst
          rules={[
            { required: true, message: '新しいパスワード（確認用）は必須項目です。' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('新しいパスワードと確認用のパスワードが一致しません。ご確認ください。');
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Style.GroupAction>
          <Button
            type="primary"
            htmlType="submit"
          >
            &nbsp;確定&nbsp;
          </Button>
        </Style.GroupAction>
      </Form>
    );
  }

  if (!resetPasswordStatus) return null;
  return (
    <MainContainer>
      <MainContent formView>
        <MainWrapper title="パスワード再設定" style={{ marginTop: 24 }}>
          <Style.ResetPasswordContainer>
            {renderResetPasswordForm()}
          </Style.ResetPasswordContainer>
        </MainWrapper>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { resetPasswordStatus, errorsReset } = state.authReducer;
  return {
    resetPasswordStatus,
    errors: errorsReset,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getResetPasswordStatus: (params) => dispatch(getResetPasswordStatusAction(params)),
  resetPassword: (params) => dispatch(resetPasswordAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
