// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled from 'styled-components';

export const DefaultLayoutContainer = styled.div`
  min-height: 100vh;
`;

export const MainLayout = styled.div`
  min-height: calc(100vh - 112px);
  background-color: white;
  overflow: hidden;
`;
