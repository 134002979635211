// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
  Collapse,
  Radio
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { isEmpty, isNumber } from 'lodash/lang';
import { useDropzone } from "react-dropzone";

import history from 'src/utils/history';
import { formatDateTime, convertSecondTime } from 'src/utils/dateTime';
import { getFilterOption } from 'src/utils/common';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';
import { Text } from 'src/components/styles/Texts';

import {
  getPlanList as getPlanListAction,
  getCompanyContractList as getCompanyContractListAction,
  getTagList as getTagListAction,
  getTeacherList as getTeacherListAction,
  getCategoryList as getCategoryListAction,
  createCourse as createCourseAction,
  updateCourse as updateCourseActon,
} from 'src/redux/actions';

import { DATE_TIME_FORMAT, FILE_IMAGE_SIZE, ALLOWED_TYPE_IMAGE_LOGO, TYPE_IMAGE_LOGO } from 'src/constants/common';
import { ONLY_NUMBER } from 'src/constants/regexValidate';
import { ServicePath } from 'src/constants/routerConstants';
import UploadImage from 'src/assets/images/common/upload-file.svg'

import * as Style from './styles';

enum SettingLogoMode {
  SETTING = "setting",
  NONSETING ="non-setting"
}

interface ICourseOverviewProps extends StateProps, DispatchProps {
  getPlanList(): void;
  getTagList(): void;
  getCompanyContractList(): void;
  getTeacherList(): void;
  getCategoryList(): void;
  createCourse(params): void;
  updateCourse(params): void;
  clearCourseDetailErrors(): void;
  courseId: string;
  isDisableRequest: boolean;
  planList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  companyContracts: {
    data: {
      id: number,
      contractName: string,
    }[],
  };
  tagList: {
    data: {
      id: number,
      name: string,
    }[],
  };
  teacherList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  categoryList: {
    data: {
      id: number,
      name: string,
    }[];
  };
  courseDetail: {
    data: {
      id: number,
      versionDescription: string,
      versionName: string,
      versionType: string,
      name: string,
      isLearned: boolean,
      isDisplayLogo: boolean,
      maxEndDate: string,
      minStartDate: string,
      status: string,
      logo:{
        logoName: string,
        logoUrl: string,
      },
      category: {
        id: number;
        name: string;
      },
      courseCategory: {
        id: number;
        name: string;
      },
      overview: string,
      updatedAt: string,
      updater: string,
      plan: {
        id: number,
        name: string,
      },
      lastUpdater: {
        id: number,
        jituName: string,
      }
      tags: {
        id: number,
        name: string,
      }[],
      teachers: {
        id: number,
        name: string,
      }[],
      contentOverview: {
        videosCount: number,
        videosTotalDuration: number,
        finishTestsCount: number,
        finishTestsTotalQuestion: number,
        preTestsCount: number,
        preTestsTotalQuestion: number,
        confirmTestsCount: number,
        confirmTestsTotalQuestion: number,
        verifyTestsCount: number,
        verifyTestsTotalQuestion: number,
      };
      courseDurations: string[];
    },
    errors,
  };
}

interface ImageUpload {
  name: string,
  size: number,
  type: string
}

const CourseOverview: React.FC<ICourseOverviewProps> = ({
  createCourse,
  getPlanList,
  getCompanyContractList,
  getTagList,
  getTeacherList,
  getCategoryList,
  updateCourse,
  clearCourseDetailErrors,
  courseId,
  courseDetail,
  tagList,
  planList,
  teacherList,
  categoryList,
  isDisableRequest
}) => {
  const [courseForm] = Form.useForm();
  const { data, errors } = courseDetail;

  const [settingLogoState, setSettingLogoState] = useState<boolean>(false)
  const [imageFile, setImageFile] = useState<any>()
  const [imageError, setImageError] = useState<string>("")
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>()
  const [imageName, setImageName] = useState<string>("")
  const courseOverviewFormData = new FormData();

  const scrollRef = useRef<any>(null);

  const onDrop = (acceptedFiles) => {
    if (isEmpty(acceptedFiles)) {
      setImageFile("")
      setImageUrl("");
      setImageError('');
      setImageName('')
      return
    }
    setImageFile(acceptedFiles[0])
    setImageName(acceptedFiles[0].name)
    const arrayName = acceptedFiles[0].name.split('.')?.toString().toLowerCase();

    if (
      !ALLOWED_TYPE_IMAGE_LOGO.includes(acceptedFiles[0]?.type) ||
      !(
        !isEmpty(arrayName) &&
        TYPE_IMAGE_LOGO.includes(arrayName[arrayName.length - 1])
      )
    ) {
      setImageError('png、jpg、jpeg等の画像ファイルをアップロードしてください。');
      setImageUrl("");
    } else if (acceptedFiles[0].size > FILE_IMAGE_SIZE) {
      setImageError('ファイルサイズが大きすぎます。(最大サイズ：5MB）');
      setImageUrl("");
    } else {
      setImageError('');
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } =
    useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      accept: "image/*"
    });
  
  useEffect(() => {
    getPlanList();
    getCompanyContractList();
    getTagList();
    getTeacherList();
    getCategoryList();
  }, []);

  useEffect(() => {
    if (errors.length > 0 && errors[0].field === 'version_name') {
      scrollRef.current.scrollIntoView();
      courseForm.setFields([
        {
          name: 'versionName',
          errors: [errors[0].message],
        },
      ]);
      clearCourseDetailErrors();
    }
  }, errors[0]);

  useEffect(() => {
    if (data.id) {
      courseForm.resetFields();
      if(courseId){
        setSettingLogoState(data.isDisplayLogo)
        setImageUrl(data.logo.logoUrl)
        setImageName(data.logo.logoName)
      }
    }
  }, [data]);

  useEffect(() => {
    if (!settingLogoState) {
      if (courseId) {
        setImageUrl(data?.logo?.logoUrl || "");
        setImageName(data?.logo?.logoName || "");
      } else {
        setImageUrl("");
        setImageName("");
      }
      setImageError("")
    }
  }, [settingLogoState]);

  const courseInitialFormValues = {
    planId: data.plan?.id,
    status: data.status,
    name: data.courseCategory?.name,
    categoryId: data.category?.id,
    tagIds: data.tags.map((tag) => tag.id),
    teacherIds: data.teachers.map((teacher) => teacher.id),
    overview: data.overview,
    versionName: data.versionName,
    versionDescription: data.versionDescription,
    versionType: data.versionType,
  };

  function handleSubmitForm(values) {
    if(settingLogoState && imageFile){
      courseOverviewFormData.append('course[logo]',imageFile , imageFile.name);
    }

    if(!isEmpty(values.tagIds)){
      for (let i = 0; i < values.tagIds.length; i++) {
        courseOverviewFormData.append("course[tag_ids][]",values.tagIds[i])
      }
    }else{
      courseOverviewFormData.append("course[tag_ids][]",isEmpty(values.tagIds) ? [] : values.tagIds)
    }

    if(!isEmpty(values.teacherIds)){
      for (let i = 0; i < values.teacherIds.length; i++) {
        courseOverviewFormData.append("course[teacher_ids][]",values.teacherIds[i])
      }
    }else{
      courseOverviewFormData.append("course[teacher_ids][]",isEmpty(values.teacherIds) ? [] : values.teacherIds)
    }
    
    values.planId && courseOverviewFormData.append("course[plan_id]", values.planId)
    values.status && courseOverviewFormData.append("course[status]", values.status)
    values.versionName && courseOverviewFormData.append("course[version_name]", values.versionName)
    values.versionDescription && courseOverviewFormData.append("course[version_description]", values.versionDescription)
    isNumber(values.categoryId) && courseOverviewFormData.append("course[category_id]", values.categoryId)
    values.overview && courseOverviewFormData.append("course[overview]", values.overview.trim())
    values.versionType && courseOverviewFormData.append("course[version_type]", values.versionType)
    courseOverviewFormData.append("course[is_display_logo]", settingLogoState.toString())
    courseOverviewFormData.append("course_category[name]", values.name.trim())

    if (courseId) {
      updateCourse({ requestData: courseOverviewFormData, id: courseId });
    } else {
      createCourse(courseOverviewFormData);
    }
  }

  function renderPlanOptions() {
    if (isEmpty(planList.data)) return null;
    return planList.data.map((plan) => (
      <Select.Option key={`plan-${plan.id}`} value={plan.id}>{plan.name}</Select.Option>
    ));
  }

  function renderTagsOptions() {
    if (isEmpty(tagList.data)) return null;
    return tagList.data.map((tag) => (
      <Select.Option key={`tag-${tag.id}`} value={tag.id}>{tag.name}</Select.Option>
    ));
  }

  function renderTeachersOptions() {
    if (isEmpty(teacherList.data)) return null;
    return teacherList.data.map((teacher) => (
      <Select.Option key={`teacher-${teacher.id}`} value={teacher.id}>{teacher.name}</Select.Option>
    ));
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  function renderCourseTotal() {
    const {
      videosCount,
      videosTotalDuration,
      finishTestsCount,
      finishTestsTotalQuestion,
      preTestsCount,
      preTestsTotalQuestion,
      confirmTestsCount,
      confirmTestsTotalQuestion,
      verifyTestsCount,
      verifyTestsTotalQuestion,
    } = data.contentOverview;
    if (courseId) {
      return (
        <Style.FormRow>
          <Style.FormCol span={3}>
            <Text>コンテンツ内容</Text>
          </Style.FormCol>
          <Style.FormCol span={21}>
            <Style.CourseTotalDetail>
              <Text>{`動画数: ${videosCount}本`}</Text>
              <Text>
                {`再生時間: ${convertSecondTime(videosTotalDuration, 'HH:mm:ss')}`}
              </Text>
              <Text>
                {`事前テスト: ${preTestsCount}テスト[${preTestsTotalQuestion}問]`}
              </Text>
              <Text>
                {`確認テスト: ${verifyTestsCount}テスト[${verifyTestsTotalQuestion}問]`}
              </Text>
              <Text>
                {`修了テスト: ${finishTestsCount}テスト[${finishTestsTotalQuestion}問]`}
              </Text>
              <Text>
                {`知識テスト: ${confirmTestsCount}テスト[${confirmTestsTotalQuestion}問]`}
              </Text>
            </Style.CourseTotalDetail>
          </Style.FormCol>
        </Style.FormRow>
      );
    }
    return null;
  }

  return (
    <Style.CourseOverviewContainer>
      <Collapse
        bordered={false}
        defaultActiveKey={['courseOverview']}
        expandIconPosition="right"
        expandIcon={
          ({ isActive }) => isActive ? <MinusOutlined style={{ color: 'white' }} /> : <PlusOutlined style={{ color: 'white' }} />
        }
        style={{ backgroundColor: '#0D122D' }}
      >
        <Collapse.Panel
          key="courseOverview"
          header={
            <Text white w6>コース概要</Text>
          }
        >
          <Form
            form={courseForm}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 21 }}
            {...courseId
              ? { initialValues: courseInitialFormValues }
              : { initialValues: {
                status: 'opening',
                licensesCount: 50,
                versionName: '1',
                versionDescription: '初期バージョン',
                versionType: 'automation',
              }}
            }
            name="modifyCourseForm"
            onFinish={(values) => handleSubmitForm(values)}
            scrollToFirstError={true}
          >
            <Style.CourseOverviewContent>
              <Form.Item
                label="プラン名"
                name="planId"
                labelAlign="left"
                colon={false}
              >
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="選択してください"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                  style={{ width: 350 }}
                >
                  {renderPlanOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="ステータス"
                name="status"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'ステータスは必須項目です。' },
                ]}
              >
                <Select
                  allowClear
                  suffixIcon={<CaretDownOutlined />}
                  placeholder="選択してください"
                  disabled={isEmpty(courseId)}
                  style={{ width: 350 }}
                >
                  <Select.Option value="opening">オープン</Select.Option>
                  <Select.Option value="closed">中止</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="コース名"
                name="name"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'コース名は必須項目です。' },
                  { whitespace: true, message: 'コース名は必須項目です。' },
                  { max: 50, message: 'コース名が長すぎます。（最大は50桁です）' },
                ]}
              >
                <Input disabled={!!courseId} />
              </Form.Item>
              <div ref={scrollRef} />
              <Form.Item
                label="バージョン名"
                name="versionName"
                labelAlign="left"
                colon={false}
                validateFirst
                rules={[
                  { required: true, whitespace: true, message: 'バージョン名は必須項目です。' },
                  { max: 50, message: 'バージョン名が長すぎます。（最大は50桁です）' },
                  {...(courseId && data.versionType === 'custom') && {
                    pattern: new RegExp(ONLY_NUMBER),
                    message: 'バージョン名は数字のみを入力しないでください。',
                  }},
                ]}
              >
                <Input
                  style={{ width: 350 }}
                  disabled={data.versionType === 'automation' || isEmpty(courseId)}
                />
              </Form.Item>
              <Form.Item
                label="説明"
                name="versionDescription"
                labelAlign="left"
                colon={false}
                validateFirst
                rules={[
                  { required: true, message: '説明は必須項目です。' },
                  { whitespace: true, message: '説明は必須項目です。' },
                  { max: 100, message: '説明が長すぎます。（最大は100桁です）' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="講座タイプ"
                name="categoryId"
                labelAlign="left"
                colon={false}
              >
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="選択してください"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                  style={{ width: 350 }}
                >
                  {renderCategoryOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tags"
                name="tagIds"
                labelAlign="left"
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                  style={{ width: 350 }}
                >
                  {renderTagsOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="担当講師"
                name="teacherIds"
                labelAlign="left"
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  filterOption={getFilterOption}
                  style={{ width: 350 }}
                >
                  {renderTeachersOptions()}
                </Select>
              </Form.Item>
              {renderCourseTotal()}
              <Form.Item
                label="コース概要"
                name="overview"
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'コース概要は必須項目です。' },
                  { whitespace: true, message: 'コース概要は必須項目です。' },
                  { max: 500, message: 'コース概要が長すぎます。（最大は500桁です）' },
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4, maxRows: 8 }} />
              </Form.Item>
              <Form.Item
                label="オリジナルロゴ"
                name="isDisplayLogo"
                labelAlign="left"
                colon={false}
              >
                 <Style.OptionGroup
                  onChange={(e) => {
                    setSettingLogoState(
                      e.target.value === SettingLogoMode.SETTING
                    );
                  }}
                  defaultValue={
                    settingLogoState
                      ? SettingLogoMode.SETTING
                      : SettingLogoMode.NONSETING
                  }
                >
                  <Radio value="non-setting" style={{marginBottom: 16}}>設定しない</Radio>
                  <Radio value="setting">設定する</Radio>
                </Style.OptionGroup>
              </Form.Item>
              {settingLogoState && (
                <Row style={{ width: "100%" }}>
                  <Col span={4} />
                  <Col span={20}>
                    <Style.BlockAddLogo
                      {...getRootProps()}
                      isDragActive={isDragActive}
                    >
                      <input {...getInputProps()} />
                      {imageUrl ? (
                        <Style.ImageLogoPreview
                          src={imageUrl}
                          alt="logo"
                          draggable={false}
                          onClick={open}
                        />
                      ) : (
                        <Style.UploadImage
                          src={UploadImage}
                          alt="upload icon"
                          draggable={false}
                          onClick={open}
                        />
                      )}
                      {imageError && (
                        <Text error margin="10px 0 10px 10px">
                          {imageError}
                        </Text>
                      )}
                      {imageName ? (
                        <Text
                          notSelect
                          truncate
                          center
                          lg
                          w6
                          style={{
                            width: 450,
                          }}
                        >
                          {imageName}
                        </Text>
                      ) : (
                        <>
                          <span>サムネイルをクリックしてロゴファイルを</span>
                          <span>アップロードしてください。</span>
                          <span>（JPEG、またはpngファイル）</span>
                        </>
                      )}
                    </Style.BlockAddLogo>
                  </Col>
                </Row>
              )}
            </Style.CourseOverviewContent>
            <Style.CourseOverviewFooter>
              <Row style={{ width: '100%' }}>
                <Col span={3} />
                <Col span={11}>
                  {courseId && (
                    <Space align="center" size={32} style={{ height: '100%' }} >
                      <Space align="center" size="large">
                        <Text xs subText w6>更新者</Text>
                        <TooltipParagraph
                          style={{ maxWidth: 300, color: '#333333', fontWeight: '600' }}
                        >
                          {data.lastUpdater.jituName}
                        </TooltipParagraph>
                      </Space>
                      <Space align="center" size="large">
                        <Text xs subText w6>更新日</Text>
                        <Text headerText w6>{formatDateTime(data.updatedAt, DATE_TIME_FORMAT)}</Text>
                      </Space>
                    </Space>
                  )}
                </Col>
                <Col span={10}>
                  <Style.CourseOverviewAction>
                    <Space align="center" size="middle">
                      <Button
                        type="primary"
                        className="btn-secondary"
                        onClick={() => {
                          if (courseId) {
                            history.push(`/admin-service/course/${courseId}`);
                          } else history.push(ServicePath.CourseList);
                        }}
                      >
                        キャンセル
                      </Button>
                      <Button 
                        type="primary" 
                        htmlType="submit" 
                        disabled={isDisableRequest || (settingLogoState && !imageUrl)}
                      >
                        保存する
                      </Button>
                    </Space>
                  </Style.CourseOverviewAction>
                </Col>
              </Row>
            </Style.CourseOverviewFooter>
          </Form>
        </Collapse.Panel>
      </Collapse>
    </Style.CourseOverviewContainer>
  );
};

const mapStateToProps = (state) => {
  const { tagList } = state.tagReducer;
  const { companyContracts } = state.companyReducer;
  const { planList } = state.planReducer;
  const { teacherList } = state.teacherReducer;
  const { categoryList } = state.categoryReducer;
  const { isDisableRequest } = state.disableRequestReducer;
  return {
    planList,
    companyContracts,
    tagList,
    teacherList,
    categoryList,
    isDisableRequest
  };
};

const mapDispatchToProps = (dispatch) => ({
  getPlanList: () => dispatch(getPlanListAction()),
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  getTagList: () => dispatch(getTagListAction()),
  getTeacherList: () => dispatch(getTeacherListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  createCourse: (params) => dispatch(createCourseAction(params)),
  updateCourse: (params) => dispatch(updateCourseActon(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(CourseOverview);
