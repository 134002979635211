// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Space,
  Row,
  Col,
  Button,
  Form,
  Input,
  Select,
} from 'antd';
import { CaretDownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { isEmpty, isNumber } from 'lodash/lang';

import history from 'src/utils/history';
import { formatDateTime } from 'src/utils/dateTime';
import { getFilterOption, handleStopPropagation } from 'src/utils/common';
import {
  MainContainer,
  MainContent,
} from 'src/components/layouts/ContentLayout';

import ConfirmModal from 'src/components/Modal/components/ConfirmModal';
import ModifyFAQContentModal from './components/ModifyFAQContentModal';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { Text } from 'src/components/styles/Texts';

import { DATE_TIME_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';

import {
  getFAQDetail as getFAQDetailAction,
  getTagList as getTagListAction,
  getCategoryList as getCategoryListAction,
  updateFAQ as updateFAQAction,
  deleteFAQContent as deleteFAQContentAction,
  clearFAQErrors as clearFAQErrorsAction,
  clearFAQContentErrors as clearFAQContentErrorsAction,
  showModal as showModalAction,
  closeModal as closeModalAction,


} from 'src/redux/actions';

import * as Style from './styles';

interface IEditFAQPageProps extends StateProps, DispatchProps {
  getFAQDetail(params): void;
  getCategoryList(): void;
  getTagList(): void;
  updateFAQ(params): void;
  deleteFAQContent(params): void;
  clearFAQErrors(): void;
  clearFAQContentErrors(): void;
  showModal(params): void;
  closeModal(): void;
  match: {
    params: {
      id?: string;
    };
  };
  tagList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  categoryList: {
    data: {
      id: number;
      name: string;
    }[];
  };
  faqDetail: {
    data: {
      id: number;
      name: string;
      category: {
        id: number;
        name: string;
      };
      tags: {
        id: number;
        name: string;
      }[];
      updatedAt: string;
      creator: {
        jituName: string;
      }
      faqContents: {
        id: number;
        question: string;
        answer: string;
      }[];
    };
    meta: object;
    errors: {
      message: string;
      field: string;
    }[];
  };
}

const EditFAQPage: React.FC<IEditFAQPageProps> = ({
  getFAQDetail,
  getTagList,
  getCategoryList,
  updateFAQ,
  deleteFAQContent,
  clearFAQErrors,
  clearFAQContentErrors,
  showModal,
  closeModal,
  match,
  categoryList,
  faqDetail,
  tagList,
}) => {

  const faqId = match.params.id;
  const [faqForm] = Form.useForm();

  useEffect(() => {
    if (faqId && parseFloat(faqId) !== faqDetail.data.id) {
      getFAQDetail({ id: faqId });
    }
    getTagList();
    getCategoryList();
  }, [faqId]);

  useEffect(() => {
    if (faqDetail.data.id) {
      faqForm.resetFields();
    }
  }, [faqDetail.data.id]);

  useEffect(() => {
    if (!isEmpty(faqDetail.errors)) {
      faqDetail.errors.forEach((error) => {
        faqForm.setFields([ { name: error.field, errors: [error.message] } ]);
      });
    }
  }, [faqDetail.errors]);

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.EditFAQ);

    return () => {
      clearFAQErrors();
      closeModal();
    }
  }, []);

  const faqInitialFormValues = {
    name: faqDetail.data.name,
    creator: faqDetail.data.creator?.jituName,
    categoryId: faqDetail.data.category?.id,
    tagIds: faqDetail.data.tags?.map((tag) => tag.id),
  };

  function handleSubmitForm(values) {
    const requestData = {
      faq: {
        name: values.name.trim(),
        tagIds: isEmpty(values.tagIds) ? [] : values.tagIds,
        ...(isNumber(values.categoryId) && { categoryId: values.categoryId }),
      },
    };
    updateFAQ({ requestData, id: faqId });
  }

  function handleAddFAQContent(position) {
    clearFAQContentErrors();
    const modalProps = {
      modalData: {
        title: '質問、回答の新規追加',
        actionType: 'add',
        faqId,
        position,
      },
    };
    return showModal(<ModifyFAQContentModal {...modalProps} />);
  }

  function handleEditFAQContent(e, faqContent) {
    handleStopPropagation(e);
    clearFAQContentErrors();
    const modalProps = {
      modalData: {
        title: '質問、回答の編集',
        actionType: 'edit',
        faqId,
        faqContent,
      },
    };
    return showModal(<ModifyFAQContentModal {...modalProps} />);
  }

  function handleDeleteFAQContent(e, faqContent) {
    handleStopPropagation(e);
    const modalProps = {
      isConfirm: true,
      modalData: {
        title: '削除確認',
        message: 'この質問を削除してもよろしいですか。？',
      },
      modalAction: {
        confirm: () => deleteFAQContent({ faqId, faqContentId: faqContent.id }),
      },
    };
    return showModal(<ConfirmModal {...modalProps} />);
  }

  function renderCategoryOptions() {
    if (isEmpty(categoryList.data)) return null;
    return categoryList.data.map((category) => (
      <Select.Option key={`category-${category.id}`} value={category.id}>{category.name}</Select.Option>
    ));
  }

  function renderTagsOptions() {
    if (isEmpty(tagList.data)) return null;
    return tagList.data.map((tag) => (
      <Select.Option key={`tag-${tag.id}`} value={tag.id}>{tag.name}</Select.Option>
    ));
  }

  function renderFAQContents() {
    return faqDetail.data.faqContents.map((faqContent, faqContentIndex) =>
      (
        <Style.FAQItemContainer key={`faq-${faqContentIndex}`}>
          <Style.FAQItemContent>
            <Row style={{ width: '100%' }}>
              <Style.FAQCol span={18}>
                <Text
                  lg
                  truncate
                >
                  {faqContent.question}
                </Text>
              </Style.FAQCol>
              <Style.FAQCol span={6}>
                <Style.FAQAction>
                  <Button
                    className="faq-item-button"
                    size="small"
                    danger
                    ghost
                    onClick={(e) => handleDeleteFAQContent(e, faqContent)}
                    style={{ marginRight: 16 }}
                  >
                    &nbsp;削除&nbsp;
                  </Button>
                  <Button
                    className="faq-item-button"
                    type="primary"
                    size="small"
                    onClick={(e) => handleEditFAQContent(e, faqContent)}
                  >
                    &nbsp;編集&nbsp;
                  </Button>
                </Style.FAQAction>
              </Style.FAQCol>
            </Row>
          </Style.FAQItemContent>
          <Style.AddFAQButton position="top">
            <Button
              className="faq-item-button"
              type="link"
              size="small"
              icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
              onClick={() => handleAddFAQContent(faqContentIndex)}
              style={{ padding: 0 }}
            >
              新規追加
            </Button>
          </Style.AddFAQButton>
          <Style.AddFAQButton position="bottom">
            <Button
              className="faq-item-button"
              type="link"
              size="small"
              icon={<PlusCircleOutlined style={{ fontSize: 20 }} />}
              onClick={() => handleAddFAQContent(faqContentIndex + 1)}
              style={{ padding: 0 }}
            >
              新規追加
            </Button>
          </Style.AddFAQButton>
        </Style.FAQItemContainer>
      ));
  }

  return (
    <MainContainer backgroundHeight={254}>
      <MainContent fullView>
        <Form
          form={faqForm}
          initialValues={faqInitialFormValues}
          name="modifyFAQForm"
          onFinish={(values) => handleSubmitForm(values)}
          scrollToFirstError={true}
        >
          <Space align="center" size={32}>
            <Text headerText xxxl w6>{`${ faqId ? 'FAQ修正' : 'FAQ新規作成'}`}</Text>
            {faqId && (
              <Space align="center" size={24}>
                <Space align="center" size={24}>
                  <Text secondaryText xs w6>更新者</Text>
                  <TooltipParagraph
                    style={{ maxWidth: 300, color: '#333333', fontWeight: '600' }}
                  >
                    {faqDetail.data.creator?.jituName}
                  </TooltipParagraph>
                </Space>
                <Space align="center" size={24}>
                  <Text secondaryText xs w6>更新日</Text>
                  <Text headerText w6>
                    {formatDateTime(faqDetail.data.updatedAt, DATE_TIME_FORMAT)}
                  </Text>
                </Space>
              </Space>
            )}
          </Space>
          <Row gutter={30}>
            <Col span={12}>
              <Form.Item
                label="項目名"
                name="name"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
                rules={[
                  { required: true, message: '項目名は必須項目です。' },
                  { whitespace: true, message: '項目名は必須項目です。' },
                  { max: 100, message: '項目名が長すぎます。（最大は100桁です）' },
                ]}
                style={{ marginTop: 24 }}
              >
                <Input className="transparent-input" />
              </Form.Item>
              <Form.Item
                name="tagIds"
                label="Tags"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
              >
                <Select
                  mode="multiple"
                  optionFilterProp="children"
                  notFoundContent="データが存在しません。"
                  className="transparent-input"
                  filterOption={getFilterOption}
                >
                  {renderTagsOptions()}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="categoryId"
                label="講座タイプ"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
                style={{ marginTop: 24 }}
              >
                <Select
                  allowClear
                  showSearch
                  suffixIcon={<CaretDownOutlined />}
                  optionFilterProp="children"
                  placeholder="選択してください"
                  notFoundContent="データが存在しません。"
                  className="transparent-input"
                  filterOption={getFilterOption}
                  style={{ width: '35%' }}
                >
                  {renderCategoryOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                name="creator"
                label="作成者"
                labelAlign="left"
                labelCol={{ span: 4 }}
                colon={false}
              >
                <Input style={{ color: '#343A40' }} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Style.EditFAQAction>
            <Space align="center" size="middle">
              <Button
                type="primary"
                htmlType="button"
                className="btn-secondary"
                onClick={() => history.goBack()}
              >
                キャンセル
              </Button>
              <Button htmlType="submit" type="primary">
                保存する
              </Button>
            </Space>
          </Style.EditFAQAction>
        </Form>
        <Style.FAQContentsContainer>
          {renderFAQContents()}
        </Style.FAQContentsContainer>
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { tagList } = state.tagReducer;
  const { categoryList } = state.categoryReducer;
  const { faqDetail } = state.faqReducer;
  return {
    tagList,
    categoryList,
    faqDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getFAQDetail: (params) => dispatch(getFAQDetailAction(params)),
  getTagList: () => dispatch(getTagListAction()),
  getCategoryList: () => dispatch(getCategoryListAction()),
  updateFAQ: (params) => dispatch(updateFAQAction(params)),
  deleteFAQContent: (params) => dispatch(deleteFAQContentAction(params)),
  clearFAQErrors: () => dispatch(clearFAQErrorsAction()),
  clearFAQContentErrors: () => dispatch(clearFAQContentErrorsAction()),
  showModal: (params) => dispatch(showModalAction(params)),
  closeModal: () => dispatch(closeModalAction())

});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(EditFAQPage);

