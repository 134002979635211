// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import {
  Form,
  Input,
} from 'antd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import {
  updateCategory as updateCategoryAction,
  createCategory as createCategoryAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IModifyCategoryModalProps {
  updateCategory(params): void;
  createCategory(params): void;
  modalData: {
    category: {
      id: number;
      name: string;
      description: string,
    },
  };
}

const ModifyCategoryModal: React.FC<IModifyCategoryModalProps> = ({
  updateCategory,
  createCategory,
  modalData,
}) => {

  const [modifyCategoryForm] = Form.useForm();
  const categoryId = modalData.category?.id;

  const categoryInitialFormValues = {
    name: modalData.category?.name,
    description: modalData.category?.description,
  };

  function handleSubmitForm(values) {
    const requestData = {
      category: {
        name: values.name.trim(),
        description: (values.description || '').trim(),
      },
    };
    if (categoryId) {
      return updateCategory({ requestData, id: categoryId });
    } else {
      return createCategory(requestData);
    }
  }
  return (
    <Form
      form={modifyCategoryForm}
      labelCol={{ span: 5 }}
      wrapperCol={{ span: 19 }}
      name="modifyCategoryForm"
      {...modalData.category.id && { initialValues: categoryInitialFormValues }}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.CategoryFieldContent>
        <Form.Item
          label="グループ名"
          name="name"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { required: true, message: 'グループ名は必須項目です。' },
            { whitespace: true, message: 'グループ名は必須項目です。' },
            { max: 25, message: 'グループ名が長すぎます。（最大は25桁です）' },
          ]}
        >
          <Input className="transparent-input" />
        </Form.Item>
        <Form.Item
          label="グループ説明"
          name="description"
          labelAlign="left"
          labelCol={{ span: 4 }}
          colon={false}
          rules={[
            { max: 125, message: 'グループ説明が長すぎます。（最大は125桁です）'}
          ]}
        >
          <Input />
        </Form.Item>
      </Style.CategoryFieldContent>
      <ModalFooter
        okText="保存する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapStateToProps = (state) => {
};

const mapDispatchToProps = (dispatch) => ({
  updateCategory: (params) => dispatch(updateCategoryAction(params)),
  createCategory: (params) => dispatch(createCategoryAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ModifyCategoryModal);
