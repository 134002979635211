// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const TabWrapperContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondaryBorder};

  &:last-child {
    border-bottom: none;
  }

  ${(props) => props.isEdit
    && css`
      height: unset;
      border-bottom: none;
    `}
`;

export const ProfileSettingTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6px;

  & .ant-select-selector {
    padding-left: 0 !important;
    background-color: unset !important;
  }
  & .ant-select-disabled {
    background-color: ${(props) => props.theme.colors.secondaryBorder} !important;
  }
  & .ant-select-selection-item {
    color: #333333;
    font-size: 24px;
    font-weight: 600;
  }
  & .ant-select-selection-placeholder {
    font-size: 24px;
  }
  & .ant-select-selection-search{
    left: 0 !important;
  }
  & .ant-select-selection-search-input {
    font-size: 24px;
    font-weight: 600;
  }
  & .ant-select-disabled {
    background-color: unset !important;
    opacity: 0.5;
  }
`;

export const ProfileSettingContent = styled.div`
  ${(props) => props.isEdit
    && css`
      margin: 16px 0;
    `}
`;

export const FormItemLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  width: 150px;
`;

export const FieldRemoveAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 24px;
  min-width: 28px;
  height: 38px;
`;
