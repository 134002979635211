//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Table, Progress, Space } from 'antd';
import { isEmpty } from 'lodash';
import history from 'src/utils/history';

import { formatDateTime, convertSecondTime } from 'src/utils/dateTime';

import { COURSE_STATUS, DATE_FORMAT } from 'src/constants/common';
import { PAGE_TITLE, ServiceTitle } from 'src/constants/pageTitle';
import { ServicePath } from 'src/constants/routerConstants';

import {
  MainContainer,
  SidebarContent,
  MainContent,
  TopContent,
} from 'src/components/layouts/ContentLayout';
import { Text } from 'src/components/styles';
import { FilterSidebar } from 'src/components/layouts/Sidebar';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import SortableHeader from 'src/components/tables/SortableHeader';

import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import {
  getCompanyContractList as getCompanyContractListAction,
  getLicenseList as getLicenseListAction,
  getLicenseDetail as getLicenseDetailAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface ILicenseListPageProps extends StateProps, DispatchProps {
  setParamsFilter(params): void;
  getCompanyContractList(): void;
  getLicenseList(params): void;
  getLicenseDetail(params): void;
  companyContracts: {
    data: {
      id: number;
      contractName: string;
    }[],
  };

  licenseList: {
    data: {
      id: number;
      company: {
        id: number;
        name: string;
        contractName: string;
      }[];
      course: {
        id: number;
        name: string;
        courseCategory: {
          name: string;
        }
      }[];
      totalLicensesCount: number;
      remainingLicensesCount: number;
      usedLicensesCount: number;
      progressPercentage: string;
      studyTime: number;
      startDate: string;
      endDate: string;
      actualStatus: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
    errors: string;
  };
}

const LicenseListPage: React.FC<ILicenseListPageProps> = ({
  getCompanyContractList,
  getLicenseList,
  getLicenseDetail,
  licenseList,
  companyContracts,
}) => {

  const { data, meta } = licenseList;

  const [filterParams, setParamsFilter] = useState<object>({});
  const [sortCondition, setSortCondition] = useState<object>({});

  useEffect(() => {
    document.title = PAGE_TITLE(ServiceTitle.LicenseList);
    getCompanyContractList();
    getLicenseList({ page: 1 });
  }, []);

  const tableData = data.map((row: any) => ({
    ...row,
    key: row.id,
    contractName: row.company?.contractName,
    courseName: row.course.courseCategory?.name,
    versionName: row.course?.name,
    startDate: formatDateTime(row.startDate, DATE_FORMAT),
    endDate: formatDateTime(row.endDate, DATE_FORMAT),
    student: (`${row.usedLicensesCount}(残り${row.remainingLicensesCount})`),
    studyTime: convertSecondTime(row.studyTime, 'HH:mm:ss'),
    status: COURSE_STATUS[row.actualStatus],
  }));

  const columns = [
    {
      title:
        <SortableHeader
          type="contractName"
          title="契約名"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'contractName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.contractName}
        </TooltipParagraph>
      ),
      width: 200,
    },
    {
      title:
        <SortableHeader
          type="courseName"
          title="コース名"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'courseName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.courseName}
        </TooltipParagraph>
      ),
      width: 350,
    },
    {
      title:
        <SortableHeader
          type="versionName"
          title="バージョン"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'versionName',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>
          {record.versionName}
        </TooltipParagraph>
      ),
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="usedLicensesCount"
          title="受講者数"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'student',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="progressPercentage"
          title="進捗率"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'progressPercentage',
      render: (line, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.progressPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.progressPercentage} />
        </Space>
      ),
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="studyTime"
          title="学習時間"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'studyTime',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="startDate"
          title="受講開始日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'startDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="endDate"
          title="受講終了日"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'endDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="actualStatus"
          title="ステータス"
          page={meta.pageInfo?.page}
          pageSize={meta.pageInfo?.limit}
          sortCondition={sortCondition}
          filterParams={filterParams}
          actionSort={getLicenseList}
          setAction={setSortCondition}
        />,
      dataIndex: 'status',
      width: 150,
    },
  ];

  const dataFilterFields = [
    {
      type: 'datepicker',
      title: '受講開始日',
    },
    {
      type: 'select',
      title: '契約名',
      fieldParams: 'company',
      options: companyContracts.data.map((company) => ({ id: company.id, name: company.contractName })),
    },
    {
      title: 'コース名',
      type: 'textInput',
      fieldParams: 'course',
    },
  ];

  return (
    <MainContainer>
      <SidebarContent>
        <FilterSidebar
          data={dataFilterFields}
          actionFilter={getLicenseList}
          setParamsFilter={setParamsFilter}
          setSortCondition={setSortCondition}
          pageSize={meta.pageInfo?.limit}
        />
      </SidebarContent>
      <MainContent>
        <TopContent>
          <Text w6 xxxl>ライセンス一覧</Text>
          <Button type="primary" onClick={() => history.push(ServicePath.CreateLicense)}>
            新規ライセンス設定
          </Button>
        </TopContent>
        <Table
          className="table-cursor-pointer"
          pagination={false}
          scroll={{ x: 1400, y: 506 }}
          columns={columns}
          dataSource={tableData}
          onRow={(record) => ({
            onClick: () => {
              getLicenseDetail({
                id: record.id,
                redirect: `/admin-service/license/${record.id}/edit`,
              });
            },
          })}
          locale={{ emptyText: 'データが存在しません。' }}
          loading={licenseList.load}
        />
        {!isEmpty(data) && (
          <PaginationLayout
            pageInfo={meta.pageInfo}
            onChangePage={getLicenseList}
            filterParams={filterParams}
            sortCondition={sortCondition}
          />
        )}
      </MainContent>
    </MainContainer>
  );
};

const mapStateToProps = (state) => {
  const { companyContracts } = state.companyReducer;
  const { licenseList } = state.licenseReducer;
  return {
    companyContracts,
    licenseList,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCompanyContractList: () => dispatch(getCompanyContractListAction()),
  getLicenseList: (params) => dispatch(getLicenseListAction(params)),
  getLicenseDetail: (params) => dispatch(getLicenseDetailAction(params)),
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(LicenseListPage);
