// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { ADMIN_SERVICE, REQUEST } from 'src/redux/constant';

export function getTagList() {
  return {
    type: REQUEST(ADMIN_SERVICE.TAG_ACTION.GET_LIST),
  };
}

export function createTag(payload) {
  return {
    type: REQUEST(ADMIN_SERVICE.TAG_ACTION.CREATE_TAG),
    payload,
  };
}

export function updateTag(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TAG_ACTION.UPDATE_TAG),
    payload: params,
  };
}

export function deleteTag(params) {
  return {
    type: REQUEST(ADMIN_SERVICE.TAG_ACTION.DELETE_TAG),
    payload: params,
  };
}
