// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import axios from 'axios';
import { get, isEmpty, omit } from 'lodash';
import moment from 'moment';

import { ServerTimeStorage, LoginStorage } from 'src/utils/localStorge';
import {toCamelCase, toSnakeCase } from 'src/utils/common';
import history from 'src/utils/history';

import {
  ServicePath,
  CompanyPath,
  StudentPath,
  NotFoundPath,
  ServerErrorPath,
} from 'src/constants/routerConstants';
import { convertServerTime } from 'src/utils/dateTime';

const hadBasicAuth = process.env.REACT_APP_BASE_ENVIRONMENT === 'development'
  || process.env.REACT_APP_BASE_ENVIRONMENT === 'staging';

const SERVER_API_URL = process.env.REACT_APP_BASE_URL;

const DEFAULT_APP_URL = {
  baseURL: SERVER_API_URL,
  headers: {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  },
};

export const statusCode = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export default class RestClient {
  config: any;
  constructor(config = {}) {
    this.config = Object.assign({}, DEFAULT_APP_URL, config);
  }

  get(url, params = {}, config = {}) {
    return this.executeRequest(url, { ...config, params });
  }

  post(url, data, config = {}, paramsType= '') {
    return this.executeRequest(url, { method: 'post', ...config, data, paramsType });
  }

  put(url, data, config = {}, paramsType= '') {
    return this.executeRequest(url, { method: 'put', ...config, data, paramsType });
  }

  delete(url, data = {}, config = {}) {
    return this.executeRequest(url, { method: 'delete', ...config, data });
  }

  patch(url, data = {}, config = {}, paramsType= '') {
    return this.executeRequest(url, { method: 'patch', ...config, data, paramsType });
  }

  async executeRequest(url, config) {
    const isFromDataRequest = config.paramsType === 'formData';
    const userStorage = LoginStorage.getData();
    let finalHeaderConfig = { ...this.config.headers, ...config.headers };
    finalHeaderConfig =  isFromDataRequest
      ? { ...finalHeaderConfig, 'Content-Type': 'multipart/form-data' } : finalHeaderConfig;

    if (hadBasicAuth) {
      const authorization = process.env.REACT_APP_BASIC_AUTH;
      const dAuthorization = !isEmpty(userStorage) ? `Bearer ${userStorage.tokenInfo.accessToken}` : '';
      if (userStorage) {
        finalHeaderConfig = {
          ...finalHeaderConfig,
          'Authorization': authorization,
          'DAuthorization': dAuthorization,
        };
      } else {
        finalHeaderConfig = {
          ...finalHeaderConfig,
          'Authorization': authorization,
        };
      }
    } else {
      const authorization = !isEmpty(userStorage) ? `Bearer ${userStorage.tokenInfo.accessToken}` : '';
      if (userStorage) finalHeaderConfig = { ...finalHeaderConfig, 'Authorization': authorization };
    }

    const finalConfig = {
      ...this.config,
      ...config,
      url,
      data: isFromDataRequest ? config.data : toSnakeCase(config.data),
      params: toSnakeCase(config.params),
      headers: { ...finalHeaderConfig },
    };

    try {
      const response = await axios.request(finalConfig);
      const serverTime = convertServerTime(response.headers['server-time'])
      ServerTimeStorage.setData(serverTime)
      return Promise.resolve(toCamelCase(response.data));
    } catch (responseError) {
      const serverErrors = responseError.response?.data.errors;
      const responseStatus = get(responseError, 'response.status', '');
      const newResponseError = get(
        responseError, 'response.data',
        { code: 1, data: null, message: ['Network error!'] },
      );
      const responseErrors = {
        responseStatus,
        ...newResponseError,
      };
      // 404 error
      if (responseStatus === statusCode.NOT_FOUND) {
        history.replace(NotFoundPath);
        return Promise.reject(responseErrors);
      }
      // 500 error
      if (responseStatus >= statusCode.INTERNAL_SERVER_ERROR) {
        history.replace(ServerErrorPath);
        return Promise.reject(responseErrors);
      }
      // 401 error
      if (
        !!userStorage &&
        (
          responseStatus === statusCode.UNAUTHORIZED ||
          (responseStatus === statusCode.BAD_REQUEST && serverErrors[0].code === 1399)
        )
      ) {
        try {
          // Call API refresh tokens
          const refreshResponse = await axios({
            method: 'POST',
            url: `${SERVER_API_URL}/refresh_tokens`,
            headers: {
              ...omit(finalHeaderConfig, ['DAuthorization']),
            },
            params: {
              'grant_type': 'refresh_token',
              'refresh_token': userStorage.tokenInfo.refreshToken,
            },
          });
          const refreshResponseData = toCamelCase(refreshResponse.data);
          // Update localStorage
          const outUpdateAuthData = LoginStorage.getData();
          LoginStorage.setData({
            ...outUpdateAuthData,
            tokenInfo: refreshResponseData.data.tokenInfo,
          });
          // Update request headers
          finalConfig.headers.DAuthorization = `Bearer ${refreshResponseData.data.tokenInfo.accessToken}`;
          if(!hadBasicAuth){
            finalConfig.headers.Authorization = `Bearer ${refreshResponseData.data.tokenInfo.accessToken}`;
          }
          // Call API again
          try {
            const reResponseData = await axios.request(finalConfig);
            const serverTime = convertServerTime(reResponseData.headers['server-time'])
            ServerTimeStorage.setData(serverTime)
            return Promise.resolve(toCamelCase(reResponseData.data));
          } catch (reResponseError) {
            const reRequestStatus = get(reResponseError, 'response.status', '');
            const reResponseConfig = get(reResponseError, 'response.config', '');
            const newReResponseError = get(
              reResponseError, 'response.data',
              { code: 1, data: null, message: ['Network error!'] },
            );
            const reResponseErrors = {
              reRequestStatus,
              ...newReResponseError,
            };

            if (
              reRequestStatus === statusCode.UNAUTHORIZED ||
              newReResponseError.errors[0].code === 1399 ||
              (responseStatus === statusCode.BAD_REQUEST && serverErrors[0].code === 1399)
            ) {
              localStorage.clear();
              if (reResponseConfig.url.indexOf('/admin_manage/') !== -1
                || window.location.href.includes('/admin-service/')
              ) {
                history.push(ServicePath.Login);
              } else if (
                reResponseConfig.url.indexOf('/company_manage/') !== -1
                || window.location.href.includes('/admin/')
              ) {
                history.push(CompanyPath.Login);
              } else {
                history.push(StudentPath.Login);
              }
            }

            if (reRequestStatus === statusCode.NOT_FOUND) {
              history.replace(NotFoundPath);
            }

            if (reRequestStatus >= statusCode.INTERNAL_SERVER_ERROR) {
              history.replace(ServerErrorPath);
            }

            return Promise.reject(reResponseErrors);
          }
        } catch (refreshResponseError) {
          localStorage.clear();
          if (window.location.href.includes('/admin-service/')) {
            history.push(ServicePath.Login);
          } else if (window.location.href.includes('/admin/')) {
            history.push(CompanyPath.Login);
          } else {
            history.push(StudentPath.Login);
          }
          return Promise.reject(refreshResponseError);
        }
      } else {
        return Promise.reject(responseErrors);
      }
    }
  }
}
