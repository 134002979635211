// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import RestClient from './restClient';

export default class NotificationServices {
  restClient: RestClient;
  constructor(restClient = new RestClient()) {
    this.restClient = restClient;
  }
  getNotificationUnread() {
    return this.restClient.get('/notifications/unread');
  }

  getNotificationList(params) {
    return this.restClient.get('/notifications', params);
  }

  markNotificationsAsRead() {
    return this.restClient.patch('/notifications/mark_all_as_read');
  }
}
