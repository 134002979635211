// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React from 'react';
import { connect } from 'react-redux';
import { Form, Input } from 'antd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import {
  createCourseGroup as createCourseGroupAction,
  createGroupChapter as createGroupChapterAction,
} from 'src/redux/actions';

import * as Style from './styles';

interface IAddGroupChapterModalProps extends DispatchProps {
  createCourseGroup(params): void;
  createGroupChapter(params): void;
  setRecentlyGroupIndexCreated(params): void;
  modalData: {
    type: string;
    title: string;
    courseId: string;
    groupId?: number;
    groupIndex?: number;
    currentNumberGroups?: number;
  };
}

const AddGroupChapterModal: React.FC<IAddGroupChapterModalProps> = ({
  createCourseGroup,
  createGroupChapter,
  setRecentlyGroupIndexCreated,
  modalData,
}) => {
  function handleSubmitForm(values) {
    if (modalData.type === 'group') {
      createCourseGroup({
        group: {
          name: values.name.trim(),
          courseId: modalData.courseId,
        },
        requestData: { currentNumberGroups: modalData.currentNumberGroups },
        requestAction: { setRecentlyGroupIndexCreated },
      });
    } else {
      createGroupChapter({
        requestData: {
          chapter: {
            name: values.name.trim(),
            groupId: modalData.groupId,
          },
        },
        groupIndex: modalData.groupIndex,
      });
    }
  }

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      name={modalData.type === 'group' ? 'createCourseGroupForm' : 'createGroupChapterForm'}
      onFinish={(values) => handleSubmitForm(values)}
    >
      <Style.GroupChapterFieldContent>
        <Form.Item
          label={modalData.type === 'group' ? 'グループ名' : 'チャプター名'}
          name="name"
          labelAlign="left"
          colon={false}
          rules={[
            { required: true, message: `${modalData.type === 'group' ? 'グループ名' : 'チャプター名'}は必須項目です。` },
            { whitespace: true, message: `${modalData.type === 'group' ? 'グループ名' : 'チャプター名'}は必須項目です。` },
            { max: 50, message: `${modalData.type === 'group' ? 'グループ名' : 'チャプター名'}が長すぎます。（最大は50桁です）` },
          ]}
        >
          <Input />
        </Form.Item>
      </Style.GroupChapterFieldContent>
      <ModalFooter
        okText="追加する"
        cancelText="キャンセル"
        isConfirm
        isForm
      />
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createCourseGroup: (params) => dispatch(createCourseGroupAction(params)),
  createGroupChapter: (params) => dispatch(createGroupChapterAction(params)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(AddGroupChapterModal);
