// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import isEmpty from "lodash/isEmpty";

import { showAlertNotice } from 'src/utils/alert';
import {
  ADMIN_SERVICE,
  MODAL_ACTION,
  SUCCESS,
  FAILURE,
  REQUEST,
  LOADING_ACTION,
} from 'src/redux/constant';

import {
  profileServices,
} from 'src/services';

function* getProfileDetailSaga(action) {
  try {
    const result = yield profileServices.getProfileDetail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL), errors: e.errors });
  }
}

function* updateUserRoleSaga(action) {
  try {
    yield profileServices.updateUserRole(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE),
      payload: {
        data: action.payload.usersRole,
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE), errors: e.errors });
  }
}

function* updateUserEmailSaga(action) {
  try {
    const result = yield profileServices.updateUserEmail(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL),
      payload: {
        data: result.data.user,
      },
    });
    yield put({
      type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL),
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    if (!isEmpty(e?.errors) && e?.errors[0].code === 1326) {
      yield put({
        type: REQUEST(MODAL_ACTION.CLOSE),
      });
      yield showAlertNotice({
        type: "error",
        message: e.errors[0].message,
      });
      yield put({
        type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL),
        errors: []
      });
    } else {
      yield put({
        type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL),
        errors: e.errors,
      });
    }
  }
  finally {
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
  }
}

function* updateUserInfoSaga(action) {
  try {
    yield profileServices.updateUserInfo(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO),
      payload: {
        data: action.payload?.requestData?.user,
      },
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO), errors: e.errors });
  } 
  finally {
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
  }
}

function* getJoinedCoursesSaga(action) {
  try {
    const result = yield profileServices.getJoinedCourses(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_JOINED_COURSES),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_JOINED_COURSES), errors: e.errors });
  }
}

function* updateCourseDurationSaga(action) {
  try {
    const result = yield profileServices.updateCourseDuration(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSES_DURATION),
      payload: {
        data: {
          id: action.payload.id,
          startDate: action.payload.requestData.startDate,
          endDate: action.payload.requestData.endDate,
          remainingDays: result.data.course.remainingDays,
          actualStatus: result.data.actualStatus,
        },
      },
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({ type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSES_DURATION), errors: e.errors });
  }
}

function* getListTestStudent(action) {
  try {
    const result = yield profileServices.getListTestStudent(action.payload)
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT),
      payload: result
    })
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT),
      errors: e.errors
    })
  }
}

function* resetHistoryTest(action) {
  try {
    const result = yield profileServices.resetHistoryTest(action.payload)
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST),
      payload: result
    })

    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })

    yield put({
      type: REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT),
      payload: {
        user_id: action.payload.requestData.user_id,
        requestData: {
          page: 1
        }
      }
    })
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST),
      errors: e.errors
    })
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE)
    })
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
  }
}

function* getListCourseCompany(action) {
  try {
    const result = yield profileServices.getListCourseCompany(action.payload)
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.GET_COURSE_COMPANY),
      payload: result
    })
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.GET_COURSE_COMPANY),
      errors: e.errors
    })
  }
}

function* updateCourseManager(action) {
  try {
    yield profileServices.updateCourseManager(action.payload)
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER),
      payload: action.payload.courseId
    })
    yield showAlertNotice({ type: 'success', message: '正常に変更されました。'});
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
  } catch (e) {
    yield put({
      type: FAILURE(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER),
      errors: e.errors
    })
    yield showAlertNotice({ type: 'error', message: e.errors[0].message });
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD)
    })
  }
}

function* unlockUserSaga(action) {
  try {
    yield profileServices.unlockUser(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.PROFILE_ACTION.UNLOCK_USER)
    });
    yield showAlertNotice({ type: "success", message: "解除に成功しました。" });
  } catch (e) {
    yield showAlertNotice({ type: "error", message: "解除に失敗しました。" });
  } finally {
    yield put({
      type: REQUEST(LOADING_ACTION.FINISHED_LOAD),
    });
    yield put({
      type: REQUEST(MODAL_ACTION.CLOSE),
    });
  }
}

export default function* profileSaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_PROFILE_DETAIL), getProfileDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_ROLE), updateUserRoleSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_INFO), updateUserInfoSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_USER_EMAIL), updateUserEmailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_JOINED_COURSES), getJoinedCoursesSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSES_DURATION), updateCourseDurationSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_TEST_STUDENT), getListTestStudent),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.RESET_TEST), resetHistoryTest),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.GET_COURSE_COMPANY), getListCourseCompany),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UPDATE_COURSE_MANAGER), updateCourseManager),
    takeLatest(REQUEST(ADMIN_SERVICE.PROFILE_ACTION.UNLOCK_USER), unlockUserSaga),
  ]);
}
