// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import {
  put,
  takeLatest,
  all,
} from 'redux-saga/effects';
import { showAlertNotice } from 'src/utils/alert';
import history from 'src/utils/history';

import {
  ADMIN_SERVICE,
  LOADING_ACTION,
  REQUEST,
  SUCCESS,
  FAILURE,
} from 'src/redux/constant';
import { ServicePath } from 'src/constants/routerConstants';

import { surveyServices } from 'src/services';

function* getSurveyListSaga(action) {
  try {
    const result = yield surveyServices.getSurveyLists(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.GET_LIST),
      payload: result,
    });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.SURVEY_ACTION.GET_LIST), errors: e.errors });
  }
}

function* getSurveyDetailSaga(action) {
  try {
    const { redirect, ...payload } = action.payload;
    const result = yield surveyServices.getSurveyDetail(payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.GET_DETAIL),
      payload: result,
    });
    if (redirect) history.push(redirect);
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.SURVEY_ACTION.GET_DETAIL), errors: e.errors });
    yield put({ type: REQUEST(LOADING_ACTION.FINISHED_LOAD) });
  }
}

function* createSurveySaga(action) {
  try {
    const result = yield surveyServices.createSurvey(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.CREATE_SURVEY),
      payload: result,
    });
    yield history.push(ServicePath.SurveyList);
    yield showAlertNotice({ type: 'success', message: '正常に作成されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.SURVEY_ACTION.CREATE_SURVEY), errors: e.errors });
  }
}

function* updateSurveySaga(action) {
  try {
    const result = yield surveyServices.updateSurvey(action.payload);
    yield put({
      type: SUCCESS(ADMIN_SERVICE.SURVEY_ACTION.UPDATE_SURVEY),
      payload: result,
    });
    yield history.push(ServicePath.SurveyList);
    yield showAlertNotice({ type: 'success', message: '正常に更新されました。' });
  } catch (e) {
    yield put({ type: FAILURE(ADMIN_SERVICE.SURVEY_ACTION.UPDATE_SURVEY), errors: e.errors });
  }
}

export default function* surveySaga() {
  yield all([
    takeLatest(REQUEST(ADMIN_SERVICE.SURVEY_ACTION.GET_LIST), getSurveyListSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.SURVEY_ACTION.GET_DETAIL), getSurveyDetailSaga),
    takeLatest(REQUEST(ADMIN_SERVICE.SURVEY_ACTION.CREATE_SURVEY), createSurveySaga),
    takeLatest(REQUEST(ADMIN_SERVICE.SURVEY_ACTION.UPDATE_SURVEY), updateSurveySaga),
  ]);
}
