// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { ReactNode } from 'react';

import { Text } from 'src/components/styles';

import * as Style from './styles';

interface IMainWrapperProps {
  children: ReactNode;
  isStudent?: boolean;
  title: ReactNode | string;
  style?: object;
  styleHeader?: object;
}

const MainWrapper: React.FC<IMainWrapperProps> = ({
  children,
  isStudent,
  title,
  style,
  styleHeader
}) => (
  <Style.MainWrapper style={style}>
    <Style.HeaderWrapper isStudent={isStudent} style={styleHeader} className="header-wrapper">
      {typeof title === 'string'
        ? <Text white lg w6>{title}</Text>
        : title
      }
    </Style.HeaderWrapper>
    {children}
  </Style.MainWrapper>
);

export default MainWrapper;
