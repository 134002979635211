// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';

import ModalFooter from 'src/components/Modal/components/ModalFooter';

import { COMPANY_STATUS, COMPANY_STATUS_OPTION } from 'src/constants/common';
import {
  selectCompanyStatus as selectCompanyStatusAction,
} from 'src/redux/actions';

interface ISelectCompanyStatusModalProps extends DispatchProps {
  selectCompanyStatus(params): void;
  modalData: {
    companyId: number;
    companyStatus: string;
  };
}
const SelectCompanyStatusModal: React.FC<ISelectCompanyStatusModalProps> = ({
  modalData,
  selectCompanyStatus,
}) => {
  const [companyStatusSelected, setCompanyStatusSelected] = useState<any>([modalData.companyStatus]);

  function handleSubmitModal() {
    return selectCompanyStatus({
      requestData: {
        company: {
          status: companyStatusSelected[0],
        },
      },
      id: modalData.companyId,
    });
  }

  const columns = [
    {
      dataIndex: 'title',
    },
  ];

  const tableData =  COMPANY_STATUS_OPTION.map((row: any) => ({
    key: row.status,
    status: row.status,
    title: row.title,
  }));

  return (
    <>
      <Table
        rowSelection={{
          type: 'radio',
          selectedRowKeys: [...companyStatusSelected],
          onChange: (key) => {
            setCompanyStatusSelected([...key]);
          },
        }}
        columns={columns}
        dataSource={tableData}
        showHeader={false}
        pagination={false}
      />
      <ModalFooter
        onSubmit={() => handleSubmitModal()}
        okText="選択する"
        cancelText="キャンセル"
        isConfirm
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  selectCompanyStatus: (params) => dispatch(selectCompanyStatusAction(params)),
});

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(SelectCompanyStatusModal);
