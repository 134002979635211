// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';
import { Text } from 'src/components/styles';

export const CourseOverviewContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0 12px;
  min-height: 200px;

  @media (max-width: 428px) {
    flex-direction: column;
    min-height: 92px;
    padding: 16px;
    margin: -16px -16px 0px -16px;
    background-color: ${(props) => props.theme.colors.primary};;
  }
`;

export const CourseOverviewContent = styled.div`
  display: flex;
  margin: 4px 0;

  & > p:first-child {
    margin-top: 2px;
    width: 100px;
    color: #EEEEEE;
  }
`;

export const LoadingCourseTitle = styled.div`
  margin-right: 100px;
  width: 100%;
`;

export const LogoCourse = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  object-fit: scale-down;
`;

export const CourseNameBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const CourseOviewsBlock = styled.div`
  min-width: 400px;

  @media (max-width: 428px) {
    display: none;
  }
`;

export const CourseNameContainer = styled.div`
  margin-right: 100px;

  @media (max-width: 428px) {
    margin-right: 0;
  }
`;

export const CourseName = styled(Text)`

  @media (max-width: 428px) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    font-weight: 700;
  }
`;

export const CourseDesc = styled(Text)`
  margin-top: 8px;

  @media (max-width: 428px) {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 20px;

    ${(props) => props.isShowMore
    && css`
      -webkit-line-clamp: unset;
      -webkit-box-orient: unset;
      overflow: unset;
      text-overflow: unset;
    `}
  }
`;
