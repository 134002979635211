// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { isEmpty } from "lodash/lang";
import { Button, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { TooltipParagraph } from "src/components/layouts/TooltipParagraph";
import SortableHeader from "src/components/tables/SortableHeader";
import {
  showModal as showModalAction,
  closeModal as closeModalAction,
  resetMyTestHistory as resetMyTestHistoryAction,
} from "src/redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import { DATE_FORMAT, TEST_TYPE } from "src/constants/common";
import { PaginationLayout } from "src/components/layouts/Pagination";
import * as Style from "./styles";
import ConfirmResetModal from "src/components/Modal/components/ConfirmResetModal";
import history from 'src/utils/history';

interface ITestListProps extends DispatchProps {
  showModal(params): void;
  getMyTestList(params): void;
  closeModal(): void;
  resetMyTestHistory(params): void;
  listTestsStudent: {
    data: {
      id: number;
      name: string;
      test: {
        id: number,
        name: string,
        testType: string
      }
      category: {
        id: number;
        name: string;
      };
      course: {
        id: number;
        name: string
      }
      isDoingUnlimitedTest: number;
      usedAttemptsCount: number;
      testScore: number;
      testScorePercentage: number;
      lastTestingDate: string;
      isWaitingTest: boolean;
      unit: {
        id: number,
        name: string,
      };
      unitVersionAble: {
        id: number,
        type: string
      },
      manageTestStatus: string
    }[];
    load: boolean;
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
  },
  isResetSucess: boolean,
  activeTabKey: string
}

const TestListStudent: React.FC<ITestListProps> =
  ({
    showModal,
    listTestsStudent,
    getMyTestList,
    isResetSucess,
    resetMyTestHistory,
    closeModal,
    activeTabKey
  }) => {
    const sortConditionUrl = history.location.state?.sortCondition;
    const pageUrl = history.location.state?.page;
    const [sortCondition, setSortCondition] = useState<object>({});
    const [filterParams, setFilterParams] = useState<object>({});

    useEffect(() => {
      if (sortConditionUrl) {
        setSortCondition(sortConditionUrl)
      }
    }, []);

    useEffect(() => {
      history.replace({
        state: {
          activeTabKey,
          sortCondition,
          page: listTestsStudent?.meta.pageInfo,
          isSort: true,
        }
      })
    }, [activeTabKey, sortCondition, listTestsStudent?.meta.pageInfo])

    useEffect(() => {
      setSortCondition(sortConditionUrl)
    }, [activeTabKey])

    useEffect(() => {
      if (activeTabKey === '3') {
        getMyTestList({ page: 1,...pageUrl, items: pageUrl?.limit, sort: sortConditionUrl })
      }
      return ()=>{
        closeModal()
      }
    }, [activeTabKey])

    useEffect(() => {
      isResetSucess && resetSuccess()
    }, [isResetSucess])

    function resetSuccess() {
      const modalProps = {
        isOk: true,
        isDone: true,
        modalData: {
          title: "テスト受講履歴のリセット",
          okeText: "完了",
        },
        modalAction: {
          confirm: () => {
            closeModal()
          }
        }
      };
      return showModal(<ConfirmResetModal {...modalProps} />);
    }

    function handleBtnResetTest(
      course_id,
      unit_version_able_id,
      unit_version_able_type,
      isDoingUnlimitedTest
    ) {
      const modalProps = {
        isClose: true,
        isOk: true,
        isDoingUnlimitedTest,
        modalData: {
          title: "テスト受講履歴のリセット",
          okeText: "リセット",
          closeText: "キャンセル",
        },

        modalAction: {
          confirm: () => {
            resetMyTestHistory({
              requestData: {
                course_id,
                unit_version_able_id,
                unit_version_able_type
              }
            });
          },
        },
      };

      return showModal(<ConfirmResetModal {...modalProps} />);
    } const columns = [
      {
        title: (
          <SortableHeader
            type="unit_name"
            title="レッスン名 "
            page={listTestsStudent.meta.pageInfo?.page}
            pageSize={listTestsStudent.meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={filterParams}
            actionSort={getMyTestList}
            setAction={setSortCondition}
          />
        ),
        dataIndex: "name",
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.name}
          </TooltipParagraph>
        ),
        width: "28%",
      },
      {
        title: (
          <SortableHeader
            type="testType"
            title="テスト種別"
            page={listTestsStudent.meta.pageInfo?.page}
            pageSize={listTestsStudent.meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={filterParams}
            actionSort={getMyTestList}
            setAction={setSortCondition}
          />
        ),
        dataIndex: "testType",
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {TEST_TYPE[record.testType]}
          </TooltipParagraph>
        ),
        width: "12%",
      }, {
        title: (
          <SortableHeader
            type="categoryName"
            title="講座タイプ"
            page={listTestsStudent.meta.pageInfo?.page}
            pageSize={listTestsStudent.meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={filterParams}
            actionSort={getMyTestList}
            setAction={setSortCondition}
          />
        ),
        dataIndex: "categoryName",
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.category || "-"}
          </TooltipParagraph>
        ),
        width: "12%",
      },
      {
        title: (
          <SortableHeader
            type="usedAttemptsCount"
            title="受講回数"
            page={listTestsStudent.meta.pageInfo?.page}
            pageSize={listTestsStudent.meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={filterParams}
            actionSort={getMyTestList}
            setAction={setSortCondition}
          />
        ),
        dataIndex: "usedAttemptsCount",
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {record.usedAttemptsCount > 0 ? record.usedAttemptsCount : "-"}
          </TooltipParagraph>
        ),
        width: "12%",
      },

      {
        title: (
          <SortableHeader
            type="testScore"
            title="得点（得点率)"
            page={listTestsStudent.meta.pageInfo?.page}
            pageSize={listTestsStudent.meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={filterParams}
            actionSort={getMyTestList}
            setAction={setSortCondition}
          />
        ),
        dataIndex: "testScore",
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {
              record.isWaitingTest ? "採点待ち" : `${record.score ?? 0}点(${record.testScorePercentage ?? 0}%)`
            }
          </TooltipParagraph>
        ),
        width: "12%",
      },
      {
        title: (
          <SortableHeader
            type="lastTestingDate"
            title="最終受講日"
            page={listTestsStudent.meta.pageInfo?.page}
            pageSize={listTestsStudent.meta.pageInfo?.limit}
            sortCondition={sortCondition}
            filterParams={filterParams}
            actionSort={getMyTestList}
            setAction={setSortCondition}
          />
        ),
        dataIndex: "lastTestingDate",
        render: (_, record) => (
          <TooltipParagraph isPreWrap rows={2}>
            {
              !!record.lastTestingDate && moment(record.lastTestingDate).format(DATE_FORMAT)
            }
          </TooltipParagraph>
        ),
        width: "12%",
      },
      {
        render: (_, record) => {
          return (
            (
              <Tooltip
                placement="right"
                title={record.disabled ? "管理対象外のテストです" : null}
              >
                <Button ghost type="primary" onClick={() => {
                  handleBtnResetTest(
                    record.courseId, record.unitVersionAbleId, record.unitVersionAbleType, record.isDoingUnlimitedTest)
                }}
                disabled={record.disabled}
                >
                  リセット
                </Button>
              </Tooltip>
            )
          );
        },
      },
    ];

    const dataTable = listTestsStudent?.data?.map((item, index) => ({
      ...item,
      usedAttemptsCount: item.usedAttemptsCount,
      key: item.unitVersionAble.id,
      id: item.id,
      courseId: item.course?.id,
      name: item.unit.name,
      testType: item.test.testType,
      category: item.category?.name,
      score: item.testScore,
      testScorePercentage: item.testScorePercentage,
      lastTestingDate: item.lastTestingDate,
      unitVersionAbleType: item.unitVersionAble.type,
      unitVersionAbleId: item.unitVersionAble.id,
      disabled: item.manageTestStatus === "disabled"
    }));

  return (
    <div>
      <Style.ListTestStudentContainer>
        <Table
          columns={columns}
          dataSource={dataTable}
          loading={listTestsStudent.load}
          pagination={false}
          locale={{ emptyText: "データが存在しません。" }}
          scroll={{ y: 506 }}
        />
      </Style.ListTestStudentContainer>
      {!isEmpty(listTestsStudent.data) && (
        <PaginationLayout
          pageInfo={listTestsStudent.meta.pageInfo}
          onChangePage={getMyTestList}
          sortCondition={sortCondition}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isResetSucess } = state.myProfileReducer;
  return {
    isResetSucess
  };
};

const mapDispatchToProps = (dispatch) => ({
  showModal: (params) => dispatch(showModalAction(params)),
  resetMyTestHistory: (params) => dispatch(resetMyTestHistoryAction(params)),
  closeModal: () => dispatch(closeModalAction())
});

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(TestListStudent);
