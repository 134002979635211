// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from "styled-components";

export const CourseContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  padding: 20px;
  overflow: scroll;
`;

export const CourseDroppableBackground = styled.div`
  padding: 8px;
  background: ${(props) => (props.isDraggingOver ? '#fff1b8' : '#f0f0f0')};
`;

export const GroupContainer = styled.div`
  user-select: none;
  width: 100%;
  padding: 6px;
  margin-bottom: ${(props) => (props.isLastChild ? 0 : "8px")};
  background: #d9d9d9;
  ${(props) =>
    props.isDragging &&
    css`
      background: #fff8e7;
      border: 2px solid ${props.theme.colors.primary};
    `}
`;
