// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import styled, { css } from 'styled-components';

export const GroupChapterFieldContent = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  padding: 20px;

  overflow: scroll;
`;

export const ChapterContents = styled.div`
  padding: 8px;
`;

export const ItemTypeIcon = styled.div`
  display: flex;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const GroupDroppableBackground = styled.div`
  padding: 8px;
  background: ${(props) => props.isDraggingOver ? '#fff1b8' : '#f0f0f0'};
`;

export const GroupContainer = styled.div`
  user-select: none;
  width: 100%;

  padding: 6px;
  margin-bottom: ${(props) => props.isLastChild ? 0 : '8px'};
  background: #8c8c8c;

  ${(props) => props.isDragging
    && css`
      background: #fff8e7;
      border: 2px solid ${props.theme.colors.primary};
    `}
`;

export const ChapterDroppableBackground = styled.div`
  padding: 8px;
  margin: 10px 20px;
  background: ${(props) => props.isDraggingOver ? '#fff1b8' : '#f0f0f0'};
`;

export const ChapterContainer = styled.div`
  user-select: none;
  margin-bottom: ${(props) => props.isLastChild ? 0 : '8px'};
  background: #bfbfbf;

  ${(props) => props.isDragging
    && css`
      background: #fff8e7;
      border: 2px solid ${props.theme.colors.primary};
    `}
`;

export const UnitDroppableBackground = styled.div`
  padding: 8px;
  margin: 8px;
  background: ${(props) => props.isDraggingOver ? '#fff1b8' : '#f0f0f0'};
`;

export const UnitContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 0 16px;
  user-select: none;
  margin-bottom: ${(props) => props.isLastChild ? 0 : '8px'};
  background: #d9d9d9;

  ${(props) => props.isDragging
    && css`
      background: #fff8e7;
      border: 2px solid ${props.theme.colors.primary};
    `}
`;
