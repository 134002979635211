// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { ReactNode } from 'react';

import * as Style from './styles';

interface ISidebarContentProps {
  children: ReactNode;
  isCollapse?: boolean;
  isCourseView?: boolean;
  isFullWidth?: boolean;
}

const SidebarContent: React.FC<ISidebarContentProps> = ({
  children,
  isCollapse,
  isCourseView,
  isFullWidth
}) => (
  <Style.SidebarContent isCollapse={isCollapse} isCourseView={isCourseView} isFullWidth={isFullWidth}>
    {children}
  </Style.SidebarContent>
);

export default SidebarContent;
