// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import { AUTH_ACTION, REQUEST } from '../constant';

export function login(params) {
  return {
    type: REQUEST(AUTH_ACTION.LOGIN),
    payload: params,
  };
}

export function logout(params) {
  return {
    type: REQUEST(AUTH_ACTION.LOGOUT),
    payload: params,
  };
}

export function forgotPassword(params) {
  return {
    type: REQUEST(AUTH_ACTION.FORGOT_PASSWORD),
    payload: params,
  };
}

export function resetPassword(params) {
  return {
    type: REQUEST(AUTH_ACTION.RESET_PASSWORD),
    payload: params,
  };
}

export function getResetPasswordStatus(params) {
  return {
    type: REQUEST(AUTH_ACTION.GET_RESET_PASSWORD_STATUS),
    payload: params,
  };
}

export function clearFlagGetResetError() {
  return {
    type: REQUEST(AUTH_ACTION.CLEAR_FLAG_GET_RESET_ERROR),
  };
}

export function clearError() {
  return {
    type: REQUEST(AUTH_ACTION.CLEAR_ERROR),
  };
}
