//  # gkc_hash_code :  01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect } from 'react';
import { Space, Button } from 'antd';

import { randomLoadingWidth } from 'src/utils/common';

import { Text, SkeletonLoading } from 'src/components/styles';

import ChangeRoleModal from '../ChangeRoleModal';

import { ACCOUNT_ROLES } from 'src/constants/common';
import { ACCOUNT_PROFILE_FIELDS } from '../../constants';

import * as Style from './styles';
import history from 'src/utils/history';

interface IAccountProfileProps {
  clearCompanyUpdateRoleErrors(): void;
  showModal(params): void;
  role: string;
  profileDetail: {
    data: {
      id: number;
      department: string;
      email: string;
      jituName: string;
      status: string;
      roles: string[];
    };
    load: boolean;
  };
  activeTabKey: string
}

const AccountProfile: React.FC<IAccountProfileProps> = ({
  clearCompanyUpdateRoleErrors,
  showModal,
  role,
  profileDetail,
  activeTabKey
}) => {

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey
      }
    })
  }, [activeTabKey])

  function showChangeRoleModal() {
    if (profileDetail.data.roles?.includes('admin_service')) return null;
    clearCompanyUpdateRoleErrors();
    const modalProps = {
      width: 560,
      modalData: {
        title: 'アカウント種別変更',
        profileDetail: profileDetail.data,
        editorRole: role,
      },
    };
    return showModal(<ChangeRoleModal {...modalProps} />);
  }

  function renderProfileContent() {
    return ACCOUNT_PROFILE_FIELDS.map((field, fieldIndex) => (
      <Style.ProfileContent key={`overview-profile-${fieldIndex}`}>
        <Space>
          <Text lg w6 style={{ minWidth: 150 }}>
            {field.title}
          </Text>
          {profileDetail.load
            ? (
              <SkeletonLoading
                active
                paragraph={false}
                title={{ width: randomLoadingWidth(250, 350) }}
                height={16}
              />
            )
            : (
              <Text lg>
                {fieldIndex === 1
                  ? (profileDetail.data[field.fieldName] || []).map((roleType) => ACCOUNT_ROLES[roleType]).toString()
                  : profileDetail.data[field.fieldName]
                }
              </Text>
            )
          }
        </Space>
        {fieldIndex === 1 && (
          <Button
            type="primary"
            onClick={() => {
              showChangeRoleModal();
            }}
            disabled={profileDetail.load
              || profileDetail.data.status === 'banned'
              || profileDetail.data.roles?.includes('admin_service')
            }
          >
            変更する
          </Button>
        )}
      </Style.ProfileContent>
    ));
  }

  return (
    <Style.ProfileWrapper>
      <Style.HeaderWrapper>
        <Text white lg w6>アカウント情報</Text>
      </Style.HeaderWrapper>
      {renderProfileContent()}
    </Style.ProfileWrapper>
  );
};

export default AccountProfile;
