// gkc_hash_code : 01E7J1BV41XHVJMHH8Y4PHXG7H
import React, { useEffect, useState } from 'react';
import { Table, Progress, Space, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { convertSecondTime } from 'src/utils/dateTime';

import { Text } from 'src/components/styles';
import SortableHeader from 'src/components/tables/SortableHeader';
import { PaginationLayout } from 'src/components/layouts/Pagination';
import { TooltipParagraph } from 'src/components/layouts/TooltipParagraph';

import { DATE_FORMAT } from 'src/constants/common';

import * as Style from './style';
import history from 'src/utils/history';

interface IJoinedCoursesProps {
  getCompanyJoinedCourses(params): void;
  getCompanyCourseDetail(params): void;
  userId: string;
  joinedCourses: {
    data: {
      id: number,
      name: string,
      courseCategory: {
        name: string;
      };
      totalLearningDays: number;
      totalLearningTime: number;
      studentProgressPercentage: number;
      testInfo: {
        usersRank: number;
        totalUsers: number;
        highestTestScore: number;
      };
      remainingDays: number;
      startDate: string;
      endDate: string;
      lastLearningDate: string;
      manageCourseStatus: string;
    }[];
    meta: {
      pageInfo: {
        count: number;
        limit: number;
        items: number;
        page: number;
      };
    };
    load: boolean;
  };
  activeTabKey: string;
}

const JoinedCourses: React.FC<IJoinedCoursesProps> = ({
  getCompanyJoinedCourses,
  getCompanyCourseDetail,
  userId,
  joinedCourses,
  activeTabKey
}) => {
  const sortConditionUrl = history.location.state?.sortCondition;
  const pageUrl = history.location.state?.page;
  const [sortCondition, setSortCondition] = useState<any>({});

  useEffect(() => {
    getCompanyJoinedCourses({ id: userId, requestData: { ...pageUrl, items: pageUrl?.limit, sort: sortConditionUrl } });
    if (sortConditionUrl) {
      setSortCondition(sortConditionUrl)
    }
  }, []);

  useEffect(() => {
    history.replace({
      state: {
        activeTabKey,
        sortCondition,
        page: joinedCourses.meta.pageInfo,
        isSort: true,
      }
    })
  }, [activeTabKey, sortCondition, joinedCourses.meta.pageInfo])

  useEffect(() => {
    setSortCondition(sortConditionUrl)
  }, [activeTabKey])

  const tableData = joinedCourses.data.map((row,index) => ({
    ...row,
    key: row.id,
    name: row.courseCategory?.name,
    totalLearningDays: `${row.totalLearningDays}日`,
    totalLearningTime: convertSecondTime(row.totalLearningTime, 'H時間m分'),
    studentProgressPercentage: row.studentProgressPercentage,
    testInfo: row.testInfo,
    startDate: moment(row.startDate).format(DATE_FORMAT),
    endDate: `${moment(row.endDate).format(DATE_FORMAT)} [残り${row.remainingDays}日]`,
    disable: row.manageCourseStatus !== "enabled",
    lastLearningDate: row.lastLearningDate && moment(row.lastLearningDate).format(DATE_FORMAT),
  }));

  function CustomRow({index, ...props}) {
    if(index !== undefined && index !== null  && tableData[index].disable){
      return (
        <Tooltip title="管理対象外のコースです" placement="bottom" arrowPointAtCenter={false}>
          <tr {...props}/>
        </Tooltip>
      );
    }
    return <tr {...props}/>
  }

  const columns = [
    {
      title:
        <SortableHeader
          type="name"
          title="コース"
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'name',
      render: (_, record) => (
        <TooltipParagraph isPreWrap rows={2}>{record.name}</TooltipParagraph>
      ),
      width: 400,
    },
    {
      title:
        <SortableHeader
          type="totalLearningDays"
          title="学習日数"
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningDays',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="totalLearningTime"
          title="学習時間"
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'totalLearningTime',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="studentProgressPercentage"
          title="進歩率"
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'studentProgressPercentage',
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text style={{ width: 35 }}>{record.studentProgressPercentage}%</Text>
          <Progress showInfo={false} strokeWidth={16} percent={record.studentProgressPercentage} />
        </Space>
      ),
      width: 200,
    },
    {
      title:
        <SortableHeader
          type="highestTestScore"
          title={
            <div>
              <Text white style={{ marginLeft: '2px' }}>テスト正答率</Text>
              <Text white style={{ marginLeft: '-10px' }}>（全テスト平均）</Text>
            </div>
          }
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'testInfo' as any,
      render: (_, record) => (
        <Space align="center" size={0}>
          <Text>
            {(isEmpty(record.testInfo) || record.testInfo.highestTestScore === null)
              ? 'ー'
              : `${record.testInfo.highestTestScore}%[${record.testInfo.usersRank}位/${record.testInfo.totalUsers}人]`
            }
          </Text>
        </Space>
      ),
      width: 200,
    },
    {
      title: (
        <SortableHeader
          type="lastLearningDate"
          title="最終学習日"
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />
      ),
      dataIndex: "lastLearningDate",
      render: (_, record) => (
        <Text>{record.lastLearningDate || ""}</Text>
      ),
      width: 130,
    },
    {
      title:
        <SortableHeader
          type="startDate"
          title="開始日"
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'startDate',
      width: 150,
    },
    {
      title:
        <SortableHeader
          type="endDate"
          title="終了日"
          page={joinedCourses.meta.pageInfo?.page}
          pageSize={joinedCourses.meta.pageInfo?.limit}
          sortCondition={sortCondition}
          dataParam={{
            id: userId,
            requestData: {
              page: joinedCourses.meta.pageInfo?.page,
            },
          }}
          actionSort={getCompanyJoinedCourses}
          setAction={setSortCondition}
        />,
      dataIndex: 'endDate',
      width: 250,
    },
  ];

  return (
    <>
      <div>
        <Style.CoursesListContainer>
          <Table
            className="table-cursor-pointer"
            pagination={false}
            scroll={{ x: 1500, y: 506 }}
            columns={columns}
            dataSource={tableData}
            onRow={(record, index) => ({
              onClick: () => {
                record.disable ||
                  getCompanyCourseDetail({
                    id: record.id,
                    redirect: `/admin/course/${record.id}`,
                  });
              },
              index,
            })}
            locale={{ emptyText: "現在受講可能なコースはありません。" }}
            loading={joinedCourses.load}
            components={{
              body: {
                row: CustomRow,
              },
            }}
            rowClassName={(record) => (record.disable ? "row-disable" : " ")}
          />
        </Style.CoursesListContainer>
        {!isEmpty(joinedCourses.data) && (
          <PaginationLayout
            pageInfo={joinedCourses.meta.pageInfo}
            onChangePage={getCompanyJoinedCourses}
            sortCondition={sortCondition}
            dataParam={{
              id: userId,
              requestData: {
                page: joinedCourses.meta.pageInfo?.page,
              },
            }}
          />
        )}
      </div>
    </>
  );
};

export default JoinedCourses;
